import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { DEFAULT_PIN_LENGTH, REGEX_PIN } from 'common/config/constants';
import { getClassName, getRemoteKeyName, getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

GlobalStyle.inject`
  .pin-input-container {
    margin-top: ${getScalablePixel(16)}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface PinFieldProps {
  focused?: boolean;
  error: string;
  value: string;
}

function PinField(props: PinFieldProps) {
  return (
    <div
      className={getClassName('pin-input-field', {
        focused: props.focused,
        error: !!props.error.length
      })}
    >
      {props.value && <span className="pin-input-field-value" />}
    </div>
  );
}

interface RawInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: VoidFunction;
  pin: string;
  active?: boolean;
}

function RawInput({ onChange, onBlur, pin, active }: RawInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (active) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [active]);

  useEffect(() => {
    function keyboardVisibilityChange(event: any) {
      if (!event.detail.visibility) {
        onBlur?.();
      }
    }
    document.addEventListener('keyboardStateChange', keyboardVisibilityChange, false);

    return () => {
      document.removeEventListener('keyboardStateChange', keyboardVisibilityChange, false);
    };
  }, [onBlur]);

  return (
    <input
      ref={inputRef}
      className="pin-input-hidden-field"
      // type 'password' adds numbers to the TV keyboard
      type="password"
      inputMode="numeric"
      autoComplete="off"
      onChange={onChange}
      onBlur={onBlur}
      value={pin}
    />
  );
}

interface PinInputProps {
  onBack: (value: string) => void;
  onComplete: (value: string) => void;
  active?: boolean;
  length?: number;
  error: string;
  clearError?: () => void;
}

function PinInput({
  onComplete,
  onBack,
  active = true,
  length = DEFAULT_PIN_LENGTH,
  error,
  clearError
}: PinInputProps) {
  // Initialize inputs from length prop
  const inputs = useMemo(() => Array(length).fill(''), [length]);

  const [pin, setPin] = useState('');
  const pinRef = useRef(pin);
  const [inputActive, setInputActive] = useState(false);
  const inputActiveRef = useRef(inputActive);
  const lenghtRef = useRef(length);
  const { ref, focusSelf, focused } = useFocusable({
    onBlur: () => setInputActive(false)
  });

  useEffect(() => {
    focusSelf();
  }, []);

  useEffect(() => {
    pinRef.current = pin;
  }, [pin]);

  useEffect(() => {
    if (active) {
      setInputActive(true);
    }
  }, [active]);

  useEffect(() => {
    if (!focused && inputActive) focusSelf();
  }, [focused, inputActive]);

  useEffect(() => {
    inputActiveRef.current = inputActive;
  }, [inputActive]);

  useEffect(() => {
    lenghtRef.current = length;
  }, [length]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const key = getRemoteKeyName(e.keyCode);
      // Handle keypress events when keyboard is not shown
      // Handle backspace and adding characters to pin stack
      if (!inputActiveRef.current) {
        if (key === 'BACKSPACE' && pinRef.current.length > 0) {
          setPin((currentPin) => currentPin.slice(0, currentPin.length - 1));
        } else if (REGEX_PIN.test(e.key) && pinRef.current.length < lenghtRef.current) {
          setPin((currentPin) => currentPin + e.key);
        }
      }

      // Handle Ok
      if (key === 'OK') {
        setInputActive(true);
      } else if (key === 'BACK') {
        // Pipe on back
        onBack('');
      }
    };
    if (focused) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focused]);

  useEffect(() => {
    // Clear pin when error is present
    if (error && pin.length === lenghtRef.current) setPin('');
  }, [error]);

  useEffect(() => {
    if (!isEmpty(pin)) clearError && clearError();

    if (pin.length === lenghtRef.current) {
      onComplete(pin);
    }
  }, [pin]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (REGEX_PIN.test(event.target.value)) {
      setPin(event.target.value);
    }
  }, []);

  const onInputBlur = useCallback(() => {
    setInputActive(false);
  }, []);

  const handleClick = useCallback(() => focusSelf(), []);

  return (
    <div className="pin-input-container" onClick={handleClick} ref={ref} tabIndex={0}>
      <RawInput onChange={handleChange} onBlur={onInputBlur} pin={pin} active={inputActive} />
      {inputs.map((x: number, index: number) => {
        return (
          <PinField
            key={index}
            error={error}
            focused={pin.length === index || (!pin.length && index === 0)}
            value={pin[index] || ''}
          />
        );
      })}
    </div>
  );
}

export default React.memo(PinInput);
