import React, { startTransition, useCallback, useEffect, useMemo } from 'react';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import {
  getLastWatchedEpgs,
  getLiveChannels,
  playVideo,
  setCurrentVideo,
  setVideoOptions,
  Translation
} from 'common/actions';
import { setDetailedInfoAsset } from 'common/actions/detailed-info';
import { Card, LiveCard } from 'common/components/cards';
import { PaidChannelPopup } from 'common/components/message-popups';
import { Stripe } from 'common/components/stripe';
import { ViewWithDetails } from 'common/components/view-with-details';
import {
  Epg,
  IViewWithDetailsStripeItem,
  LiveCategories,
  LiveChannel,
  LiveChannels
} from 'common/constants/data-types';
import { usePopup } from 'common/contexts/popup';
import protect from 'common/HoC/protect';
import { IDetailedInfo } from 'common/reducers/detailedInfo';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getChannelLogo } from 'common/services/helpers';
import { groupByCategory } from 'common/utils';
import { useParentalLock } from 'common/utils/hooks';
import { usePinPopup } from 'common/utils/hooks/usePinPopup';
import { PromiseDispatch } from 'index';

interface StateProps {
  i18n: Translation;
  liveCategories: LiveCategories;
  liveChannels: LiveChannels;
  favoriteChannels: FavoriteChannels;
  lastWatched: Epg[];
  logos: any;
  detailedInfo: IDetailedInfo;
  videoPlayer: IVideoPlayer;
}

interface HomeProps extends StateProps {
  currentAsset: IDetailedInfo['asset'];
  getLiveChannels: PromiseDispatch;
  getLastWatchedEpgs: PromiseDispatch;
  setCurrentVideo: any;
  setVideoOptions: any;
  playVideo: (currentepg: Epg, options?: any) => void;
  setDetailedInfoAsset: (asset: IDetailedInfo['asset']) => void;
  currentPlayingChannelId?: string;
}

function Home(props: HomeProps) {
  const { openPinPopup } = usePinPopup();
  const { openPopup } = usePopup();
  const { locked: channelsLocked, unlock, getChannelsLockedState } = useParentalLock();

  // Group live channels
  const liveRows = useMemo(() => {
    const { liveChannels, liveCategories } = props;

    if (isEmpty(props.liveChannels)) return [];

    if (!isEmpty(liveChannels) && !isEmpty(liveCategories)) {
      const formated = Object.values(liveChannels)
        .filter((liveChannel) => liveChannel.visible)
        .map((channel: LiveChannel) => ({
          ...channel,
          isLiveCard: true,
          major_category: liveCategories[channel.category]?.name || props.i18n.common.other
        }));
      return groupByCategory(formated);
    }
    return [];
  }, [props.liveChannels, props.liveCategories, props.i18n?.common?.other, channelsLocked]);

  const favoriteChannels = useMemo(
    () =>
      Object.values(props.favoriteChannels)
        .filter((e) => e.visible)
        .map((e) => ({
          ...e,
          isLiveCard: true
        })),
    [props.favoriteChannels]
  );

  const openPlayer = useCallback(
    (channel: IViewWithDetailsStripeItem) => {
      const video = channel.isLiveCard ? channel.currentepg : channel;
      const channelsLockedState = getChannelsLockedState();
      const playingSameChannel = props.currentPlayingChannelId === video.chan_id;

      const handlePlay = () => {
        props.playVideo(video, {
          startPosition: -1,
          channelPosition: channel.isLiveCard ? channel.position : null
        });
        props.setVideoOptions({
          isFullscreen: true
        });
      };

      if (!channel.access) {
        openPopup({
          id: 'paid-channel-popup',
          type: 'primary',
          content: <PaidChannelPopup popupId="paid-channel-popup" />
        });
      } else if (channel.locked && channelsLockedState && !playingSameChannel) {
        openPinPopup({
          onSuccess: () => {
            unlock();
            handlePlay();
          }
        });
      } else {
        handlePlay();
      }
    },
    [props.currentPlayingChannelId]
  );

  const renderLiveCard = useCallback(
    (item: LiveChannel) => {
      return (
        <LiveCard
          key={`live-card-${item.id}`}
          liveChannel={item}
          logo={props.logos[item.id]?.png}
          i18n={props.i18n}
          channelsLocked={channelsLocked}
        />
      );
    },
    [props.logos, props.i18n, channelsLocked]
  );
  const renderArchiveCard = useCallback(
    (item: Epg) => (
      <Card
        key={`archive-card-${item.eventid}`}
        epg={item}
        logo={getChannelLogo(item.chan_id, props.logos)}
      />
    ),
    [props.logos]
  );
  const renderFavoriteList = useCallback(() => {
    if (isEmpty(favoriteChannels)) {
      return null;
    }
    return (
      <Stripe
        key={'favorite-channels'}
        items={favoriteChannels}
        renderItem={renderLiveCard}
        title={props.i18n.channelList.favorites}
        onStripeItemPress={openPlayer}
      />
    );
  }, [favoriteChannels]);

  const renderLiveChannelList = useCallback(() => {
    return liveRows.map((row, index) => {
      return (
        <Stripe
          key={`live-channels-stripe-${index}`}
          title={row.title}
          onStripeItemPress={openPlayer}
          items={row.items}
          renderItem={renderLiveCard}
        />
      );
    });
  }, [liveRows]);

  const renderLastWatched = useCallback(() => {
    if (isEmpty(props.lastWatched)) {
      return null;
    }
    return (
      <Stripe
        key={'last-watched'}
        items={props.lastWatched}
        renderItem={renderArchiveCard}
        title={props.i18n.channelList.lastWatched}
        onStripeItemPress={openPlayer}
      />
    );
  }, [props.lastWatched]);

  useEffect(() => {
    if (props.currentAsset && props.currentAsset.isLiveCard && !isEmpty(props.liveChannels)) {
      const current = props.liveChannels[props.currentAsset.epg.chan_id];

      startTransition(() =>
        props.setDetailedInfoAsset({
          epg: current?.currentepg,
          isLiveCard: props.currentAsset?.isLiveCard,
          resolution: current?.resolution
        })
      );
    }
  }, [props.liveChannels]);

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset && !isEmpty(props.liveChannels)) {
        const epg = asset.isLiveCard ? props.liveChannels[asset.id]?.currentepg : asset;

        // if adult channel return undefined
        if (props.liveChannels[asset.id]?.adult) {
          return undefined;
        }

        const newAsset = {
          epg,
          isLiveCard: !!asset.isLiveCard,
          resolution: asset.isLiveCard ? asset.resolution : props.liveChannels[asset.id]?.resolution
        };
        return epg && newAsset;
      }
      return undefined;
    },
    [props.liveChannels]
  );

  return (
    <ViewWithDetails focusKey={location.pathname} getSelectedAssetData={getSelectedAssetData}>
      {[renderLastWatched(), renderFavoriteList(), ...renderLiveChannelList()]}
    </ViewWithDetails>
  );
}

const mapStateToProps = ({
  liveChannels,
  logos,
  liveCategories,
  lastWatched,
  i18n,
  detailedInfo,
  favoriteChannels,
  videoPlayer
}: StateProps) => ({
  i18n,
  liveChannels,
  favoriteChannels,
  logos,
  liveCategories,
  lastWatched: lastWatched?.filter((e) => e.visible),
  currentAsset: detailedInfo.asset,
  currentPlayingChannelId: videoPlayer.channelId
});

export default React.memo(
  protect(
    connect(mapStateToProps, {
      getLiveChannels,
      getLastWatchedEpgs,
      setCurrentVideo,
      setVideoOptions,
      playVideo,
      setDetailedInfoAsset
    })(Home)
  )
);
