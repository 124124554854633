import { ClampContainerMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import GlobalStyle from 'common/utils/GlobalStyle';
import { memo } from 'react';
import styled, { css } from 'styled-components';
const { font_color_b, font_color_c, font_color_white } = variables;

GlobalStyle.inject`
.button, .simple-button {
  background: transparent;
  
  &.disabled {
    opacity: 0.5;
  }

  &.focused {
    background: rgba(255, 255, 255, 0.2);
  }

  &.button {
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: ${getScalablePixel(8)}px;
    border: solid ${getScalablePixel(1)}px ${font_color_c};
    justify-content: center;
    align-items: center;
    transition: background ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
    
    &.focused {
      border-color: ${font_color_b};
    }
    &.disabled {
      border-color: ${font_color_b};
    }

    &.small {
      padding: ${getScalablePixel(12)}px ${getScalablePixel(16)}px;
    }
    &.medium {
      padding: ${getScalablePixel(16)}px ${getScalablePixel(32)}px;
    }
    &.large {
      padding: ${getScalablePixel(16)}px ${getScalablePixel(118)}px;
    }
  }

  &.simple-button {
    width: auto;
    height: auto;
    padding: ${getScalablePixel(8)}px;
    min-width: ${getScalablePixel(62)}px;
    min-height: ${getScalablePixel(62)}px;
    position: relative;
    overflow: hidden;
    border-radius: ${getScalablePixel(200)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;

    &.focused.expand-on-focus {
      transform: scale(1.1);
    }
  }

  .button-title {
    user-select: none;
    ${ClampContainerMixin({ lines: 1, wordBreak: 'break-all' })}

    &.small {
      font-size: ${getScalablePixel(18)}px;
    }
    &.medium {
      font-size: ${getScalablePixel(24)}px;
    }
    &.large {
      font-size: ${getScalablePixel(28)}px;
    }
  }

  .button-icon-container {
    display: flex;
    align-items: center;
    margin-right: ${getScalablePixel(12)}px;
  }
}

`;

const ButtonSizeMap = {
  small: [12, 16],
  medium: [16, 32],
  large: [16.5, 36]
};

const ButtonTitleMap = {
  small: 18,
  medium: 24,
  large: 28
};
interface Focusable {
  focused?: boolean;
  disabled?: boolean;
}

const Common = css<Focusable>`
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')}
  background: ${({ focused }) => (focused ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
`;

export type ButtonSize = 'small' | 'medium' | 'large';

export interface StyledButtonProps extends Focusable {
  size: ButtonSize;
  fitContainer?: boolean;
  color?: string;
  fontWeight?: number;
  textTransform?: string;
  borderRadius?: number;
  focusBackgroundColor?: string;
  focusColor?: string;
  backgroundColor?: string;
  margin?: number;
}

export const ButtonStyled = memo(styled.div.attrs(
  ({
    color,
    fontWeight,
    textTransform,
    borderRadius,
    backgroundColor,
    focusBackgroundColor,
    focusColor,
    margin,
    focused
  }: StyledButtonProps) => ({
    style: {
      color: focused ? focusColor || color || font_color_white : color || font_color_white,
      fontWeight,
      textTransform,
      borderRadius: borderRadius || 3,
      backgroundColor: focused ? focusBackgroundColor || backgroundColor : backgroundColor,
      margin
    }
  })
)<StyledButtonProps>`
  ${Common}
  position: relative;
  overflow: hidden;
  border: solid ${getScalablePixel(1)}px
    ${({ focused, disabled }) => (focused ? font_color_b : disabled ? font_color_b : font_color_c)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ size }) =>
    `${getScalablePixel(ButtonSizeMap[size][0])}px ${getScalablePixel(ButtonSizeMap[size][1])}px`};
`);

export const ButtonTitle = styled.span<StyledButtonProps>`
  user-select: none;
  font-size: ${({ size }) => `${getScalablePixel(ButtonTitleMap[size])}px`};
`;

export const ButtonIconContainer = styled.div`
  margin-right: ${getScalablePixel(12)}px;
  display: flex;
  align-items: center;
`;

const SimpleButtonSizeStyle = css`
  min-width: ${getScalablePixel(62)}px;
  min-height: ${getScalablePixel(62)}px;
`;
// ${({ expandOnFocus, focused }) =>
//   expandOnFocus && focused ? SimpleButtonSizeStyle : !expandOnFocus ? SimpleButtonSizeStyle : ''}
export const SimpleButtonStyled = styled.div<Focusable & { expandOnFocus?: boolean }>`
  ${Common}
  width: auto;
  height: auto;
  padding: ${getScalablePixel(8)}px;
  ${SimpleButtonSizeStyle}
  transform: ${({ expandOnFocus, focused }) => `scale(${expandOnFocus && focused ? 1.1 : 1});`};
  position: relative;
  overflow: hidden;
  border-radius: ${getScalablePixel(200)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
`;
