import React, { CSSProperties } from 'react';

import Icon, { IconSize } from 'common/components/Icon';
import { Image } from 'common/components/image';

interface Props {
  id: string;
  icon?: {
    activeState?: boolean;
    activeIcon?: string;
    inactiveIcon?: string;
    size?: IconSize;
    style?: CSSProperties;
  };
  position: number;
  channelLogo: string;
  name: string;
}

function InnerSettingsToggleChannel({ id, icon, position, channelLogo, name }: Props) {
  const renderIcon = () => {
    const component = icon?.activeState ? icon?.activeIcon : icon?.inactiveIcon;
    return typeof component === 'string' ? (
      <Icon src={component} size={icon?.size || 'medium'} style={icon?.style} />
    ) : (
      component
    );
  };
  return (
    <div className="inner-settings-toggle-item" key={id}>
      {/* action icon */}
      <div className="action-icon">
        {icon?.activeIcon && icon?.inactiveIcon && renderIcon()}
        {!icon?.activeIcon || !icon?.inactiveIcon}
      </div>
      {/* channel position */}
      <div className="channel-position">{position}</div>
      {/* channel logo */}
      <div className="channel-logo">
        <Image src={channelLogo} />
      </div>
      {/* channel name */}
      <div className="channel-name">{name}</div>
    </div>
  );
}

export default React.memo(InnerSettingsToggleChannel);
