// Brand colors for items
export const color_primary = '#ED1C24';
export const color_secondary = color_primary;

export const color_helper = '#EF4156';
export const group_list_title_color = color_helper;
//

export const DISABLE_RIVE = false;

export const DEFAULT_LOGO_PATH = 'https://play.b-box.bg/channel-logos/test-350_bbox.png';

export const DEFAULT_VIEWMAP = '6daad49fd96948042283cf0b8e442ae961880acb';

export const GET_JOIN_URL_PATH = (email?: string) =>
  `https://join.bulsatcom.bg${!email ? '' : `/?email=base64(${email})`}`;

export const CHANGE_PASSWORD_ENABLED = false;
export const REORDER_CHANNELS_ENABLED = false;
export const FAVORITES_MAX_LIMIT = 100;
