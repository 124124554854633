import * as prismic from '@prismicio/client';
import { API_URL as BRAND_API_URL } from 'common/config/constants';
import { isEmpty } from 'lodash';

export const repositoryName = process.env.REACT_APP_PRISMIC_REPO_NAME;
export const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;

export const PICTURES_PROXY_URL = new URL('/prismic/pictures', BRAND_API_URL).href;
export const VIDEOS_PROXY_URL = new URL('/prismic/video', BRAND_API_URL).href;

export const PRISMIC_API_URL = new URL('/prismic/api/v2', BRAND_API_URL).href;

export const replacePrismicURLs = (
  data: Record<string, any>,
  inputURL: string,
  replaceURL: string
): Record<string, any> => {
  try {
    // Eficiently replace URL's with the proxy URL
    const dataString = JSON.stringify(data).replaceAll(inputURL, replaceURL);
    return JSON.parse(dataString);
  } catch (error) {
    console.error('Error while replacing Prismic URLs', error);

    return data;
  }
};

let prismicClientError = false;
const getURL = (urlParam: string) =>
  prismicClientError
    ? urlParam.replace(PRISMIC_API_URL, prismic.getRepositoryEndpoint(repositoryName!))
    : urlParam;

export const prismicClient = !isEmpty(accessToken)
  ? prismic.createClient(PRISMIC_API_URL, {
      accessToken,
      fetch: (urlParam, options) => {
        return fetch(getURL(urlParam), options).catch((error) => {
          if (prismicClientError) {
            throw error;
          }
          console.error('Error while fetching Prismic API', error);
          prismicClientError = true;
          return fetch(getURL(urlParam), options);
        });
      }
    })
  : undefined;
