
import { CATEGORY_FAVORITES_TYPE } from 'common/config/constants';
import { CATEGORIES_LIVE_CLEAR, CATEGORIES_LIVE_GET } from 'common/constants/action-types';
import request from 'common/services/request';

export const getLiveCategories = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/channels/categories'
      });
      const { data } = await ajax;
      dispatch({
        type: CATEGORIES_LIVE_GET,
        payload: {
          [CATEGORY_FAVORITES_TYPE.id]: CATEGORY_FAVORITES_TYPE, // adds a Favorites category
          ...data
        }
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const clearLiveCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CATEGORIES_LIVE_CLEAR
      });
    } catch (error) {
      console.warn(error)

    }
  };
};
