// eslint-disable-next-line @typescript-eslint/no-var-requires
let PaymentQr: string | null | undefined = null;
try {
  PaymentQr = require('@assets/media/payment-qr.png');
} catch (error) {
  /* empty */
}
import { Translation } from 'common/actions';
import { P1 } from 'common/components/Typography';
import { Image } from 'common/components/image';
import variables from 'common/config/variables';
import { getClassName, getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

GlobalStyle.inject`
.paid-description {
  display: flex;

  & > * {
    margin: 0 ${getScalablePixel(24)}px;
  }

  &.single-row {
    ${P1} {
      text-align: center;
    }
  }

  ${P1} {
    width: ${getScalablePixel(350)}px;
    text-align: right;
    color: ${hexToRGB(variables.color_grey_10, 0.6)};
    b {
      color: ${hexToRGB(variables.color_grey_10)};
    }
  }

  & .qr {
    width: ${getScalablePixel(279)}px;
    height: ${getScalablePixel(279)}px;
  }
}

`;

interface PaidDescriptionProps {
  i18n: Translation;
}

function PaidDescription({ i18n }: PaidDescriptionProps) {
  if (isEmpty(i18n)) return <></>;

  return (
    <div className={getClassName('paid-description', { 'single-row': isEmpty(PaymentQr) })}>
      <P1 dangerouslySetInnerHTML={{ __html: i18n.playerOverlay.paidSubscriptionDescription }} />
      {PaymentQr && <Image className="qr" src={PaymentQr} />}
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(PaidDescription));
