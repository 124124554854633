import React from 'react';

interface LiveCardProgressProps {
  progress: number;
  visible?: boolean;
}

function LiveCardProgress({ progress, visible }: LiveCardProgressProps) {
  return (
    <div className={`live-card-progress-container ${!visible ? 'visible' : ''}`}>
      <div
        className="value"
        key={'live-card-progress-value'}
        style={{
          width: `${progress}%`
        }}
      />
    </div>
  );
}

export default React.memo(LiveCardProgress);
