import { setPlayerControlsState, Translation } from 'common/actions';
import { HAS_ACCESS } from 'common/config/constants';
import variables from 'common/config/variables';
import { LiveCategories, LiveChannels } from 'common/constants/data-types';
import { IChannelList } from 'common/reducers/channelList';
import { IVideoPlayer, PlayerControlsState } from 'common/reducers/videoPlayer';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import ChannelList from './ChannelList';
import { PlayerControlsProps } from './Controls';
import './Controls.styles';
import DetailedInfo from './DetailedInfo';
import ExtendedBottomView from './ExtendedBottomView';
import LiveCategoriesStrip from './LiveCategoriesStrip';
import Seekbar from './Seekbar';
import SeekButtonsStripe from './SeekButtonsStripe';

interface BottomViewProps extends PlayerControlsProps {
  // Redux injected
  playerControlsState: PlayerControlsState;
  setPlayerControlsState: (playerState: PlayerControlsState) => void;
  loadedChannelEpgs?: boolean;
  loadedCurrentEpg?: boolean;
  hasNextVideo?: boolean;
  nextChannelId?: string;
  fastZapping?: boolean;
  channelAccess?: number;
  videoLocked?: boolean;
  //
  fastZapNumber?: number;
  channelList: IChannelList;
  toggleControls: PlayerControlsProps['toggleControls'];
}
function BottomView(props: BottomViewProps) {
  const bottomViewOpenned = useMemo(
    () => ['bottom-view'].includes(props.playerControlsState),
    [props.playerControlsState]
  );
  // Hide bottom view when hidden and fast zapping
  const visible = useMemo(() => {
    const state = props.playerControlsState;
    switch (state) {
      case 'mini':
      case 'bottom-view':
      case 'expanded':
        return !props.hasNextVideo && props.channelAccess === HAS_ACCESS && !props.disabled;
      case 'channels':
      case 'seeking':
        return true;
      case 'hidden':
        return false;
      default:
        return false;
    }
  }, [props.playerControlsState, props.loadedChannelEpgs, props.hasNextVideo, props.disabled]);

  const extendedBottomViewVisible = useMemo(
    () => ['expanded', 'bottom-view'].includes(props.playerControlsState),
    [props.playerControlsState]
  );
  const extendedBottomViewClosed = useMemo(
    () => ['hidden', 'mini'].includes(props.playerControlsState),
    [props.playerControlsState]
  );

  const onBottomViewFocus = useCallback(() => props.setPlayerControlsState('bottom-view'), []);

  const onBottomViewBlur = useCallback(() => props.toggleControls(), []);

  const onBottomViewPlay = useCallback(
    () =>
      setTimeout(() => {
        props.toggleControls(true);
      }, variables.SHORT_THROTTLE),
    []
  );

  return (
    <div
      className={getClassName('bottom-view ', {
        visible,
        'bottom-view-visible': bottomViewOpenned
      })}
    >
      <ChannelList fastZapNumber={props.fastZapNumber} />
      <div className="bottom-view-controls-container">
        <div
          className={getClassName('fast-zapping-container', { 'fast-zapping': props.fastZapping })}
        >
          <LiveCategoriesStrip visible={props.playerControlsState === 'channels'} />
          {props.playerControlsState !== 'seeking' && (
            <DetailedInfo bottomViewVisible={bottomViewOpenned} />
          )}
          {!bottomViewOpenned && (
            <Seekbar
              focusKey="SEEKBAR"
              pause={props.pause}
              play={props.play}
              setCurrentTime={props.setCurrentTime}
            />
          )}
          <SeekButtonsStripe
            togglePlay={props.togglePlay}
            forward={props.forward}
            backward={props.backward}
            setVideoToLive={props.setVideoToLive}
            setVideoToBegining={props.setVideoToBegining}
            toggleControls={props.toggleControls}
          />
        </div>
        <ExtendedBottomView
          visible={extendedBottomViewVisible}
          closed={extendedBottomViewClosed}
          onFocus={onBottomViewFocus}
          onBlur={onBottomViewBlur}
          onPlay={onBottomViewPlay}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({
  videoPlayer,
  channelList,
  liveChannels,
  liveCategories,
  logos,
  i18n
}: {
  videoPlayer: IVideoPlayer;
  channelList: IChannelList;
  liveChannels: LiveChannels;
  liveCategories: LiveCategories;
  logos: any;
  i18n: Translation;
}) => ({
  playerControlsState: videoPlayer.playerControlsState,
  loadedChannelEpgs: !isEmpty(videoPlayer.allEpgs) && !isEmpty(videoPlayer.epgs),
  loadedCurrentEpg: videoPlayer.epgs && !isEmpty(videoPlayer.epgs.current),
  hasNextVideo: !isEmpty(videoPlayer.nextVideo),
  videoLocked: videoPlayer.video?.locked,
  nextChannelId: videoPlayer.nextVideo?.channelId,
  fastZapping: videoPlayer.video?.fastZapping,
  channelAccess: videoPlayer.video?.access,
  channelList,
  liveChannels,
  liveCategories,
  logos,
  i18n
});

export default React.memo(connect(mapStateToProps, { setPlayerControlsState })(BottomView));
