import { ROUTE_ROOT } from 'common/config/constants';
import { useCallback } from 'react';
import usePageNavigation from './usePageNavigation';
import useRequiredData from './useRequiredData';

function useSoftReload() {
  const { clear } = useRequiredData();
  const navigate = usePageNavigation();

  const reload = useCallback((withoutProcess?: boolean, replace?: boolean) => {
    clear();
    navigate(ROUTE_ROOT, withoutProcess, replace);
  }, []);

  return reload;
}

export default useSoftReload;
