import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useStateCallback } from 'common/utils/hooks';
import { isEmpty, uniqueId } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { PopupContainer as PopupsContainer } from './Message.styles';
import MessagePromptItem from './MessagePromptItem';

export interface MessageOptions {
  id?: string;
  messageId: string;
  title?: string;
  cover?: string;
  multiple?: number;
  onOpen?: (id: string) => void;
}

type MessageElement = MessageOptions;

interface MessageContext {
  hasMessages?: boolean;
  openMessage: (message: MessageOptions) => string;
  closeMessage: (id: string, lastFocused?: boolean) => void;
}

const defaultOptions: Partial<MessageOptions> = {};

//@ts-ignore
const MessageContextInner = React.createContext<MessageContext>();

export const useMessage = () => useContext(MessageContextInner);

export function MessageProvider(props: React.PropsWithChildren) {
  const [messages, setMessages] = useStateCallback<MessageElement[]>([]);
  const messagesRef = useRef(messages);

  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusKey: 'MESSAGE_CONTAINER',
    isFocusBoundary: true,
    trackChildren: true,
    focusable: !isEmpty(messages)
  });

  const hasMessages = useMemo(() => messages.length > 0, [messages]);

  const handleOpenMessage = useCallback((options: MessageOptions) => {
    options = { ...defaultOptions, ...options };
    const id = uniqueId('message');
    const newMessage: MessageElement = { id, ...options };
    setMessages((current) => {
      const newMessages = [
        ...current,
        ...(current.findIndex(({ id }) => id === newMessage.id) === -1 ? [newMessage] : [])
      ];
      messagesRef.current = newMessages;
      return newMessages;
    });
    newMessage.onOpen && newMessage.onOpen(id);
    return id;
  }, []);

  const handleCloseMessage = useCallback((id: string) => {
    let message: MessageElement | undefined;
    setMessages(
      (currentMessages) => {
        message = currentMessages.find((p) => p.id === id);
        if (message) {
          const messageIndex = currentMessages.indexOf(message);
          const newMessages = [...currentMessages];
          newMessages.splice(messageIndex, 1);
          messagesRef.current = newMessages;
          return newMessages;
        }
        return currentMessages;
      },
      () => {
        setFocus('APP_CONTAINER');
      }
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      hasMessages: hasMessages,
      openMessage: handleOpenMessage,
      closeMessage: handleCloseMessage
    }),
    [messages]
  );

  const forceFocus = useCallback(() => {
    ref.current?.focus();
    setFocus('MESSAGE_CONTAINER');
  }, []);

  useEffect(() => {
    hasMessages && forceFocus();
  }, [hasMessages]);

  useEffect(() => {
    // Make sure we always have focus
    hasMessages && !hasFocusedChild && forceFocus();
  }, [hasMessages, hasFocusedChild]);

  const renderPopups = () => {
    return messages.map((message) => {
      return <MessagePromptItem key={message.id} id={message.id as string} message={message} />;
    });
  };

  return (
    <MessageContextInner.Provider value={contextValue}>
      {props.children}
      <FocusContext.Provider value={focusKey}>
        <PopupsContainer ref={ref} visible={hasMessages}>
          {renderPopups()}
        </PopupsContainer>
      </FocusContext.Provider>
    </MessageContextInner.Provider>
  );
}
