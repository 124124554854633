import {
  RESET_VIDEO_NEXT,
  VIDEO_ALL_EPG,
  VIDEO_CURRENT,
  VIDEO_NEXT,
  VIDEO_OPTIONS,
  VIDEO_PLAYER_EPGS,
  VIDEO_PLAYER_TOGGLE,
  VIDEO_RESET,
  VIDEO_SET_PLAYER_STATE
} from 'common/constants/action-types';
import { getPlaylist } from 'common/services/helpers';
import request from 'common/services/request';
import { isEmpty } from 'lodash';
// import { unlockChannelsTemporarily } from './profile-settings';

/**
 * Set video player current url to play
 * @param epg {object} - { isOpen: bool, isPlaying: bool, channelId: string, video: { start: number, end: number } }
 * @returns {function(*)}
 */
export const setCurrentVideo = (epg) => {
  return (dispatch, getState) => {
    try {
      const video = getPlaylist(epg.channelId, epg.video.start, epg.video.stop);
      const { videoPlayer, liveChannels } = getState();
      // const { timeToLockChannels, lockPeriod } = profileSettings.react_tv_settings;
      const nextPosition = epg.video?.channelPosition;
      const currentPosition = videoPlayer.video?.channelPosition;

      const previousIsPlaying = videoPlayer.isPlaying;

      const nextSource = video.source;
      const currentSource = videoPlayer.video.source;

      // When video source, channel id or channel position are different
      const playingNewChannel = nextSource !== currentSource || (!isEmpty(currentPosition) && nextPosition != currentPosition);
      const isPlaying = previousIsPlaying ? !playingNewChannel : false;

      const access = liveChannels[epg.channelId]?.access;
      const playingSameChannel = nextPosition === currentPosition;
      // Force unlock channel if playing the same channel
      const locked = playingSameChannel ? false : liveChannels[epg.channelId]?.locked;

      dispatch({
        type: VIDEO_CURRENT,
        payload: {
          ...epg,
          isPlaying,
          video: {
            ...epg.video,
            ...video,
            access,
            locked
          }
        }
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const setNextVideo = (channelId, start, stop, position, startPosition = -1, autoPlay = false) => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_NEXT,
      payload: {
        channelId,
        start,
        stop,
        startPosition,
        autoPlay,
        position
      }
    });
  };
};

export const resetNextVideo = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VIDEO_NEXT,
      payload: {}
    });
  };
};

/**
 * Set video options - volume, current time, live, play/pause, mute
 * @param options
 * @returns {Function}
 */
export const setVideoOptions = (options) => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_OPTIONS,
      payload: options
    });
  };
};

/**
 * Set video player container current state
 * @param state {object} - { isOpen: bool, isPlaying: bool }
 * @returns {function(*)}
 */
export const toggleVideoPlayerState = (state) => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_PLAYER_TOGGLE,
      payload: state
    });
  };
};

/**
 * Set video player epgs
 * @param state
 * @returns {Function}
 */
export const setVideoPlayerEpgs = (state) => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_PLAYER_EPGS,
      payload: state
    });
  };
};

/**
 * Set video player container all epgs for current channel
 * @param channelId {string}
 * @returns {function(*)}
 */
export const getAllEpgsForChannel = (channelId) => {
  return async (dispatch) => {
    try {
      let ajax = request({
        method: 'get',
        url: `/epgs/${channelId}?all`
      });
      const { data } = await ajax;
      const reversedData = [...data].reverse();

      dispatch({
        type: VIDEO_ALL_EPG,
        payload: {
          allEpgs: reversedData
        }
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

/**
 */
export const getAllEpgsForSeries = async (baseContentId) => {
  try {
    let ajax = request({
      method: 'get',
      url: `/epgs/byField/baseContentId?term=${baseContentId}`
    });
    const { data } = await ajax;
    const reversedData = [...data].reverse();
    return reversedData;
  } catch (error) {
    console.warn(error)
  }
};

/**
 *
 * @param {undefined | number} currentTime
 * @returns
 */
export const resetVideo = () => {
  return (dispatch, getState) => {
    const { videoPlayer } = getState();
    // Check if its in reset state
    if (videoPlayer.isOpen) {
      dispatch({
        type: VIDEO_RESET,
        payload: {}
      });
    }
  };
};

export const playVideo = (currentepg, options) => {
  const defaultOptions = { startPosition: -1, channelPosition: -1 };
  options = { ...defaultOptions, ...options };
  let { startPosition, hash, channelPosition } = options;
  return (dispatch, getState) => {
    // If channelPosition is -1 use the previous set channel position
    if (channelPosition === -1) {
      channelPosition = getState().videoPlayer?.video?.channelPosition;
    }
    return dispatch(
      setCurrentVideo({
        isOpen: true,
        channelId: currentepg.chan_id,
        video: {
          start: currentepg.start,
          stop: currentepg.stop,
          duration: currentepg.stop - currentepg.start,
          startPosition,
          hash,
          channelPosition
        }
      })
    );
  };
};

export const playVideoByPosition = (position, options) => {
  return (dispatch, getState) => {
    try {
      const { liveChannels, favoriteChannels } = getState();
      const channels = [...Object.values(liveChannels), ...Object.values(favoriteChannels)];
      const channel = channels.find(e => e.position === parseInt(position));
      if (channel) {
        return dispatch(playVideo(channel.currentepg, { channelPosition: channel.position, ...options }))
      } else {
        console.warn('Could not find channel with position', position);
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export const playVideoByEventId = (eventid, options) => {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: 'get',
        url: `/epgs/byField/eventid?term=${eventid}`,
      });
      if (!isEmpty(data) && !isEmpty(data[0])) {
        return dispatch(playVideo(data[0]));
      } else {
        return -1;
      }
    } catch (error) {
      console.warn(error)
    }
  }
}


export const stopVideo = () => {
  return toggleVideoPlayerState({
    isOpen: false,
    isPlaying: false
  });
};

export const setPlayerPlaying = (state) => {
  return toggleVideoPlayerState({
    isPlaying: state
  });
};

export const setPlayerControlsState = (state) => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_SET_PLAYER_STATE,
      payload: state
    });
  };
};

export const resetEpgs = () => {
  return (dispatch) => {
    dispatch({
      type: VIDEO_ALL_EPG,
      payload: { epgs: null, allEpgs: null }
    });
  };
};
