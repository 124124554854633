import { DEFAULT_SCREEN_WIDTH, PAID_CHANNEL_POSITION_OFFSET } from 'common/config/constants';
import variables from 'common/config/variables';
import { SAFE_AREA_VIEW_PADDING } from 'common/config/variables/default';
import { CategoryGroups, LiveChannel } from 'common/constants/data-types';
import {
  AppTheme,
  ContainerOffsetMap,
  ContentScrollingContentAxis,
  RemoteKeyCodeMap,
  ScrollPositionAxisMap,
  StartOffsetPaddingMap
} from 'common/interfaces';
import { sort } from 'common/services/helpers';
import _, { isNil } from 'lodash';
import React from 'react';
import AnimationDurations from './AnimationDurations';
import { isInputFieldFocused } from './helpers';

export function checkRequiredEnv() {
  const required = ['PRODUCT_NAME'];
  let required_index = 0;
  const envs = Object.keys(process.env);
  const found = required.every((r, index) => {
    required_index = index;
    return envs.indexOf(r) >= 0;
  });
  if (!found) {
    return `ENV variable '${required[required_index]} is not set'`;
  }
  return undefined;
}

export function groupByCategory<T = any>(
  data: ({ category: number; major_category: string; [key: string]: any } & T)[]
): CategoryGroups<T> {
  return _(data.sort((a, b) => a.category - b.category))
    .groupBy((e) => e.major_category)
    .map((value, key) => ({ title: key, items: value }))
    .value();
}

/**
 * Helper function to calculate a scalable pixel
 * so the non-relative unit (px) can scale and look the same on different resoltions.
 * Keep in mind this only works on screen with 16:9 aspect ratio
 * @param px
 * @returns
 */
export function getScalablePixel(px: number, invert = false) {
  if (invert) {
    return Math.round((DEFAULT_SCREEN_WIDTH / window.innerWidth) * px);
  }
  return Math.round((window.innerWidth / DEFAULT_SCREEN_WIDTH) * px);
}

export const offsetArray = (arr: Array<any>, offset: number) => [
  ...arr.slice(offset),
  ...arr.slice(0, offset)
];

/**
 * Helper function for generating keys for focusKey.
 * This key can help with focus managements
 * @param name
 * @param id
 * @returns
 */
export function getKey(name: string, id?: number | string, path?: string) {
  const _path = (path || location.pathname).replace(/\//, '');
  return `${!isNil(_path) ? _path + '-' : ''}${name}${!isNil(id) ? `-${id}` : ''}`;
}

export const getStripeEndPadding = () =>
  // variables.SAFE_AREA_VIEW_PADDING - variables.STRIPE_ITEM_PADDING;
  '50%';

export const getScrollOffset = (stripeItemWidth: number) =>
  stripeItemWidth +
  variables.STRIPE_ITEM_PADDING * 2 +
  // Calculate diff between default padding and current padding
  (variables.SAFE_AREA_VIEW_PADDING - SAFE_AREA_VIEW_PADDING) * 2;

export const getDefaultSmoothScrollOptions = (stripeItemWidth: number) => ({
  block: 'start',
  inline: 'center',
  allowAnimationOverlap: false,
  // Transition easing function:
  // easeOutQuart
  // cubic-bezier(0.25, 1, 0.5, 1)
  easing: (x: number) => 1 - Math.pow(1 - x, 4),
  //@ts-ignore
  duration: AnimationDurations.get('SCROLL_ANIMATION_DURATION'),
  paddingLeft: -getScrollOffset(stripeItemWidth) / 2,
  paddingTop: -variables.STRIPE_ITEM_PADDING
});

export const getRemoteKeyName = (code: number) => {
  try {
    const codeName = RemoteKeyCodeMap[code];
    if (codeName === 'BACK' && isInputFieldFocused() && navigator.userAgent.match(/Philips/i)) {
      return null;
    }
    return codeName;
  } catch (error) {
    console.warn('Could not find key name', error);
    return null;
  }
};

export const closeCurrentApp = () => {
  try {
    // Check if tizen app
    //@ts-ignore
    if (typeof window.tizen !== 'undefined') {
      //@ts-ignore
      window.tizen.application.getCurrentApplication().exit();
    } else if (navigator.userAgent.includes('webos')) {
      //@ts-ignore
      webOS.platformBack();
      window.close();
    } else {
      window.close();
    }
  } catch (error) {
    console.warn(error);
  }
};

export const screenSaverToggle = (state: boolean) => {
  try {
    //@ts-ignore
    if (typeof window.tizen !== 'undefined' && window.webapis) {
      //@ts-ignore
      webapis.appcommon.setScreenSaver(
        state
          ? //@ts-ignore
            webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_ON
          : //@ts-ignore
            webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF,
        () => ({}),
        (e: any) => {
          throw new Error(e);
        }
      );
    }
  } catch (error) {
    console.warn(error);
  }
};

export function getClassName(
  className: string,
  props: { [s: string]: unknown } | ArrayLike<unknown>
) {
  const additionalClasses: string[] = [];
  for (const [key, value] of Object.entries(props)) {
    if (value) additionalClasses.push(key);
  }
  return `${className} ${additionalClasses.join(' ')}`;
}

export function getMouseMoveScrollHandler(
  scrollContainer: HTMLElement | null,
  axis: ContentScrollingContentAxis,
  size?: { x: number; y: number }
) {
  return (e: React.MouseEvent<HTMLElement>) => {
    // Enable mouse scroll only on horizontal stripes
    //@ts-ignore
    if (!scrollContainer || smoothScroll.scrolling()) {
      return;
    }
    let inline = 'center';

    const defaultOffset = variables.STRIPE_ITEM_WIDTH;
    const OffsetMap = size || { x: defaultOffset, y: defaultOffset };
    const PositionMap = { x: 'clientX', y: 'clientY' };
    const ScrollPositionMap = { x: 'xPos', y: 'yPos' };

    const containerRect = (scrollContainer as HTMLDivElement).getBoundingClientRect();
    const pointerPosition = e[PositionMap[axis]] - containerRect[StartOffsetPaddingMap[axis]];
    const containerOffset = containerRect[ContainerOffsetMap[axis]];
    if (pointerPosition < variables.SAFE_AREA_VIEW_PADDING) {
      inline = 'start';
    } else if (pointerPosition > containerOffset - variables.SAFE_AREA_VIEW_PADDING) {
      inline = 'end';
    } else {
      return;
    }
    const position =
      scrollContainer[ScrollPositionAxisMap[axis]] +
      OffsetMap[axis] * (inline === 'start' ? -1 : 1);
    //@ts-ignore
    smoothScroll({
      inline,
      [ScrollPositionMap[axis]]: position,
      scrollingElement: scrollContainer,
      allowAnimationOverlap: false,
      duration: AnimationDurations.get('SCROLL_ANIMATION_DURATION'),
      paddingLeft: 0
    });
  };
}
// Live channel array sorted as like it would be without the paid channel position mask
export const sortByPosition = (channels: LiveChannel[]) => {
  const items = channels.map((e) => ({
    ...e,
    access: e.access ? e.position : e.position * PAID_CHANNEL_POSITION_OFFSET
  }));

  return sort(items, false, variables.WHITELABEL_TYPE === AppTheme.LINEAR) as LiveChannel[];
};

export function interpolate(string: string, argumentArray: any[]) {
  const regex = /%s/;
  const _r = function (p: string, c: any) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, string);
}

export function getCaretPosition(ctrl: HTMLInputElement | HTMLTextAreaElement) {
  let CaretPos = 0;
  const oldType = ctrl.type;
  ctrl.setAttribute('type', 'text');
  if (ctrl.selectionStart || ctrl.selectionStart == 0) {
    // Standard.
    CaretPos = ctrl.selectionStart;
  } else if (document['selection']) {
    // Legacy IE
    ctrl.focus();
    const range = document['selection'].createRange();
    range.moveStart('character', -ctrl.value.length);
    CaretPos = range.text.length;
  }
  ctrl.setAttribute('type', oldType);

  return CaretPos;
}

export function setCaretPosition(ctrl: HTMLInputElement | HTMLTextAreaElement, pos: number) {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    const oldType = ctrl.type;
    ctrl.setAttribute('type', 'text');
    ctrl.setSelectionRange(pos, pos);
    ctrl.setAttribute('type', oldType);
  }
}

export function getDeviceType() {
  //@ts-ignore
  if (typeof window.tizen !== 'undefined') {
    return 'tizen';
  } else if (navigator.userAgent.includes('webos')) {
    return 'webos';
  } else if (navigator.userAgent.match(/Philips/i)) {
    return 'titanos';
  }

  return false;
}
