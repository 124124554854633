import { FocusableComponentLayout, FocusDetails } from '@noriginmedia/norigin-spatial-navigation';
import { Translation } from 'common/actions';
import { Row } from 'common/components/common/style';
import { usePopup } from 'common/contexts/popup';
import { useTrialTimeLogic } from 'common/utils/hooks';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import MenuItem, { MenuItemProps } from 'menu/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  BottomBorder,
  ColumnContainer,
  DemoTitle,
  MenuTrialCounterStyle,
  RemainingSubtitle,
  RemainingTimePeriod,
  RemainingTimeValue
} from './Trial.styles';
import TrialModal from './TrialModal';

interface MenuTrialCounterProps extends Omit<MenuItemProps, 'onEnterPress'> {
  visible?: boolean;
  onPopupToggle?: (state: boolean) => void;
  //
  userDetails: any;
  i18n: Translation;
  //
}

const MenuTrialCounter = (props: MenuTrialCounterProps) => {
  const data = useTrialTimeLogic();
  const { openPopup } = usePopup();
  const [focused, setFocused] = useState(false);

  const handlePress = useCallback(() => {
    const onPopupToggle = (state: boolean) => {
      props.onPopupToggle && props.onPopupToggle(state);
    };
    openPopup({
      id: 'TRIAL-MODAL',
      returnToLastFocused: true,
      type: 'primary',
      content: <TrialModal />,
      onOpen: () => onPopupToggle(true),
      onClose: () => onPopupToggle(false)
    });
  }, [openPopup, props.onPopupToggle]);

  const onFocus = useCallback(
    (layout: FocusableComponentLayout, options: object, details: FocusDetails) => {
      props.onFocus && props.onFocus(layout, options, details);
      setFocused(true);
    },
    []
  );

  const onBlur = useCallback(
    (layout: FocusableComponentLayout, options: object, details: FocusDetails) => {
      props.onBlur && props.onBlur(layout, options, details);
      setFocused(false);
    },
    []
  );

  const menuItemProps = useMemo(() => {
    const { i18n, userDetails, ...rest } = props;
    return rest;
  }, [props]);

  if (isEmpty(props.userDetails) || isEmpty(props.userDetails?.trial) || data?.expired) {
    return <></>;
  }

  return (
    <MenuItem
      {...menuItemProps}
      onEnterPress={handlePress}
      onClick={handlePress}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <MenuTrialCounterStyle focused={focused}>
        <DemoTitle>{props.i18n?.trial?.demo}</DemoTitle>
        <Row>
          <RemainingTimeValue>{data?.number}</RemainingTimeValue>
          <ColumnContainer>
            <RemainingSubtitle>{data?.word}</RemainingSubtitle>
            <RemainingTimePeriod>{data?.wordTime}</RemainingTimePeriod>
          </ColumnContainer>
        </Row>
        <BottomBorder />
      </MenuTrialCounterStyle>
    </MenuItem>
  );
};

const mapStateToProps = ({ userDetails, i18n }: StoreState) => ({
  userDetails,
  i18n
});

export default React.memo(connect(mapStateToProps, {})(MenuTrialCounter));
