import {
  FocusableComponentLayout,
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import MainSettingsIcon from '@assets/media/main-settings-icon.svg';
import MessagesIconUnread from '@assets/media/menu-message-icon-unread.svg';
import MessagesIcon from '@assets/media/menu-message-icon.svg';

import { Translation } from 'common/actions';
import { DateTime } from 'common/components/date-time';
import { ExitPopup } from 'common/components/exit-popup';
import Icon from 'common/components/Icon';
import { MainSettingsDrawer } from 'common/components/main-settings';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { MenuTrialCounter } from 'common/components/trial';
import VerticalSeparator from 'common/components/VerticalSeparator';
import {
  MENU_ROUTES,
  ROUTES_WITH_HEADER,
  ROUTE_INBOX,
  ROUTE_SEARCH
} from 'common/config/constants';
import variables from 'common/config/variables';
import { Category, RecordedStore } from 'common/constants/data-types';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { getClassName, getKey, getRemoteKeyName, getScalablePixel } from 'common/utils';
import FocusHistory from 'common/utils/FocusHistory';
import { getPageName, getRootPath } from 'common/utils/helpers';
import { useHoverable, useMessages, usePageNavigation } from 'common/utils/hooks';
import { StoreState } from 'index';
import { isEmpty, throttle } from 'lodash';
import { routes } from 'pages';
import DetailedInfoWrapper from './DetailedInfo';
import { MenuStatesEnum } from './Menu.styles';
import MenuItem from './MenuItem';
import MenuSelector from './MenuSelector';
import SearchItem from './SearchItem';
import { getInitialFocusKey, getRouteMatch, isMenuShown } from './utils';
const { THROTTLE_TIMEOUT } = variables;

type StateProps = {
  bannerActive: boolean;
  categories: RecordedStore['categories'];
  i18n: Translation;
};

type OunProps = {
  focusKey: string;
};

type MenuProps = StateProps & OunProps;

function Menu({ focusKey: focusKeyParam, bannerActive, categories, i18n }: MenuProps) {
  const { openDrawer, closeDrawer } = useDrawer();
  const { pathname } = useLocation();
  const menuIsShown = useMemo(() => isMenuShown(pathname), [pathname]);
  const [openedPopupFromMenu, setOpenedPopup] = useState(false);
  const navigate = usePageNavigation();
  const { hasUnreadMessages, hasInboxMessages } = useMessages();

  const { ref, hasFocusedChild, focusKey, focusSelf } = useFocusable({
    focusable: menuIsShown,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    forceFocus: true,
    isFocusBoundary: false,
    focusKey: focusKeyParam,
    preferredChildFocusKey: getInitialFocusKey(pathname),
    onFocus: () => {
      // Clear history for current path when user navigates to the menu
      FocusHistory.clearHistoryForPath(location.pathname);
      //
    }
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(hasFocusedChild);

  const { openPopup } = usePopup();

  const [focusedChildLayout, setFocusedChildLayout] = useState<{
    layout?: FocusableComponentLayout;
    visible?: boolean;
  }>();
  const [subText, setSubText] = useState('');
  const searchActive = useMemo(() => getRootPath(pathname).includes(ROUTE_SEARCH), [pathname]);

  useEffect(() => {
    if (Object.keys(categories).length) {
      const match = getRouteMatch(pathname);
      if (match) {
        const categoryKey = match[1];
        const matchedCategory = categories[categoryKey] as Category;

        setSubText(matchedCategory.name);
      }
    }
    return () => {
      if (getRouteMatch(pathname) && Object.keys(categories).length) {
        setSubText('');
      }
    };
  }, [pathname, categories]);

  const viewState = useMemo(() => {
    const focused = active || openedPopupFromMenu;
    if (!focused) {
      if (bannerActive) {
        return MenuStatesEnum.bannerActive;
      } else {
        return MenuStatesEnum.detailedInfo;
      }
    } else {
      return MenuStatesEnum.main;
    }
  }, [active, bannerActive, openedPopupFromMenu]);

  const menuItemForCurrentPage = useMemo(
    () => getKey('menu-item', undefined, getRootPath(location.pathname)),
    [location.pathname]
  );

  // Hack to get the new position of the selected element
  // Only on elements in the header menu
  const handleFocus = () => {
    if (menuIsShown && MENU_ROUTES.includes(getRootPath(location.pathname))) {
      setFocus(menuItemForCurrentPage);
    }
  };

  // Used for initial page loading
  useEffect(() => {
    focusSelf();
  }, [menuIsShown]);

  // Used for tracking the selector position to current page
  useEffect(() => {
    hasFocusedChild && getCurrentFocusKey() === menuItemForCurrentPage && handleFocus();
  }, [menuIsShown, hasFocusedChild]);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = throttle((e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        const currentFocusKey = getCurrentFocusKey();
        const homeMenuItem = 'home-menu-item';
        // Check if focused on home
        if (currentFocusKey === homeMenuItem) {
          openPopup({
            id: 'EXIT-POPUP',
            content: <ExitPopup />,
            returnToLastFocused: true,
            onOpen: () => setOpenedPopup(true),
            onClose: () => setOpenedPopup(false)
          });
          return;
        } else if (currentFocusKey.includes('search-menu-item') && searchActive) {
          navigate(-1, true);
        }
        // Must be last
        else if (currentFocusKey.includes('menu-item')) {
          setFocus(homeMenuItem);
        }
      }
    }, THROTTLE_TIMEOUT);
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild, openPopup, searchActive]);

  const onMenuItemFocus = useCallback(
    (layout: FocusableComponentLayout) => {
      // Shift selector only when the menu is expanded
      if (layout && menuIsShown) {
        startTransition(() => setFocusedChildLayout({ layout, visible: true }));
      }
    },
    [menuIsShown]
  );

  const renderBreadCrumb = useCallback(() => {
    if (viewState === MenuStatesEnum.detailedInfo) {
      const routeConfig = routes
        .filter((route) => ROUTES_WITH_HEADER.includes(route.path || ''))
        .find((route) => route.path === getRootPath(pathname));
      if (!routeConfig) {
        return null;
      }
      const pageTitle = isEmpty(i18n) ? '' : i18n.pageNames[getPageName(routeConfig.path) || ''];
      return <DetailedInfoWrapper text={pageTitle} subText={subText} />;
    }
  }, [viewState, pathname, subText]);

  const renderMenu = () => {
    const menuRoutes = routes.filter((route) => MENU_ROUTES.includes(route.path || ''));

    const items = menuRoutes.map((route) => {
      const key = getKey('menu-item', undefined, route.path);
      const pageName = getPageName(route.path);
      const pageTitle = isEmpty(i18n) ? '' : i18n.pageNames[pageName];

      return (
        <MenuItem
          key={key}
          route={route}
          title={pageTitle}
          disabled={!menuIsShown || searchActive}
          focusKey={key}
          onFocus={onMenuItemFocus}
        />
      );
    });

    return (
      <>
        {items}
        {viewState === 'main' &&
          focusedChildLayout &&
          focusedChildLayout.layout &&
          focusedChildLayout.visible && (
            <MenuSelector
              position={getScalablePixel(
                focusedChildLayout.layout.x + focusedChildLayout.layout.width / 2,
                true
              )}
              width={focusedChildLayout.layout.width}
            />
          )}
      </>
    );
  };

  const defaultOnFocus = useCallback(() => setFocusedChildLayout({ visible: false }), []);
  const mainSettingsIcon = useMemo(() => <Icon src={MainSettingsIcon} size="large" />, []);
  const messagesIcon = useMemo(
    () => <Icon src={hasUnreadMessages ? MessagesIconUnread : MessagesIcon} size="large" />,
    [hasUnreadMessages]
  );

  const openMainSettingsDrawer = () => {
    setOpenedPopup(true);
    openDrawer({
      id: 'main-settings-drawer',
      content: <MainSettingsDrawer />,
      positionContent: 'top',
      onClose: () => {
        closeDrawer('main-settings-drawer');
        setOpenedPopup(false);
      }
    });
  };

  const openInbox = useCallback(() => {
    navigate(ROUTE_INBOX);
  }, []);

  const renderSections = useCallback(() => {
    const visible = viewState !== MenuStatesEnum.detailedInfo;

    return (
      <div className={`menu-section-container ${visible ? 'visible' : ''}`}>
        <MenuTrialCounter
          key="trial-timer"
          focusKey="trial-timer-mmenu-item"
          onFocus={defaultOnFocus}
          visible={visible}
          onPopupToggle={setOpenedPopup}
        />
        <SearchItem
          key="search-icon"
          searchActive={searchActive}
          focusKey="search-menu-item"
          onFocus={defaultOnFocus}
        />
        {hasInboxMessages && (
          <MenuItem
            title={messagesIcon}
            key="messages-inbox"
            focusKey="messages-inbox-menu-item"
            onEnterPress={openInbox}
            onClick={openInbox}
            onFocus={defaultOnFocus}
          />
        )}
        <MenuItem
          title={mainSettingsIcon}
          key="main-settings"
          focusKey="main-settings-menu-item"
          onEnterPress={openMainSettingsDrawer}
          onClick={openMainSettingsDrawer}
          onFocus={defaultOnFocus}
        />
        <VerticalSeparator height={`${getScalablePixel(24)}px`} margin={38} />
        <span className="date-time">
          <DateTime showDate={false} size="small" />
        </span>
      </div>
    );
  }, [viewState, searchActive, messagesIcon, hasInboxMessages]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={getClassName(`top-menu-wrapper ${viewState}`, {
          visible: menuIsShown,
          'search-active': searchActive
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="product-logo-wrapper">
          <ProductLogo />
        </div>
        <div className={`menu-items-container ${viewState}`}>
          <div className="breadcrumb-container">{renderBreadCrumb()}</div>
          <div className="menu-items">{renderMenu()}</div>
        </div>
        {renderSections()}
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ carousel, recorded, i18n }: StoreState): StateProps => {
  const categories = recorded.categories as RecordedStore['categories'];
  return {
    bannerActive: !carousel.isExpanded,
    categories,
    i18n
  };
};

export default React.memo(connect(mapStateToProps, {})(Menu));
