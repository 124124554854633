import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
.simple-scroll-view {
    display: flex;
    padding: 1px;

    &.horizontal {
      flex-direction: row;
      align-items: center;
      overflow-x: hidden;
      justify-content: flex-start;
    }

    &.vertical {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      overflow-y: hidden;
    }

    .simple-scroll-view-item {
      min-width: fit-content;
    }
}
`;
