import {
  doesFocusableExist,
  FocusableComponentLayout,
  FocusContext,
  getCurrentFocusKey,
  pause,
  resume,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { STRIPE_ITEM_HEIGHT } from 'common/config/variables/default';
import {
  ContentAlign,
  ContentAxisAlignMap,
  ContentScrollingContentAxis,
  FocusableItem,
  ScrollDirectionPaddingMap,
  ScrollPaddingAxisMap,
  StartOffsetPaddingMap,
  StripeData,
  StripeItemData,
  StripeItemEvents,
  WindowMap
} from 'common/interfaces';
import { NavigateMode } from 'common/reducers/appConfig';
import {
  getClassName,
  getDefaultSmoothScrollOptions,
  getKey,
  getMouseMoveScrollHandler,
  getRemoteKeyName,
  getStripeEndPadding
} from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import FocusHistory from 'common/utils/FocusHistory';
import { isInViewport } from 'common/utils/helpers';
import { useOnScreen } from 'common/utils/hooks';
import smoothScroll from 'common/utils/smoothScroll';
import { DebouncedFunc, isEmpty, isNil, isNumber, throttle } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useDeferredValue,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import './Stripe.styles';
import StripeItem from './StripeItem';
import StripePadding from './StripePadding';

const { INITIAL_STRIPE_ITEM_LOAD_SIZE, LOAD_OVERSCAN_COUNT, STRIPE_ITEM_WIDTH } = variables;

export interface FocusableStripeProps extends StripeData, FocusableItem, StripeItemEvents {
  saveFocusOnUnmount?: boolean;
  initiallyFocused?: boolean;
  index?: number;
  loading?: boolean;
  axis: ContentScrollingContentAxis;
  align?: ContentAlign;
  scrollPadding?: number;
  startOffsetPadding?: string;
  forceLoadToIndex?: number;
  indexesShift?: number;
  disableEventHandlers?: boolean;
  // Tells parent component which index has been focused
  onScrollToIndex?: (index: number) => void;
  // When back is pressed on focusable stripe
  // Status can be 0/-1
  // returns -1 when scroll position is already at the same index
  onBack?: (status: number) => void;
  //
  // Helps the component know when to use the clean up function
  visible?: boolean;
  navigateMode: NavigateMode;
  //
}

export interface FocusableStripeRefObject {
  scrollToIndex: (index: number, animateScroll?: boolean) => void;
}

const FocusableStripe = React.forwardRef<FocusableStripeRefObject, FocusableStripeProps>(
  function FocusableStripe(
    {
      title: rowTitle,
      focusKey: focusKeyParam,
      items: itemsParam,
      groupItems,
      renderItem,
      renderGroupItem,
      onFocus,
      onBlur,
      onArrowPress,
      onStripeItemFocus,
      onStripeItemPress,
      onStripeItemBlur,
      onStripeItemHover,
      onScrollToIndex,
      initiallyFocused,
      index: rowIndex,
      disabled,
      axis,
      initialIndex: initialIndexParam,
      align,
      scrollPadding,
      startOffsetPadding,
      isFocusBoundary,
      loadMore,
      navigateMode,
      visible: visibleParam = true,
      stripeItemClassName: focusedClassName,
      onBack,
      indexesShift = 0,
      getFocusKey: getFocusKeyForItem,
      saveFocusOnUnmount = true
    }: FocusableStripeProps,
    customRef
  ) {
    const itemsRef = useRef<StripeItemData[]>([]);
    const groupItemsRef = useRef(groupItems);

    const items = useMemo(() => {
      if (!isEmpty(groupItems) && groupItems) {
        groupItemsRef.current = groupItems;
        itemsRef.current = groupItems.reduce((acum: StripeItemData[], current) => {
          acum.push(...(current.items as any));
          return acum;
        }, []);
      } else if (!isEmpty(itemsParam) && itemsParam) {
        itemsRef.current = itemsParam;
      } else {
        itemsRef.current = [];
      }
      return itemsRef.current;
    }, [itemsParam, groupItems]);

    const isGroupMode = useMemo(
      () => !isEmpty(groupItems) && groupItems && !!groupItemsRef.current,
      [groupItems]
    );

    const groupIndexesRef = useRef<number[]>([]);
    const groupIndexes = useMemo(() => {
      if (isEmpty(groupItems) || !groupItems) {
        groupIndexesRef.current = [];
        return [];
      }
      let acum = 0;
      const indexes = groupItems.reduce((a: number[], e, i) => {
        acum += e.items.length;
        if (i === 0) {
          a.push(0);
          return a;
        }
        // const size = acum + e.items.length;
        a.push(acum);
        return a;
      }, []);
      groupIndexesRef.current = indexes;
      return indexes;
    }, [groupItems]);

    const focusKeyList = useRef<string[]>([]);
    const focusOnIndex = useRef<number | undefined>();
    const viewportIndexes = useRef<[number, number]>([-1, -1]);
    const { ref, focusKey, focusSelf } = useFocusable({
      onFocus,
      onBlur: (...args) => {
        setHasFocusedChild(false);
        uiCheckerFrameId.current && cancelAnimationFrame(uiCheckerFrameId.current);
        resume();
        onBlur && onBlur(...args);
      },
      focusable: !disabled,
      saveLastFocusedChild: true,
      autoRestoreFocus: true,
      focusKey: focusKeyParam,
      isFocusBoundary,
      trackChildren: false,
      extraProps: {
        index: rowIndex
      }
    });
    const location = useLocation();
    const scrollingRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<ViewportListRef | null>(null);
    const listRefs = useRef<ViewportListRef[]>([]);
    const debounceHoverRef = useRef<DebouncedFunc<VoidFunction> | null>(null);
    const uiCheckerFrameId = useRef<number | null>(null);
    const [hasFocusedChild, setHasFocusedChild] = useState(false);
    const hasFocusedChildRef = useRef(hasFocusedChild);
    const _visible = useOnScreen(
      ref,
      (ref.current?.offsetWidth || 0) / 2,
      (ref.current?.offsetHeight || 0) / 2
    );
    const visible = useDeferredValue(_visible);

    useEffect(() => {
      if (initiallyFocused) {
        const key = focusKeyList.current[0];
        doesFocusableExist(key) && setFocus(key);
      }
    }, [initiallyFocused]);

    const cleanUp = useCallback(() => {
      AnimationDurations.reset('SCROLL_ANIMATION_DURATION');
      AnimationDurations.reset('DEFAULT_ANIMATION_DURATION');
    }, []);

    useEffect(() => {
      !visibleParam && cleanUp();
    }, [visibleParam, cleanUp]);

    useEffect(() => {
      return cleanUp;
    }, []);

    useEffect(() => {
      !hasFocusedChild && cleanUp();
      hasFocusedChildRef.current = hasFocusedChild;
    }, [hasFocusedChild, cleanUp]);

    // Handle scroll to index when returning to a page
    useLayoutEffect(() => {
      try {
        if (saveFocusOnUnmount) {
          const current = FocusHistory.getCurrentByPath(location.pathname);
          if (current && current.layout && current.layout.parentIndex === rowIndex) {
            const offset = current.layout ? current.layout[axis === 'x' ? 'width' : 'height'] : 0;
            scrollToIndex(current.layout.index, false, offset, false);
          }
        }
      } catch (error) {
        console.warn(error);
      }
    }, [saveFocusOnUnmount, rowIndex]);
    //

    const getGroupIndexByItemIndex = useCallback((index: number) => {
      return groupIndexesRef.current.findIndex((e) => index <= e);
    }, []);

    // Handle back navigation
    useEffect(() => {
      const handleKeyPress = throttle((e: KeyboardEvent) => {
        if (getRemoteKeyName(e.keyCode) === 'BACK') {
          const currentKey = getCurrentFocusKey();
          const firstKey = focusKeyList.current[0];
          const status = currentKey === firstKey ? -1 : 0;
          onBack && onBack(status);
        }
      }, variables.KEYPRESS_TIMEOUT);
      if (hasFocusedChild) {
        document.addEventListener('keydown', handleKeyPress);
      }
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [hasFocusedChild, onBack]);

    const scrollToIndex = useCallback(
      (index: number, alignToTop?: boolean, offset?: number, animateScroll?: boolean) => {
        if (!animateScroll) {
          // temporary disable animations
          AnimationDurations.set('SCROLL_ANIMATION_DURATION', 0);
          AnimationDurations.set('DEFAULT_ANIMATION_DURATION', 0);
          //
        }
        let list = listRef.current;
        let indexOffset = 0;
        if (isGroupMode && groupIndexesRef.current) {
          // Check in which list the index is containing
          const groupIndex = getGroupIndexByItemIndex(index);
          list = listRefs.current[groupIndex];
          indexOffset = groupItemsRef.current
            ? -(groupItemsRef.current[groupIndex]?.items?.length - 1) || 0
            : 0;
        }
        if (!list) return;

        list.scrollToIndex({
          index: index + indexOffset,
          alignToTop,
          offset,
          prerender: INITIAL_STRIPE_ITEM_LOAD_SIZE
        });
        const keyToFocus = focusKeyList.current[index];
        // Check if item is already in the viewport
        // Then focus manually here
        // Only on traditional list without groups
        if (isGroupMode) {
          // When in group mode, focus has to be set outside the component.
          // Maybe use the index and "getFocusKey" to set the focus
          onScrollToIndex && onScrollToIndex(index);
          cleanUp();
        } else if (viewportIndexes.current.includes(index) && doesFocusableExist(keyToFocus)) {
          setFocus(keyToFocus);
          onScrollToIndex && onScrollToIndex(index);
          focusOnIndex.current = undefined;
          cleanUp();
        } else {
          focusOnIndex.current = index;
        }
      },
      [onScrollToIndex, isGroupMode]
    );

    // Handle scroll to index
    useEffect(() => {
      const _initialIndexParam = isNumber(initialIndexParam)
        ? (initialIndexParam as number)
        : initialIndexParam?.index;
      const initialIndex = _initialIndexParam;
      if (!isNil(initialIndex)) {
        scrollToIndex(
          initialIndex,
          false,
          scrollPadding,
          !isNumber(initialIndexParam) ? initialIndexParam?.animateScroll : false
        );
      }
    }, [initialIndexParam, scrollPadding, scrollToIndex]);
    //

    useImperativeHandle(
      customRef,
      () => ({
        scrollToIndex: (index, animateScroll) =>
          scrollToIndex(index, false, scrollPadding, animateScroll)
      }),
      [scrollToIndex, scrollPadding]
    );

    const getIndex = (extraProps?: { index: number }) =>
      isNil(extraProps) ? -1 : extraProps.index;

    const getItemByIndex = useCallback(
      (extraProps?: { index: number }) => {
        const index = getIndex(extraProps);
        return index !== -1 ? items[index] : null;
      },
      [items]
    );

    const _onStripeItemHover = useCallback(
      (_layout: FocusableComponentLayout, extraProps: any) => {
        onStripeItemHover && onStripeItemHover(getItemByIndex(extraProps));
      },
      [onStripeItemHover, getItemByIndex]
    );

    const _onStripeItemBlur = useCallback(
      (layout: FocusableComponentLayout, extraProps: any) => {
        //@ts-ignore
        onStripeItemBlur && onStripeItemBlur(getItemByIndex(extraProps));
      },
      [onStripeItemBlur, getItemByIndex]
    );

    const _onStripeItemFocus = useCallback(
      (layout: FocusableComponentLayout, extraProps: any) => {
        onStripeItemFocus && onStripeItemFocus(getItemByIndex(extraProps));
        if (!hasFocusedChildRef.current) setHasFocusedChild(true);
        const index = getIndex(extraProps);
        if (!scrollingRef.current) {
          return;
        }
        // Check if node is currently in the list
        // Restore focus
        if (!layout.node.isConnected) {
          pause();
          return;
        } else {
          resume();
        }
        saveFocusOnUnmount &&
          FocusHistory.setCurrentNode(
            { ...layout, index, parentIndex: rowIndex },
            location.pathname
          );
        // Only smooth scroll when navigating with arrows
        navigateMode === 'DIRECTIONAL' &&
          //@ts-ignore
          smoothScroll({
            ...getDefaultSmoothScrollOptions(layout.width),
            toElement: layout.node,
            firstAxis: axis,
            scrollingElement: scrollingRef.current,
            ...(align ? { [ContentAxisAlignMap[axis]]: align } : undefined),
            ...(!isNil(scrollPadding) ? { [ScrollPaddingAxisMap[axis]]: scrollPadding } : undefined)
          });
        // });
        // Handle load more
        index >= items.length - 1 - LOAD_OVERSCAN_COUNT &&
          loadMore &&
          loadMore({ rowIndex: rowIndex || 0, index });
        //
        // Temporary disable focus management so the UI can catch up
        const uiChecker = () => {
          if (isInViewport(layout.node)) {
            resume();
          } else {
            pause();
            uiCheckerFrameId.current = requestAnimationFrame(uiChecker);
          }
        };
        uiCheckerFrameId.current && cancelAnimationFrame(uiCheckerFrameId.current);
        uiChecker();
      },
      [
        scrollingRef.current,
        uiCheckerFrameId.current,
        onStripeItemFocus,
        scrollPadding,
        axis,
        align,
        items,
        rowIndex,
        loadMore,
        navigateMode,
        getItemByIndex,
        saveFocusOnUnmount
      ]
    );

    const _onStripeItemPress = useCallback(
      (extraProps: any) => {
        onStripeItemPress && onStripeItemPress(getItemByIndex(extraProps));
      },
      [onStripeItemPress, items]
    );

    const onWheel = useCallback(() => {
      focusSelf();
    }, [axis, scrollingRef.current]);

    const handleFocusOnViewportChange = useCallback(() => {
      // Handle focus on index when viewport is scrolling to that specific index
      // Only set the focus when the new item becomes visible
      if (!isNil(focusOnIndex.current) && !isEmpty(focusKeyList.current)) {
        const keyToFocus = focusKeyList.current[focusOnIndex.current];
        if (doesFocusableExist(keyToFocus)) {
          onScrollToIndex && onScrollToIndex(focusOnIndex.current);
          setFocus(keyToFocus);
          focusOnIndex.current = undefined;
          cleanUp();
        }
      }
    }, [onScrollToIndex]);

    const getFocusKey = useCallback(
      (index: number, focusKey?: string) => {
        if (!focusKey) {
          focusKeyList.current.splice(index, 1);
          return;
        }
        focusKeyList.current[index] = focusKey;
        handleFocusOnViewportChange();
      },
      [handleFocusOnViewportChange]
    );

    const onViewportIndexesChange = useCallback(
      (indexes: [number, number]) => {
        viewportIndexes.current = indexes;
        handleFocusOnViewportChange();
      },
      [handleFocusOnViewportChange]
    );

    const focusableItems = useMemo(() => items.filter((e) => !e.disabled), [items]);

    const renderStripeItems = useCallback(
      (item: StripeItemData, index: number) => {
        // todo: add categories as items.
        // this can be a secondary,
        // more explicit way of setup focusablestripe to not render stripe item
        if (item.disabled) {
          return renderItem(item, index) as ReactElement;
        }

        const focusableIndex = focusableItems.indexOf(item);
        const uniqueKey = getKey('stripe-item', `${item.id}-${rowIndex || 0}-${index}`, focusKey);
        const focusKeyForItem = getFocusKeyForItem && getFocusKeyForItem(item, index);
        const disabledItem = typeof disabled === 'undefined' ? item.disabled : disabled;

        return (
          <StripeItem
            key={uniqueKey}
            focusKey={focusKeyForItem}
            getFocusKey={getFocusKey}
            onEnterPress={_onStripeItemPress}
            onFocus={_onStripeItemFocus}
            onBlur={_onStripeItemBlur}
            onHover={_onStripeItemHover}
            onMouseLeave={onStripeItemBlur}
            onClick={_onStripeItemPress}
            index={index}
            focusableIndex={focusableIndex}
            axis={axis}
            onArrowPress={onArrowPress}
            disabled={disabledItem}
            initiallyFocused={initiallyFocused && focusableIndex === 0}
            navigateMode={navigateMode}
            stripeItemClassName={focusedClassName}
            hidden={!visible}
          >
            {renderItem(item, index) as ReactElement}
          </StripeItem>
        );
      },
      [
        rowIndex,
        renderItem,
        initiallyFocused,
        axis,
        focusedClassName,
        disabled,
        focusableItems,
        _onStripeItemFocus,
        _onStripeItemBlur,
        _onStripeItemPress,
        _onStripeItemHover,
        onArrowPress,
        visible,
        getFocusKeyForItem,
        focusKey
      ]
    );

    const renderList = () => {
      if (!isEmpty(groupItems) && groupItems && renderGroupItem) {
        // Remove empty groups
        const nonEmptyGroups = groupItems?.filter((e) => e.items.length > 0);
        let groupOffset = 0;
        //
        return nonEmptyGroups?.map((group, groupIndex) => {
          const previousGroup = groupIndex > 0 && nonEmptyGroups[groupIndex - 1];
          const previousGroupSize = previousGroup ? previousGroup.items.length : 0;
          groupOffset += previousGroupSize;
          const offset = groupOffset;
          return (
            <>
              {renderGroupItem(group.title, groupIndex)}
              <ViewportList
                ref={(ref) => {
                  if (ref) listRefs.current[groupIndex] = ref;
                }}
                viewportRef={scrollingRef}
                items={group.items}
                initialPrerender={INITIAL_STRIPE_ITEM_LOAD_SIZE}
                onViewportIndexesChange={onViewportIndexesChange}
                axis={axis}
                indexesShift={indexesShift}
                scrollThreshold={window[WindowMap[axis]]}
              >
                {(item, index) => renderStripeItems(item, index + offset)}
              </ViewportList>
            </>
          );
        });
      } else if (!isEmpty(items) && items) {
        return (
          <ViewportList
            ref={listRef}
            viewportRef={scrollingRef}
            items={items}
            initialPrerender={INITIAL_STRIPE_ITEM_LOAD_SIZE}
            onViewportIndexesChange={onViewportIndexesChange}
            axis={axis}
            indexesShift={indexesShift}
            scrollThreshold={window[WindowMap[axis]]}
          >
            {(item, index) => renderStripeItems(item, index)}
          </ViewportList>
        );
      }
    };

    if (isEmpty(items)) {
      return <></>;
    }

    return (
      <FocusContext.Provider value={focusKey}>
        <div
          className={getClassName(`focusable-stripe-wrapper ${axis}`, { focused: hasFocusedChild })}
          ref={ref}
        >
          {rowTitle && <span className="focusable-stripe-title">{rowTitle}</span>}
          <div
            className={`focusable-stripe-content ${axis}`}
            ref={scrollingRef}
            onMouseMove={getMouseMoveScrollHandler(scrollingRef.current, axis, {
              x: STRIPE_ITEM_WIDTH,
              y: STRIPE_ITEM_HEIGHT
            })}
            onWheel={onWheel}
          >
            {startOffsetPadding && (
              <StripePadding padding={startOffsetPadding} direction={StartOffsetPaddingMap[axis]} />
            )}
            {renderList()}
            <StripePadding
              padding={getStripeEndPadding()}
              direction={ScrollDirectionPaddingMap[axis] as string}
            />
          </div>
        </div>
      </FocusContext.Provider>
    );
  }
);

export default React.memo(FocusableStripe);
