import { ClampContainerMixin, H1, H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
.message-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    
    .title, .time, .content, .cover{
        opacity: 0.4;
        transition: opacity ${variables.MOUNT_FADEIN_TIMEOUT}ms;
    }
    
     &.active {
        .title, .time, .content, .cover{
            opacity: 1;
        }
     }

    .cover {
        width: ${getScalablePixel(1280)}px;
        height: ${getScalablePixel(540)}px;
        mask-image: linear-gradient(to bottom, black 60%, transparent 95%);
    }

    .text-container {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 ${getScalablePixel(82)}px;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: ${getScalablePixel(494)}px;
            top: -${getScalablePixel(493)}px;
            left: 0;
        }
    }

    .title {
        ${ClampContainerMixin({ lines: 2 })}
        color: ${variables.color_grey_10};
        font-size: ${getScalablePixel(36)}px;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: inherit;
        transform: translateY(-100%);
    }
    .time {
        color: ${variables.color_grey_40};
        font-size: ${getScalablePixel(28)}px;
        font-weight: light;
        margin-top: ${getScalablePixel(12)}px;
    }
    .content {
        ${ClampContainerMixin({ lines: 10 })}
        color: ${variables.color_grey_20};
        font-size: ${getScalablePixel(24)}px;
        font-weight: normal;
        margin-top: ${getScalablePixel(32)}px;
        white-space: pre-line;
    }
}
.inbox-page-container {
    display: flex;
    flex-direction: row;
    background: ${variables.color_grey_90};


    .message-list {
        z-index: 1;

        .message-inbox-group-title {
            color: ${variables.color_grey_40};
            font-size: ${getScalablePixel(22)}px;
            font-weight: light;
            box-sizing: content-box;
            width: auto;
            height: fit-content;
            align-self: flex-start;
            flex: 1 0 auto;
            padding: 
                ${getScalablePixel(32)}px
                ${getScalablePixel(16)}px
                ${getScalablePixel(8)}px
                ${getScalablePixel(16)}px;

        }

        .message-list-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .no-messages-container {
                display: flex;
                flex: 0 1 70%;
                background: ${variables.color_grey_90};
                justify-content: space-around;
                align-items: center;
                flex-direction: column;

                .no-message-icon {
                    width: ${getScalablePixel(144)}px;
                }

                .no-messages-info-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                ${H2} {
                    font-size: ${getScalablePixel(32)}px;
                }
            }
        }

        .message-list-title {
            padding-top: ${getScalablePixel(101)}px;
            padding-left: ${getScalablePixel(32)}px;
            height: ${getScalablePixel(209)}px;
            box-sizing: border-box;
            ${H1} {
                font-size: ${getScalablePixel(42)}px;
            }
        }

        .inbox-message-item {
            max-width: none;
            width: 100%;
            background: ${variables.color_grey_70};
            margin: ${getScalablePixel(2)}px 0px;

            &.focused {
                background: ${variables.color_grey_60};
                transform: none;
            }
            &:after {
                display: none;
                content: "";
            }

            .message-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 
                    ${getScalablePixel(32)}px
                    ${getScalablePixel(32)}px
                    ${getScalablePixel(40)}px
                    ${getScalablePixel(32)}px;
                position: relative;
                transition: 
                    opacity ${variables.DEFAULT_ANIMATION_DURATION}ms, 
                    height ${variables.PAGE_FADE_DURATION}ms 
                        ${variables.DEFAULT_ANIMATION_DURATION}ms,
                    padding ${variables.PAGE_FADE_DURATION}ms 
                        ${variables.DEFAULT_ANIMATION_DURATION}ms;

                height: ${getScalablePixel(122)}px;

                &.has-delete-overlay {
                    & > *:not(.delete-overlay) {
                        opacity: 0.4;
                    }
                }

                &.remove {
                    opacity: 0;
                    height: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .message-item-close-icon {
                    flex: 0 0 auto;
                    opacity: 1;
                    &.hidden {
                        opacity: 0;
                    }
                }

                .delete-overlay {
                    display: flex;
                    justify-content: center;
                    align-items: center;                
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transform: translateX(100%);
                    transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
                    
                    
                    .button {
                        opacity: 1;
                        width: fit-content;
                        height: fit-content;
                    }

                    &.active {
                        transform: translateX(0);
                    }
                }

                .message-text-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    
                    & > * {
                        padding: ${getScalablePixel(4)}px 0;
                    }
                }

                .close-button {
                    width: 55px;
                    height: 55px;
                    border-radius: 55px;
                    border: solid 1px white;
                }

                .message-title {
                    ${ClampContainerMixin({ lines: 2 })}
                    color: ${variables.color_grey_20};
                    font-size: ${getScalablePixel(26)}px;
                    font-weight: bold;
                    &:before {
                        content: "";
                        display: inline-block;
                        width: ${getScalablePixel(17)}px;
                        height:  ${getScalablePixel(17)}px;
                        background-color: ${variables.border_color_detail};
                        margin-right: ${getScalablePixel(16)}px;
                        border-radius: ${getScalablePixel(32)}px;
                    }
                }
                .message-content {
                    ${ClampContainerMixin({ lines: 2 })}
                    color: ${variables.color_grey_30};
                    font-size: ${getScalablePixel(22)}px;
                }

                &.read {
                    .message-title {
                        color: ${variables.color_grey_40};
                        &:before {
                            content: none;
                        }
                    }
                    .message-content {
                        color: ${variables.color_grey_50};
                    }
                }
            }
        }

        width: ${getScalablePixel(640)}px;
        box-shadow: 
            ${getScalablePixel(-12)}px
            ${getScalablePixel(4)}px
            ${getScalablePixel(32)}px
            0
            rgba(0, 0, 0, 0.3);
        height: 100%;
    }
}
`;
