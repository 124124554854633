import Hls from '@mediahub-bg/hls.js';
import { removeUserDetails, resetVideo, setError } from 'common/actions';
import {
  ERROR_CODES_THAT_REQUIRE_LOGIN,
  ERROR_REDIRECT_MAP,
  REDIRECT_DEFAULT_ROUTE,
  STATUS_CODE_BAD_REQUEST
} from 'common/config/constants';
import { LiveChannels } from 'common/constants/data-types';
import { ChannelSetting, ProfileSettings, SettingsKey } from 'common/reducers/profileSettings';
import { findClosestNumber } from 'common/services/helpers';
import firebase from 'common/utils/firebase';
import GlobalStyle from 'common/utils/GlobalStyle';
import { findDeviceSettingsItem } from 'common/utils/helpers';
import { useRequiredData, useSignOut } from 'common/utils/hooks';
import _Hls, {
  AudioTracksUpdatedData,
  AudioTrackSwitchedData,
  BufferAppendedData,
  BufferAppendingData,
  ErrorData,
  Level,
  LevelSwitchedData,
  ManifestParsedData,
  MediaPlaylist,
  SubtitleTracksUpdatedData,
  SubtitleTrackSwitchData
} from 'hls.js';
import { findLastIndex, isEmpty, isNil, isNumber } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

export const parseError = (data: ErrorData): { status: number; subcode: number } | null => {
  try {
    let errorCode;
    const { status, response } = data.networkDetails;
    if (typeof response === 'string' && response !== '') {
      errorCode = (JSON.parse(response) || {}).subcode as number;
      return { status: status as number, subcode: errorCode };
    }
    return null;
  } catch (error) {
    console.info(error);
    return null;
  }
};

GlobalStyle.inject`
  .styled-video {
    width: 100%;
    height: 100%;
    outline: none;
  }
`;

export type THls = _Hls & {
  dvbAudioTrack: any;
  defaultDvbAudioLanguage?: string;
  dvbAudioTracks: any[];
};

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  channelId?: string;
  defaultDvbAudioLanguage?: string;
  dvbAudioTrack?: number;
  subtitleTrack?: number;
  startPosition?: number;
  startLevel?: number;
  autoPlay?: boolean;
  onSubtitlesUpdated?: (data: SubtitleTracksUpdatedData) => void;
  onSubtitleTrackSwitch?: (data: SubtitleTrackSwitchData) => void;
  onDvbAudioTracksUpdated?: (data: AudioTracksUpdatedData) => void;
  onDvbAudioTrackSwitch?: (data: AudioTrackSwitchedData) => void;
  onLevelSwitched?: (data: LevelSwitchedData) => void;
  onLevelsUpdated?: (data: Level[]) => void;
  onHlsError?: (data: ErrorData) => void;
  onBufferAppended?: (data: BufferAppendedData) => void;
  onBufferAppending?: (data: BufferAppendingData) => void;
  setError: any;
  resetVideo: VoidFunction;
  removeUserDetails: any;
  hash?: string;
  // Redux injected
  liveChannels: LiveChannels;
  profileSettings: ProfileSettings;
  //
}

export type VideoRefObject = {
  video?: HTMLVideoElement | null;
  hls?: THls | null;
};

const Video = React.forwardRef<VideoRefObject, VideoProps>(function Video(
  props: VideoProps,
  customRef
) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hls = useRef<THls | null>(null);
  const dvbAudioTracks = useRef<MediaPlaylist[]>([]);

  const signOut = useSignOut();
  const { refreshToken } = useRequiredData();

  React.useImperativeHandle(customRef, () => ({
    video: videoRef.current,
    hls: hls.current
  }));

  useEffect(() => {
    return destroyVideo;
  }, []);

  useEffect(() => {
    if (props.src && (!videoRef.current?.buffered.length || props.hash)) {
      initVideo();
    } else if (!props.src) {
      destroyVideo();
    }
  }, [props.src, props.hash]);

  // Handle update on tracks based on props
  useEffect(() => {
    if (!hls.current) {
      return;
    }
    const { subtitleTrack, dvbAudioTrack } = props;
    if (dvbAudioTrack) {
      hls.current.dvbAudioTrack = dvbAudioTrack;
    }
    if (subtitleTrack) {
      hls.current.subtitleTrack = subtitleTrack;
    }
  }, [props.dvbAudioTrack, props.subtitleTrack]);

  useEffect(() => {
    if (!isEmpty(hls.current?.subtitleTracks) && hls.current?.subtitleTracks) {
      switchToDefaultResource(hls.current?.subtitleTracks, 'subtitles', 'subtitleTrack');
    }
  }, [props.profileSettings?.react_tv_settings?.subtitles]);

  useEffect(() => {
    if (!isEmpty(dvbAudioTracks.current)) {
      switchToDefaultResource(dvbAudioTracks.current, 'audio', 'dvbAudioTrack');
    }
  }, [props.profileSettings?.react_tv_settings?.audio]);

  useEffect(() => {
    handleInitialVideoLevel(hls.current?.levels || []);
  }, [props.profileSettings?.react_tv_settings?.resolution]);

  const destroyVideo = () => {
    if (hls.current) {
      hls.current?.destroy();
    }
    if (videoRef.current) {
      videoRef.current.src = '';
      videoRef.current.load();
    }
  };

  const initVideo = useCallback(() => {
    if (!videoRef.current) {
      return;
    }
    if (Hls.isSupported()) {
      if (hls.current) {
        hls.current.destroy();
      }
      // create a new HLS object
      const defaultResource = getDefaultResource('audio');
      const defaultDvbAudioLanguage =
        props.defaultDvbAudioLanguage || isNumber(defaultResource) ? 'und' : defaultResource;
      const hlsOptions = {
        enableWorker: true,
        autoStartLoad: false,
        startPosition: isNil(props.startPosition) ? -1 : props.startPosition,
        capLevelOnFPSDrop: true,
        startLevel: isNil(props.startLevel) ? undefined : props.startLevel,
        defaultDvbAudioLanguage,
        // Reduce buffer size from default 60mb to 30mb for better handling on older TV's
        maxBufferSize: 30 * 1000 * 1000,
        skipBufferRangeStart: 2,
        // Workaround for this issue https://github.com/video-dev/hls.js/issues/6562
        // https://mediahub-platform.atlassian.net/browse/RTV-996
        ...(window.navigator.userAgent?.includes?.('Tizen 7.0')
          ? {
              // backBuffer length is called liveBackBufferLength in this version
              // And it's set to infinity by default
              // backBufferLength: -1,
              enableWorker: false
              // already 30mb, but keep this value if primary has changed
              // maxBufferSize: 30 * 1000 * 1000,
            }
          : {})
      };
      hls.current = new Hls(hlsOptions);

      // bind video element to this HLS object
      hls.current?.attachMedia(videoRef.current);

      firebase.getPlayerStartTrace()?.start();
      // video and hls.js are now bound together!
      hls.current?.loadSource(props.src as string);
      hls.current?.on(
        Hls.Events.SUBTITLE_TRACKS_UPDATED,
        (event: any, data: SubtitleTracksUpdatedData) => {
          props.onSubtitlesUpdated && props.onSubtitlesUpdated(data);
          if (videoRef.current) {
            // Enable all tracks
            for (let i = 0; i < videoRef.current.textTracks.length; i++) {
              const track = videoRef.current.textTracks[i];
              if (track.mode === 'disabled') track.mode = 'hidden';
            }
          }
          switchToDefaultResource(data.subtitleTracks, 'subtitles', 'subtitleTrack');
        }
      );
      hls.current?.on(
        Hls.Events.DVB_AUDIO_TRACKS_UPDATED,
        (event: any, data: AudioTracksUpdatedData) => {
          dvbAudioTracks.current = data.audioTracks;
          props.onDvbAudioTracksUpdated && props.onDvbAudioTracksUpdated(data);
          switchToDefaultResource(data.audioTracks || [], 'audio', 'dvbAudioTrack');
        }
      );

      hls.current?.on(
        Hls.Events.DVB_AUDIO_TRACK_SWITCHED,
        (_event: any, data: AudioTrackSwitchedData) => {
          props.onDvbAudioTrackSwitch && props.onDvbAudioTrackSwitch(data);
        }
      );

      hls.current?.on(
        Hls.Events.SUBTITLE_TRACK_SWITCH,
        (_event: any, data: SubtitleTrackSwitchData) => {
          props.onSubtitleTrackSwitch && props.onSubtitleTrackSwitch(data);
        }
      );

      hls.current?.on(Hls.Events.LEVEL_SWITCHED, (_event: any, data: LevelSwitchedData) => {
        props.onLevelSwitched && props.onLevelSwitched(data);
      });

      hls.current?.on(Hls.Events.BUFFER_APPENDED, (_event: any, data: BufferAppendedData) => {
        props.onBufferAppended && props.onBufferAppended(data);
      });

      hls.current?.on(Hls.Events.BUFFER_APPENDING, (_event: any, data: BufferAppendingData) => {
        props.onBufferAppending && props.onBufferAppending(data);
      });

      hls.current?.on(Hls.Events.MANIFEST_PARSED, (event: any, data: ManifestParsedData) => {
        props.onLevelsUpdated && props.onLevelsUpdated(data.levels);
        hls.current?.startLoad(hlsOptions.startPosition);
        handleInitialVideoLevel(data.levels);
      });
      hls.current?.on(Hls.Events.ERROR, async (_event: any, data: ErrorData) => {
        switch (data.details) {
          case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
            break;
          case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
            console.warn('Timeout while loading manifest');
            break;
          case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
            console.warn('Error while parsing manifest:' + data.reason);
            break;
          case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
            console.warn('Error while loading level playlist');
            break;
          case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
            console.warn('Timeout while loading level playlist');
            break;
          case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
            console.warn('Error while trying to switch to level ' + data.level);
            break;
          case Hls.ErrorDetails.FRAG_LOAD_ERROR:
            console.warn('Error while loading fragment ' + data.frag?.url);
            break;
          case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
            console.warn('Timeout while loading fragment ' + data.frag?.url);
            break;
          case Hls.ErrorDetails.FRAG_LOOP_LOADING_ERROR:
            console.warn('Fragment-loop loading error');
            break;
          case Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
            console.warn('Decrypting error:' + data.reason);
            break;
          case Hls.ErrorDetails.FRAG_PARSING_ERROR:
            console.warn('Parsing error:' + data.reason);
            break;
          case Hls.ErrorDetails.KEY_LOAD_ERROR:
            console.warn('Error while loading key ' + data.frag?.decryptdata?.uri);
            break;
          case Hls.ErrorDetails.KEY_LOAD_TIMEOUT:
            console.warn('Timeout while loading key ' + data.frag?.decryptdata?.uri);
            break;
          case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
            console.warn('Buffer append error');
            break;
          case Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR:
            console.warn('Buffer add codec error for ' + data.mimeType + ':' + data.err?.message);
            break;
          case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
            console.warn('Buffer appending error');
            break;
          case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            console.warn('Buffer stalled error');
            break;
          default:
            break;
        }

        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.warn('Fatal media error encountered, trying to recover');
              break;
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.warn('Fatal network error', data);
              await handleHlsNetworkError({ ...data });
              break;
            default:
              // cannot recover
              console.warn('An unrecoverable error occurred');
              break;
          }
        }
        props.onHlsError && props.onHlsError(data);
      });
    }

    // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
    // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
    // This is using the built-in support of the plain video element, without using hls.js.
    else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      console.warn('Playing video on native:', props);
      videoRef.current.src = props.src as string;
      videoRef.current.addEventListener('canplay', playVideoOnNative);
    } else {
      console.warn('Could not play video', props);
    }
  }, [
    hls.current,
    props.src,
    videoRef.current,
    props.defaultDvbAudioLanguage,
    props.startLevel,
    props.startPosition,
    props.profileSettings,
    props.onSubtitlesUpdated,
    props.onSubtitleTrackSwitch,
    props.onDvbAudioTrackSwitch,
    props.onDvbAudioTracksUpdated,
    props.onHlsError,
    props.onBufferAppended,
    props.onBufferAppending
  ]);

  const handleHlsNetworkError = useCallback(
    async (data: ErrorData) => {
      const error = parseError(data);

      // Handling if it's a custom error:
      // SignOuts the user
      // and based on the `subcode` property of response
      // redirects to Login or to TooManyUsers page
      if (error?.status === STATUS_CODE_BAD_REQUEST && error?.subcode) {
        // Try to refresh token first
        const success = await refreshToken();
        // Exit function if refresh token was successful
        if (success) return;
        //
        if (ERROR_CODES_THAT_REQUIRE_LOGIN.includes(error.subcode)) {
          signOut();
          props.removeUserDetails();
        }
        const redirect = ERROR_CODES_THAT_REQUIRE_LOGIN.includes(error.subcode)
          ? REDIRECT_DEFAULT_ROUTE
          : ERROR_REDIRECT_MAP[error.subcode];
        props.setError(error.subcode, redirect);
        props.resetVideo();
      } else {
        // If it is not a custom error
        // shows the loader and
        // TODO add message A network error occurred, try to recover
        hls.current?.startLoad();
      }
    },
    [props.removeUserDetails, hls.current]
  );

  const playVideoOnNative = useCallback(() => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.removeEventListener('canplay', playVideoOnNative);

    if (isNumber(props.startPosition) && props.startPosition > 0) {
      videoRef.current.currentTime = props.startPosition;
    }

    videoRef.current.play();
  }, [videoRef.current]);

  const getResourceConfiguration = useCallback(
    (resourceKey: SettingsKey) => {
      try {
        if (!props.channelId) {
          throw new Error('No channel id');
        }
        const { liveChannels, profileSettings } = props;
        const { channelId } = props;
        const { channels } = profileSettings.react_tv_settings;
        const defaultResource = liveChannels[channelId][resourceKey];
        const individualResource = findDeviceSettingsItem(channels, channelId)?.[resourceKey];
        const globalResource = profileSettings.react_tv_settings[resourceKey];
        return { globalResource, individualResource, defaultResource };
      } catch (error) {
        console.warn(error);
        return { globalResource: 'und', individualResource: 'und', defaultResource: 'und' };
      }
    },
    [props.liveChannels, props.profileSettings, props.channelId]
  );

  const getDefaultResource = useCallback(
    (resourceKey: SettingsKey) => {
      const { globalResource, individualResource, defaultResource } =
        getResourceConfiguration(resourceKey);
      return individualResource || globalResource || defaultResource || 'und';
    },
    [props.liveChannels, props.profileSettings, props.channelId]
  );

  const switchToDefaultResource = (
    resourceList: any[],
    resourceKey: SettingsKey,
    fieldToUpdate: 'subtitleTrack' | 'dvbAudioTrack'
  ) => {
    try {
      if (
        isEmpty(resourceList) ||
        (hls.current && isNil(hls.current[fieldToUpdate])) ||
        !hls.current ||
        !props.channelId
      ) {
        return;
      }
      const { globalResource, individualResource, defaultResource } =
        getResourceConfiguration(resourceKey);
      const newResource = getDefaultResource(resourceKey);

      const newResourceIndex =
        newResource === -1
          ? -1
          : findLastIndex(resourceList, ({ name: resourceName }) => {
              return (
                resourceName === individualResource ||
                resourceName === globalResource ||
                resourceName === defaultResource
              );
            });
      const resourceId = newResourceIndex === -1 ? -1 : resourceList[newResourceIndex].id;
      if (hls.current[fieldToUpdate] !== resourceId) {
        hls.current[fieldToUpdate] = resourceId;
      }
    } catch (error) {
      console.warn(error);
      if (hls.current) {
        hls.current[fieldToUpdate] = resourceList[0].id;
      }
    }
  };

  const handleInitialVideoLevel = useCallback(
    (levels: Level[]) => {
      const { profileSettings } = props;
      if (!levels || !hls.current) {
        return;
      }
      try {
        const channelItem = findDeviceSettingsItem(
          profileSettings.react_tv_settings.channels,
          props.channelId
        ) as ChannelSetting;
        const individualSettingResolution = channelItem && channelItem.resolution;
        const globalSettingResolution = profileSettings.react_tv_settings.resolution;
        const resolution = individualSettingResolution || globalSettingResolution || -1;
        if (resolution === -1) {
          hls.current.nextLevel = -1;
          return;
        }
        const resolutionNumber = Number(resolution);
        const levelHeights = levels.map((level) => level.height);
        const targetResolution = findClosestNumber(levelHeights, resolutionNumber);
        const level = findLastIndex(levelHeights, (height) => targetResolution >= height);
        hls.current.nextLevel = level;
      } catch (error) {
        console.warn(error);
        hls.current.nextLevel = -1;
      }
    },
    [props.profileSettings, props.channelId, hls.current]
  );

  const getVideoRef = useCallback((node: HTMLVideoElement) => {
    if (node) {
      videoRef.current = node;
    }
  }, []);

  const onCanPlay = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      firebase.getPlayerStartTrace()?.stop();
      props.onCanPlay && props.onCanPlay(event);
    },
    [props.onCanPlay]
  );

  const videoProps = useMemo(() => {
    const {
      onSubtitlesUpdated,
      onSubtitleTrackSwitch,
      onDvbAudioTracksUpdated,
      onDvbAudioTrackSwitch,
      onLevelSwitched,
      onLevelsUpdated,
      onHlsError,
      onBufferAppending,
      setError,
      removeUserDetails,
      profileSettings,
      liveChannels,
      onBufferAppended,
      startPosition,
      channelId,
      ...rest
    } = props;
    return rest;
  }, [props]);

  return (
    <video
      {...videoProps}
      className={`styled-video ${videoProps.className || ''}`}
      onCanPlay={onCanPlay}
      ref={getVideoRef}
      autoPlay={isNil(props.autoPlay) ? true : props.autoPlay}
    />
  );
});

const mapStateToProps = ({ liveChannels, profileSettings }: any) => ({
  liveChannels,
  profileSettings
});

export default React.memo(
  connect(
    mapStateToProps,
    {
      removeUserDetails,
      setError,
      resetVideo
    },
    null,
    { forwardRef: true }
  )(Video)
);
