import { translationPacket } from 'common/actions';
import { Column, Row } from 'common/components/common/style';
import { MetadataSeparator } from 'common/components/metadata-strip/MetadataStrip.styles';
import { H3, P1 } from 'common/components/Typography';
import { Epg } from 'common/constants/data-types';
import { formatTime } from 'common/services/helpers';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { EpgShowInfoContainer, EpgShowType } from './Controls.styles';
import { getEpisodeInfoText } from './utils';

interface EpgShowInfoProps {
  // Redux injected
  i18n: typeof translationPacket;
  //
  type: EpgShowType;
  start: number;
  stop: number;
  title: string;
  major_category?: Epg['major_category'];
  minor_categories?: Epg['minor_categories'];
  numeration?: Epg['numeration'];
}

function EpgShowInfo(props: EpgShowInfoProps) {
  const inlineStyle = useMemo<CSSProperties>(
    () => ({
      flexWrap: 'wrap',
      textAlign: props.type === 'next' ? 'end' : 'start'
    }),
    [props.type]
  );

  const renderSubtitle = useCallback(() => {
    const before = props.type === 'prev' ? props.i18n.common.before : undefined;
    const upcoming = props.type === 'next' ? props.i18n.common.upcoming : undefined;
    const startTime = formatTime(props.start);
    const endTime = formatTime(props.stop);
    return (
      <Row>
        <P1>
          {before}
          {before && <MetadataSeparator />}
          {`${startTime} - ${endTime}`}
          {upcoming && <MetadataSeparator />}
          {upcoming}
        </P1>
      </Row>
    );
  }, [props.start, props.stop, props.type, props.i18n]);

  const renderEpisodeInfo = () => {
    const episodeInfoText = getEpisodeInfoText(props.numeration);
    return (
      episodeInfoText && (
        <>
          <P1>{episodeInfoText}</P1>
          <MetadataSeparator />
        </>
      )
    );
  };

  const renderMinorCategories = useCallback(() => {
    return (
      props.minor_categories &&
      props.minor_categories.map((category) => {
        return (
          <React.Fragment key={category}>
            <MetadataSeparator />
            <P1>{category}</P1>
          </React.Fragment>
        );
      })
    );
  }, [props.major_category, props.minor_categories]);

  return (
    <Column>
      {
        <EpgShowInfoContainer type={props.type}>
          {renderSubtitle()}
          <H3 style={{ textAlign: props.type === 'prev' ? 'start' : 'end' }}>{props.title}</H3>
          <Row
            justifyContent={props.type === 'prev' ? 'flex-start' : 'flex-end'}
            style={inlineStyle}
          >
            {renderEpisodeInfo()}
            <P1>{props.major_category}</P1>
            {renderMinorCategories()}
          </Row>
        </EpgShowInfoContainer>
      }
    </Column>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(EpgShowInfo));
