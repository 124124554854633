import { Translation } from 'common/actions';
import { toHHMMSS } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { useIdleTimer } from 'common/utils/hooks/useIdleTimer';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import './IdleTimerOverlay.styles';

interface IdleTimerProps {
  //Redux
  i18n: Translation;
  //
  onBack?: VoidFunction;
  disabled?: boolean;
}

function IdleTimerOverlay(props: IdleTimerProps) {
  const { idleMessageShown, remainingTime } = useIdleTimer({
    onIdleTimerEnd: props.onBack && props.onBack,
    disabled: props.disabled
  });

  const remainingTimeFormatted = useMemo(() => toHHMMSS(remainingTime), [remainingTime]);

  if (!idleMessageShown || isEmpty(props.i18n) || props.disabled) return null;

  return (
    <div className="idle-message-wrapper">
      <div className="idle-message-gradient-mask"></div>
      <div className="idle-message-inner">
        <div className="idle-message-content">
          <div className="idle-message-title">{props.i18n.idleTimer.stillWatching}</div>
          <div className="idle-message-subtitle">{props.i18n.idleTimer.videoShutdown}</div>
        </div>
        <div className="idle-message-timer">
          <span className="idle-message-text">{props.i18n.idleTimer.continue}</span>
          <span className="idle-message-time-remaining">{remainingTimeFormatted}</span>
          <div
            className={getClassName('idle-message-overlay-bg', { active: idleMessageShown })}
          ></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(IdleTimerOverlay));
