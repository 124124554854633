import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
.delete-account-popup-wrapper {
    display: flex;
    flex-direction: column;
    
    .title {
        font-size: ${getScalablePixel(50)}px;
        color: ${variables.color_grey_30};
        text-align: center;
        padding-bottom: ${getScalablePixel(42)}px;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        & > * {
            flex: 0 1 40%;
        }
        .column {
            display: flex;
            flex-direction: column;
            text-align: end;
            flex: 0 1 60%;

            .description {
                font-size: ${getScalablePixel(28)}px;
                color: ${variables.color_grey_40};
                height: 100%;
            }
        }
    }
}
`;
