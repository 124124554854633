import {
  setFavoriteChannels,
  setProfileSetting,
  updateLastWatchedEpgs,
  updateLiveChannels,
  updateMostWatchedEpgs,
  updateSubcategoryEpgs
} from 'common/actions';
import variables from 'common/config/variables';
import { LiveChannels } from 'common/constants/data-types';
import { ChannelSettings } from 'common/reducers/profileSettings';
import { generateChannelSettings } from 'common/utils/profile-settings';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkActionDispatch } from 'redux-thunk';

interface Props {
  liveChannels: LiveChannels;
  profileSettingsChannels: ChannelSettings;
  savedProfileSettings?: boolean;
  setFavoriteChannels: ThunkActionDispatch<typeof setFavoriteChannels>;
  updateLiveChannels: ThunkActionDispatch<typeof updateLiveChannels>;
  setProfileSetting: ThunkActionDispatch<typeof setProfileSetting>;
  updateLastWatchedEpgs: ThunkActionDispatch<typeof updateLastWatchedEpgs>;
  updateMostWatchedEpgs: ThunkActionDispatch<typeof updateMostWatchedEpgs>;
  updateSubcategoryEpgs: ThunkActionDispatch<typeof updateSubcategoryEpgs>;
}
function ProfileSettingsProvider(props: React.PropsWithChildren<Props>) {
  const hasLiveChannels = useMemo(() => !isEmpty(props.liveChannels), [props.liveChannels]);
  const positionKey = useMemo(
    () => (variables.REORDER_CHANNELS_ENABLED ? 'position' : 'favoritePosition'),
    []
  );

  // Listen for favorite channels changes and update favorite channels list
  // only after the data has been saved to the server
  useEffect(() => {
    if (!isEmpty(props.profileSettingsChannels) && props.savedProfileSettings && hasLiveChannels) {
      const favoriteIds = Object.keys(props.profileSettingsChannels)
        .filter((id) => props.profileSettingsChannels[id].favorite)
        .sort((a, b) => {
          const channelSettingsA = props.profileSettingsChannels?.[a]?.[positionKey];
          const channelSettingsB = props.profileSettingsChannels?.[b]?.[positionKey];
          if (channelSettingsA && channelSettingsB) {
            return channelSettingsA - channelSettingsB;
          }
          return 0;
        });
      props.setFavoriteChannels(favoriteIds);
    }
  }, [props.liveChannels, props.profileSettingsChannels, props.savedProfileSettings]);
  //

  // Listen for changes in profile settings to dispatch actions in store
  // Dispatch actions only after the profile settings have been saved to the server
  useEffect(() => {
    if (!isEmpty(props.profileSettingsChannels) && props.savedProfileSettings) {
      props.updateLiveChannels();
      props.updateLastWatchedEpgs();
      props.updateMostWatchedEpgs();
      props.updateSubcategoryEpgs();
    }
  }, [props.profileSettingsChannels, props.savedProfileSettings]);
  //

  // Check if liveChannels are different from the saved profile settings
  useEffect(() => {
    if (!isEmpty(props.profileSettingsChannels) && hasLiveChannels) {
      const settingsChannelsKeys = Object.keys(props.profileSettingsChannels);
      const liveChannelsKeys = Object.keys(props.liveChannels);
      const isDifferent =
        settingsChannelsKeys.length !== liveChannelsKeys.length ||
        settingsChannelsKeys.some((key) => !liveChannelsKeys.includes(key)) ||
        liveChannelsKeys.some((key) => !settingsChannelsKeys.includes(key));
      if (isDifferent) {
        props.setProfileSetting(
          'channels',
          generateChannelSettings(props.profileSettingsChannels, props.liveChannels, isDifferent)
        );
      }
    }
  }, [props.liveChannels, props.profileSettingsChannels]);
  //

  return <>{props.children}</>;
}

const mapStateToProps = ({ profileSettings, liveChannels }: StoreState) => ({
  profileSettingsChannels: profileSettings?.react_tv_settings?.channels,
  savedProfileSettings: profileSettings?.react_tv_settings?.saved,
  liveChannels
});

export default React.memo(
  connect(mapStateToProps, {
    setFavoriteChannels,
    updateLiveChannels,
    setProfileSetting,
    updateLastWatchedEpgs,
    updateMostWatchedEpgs,
    updateSubcategoryEpgs
  })(ProfileSettingsProvider)
);
