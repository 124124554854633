import { CLEAR_ERROR, SET_ERROR } from 'common/constants/action-types';

export const setError = (code: number, redirect?: string) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_ERROR,
      payload: { code, redirect },
    });
  };
};

export const clearError = () => {
  return (dispatch: any) => {
    dispatch({
      type: CLEAR_ERROR,
      payload: {},
    });
  };
};
