import MetadataStrip, { MetadataStripProps } from 'common/components/metadata-strip/MetadataStrip';
import { Epg } from 'common/constants/data-types';
import React from 'react';
import { DescriptionContainer, DescriptionMainInfo, DescriptionTitle } from './Description.styles';
import DescriptionDay from './DescriptionDay';
import DescriptionStaff from './DescriptionStaff';
import DescriptionSubtitle from './DescriptionSubtitle';

import { getLineClamps } from './utils';

type DescriptionProps = {
  numeration: Epg['numeration'];
  originaltitle: Epg['originaltitle'];
  title: Epg['title'];
  start: Epg['start'];
  stop: Epg['stop'];
  shorttext: Epg['shorttext'];
  fulltext: Epg['fulltext'];
  persons: Epg['persons'];
  actors: Epg['actors'];
  year?: Epg['year'];
  country?: Epg['country'];
  major_category?: Epg['major_category'];
  minor_categories?: Epg['minor_categories'];
  duration?: Epg['duration'];
  rating?: Epg['rating'];
  resolution?: string;
};

function Description({
  numeration,
  originaltitle,
  title,
  start,
  stop,
  shorttext,
  fulltext,
  persons,
  actors,
  ...rest
}: DescriptionProps) {
  const mainInfo = shorttext || fulltext || null;

  const { linesTitle, linesMainInfo, ...restLines } = getLineClamps({
    title,
    mainInfo,
    persons,
    actors
  });

  return (
    <DescriptionContainer>
      <DescriptionSubtitle numeration={numeration} originaltitle={originaltitle} />
      <DescriptionTitle lines={linesTitle}>{title}</DescriptionTitle>
      <MetadataStrip {...(rest as MetadataStripProps)} />
      <DescriptionDay start={start} stop={stop} />
      <DescriptionMainInfo lines={linesMainInfo}>{mainInfo}</DescriptionMainInfo>
      <DescriptionStaff persons={persons} actors={actors} {...restLines} />
    </DescriptionContainer>
  );
}

export default React.memo(Description);
