import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css, keyframes } from 'styled-components';
const { background_color_dark } = variables;

const _fadeIn = keyframes`
  from {
    opacity: 0.01;
  }
  to {
    opacity: 1;
  }
`;

export const DefaultFadeIn = css`
  animation: ${_fadeIn} ${variables.MOUNT_FADEIN_TIMEOUT}ms ease-in-out;
`;

interface FadeInOptions {
  duration?: number;
  delay?: number;
}
export const FadeIn = ({
  duration = variables.MOUNT_FADEIN_TIMEOUT,
  delay = 0
}: FadeInOptions) => css`
  animation: ${_fadeIn} ${duration}ms ${delay}ms ease-in;
`;

export const PlayerMaskMixin = css`
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* Fixes browser rendering of outside border */
    outline: ${getScalablePixel(1)}px solid ${background_color_dark};
    z-index: 1;
    background: linear-gradient(
        90deg,
        ${background_color_dark} 0%,
        ${hexToRGB(background_color_dark, 0.5)} 25%,
        rgba(0, 0, 0, 0) 50%
      ),
      linear-gradient(
        45deg,
        ${background_color_dark} 0%,
        ${hexToRGB(background_color_dark, 0.5)} 25%,
        rgba(0, 0, 0, 0) 50%
      ),
      linear-gradient(
        0deg,
        ${background_color_dark} 0%,
        ${hexToRGB(background_color_dark, 0.5)} 25%,
        rgba(0, 0, 0, 0) 50%
      );
  }
`;

export const ViewContainer = styled.div`
  background-color: ${background_color_dark};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

GlobalStyle.inject`
   ::-webkit-scrollbar {
     display: none;
   }
   background-color: ${background_color_dark};
   overflow: hidden;

  .flex {
    display: flex;

    &.row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
 `;
