import { translationPacket } from 'common/actions';
import { HAS_ACCESS } from 'common/config/constants';
import { Epg, LiveChannels } from 'common/constants/data-types';
import {
  ChannelSettings,
  INITIAL_STATE,
  LOCK_PERIODS,
  ProfileSettings,
  Settings
} from 'common/reducers/profileSettings';
import { minutesToMS } from 'common/services/helpers';
import { checkLockStatusForChannel, checkVisibleStatusForChannel } from './helpers';

const isProfileSettings = (
  object: ChannelSettings | ProfileSettings
): object is ProfileSettings => {
  return 'react_tv_settings' in object;
};
export function generateChannelSettings(
  settings: ChannelSettings | ProfileSettings,
  liveChannels: LiveChannels,
  regeneragePosition = false
) {
  const channels = isProfileSettings(settings) ? settings.react_tv_settings?.channels : settings;
  return Object.keys(liveChannels).reduce((acc, channelId) => {
    const channelSettings = channels?.[channelId] || {};
    const liveChannel = liveChannels[channelId];

    const position = regeneragePosition
      ? liveChannel.originalPosition
      : channelSettings.position || liveChannel.originalPosition;

    acc[channelId] = {
      ...channelSettings,
      favoritePosition: channelSettings.favoritePosition || 0,
      visible: channelSettings.visible || true,
      audio: channelSettings.audio || null,
      resolution: channelSettings.resolution || null,
      subtitles: channelSettings.subtitles || null,
      position,
      locked: channelSettings.locked || liveChannel.locked || false
    };
    return acc;
  }, {});
}

export function generateDefaultSettings(liveChannels: LiveChannels): Settings {
  const channelSettings = Object.keys(liveChannels).reduce((acc, channelId) => {
    const liveChannel = liveChannels[channelId];
    acc[channelId] = {
      favoritePosition: 0,
      favorite: false,
      visible: true,
      audio: null,
      resolution: null,
      subtitles: null,
      position: liveChannel.originalPosition,
      locked: liveChannel.locked || false
    };
    return acc;
  }, {});
  return {
    ...INITIAL_STATE.react_tv_settings,
    lockPeriod: minutesToMS(5),
    channels: { ...channelSettings }
  };
}

export const propagateChannelAccessToEpg = (epg: Epg, profileSettings: ProfileSettings) => ({
  ...epg,
  visible:
    epg.access === HAS_ACCESS &&
    checkVisibleStatusForChannel(profileSettings, epg.chanId) &&
    !checkLockStatusForChannel(profileSettings, epg.chanId)
});

export function getLockPeriods(i18n: typeof translationPacket) {
  const { minutes, immediately, untilRestart } = i18n.mainSettings;
  const minutesLabel = minutes.toLowerCase();

  return [
    {
      label: immediately,
      value: LOCK_PERIODS.ALWAYS_LOCK
    },
    {
      label: `5 ${minutesLabel}`,
      value: minutesToMS(5)
    },
    {
      label: `10 ${minutesLabel}`,
      value: minutesToMS(10)
    },
    {
      label: `20 ${minutesLabel}`,
      value: minutesToMS(20)
    },
    {
      label: `30 ${minutesLabel}`,
      value: minutesToMS(30)
    },
    {
      label: `60 ${minutesLabel}`,
      value: minutesToMS(60)
    },
    {
      label: untilRestart,
      value: LOCK_PERIODS.SESSION_LOCK
    }
  ];
}
