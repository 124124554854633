import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import {
  getLastWatchedEpgs,
  getListsForManySubcategories,
  getMostWatchedEpgs,
  playVideo,
  setVideoOptions,
  translationPacket
} from 'common/actions';
import { Card, TextCard } from 'common/components/cards';
import { Stripe } from 'common/components/stripe';
import { ViewWithDetails } from 'common/components/view-with-details';
import { getCategoryBackground } from 'common/config/archive-categories-bg';
import { ROUTE_ARCHIVE } from 'common/config/constants';
import variables from 'common/config/variables';
import {
  Category,
  Epg,
  IViewWithDetailsStripeItem,
  LiveChannels,
  RecordedStore
} from 'common/constants/data-types';
import protect from 'common/HoC/protect';
import { getChannelLogo } from 'common/services/helpers';
import { usePageNavigation } from 'common/utils/hooks';
import { PromiseDispatch } from 'index';
import { connect } from 'react-redux';

interface StateProps {
  i18n: typeof translationPacket;
  liveChannels: LiveChannels;
  recorded: RecordedStore;
  lastWatched: Epg[];
  mostWatched: Epg[];
  logos: any;
}

interface ArchiveProps extends StateProps {
  getListsForManySubcategories: (
    category: Category,
    count?: number,
    offset?: number
  ) => Promise<any>;
  setVideoOptions: any;
  playVideo: (currentepg: Epg, options?: any) => void;
  getMostWatchedEpgs: PromiseDispatch;
  getLastWatchedEpgs: PromiseDispatch;
}

function Archive({
  i18n,
  recorded,
  lastWatched,
  mostWatched,
  liveChannels,
  playVideo,
  setVideoOptions,
  getMostWatchedEpgs,
  getLastWatchedEpgs,
  logos
}: ArchiveProps) {
  const navigate = usePageNavigation();

  useEffect(() => {
    const id = setTimeout(() => {
      getLastWatchedEpgs();
      getMostWatchedEpgs();
    }, variables.THROTTLE_TIMEOUT);
    return () => {
      clearTimeout(id);
    };
  }, []);

  const openPlayer = useCallback((asset: IViewWithDetailsStripeItem) => {
    playVideo(asset);
    setVideoOptions({
      isFullscreen: true
    });
  }, []);

  const renderEpgItem = useCallback(
    (item: Epg) => (
      <Card
        key={`archive-card-${item.eventid}`}
        epg={item}
        logo={getChannelLogo(item.chan_id, logos)}
      />
    ),
    [logos]
  );

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset.chan_id) {
        return {
          epg: asset,
          // Extract resolution from liveChannels
          resolution: liveChannels[asset.chan_id]?.resolution
        };
      }
      return undefined;
    },
    [liveChannels]
  );

  const renderCategoryCard = useCallback(
    (item: Category, idx: number) => (
      <TextCard
        key={`archive-category-card-${idx}`}
        title={item.name}
        bgUrl={getCategoryBackground(idx)}
      />
    ),
    []
  );

  const openCategory = useCallback((category: Category) => {
    navigate(`${ROUTE_ARCHIVE}/${category.id}`);
  }, []);

  const getFocusKeyOnCategories = useCallback(
    (item: Category, index: number) => `archive-category-${item.position}-${index}`,
    []
  );

  const renderCategories = useCallback(() => {
    const { categories } = recorded;

    if (isEmpty(categories)) {
      return null;
    }
    const categoriesList = Object.values(categories).map((e) => ({
      ...e,
      id: e.position.toString()
    }));

    return (
      <Stripe
        key="archive-category-stripe"
        items={categoriesList}
        renderItem={renderCategoryCard}
        onStripeItemPress={openCategory}
        getFocusKey={getFocusKeyOnCategories}
      />
    );
  }, [recorded.categories]);

  const renderMostWatched = useCallback(() => {
    if (isEmpty(mostWatched)) {
      return null;
    }

    return (
      <Stripe
        key="most-watched-stripe"
        items={mostWatched}
        renderItem={renderEpgItem}
        title={i18n.common.mostWatched}
        onStripeItemPress={openPlayer}
      />
    );
  }, [mostWatched]);

  const renderLastWatched = useCallback(() => {
    if (isEmpty(lastWatched)) {
      return null;
    }

    return (
      <Stripe
        key="last-watched-stripe"
        items={lastWatched}
        renderItem={renderEpgItem}
        title={i18n.channelList.lastWatched}
        onStripeItemPress={openPlayer}
      />
    );
  }, [lastWatched]);

  return (
    <ViewWithDetails focusKey={location.pathname} getSelectedAssetData={getSelectedAssetData}>
      {[renderCategories(), renderMostWatched(), renderLastWatched()]}
    </ViewWithDetails>
  );
}

const mapStateToProps = ({
  i18n,
  recorded,
  lastWatched,
  mostWatched,
  liveChannels,
  logos
}: StateProps) => ({
  i18n,
  recorded,
  lastWatched: lastWatched?.filter((e) => e.visible),
  mostWatched: mostWatched?.filter((e) => e.visible),
  liveChannels,
  logos
});
export default React.memo(
  protect(
    connect(mapStateToProps, {
      getListsForManySubcategories,
      getLastWatchedEpgs,
      getMostWatchedEpgs,
      setVideoOptions,
      playVideo
    })(Archive)
  )
);
