import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { changePassword, translationPacket } from 'common/actions';
import { DefaultButton } from 'common/components/button';
import { Column, Row } from 'common/components/common/style';
import { Input } from 'common/components/input';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { usePopup } from 'common/contexts/popup';
import { getUserObject } from 'common/services/helpers';
import { getKey, getScalablePixel } from 'common/utils';
import { StoreState } from 'index';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Footer, FormContainer, LogoContainer } from './ChangePasswordPopup.styles';

interface ChangePasswordProps {
  i18n: typeof translationPacket;
  changePassword: any;
}

export interface ChangePasswordFields {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

function ChangePasswordPopup(props: ChangePasswordProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { closePopup } = usePopup();
  const initialFormFields = useMemo(() => {
    const fields = getUserObject() as ChangePasswordFields;
    return {
      oldPassword: fields?.oldPassword || '',
      newPassword: fields?.newPassword || '',
      repeatNewPassword: fields?.repeatNewPassword || ''
    };
  }, []);
  const [form, setForm] = useState<ChangePasswordFields>(initialFormFields);
  const [formErrors, setFormErrors] = useState<ChangePasswordFields>({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  });

  const { focusKey, ref } = useFocusable({
    isFocusBoundary: true,
    preferredChildFocusKey: getKey('input', 'email'),
    autoRestoreFocus: true,
    trackChildren: true,
    forceFocus: false
  });

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    // Clear form error when text for the field is changed
    setFormErrors((errors) => ({ ...errors, [e.target.name]: '' }));
    //
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateOldPassword = () => {
    setFormErrors({ ...formErrors, oldPassword: '' });
    if (!form.oldPassword) {
      setFormErrors({ ...formErrors, oldPassword: props.i18n.authentication.passwordEmpty });
    }
  };

  const validateNewPassword = () => {
    setFormErrors({ ...formErrors, newPassword: '' });
    if (!form.newPassword) {
      setFormErrors({ ...formErrors, newPassword: props.i18n.authentication.emailEmpty });
      return;
    }
  };

  const validateRepeatNewPassword = () => {
    setFormErrors({ ...formErrors, repeatNewPassword: '' });
    if (form.repeatNewPassword !== form.newPassword) {
      setFormErrors({
        ...formErrors,
        repeatNewPassword: props.i18n.authentication.passwordsDoNotMatch
      });
      return;
    }
  };

  const handleSubmit = async () => {
    validateOldPassword();
    validateNewPassword();
    validateRepeatNewPassword();
    closeSnackbar();
    if (!formErrors.oldPassword && !formErrors.newPassword) {
      try {
        const { oldPassword, newPassword, repeatNewPassword } = form;

        await props.changePassword(oldPassword, newPassword, repeatNewPassword);
        enqueueSnackbar(props.i18n.authentication.passwordChangeSuccess);
        closePopup('CHANGE-PASSWORD-MODAL');
      } catch (error: any) {
        const errorMessage = error?.error_info?.description || error?.message || '';
        //@ts-ignore
        enqueueSnackbar(errorMessage, { title: props.i18n.snackbar.alert });
      }
    }
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <Container>
        <FormContainer ref={ref}>
          <LogoContainer>
            <ProductLogo width={getScalablePixel(180)} type="full" />
          </LogoContainer>

          <Row className="content">
            <Column className="container">
              <Column className="input-fields">
                <Input
                  type={'oldPassword'}
                  name={'oldPassword'}
                  placeholder={props.i18n.authentication.oldPassword}
                  focusKey={getKey('input', 'oldPassword')}
                  onChangeText={onChangeText}
                  value={form.oldPassword}
                  error={formErrors.oldPassword}
                  onBlur={validateOldPassword}
                />
                <Input
                  type={'newPassword'}
                  name={'newPassword'}
                  placeholder={props.i18n.authentication.newPassword}
                  focusKey={getKey('input', 'newPassword')}
                  onChangeText={onChangeText}
                  value={form.newPassword}
                  error={formErrors.newPassword}
                  onBlur={validateNewPassword}
                />
                <Input
                  type={'repeatNewPassword'}
                  name={'repeatNewPassword'}
                  placeholder={props.i18n.authentication.repeatNewPassword}
                  focusKey={getKey('input', 'repeatNewPassword')}
                  onChangeText={onChangeText}
                  value={form.repeatNewPassword}
                  error={formErrors.repeatNewPassword}
                  onBlur={validateRepeatNewPassword}
                />

                <DefaultButton
                  title={props.i18n.account.changePassword}
                  textTransform="uppercase"
                  fitContainer
                  onEnterPress={handleSubmit}
                  focusKey="CHANGE_PASSWORD"
                />
              </Column>
            </Column>
          </Row>
        </FormContainer>
        <Footer />
      </Container>
    </FocusContext.Provider>
  );
}

const mapDispatchToProps = {
  changePassword
};

const mapStateToProps = ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  userDetails,
  appConfig
}: StoreState) => ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  userDetails,
  navigateMode: appConfig.navigateMode
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopup));
