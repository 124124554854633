//sc
/**
 * Copyright 2014 Google Inc. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 *
 * @fileoverview Description of this file.
 *
 * A polyfill for HTML Canvas features, including
 * Path2D support.
 */

(function (CanvasRenderingContext2D) {
  if (CanvasRenderingContext2D.prototype.ellipse == undefined) {
    CanvasRenderingContext2D.prototype.ellipse = function (
      x,
      y,
      radiusX,
      radiusY,
      rotation,
      startAngle,
      endAngle,
      antiClockwise
    ) {
      this.save();
      this.translate(x, y);
      this.rotate(rotation);
      this.scale(radiusX, radiusY);
      this.arc(0, 0, 1, startAngle, endAngle, antiClockwise);
      this.restore();
    };
  }

  if (typeof Path2D !== 'function' || typeof new Path2D().addPath !== 'function') {
    (function () {
      // Include the SVG path parser.
      var parser = (function () {
        /*
         * Generated by PEG.js 0.8.0.
         *
         * http://pegjs.majda.cz/
         */

        function peg$subclass(child, parent) {
          function ctor() {
            this.constructor = child;
          }
          ctor.prototype = parent.prototype;
          child.prototype = new ctor();
        }

        function SyntaxError(message, expected, found, offset, line, column) {
          this.message = message;
          this.expected = expected;
          this.found = found;
          this.offset = offset;
          this.line = line;
          this.column = column;

          this.name = 'SyntaxError';
        }

        peg$subclass(SyntaxError, Error);

        function parse(input) {
          var options = arguments.length > 1 ? arguments[1] : {},
            peg$FAILED = {},
            peg$startRuleFunctions = { svg_path: peg$parsesvg_path },
            peg$startRuleFunction = peg$parsesvg_path,
            peg$c0 = peg$FAILED,
            peg$c1 = [],
            peg$c2 = null,
            peg$c3 = function (d) {
              return ops;
            },
            peg$c4 = /^[Mm]/,
            peg$c5 = { type: 'class', value: '[Mm]', description: '[Mm]' },
            peg$c6 = function (ch, args) {
              var moveCh = ch;
              // If this is the first move cmd then force it to be absolute.
              if (firstSubPath) {
                moveCh = 'M';
                firstSubPath = false;
              }
              ops.push({ type: 'moveTo', args: makeAbsolute(moveCh, args[0]) });
              for (var i = 1; i < args.length; i++) {
                // The lineTo args are either abs or relative, depending on the
                // original moveto command.
                ops.push({ type: 'lineTo', args: makeAbsolute(ch, args[i]) });
              }
            },
            peg$c7 = function (one, rest) {
              return concatSequence(one, rest);
            },
            peg$c8 = /^[Zz]/,
            peg$c9 = { type: 'class', value: '[Zz]', description: '[Zz]' },
            peg$c10 = function () {
              ops.push({ type: 'closePath', args: [] });
            },
            peg$c11 = /^[Ll]/,
            peg$c12 = { type: 'class', value: '[Ll]', description: '[Ll]' },
            peg$c13 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({ type: 'lineTo', args: makeAbsolute(ch, args[i]) });
              }
            },
            peg$c14 = /^[Hh]/,
            peg$c15 = { type: 'class', value: '[Hh]', description: '[Hh]' },
            peg$c16 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({ type: 'lineTo', args: makeAbsoluteFromX(ch, args[i]) });
              }
            },
            peg$c17 = /^[Vv]/,
            peg$c18 = { type: 'class', value: '[Vv]', description: '[Vv]' },
            peg$c19 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({ type: 'lineTo', args: makeAbsoluteFromY(ch, args[i]) });
              }
            },
            peg$c20 = /^[Cc]/,
            peg$c21 = { type: 'class', value: '[Cc]', description: '[Cc]' },
            peg$c22 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({ type: 'bezierCurveTo', args: makeAbsoluteMultiple(ch, args[i]) });
              }
            },
            peg$c23 = function (cp1, cp2, last) {
              return cp1.concat(cp2, last);
            },
            peg$c24 = /^[Ss]/,
            peg$c25 = { type: 'class', value: '[Ss]', description: '[Ss]' },
            peg$c26 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({
                  type: 'bezierCurveTo',
                  args: makeReflected().concat(makeAbsoluteMultiple(ch, args[i]))
                });
              }
            },
            peg$c27 = function (cp1, last) {
              return cp1.concat(last);
            },
            peg$c28 = /^[Qq]/,
            peg$c29 = { type: 'class', value: '[Qq]', description: '[Qq]' },
            peg$c30 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                ops.push({ type: 'quadraticCurveTo', args: makeAbsoluteMultiple(ch, args[i]) });
              }
            },
            peg$c31 = /^[Tt]/,
            peg$c32 = { type: 'class', value: '[Tt]', description: '[Tt]' },
            peg$c33 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                var reflected = makeReflected();
                ops.push({
                  type: 'quadraticCurveTo',
                  args: reflected.concat(makeAbsoluteMultiple(ch, args[i]))
                });
                lastControl = reflected.slice(0);
              }
            },
            peg$c34 = /^[Aa]/,
            peg$c35 = { type: 'class', value: '[Aa]', description: '[Aa]' },
            peg$c36 = function (ch, args) {
              for (var i = 0; i < args.length; i++) {
                var x1 = [lastCoord.slice()];
                var x2 = [makeAbsolute(ch, args[i].slice(-2))];
                absArgs = x1.concat(args[i].slice(0, -2), x2);
                ellipseFromEllipticalArc.apply(this, absArgs);
              }
            },
            peg$c37 = function (rx, ry, xrot, large, sweep, last) {
              return [
                parseFloat(rx),
                parseFloat(ry),
                parseFloat(flatten(xrot).join('')),
                parseInt(large),
                parseInt(sweep),
                last[0],
                last[1]
              ];
            },
            peg$c38 = function (x, y) {
              return [x, y];
            },
            peg$c39 = function (number) {
              return parseFloat(flatten(number).join(''));
            },
            peg$c40 = '0',
            peg$c41 = { type: 'literal', value: '0', description: '"0"' },
            peg$c42 = '1',
            peg$c43 = { type: 'literal', value: '1', description: '"1"' },
            peg$c44 = ',',
            peg$c45 = { type: 'literal', value: ',', description: '","' },
            peg$c46 = '.',
            peg$c47 = { type: 'literal', value: '.', description: '"."' },
            peg$c48 = /^[eE]/,
            peg$c49 = { type: 'class', value: '[eE]', description: '[eE]' },
            peg$c50 = '+',
            peg$c51 = { type: 'literal', value: '+', description: '"+"' },
            peg$c52 = '-',
            peg$c53 = { type: 'literal', value: '-', description: '"-"' },
            peg$c54 = /^[0-9]/,
            peg$c55 = { type: 'class', value: '[0-9]', description: '[0-9]' },
            peg$c56 = function (digits) {
              return digits.join('');
            },
            peg$c57 = /^[ \t\n\r]/,
            peg$c58 = { type: 'class', value: '[ \\t\\n\\r]', description: '[ \\t\\n\\r]' },
            peg$currPos = 0,
            peg$reportedPos = 0,
            peg$cachedPos = 0,
            peg$cachedPosDetails = { line: 1, column: 1, seenCR: false },
            peg$maxFailPos = 0,
            peg$maxFailExpected = [],
            peg$silentFails = 0,
            peg$result;

          if ('startRule' in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
              throw new Error('Can\'t start parsing from rule "' + options.startRule + '".');
            }

            peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
          }

          function text() {
            return input.substring(peg$reportedPos, peg$currPos);
          }

          function offset() {
            return peg$reportedPos;
          }

          function line() {
            return peg$computePosDetails(peg$reportedPos).line;
          }

          function column() {
            return peg$computePosDetails(peg$reportedPos).column;
          }

          function expected(description) {
            throw peg$buildException(
              null,
              [{ type: 'other', description: description }],
              peg$reportedPos
            );
          }

          function error(message) {
            throw peg$buildException(message, null, peg$reportedPos);
          }

          function peg$computePosDetails(pos) {
            function advance(details, startPos, endPos) {
              var p, ch;

              for (p = startPos; p < endPos; p++) {
                ch = input.charAt(p);
                if (ch === '\n') {
                  if (!details.seenCR) {
                    details.line++;
                  }
                  details.column = 1;
                  details.seenCR = false;
                } else if (ch === '\r' || ch === '\u2028' || ch === '\u2029') {
                  details.line++;
                  details.column = 1;
                  details.seenCR = true;
                } else {
                  details.column++;
                  details.seenCR = false;
                }
              }
            }

            if (peg$cachedPos !== pos) {
              if (peg$cachedPos > pos) {
                peg$cachedPos = 0;
                peg$cachedPosDetails = { line: 1, column: 1, seenCR: false };
              }
              advance(peg$cachedPosDetails, peg$cachedPos, pos);
              peg$cachedPos = pos;
            }

            return peg$cachedPosDetails;
          }

          function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) {
              return;
            }

            if (peg$currPos > peg$maxFailPos) {
              peg$maxFailPos = peg$currPos;
              peg$maxFailExpected = [];
            }

            peg$maxFailExpected.push(expected);
          }

          function peg$buildException(message, expected, pos) {
            function cleanupExpected(expected) {
              var i = 1;

              expected.sort(function (a, b) {
                if (a.description < b.description) {
                  return -1;
                } else if (a.description > b.description) {
                  return 1;
                } else {
                  return 0;
                }
              });

              while (i < expected.length) {
                if (expected[i - 1] === expected[i]) {
                  expected.splice(i, 1);
                } else {
                  i++;
                }
              }
            }

            function buildMessage(expected, found) {
              function stringEscape(s) {
                function hex(ch) {
                  return ch.charCodeAt(0).toString(16).toUpperCase();
                }

                return s
                  .replace(/\\/g, '\\\\')
                  .replace(/"/g, '\\"')
                  .replace(/\x08/g, '\\b')
                  .replace(/\t/g, '\\t')
                  .replace(/\n/g, '\\n')
                  .replace(/\f/g, '\\f')
                  .replace(/\r/g, '\\r')
                  .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) {
                    return '\\x0' + hex(ch);
                  })
                  .replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) {
                    return '\\x' + hex(ch);
                  })
                  .replace(/[\u0180-\u0FFF]/g, function (ch) {
                    return '\\u0' + hex(ch);
                  })
                  .replace(/[\u1080-\uFFFF]/g, function (ch) {
                    return '\\u' + hex(ch);
                  });
              }

              var expectedDescs = new Array(expected.length),
                expectedDesc,
                foundDesc,
                i;

              for (i = 0; i < expected.length; i++) {
                expectedDescs[i] = expected[i].description;
              }

              expectedDesc =
                expected.length > 1
                  ? expectedDescs.slice(0, -1).join(', ') +
                    ' or ' +
                    expectedDescs[expected.length - 1]
                  : expectedDescs[0];

              foundDesc = found ? '"' + stringEscape(found) + '"' : 'end of input';

              return 'Expected ' + expectedDesc + ' but ' + foundDesc + ' found.';
            }

            var posDetails = peg$computePosDetails(pos),
              found = pos < input.length ? input.charAt(pos) : null;

            if (expected !== null) {
              cleanupExpected(expected);
            }

            return new SyntaxError(
              message !== null ? message : buildMessage(expected, found),
              expected,
              found,
              pos,
              posDetails.line,
              posDetails.column
            );
          }

          function peg$parsesvg_path() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parsewsp();
            while (s2 !== peg$FAILED) {
              s1.push(s2);
              s2 = peg$parsewsp();
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parsemoveTo_drawTo_commandGroups();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$parsewsp();
                while (s4 !== peg$FAILED) {
                  s3.push(s4);
                  s4 = peg$parsewsp();
                }
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c3(s2);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsemoveTo_drawTo_commandGroups() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsemoveTo_drawTo_commandGroup();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = [];
              s4 = peg$parsewsp();
              while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parsewsp();
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsemoveTo_drawTo_commandGroups();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsemoveTo_drawTo_commandGroup() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsemoveto();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = [];
              s4 = peg$parsewsp();
              while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parsewsp();
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsedrawto_commands();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsedrawto_commands() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsedrawto_command();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = [];
              s4 = peg$parsewsp();
              while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parsewsp();
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsedrawto_commands();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsedrawto_command() {
            var s0;

            s0 = peg$parseclosepath();
            if (s0 === peg$FAILED) {
              s0 = peg$parselineto();
              if (s0 === peg$FAILED) {
                s0 = peg$parsehorizontal_lineto();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsevertical_lineto();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsecurveto();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parsesmooth_curveto();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parsequadratic_bezier_curveto();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parsesmooth_quadratic_bezier_curveto();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseelliptical_arc();
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            return s0;
          }

          function peg$parsemoveto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c4.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c5);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsemoveto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c6(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsemoveto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parselineto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parseclosepath() {
            var s0, s1;

            s0 = peg$currPos;
            if (peg$c8.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c9);
              }
            }
            if (s1 !== peg$FAILED) {
              peg$reportedPos = s0;
              s1 = peg$c10();
            }
            s0 = s1;

            return s0;
          }

          function peg$parselineto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c11.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c12);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parselineto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c13(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parselineto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parselineto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsehorizontal_lineto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c14.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c15);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c16(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecoordinate_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsecoordinate_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsevertical_lineto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c17.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c18);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c19(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecurveto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c20.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c21);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecurveto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c22(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecurveto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsecurveto_argument();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsecurveto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecurveto_argument() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma_wsp();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate_pair();
                if (s3 !== peg$FAILED) {
                  s4 = peg$parsecomma_wsp();
                  if (s4 === peg$FAILED) {
                    s4 = peg$c2;
                  }
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parsecoordinate_pair();
                    if (s5 !== peg$FAILED) {
                      peg$reportedPos = s0;
                      s1 = peg$c23(s1, s3, s5);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$c0;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesmooth_curveto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c24.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c25);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsesmooth_curveto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c26(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesmooth_curveto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsesmooth_curveto_argument();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsesmooth_curveto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesmooth_curveto_argument() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma_wsp();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate_pair();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c27(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsequadratic_bezier_curveto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c28.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c29);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsequadratic_bezier_curveto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c30(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsequadratic_bezier_curveto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsequadratic_bezier_curveto_argument();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsequadratic_bezier_curveto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsequadratic_bezier_curveto_argument() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma_wsp();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate_pair();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c27(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesmooth_quadratic_bezier_curveto() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c31.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c32);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsesmooth_quadratic_bezier_curveto_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c33(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesmooth_quadratic_bezier_curveto_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate_pair();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsesmooth_quadratic_bezier_curveto_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parseelliptical_arc() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c34.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c35);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = [];
              s3 = peg$parsewsp();
              while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsewsp();
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parseelliptical_arc_argument_sequence();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c36(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parseelliptical_arc_argument_sequence() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parseelliptical_arc_argument();
            if (s1 !== peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$parsecomma_wsp();
              if (s3 === peg$FAILED) {
                s3 = peg$c2;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parseelliptical_arc_argument_sequence();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$c0;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$c0;
              }
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c7(s1, s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parseelliptical_arc_argument() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

            s0 = peg$currPos;
            s1 = peg$parsenonnegative_number();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma_wsp();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsenonnegative_number();
                if (s3 !== peg$FAILED) {
                  s4 = peg$parsecomma_wsp();
                  if (s4 === peg$FAILED) {
                    s4 = peg$c2;
                  }
                  if (s4 !== peg$FAILED) {
                    s5 = peg$parsenumber();
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parsecomma_wsp();
                      if (s6 !== peg$FAILED) {
                        s7 = peg$parseflag();
                        if (s7 !== peg$FAILED) {
                          s8 = peg$parsecomma_wsp();
                          if (s8 === peg$FAILED) {
                            s8 = peg$c2;
                          }
                          if (s8 !== peg$FAILED) {
                            s9 = peg$parseflag();
                            if (s9 !== peg$FAILED) {
                              s10 = peg$parsecomma_wsp();
                              if (s10 === peg$FAILED) {
                                s10 = peg$c2;
                              }
                              if (s10 !== peg$FAILED) {
                                s11 = peg$parsecoordinate_pair();
                                if (s11 !== peg$FAILED) {
                                  peg$reportedPos = s0;
                                  s1 = peg$c37(s1, s3, s5, s7, s9, s11);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$c0;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$c0;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$c0;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$c0;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecoordinate_pair() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parsecoordinate();
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma_wsp();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsecoordinate();
                if (s3 !== peg$FAILED) {
                  peg$reportedPos = s0;
                  s1 = peg$c38(s1, s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsecoordinate() {
            var s0, s1;

            s0 = peg$currPos;
            s1 = peg$parsenumber();
            if (s1 !== peg$FAILED) {
              peg$reportedPos = s0;
              s1 = peg$c39(s1);
            }
            s0 = s1;

            return s0;
          }

          function peg$parsenonnegative_number() {
            var s0;

            s0 = peg$parsefloating_point_constant();
            if (s0 === peg$FAILED) {
              s0 = peg$parsedigit_sequence();
            }

            return s0;
          }

          function peg$parsenumber() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$parsesign();
            if (s1 === peg$FAILED) {
              s1 = peg$c2;
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parsefloating_point_constant();
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsesign();
              if (s1 === peg$FAILED) {
                s1 = peg$c2;
              }
              if (s1 !== peg$FAILED) {
                s2 = peg$parsedigit_sequence();
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            }

            return s0;
          }

          function peg$parseflag() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 48) {
              s0 = peg$c40;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c41);
              }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 49) {
                s0 = peg$c42;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c43);
                }
              }
            }

            return s0;
          }

          function peg$parsecomma_wsp() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parsewsp();
            if (s2 !== peg$FAILED) {
              while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parsewsp();
              }
            } else {
              s1 = peg$c0;
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parsecomma();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$parsewsp();
                while (s4 !== peg$FAILED) {
                  s3.push(s4);
                  s4 = peg$parsewsp();
                }
                if (s3 !== peg$FAILED) {
                  s1 = [s1, s2, s3];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsecomma();
              if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$parsewsp();
                while (s3 !== peg$FAILED) {
                  s2.push(s3);
                  s3 = peg$parsewsp();
                }
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            }

            return s0;
          }

          function peg$parsecomma() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 44) {
              s0 = peg$c44;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c45);
              }
            }

            return s0;
          }

          function peg$parsefloating_point_constant() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$parsefractional_constant();
            if (s1 !== peg$FAILED) {
              s2 = peg$parseexponent();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsedigit_sequence();
              if (s1 !== peg$FAILED) {
                s2 = peg$parseexponent();
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            }

            return s0;
          }

          function peg$parsefractional_constant() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            s1 = peg$parsedigit_sequence();
            if (s1 === peg$FAILED) {
              s1 = peg$c2;
            }
            if (s1 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 46) {
                s2 = peg$c46;
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c47);
                }
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsedigit_sequence();
                if (s3 !== peg$FAILED) {
                  s1 = [s1, s2, s3];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsedigit_sequence();
              if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 46) {
                  s2 = peg$c46;
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c47);
                  }
                }
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            }

            return s0;
          }

          function peg$parseexponent() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c48.test(input.charAt(peg$currPos))) {
              s1 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c49);
              }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parsesign();
              if (s2 === peg$FAILED) {
                s2 = peg$c2;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsedigit_sequence();
                if (s3 !== peg$FAILED) {
                  s1 = [s1, s2, s3];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$c0;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$c0;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$c0;
            }

            return s0;
          }

          function peg$parsesign() {
            var s0;

            if (input.charCodeAt(peg$currPos) === 43) {
              s0 = peg$c50;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c51);
              }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 45) {
                s0 = peg$c52;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$c53);
                }
              }
            }

            return s0;
          }

          function peg$parsedigit_sequence() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = [];
            if (peg$c54.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c55);
              }
            }
            if (s2 !== peg$FAILED) {
              while (s2 !== peg$FAILED) {
                s1.push(s2);
                if (peg$c54.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$c55);
                  }
                }
              }
            } else {
              s1 = peg$c0;
            }
            if (s1 !== peg$FAILED) {
              peg$reportedPos = s0;
              s1 = peg$c56(s1);
            }
            s0 = s1;

            return s0;
          }

          function peg$parsewsp() {
            var s0;

            if (peg$c57.test(input.charAt(peg$currPos))) {
              s0 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$c58);
              }
            }

            return s0;
          }

          // The last coordinate we are at in the path. In absolute coords.
          var lastCoord = [0, 0];
          // The last control point we encountered in the path. In absolute coords.
          var lastControl = [0, 0];
          // The list of operations we've parsed so far.
          var ops = [];
          // Have we parsed the first sub-path yet?
          var firstSubPath = true;
          // The letter of the last parsed command.
          var lastCh = '';

          // Flatten an array.
          function flatten(a) {
            var flat = [];
            for (var i = 0; i < a.length; i++) {
              if (a[i] instanceof Array) {
                flat.push.apply(flat, flatten(a[i]));
              } else {
                flat.push(a[i]);
              }
            }
            return flat;
          }

          // Convert a position into an absolute position.
          function makeAbsolute(c, coord) {
            if ('mlazhvcsqt'.indexOf(c) === -1) {
              lastCoord = coord;
            } else {
              lastCoord[0] += coord[0];
              lastCoord[1] += coord[1];
            }
            lastCh = c;
            return lastCoord.slice(0);
          }

          // Convert a sequence of coordinates into absolute coordinates.
          //
          // For arguments that take multiple coord pairs, such as bezier.
          function makeAbsoluteMultiple(c, seq) {
            var r = [];
            var lastPosCopy = lastCoord.slice(0);
            for (var i = 0; i < seq.length; i += 2) {
              // Only the last point should update lastCoord.
              lastCoord = lastPosCopy.slice(0);
              var coord = makeAbsolute(c, seq.slice(i, i + 2));
              r = r.concat(coord);
              // Record the last control point, it might be needed for
              // shorthand operations.
              if (i == seq.length - 4) {
                lastControl = coord.slice(0);
              }
            }
            return r;
          }

          // Find the reflection of the last control point over
          // the last postion in the path.
          function makeReflected() {
            if ('CcSsQqTt'.indexOf(lastCh) == -1) {
              lastControl = lastCoord.slice(0);
            }
            // reflected = 2*lastCoord - lastControl
            // Note the result is absolute, not relative.
            var r = [0, 0];
            r[0] = 2 * lastCoord[0] - lastControl[0];
            r[1] = 2 * lastCoord[1] - lastControl[1];
            return r;
          }

          function makeAbsoluteFromX(c, x) {
            var coord = [x, 0];
            if (c == 'H') {
              coord[1] = lastCoord[1];
            }
            return makeAbsolute(c, coord);
          }

          function makeAbsoluteFromY(c, y) {
            var coord = [0, y];
            if (c == 'V') {
              coord[0] = lastCoord[0];
            }
            return makeAbsolute(c, coord);
          }

          function concatSequence(one, rest) {
            var r = [one];
            if (rest && rest.length > 1) {
              var rem = rest[1];
              for (var i = 0; i < rem.length; i++) {
                r.push(rem[i]);
              }
            }
            return r;
          }

          function mag(v) {
            return Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2));
          }

          function dot(u, v) {
            return u[0] * v[0] + u[1] * v[1];
          }

          function ratio(u, v) {
            return dot(u, v) / (mag(u) * mag(v));
          }

          function clamp(value, min, max) {
            return Math.min(Math.max(val, min), max);
          }

          function angle(u, v) {
            var sign = 1.0;
            if (u[0] * v[1] - u[1] * v[0] < 0) {
              sign = -1.0;
            }
            return sign * Math.acos(clamp(ratio(u, v)), -1, 1);
          }

          function rotClockwise(v, angle) {
            var cost = Math.cos(angle);
            var sint = Math.sin(angle);
            return [cost * v[0] + sint * v[1], -1 * sint * v[0] + cost * v[1]];
          }

          function rotCounterClockwise(v, angle) {
            var cost = Math.cos(angle);
            var sint = Math.sin(angle);
            return [cost * v[0] - sint * v[1], sint * v[0] + cost * v[1]];
          }

          function midPoint(u, v) {
            return [(u[0] - v[0]) / 2.0, (u[1] - v[1]) / 2.0];
          }

          function meanVec(u, v) {
            return [(u[0] + v[0]) / 2.0, (u[1] + v[1]) / 2.0];
          }

          function pointMul(u, v) {
            return [u[0] * v[0], u[1] * v[1]];
          }

          function scale(c, v) {
            return [c * v[0], c * v[1]];
          }

          function sum(u, v) {
            return [u[0] + v[0], u[1] + v[1]];
          }

          // Convert an SVG elliptical arc to a series of canvas commands.
          //
          // x1, x2: start and stop coordinates of the ellipse.
          // rx, ry: radii of the ellipse.
          // phi: rotation of the ellipse.
          // fA: large arc flag.
          // fS: sweep flag.
          function ellipseFromEllipticalArc(x1, rx, ry, phi, fA, fS, x2) {
            // Convert from endpoint to center parametrization, as detailed in:
            //   http://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes
            if (rx == 0 || ry == 0) {
              ops.push({ type: 'lineTo', args: x2 });
              return;
            }
            var phi = phi * (Math.PI / 180.0);
            rx = Math.abs(rx);
            ry = Math.abs(ry);
            var xPrime = rotClockwise(midPoint(x1, x2), phi); // F.6.5.1
            var xPrime2 = pointMul(xPrime, xPrime);
            var rx2 = Math.pow(rx, 2);
            var ry2 = Math.pow(ry, 2);

            var lambda = Math.sqrt(xPrime2[0] / rx2 + xPrime2[1] / ry2);
            if (lambda > 1) {
              rx *= lambda;
              ry *= lambda;
              rx2 = Math.pow(rx, 2);
              ry2 = Math.pow(ry, 2);
            }
            var factor = Math.sqrt(
              Math.abs(rx2 * ry2 - rx2 * xPrime2[1] - ry2 * xPrime2[0]) /
                (rx2 * xPrime2[1] + ry2 * xPrime2[0])
            );
            if (fA == fS) {
              factor *= -1.0;
            }
            var cPrime = scale(factor, [(rx * xPrime[1]) / ry, (-ry * xPrime[0]) / rx]); // F.6.5.2
            var c = sum(rotCounterClockwise(cPrime, phi), meanVec(x1, x2)); // F.6.5.3
            var x1UnitVector = [(xPrime[0] - cPrime[0]) / rx, (xPrime[1] - cPrime[1]) / ry];
            var x2UnitVector = [
              (-1.0 * xPrime[0] - cPrime[0]) / rx,
              (-1.0 * xPrime[1] - cPrime[1]) / ry
            ];
            var theta = angle([1, 0], x1UnitVector); // F.6.5.5
            var deltaTheta = angle(x1UnitVector, x2UnitVector); // F.6.5.6
            var start = theta;
            var end = theta + deltaTheta;
            ops.push(
              { type: 'save', args: [] },
              { type: 'translate', args: [c[0], c[1]] },
              { type: 'rotate', args: [phi] },
              { type: 'scale', args: [rx, ry] },
              { type: 'arc', args: [0, 0, 1, start, end, 1 - fS] },
              { type: 'restore', args: [] }
            );
          }

          peg$result = peg$startRuleFunction();

          if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result;
          } else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
              peg$fail({ type: 'end', description: 'end of input' });
            }

            throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos);
          }
        }

        return {
          SyntaxError: SyntaxError,
          parse: parse
        };
      })();

      function Path_(arg) {
        this.ops_ = [];
        if (arg == undefined) {
          return;
        }
        if (typeof arg == 'string') {
          try {
            this.ops_ = parser.parse(arg);
          } catch (e) {
            // Treat an invalid SVG path as an empty path.
          }
        } else if (arg.hasOwnProperty('ops_')) {
          this.ops_ = arg.ops_.slice(0);
        } else {
          throw 'Error: ' + typeof arg + 'is not a valid argument to Path';
        }
      }

      // TODO(jcgregorio) test for arcTo and implement via something.

      // Path methods that map simply to the CanvasRenderingContext2D.
      var simple_mapping = [
        'closePath',
        'moveTo',
        'lineTo',
        'quadraticCurveTo',
        'bezierCurveTo',
        'rect',
        'arc',
        'arcTo',
        'ellipse',
        'isPointInPath',
        'isPointInStroke'
      ];

      function createFunction(name) {
        return function () {
          this.ops_.push({ type: name, args: Array.prototype.slice.call(arguments, 0) });
        };
      }

      // Add simple_mapping methods to Path2D.
      for (var i = 0; i < simple_mapping.length; i++) {
        var name = simple_mapping[i];
        Path_.prototype[name] = createFunction(name);
      }

      Path_.prototype['addPath'] = function (path, tr) {
        var hasTx = false;
        if (
          tr &&
          tr.a != undefined &&
          tr.b != undefined &&
          tr.c != undefined &&
          tr.d != undefined &&
          tr.e != undefined &&
          tr.f != undefined
        ) {
          hasTx = true;
          this.ops_.push({ type: 'save', args: [] });
          this.ops_.push({ type: 'transform', args: [tr.a, tr.b, tr.c, tr.d, tr.e, tr.f] });
        }
        this.ops_ = this.ops_.concat(path.ops_);
        if (hasTx) {
          this.ops_.push({ type: 'restore', args: [] });
        }
      };

      var original_fill = CanvasRenderingContext2D.prototype.fill;
      var original_stroke = CanvasRenderingContext2D.prototype.stroke;
      var original_clip = CanvasRenderingContext2D.prototype.clip;
      var original_is_point_in_path = CanvasRenderingContext2D.prototype.isPointInPath;
      var original_is_point_in_stroke = CanvasRenderingContext2D.prototype.isPointInStroke;

      // Replace methods on CanvasRenderingContext2D with ones that understand Path2D.
      CanvasRenderingContext2D.prototype.fill = function (arg) {
        if (arg instanceof Path_) {
          this.beginPath();
          for (var i = 0, len = arg.ops_.length; i < len; i++) {
            var op = arg.ops_[i];
            CanvasRenderingContext2D.prototype[op.type].apply(this, op.args);
          }
          original_fill.apply(this, Array.prototype.slice.call(arguments, 1));
        } else {
          original_fill.apply(this, arguments);
        }
      };

      CanvasRenderingContext2D.prototype.stroke = function (arg) {
        if (arg instanceof Path_) {
          this.beginPath();
          for (var i = 0, len = arg.ops_.length; i < len; i++) {
            var op = arg.ops_[i];
            CanvasRenderingContext2D.prototype[op.type].apply(this, op.args);
          }
          original_stroke.call(this);
        } else {
          original_stroke.call(this);
        }
      };

      CanvasRenderingContext2D.prototype.clip = function (arg) {
        if (arg instanceof Path_) {
          // Note that we don't save and restore the context state, since the
          // clip region is part of the state. Not really a problem since the
          // HTML 5 spec doesn't say that clip(path) doesn't affect the current
          // path.
          this.beginPath();
          for (var i = 0, len = arg.ops_.length; i < len; i++) {
            var op = arg.ops_[i];
            CanvasRenderingContext2D.prototype[op.type].apply(this, op.args);
          }
          original_clip.apply(this, Array.prototype.slice.call(arguments, 1));
        } else {
          original_clip.apply(this, arguments);
        }
      };

      CanvasRenderingContext2D.prototype.isPointInPath = function (arg) {
        if (arg instanceof Path_) {
          this.beginPath();
          for (var i = 0, len = arg.ops_.length; i < len; i++) {
            var op = arg.ops_[i];
            CanvasRenderingContext2D.prototype[op.type].apply(this, op.args);
          }
          return original_is_point_in_path.apply(this, Array.prototype.slice.call(arguments, 1));
        } else {
          return original_is_point_in_path.apply(this, arguments);
        }
      };
      CanvasRenderingContext2D.prototype.isPointInStroke = function (arg) {
        if (arg instanceof Path_) {
          this.beginPath();
          for (var i = 0, len = arg.ops_.length; i < len; i++) {
            var op = arg.ops_[i];
            CanvasRenderingContext2D.prototype[op.type].apply(this, op.args);
          }
          return original_is_point_in_stroke.apply(this, Array.prototype.slice.call(arguments, 1));
        } else {
          return original_is_point_in_stroke.apply(this, arguments);
        }
      };

      // Set up externs.
      window.Path2D = Path_;
    })();
  }
})(
  typeof CanvasRenderingContext2D === 'undefined' ? undefined : CanvasRenderingContext2D,
  typeof require === 'undefined' ? undefined : require
);
