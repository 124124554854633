export const APP_GET_CONFIG = 'APP_GET_CONFIG';
export const APP_GET_SUBLANGS = 'APP_GET_SUBLANGS';
export const APP_GET_AUDIOLANGS = 'APP_GET_AUDIOLANGS';
export const APP_SET_NAVIGATE_MODE = 'APP_SET_NAVIGATE_MODE';
export const EPG_GET = 'EPG_GET';
export const RECORDED_GET = 'RECORDED_GET';
export const CATEGORIES_GET = 'CATEGORIES_GET';
export const RECORDED_RESET = 'RECORDED_RESET';
export const LIVE_CHANNELS_GET = 'LIVE_CHANNELS_GET';
export const LIVE_CHANNELS_CLEAR = 'LIVE_CHANNELS_CLEAR';
export const FILTERED_LIVE_CHANNELS_GET = 'FILTERED_LIVE_CHANNELS_GET';
export const LOGOS_SET = 'LOGOS_SET';
export const RECORDED_CLEAR_CATEGORIES_EPGS = 'RECORDED_CLEAR_CATEGORIES_EPGS';
export const RECORDED_CLEAR_SUBCATEGORIES_EPGS = 'RECORDED_CLEAR_SUBCATEGORIES_EPGS';
export const RECORDED_GET_CATEGORY_EPGS = 'RECORDED_GET_CATEGORY_EPGS';
export const RECORDED_GET_SUBCATEGORY_EPGS = 'RECORDED_GET_SUBCATEGORY_EPGS';
export const RECORDED_CLEAR_CATEGORY_LIST = 'RECORDED_CLEAR_CATEGORY_LIST';
export const RECORDED_GET_CATEGORY_LIST_NEXT_PAGE = 'RECORDED_GET_CATEGORY_LIST_NEXT_PAGE';
export const RECORDED_CLEAR_SUBCATEGORY_LIST = 'RECORDED_CLEAR_SUBCATEGORY_LIST';
export const RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE = 'RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE';
export const TRANSLATIONS_GET_COMMON = 'TRANSLATIONS_GET_COMMON';
export const VIDEO_CURRENT = 'VIDEO_CURRENT';
export const VIDEO_NEXT = 'VIDEO_NEXT';
export const RESET_VIDEO_NEXT = 'RESET_VIDEO_NEXT';
export const VIDEO_PLAYER_TOGGLE = 'VIDEO_PLAYER_TOGGLE';
export const VIDEO_ALL_EPG = 'VIDEO_ALL_EPG';
export const VIDEO_RESET = 'VIDEO_RESET';
export const VIDEO_OPTIONS = 'VIDEO_OPTIONS';
export const VIDEO_PLAYER_EPGS = 'VIDEO_PLAYER_EPGS';
export const VIDEO_SET_AFTER_SUBSCRIPTION = 'VIDEO_SET_AFTER_SUBSCRIPTION';
export const VIDEO_RESET_AFTER_SUBSCRIPTION = 'VIDEO_RESET_AFTER_SUBSCRIPTION';
export const VIDEO_SET_PLAYER_STATE = 'VIDEO_SET_PLAYER_STATE';
export const CURRENT_CHANNEL_SET = 'CURRENT_CHANNEL_SET';
export const CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY = 'CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY';
export const AUTH_DELETE_TOKEN = 'AUTH_DELETE_TOKEN';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SET_LAST_REQUESTED_ROUTE = 'AUTH_SET_LAST_REQUESTED_ROUTE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const CLOSE_WRITE_TO_US = 'CLOSE_WRITE_TO_US';
export const OPEN_WRITE_TO_US = 'OPEN_WRITE_TO_US';
export const OPEN_CONFIRMATION = 'OPEN_CONFIRMATION';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';
export const OPEN_GDPR = 'OPEN_GDPR';
export const CLOSE_GDPR = 'CLOSE_GDPR';
export const OPEN_PROMOTIONS = 'OPEN_PROMOTIONS';
export const CLOSE_PROMOTIONS = 'CLOSE_PROMOTIONS';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const OPEN_PIN_MODAL = 'OPEN_PIN_MODAL';
export const CLOSE_PIN_MODAL = 'CLOSE_PIN_MODAL';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const MOST_WATCHED_GET_EPGS = 'MOST_WATCHED_GET_EPGS';
export const MOST_WATCHED_CLEAR_EPGS = 'MOST_WATCHED_CLEAR_EPGS';
export const LAST_WATCHED_GET_EPGS = 'LAST_WATCHED_GET_EPGS';
export const LAST_WATCHED_CLEAR_EPGS = 'LAST_WATCHED_CLEAR_EPGS';
export const SEARCH_SET_RESULT = 'SEARCH_SET_RESULT';
export const SEARCH_FILTER_RESULT = 'SEARCH_FILTER_RESULT';
export const SEARCH_CHANNEL_RESULT = 'SEARCH_CHANNEL_RESULT';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const CHANGE_SCREEN_TYPE = 'CHANGE_SCREEN_TYPE';
export const USER_GET_DETAILS = 'USER_GET_DETAILS';
export const USER_CONFIRM_DELETION = 'USER_CONFIRM_DELETION';
export const USER_GET_PROFILE_SETTINGS = 'USER_GET_PROFILE_SETTINGS';
export const USER_SET_PROFILE_SETTINGS = 'USER_SET_PROFILE_SETTINGS';
export const USER_SET_PROFILE_SETTING = 'USER_SET_PROFILE_SETTING';
export const USER_SET_CHANNEL_PROFILE_SETTING = 'USER_SET_CHANNEL_PROFILE_SETTING';
export const USER_SAVE_PROFILE_SETTINGS = 'USER_SAVE_PROFILE_SETTINGS';
export const USER_REMOVE_PROFILE_SETTINGS = 'USER_REMOVE_PROFILE_SETTINGS';
export const USER_RESET_PROFILE_SETTINGS = 'USER_RESET_PROFILE_SETTINGS';
export const USER_GET_FULL_DETAILS = 'USER_GET_FULL_DETAILS';
export const USER_GET_INVOICES = 'USER_GET_INVOICES';
export const USER_REMOVE_DETAILS = 'USER_REMOVE_DETAILS';
export const USER_GET_INVOICE_URL = 'USER_GET_INVOICE_URL';
export const SUBSCRIPTIONS_GET = 'SUBSCRIPTIONS_GET';
export const ADDONS_GET = 'ADDONS_GET';
export const CATEGORIES_LIVE_GET = 'CATEGORIES_LIVE_GET';
export const CATEGORIES_LIVE_CLEAR = 'CATEGORIES_LIVE_CLEAR';
export const CAROUSEL_TOGGLE = 'CAROUSEL_TOGGLE';
export const CAROUSEL_SET_BANNERS = 'CAROUSEL_SET_BANNERS';
export const CAROUSEL_BANNER_PLAYING_TOGGLE = 'CAROUSEL_BANNER_PLAYING_TOGGLE';
export const SERVER_TIME_SET = 'SERVER_TIME_SET';
export const APP_GET_APP_RESOLUTIONS = 'APP_GET_APP_RESOLUTIONS';
export const APP_GET_CHANNEL_SETTINGS = 'APP_GET_CHANNEL_SETTINGS';
export const APP_SET_CHANNEL_SETTINGS = 'APP_SET_CHANNEL_SETTINGS';
export const APP_GET_CHANNEL_DEFAULT_PIN = 'APP_GET_CHANNEL_DEFAULT_PIN';
export const REMOVE_SOME_CHANNELS_FROM_LOCK_LIST = 'REMOVE_SOME_CHANNELS_FROM_LOCK_LIST';
export const SAVE_CHANNELS_STATE_TO_REDUX = 'SAVE_CHANNELS_STATE_TO_REDUX';
export const CHANNEL_LIST_SET_CATEGORY = 'CHANNEL_LIST_SET_CATEGORY';
export const SET_DETAILED_INFO_DATA = 'SET_DETAILED_INFO_DATA';
export const RETRIEVE_PRISMIC_RADIO_IMAGES = 'RETRIEVE_PRISMIC_RADIO_IMAGES';
export const RETRIEVE_PRISMIC_SCREENSAVER_IMAGES = 'RETRIEVE_PRISMIC_SCREENSAVER_IMAGES';
export const USER_TOGGLE_CHANNEL_SETTING = 'USER_TOGGLE_CHANNEL_SETTING';
export const USER_SET_HIDDEN_CHANNELS = 'USER_SET_HIDDEN_CHANNELS';
export const APP_SET_FAVORITE_CHANNELS = 'APP_SET_FAVORITE_CHANNELS';
export const APP_CLEAR_FAVORITE_CHANNELS = 'APP_CLEAR_FAVORITE_CHANNELS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const SORT_MESSAGES = 'SORT_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const DELETE_MESSAGES = 'DELETE_MESSAGES';
export const READ_MESSAGES = 'READ_MESSAGES';
export const SET_IDLE_TIMER = 'SET_IDLE_TIMER';