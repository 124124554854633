import ConfirmResetIcon from '@assets/media/confirm-settings-icon.svg';
import { translationPacket } from 'common/actions';
import { Prompt } from 'common/components/promp';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  i18n: typeof translationPacket;
  resetSettingName: string;
  onConfirm: () => void;
}
function ConfirmResetPopup({ i18n, resetSettingName, onConfirm }: Props) {
  return (
    <Prompt
      name="CONFIRM-RESET-SETTINGS-POPUP"
      title={i18n.mainSettings.confirmSettings}
      subtitle={`${
        i18n.mainSettings.resetSettingsConfirmation
      } ${resetSettingName.toLocaleUpperCase()} ?`}
      confirmTitle={i18n.common.yes}
      closeTitle={i18n.common.close}
      icon={ConfirmResetIcon}
      onConfirm={onConfirm}
    />
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(ConfirmResetPopup));
