import LockedChanelThumbnail from '@assets/media/locked_channel_thumbnail.png';
import DefaultThumbnail from '@assets/media/no_info_thumbnail.png';
import PaidChannelThumbnail from '@assets/media/paid_channel_thumbnail.png';
import { translationPacket } from 'common/actions';
import { Image } from 'common/components/image';
import { HAS_ACCESS } from 'common/config/constants';
import { LiveChannelEpg } from 'common/constants/data-types';
import { getDateAndTimeFast, getThumbnailUrl } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { useParentalLock } from 'common/utils/hooks';
import React, { useCallback, useMemo } from 'react';
import './LiveChannelListItem.styles';

interface ListItemProps {
  focused?: boolean;
  current: LiveChannelEpg;
  next: LiveChannelEpg;
  progress: number;
  logo: string;
  i18n: typeof translationPacket;
  position: number;
  locked?: boolean;
  adult?: boolean;
  thumbnail?: string;
}

function LiveChannelListItem(props: ListItemProps) {
  const { locked: channelsLocked } = useParentalLock();
  const currentEpgParsedDate = getDateAndTimeFast(
    props.current.start,
    props.current.stop,
    props.i18n.months
  );
  const nextEpgParsedDate = getDateAndTimeFast(
    props.next.start,
    props.next.stop,
    props.i18n.months
  );

  const access = props.current.access;
  const locked = useMemo(() => props.locked && channelsLocked, [props.locked, channelsLocked]);
  const adult = props.adult;

  // Update thumbnailUrl on liveChannels update
  const thumbnailUrl = useMemo(() => getThumbnailUrl(props.current.chan_id, true), [props.current]);
  const thumbnailImage = useMemo(() => {
    if (access !== HAS_ACCESS) {
      return PaidChannelThumbnail;
    }

    if (adult) {
      return props.thumbnail;
    }

    if (locked) {
      return LockedChanelThumbnail;
    }

    if (access === HAS_ACCESS) {
      return thumbnailUrl;
    }
  }, [access, locked, adult]);

  const fallbackImage = useMemo(() => {
    return adult ? LockedChanelThumbnail : DefaultThumbnail;
  }, [adult]);

  const renderProgressbar = useCallback(() => {
    const progress = 100 - props.progress;

    return (
      <div className="progressbar">
        {!props.focused && (
          <div className="value" style={{ transform: `translateX(-${progress}%)` }} />
        )}
      </div>
    );
  }, [props.progress, props.focused]);

  return (
    <div
      className={getClassName('live-channel-list-item', {
        focused: props.focused,
        paid: access !== HAS_ACCESS,
        locked: locked
      })}
    >
      <div className="logo-container">
        <span className="title">{props.position}</span>
        <Image className="logo" src={props.logo} />
      </div>
      <div className="title-thumbnail-container">
        <span className="channel-name">{props.current.chan_name}</span>
        <Image className="thumbnail" src={thumbnailImage} fallbackSrc={fallbackImage} />
      </div>
      <div className="current-epg-container">
        <div className="info-container">
          <span className="time-slot">{`${currentEpgParsedDate.start} - ${currentEpgParsedDate.stop}`}</span>
          <span className="epg-title">{adult ? props.i18n.common.adult : props.current.title}</span>
        </div>
        {renderProgressbar()}
      </div>
      {!adult && (
        <div className="next-epg-container">
          <span className="time-slot">
            {props.i18n.common.upcoming}
            <span className="separator"> · </span>
            {`${nextEpgParsedDate.start} - ${nextEpgParsedDate.stop}`}
          </span>
          <span className="epg-title">{props.next.title}</span>
        </div>
      )}
    </div>
  );
}

export default React.memo(LiveChannelListItem);
