import { LiveChannel } from 'common/constants/data-types';
import React, { useCallback, useMemo } from 'react';

import { CHANNEL_ACCESS_TYPES, HAS_ACCESS, THUMBNAIL_OFFSET } from 'common/config/constants';
import { getThumbnailUrl } from 'common/services/helpers';

import AdultIcon from '@assets/media/adult-icon.svg';
import BankCardIcon from '@assets/media/bank-card.svg';
import LockIcon from '@assets/media/lock-icon.svg';
import AdultChannelThumbnail from '@assets/media/locked_channel_thumbnail.png';
import DefaultThumbnail from '@assets/media/no_info_thumbnail.png';

import { Translation } from 'common/actions';
import { Image } from 'common/components/image';
import { getClassName } from 'common/utils';
import { useRadioImage } from 'common/utils/hooks';
import { deepEqual } from 'fast-equals';
import './Card.styles';
import CardAccessOverlay, { CardAccessOverlayProps } from './CardAccessOverlay';
import LiveCardProgress from './LiveCardProgress';

interface LiveCardProps {
  focused?: boolean;
  hidden?: boolean;
  channelsLocked?: boolean;
  liveChannel: LiveChannel;
  logo: string;
  i18n: Translation;
}

function LiveCard({
  logo: logoSrc,
  liveChannel,
  focused = false,
  i18n,
  hidden,
  channelsLocked
}: LiveCardProps) {
  const { start, position, title, chan_id, access, locked, progress, adult } = useMemo(() => {
    const epg = liveChannel.currentepg;

    return {
      position: liveChannel.position,
      start: epg.start,
      progress: liveChannel.progress,
      title: epg.title,
      chan_id: liveChannel.id,
      access: liveChannel.access,
      locked: liveChannel.locked && channelsLocked,
      adult: liveChannel.adult
    };
  }, [liveChannel, channelsLocked]);

  const radioImages = useRadioImage(chan_id);

  // Update thumbnailUrl on liveChannels update
  const thumbnailUrl = useMemo(() => {
    if (adult) {
      return liveChannel.thumbnail;
    }

    return radioImages
      ? radioImages.radio_thumbnail_image.url
      : getThumbnailUrl(chan_id, true, start + THUMBNAIL_OFFSET);
  }, [chan_id, start, liveChannel, radioImages]);

  const showInfo = access === HAS_ACCESS && !locked && !adult;

  const renderChannelAccessOverlay = useCallback(() => {
    let accessOverlayProps: Partial<CardAccessOverlayProps> = {};

    if (access !== HAS_ACCESS) {
      accessOverlayProps = {
        accessType: CHANNEL_ACCESS_TYPES.PAID,
        icon: BankCardIcon,
        title: i18n?.channelList?.paid,
        subTitle: i18n?.channelList?.plan
      };
    } else if (adult) {
      accessOverlayProps = {
        accessType: CHANNEL_ACCESS_TYPES.LOCKED,
        icon: locked ? LockIcon : undefined,
        secondaryIcon: AdultIcon,
        title: i18n?.channelList?.epg,
        subTitle: i18n?.channelList?.adult
      };
    } else if (locked) {
      accessOverlayProps = {
        accessType: CHANNEL_ACCESS_TYPES.LOCKED,
        icon: LockIcon,
        title: i18n?.channelList?.locked,
        subTitle: i18n?.channelList?.channel
      };
    }
    return (
      accessOverlayProps.accessType && (
        <CardAccessOverlay {...(accessOverlayProps as CardAccessOverlayProps)} focused={focused} />
      )
    );
  }, [focused, locked]);

  if (hidden) {
    return <div className="card-box live-card-box"></div>;
  }

  return (
    <div className="card-box live-card-box">
      <Image
        src={thumbnailUrl}
        fallbackSrc={adult ? AdultChannelThumbnail : DefaultThumbnail}
        className={'live-card-background'}
      />
      <div className={getClassName('live-card-overlay', { focused })} />
      <div className={getClassName('live-card-info-container', { focused, locked })}>
        <span>{position}&nbsp;·&nbsp;</span>
        <Image className="live-card-logo" src={logoSrc} />
      </div>
      <div>
        {showInfo && <div className={getClassName('live-card-title', { focused })}>{title}</div>}
        {showInfo && <LiveCardProgress progress={progress} visible={!focused} />}
      </div>
      {renderChannelAccessOverlay()}
    </div>
  );
}

export default React.memo(LiveCard, deepEqual);
