import { Column } from 'common/components/common/style';
import { H2 } from 'common/components/Typography';
import {
  color_grey_40,
  color_grey_60,
  color_primary,
  color_white
} from 'common/config/variables/default';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

export const LockIconWithText = styled.div`
  margin-top: ${getScalablePixel(32)}px !important;
  text-align: center;
  font-size: ${getScalablePixel(42)}px;
  font-style: normal;
  font-weight: 500;
  color: ${color_white};
`;

export const PinTitle = styled('p')`
  margin: 0;
  color: ${color_grey_40};
  font-size: ${getScalablePixel(26)}px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
`;

export const PopupContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  min-width: 50vw;
  min-height: ${getScalablePixel(624)}px;
  height: 100%;

  ${H2} {
    text-transform: uppercase;
  }

  & > * {
    margin: ${getScalablePixel(8)}px 0;
  }
`;

export const PinContainer = styled('div')`
  text-align: center;
  margin: ${getScalablePixel(10)}px ${getScalablePixel(64)}px ${getScalablePixel(64)}px;
`;

export const Stepper = styled('div')`
  margin: ${getScalablePixel(8)}px 0;
  padding: ${getScalablePixel(8)}px 0;
  height: ${getScalablePixel(20)}px;
  display: flex;
  align-items: center;
`;

export const StepperLines = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: ${getScalablePixel(4)}px;
`;

export const StepperLine = styled.span<{ className: string }>`
  background: ${color_grey_60};
  width: ${getScalablePixel(180)}px;
  height: ${getScalablePixel(2)}px;

  &.error {
    background: ${color_primary};
  }

  &.active {
    background: #fbfbfb;
    height: ${getScalablePixel(4)}px;
  }
`;
