import { SERVER_TIME_SET } from 'common/constants/action-types';

export default function (state = null, action) {
  switch (action.type) {
    case SERVER_TIME_SET:
      return action.payload;
    default:
      return state;
  }
}
