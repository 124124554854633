import { Translation } from 'common/actions';
import { Image } from 'common/components/image';
import PinPrompt from 'common/components/keyboard-popups/PinPrompt';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

interface StoreState {
  i18n: Translation;
  logos: any;
}

interface Props {
  channelId?: string;
  i18n: Translation;
  logos: any;
  onSuccess: () => void;
  onBack: () => void;
  videoRef: any;
  active?: boolean;
}

const LockChannelOverlay = ({
  channelId,
  logos,
  i18n,
  onSuccess,
  onBack,
  videoRef,
  active = false
}: Props) => {
  const channelLogo = useMemo(
    () => logos && channelId && logos[channelId]?.pngWhite,
    [logos, channelId]
  );

  useEffect(() => {
    // Makes sure that focus is not lost during overlay changing
    videoRef.current.focus();
  }, [videoRef, channelId]);

  if (isEmpty(i18n)) return <></>;

  return (
    <div className="player-overlay-container locked-overlay-container">
      <div className="pin-container">
        <Image className="channel-logo" src={channelLogo} />
        <PinPrompt onSuccess={onSuccess} onBack={onBack} active={active} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ i18n, logos }: StoreState) => ({
  i18n,
  logos
});

export default React.memo(connect(mapStateToProps, {})(LockChannelOverlay));
