import { CATEGORY_FAVORITES_TYPE } from 'common/config/constants';
import variables from 'common/config/variables';
import {
  APP_CLEAR_FAVORITE_CHANNELS,
  APP_SET_FAVORITE_CHANNELS
} from 'common/constants/action-types';
import { AppTheme } from 'common/interfaces';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { AppDispatch, StoreState } from 'index';

export const setFavoriteChannels = (ids: string[]) => {
  return (dispatch: AppDispatch, getState: () => StoreState) => {
    // Convert array to object type
    // { [id]: FavoriteChannels }
    const { liveChannels } = getState();
    const favoriteChannels = ids.reduce((acc: FavoriteChannels, id, index) => {
      const channel = liveChannels[id];
      if (!channel) return acc;
      const channelPosition =
        variables.WHITELABEL_TYPE === AppTheme.LINEAR ? channel.position : index + 1;

      acc[id] = {
        ...channel,
        position: channelPosition,
        category: CATEGORY_FAVORITES_TYPE.id
      };
      return acc;
    }, {});

    dispatch({
      type: APP_SET_FAVORITE_CHANNELS,
      payload: favoriteChannels
    });
  };
};

export const clearFavoriteChannels = () => {
  return (dispatch: AppDispatch) =>
    dispatch({
      type: APP_CLEAR_FAVORITE_CHANNELS,
      payload: {}
    });
};
