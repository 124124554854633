import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import FieldError from 'common/assets/bulsatcom/media/field_error.svg';
import { FocusableItem } from 'common/interfaces';
import { getCaretPosition, setCaretPosition } from 'common/utils';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import {
  HelperText,
  Icon,
  InputContainer,
  InputStyled,
  Placeholder,
  PlaceholderContainer,
  RootContainer
} from './Input.styles';

interface InputProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'onFocus' | 'onChange' | 'onBlur' | 'onClick' | 'onMouseLeave'
    >,
    FocusableItem {
  name: string;
  onChangeText?: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string;
}

export default function Input(props: InputProps) {
  const { error, placeholder, onFocus, onBlur, onChangeText, ...rest } = useMemo(
    () => props,
    [props]
  );
  const { focused, ref } = useFocusable({
    onFocus: (layout: FocusableComponentLayout, objProps: object, details: FocusDetails) => {
      onFocus && onFocus(layout, objProps, details);
      // Sync focus
      ref.current && ref.current.focus();
    },
    onEnterPress: (data: any, details: KeyPressDetails) => {
      props.onEnterPress && props.onEnterPress(data, details);
      // Auto focus on enter press
      ref.current && ref.current.focus();
    },
    onBlur: (layout: FocusableComponentLayout, objProps: object, details: FocusDetails) => {
      onBlur && onBlur(layout, objProps, details);
      // Sync blur
      ref.current && ref.current.blur();
    },
    onArrowPress: (direction: string, objProps: object, details: KeyPressDetails) => {
      const inputFieldRef = ref.current as HTMLInputElement;
      const defaultHandler = () =>
        props.onArrowPress ? props.onArrowPress(direction, objProps, details) : true;
      const caretPosition = getCaretPosition(inputFieldRef);

      if (direction === 'left' || direction === 'right') {
        const caretPositionOffset = direction === 'left' ? -1 : 1;
        const position = caretPosition + caretPositionOffset;
        position >= 0 &&
          position <= inputFieldRef.value.length &&
          setCaretPosition(inputFieldRef, position);
        return false;
      }

      return defaultHandler();
    },
    focusKey: props.focusKey
  });

  useEffect(() => {
    ref.current.setAttribute('value', '');
    const inputFieldSetValue = () => {
      ref.current.setAttribute('value', ref.current.value);
    };

    ref.current?.addEventListener('input', inputFieldSetValue);

    return () => {
      ref.current && ref.current.removeEventListener('input', inputFieldSetValue);
    };
  }, []);

  return (
    <RootContainer>
      <InputContainer>
        <InputStyled
          {...rest}
          type={rest.type || 'text'}
          autoComplete="off"
          onChange={onChangeText}
          ref={ref}
          className={'input-contain'}
          focused={focused}
        />

        <PlaceholderContainer className="placeholder-text">
          <Placeholder className="text">{placeholder}</Placeholder>
        </PlaceholderContainer>

        {error && <Icon src={FieldError} />}
      </InputContainer>

      {error && <HelperText>{error}</HelperText>}
    </RootContainer>
  );
}
