import { playVideo, setVideoOptions, translationPacket } from 'common/actions';
import { setDetailedInfoAsset } from 'common/actions/detailed-info';
import ChannelsCategorySelectorView from 'common/components/channels-category-selector-view/ChannelsCategorySelectorView';
import { LiveChannelListItem } from 'common/components/live-channel-list-item';
import { PaidChannelPopup } from 'common/components/message-popups';
import { Stripe } from 'common/components/stripe';
import TVChannelsBannerBackground from 'common/components/tv-channel-banner-background/TVChannelsBannerBackground';
import { ViewWithDetails } from 'common/components/view-with-details';
import variables from 'common/config/variables';
import {
  Category,
  Epg,
  IViewWithDetailsStripeItem,
  LiveChannel,
  LiveChannels
} from 'common/constants/data-types';
import { usePopup } from 'common/contexts/popup';
import protect from 'common/HoC/protect';
import { AppTheme } from 'common/interfaces';
import { IDetailedInfo } from 'common/reducers/detailedInfo';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { useParentalLock } from 'common/utils/hooks';
import { usePinPopup } from 'common/utils/hooks/usePinPopup';
import { isEmpty } from 'lodash';
import React, {
  startTransition,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState
} from 'react';
import { connect } from 'react-redux';
const { STRIPE_PADDING } = variables;

interface StateProps {
  liveChannels: LiveChannels;
  favoriteChannels: FavoriteChannels;
  logos: any;
  i18n: typeof translationPacket;
  setVideoOptions: any;
  detailedInfo: IDetailedInfo;
  playVideo: (currentepg: Epg, options?: any) => void;
  setDetailedInfoAsset: (asset: IDetailedInfo['asset']) => void;
  videoPlayer: IVideoPlayer;
}

interface TVChannelsProps extends StateProps {
  currentPlayingChannelId?: string;
}

function TVChannels(props: TVChannelsProps) {
  const [currentCategory, setCurrentCategory] = useState<Partial<Category> | null>(null);
  const deferredCategory = useDeferredValue(currentCategory);
  const { openPopup } = usePopup();
  const { openPinPopup } = usePinPopup();
  const { getChannelsLockedState, unlock: temporarilyUnlock } = useParentalLock();

  const hideFavoriteChannels = useMemo(
    () =>
      isEmpty(props.favoriteChannels) ||
      (variables.WHITELABEL_TYPE === AppTheme.LINEAR && !deferredCategory),
    [props.favoriteChannels, deferredCategory]
  );

  const formattedChannels = useMemo(() => {
    if (isEmpty(props.liveChannels)) return [];
    const channels = [
      ...(hideFavoriteChannels ? [] : Object.values(props.favoriteChannels)),
      ...Object.values(props.liveChannels)
    ];
    return channels
      .filter((e) => e.visible)
      .filter((e) => (deferredCategory ? e.category === deferredCategory.position : true))
      .map((channel: LiveChannel) => ({
        ...channel,
        isLiveCard: true
      }));
  }, [props.liveChannels, deferredCategory]);

  // TODO: make a generic function for this
  const openPlayer = useCallback(
    (channel: IViewWithDetailsStripeItem) => {
      const video = channel.isLiveCard ? channel.currentepg : channel;
      const channelsLockedState = getChannelsLockedState();
      const playingSameChannel = props.currentPlayingChannelId === video.chan_id;

      const handlePlay = () => {
        props.playVideo(video, { channelPosition: channel.position });
        props.setVideoOptions({
          isFullscreen: true
        });
      };

      if (!channel.access) {
        openPopup({
          id: 'paid-channel-popup',
          type: 'primary',
          content: <PaidChannelPopup popupId="paid-channel-popup" />
        });
      } else if (channel.locked && channelsLockedState && !playingSameChannel) {
        openPinPopup({
          onSuccess: () => {
            temporarilyUnlock();
            handlePlay();
          }
        });
      } else {
        handlePlay();
      }
    },
    [props.currentPlayingChannelId]
  );

  const renderLiveListItem = useCallback(
    (item: LiveChannel) => {
      return (
        <LiveChannelListItem
          current={item.currentepg}
          next={item.nextepg}
          logo={props.logos[item.id]?.pngWhite}
          i18n={props.i18n}
          progress={item.progress}
          position={item.position}
          locked={item.locked}
          adult={item.adult}
          thumbnail={item.thumbnail}
        />
      );
    },
    [props.i18n, deferredCategory]
  );

  const renderLiveChannelList = useCallback(() => {
    return (
      <Stripe
        onStripeItemPress={openPlayer}
        items={formattedChannels}
        renderItem={renderLiveListItem}
      />
    );
  }, [formattedChannels]);

  useEffect(() => {
    if (props.detailedInfo.asset && !isEmpty(props.liveChannels)) {
      const current = props.liveChannels[props.detailedInfo.asset.epg.chan_id];
      startTransition(() =>
        props.setDetailedInfoAsset({
          epg: current?.currentepg,
          isLiveCard: true,
          resolution: current?.resolution
        })
      );
    }
  }, [props.liveChannels]);

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset && !isEmpty(props.liveChannels)) {
        const epg = props.liveChannels[asset.id]?.currentepg;

        // if adult channel return undefined
        if (props.liveChannels[asset.id]?.adult) {
          return undefined;
        }

        const newAsset = {
          epg,
          isLiveCard: !!asset.isLiveCard,
          resolution: asset.isLiveCard ? asset.resolution : props.liveChannels[asset.id]?.resolution
        };
        return epg && newAsset;
      } else {
        return undefined;
      }
    },
    [props.liveChannels]
  );

  return (
    <ViewWithDetails
      focusKey={location.pathname}
      disableBanners
      customBannerView={<TVChannelsBannerBackground />}
      axis="y"
      stripeAlign="center"
      stripeScrollPadding={STRIPE_PADDING}
      getSelectedAssetData={getSelectedAssetData}
      stripeItemClassName="tv-channel-item"
      stripeHeader={
        <ChannelsCategorySelectorView
          currentCategory={deferredCategory}
          onCategorySelect={setCurrentCategory}
        />
      }
    >
      {renderLiveChannelList()}
    </ViewWithDetails>
  );
}

const mapStateToProps = ({
  liveChannels,
  i18n,
  logos,
  detailedInfo,
  favoriteChannels,
  videoPlayer
}: StateProps) => ({
  liveChannels,
  favoriteChannels,
  i18n,
  logos,
  detailedInfo,
  currentPlayingChannelId: videoPlayer.channelId
});

export default React.memo(
  protect(
    connect(mapStateToProps, {
      playVideo,
      setVideoOptions,
      setDetailedInfoAsset
    })(TVChannels)
  )
);
