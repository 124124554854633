import { H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

GlobalStyle.inject`
    .linear-search-page {
        overflow: hidden;
        &.search-active {
            background: ${hexToRGB(variables.background_color_dark, 0.9)};
            & > * {
                background: unset !important;
                transform: translateY(${getScalablePixel(150)}px);
            }
        }

        .no-results-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            ${H2} {
                font-size: ${getScalablePixel(32)}px;
            }

            img {
                width: ${getScalablePixel(258)}px;
            }
        }
    }
`;
