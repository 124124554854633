// Import the functions you need from the SDKs you need
import { PRODUCT_NAME } from 'common/config/constants';
// eslint-disable-next-line import/named
import { Analytics, getAnalytics as innerGetAnalytics, logEvent } from 'firebase/analytics';
// eslint-disable-next-line import/named
import { getPerformance, PerformanceTrace, trace } from 'firebase/performance';
// eslint-disable-next-line import/named
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
// organize-imports-disable-next-line
import { SEARCH_LOGEVENT_TIMEOUT } from 'common/config/variables/default';
import { debounce, isEmpty } from 'lodash';

let firebaseConfig: FirebaseOptions | null = null;
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  firebaseConfig = require(`common/config/firebase/${PRODUCT_NAME}.ts`).default;
} catch (e) {
  console.warn('');
}

let analytics: Analytics | null = null;
let app: FirebaseApp | null = null;

// Initialize Firebase
export const initializeFirebase = () => {
  try {
    if (isEmpty(firebaseConfig)) throw new Error(`No firebase config found for ${PRODUCT_NAME}`);
    app = initializeApp(firebaseConfig);
    analytics = innerGetAnalytics(app);
    // Enable auto collection
    analytics.app.automaticDataCollectionEnabled = true;
    // Enable performance
    const perf = getPerformance(app);
    perf.dataCollectionEnabled = true;
    perf.instrumentationEnabled = true;
    return analytics;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const getAnalytics = () => {
  try {
    if (isEmpty(firebaseConfig)) return null;
    if (isEmpty(analytics)) throw new Error('Analytics not initialized');
    return analytics;
  } catch (error) {
    return null;
  }
};

const _logEvent = (eventName: string, eventParams?: object) => {
  try {
    if (isEmpty(firebaseConfig)) return null;
    if (isEmpty(analytics)) throw new Error('Analytics not initialized');

    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.warn(error);
  }
};

let playerStartTrace: PerformanceTrace | null = null;
let playerTraceStarted = false;
const getPlayerStartTrace = () => {
  try {
    if (isEmpty(firebaseConfig)) return null;

    if (isEmpty(app)) throw new Error('Analytics not initialized');
    playerStartTrace = !playerTraceStarted
      ? trace(getPerformance(app), 'playerStart')
      : playerStartTrace;
    return {
      start: () => {
        // If already started but not stopped
        // Create a new instance
        if (playerTraceStarted) playerStartTrace = trace(getPerformance(app!), 'playerStart');
        //
        playerStartTrace!.start();
        playerTraceStarted = true;
      },
      stop: () => {
        if (!playerTraceStarted) return;
        playerStartTrace!.stop();
        playerTraceStarted = false;
      }
    };
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const logUserSearch = debounce(
  (searchTerm: string) => _logEvent('search', { searchTerm }),
  SEARCH_LOGEVENT_TIMEOUT
);

export default { getAnalytics, logEvent: _logEvent, getPlayerStartTrace, logUserSearch };
