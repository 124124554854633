import styled from 'styled-components';

import ArrowRight from '@assets/media/arrow-right.svg';
import { FlexRowMixin } from 'common/components/common/style';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

const {
  color_primary,
  DEFAULT_ANIMATION_DURATION,
  font_color_b,
  font_color_white,
  MENU_SIZE,
  SAFE_AREA_VIEW_PADDING,
  SEARCH_TRANSITION_DURATION,
  background_color_dark
} = variables;

export enum MenuStatesEnum {
  main = 'main',
  bannerActive = 'banner-active',
  detailedInfo = 'detailed-info'
}

export type MenuViewStates = `${MenuStatesEnum}`;

GlobalStyle.inject`
.top-menu-wrapper {
  position: relative;
  padding-left: ${SAFE_AREA_VIEW_PADDING}px;
  padding-right: ${SAFE_AREA_VIEW_PADDING}px;
  padding-bottom: ${getScalablePixel(18)}px;
  padding-top: ${getScalablePixel(56)}px;
  height: ${MENU_SIZE}px;
  width: 100%;
  z-index: 2;
  display: none;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  transition-property: transform;
  transition-duration: ${DEFAULT_ANIMATION_DURATION}ms;
  opacity: 1;
  box-shadow: -${getScalablePixel(8)}px ${getScalablePixel(8)}px ${getScalablePixel(
  16
)}px 0 rgba(0, 0, 0, 0.64);
  background-color: ${hexToRGB(background_color_dark, 0.6)};
  box-sizing: border-box;

  &.visible {
    display: flex;
  }

  &.search-active {
    .menu-item:not(.search-item), .date-time, .vertical-separator {
      will-change: opacity;
      transition: opacity ${SEARCH_TRANSITION_DURATION}ms;
      opacity: 0.1;
    }
  }

  &.banner-active {
    transform: translate3d(0, -${getScalablePixel(20)}px, 0);
    opacity: 0.2;
    box-shadow: -8px 8px 16px 0 rgba(0, 0, 0, 0.64);
    background-color: ${hexToRGB(background_color_dark, 0.6)};
  }
  &.detailed-info {
    transform: translate3d(0, -${getScalablePixel(20)}px, 0);
    opacity: 1;
    box-shadow: none;
    background-color: transparent;
  }

  .menu-section-container {
    ${FlexRowMixin}
    justify-content: space-between;
    opacity: 0;
    &.visible {
      opacity: 1;
    }

    & .menu-item {
      padding: ${`0 ${getScalablePixel(20)}px`};
    }
  }

  .product-logo-wrapper {
    display: flex;
    align-items: center;
    width: ${getScalablePixel(125)}px;
    height: ${getScalablePixel(56)}px;
    & img {
      height: 100%;
    }
  }

  .menu-items-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${`${getScalablePixel(16)}px 0`};
    position: relative;
    transform: translate3d(${getScalablePixel(119)}px, 0, 0);
    transition: transform ${DEFAULT_ANIMATION_DURATION}ms;

    &.detailed-info {
      transform: translate3d(0, 0, 0);

      .menu-items {
        opacity: 0;
      }
    }

    .menu-items {
      display: flex;
      flex-direction: row;
      & > *:not(:last-child) {
        margin-right: ${getScalablePixel(22)}px;
      }
    }

    .breadcrumb-container {
      overflow: visible;
    }
  }

  
}

.menu-detailed-info-wrapper {
  display: flex;
  align-items: center;
  min-height: ${getScalablePixel(34)}px;
  padding-left: ${getScalablePixel(36)}px;
  font-family: Default-Font;
  font-weight: bold;
  font-size: ${getScalablePixel(28)}px;

  .menu-separator {
    display: inline-block;
    opacity: 0.6;
    background-color: ${variables.background_color_white};
    height: ${getScalablePixel(24)}px;
    width: ${getScalablePixel(1)}px;
  }

  .menu-detailed-info-text {
    display: inline-block;
    color: ${font_color_b};
    margin-left: ${getScalablePixel(13)}px;
    &.active {
      color: ${font_color_white};
    }
  }

  .menu-detailed-sub-text {
    display: inline-block;
    position: relative;
    color: ${variables.font_color_white};
    margin-left: ${getScalablePixel(24)}px;

    &:before {
      content: ' ';
      background-color: ${font_color_white};
      display: inline-block;
      width: ${getScalablePixel(6)}px;
      height: ${getScalablePixel(6)}px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - ${getScalablePixel(3)}px);
      left: -${getScalablePixel(24) / 2 + getScalablePixel(3)}px;
    }
  }
}

.product-logo-wrapper {
  display: flex;
  align-items: center;
  width: ${getScalablePixel(125)}px;
  height: ${getScalablePixel(56)}px;
  & img {
    height: 100%;
  }
}

.menu-item {
  position: relative;
  padding: ${`0 ${getScalablePixel(10)}px`};
  text-align: center;
  font-size: ${getScalablePixel(30)}px;
  color: ${font_color_white};
  opacity: 0.5;
  letter-spacing: 1px;
  transition: color ${DEFAULT_ANIMATION_DURATION}ms, opacity ${DEFAULT_ANIMATION_DURATION}ms;
  will-change: opacity;
  &.focused {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}

.menu-selector {
  position: absolute;
  bottom: 0;
  left: 0;
  will-change: width, transform;
  border-bottom: solid ${getScalablePixel(4)}px ${color_primary};
  width: ${getScalablePixel(1)}px;
  transition: transform ${DEFAULT_ANIMATION_DURATION}ms, width ${DEFAULT_ANIMATION_DURATION}ms;
  transform-origin: center;
}

.search-icon {
  position: relative;
  z-index: 1;
}

.search-item-container {
  position: absolute;
  top: 50%;
  right: 0;
  min-width: ${getScalablePixel(540)}px;
  height: ${getScalablePixel(64)}px;
  border-radius: 106px;
  border: solid ${getScalablePixel(2)}px rgba(255, 255, 255, 0.2);
  background: ${hexToRGB(background_color_dark, 60)};

  padding: ${getScalablePixel(14)}px ${getScalablePixel(72)}px ${getScalablePixel(
  14
)}px ${getScalablePixel(37)}px;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-50%) scale(0, 1);
  transform-origin: right;
  opacity: 0;
  transition: transform ${SEARCH_TRANSITION_DURATION}ms, opacity ${DEFAULT_ANIMATION_DURATION}ms ${DEFAULT_ANIMATION_DURATION}ms;
  will-change: transform, opacity;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 101%;
    width: ${getScalablePixel(160)}px;
    height: 100%;
    background: linear-gradient(to left, ${hexToRGB(
      background_color_dark,
      60
    )} 0%, rgba(0,0,0,0) 100%);
  }

  &.active {
    transition: transform ${SEARCH_TRANSITION_DURATION}ms, opacity ${DEFAULT_ANIMATION_DURATION}ms;
    opacity: 1;
    transform: translateY(-50%) scale(1, 1);
  }

  input {
    all: unset;
    flex: 1;
    font-size: ${getScalablePixel(26)}px;
    color: ${font_color_white};
    text-align: left;

    &::placeholder {
      font-size: ${getScalablePixel(26)}px;
      color: ${font_color_white};
      opacity: 0.4;
      text-align: left;
    }
  }
}
`;

const SIDE_MENU_EXPANDED_WIDTH = getScalablePixel(1440);

GlobalStyle.inject`
.side-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; 
  z-index: 3;
  width: ${variables.MENU_SIZE}px;
  padding: ${getScalablePixel(76)}px 0;
  position: relative;
  transform: translateX(-100%);
  transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
  
  &.visible {
    transform: translateX(0);
  }

  &.removed {
    display: none;
  }

  &.focused {
    .side-menu-item-active {
      opacity: 0.6;
    }
    .menu-item-title {
      opacity: 1;
    }

    .side-menu-mask {
      transform: scale3d(1, 1, 1);
    }

    .menu-item-active-selector {
      opacity: 0 !important;
    }

    .product-logo-wrapper {
      transform: scale(2);
    }
  }

  .product-logo-wrapper {
    /* width: ${getScalablePixel(98)}px; */
    transition: transform ${DEFAULT_ANIMATION_DURATION}ms;
    transform-origin: left center;
    position: relative;
    will-change: transform;
  }

  .breadcrumb-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${getScalablePixel(variables.SAFE_AREA_VIEW_PADDING)}px;
    opacity: 0;
    transition: opacity 0ms 0ms;
    will-change: opacity, content;

    &.visible {
      transition: opacity ${DEFAULT_ANIMATION_DURATION}ms ${DEFAULT_ANIMATION_DURATION}ms;
      opacity: 1;
    }

    .menu-separator {
      margin-left: -${getScalablePixel(13)}px;
    }
    
    .menu-detailed-info-wrapper {
      padding: 0;
      width: max-content;
    }
  }

  .side-menu-mask {
    width: ${SIDE_MENU_EXPANDED_WIDTH}px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(90deg, ${variables.color_grey_90} 18%, 
      ${hexToRGB(variables.color_grey_90, 0.8)} 36%,
      ${hexToRGB(variables.color_grey_90, 0.5)} 65%,
      transparent 100%);
    /* background: linear-gradient(90deg, ${variables.color_grey_90} 22%, 
      ${hexToRGB(variables.color_grey_90, 0.8)} 54%, rgba(0, 0, 0, 0) 100%); */
    transform: scale3d(${variables.MENU_SIZE / SIDE_MENU_EXPANDED_WIDTH}, 1, 1);
    transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
    transform-origin: left;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
  }

  .menu-icon {
    width: ${getScalablePixel(36)}px;
    height: ${getScalablePixel(36)}px;
  }

  .menu-item {
    margin: ${getScalablePixel(12)}px 0;
  }


  .menu-item-title {
    font-size: ${getScalablePixel(30)}px;
    font-weight: 700;
    color: ${variables.color_white};
    width: max-content;
    opacity: 0;
    transition: opacity ${variables.DEFAULT_ANIMATION_DURATION}ms;
    position: absolute;
    padding-left: ${getScalablePixel(24)}px;
    will-change: opacity;
  }
  .menu-item-active-selector {
    background-color: ${variables.color_primary};
    width: ${getScalablePixel(46)}px;
    height: ${getScalablePixel(6)}px;
    border-radius: ${getScalablePixel(40)}px;
    opacity: 0;
    position: absolute;
    bottom: -${getScalablePixel(4)}px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity ${DEFAULT_ANIMATION_DURATION}ms;
    &.active {
      opacity: 1;
    }
  }
}
`;

GlobalStyle.inject`
.close-menu-view {
  position: fixed;
  bottom: ${getScalablePixel(50)}px;
  right: ${getScalablePixel(73)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: opacity ${variables.DEFAULT_ANIMATION_DURATION}ms;

  &::after {
    content: '';
    position: absolute;
    top: -186%;
    left: -75%;
    width: ${getScalablePixel(800)}px;
    height: ${getScalablePixel(300)}px;
    z-index: -1;
    background: radial-gradient(
      ${hexToRGB(variables.color_grey_90, 0.8)} 0%,
      ${hexToRGB(variables.color_grey_90, 0.6)} 25%,
      transparent 70%);
  }

  &.visible {
    opacity: 1;

    &.active {
      .close-menu-arrow {
        svg {
          animation: offsettozero ${variables.AUTO_CLOSE_SIDEMENU_TIMEOUT}ms linear forwards;
        }
      }
      &.initial-open {
        .close-menu-arrow {
          svg {
            animation: offsettozero ${
              variables.AUTO_CLOSE_SIDEMENU_INITIAL_TIMEOUT
            }ms linear forwards;
          }
        }
      }
    }
  }

  .close-menu-title {
    color: ${variables.color_grey_30};
    font-size: ${getScalablePixel(20)}px;
    text-transform: uppercase;
    padding-right: ${getScalablePixel(24)}px;
    opacity: 0.6;
  }
  .close-menu-arrow {
    width: ${getScalablePixel(40)}px;
    height: ${getScalablePixel(40)}px;
    background-image: url(${ArrowRight});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${getScalablePixel(14)}px;
    opacity: 0.6;

    svg {
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);
      stroke-dasharray: 126;
      stroke-dashoffset: 126;
      overflow: visible;

      circle {
        cx: ${getScalablePixel(20)}px;
        cy: ${getScalablePixel(20)}px;
        r: ${getScalablePixel(20)}px;
        stroke: ${variables.color_grey_30};
        stroke-width: ${getScalablePixel(3)};
        fill: transparent;
      }
    }
    
    @keyframes offsettozero {
      to {
        stroke-dashoffset: 0;
      }
    }
    
  }
}
`;

export const DetailedInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: ${getScalablePixel(34)}px;
  padding-left: ${getScalablePixel(36)}px;
  font-family: Default-Font;
  font-weight: bold;
  font-size: ${getScalablePixel(28)}px;
`;
