import { getCurrentFocusKey, setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { MENU_ROUTES, ROUTE_ARCHIVE, ROUTE_HOME, ROUTE_SIGN_IN } from 'common/config/constants';
import FocusHistory from 'common/utils/FocusHistory';
import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getKey } from '..';

// export const previousFocusKeys: FocusKeysMap = {};
// export const currentFocusKeys: FocusKeysMap = {};

export let prevPath = '';

type Route = (typeof MENU_ROUTES)[number] | number;

const usePageNavigation = () => {
  const innerNavigate = useNavigate();
  const location = useLocation();

  const isPrevious = useCallback((page: Route) => {
    return isNumber(page) && page < 0 && prevPath;
  }, []);

  //   const processBeforeNavigate = (page: Route) => {};

  const processAfterNavigate = useCallback((page: Route) => {
    // Check if going to previous page
    const previous = FocusHistory.getPreviousByPath(prevPath);
    if (isPrevious(page) && previous) {
      setFocus(previous.focusKey);
      return;
    }
    //Else handle normally
    switch (page) {
      case ROUTE_HOME:
      case ROUTE_ARCHIVE:
        setFocus(getKey('stripe', 0, page));
        break;
      case ROUTE_SIGN_IN:
        setFocus(getKey('input', 'email'));
        break;
      default:
        {
          const key = getKey('stripe', 0, isNumber(page) ? prevPath : page);
          setFocus(key);
        }
        break;
    }
  }, []);

  const navigate = useCallback(
    (page: Route, withoutProcess?: boolean, replace?: boolean) => {
      const prevFocusKey = getCurrentFocusKey();
      // processBeforeNavigate(page);
      innerNavigate(page as any, { replace });
      !withoutProcess && processAfterNavigate(page);
      FocusHistory.setPrevious(prevFocusKey, location.pathname);
      prevPath = location.pathname;
      FocusHistory.setCurrent(getCurrentFocusKey(), location.pathname);
      if (isPrevious(page)) {
        FocusHistory.clearHistoryForPath(location.pathname);
      }
    },
    [location.pathname]
  );

  return navigate;
};

export default usePageNavigation;
