import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

interface Props {
  id: string;
  title: string | React.ReactNode;
  subtitle?: string;
  disabled?: boolean;
}

function InnerSettingsMenuItem({ id, title, subtitle, disabled }: Props) {
  const disabledClass = useMemo(() => {
    return disabled && 'disabled';
  }, [disabled]);

  const hasSubtitleClass = useMemo(() => (!isEmpty(subtitle) ? 'has-subtitle' : ''), [subtitle]);

  return (
    <div className={`inner-settings-menu-item ${disabledClass} ${hasSubtitleClass}`} key={id}>
      <div className="inner-settings-menu-title">
        {/* inner menu title */}
        {title}
      </div>
      {/* inner menu subtitle */}
      {!isEmpty(subtitle) && <div className="inner-settings-menu-subtitle">{subtitle}</div>}
    </div>
  );
}

export default React.memo(InnerSettingsMenuItem);
