import ArchiveIcon from '@assets/media/archive-icon.png';
import HomeIcon from '@assets/media/home-icon.png';
import LiveChannelsIcon from '@assets/media/live-channels-icon.png';
import MainSettingsIcon from '@assets/media/main-settings-icon.svg';
import SearchIcon from '@assets/media/side-menu-search-icon.png';
import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { Translation } from 'common/actions';
import { ExitPopup } from 'common/components/exit-popup';
import Icon from 'common/components/Icon';
import { Image } from 'common/components/image';
import { MainSettingsDrawer } from 'common/components/main-settings';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import {
  ROUTES_WITH_HEADER,
  ROUTE_ARCHIVE,
  ROUTE_HOME,
  ROUTE_SEARCH,
  ROUTE_TV_CHANNELS
} from 'common/config/constants';
import variables from 'common/config/variables';
import { Category, RecordedStore } from 'common/constants/data-types';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { FocusableItem } from 'common/interfaces';
import { getClassName, getKey, getRemoteKeyName } from 'common/utils';
import FocusHistory from 'common/utils/FocusHistory';
import { getPageName, getRootPath } from 'common/utils/helpers';
import { useOpennedOverlay, useVisibilityState } from 'common/utils/hooks';
import { isEmpty, throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import CloseMenuView from './CloseMenuView';
import DetailedInfo from './DetailedInfo';
import MenuItem from './MenuItem';
import { getRouteMatch, isMenuShown } from './utils';

interface MenuRoute {
  title: string;
  icon: string;
  path: string;
  disableBreadCrumb?: boolean;
}

interface SideMenuProps extends FocusableItem {
  focusKey?: string;
  initialOpen?: boolean;
  onMenuSelect?: () => void;
  // Redux injected
  i18n: Translation;
  isPlayerOpenned?: boolean;
  categories: RecordedStore['categories'];
  //
}

function SideMenu({
  focusKey: focusKeyParam,
  i18n,
  isPlayerOpenned,
  onFocus,
  onBlur,
  onMenuSelect: onMenuSelectParam,
  initialOpen,
  categories
}: SideMenuProps) {
  const { pathname } = useLocation();
  const isAppActive = useVisibilityState();
  const { openDrawer, closeDrawer, getDrawers } = useDrawer();

  const settingsOpened = useMemo(
    () =>
      getDrawers()
        .map((x) => x.id)
        .includes('main-settings-drawer'),
    [getDrawers]
  );

  const returnToPlayer = useCallback(() => {
    // First force focus to the needed item in the menu so that the container
    // Remembers the last focused item
    const key = getKey('menu-item', undefined, ROUTE_HOME);
    setFocus(key);
    //
    setFocus('PLAYER');
  }, []);

  const onArrowPress = useCallback((direction: string) => {
    if (direction === 'right') {
      returnToPlayer();
      return false;
    }
    return true;
  }, []);
  const menuIsShown = useMemo(() => isMenuShown(pathname), [pathname]);

  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusable: menuIsShown,
    focusKey: focusKeyParam,
    trackChildren: true,
    onFocus: (...args) => {
      onFocus && onFocus(...args);
      // Clear history for current path when user navigates to the menu
      FocusHistory.clearHistoryForPath(location.pathname);
      //
    },
    onBlur
  });

  const {
    focused: menuIsActive,
    setOpenned: setOpenedOverlay,
    setClosed: setClosedOverlay
  } = useOpennedOverlay(hasFocusedChild);
  const [subText, setSubText] = useState('');
  const { openPopup } = usePopup();

  const MENU_ROUTES: MenuRoute[][] = useMemo(() => {
    return [
      [
        {
          title: i18n?.pageNames?.search,
          icon: SearchIcon,
          path: ROUTE_SEARCH,
          disableBreadCrumb: true
        }
      ],
      [
        {
          title: i18n?.pageNames?.home,
          icon: HomeIcon,
          path: ROUTE_HOME
        },
        {
          title: i18n?.pageNames?.channels,
          icon: LiveChannelsIcon,
          path: ROUTE_TV_CHANNELS
        },
        {
          title: i18n?.pageNames?.archive,
          icon: ArchiveIcon,
          path: ROUTE_ARCHIVE
        }
      ]
    ];
  }, [i18n]);

  const MENU_ROUTES_FLAT = useMemo(() => MENU_ROUTES.flat(), [MENU_ROUTES]);

  const menuIsVisible = useMemo(
    () => (menuIsShown && !isPlayerOpenned) || hasFocusedChild,
    [menuIsShown, isPlayerOpenned, hasFocusedChild]
  );
  const disableBreadcrumbForCurrentPage = useMemo(() => {
    const currentPage = MENU_ROUTES_FLAT.find((e) =>
      getRootPath(location.pathname).includes(e.path)
    );
    return currentPage?.disableBreadCrumb;
  }, [location.pathname, MENU_ROUTES_FLAT]);
  const [autoCloseTimerActive, setAutoCloseTimerActive] = useState(hasFocusedChild);

  const menuItemForCurrentPageRef = useRef(
    getKey('menu-item', undefined, getRootPath(location.pathname))
  );

  useEffect(() => {
    const key = getKey('menu-item', undefined, getRootPath(location.pathname));
    menuItemForCurrentPageRef.current = key;
  }, [location.pathname]);

  // Hack to get the new position of the selected element
  // Only on elements in the header menu
  const handleFocus = () => {
    if (
      menuIsShown &&
      MENU_ROUTES_FLAT.map((e) => e.path).includes(getRootPath(location.pathname)) &&
      menuItemForCurrentPageRef.current
    ) {
      setFocus(menuItemForCurrentPageRef.current);
    }
  };
  // Used for initial page loading
  useEffect(handleFocus, [menuIsShown]);

  // Reset auto close timer when activity is tracked
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const handleTimer = () => {
      setAutoCloseTimerActive(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => setAutoCloseTimerActive(true), variables.THROTTLE_TIMEOUT);
    };
    if (menuIsShown && hasFocusedChild) {
      document.addEventListener('keydown', handleTimer);
      setAutoCloseTimerActive(true);
    }

    if (isAppActive && menuIsShown && hasFocusedChild && !settingsOpened) {
      setAutoCloseTimerActive(true);
    } else {
      setAutoCloseTimerActive(false);
    }

    return () => {
      document.removeEventListener('keydown', handleTimer);
      setAutoCloseTimerActive(false);
      clearTimeout(timeout);
    };
  }, [hasFocusedChild, isAppActive, menuIsShown, settingsOpened]);

  // Handle autoclose menu timeout
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (autoCloseTimerActive) {
      timeout = setTimeout(
        returnToPlayer,
        initialOpen
          ? variables.AUTO_CLOSE_SIDEMENU_INITIAL_TIMEOUT
          : variables.AUTO_CLOSE_SIDEMENU_TIMEOUT
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoCloseTimerActive, initialOpen]);

  // Handle subpage breadcrumbs
  useEffect(() => {
    if (Object.keys(categories).length) {
      const match = getRouteMatch(pathname);
      if (match) {
        const categoryKey = match[1];
        const matchedCategory = categories[categoryKey] as Category;

        setSubText(matchedCategory.name);
      }
    }
    return () => {
      if (getRouteMatch(pathname) && Object.keys(categories).length) {
        setSubText('');
      }
    };
  }, [pathname, categories]);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = throttle((e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        if (!(menuIsVisible && hasFocusedChild)) return;
        const currentFocusKey = getCurrentFocusKey();
        const homeMenuItem = 'home-menu-item';
        // Check if focused on home
        if (currentFocusKey === homeMenuItem) {
          openPopup({
            id: 'EXIT-POPUP',
            content: <ExitPopup />,
            returnToLastFocused: true,
            onOpen: setOpenedOverlay,
            onClose: setClosedOverlay
          });
          return;
        }
        // Must be last
        else {
          setFocus(homeMenuItem);
        }
      }
    }, variables.THROTTLE_TIMEOUT);
    let timeout: NodeJS.Timeout;
    if (menuIsVisible && hasFocusedChild) {
      timeout = setTimeout(() => {
        document.addEventListener('keyup', handleKeyPress);
      }, variables.THROTTLE_TIMEOUT);
    }
    return () => {
      clearTimeout(timeout);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [menuIsVisible, hasFocusedChild, openPopup]);

  const onMenuSelect = useCallback(() => {
    onMenuSelectParam && onMenuSelectParam();
  }, [onMenuSelectParam]);

  const renderMenu = () => {
    const items = MENU_ROUTES.map((outerRoutes, outerIndex) => {
      return (
        <div key={`inner-route-${outerIndex}`} className="menu-items">
          {outerRoutes.map((route) => {
            const key = getKey('menu-item', undefined, route.path);
            const pageName = getPageName(route.path);
            const pageTitle = isEmpty(i18n) ? '' : i18n.pageNames[pageName];
            const active = settingsOpened ? false : getRootPath(location.pathname) === route.path;

            return (
              <MenuItem
                key={key}
                route={route}
                disabled={!menuIsShown}
                navigateOnFocus={false}
                focusKey={key}
                active={active && !hasFocusedChild && !menuIsActive}
                onEnterPress={onMenuSelect}
                onClick={onMenuSelect}
                onArrowPress={onArrowPress}
              >
                <Image src={route.icon} className="menu-icon" />
                <span className="menu-item-title">{pageTitle}</span>
                <div className={getClassName('menu-item-active-selector', { active })} />
              </MenuItem>
            );
          })}
        </div>
      );
    });

    return <>{items}</>;
  };

  const openMainSettingsDrawer = () => {
    setOpenedOverlay();
    openDrawer({
      id: 'main-settings-drawer',
      content: <MainSettingsDrawer />,
      positionContent: 'top',
      onClose: () => {
        closeDrawer('main-settings-drawer');
        setClosedOverlay();
      }
    });
  };

  const mainSettingsIcon = useMemo(() => <Icon src={MainSettingsIcon} size="large" />, []);

  const renderSettingsButton = useCallback(() => {
    return (
      <MenuItem
        key="main-settings"
        focusKey="main-settings-menu-item"
        onEnterPress={openMainSettingsDrawer}
        onClick={openMainSettingsDrawer}
        onArrowPress={onArrowPress}
        active={settingsOpened}
      >
        <Image src={MainSettingsIcon} className="menu-icon" />
        <span className="menu-item-title">{i18n?.mainSettings?.settings}</span>
        <div className={getClassName('menu-item-active-selector', { active: settingsOpened })} />
      </MenuItem>
    );
  }, [i18n, mainSettingsIcon, onArrowPress, settingsOpened]);

  const currentPageTitle = useMemo(() => {
    const routeConfig = MENU_ROUTES_FLAT.filter((route) =>
      ROUTES_WITH_HEADER.includes(route.path || '')
    ).find((route) => route.path === getRootPath(pathname));
    if (!routeConfig) {
      return null;
    }
    return isEmpty(i18n) ? '' : i18n.pageNames[getPageName(routeConfig.path) || ''];
  }, [i18n, pathname]);

  const renderBreadcrumb = useCallback(() => {
    // render custom breadcrumb for current page or render the default breadcrumb
    return (
      <div
        className={getClassName('breadcrumb-container', {
          visible: !menuIsActive && menuIsVisible
        })}
      >
        {!disableBreadcrumbForCurrentPage && (
          <DetailedInfo text={currentPageTitle} subText={subText} />
        )}
      </div>
    );
  }, [menuIsActive, menuIsVisible, currentPageTitle, subText, disableBreadcrumbForCurrentPage]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={getClassName('side-menu-wrapper', {
          visible: menuIsVisible,
          focused: menuIsActive,
          removed: !menuIsShown
        })}
      >
        <div className="side-menu-mask" />
        <div className="product-logo-wrapper">
          <ProductLogo />
          {renderBreadcrumb()}
        </div>
        {renderMenu()}
        <div>{renderSettingsButton()}</div>
      </div>
      <CloseMenuView
        active={autoCloseTimerActive}
        visible={menuIsShown && hasFocusedChild}
        initialOpen={initialOpen}
      />
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n, videoPlayer, recorded }: any) => {
  const categories = recorded.categories as RecordedStore['categories'];

  return {
    i18n,
    isPlayerOpenned: videoPlayer.video?.isFullscreen,
    categories
  };
};

export default React.memo(connect(mapStateToProps, {})(SideMenu));
