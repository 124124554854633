import { Column } from 'common/components/common/style';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

export const SnackbarContainer = styled(Column)`
  background: ${variables.background_color_light};
  border-radius: ${getScalablePixel(16)}px;
  padding: ${getScalablePixel(12)}px ${getScalablePixel(18)}px;
  box-shadow: 0px 0px ${getScalablePixel(64)}px -${getScalablePixel(8)}px;
  & > * {
    margin: ${getScalablePixel(6)}px 0;
  }
`;
