import { clearSearch, playVideo, setVideoOptions, translationPacket } from 'common/actions';
import { Card } from 'common/components/cards';
import { Stripe } from 'common/components/stripe';
import { H2 } from 'common/components/Typography';
import { ViewWithDetails } from 'common/components/view-with-details';
import variables from 'common/config/variables';
import { Epg, IViewWithDetailsStripeItem, LiveChannels } from 'common/constants/data-types';
import { SearchState } from 'common/reducers/searchState';
import { getChannelLogo } from 'common/services/helpers';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

interface StateProps {
  i18n: typeof translationPacket;
  searchState: SearchState;
  logos: any;
  liveChannels: LiveChannels;
  playVideo: typeof playVideo;
  setVideoOptions: typeof setVideoOptions;
  clearSearch: VoidFunction;
}

type SearchProps = StateProps;

function Search(props: SearchProps) {
  // Clear state
  useEffect(() => {
    return () => {
      props.clearSearch();
    };
  }, []);

  const openPlayer = useCallback((asset: IViewWithDetailsStripeItem) => {
    props.playVideo(asset);
    props.setVideoOptions({
      isFullscreen: true
    });
  }, []);

  const renderArchiveCard = useCallback(
    (item: Epg) => (
      <Card
        key={`archive-card-${item.eventid}`}
        epg={item}
        logo={getChannelLogo(item.chan_id, props.logos)}
      />
    ),
    [props.logos]
  );

  const renderSearchList = useCallback(() => {
    if (isEmpty(props.searchState.recorded)) {
      return null;
    }
    const { recorded } = props.searchState;
    return (
      <Stripe
        key={'search'}
        items={recorded}
        renderItem={renderArchiveCard}
        title={props.i18n.search.results}
        onStripeItemPress={openPlayer}
      />
    );
  }, [props.searchState.recorded, openPlayer]);

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset.chan_id) {
        return {
          epg: asset,
          // Extract resolution from liveChannels
          resolution: props.liveChannels[asset.chan_id]?.resolution
        };
      }
      return undefined;
    },
    [props.liveChannels]
  );

  return (
    <ViewWithDetails
      hideBanners
      getSelectedAssetData={getSelectedAssetData}
      stripeHeader={
        props.searchState.hasSearch &&
        isEmpty(props.searchState.recorded) && (
          <H2 style={{ paddingLeft: variables.SAFE_AREA_VIEW_PADDING }}>
            {props.i18n.search.noResults}
          </H2>
        )
      }
    >
      {renderSearchList()}
    </ViewWithDetails>
  );
}

const mapStateToProps = ({ i18n, searchState, logos, liveChannels }: StateProps) => {
  return {
    i18n,
    searchState,
    logos,
    liveChannels
  };
};
export default React.memo(
  connect(mapStateToProps, {
    clearSearch,
    playVideo,
    setVideoOptions
  })(Search)
);
