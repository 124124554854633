import { Translation } from 'common/actions';
import { Row } from 'common/components/common/style';
import { useTrialTimeLogic } from 'common/utils/hooks';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import { MenuItemProps } from 'menu/MenuItem';
import React from 'react';
import { connect } from 'react-redux';
import {
  ColumnContainer,
  DemoPeriodTitle,
  RemainingSubtitle,
  RemainingTimePeriod,
  RemainingTimeValue,
  TrialCounterStyle
} from './Trial.styles';

interface TrialCounterProps extends Omit<MenuItemProps, 'onEnterPress'> {
  // Redux injected
  userDetails: any;
  i18n: Translation;
  //
}

const MenuTrialCounter = (props: TrialCounterProps) => {
  const data = useTrialTimeLogic();

  if (isEmpty(props.userDetails) || isEmpty(props.userDetails?.trial) || data?.expired) {
    return <></>;
  }

  return (
    <TrialCounterStyle>
      <Row>
        <RemainingTimeValue>{data?.number}</RemainingTimeValue>
        <ColumnContainer>
          <RemainingSubtitle>{data?.word}</RemainingSubtitle>
          <RemainingTimePeriod>{data?.wordTime}</RemainingTimePeriod>
        </ColumnContainer>
      </Row>
      <DemoPeriodTitle>{props.i18n?.trial?.demoPeriod}</DemoPeriodTitle>
    </TrialCounterStyle>
  );
};

const mapStateToProps = ({ userDetails, i18n }: StoreState) => ({
  userDetails,
  i18n
});

export default React.memo(connect(mapStateToProps, {})(MenuTrialCounter));
