import { APP_SET_CHANNEL_SETTINGS } from 'common/constants/action-types';

import request from 'common/services/request';
import { store } from 'index';

// Get channel settings
export const getChannelSettings = () => {
  return async (dispatch: typeof store.dispatch) => {
    try {
      const { data } = await request({
        method: 'get',
        url: 'users/me/channelSettings'
      });
      dispatch({
        type: APP_SET_CHANNEL_SETTINGS,
        payload: data
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

// This is used for PIN only
export const setLockedPin = (newPIN: string) => {
  return async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {
    try {
      const channelSettings = getState().channelSettings;

      const ajax = request({
        method: 'post',
        url: 'users/me/channelSettings',
        data: {
          ...channelSettings,
          lockedPIN: newPIN
        }
      });
      const response = await ajax;
      dispatch({
        type: APP_SET_CHANNEL_SETTINGS,
        payload: response.data
      });
    } catch (error) {
      console.warn(error);
    }
  };
};
