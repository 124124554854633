import { AppTheme } from 'common/interfaces';

export const color_primary = '#6769D0';
export const color_secondary = '#FEED01';

export const DEFAULT_VIEWMAP = '0deasdhv77a8c4195sdlfkjba14db72d85c12553';

export const DEFAULT_LOGO_PATH = 'https://msatpro.msatcable.com/channel-logos/test-350_msat.png';

export const WHITELABEL_TYPE = AppTheme.LINEAR;
