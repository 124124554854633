import { AppTheme } from 'common/interfaces';

export const color_primary = '#1f9cd7';
export const color_secondary = '#f06b44';

export const DISABLE_RIVE = false;

export const DEFAULT_VIEWMAP = 'd45707f9dbde3649d34a7d0d043e54be785d71ee';

export const WHITELABEL_TYPE = AppTheme.LINEAR;
