import {
  ROUTES_WITH_HEADER,
  ROUTE_ARCHIVE,
  ROUTE_ERROR_LOADING_DATA,
  ROUTE_HOME,
  ROUTE_INBOX,
  ROUTE_NO_SUBSCRIPTION,
  ROUTE_ROOT,
  ROUTE_SCREENSAVER,
  ROUTE_SEARCH,
  ROUTE_SIGN_IN,
  ROUTE_TOO_MANY_USERS,
  ROUTE_TRIAL_END,
  ROUTE_TRIAL_WELCOME,
  ROUTE_TV_CHANNELS
} from 'common/config/constants';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getClassName } from 'common/utils';
import { getRootPath } from 'common/utils/helpers';
import { StoreState } from 'index';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// eslint-disable-next-line import/named
import { RouteObject, useLocation } from 'react-router';
import { useRoutes } from 'react-router-dom';
import Archive from './Archive';
import CategoryArchive from './CategoryArchive';
import ErrorLoadingData from './ErrorLoadingData';
import Home from './Home';
import Inbox from './Inbox';
import Launcher from './Launcher';
import Login from './Login';
import NoSubscription from './NoSubscription';
import RestrictedAccess from './RestrictedAccess';
import Screensaver from './Screensaver';
import Search from './Search';
import TrialEnd from './TrialEnd';
import TrialWelcome from './TrialWelcome';
import TVChannels from './TVChannels';

export type TRoute = RouteObject & { title?: string };

export const routes: TRoute[] = [
  {
    path: ROUTE_ROOT,
    element: <Launcher />
  },
  {
    path: ROUTE_HOME,
    element: <Home />
  },
  {
    path: ROUTE_TV_CHANNELS,
    element: <TVChannels />
  },
  {
    path: ROUTE_ARCHIVE,
    element: <Archive />
  },
  {
    path: ROUTE_TRIAL_WELCOME,
    element: <TrialWelcome />
  },
  {
    path: ROUTE_TRIAL_END,
    element: <TrialEnd />
  },
  {
    path: `${ROUTE_ARCHIVE}/:category`,
    element: <CategoryArchive />
  },
  {
    path: ROUTE_SIGN_IN,
    element: <Login />
  },
  {
    path: ROUTE_TOO_MANY_USERS,
    element: <RestrictedAccess />
  },
  {
    path: ROUTE_NO_SUBSCRIPTION,
    element: <NoSubscription />
  },
  {
    path: ROUTE_ERROR_LOADING_DATA,
    element: <ErrorLoadingData />
  },
  {
    path: ROUTE_SEARCH,
    element: <Search />
  },
  {
    path: ROUTE_INBOX,
    element: <Inbox />
  },
  {
    path: ROUTE_SCREENSAVER,
    element: <Screensaver />
  }
];

interface PagesProps {
  className?: string;
}

const Pages = React.forwardRef<HTMLDivElement, PagesProps>(function Pages(props: PagesProps, ref) {
  const location = useLocation();
  const isPlayerOpenned = useSelector(
    (state: StoreState) => (state.videoPlayer as IVideoPlayer).video?.isFullscreen,
    shallowEqual
  );

  const menuIsShown = useMemo(() => {
    return ROUTES_WITH_HEADER.includes(getRootPath(location.pathname));
  }, [location.pathname]);

  return (
    <div
      ref={ref}
      className={getClassName(`page-wrapper ${props.className}`, {
        'menu-shown': menuIsShown,
        // Hide elements below player
        'player-openned': isPlayerOpenned
      })}
    >
      {useRoutes(routes)}
    </div>
  );
});

export default React.memo(Pages);
