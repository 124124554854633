import { Image } from 'common/components/image';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import styled, { css } from 'styled-components';
const {
  background_color_dark,
  border_color_b,
  font_color_c,
  font_color_d,
  font_color_dark,
  font_color_error,
  font_color_white
} = variables;

export const RootContainer = styled.div`
  margin-bottom: ${getScalablePixel(46)}px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface MovePlaceholderFragment {
  selector: string;
}
const MovePlaceholderMixin = ({ selector }: MovePlaceholderFragment) => css`
  .placeholder-text .text,
  ${selector} + .placeholder-text .text {
    background: ${background_color_dark};
    padding: 0 ${getScalablePixel(8)}px;
    opacity: 0.8;
    color: ${font_color_c};
    transform: translate3d(0, -170%, 0);
    font-size: ${getScalablePixel(18)}px;
  }
`;

export const InputStyled = styled.input<{ error?: boolean; focused?: boolean }>`
  height: ${getScalablePixel(62)}px;
  background: transparent;
  outline: unset;
  padding-left: ${getScalablePixel(24)}px;
  border-radius: ${getScalablePixel(6)}px;
  font-size: ${getScalablePixel(27)}px;
  color: ${font_color_white};
  border: solid ${getScalablePixel(2)}px
    ${({ error, focused }) =>
      focused ? border_color_b : error ? font_color_error : font_color_dark};
  ${MovePlaceholderMixin({ selector: ':focus' })}
  ${MovePlaceholderMixin({ selector: '&[value]:not([value=""])' })}
`;

export const PlaceholderContainer = styled.div`
  color: ${font_color_d};
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${getScalablePixel(24)}px;
  right: 0;
  border: ${getScalablePixel(3)}px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const Placeholder = styled.div`
  font-size: ${getScalablePixel(27)}px;
  transform: translate3d(0, 0, 0);
  transition-property: transform, font-size, background-color, color;
  transition-duration: ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  transition-timing-function: ease-out;
`;

export const HelperText = styled.div`
  margin-top: ${getScalablePixel(2)}px;
  font-size: ${getScalablePixel(22)}px;
  color: ${font_color_error};
`;

export const Icon = styled(Image)`
  position: absolute;
  width: ${getScalablePixel(28)}px;
  height: ${getScalablePixel(28)}px;
  right: ${getScalablePixel(31)}px;
  top: ${getScalablePixel(22)}px;
`;
