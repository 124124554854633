import { H4, P1 } from 'common/components/Typography';
// eslint-disable-next-line import/named
import { CustomContentProps, SnackbarContent } from 'notistack';
import React, { useCallback } from 'react';
import { SnackbarContainer } from './CustomSnackbar.styles';

interface ReportCompleteProps extends CustomContentProps {
  title?: string;
}

export const CustomSnackbar = React.forwardRef<HTMLDivElement, ReportCompleteProps>(
  function CustomSnackbar(props, ref) {
    const { id, message, title, ...rest } = props;

    const renderMessage = useCallback(() => {
      if (typeof message === 'string') return <P1 dangerouslySetInnerHTML={{ __html: message }} />;
      return message;
    }, [message]);

    return (
      <SnackbarContent ref={ref} role="alert" {...rest}>
        <SnackbarContainer>
          {title && <H4>{title}</H4>}
          {renderMessage()}
        </SnackbarContainer>
      </SnackbarContent>
    );
  }
);
