import { KeyPressDetails, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { FocusableItem } from 'common/interfaces';
import { getClassName } from 'common/utils';
import { getRootPath } from 'common/utils/helpers';
import { useHoverable, usePageNavigation } from 'common/utils/hooks';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import { TRoute } from 'pages';
import React, { useCallback, useRef } from 'react';
import { useLocation } from 'react-router';

export interface MenuItemProps extends FocusableItem {
  route?: TRoute;
  title?: string | React.ReactNode;
  focusKey?: string;
  disabled?: boolean;
  children?: string | React.ReactNode;
  className?: string;
  navigateOnFocus?: boolean;
  active?: boolean;
}

function MenuItem({
  route,
  title,
  focusKey,
  onFocus,
  onBlur,
  onEnterPress,
  onClick: onClickParam,
  children,
  className,
  disabled,
  navigateOnFocus = true,
  active: activeParam,
  onArrowPress
}: MenuItemProps) {
  const debounceNavigationRef = useRef<DebouncedFunc<VoidFunction> | null>(null);
  const location = useLocation();
  const navigate = usePageNavigation();
  const handleNavigate = useCallback(
    () =>
      route &&
      !disabled &&
      route.path &&
      getRootPath(location.pathname) !== route.path &&
      navigate(route.path, true),
    [route, disabled, location.pathname]
  );

  const { ref, focused } = useFocusable({
    focusKey,
    // extraProps: {  },
    onFocus: (...args) => {
      onFocus && onFocus(...args);
      if (navigateOnFocus) {
        debounceNavigationRef.current = debounce(handleNavigate, variables.LONG_THROTTLE_TIMEOUT);
        debounceNavigationRef.current();
      }
    },
    onEnterPress: (object: any, details: KeyPressDetails) => {
      onEnterPress && onEnterPress(object, details);
      handleNavigate();
    },
    onBlur: (...args) => {
      onBlur && onBlur(...args);
      debounceNavigationRef.current?.cancel();
    },
    onArrowPress
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  const onClick = useCallback(() => {
    onClickParam && onClickParam({});
    handleNavigate();
  }, [handleNavigate, onClickParam]);

  return (
    <div
      className={getClassName(`menu-item ${!isEmpty(className) ? className : ''}`, {
        focused: active,
        active: activeParam
      })}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children || title || ''}
    </div>
  );
}

export default React.memo(MenuItem);
