import { Translation } from 'common/actions';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import './Menu.styles';

interface CloseMenuViewProps {
  visible?: boolean;
  initialOpen?: boolean;
  active?: boolean;
  // Redux injected
  i18n: Translation;
  //
}

function CloseMenuView(props: CloseMenuViewProps) {
  if (isEmpty(props.i18n)) {
    return <></>;
  }
  return (
    <div
      className={getClassName('close-menu-view', {
        visible: props.visible,
        active: props.active,
        'initial-open': props.initialOpen
      })}
    >
      <span className="close-menu-title">{props.i18n.menu.closeMenu}</span>
      <span className="close-menu-arrow">
        <svg>
          <circle />
        </svg>
      </span>
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => {
  return {
    i18n
  };
};
export default React.memo(connect(mapStateToProps, {})(CloseMenuView));
