import { LOGOS_SET } from 'common/constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case LOGOS_SET:
      return action.payload;
    default:
      return state;
  }
}
