import { PRODUCT_NAME } from 'common/config/constants';
import { TRANSLATIONS_GET_COMMON } from 'common/constants/action-types';
import { AppDispatch } from 'index';
import { merge } from 'lodash';
import defaultTranslationPacket from './default.json';

export type Translation = typeof defaultTranslationPacket;
export { defaultTranslationPacket as translationPacket };

/**
 * Get all needed translations to run the app
 * @returns {function(*)}
 */
export const getTranslations = () => {
  return async (dispatch: AppDispatch) => {
    let translationPayload = defaultTranslationPacket;
    try {
      translationPayload = merge(
        defaultTranslationPacket,
        await import(`common/config/translations/${PRODUCT_NAME}.json`)
      );
      // Replace regex keys
      let stringified = JSON.stringify(translationPayload);
      stringified = stringified.replaceAll(
        translationPayload.telephoneNumberKey,
        translationPayload.telephoneNumber
      );
      // Return to json format
      translationPayload = JSON.parse(stringified);
      //
    } catch (error) {
      console.warn(`No translation found for '${PRODUCT_NAME}', using default`, error);
    }
    dispatch({
      type: TRANSLATIONS_GET_COMMON,
      payload: translationPayload
    });
  };
};
