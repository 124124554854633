import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';
const { font_color_white } = variables;

export const ClampContainerMixin = ({
  lines = 1,
  wordBreak = 'break-word'
}: {
  lines?: number;
  wordBreak?: 'break-all' | 'break-word';
}) => `
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${wordBreak};
`;

export const ClampContainer = styled.span<{ lines?: number }>`
  ${({ lines }) => ClampContainerMixin({ lines })}
`;

const Common = styled.span`
  user-select: none;
`;

const H = styled(Common)`
  font-family: 'Default-Font';
  font-weight: bold;
  color: ${font_color_white};
`;

export const H1 = styled(H)`
  font-size: ${getScalablePixel(54)}px;
`;

export const H2 = styled(H)`
  font-size: ${getScalablePixel(27)}px;
`;

export const H3 = styled(H)`
  font-size: ${getScalablePixel(23)}px;
`;

export const H4 = styled(H)`
  font-size: ${getScalablePixel(20)}px;
`;

const P = styled(Common)`
  font-family: 'Default-Font';
  font-weight: normal;
  color: ${font_color_white};
`;

const ParagraphSizeMap = { 1: 20, 2: 18, 3: 14 };
export const ParagraphMixin = ({ size = 1 }: { size?: number }) => `
  user-select: none;
  font-family: 'Default-Font';
  font-weight: normal;
  color: ${font_color_white};
  font-size: ${ParagraphSizeMap[size]};
`;

export const P1 = styled(P)`
  font-size: ${getScalablePixel(20)}px;
`;

export const P2 = styled(P)`
  font-size: ${getScalablePixel(18)}px;
`;

export const P4 = styled(P)`
  font-size: ${getScalablePixel(14)}px;
`;
