import { IconProps } from 'common/components/Icon';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import { isNil } from 'lodash';
import styled, { css } from 'styled-components';
const { font_color_white } = variables;

export type FlexAlign =
  | 'space-between'
  | 'space-around'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'initial';

export interface FocusableStyledComponentProps {
  focused?: boolean;
}

export interface Flex {
  justifyContent?: FlexAlign;
  alignItems?: FlexAlign;
  flex?: number;
  gap?: number;
}

export interface CommonStyle extends Flex {
  maxHeight?: string;
  minWidth?: string;
  padding?: string;
  // TODO: add more in the future
}

const Common = css<CommonStyle>`
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ flex }) => !isNil(flex) && `flex: ${flex}`};
  ${({ gap }) => !isNil(gap) && `gap: ${gap}px`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  position: relative;
`;

export const Row = styled.div<CommonStyle>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${Common};
`;

export const FlexRowMixin = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div<CommonStyle>`
  display: flex;
  flex-direction: column;
  ${Common};
`;

const IconSizeMap = {
  miniature: 10,
  verySmall: 16,
  small: 24,
  medium: 36,
  large: 48
};

export const IconStyle = ({ size }: IconProps) => `
  width: ${getScalablePixel(IconSizeMap[size])}px;
`;

export const VerticalSeparator = styled.div<{ height?: string; margin?: number }>`
  width: ${getScalablePixel(1)}px;
  height: ${({ height }) => (isNil(height) ? '80%' : height)};
  margin: 0 ${({ margin }) => getScalablePixel(margin || 2)}px;
  opacity: 0.5;
  background-color: #fff;
`;

export const RadioButton = styled(Row)<{ checked?: boolean }>`
  margin: 0 ${getScalablePixel(10)}px;
  width: ${getScalablePixel(28)}px;
  height: ${getScalablePixel(28)}px;
  border-radius: ${getScalablePixel(28)}px;
  background: transparent;
  border: solid ${getScalablePixel(3)}px #fff;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &:after {
    content: '';
    left: 0;
    top: 0;
    width: ${getScalablePixel(12)}px;
    height: ${getScalablePixel(12)}px;
    background: ${({ checked }) => (checked ? font_color_white : 'transparent')};
    border-radius: ${getScalablePixel(10)}px;
  }
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  width: ${getScalablePixel(120)}px;
  height: ${getScalablePixel(120)}px;
  border: ${getScalablePixel(12)}px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
