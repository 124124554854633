import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { translationPacket } from 'common/actions';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { ProfileSettings } from 'common/reducers/profileSettings';
import { useParentalLock } from 'common/utils/hooks';
import { usePinPopup } from 'common/utils/hooks/usePinPopup';
import { getLockPeriods } from 'common/utils/profile-settings';
import { StoreState } from 'index';
import SettingsListDrawer from './SettingsListDrawer';
import UnlockPeriodDrawer from './UnlockPeriodDrawer';

interface Props {
  i18n: typeof translationPacket;
  profileSettings: ProfileSettings;
}

function UnlockChannelsDrawer({ i18n, profileSettings }: Props) {
  const { openDrawer } = useDrawer();
  const { openPinPopup } = usePinPopup();
  const { unlock } = useParentalLock();

  const openUnlockPeriodDrawer = useCallback(() => {
    openDrawer({
      id: 'unlock-period-settings-drawer',
      content: <UnlockPeriodDrawer />,
      positionContent: 'top'
    });
  }, []);

  const handleUnlockAllChannels = useCallback(() => {
    openPinPopup({ onSuccess: unlock });
  }, []);

  const lockPeriodLabel = useMemo(() => {
    const lockPeriodValue = profileSettings.react_tv_settings.lockPeriod;
    return getLockPeriods(i18n).find((period) => period.value === lockPeriodValue)?.label;
  }, [profileSettings?.react_tv_settings?.lockPeriod, i18n]);

  const menuItems = useMemo(() => {
    return [
      {
        id: 'unlock-period',
        label: i18n.mainSettings.unlockPeriod,
        subtitle: lockPeriodLabel,
        onClick: openUnlockPeriodDrawer,
        type: MENU_ITEMS.innerMenuItem
      },
      {
        id: 'unlock-all-channels',
        label: i18n.mainSettings.unlockAll,
        onClick: handleUnlockAllChannels,
        type: MENU_ITEMS.innerMenuItem
      }
    ];
  }, [lockPeriodLabel]);

  return (
    <SettingsListDrawer drawerTitle={i18n.mainSettings.unlockChannels} menuItems={menuItems} />
  );
}

const mapStateToProps = ({ i18n, profileSettings }: StoreState) => ({
  i18n,
  profileSettings
});

export default React.memo(connect(mapStateToProps, null)(UnlockChannelsDrawer));
