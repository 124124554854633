import { uniqueId } from 'lodash';
import React from 'react';
/* eslint-disable import/named */
import {
  CSSObject,
  DefaultTheme,
  Interpolation,
  InterpolationFunction,
  ThemedStyledProps,
  createGlobalStyle
} from 'styled-components';

class GlobalStyle<P extends object> {
  private style: { key: string; component: React.ComponentClass }[] = [];

  public inject(
    first:
      | TemplateStringsArray
      | CSSObject
      | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
  ) {
    const styled = createGlobalStyle(first, ...interpolations);
    this.style.push({
      key: uniqueId('style'),
      component: styled as unknown as React.ComponentClass
    });
  }

  public render() {
    return this.style.map((e) => {
      return <e.component key={e.key} />;
    });
  }
}

export default new GlobalStyle();
