import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
img {
  width: 100%;
  height: unset;
  object-fit: contain;
  image-rendering: auto;
}
`;
