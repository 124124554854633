import variables from 'common/config/variables';
import { LIVE_CHANNELS_CLEAR, LIVE_CHANNELS_GET } from 'common/constants/action-types';
import { LiveChannel, LiveChannels } from 'common/constants/data-types';
import { sort } from 'common/services/helpers';
import request from 'common/services/request';
import { aggregateChannels } from 'common/utils/helpers';
import { AppDispatch, StoreState } from 'index';

export const getRawLiveChannels = async () => {
  try {
    const { data } = await request({
      method: 'get',
      url: '/channels'
    });
    const sorted = sort(data, false, false) as LiveChannel[];
    return aggregateChannels(sorted, null) as LiveChannels;
  } catch (error) {
    console.warn(error);
    return {};
  }
};

export const getLiveChannels = () => {
  return async (dispatch: AppDispatch, getState: () => StoreState) => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/channels'
      });
      const state = getState();
      const channels = state.profileSettings?.react_tv_settings?.channels || {};
      const sortBySettings = variables.REORDER_CHANNELS_ENABLED;

      // sort API data as array then return it as Object
      const sorted = sort(data, false, sortBySettings) as LiveChannel[];
      const liveChannels = aggregateChannels(sorted, channels);

      dispatch({
        type: LIVE_CHANNELS_GET,
        payload: liveChannels
      });
      return liveChannels;
    } catch (error) {
      console.warn(error);
    }
  };
};

export const updateLiveChannels = () => {
  return (dispatch: AppDispatch, getState: () => StoreState) => {
    try {
      const { liveChannels, profileSettings } = getState();
      const profileSettingsChannels = profileSettings?.react_tv_settings?.channels || {};

      const sorted = sort(liveChannels, false, variables.REORDER_CHANNELS_ENABLED) as LiveChannel[];
      const channels = aggregateChannels(sorted, profileSettingsChannels);

      dispatch({
        type: LIVE_CHANNELS_GET,
        payload: channels
      });
      return liveChannels;
    } catch (error) {
      console.warn(error);
    }
  };
};

export const setLiveChannels = (liveChannels: LiveChannels) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: LIVE_CHANNELS_GET,
        payload: liveChannels
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const clearLiveChannels = () => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: LIVE_CHANNELS_CLEAR
      });
    } catch (error) {
      console.warn(error);
    }
  };
};
