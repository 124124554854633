import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { P1 } from 'common/components/Typography';
import { DefaultButton } from 'common/components/button';
import { usePopup } from 'common/contexts/popup';
import { useOnlineStatus } from 'common/utils/hooks';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { ButtonContainer, IconStyled, PopupContainer, Title } from './Prompt.styles';

interface PromptProps {
  name: string;
  icon?: string | React.ReactNode;
  title: string;
  subtitle?: string;
  confirmTitle: string;
  closeTitle: string;
  onConfirm?: VoidFunction;
  onClose?: VoidFunction;
  // // Redux injected
  // i18n: typeof translationPacket;
  // //
}
function Prompt({
  name,
  icon,
  title,
  subtitle,
  confirmTitle,
  closeTitle,
  onClose,
  onConfirm
}: PromptProps) {
  const { ref, focusKey } = useFocusable({
    focusKey: name,
    preferredChildFocusKey: 'close-button',
    isFocusBoundary: true,
    trackChildren: true
  });

  const location = useLocation();

  const [isOnline] = useOnlineStatus();
  const { closePopup } = usePopup();

  const close = useCallback(() => {
    closePopup(name);
    onClose && onClose();
  }, [onClose, name]);

  const confirm = useCallback(() => {
    closePopup(name);
    onConfirm && onConfirm();
  }, [onConfirm, name]);

  useEffect(() => {
    !isOnline && setFocus('close-button');
  }, [location.pathname, isOnline]);

  return (
    <FocusContext.Provider value={focusKey}>
      <PopupContainer>
        {icon && (typeof icon === 'string' ? <IconStyled src={icon} /> : icon)}
        <Title>{title}</Title>
        {subtitle && <P1>{subtitle}</P1>}
        <ButtonContainer ref={ref}>
          <DefaultButton title={confirmTitle} onEnterPress={confirm} focusKey="confirm-button" />
          <DefaultButton title={closeTitle} onEnterPress={close} focusKey="close-button" />
        </ButtonContainer>
      </PopupContainer>
    </FocusContext.Provider>
  );
}

export default React.memo(connect(null, {})(Prompt));
