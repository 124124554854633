import { RADIO_CATEGORY_ID } from 'common/config/constants';
import { LiveChannels } from 'common/constants/data-types';
import { PrismicRadioImages } from 'common/reducers/prismic';
import { store } from 'index';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

function useRadioImage(chan_id: string): undefined | null | PrismicRadioImages {
  return useMemo(() => {
    if (isEmpty(store.getState().liveChannels)) return null;

    const liveChannel = (store.getState()?.liveChannels as LiveChannels)[chan_id];
    if (liveChannel) {
      return liveChannel.category === RADIO_CATEGORY_ID && store.getState()?.prismic.radioImages;
    }
    return null;
  }, [chan_id]);
}

export default useRadioImage;
