import LogoutIcon from '@assets/media/logout-icon.svg';
import { translationPacket } from 'common/actions';
import { Prompt } from 'common/components/promp';
import { ROUTE_SIGN_IN } from 'common/config/constants';
import { usePageNavigation, useSignOut } from 'common/utils/hooks';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

interface LogoutPopupProps {
  // Redux injected
  i18n: typeof translationPacket;
  onSignOut?: VoidFunction;
  //
}
function LogoutPopup({ i18n, onSignOut }: LogoutPopupProps) {
  const signOut = useSignOut();
  const navigate = usePageNavigation();

  const confirm = useCallback(async () => {
    await signOut();
    navigate(ROUTE_SIGN_IN, true, true);
    onSignOut && onSignOut();
  }, []);

  return (
    <Prompt
      name="LOGOUT-POPUP"
      title={i18n.account.logoutTitle}
      subtitle={i18n.account.logoutConfirmation}
      confirmTitle={i18n.account.logout}
      closeTitle={i18n.common.remove}
      icon={LogoutIcon}
      onConfirm={confirm}
    />
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(LogoutPopup));
