import { Translation } from 'common/config/translations';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import AudioIcon from '@assets/media/audio-settings-icon.svg';
import ClockIcon from '@assets/media/clock-icon.svg';
import FavoritesIcon from '@assets/media/favorites-icon.svg';
import HideIcon from '@assets/media/hide-icon.svg';
import LockIcon from '@assets/media/lock-icon.svg';
import ReorderIcon from '@assets/media/reordrer-icon.svg';
import ResetSettingsIcon from '@assets/media/settings-reset-icon.svg';
import SubtitlesIcon from '@assets/media/subtitle-settings-icon.svg';
import UserIcon from '@assets/media/user-icon.svg';
import VideoIcon from '@assets/media/video-settings-icon.svg';

import { removeUserDetails, saveProfileSettings, setProfileSetting } from 'common/actions';
import FocusableStripe from 'common/components/stripe/FocusableStripe';
import variables from 'common/config/variables';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { AppConfigState } from 'common/reducers/appConfig';
import { CLOCK_POSITIONS, ProfileSettings, SettingsKey } from 'common/reducers/profileSettings';
import { convertLanguages } from 'common/utils/helpers';
import { deepEqual } from 'fast-equals';
import { store, StoreState } from 'index';
import { cloneDeep, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { ThunkActionDispatch } from 'redux-thunk';
import ChannelsReorderDrawer from './ChannelsReorderDrawer';
import FavoriteChannelsDrawer from './FavoriteChannelsDrawer';
import HideChannelsDrawer from './HideChannelsDrawer';
import LockChannelsDrawer from './LockChannelsDrawer';
import { MainSettingsDrawerTitle, SettingsCategoryTitle } from './MainSettingsDrawer.styles';
import ProfileSettingsDrawer from './ProfileSettingsDrawer';
import ResetSettingsDrawer from './ResetSettingsDrawer';
import SettingsDrawerSettingItem from './SettingsDrawerSettingItem';
import SettingsListDrawer from './SettingsListDrawer';
interface MainSettingsItem {
  id: string;
  icon?: string;
  subtitle?: string;
  onClick?: () => void;
  type?: string;
  title: string;
  disabled?: boolean;
}

interface MainSettingsDrawerProps {
  i18n: Translation;
  appConfig: AppConfigState;
  saveProfileSettingsDispatch: ThunkActionDispatch<typeof saveProfileSettings>;
  setProfileSetting: ThunkActionDispatch<typeof setProfileSetting>;
  onSelect?: () => void;
  profileSettings: ProfileSettings;
}

/**
 * Child drawers for sub settings change the profileSettings local redux state
 * After closing child drawer, the parent drawer saves the local redux state to the API
 * A snackbar is shown to the user after the API call
 */
function MainSettingsDrawer({
  i18n,
  saveProfileSettingsDispatch,
  appConfig,
  profileSettings,
  setProfileSetting
}: MainSettingsDrawerProps) {
  const [localProfileSettings, setLocalProfileSettings] = useState<ProfileSettings>(
    cloneDeep(profileSettings)
  );
  const localProfileSettingsRef = useRef(cloneDeep(localProfileSettings));
  const { openDrawer } = useDrawer();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    localProfileSettingsRef.current = cloneDeep(localProfileSettings);
  }, [localProfileSettings]);

  const saveProfileSettings = useCallback(async () => {
    try {
      const profileSettingsRef = store.getState().profileSettings as ProfileSettings;
      // Check if the local redux state is the same as the API state
      if (
        deepEqual(
          localProfileSettingsRef.current?.react_tv_settings,
          profileSettingsRef?.react_tv_settings
        )
      ) {
        return;
      }
      const response = await saveProfileSettingsDispatch();
      if (isEmpty(response)) throw new Error('Empty response');

      // Update the local redux state with the response from the API
      setLocalProfileSettings(response);
      //

      enqueueSnackbar(i18n?.mainSettings?.settingsSaveSuccess);
    } catch (error) {
      enqueueSnackbar(i18n?.mainSettings?.settingsSaveError);
    }
  }, [i18n?.mainSettings]);

  // All the actions for opening of drawers
  //#region
  const openFavoriteChannelsDrawer = useCallback(() => {
    openDrawer({
      id: 'favorite-channels-settings-drawer',
      content: <FavoriteChannelsDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);

  const openMainSettingsDrawerHideChannels = useCallback(() => {
    openDrawer({
      id: 'hide-channels-settings-drawer',
      content: <HideChannelsDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);

  const openMainSettingsDrawerLockChannels = useCallback(() => {
    openDrawer({
      id: 'lock-channels-settings-drawer',
      content: <LockChannelsDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);

  const openReorderChannelsDrawer = useCallback(() => {
    openDrawer({
      id: 'reorder-channels-settings-drawer',
      content: <ChannelsReorderDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);
  //#endregion

  const updateSetting = useCallback(
    (key: SettingsKey) => (item: any) => {
      setProfileSetting(key, item.value);
    },
    []
  );

  const getLanguageMenuItems = useCallback(
    (key: string) => {
      const localNames = convertLanguages(appConfig[key], '3', ['local', '2B']);

      return localNames.map((item) => ({
        id: `${key.toUpperCase()}_MENU_${item.local}`,
        label: item['local'],
        value: item['2B'],
        type: MENU_ITEMS.radioMenuItem
      }));
    },
    [appConfig]
  );

  // Items for audio drawer
  const audioSettingItems = useMemo(() => {
    return [
      {
        id: '-1',
        label: i18n.mainSettings.defaultSetting,
        value: null,
        type: MENU_ITEMS.radioMenuItem
      },
      ...getLanguageMenuItems('audio')
    ];
  }, [getLanguageMenuItems]);

  const openAudioSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'audio-settings-drawer',
      content: (
        <SettingsListDrawer
          drawerTitle={i18n.mainSettings.audio}
          menuItems={audioSettingItems}
          onMenuItemPress={updateSetting('audio')}
          selectedKey={'audio'}
        />
      ),
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [audioSettingItems, updateSetting, saveProfileSettings]);

  // Items for subtitles drawer
  const subtitleSettingItems = useMemo(() => {
    return [
      {
        id: 'DISABLED_SUBTITLES_MENU_ITEM',
        label: i18n.mainSettings.disabled,
        value: -1,
        type: MENU_ITEMS.radioMenuItem
      },
      {
        id: 'DEFAULT_SUBTITLES_MENU_ITEM',
        label: i18n.mainSettings.defaultSetting,
        value: null,
        type: MENU_ITEMS.radioMenuItem
      },
      ...getLanguageMenuItems('subtitles')
    ];
  }, [getLanguageMenuItems]);

  const openSubtitlesSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'subtitles-settings-drawer',
      content: (
        <SettingsListDrawer
          drawerTitle={i18n.mainSettings.subtitles}
          menuItems={subtitleSettingItems}
          onMenuItemPress={updateSetting('subtitles')}
          selectedKey={'subtitles'}
        />
      ),
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings, subtitleSettingItems, updateSetting]);

  // Items for resolutions drawer
  const resolutions = useMemo(
    () =>
      appConfig.resolutions.map((resolution, index) => {
        // resolution coming from API as 1920x1080, so we split by x and save the height for a setting value
        const resolutionValue = (resolution as string).split('x')[1];
        const resolutionLabel = `${(resolution as string).split('x')[1]}p`;
        return {
          id: index.toString(),
          label: resolution === 'auto' ? i18n.mainSettings.auto : resolutionLabel,
          value: resolution === 'auto' ? -1 : resolutionValue,
          type: MENU_ITEMS.radioMenuItem
        };
      }),
    [appConfig]
  );

  const openVideoSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'video-settings-drawer',
      content: (
        <SettingsListDrawer
          drawerTitle={i18n.mainSettings.video}
          menuItems={resolutions}
          onMenuItemPress={updateSetting('resolution')}
          selectedKey={'resolution'}
        />
      ),
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings, resolutions, updateSetting]);

  const clockSettingsItems = useMemo(() => {
    return [
      {
        id: 'ALWAYS_ON_SCREEN_CLOCK',
        label: i18n.mainSettings.alwaysOnScreen,
        value: CLOCK_POSITIONS.ALWAYS,
        type: MENU_ITEMS.radioMenuItem
      },
      {
        id: 'ONLY_IN_MENU_CLOCK',
        label: i18n.mainSettings.onlyInMenu,
        value: CLOCK_POSITIONS.IN_MENU,
        type: MENU_ITEMS.radioMenuItem
      }
    ];
  }, []);

  const openClockSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'clock-settings-drawer',
      content: (
        <SettingsListDrawer
          drawerTitle={i18n.mainSettings.clock}
          menuItems={clockSettingsItems}
          onMenuItemPress={updateSetting('clockPosition')}
          selectedKey={'clockPosition'}
        />
      ),
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings, clockSettingsItems, updateSetting]);

  const openResetProfileSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'reset-settings-drawer',
      content: <ResetSettingsDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);

  // Selected options
  const selectedAudioLang = useMemo(
    () =>
      audioSettingItems.find((lang) => lang.value === profileSettings?.react_tv_settings?.audio),
    [profileSettings?.react_tv_settings?.audio, audioSettingItems]
  );

  const selectedSubtitleLang = useMemo(
    () =>
      subtitleSettingItems.find(
        (lang) => lang.value === profileSettings?.react_tv_settings?.subtitles
      ),
    [profileSettings?.react_tv_settings?.subtitles, subtitleSettingItems]
  );

  const selectedResolution = useMemo(
    () =>
      resolutions.find(
        (resolution) => resolution.value === profileSettings?.react_tv_settings?.resolution
      ),
    [profileSettings?.react_tv_settings?.resolution, resolutions]
  );

  const openProfileSettingsDrawer = useCallback(() => {
    openDrawer({
      id: 'profile-settings-drawer',
      content: <ProfileSettingsDrawer />,
      positionContent: 'top',
      onClose: saveProfileSettings
    });
  }, [saveProfileSettings]);

  const mainSettingsItems = useMemo(
    () => [
      {
        title: i18n.mainSettings.channelSettings,
        items: [
          {
            id: 'MAIN-SETTINGS-FAVORITES',
            icon: FavoritesIcon,
            title: i18n.mainSettings.favorites,
            subtitle: i18n.mainSettings.favoritesHelperText,
            onClick: openFavoriteChannelsDrawer
          },
          ...(variables.REORDER_CHANNELS_ENABLED
            ? [
                {
                  id: 'MAIN-SETTINGS-REORDER-CHANNELS',
                  icon: ReorderIcon,
                  title: i18n.mainSettings.channelsReorder,
                  subtitle: i18n.mainSettings.channelsReorderHelperText,
                  onClick: openReorderChannelsDrawer
                }
              ]
            : []),
          {
            id: 'MAIN-SETTINGS-HIDE-CHANNELS',
            icon: HideIcon,
            title: i18n.mainSettings.hideChannels,
            subtitle: i18n.mainSettings.hideChannelsHelperText,
            onClick: openMainSettingsDrawerHideChannels
          },
          {
            id: 'MAIN-SETTINGS-LOCK-CHANNELS',
            icon: LockIcon,
            title: i18n.mainSettings.lockChannels,
            subtitle: i18n.mainSettings.lockChannelsHelperText,
            onClick: openMainSettingsDrawerLockChannels
          }
        ]
      },
      {
        title: i18n.mainSettings.globalSettings,
        items: [
          {
            id: 'main-settings-global-audio',
            icon: AudioIcon,
            title: i18n.mainSettings.audio,
            subtitle: selectedAudioLang?.label || '',
            onClick: openAudioSettingsDrawer
          },
          {
            id: 'main-settings-global-subtitle',
            icon: SubtitlesIcon,
            title: i18n.mainSettings.subtitles,
            subtitle: selectedSubtitleLang?.label || '',
            onClick: openSubtitlesSettingsDrawer
          },
          {
            id: 'main-settings-global-video',
            icon: VideoIcon,
            title: i18n.mainSettings.video,
            subtitle: selectedResolution?.label || '',
            onClick: openVideoSettingsDrawer
          },
          {
            id: 'main-settings-clock',
            icon: ClockIcon,
            title: i18n.mainSettings.clock,
            subtitle: i18n.mainSettings.clockHelperText,
            onClick: openClockSettingsDrawer
          }
        ]
      },
      {
        title: i18n.mainSettings.others,
        items: [
          {
            id: 'PROFILE-SETTINGS',
            icon: UserIcon,
            title: i18n.mainSettings.profile,
            subtitle: i18n.mainSettings.accountSettings,
            onClick: openProfileSettingsDrawer
          },
          {
            id: 'MAIN-SETTINGS-RESET',
            icon: ResetSettingsIcon,
            title: i18n.mainSettings.resetSettings,
            subtitle: i18n.mainSettings.defaultSettings,
            onClick: openResetProfileSettingsDrawer
          }
        ]
      }
    ],
    [i18n, selectedResolution, selectedSubtitleLang, selectedAudioLang]
  );

  const renderGroupItem = useCallback((title: string) => {
    return <SettingsCategoryTitle key={title}>{title}</SettingsCategoryTitle>;
  }, []);

  const renderMenuItem = useCallback((item: MainSettingsItem) => {
    return (
      <SettingsDrawerSettingItem
        key={item.id}
        icon={item.icon}
        title={item.title}
        subtitle={item.subtitle}
      />
    );
  }, []);

  const onStripeItemPress = useCallback((item: MainSettingsItem) => {
    if (item.onClick) {
      item.onClick();
    }
  }, []);

  return (
    <div className="main-settings-container">
      <MainSettingsDrawerTitle>{i18n.mainSettings.settings}</MainSettingsDrawerTitle>
      <FocusableStripe
        focusKey="MAIN_SETTINGS_MENU"
        groupItems={mainSettingsItems}
        axis="y"
        align="center"
        initiallyFocused
        isFocusBoundary
        onStripeItemPress={onStripeItemPress}
        renderItem={renderMenuItem}
        renderGroupItem={renderGroupItem}
        navigateMode={appConfig.navigateMode}
        stripeItemClassName="settings-menu-item"
      />
    </div>
  );
}

const mapDispatchToProps = {
  saveProfileSettingsDispatch: saveProfileSettings,
  setProfileSetting,
  removeUserDetails
};

const mapStateToProps = ({ i18n, appConfig, profileSettings }: StoreState) => ({
  i18n,
  appConfig,
  profileSettings
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(MainSettingsDrawer));
