import { LinearSearchPage, OnDemandSearchPage } from 'common/components/search-page';
import variables from 'common/config/variables';
import protect from 'common/HoC/protect';
import { AppTheme } from 'common/interfaces';
import React, { useCallback } from 'react';

function Search() {
  const renderSearchPage = useCallback(() => {
    switch (variables.WHITELABEL_TYPE) {
      case AppTheme.LINEAR:
        return <LinearSearchPage />;
      case AppTheme.ON_DEMAND:
        return <OnDemandSearchPage />;
      default:
        return <OnDemandSearchPage />;
    }
  }, []);

  return renderSearchPage();
}

export default React.memo(protect(Search));
