import variables from 'common/config/variables';
import GlobalStyle from 'common/utils/GlobalStyle';
const { background_color_dark } = variables;

GlobalStyle.inject`
.video-container {
  background-color: ${background_color_dark};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}


.radio-overlay {
  width: 100%;
  heght: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.radio-logo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

`;
