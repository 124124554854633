import { H1, P1 } from 'common/components/Typography';
import { Image } from 'common/components/image';
import React from 'react';

interface ErrorProps {
  icon: string;
  title: string;
  subtitle: string;
}

function Error(props: ErrorProps) {
  return (
    <div className="error-banner-container">
      <Image src={props.icon} />
      <H1>{props.title}</H1>
      <P1>{props.subtitle}</P1>
    </div>
  );
}

export default Error;
