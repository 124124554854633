import { IS_LIVE_API } from 'common/config/constants';
import GlobalStyle from 'common/utils/GlobalStyle';
import React, { useEffect } from 'react';

GlobalStyle.inject`
.fps-meter {
  position: fixed;
  top: 0;
  right: 0;
  background: black;
  color: white;
  font-size: 32px;
  z-index: 1000000;
 }
`;

let SHOW_FPS = false;
try {
  SHOW_FPS = JSON.parse(process.env.REACT_APP_SHOW_FPS as string);
} catch (error) {
  /* empty */
}

function FpsMeter() {
  useEffect(() => {
    let id = 0;
    const fpscont = document.getElementById('fps-meter');
    const fpsMeter = () => {
      let prevTime = Date.now(),
        frames = 0;

      id = requestAnimationFrame(function loop() {
        const time = Date.now();
        frames++;
        if (time > prevTime + 1000) {
          const fps = Math.round((frames * 1000) / (time - prevTime));
          prevTime = time;
          frames = 0;

          fpscont!.innerHTML = `${fps}FPS`;
        }

        requestAnimationFrame(loop);
      });
    };

    SHOW_FPS && !IS_LIVE_API && fpsMeter();
    return () => {
      cancelAnimationFrame(id);
    };
  }, []);

  if (!SHOW_FPS || IS_LIVE_API) {
    return null;
  }

  return <div id="fps-meter" className="fps-meter"></div>;
}

export default FpsMeter;
