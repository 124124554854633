import { SERVER_TIME_SET } from 'common/constants/action-types';
import config from 'common/services/config';
import request from 'common/services/request';

export const getServerTime = () => {
  return async (dispatch) => {
    try {
      let ajax = request({
        method: 'get',
        url: '/serverTime',
      });
      const { data } = await ajax;
      data.offset = Math.round(new Date().getTime() / 1000 - data.now);
      config.timestampOffset = data.offset;
      config.defaultOffset = data.defaultOffset;

      dispatch({
        type: SERVER_TIME_SET,
        payload: data,
      });
    } catch (error) {
      console.warn(error)
    }
  };
};
