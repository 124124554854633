import { getClassName } from 'common/utils';
import { clamp, isArray } from 'lodash';
import React, { useMemo } from 'react';

interface ProgressBarProps {
  value: number | number[];
  active?: boolean;
  customRef?: React.RefObject<HTMLDivElement>;
}

const Progressbar = (props: React.PropsWithChildren<ProgressBarProps>) => {
  const primaryValue = useMemo(() => {
    const value = isArray(props.value) ? props.value[0] : props.value;
    return clamp(value, 0, 100);
  }, [props.value]);

  const secondaryValue = useMemo(() => {
    const value = isArray(props.value) ? props.value[1] : 100;
    return clamp(value, 0, 100);
  }, [props.value]);
  return (
    <div
      className={getClassName('progressbar-container', { focused: props.active })}
      ref={props.customRef}
    >
      <div className="value secondary" style={{ width: `${secondaryValue}%` }} />
      <div className="value primary" style={{ width: `${primaryValue}%` }} />
      {props.children}
    </div>
  );
};

export default React.memo(Progressbar);
