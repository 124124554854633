import {
  clearSubcategoriesEpgs,
  getListsForManySubcategories,
  getSubcategoryListNextPage,
  playVideo,
  setVideoOptions
} from 'common/actions';
import Card from 'common/components/cards/Card';
import { LoadingIndicator } from 'common/components/common/style';
import { Stripe } from 'common/components/stripe';
import { ViewWithDetails } from 'common/components/view-with-details';
import variables from 'common/config/variables';
import {
  Category,
  Epg,
  IViewWithDetailsStripeItem,
  LiveChannels,
  RecordedStore
} from 'common/constants/data-types';
import { LoadMoreOptions } from 'common/interfaces';
import AbortHandler from 'common/services/AbortHandler';
import { getChannelLogo } from 'common/services/helpers';
import { useOnlineStatus, usePageNavigation, useParentalLock } from 'common/utils/hooks';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
const { DEFAULT_PAGE_SIZE } = variables;

interface ArchiveProps {
  recorded: RecordedStore;
  getListsForManySubcategories: (category: Category) => Promise<void>;
  clearSubcategoriesEpgs: () => Promise<void>;
  getSubcategoryListNextPage: (
    id: number | string,
    count?: number,
    offset?: number
  ) => Promise<any>;
  playVideo: (currentepg: Epg, options?: any) => void;
  setVideoOptions: any;
  liveChannels: LiveChannels;
  logos: any;
  isPlaying: boolean;
}

function CategoryArchive(props: ArchiveProps) {
  const { locked: channelsLocked } = useParentalLock();

  const [isOnline] = useOnlineStatus();
  const { categories, subcategoriesEpgs } = useMemo(() => {
    const subscategoriesEpgs = Object.entries(props.recorded?.subcategoriesEpgs).map(
      ([subCatId, epgs]) => {
        return [
          subCatId,
          // Filter out locked channels
          (epgs as Epg[]).filter((e) => (channelsLocked ? e.visible : true))
        ];
      }
    );
    //
    return {
      ...props.recorded,
      subcategoriesEpgs: Object.fromEntries(subscategoriesEpgs)
    };
  }, [props.recorded]);

  const loading = useMemo(
    () => isEmpty(categories) || isEmpty(subcategoriesEpgs),
    [props.recorded]
  );
  const emptyEpgs = useMemo(
    () => !loading && isEmpty(Object.values(subcategoriesEpgs).flat()),
    [subcategoriesEpgs, loading]
  );
  const navigate = usePageNavigation();
  const { category: categoryId } = useParams();
  const currentCategory = useMemo(
    () => props.recorded && categoryId && props.recorded[categoryId],
    [props.recorded, categoryId]
  );

  useEffect(() => {
    return () => {
      props.clearSubcategoriesEpgs();
    };
  }, []);

  useEffect(() => {
    isOnline &&
      !isEmpty(categories) &&
      categoryId &&
      props.getListsForManySubcategories(categories[categoryId]);
    return () => {
      AbortHandler.abort();
    };
  }, [isOnline, categories, categoryId]);

  const goBack = useCallback(() => {
    props.clearSubcategoriesEpgs();
    navigate(-1, true);
  }, [navigate]);

  // If this category has no epgs return to previous page
  useEffect(() => {
    if (emptyEpgs) {
      goBack();
    }
  }, [emptyEpgs]);

  const openPlayer = useCallback((epg: Epg) => {
    props.playVideo(epg);
    props.setVideoOptions({ isFullscreen: true });
  }, []);

  const renderItem = useCallback(
    (item: Epg) => (
      <Card
        key={`archive-card-${item.eventid}`}
        epg={item}
        logo={getChannelLogo(item.chan_id, props.logos)}
      />
    ),
    [props.logos]
  );

  const loadMore = useCallback(
    ({ rowIndex }: LoadMoreOptions) => {
      try {
        if (isNil(categoryId)) {
          return;
        }
        const { subcategories } = categories[parseInt(categoryId)];
        const currentCategory = subcategories[rowIndex].id;
        const offset = (subcategoriesEpgs[currentCategory] as Epg[]).length;
        props.getSubcategoryListNextPage(currentCategory, DEFAULT_PAGE_SIZE, offset);
      } catch (error) {
        console.warn(error);
      }
    },
    [categories, categoryId, subcategoriesEpgs]
  );

  const renderStripes = useCallback(() => {
    if (loading || isNil(categoryId)) {
      return;
    }
    return Object.values(categories[parseInt(categoryId)].subcategories).flatMap((row, index) => {
      const epgs = subcategoriesEpgs[row.id] as Epg[];
      // Skip empty subcategories
      if (isEmpty(epgs)) {
        return [];
      }
      return (
        <Stripe
          key={index}
          title={row.name}
          items={epgs}
          loadMore={loadMore}
          onStripeItemPress={openPlayer}
          renderItem={renderItem}
        />
      );
    });
  }, [categories, subcategoriesEpgs, categoryId, loading, currentCategory]);

  const onBack = useCallback((last?: boolean) => {
    if (last) {
      goBack();
      return false;
    }
    return true;
  }, []);

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset.chan_id) {
        return {
          epg: asset,
          // Extract resolution from liveChannels
          resolution: props.liveChannels[asset.chan_id]?.resolution
        };
      }
      return undefined;
    },
    [props.liveChannels]
  );

  return (
    <>
      {loading && <LoadingIndicator />}
      <ViewWithDetails
        focusKey={location.pathname}
        getSelectedAssetData={getSelectedAssetData}
        initiallyFocused
        onBack={onBack}
      >
        {renderStripes()}
      </ViewWithDetails>
    </>
  );
}

const mapStateToProps = ({ videoPlayer, recorded, liveChannels, logos }: any) => ({
  liveChannels,
  isPlaying: videoPlayer.isPlaying,
  recorded,
  logos
});

export default React.memo(
  connect(mapStateToProps, {
    clearSubcategoriesEpgs,
    getListsForManySubcategories,
    getSubcategoryListNextPage,
    playVideo,
    setVideoOptions
  })(CategoryArchive)
);
