import { LAST_WATCHED_CLEAR_EPGS, LAST_WATCHED_GET_EPGS } from 'common/constants/action-types';
import request from 'common/services/request';
import { propagateChannelAccessToEpg } from 'common/utils/profile-settings';

export const getLastWatchedEpgs = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/users/me/lastContent'
      });
      const { profileSettings } = getState();

      const epgsWithAccessRights = data.map(
        (epg) => propagateChannelAccessToEpg(epg, profileSettings)
      );
      dispatch({
        type: LAST_WATCHED_GET_EPGS,
        payload: epgsWithAccessRights
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const updateLastWatchedEpgs = () => {
  return (dispatch, getState) => {
    try {
      const { profileSettings, lastWatched } = getState();

      const epgsWithAccessRights = (lastWatched || []).map(
        (epg) =>
          propagateChannelAccessToEpg(epg, profileSettings)
      );
      dispatch({
        type: LAST_WATCHED_GET_EPGS,
        payload: epgsWithAccessRights
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const clearLastWatchedEpgs = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: LAST_WATCHED_CLEAR_EPGS
      });
    } catch (error) {
      console.warn(error);
    }
  };
};
