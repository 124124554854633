import styled from 'styled-components';

import { Column, Row } from 'common/components/common/style';
import { H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
const {
  color_grey_40,
  color_grey_70,
  color_grey_90,
  color_helper,
  color_primary,
  color_white,
  font_color_white,
  group_list_title_color
} = variables;

export const MainSettingsDrawerTitle = styled(H2)`
  font-weight: bold;
  font-size: ${getScalablePixel(42)}px;
  padding: ${getScalablePixel(64)}px ${getScalablePixel(46)}px ${getScalablePixel(44)}px;
  background: ${color_grey_90};
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.8);
`;

export const SettingsCategoryTitle = styled('span')`
  min-height: ${getScalablePixel(64)}px;
  box-sizing: border-box;
  width: 100%;
  color: ${group_list_title_color};
  padding: ${getScalablePixel(26)}px ${getScalablePixel(48)}px ${getScalablePixel(10)}px;
  font-size: ${getScalablePixel(24)}px;
  display: block;
`;

export const MainSettingsDrawerItemTitle = styled('span')`
  color: ${font_color_white};
  font-size: ${getScalablePixel(32)}px;
  margin-bottom: 4px;
`;

export const MainSettingsDrawerItemSubtitle = styled('span')`
  color: ${color_grey_40};
  font-size: ${getScalablePixel(24)}px;
`;

export const MainSettingsDrawerInnerSubtitle = styled(MainSettingsDrawerItemSubtitle)`
  padding: ${getScalablePixel(26)}px ${getScalablePixel(48)}px;
`;

export const MainSettingListItem = styled(Row)`
  padding: ${getScalablePixel(0)}px ${getScalablePixel(40)}px;
  width: ${variables.DRAWER_CONTAINER_WIDTH}px;
  box-sizing: border-box;

  &:focus {
    background: ${color_grey_70};
  }
`;

export const SettingItemIcon = styled(Column)`
  color: ${color_white};
  align-items: center;

  img {
    padding: 0;
  }
`;

export const SettingItemContent = styled(Column)`
  padding: ${getScalablePixel(27)}px 0;
  margin-left: ${getScalablePixel(32)}px;
  width: 100%;
`;

export const SettingToggleRow = styled(Row)`
  padding: ${getScalablePixel(20)}px ${getScalablePixel(27)}px;
  font-size: ${getScalablePixel(28)}px;
  width: ${getScalablePixel(691)}px;
`;

export const ReorderIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: ${variables.color_grey_20};
    z-index: -1;
    padding: 0 ${getScalablePixel(22)}px;
  }

  .image {
    box-sizing: border-box;
  }
`;

export const DeleteAccountLabel = styled.span`
  color: ${color_helper};
`;
