import FooterImage from '@assets/media/footer.png';
import BackgroundImage from '@assets/media/login_background.png';
let LoginQr: string | undefined;
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  LoginQr = require('@assets/media/login_qr.png');
} catch (error) {
  /* empty */
}

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { signIn, translationPacket } from 'common/actions';
import { LoginButton } from 'common/components/button';
import { Column, Row } from 'common/components/common/style';
import { ExitPopup } from 'common/components/exit-popup';
import { Image } from 'common/components/image';
import { Input } from 'common/components/input';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { H1, H4, P1 } from 'common/components/Typography';
import VerticalSeparator from 'common/components/VerticalSeparator';
import {
  ERROR_SUBCODE_TRIAL_EXPIRED,
  PRODUCT_NAME,
  REGEX_EMAIL,
  ROUTE_ROOT,
  ROUTE_TRIAL_END
} from 'common/config/constants';
import variables from 'common/config/variables';
import { usePopup } from 'common/contexts/popup';
import { getUserObject } from 'common/services/helpers';
import { getKey, getRemoteKeyName, getScalablePixel } from 'common/utils';
import firebase from 'common/utils/firebase';
import GlobalStyle from 'common/utils/GlobalStyle';
import { usePageNavigation } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
const { DEFAULT_VIEWMAP, THROTTLE_TIMEOUT, font_color_b } = variables;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: ${getScalablePixel(90)}px;
  justify-content: center;
  background: url(${BackgroundImage});
  background-size: cover;
`;

// width: ${getScalablePixel(404)}px;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Footer = styled.div`
  background: url(${FooterImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 150px;
  width: 100%;
`;

const LogoContainer = styled.div`
  text-align: center;
  height: ${getScalablePixel(140)}px;
`;

GlobalStyle.inject`
.login-view {
  align-items: flex-start;
  justify-content: space-around;
  padding: 0 ${getScalablePixel(64)}px;
  
  ${H1} {
    font-size: ${getScalablePixel(40)}px;
    color: ${font_color_b};
    text-align: center;
  }
  .input-fields > ${H1}, .register-title-container {
    padding-bottom: ${getScalablePixel(32)}px;
    justify-content: flex-start;
    img {
      padding: 0 ${getScalablePixel(8)}px;
      height: ${getScalablePixel(30)}px;
      width: unset;
    }
  }

  .container {
    flex: 1;
    align-items: center;
  }
  .input-fields {
    width: ${getScalablePixel(420)}px;
    align-self: center;
  }

  .register-description {
    height: ${getScalablePixel(279)}px;
    img {
      width: ${getScalablePixel(279)}px;
    }
    align-items: flex-start;
    ${P1} {
      color: ${font_color_b};
      font-size: ${getScalablePixel(32)}px;
    }
    ${H4} {
      font-size: ${getScalablePixel(23)}px;
    }
  }

  .vertical-separator {
    height: 100%;
    width: ${getScalablePixel(3)}px;
    background: linear-gradient(to bottom, #ffffff 10%,rgba(255, 255, 255, 0) 0%);
    background-size: 100% ${getScalablePixel(10)}px;
    background-repeat: repeat-y;
    border: none;
  }

  .register-description-text {
    height: 100%;
    max-width: ${getScalablePixel(300)}px;
    justify-content: space-between;
    padding-left: ${getScalablePixel(32)}px;
  }
}

`;

interface LoginProps {
  i18n: typeof translationPacket;
  signIn: any;
}

export interface LoginFields {
  email: string;
  password: string;
}

function Login(props: LoginProps) {
  const navigate = usePageNavigation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const initialFormFields = useMemo(() => {
    const fields = getUserObject() as any;
    return {
      email: fields?.email || '',
      password: fields?.password || ''
    };
  }, []);
  const [form, setForm] = useState<LoginFields>(initialFormFields);
  const [formErrors, setFormErrors] = useState<LoginFields>({ email: '', password: '' });

  const { focusKey, ref, focusSelf, hasFocusedChild } = useFocusable({
    isFocusBoundary: true,
    preferredChildFocusKey: getKey('input', 'email'),
    autoRestoreFocus: true,
    trackChildren: true,
    forceFocus: true
  });

  const { openPopup } = usePopup();

  useEffect(() => {
    setTimeout(focusSelf, THROTTLE_TIMEOUT);
  }, []);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const modifier = navigator.userAgent.match(/TV/i) ? true : e.ctrlKey;
      if (getRemoteKeyName(e.keyCode) === 'BACK' && modifier) {
        openPopup({
          id: 'EXIT-POPUP',
          content: <ExitPopup />
        });
        return;
      }
    };
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild]);

  // Wait for required props to load
  const loading = useMemo(() => isEmpty(props.i18n), [props.i18n]);
  //

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    // Clear form error when text for the field is changed
    setFormErrors((errors) => ({ ...errors, [e.target.name]: '' }));
    //
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleValidateEmail = () => {
    setFormErrors({ ...formErrors, email: '' });

    if (!form.email) {
      setFormErrors({ ...formErrors, email: props.i18n.authentication.emailEmpty });
      return;
    }

    if (!form.email.match(REGEX_EMAIL)) {
      setFormErrors({ ...formErrors, email: props.i18n.authentication.invalidEmail });
      return;
    }
  };

  const handleValidatePassword = () => {
    setFormErrors({ ...formErrors, password: '' });
    !form.password &&
      setFormErrors({ ...formErrors, password: props.i18n.authentication.passwordEmpty });
  };

  const handleSignIn = async () => {
    handleValidatePassword();
    handleValidateEmail();
    closeSnackbar();
    if (!formErrors.email && !formErrors.password) {
      try {
        const { email, password } = form;

        await props.signIn({
          email,
          grant_type: 'client_credentials',
          password,
          rememberme: true,
          viewmap: [DEFAULT_VIEWMAP]
        });
        firebase.logEvent('login', { type: 'success' });
        // Navigate
        navigate(ROUTE_ROOT);
        //
      } catch (error: any) {
        const errorMessage = error?.error_info?.description || error?.message || '';
        firebase.logEvent('login', { type: 'fail', message: errorMessage });
        if (error.subcode === ERROR_SUBCODE_TRIAL_EXPIRED) {
          navigate(ROUTE_TRIAL_END, true);
        } else {
          //@ts-ignore
          enqueueSnackbar(errorMessage, { title: props.i18n.snackbar.alert });
        }
      }
    }
  };

  // TODO: make a better way for checking of brand
  const renderRegisterTitle = () => {
    if (PRODUCT_NAME === 'bulsatcom') {
      return (
        <>
          <H1>{props.i18n.loginPage.youAreNot}</H1>
          <ProductLogo type="small" />
          <H1>{props.i18n.loginPage.userQuestion}</H1>
        </>
      );
    }
    return <H1>{props.i18n.loginPage.additionalInfoTitle}</H1>;
  };

  const renderAdditionalInfo = () => {
    const { additionalInfoTitle, additionalInfoDescription } = props.i18n.loginPage;
    if (isEmpty(additionalInfoTitle) || isEmpty(additionalInfoDescription)) {
      return null;
    }
    return (
      <>
        <VerticalSeparator />
        <Column className="container">
          <Row className="register-title-container">{renderRegisterTitle()}</Row>
          <Row className="register-description">
            {LoginQr && <Image src={LoginQr} />}
            <Column className="register-description-text">
              <P1>{props.i18n.loginPage.additionalInfoDescription}</P1>
              <H4>{props.i18n.loginPage.additionalInfoBoldText}</H4>
            </Column>
          </Row>
        </Column>
      </>
    );
  };

  if (loading) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <Container>
        <FormContainer ref={ref}>
          <LogoContainer>
            <ProductLogo width={getScalablePixel(180)} type="full" />
          </LogoContainer>

          <Row className="login-view">
            <Column className="container">
              <Column className="input-fields">
                <H1>{props.i18n.loginPage.loginWithYourAccount}</H1>
                <Input
                  name={'email'}
                  placeholder={props.i18n.authentication.email}
                  focusKey={getKey('input', 'email')}
                  onChangeText={onChangeText}
                  value={form.email}
                  error={formErrors.email}
                  onBlur={handleValidateEmail}
                />
                <Input
                  type={'password'}
                  name={'password'}
                  placeholder={props.i18n.authentication.password}
                  focusKey={getKey('input', 'password')}
                  onChangeText={onChangeText}
                  value={form.password}
                  error={formErrors.password}
                  onBlur={handleValidatePassword}
                />

                <LoginButton onSignIn={handleSignIn} />
              </Column>
            </Column>
            {renderAdditionalInfo()}
          </Row>
        </FormContainer>
        <Footer />
      </Container>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, { signIn })(Login));
