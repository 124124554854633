import { LOCK_PERIODS, ProfileSettings } from 'common/reducers/profileSettings';
import { getToday } from 'common/services/helpers';
import { StoreState } from 'index';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';

const getSessionStorageItem = (key: string) => {
  const item = sessionStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

const useParentalLock = () => {
  const [timeToLockChannels, setTimeToLock] = useLocalStorage('TIME_TO_LOCK_CHANNELS', 0);
  const [unlockedTemporarily, setUnlockedTemporarily] = useSessionStorage(
    'UNLOCKED_TEMPORARILY',
    false
  );

  const lockPeriod = useSelector((state: StoreState) => {
    const settings = (state.profileSettings as ProfileSettings)?.react_tv_settings;
    return settings?.saved ? settings?.lockPeriod : LOCK_PERIODS.ALWAYS_LOCK;
  }, shallowEqual);

  const lockPeriodRef = useRef(lockPeriod);
  useEffect(() => {
    lockPeriodRef.current = lockPeriod;
  }, [lockPeriod]);

  const unlockedTemporarilyRef = useRef(unlockedTemporarily);
  useEffect(() => {
    unlockedTemporarilyRef.current = unlockedTemporarily;
  }, [unlockedTemporarily]);

  const timeToLockChannelsRef = useRef(timeToLockChannels);
  useEffect(() => {
    timeToLockChannelsRef.current = timeToLockChannels;
  }, [timeToLockChannels]);

  const periodHasTemporalUnlockRef = useRef(false);
  const periodHasTemporalUnlock = useMemo(() => {
    const value = [LOCK_PERIODS.ALWAYS_LOCK, LOCK_PERIODS.SESSION_LOCK].includes(lockPeriod);
    periodHasTemporalUnlockRef.current = value;
    return value;
  }, [lockPeriod]);

  const getChannelsLockedState = useCallback(() => {
    const unlockedTemporarilySession = getSessionStorageItem('UNLOCKED_TEMPORARILY');
    const timeToLockChannelsLocal = getLocalStorageItem('TIME_TO_LOCK_CHANNELS');
    const value = periodHasTemporalUnlockRef.current
      ? !unlockedTemporarilySession
      : timeToLockChannelsLocal < getToday().getTime();
    channelsLockedRef.current = value;
    return value;
  }, []);

  const channelsLockedRef = useRef(false);
  const channelsLocked = useMemo(() => {
    return getChannelsLockedState();
  }, [lockPeriod, unlockedTemporarily, timeToLockChannels]);

  const unlock = useCallback(() => {
    setTimeToLock(getToday().getTime() + lockPeriodRef.current);
    if (periodHasTemporalUnlockRef.current) {
      setUnlockedTemporarily(true);
    }
  }, []);

  const lockChannelsBack = useCallback(() => {
    if (lockPeriodRef.current === LOCK_PERIODS.ALWAYS_LOCK) {
      setUnlockedTemporarily(false);
      setTimeToLock(0);
    }
  }, []);

  return { locked: channelsLocked, unlock, lockChannelsBack, getChannelsLockedState };
};

export default useParentalLock;
