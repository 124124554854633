import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { translationPacket } from 'common/actions';
import { SimpleScrollView, SimpleScrollViewItem } from 'common/components/simple-scroll-view';
import { CATEGORY_FAVORITES_TYPE } from 'common/config/constants';
import variables from 'common/config/variables';
import { Category, LiveCategories, LiveChannels } from 'common/constants/data-types';
import { FavoriteChannel, FavoriteChannels } from 'common/reducers/favoriteChannels';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import './ChannelsCategorySelectorView.styles';
const { LONG_THROTTLE_TIMEOUT } = variables;

interface StateProps {
  liveCategories: LiveCategories;
  liveChannels: LiveChannels;
  favoriteChannels: FavoriteChannels;
  i18n: typeof translationPacket;
}
interface ChannelsCategorySelectorViewProps {
  i18n: StateProps['i18n'];
  liveCategories: Category[];
  currentCategory: Partial<Category> | null;
  onCategorySelect: (category: Partial<Category> | null) => void;
  favoriteChannels: FavoriteChannel[];
}

function ChannelsCategorySelectorView({
  liveCategories,
  i18n,
  currentCategory,
  onCategorySelect,
  favoriteChannels
}: ChannelsCategorySelectorViewProps) {
  const categorySwitchDebounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleCategorySwitch = useCallback(
    (category: Partial<Category> | null) => {
      categorySwitchDebounceRef.current && clearTimeout(categorySwitchDebounceRef.current);
      categorySwitchDebounceRef.current = setTimeout(
        () => onCategorySelect(category),
        LONG_THROTTLE_TIMEOUT
      );
    },
    [categorySwitchDebounceRef.current, onCategorySelect]
  );

  const renderAllCategoryItem = useCallback(() => {
    return (
      <SimpleScrollViewItem
        key={'tv-channel-category-all'}
        className={getClassName('tv-channels-category-item', {
          active: isEmpty(currentCategory)
        })}
        onFocus={() => handleCategorySwitch(null)}
        onClick={() => handleCategorySwitch(null)}
      >
        <span className="tv-channels-category-item-title">{i18n.common.all}</span>
      </SimpleScrollViewItem>
    );
  }, [i18n, currentCategory]);

  const renderFavoriteCategoryItem = useCallback(() => {
    if (isEmpty(favoriteChannels)) {
      return <></>;
    }

    return (
      <SimpleScrollViewItem
        key={'tv-channel-category-favorite'}
        className={getClassName('tv-channels-category-item', {
          active: currentCategory?.position === CATEGORY_FAVORITES_TYPE.position
        })}
        onFocus={() => handleCategorySwitch(CATEGORY_FAVORITES_TYPE)}
        onClick={() => handleCategorySwitch(CATEGORY_FAVORITES_TYPE)}
      >
        <span className="tv-channels-category-item-title">{i18n.channelList.favorites}</span>
      </SimpleScrollViewItem>
    );
  }, [i18n, currentCategory, favoriteChannels]);

  const renderCategories = useCallback(() => {
    if (isEmpty(liveCategories)) {
      return [];
    }

    const categoriesTemplate = liveCategories
      // Filter out injected categories
      .filter((e) => e.position > 0)
      .map((category) => {
        const onFocus = () => handleCategorySwitch(category);
        return (
          <SimpleScrollViewItem
            key={`tv-channel-category-${category.position}`}
            className={getClassName('tv-channels-category-item', {
              active: currentCategory?.position === category.position
            })}
            onFocus={onFocus}
            onClick={onFocus}
          >
            <span className="tv-channels-category-item-title">{category.name}</span>
          </SimpleScrollViewItem>
        );
      });

    return [renderAllCategoryItem(), renderFavoriteCategoryItem(), ...categoriesTemplate];
  }, [liveCategories, currentCategory]);

  const onBack = useCallback((last?: boolean) => {
    if (last) {
      setFocus('MENU');
      return false;
    }
    return true;
  }, []);

  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <SimpleScrollView onBack={onBack} className="tv-channels-category-list">
      {renderCategories()}
    </SimpleScrollView>
  );
}

const mapStateToProps = ({ liveCategories, liveChannels, i18n, favoriteChannels }: StateProps) => {
  const liveCategoriesArr = Object.values(liveCategories) as Category[];
  const filteredFavoriteChannels = Object.values(favoriteChannels).filter((e) => e.visible);
  const channels = [...Object.values(filteredFavoriteChannels), ...Object.values(liveChannels)];
  return {
    i18n,
    liveCategories: liveCategoriesArr.filter((category) =>
      channels.find((e) => e.category === category.position && e.visible)
    ),
    favoriteChannels: filteredFavoriteChannels
  };
};
export default React.memo(connect(mapStateToProps, {})(ChannelsCategorySelectorView));
