import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { Screensaver } from 'common/components/screensaver';
import { useSoftReload } from 'common/utils/hooks';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

function ScreensaverPage() {
  const softReload = useSoftReload();

  const handleResume = useCallback(() => {
    softReload();
    setFocus('PLAYER');
  }, []);

  return <Screensaver onScreensaverResume={handleResume} />;
}

const mapStateToProps = () => ({});

export default React.memo(connect(mapStateToProps, {})(ScreensaverPage));
