import { VOLUME_MAX } from 'common/config/constants';
import {
  RESET_VIDEO_NEXT,
  VIDEO_ALL_EPG,
  VIDEO_CURRENT,
  VIDEO_NEXT,
  VIDEO_OPTIONS,
  VIDEO_PLAYER_EPGS,
  VIDEO_PLAYER_TOGGLE,
  VIDEO_RESET,
  VIDEO_SET_PLAYER_STATE
} from 'common/constants/action-types';
import { Epg } from 'common/constants/data-types';

export type PlayerControlsState =
  | 'hidden'
  | 'expanded'
  | 'mini'
  | 'seeking'
  | 'channels'
  | 'bottom-view';

export interface IVideoLevel {
  resolution: string;
  name: string;
  height: number;
  value: number;
}

interface IVideo {
  source?: string;
  start: number;
  stop: number;
  startPosition: number;
  isLiveEpg?: number;
  channelPosition?: number;
  now?: number;
  access?: number;
  locked?: boolean;
  duration: number;
  currentTime: number;
  seekingTime: number;
  paused: boolean;
  ended: boolean;
  playbackRate: number;
  muted: boolean;
  volume: number;
  lastPositiveVolume: number;
  readyState: number;
  networkState: number;
  hasStarted: boolean;
  isFullscreen: boolean;
  fastZapping?: boolean;
  currentLevel?: number;
  levels?: IVideoLevel[];
  autoLevel?: boolean;
  audioTracks?: any[];
  currentAudioTrack?: number;
  subtitleTracks?: any[];
  currentSubtitleTrack?: number;
  hash?: string;
}

interface IEpgs {
  all: Epg[];
  current: Epg;
  next: Epg;
  prev: Epg;
  initialOffset: number;
  initialToIndex: number;
}

export interface IVideoPlayer {
  channelId?: string;
  isOpen: boolean;
  isPlaying: boolean;
  allEpgs?: Epg[];
  video: IVideo;
  nextVideo?: INextVideo;
  epgs?: IEpgs;
  playerControlsState: PlayerControlsState;
}

export interface INextVideo {
  source: string;
  channelId: string;
  start: number;
  stop: number;
  startPosition: number;
  autoPlay?: boolean;
  position: number;
}

const videoInitialState = {
  source: undefined,
  start: 0,
  stop: 0,
  startPosition: -1,
  duration: 0,
  currentTime: 0,
  seekingTime: 0,
  paused: false,
  ended: false,
  playbackRate: 1,
  muted: false,
  volume: VOLUME_MAX,
  lastPositiveVolume: VOLUME_MAX,
  readyState: 0,
  networkState: 0,
  hasStarted: false,
  isFullscreen: false,
  currentLevel: undefined,
  levels: undefined
};

const nextVideoInitialState = {
  source: null,
  channelId: null,
  start: null,
  stop: null,
  startPosition: null
};

export default function (
  state: IVideoPlayer = {
    channelId: undefined,
    nextVideo: undefined,
    isOpen: false,
    isPlaying: false,
    allEpgs: undefined,
    video: videoInitialState,
    epgs: undefined,
    playerControlsState: 'hidden'
  },
  action: any
) {
  switch (action.type) {
    case VIDEO_CURRENT:
      return {
        ...state,
        ...action.payload,
        video: {
          ...state.video,
          ...action.payload.video
        },
        epgs: {
          ...state.epgs,
          ...action.payload.epgs
        }
      };
    case VIDEO_NEXT:
      return {
        ...state,
        nextVideo: {
          ...(state.nextVideo && state.nextVideo),
          ...action.payload
        }
      };
    case RESET_VIDEO_NEXT:
      return {
        ...state,
        nextVideo: undefined
      };
    case VIDEO_PLAYER_TOGGLE:
      return {
        ...state,
        ...action.payload
      };
    case VIDEO_ALL_EPG:
      return {
        ...state,
        ...action.payload
      };
    case VIDEO_RESET:
      return {
        isOpen: false,
        isPlaying: false,
        allEpgs: null,
        video: {},
        epgs: null,
        playerControlsState: 'hidden'
      };
    case VIDEO_OPTIONS:
      return {
        ...state,
        video: {
          ...state.video,
          ...action.payload
        }
      };
    case VIDEO_PLAYER_EPGS:
      return {
        ...state,
        epgs: action.payload
      };
    case VIDEO_SET_PLAYER_STATE:
      return {
        ...state,
        playerControlsState: action.payload
      };
    default:
      return state;
  }
}
