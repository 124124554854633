import variables from 'common/config/variables';
import { isEmpty } from 'lodash';

class AnimationDuration {
  private defaultAnimations: any;
  private animations: any;

  private syncVariables = () => {
    this.defaultAnimations = {
      SCROLL_ANIMATION_DURATION: variables.SCROLL_ANIMATION_DURATION,
      DEFAULT_ANIMATION_DURATION: variables.DEFAULT_ANIMATION_DURATION,
      CARD_FOCUSED_TIMEOUT: variables.CARD_FOCUSED_TIMEOUT,
      PLAYER_EXPAND_DURATION: variables.PLAYER_EXPAND_DURATION,
      CONTROLS_EXPAND_DURATION: variables.CONTROLS_EXPAND_DURATION,
      PLAYER_AUTOHIDE_TIMER: variables.PLAYER_AUTOHIDE_TIMER,
      PLAYER_MINIVIEW_AUTOHIDE_TIMER: variables.PLAYER_MINIVIEW_AUTOHIDE_TIMER
    };
    if (isEmpty(this.animations)) this.animations = { ...this.defaultAnimations };
  };

  get = (key: keyof typeof this.animations) => {
    this.syncVariables();
    return this.animations[key];
  };

  getDefault = (key: keyof typeof this.animations) => {
    this.syncVariables();

    return this.defaultAnimations[key];
  };

  set = (key: keyof typeof this.animations, value: number) => {
    this.syncVariables();

    this.animations[key] = value;
    return this.animations[key];
  };

  reset = (key: keyof typeof this.animations) => {
    this.syncVariables();
    if (this.animations[key] !== this.defaultAnimations[key]) {
      this.animations[key] = this.defaultAnimations[key];
    }
  };

  resetAll = () => {
    this.syncVariables();

    this.animations = { ...this.defaultAnimations };
  };
}

export default new AnimationDuration();
