// import ProductIconFull from '@assets/media/logo_full.svg';
import ProductIconSmall from '@assets/media/logo_small.svg';
let ProductIconFull: string | undefined;
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  ProductIconFull = require('@assets/media/logo_full.svg');
} catch (error) {
  /* empty */
}

import { Image } from 'common/components/image';
import React, { FC, useMemo } from 'react';

interface ProductLogoProps {
  width?: string | number;
  height?: string | number;
  type?: 'small' | 'full';
}

const ProductLogo: FC<ProductLogoProps> = ({ width, height, type = 'small' }) => {
  const style = useMemo(() => ({ width, height }), [width, height]);
  return (
    <Image
      style={style}
      src={type === 'full' && ProductIconFull ? ProductIconFull : ProductIconSmall}
    />
  );
};

export default React.memo(ProductLogo);
