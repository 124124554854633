import { Column, Row as FlexRow } from 'common/components/common/style';
import { Image } from 'common/components/image';
import { H2, P1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';
const { font_color_b, font_color_c } = variables;

export const Row = styled(FlexRow)`
  padding: ${getScalablePixel(12)}px 0;
  & > * {
    padding: 0 ${getScalablePixel(42)}px;
    flex: 0 1 50%;
  }
`;
// align-items: flex-start;

export const MiddleRow = styled(Row)`
  border-style: dashed;
  text-align: end;
  border-width: ${getScalablePixel(1.5)}px 0;
  border-color: ${font_color_c};
`;

export const TooManyUsersInfo = styled(P1)`
  text-align: end;
  font-size: ${getScalablePixel(28)}px;
  color: ${font_color_b};
`;

export const Description = styled(P1)`
  text-align: start;
`;

export const ButtonContainer = styled(Column)`
  align-items: normal;
`;

export const ErrorIcon = styled(Image)`
  height: ${getScalablePixel(140)}px;
  width: auto;
`;

export const ProductIconContainer = styled(Image)`
  width: ${getScalablePixel(318)}px;
`;

export const UserTitle = styled(H2)`
  padding: ${getScalablePixel(20)}px 0;
`;

export const Wrapper = styled(Column)`
  padding: 0 ${getScalablePixel(42)}px;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  align-self: center;
  max-width: ${getScalablePixel(1200)}px;
`;
