import { SET_IDLE_TIMER } from 'common/constants/action-types';
import { IdleTimer } from 'common/reducers/idleTimer';
import { AppDispatch } from 'index';

export const setIdleTimer = (timerState: Partial<IdleTimer>): any => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_IDLE_TIMER,
      payload: timerState
    });
  };
};
