import { Row } from 'common/components/common/style';
import { ClampContainer } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';
const { font_color_c, font_color_d, font_color_white, SAFE_AREA_VIEW_PADDING } = variables;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${SAFE_AREA_VIEW_PADDING}px;
  z-index: 2;
  max-width: 50%;
`;

export const DescriptionRowContainer = styled(Row)`
  padding: ${`${getScalablePixel(1)}px 0`};
  justify-content: flex-start;
  font-size: ${getScalablePixel(22)}px;
  font-weight: bold;
  line-height: 1;
  color: ${font_color_c};
  & > *:not(:last-child) {
    margin-right: ${getScalablePixel(12)}px;
  }
`;

export const DescriptionTitle = styled(ClampContainer)<{ fontWeight?: string }>`
  font-size: ${getScalablePixel(52)}px;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  color: ${font_color_white};
  word-break: break-word;
  /* line-height: 1; */
`;

export const DescriptionDuration = styled.span`
  white-space: nowrap;
`;

export const DescriptionDateRow = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  color: ${font_color_c};
  font-size: ${getScalablePixel(21)}px;
  padding: ${`${getScalablePixel(8)}px 0`};
`;

export const DescriptionDateRowTime = styled.span`
  font-weight: bold;
`;

export const DescriptionMainInfo = styled(ClampContainer)`
  word-break: break-word;
  color: ${font_color_white};
  font-size: ${getScalablePixel(24)}px;
  margin-bottom: 16px;
`;

export const DescriptionStaffContainer = styled.div`
  color: ${font_color_white};
  display: flex;
  flex-direction: column;
  flex: 0 0 40%;
`;

export const DescriptionStaffRow = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DescriptionStaffTitle = styled.span<{ align: 'left' | 'right' }>`
  color: ${font_color_d};
  font-weight: bold;
  font-size: ${getScalablePixel(24)}px;
  text-align: ${({ align }) => align};
  flex: 0 0 ${getScalablePixel(134)}px;
`;

export const DescriptionStaffData = styled(ClampContainer)`
  font-size: ${getScalablePixel(22)}px;
  padding: ${`0 ${getScalablePixel(16)}px`};
  flex: 1;
`;
