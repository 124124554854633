import BackwardIcon from '@assets/media/backward-60sec.svg';
import ForwardIcon from '@assets/media/forward-60sec.svg';
import PauseIcon from '@assets/media/pause-icon.svg';
import PlayIcon from '@assets/media/play-icon.svg';
import RestartIcon from '@assets/media/restart-icon.svg';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { translationPacket } from 'common/actions';
import { Button, SimpleButton } from 'common/components/button';
import Icon from 'common/components/Icon';
import { ENABLE_BEGINING_SECONDS, ENABLE_LIVE_SECONDS } from 'common/config/constants';
import variables from 'common/config/variables';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import { getLivePoint } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import { isEmpty } from 'lodash';
import React, { useCallback, useDeferredValue, useMemo } from 'react';
import { connect } from 'react-redux';
import { PlayerControlsProps } from './Controls';
import EpgShow from './EpgShow';
const { DEFAULT_SEEK_STEP } = variables;

interface SeekButtonsStripeProps {
  // Redux injected
  i18n: typeof translationPacket;
  currentTime: number;
  currentEpgStart: number;
  playerControlsState: PlayerControlsState;
  paused?: boolean;
  //
  togglePlay: PlayerControlsProps['togglePlay'];
  forward: PlayerControlsProps['forward'];
  backward: PlayerControlsProps['backward'];
  setVideoToLive: PlayerControlsProps['setVideoToLive'];
  setVideoToBegining: PlayerControlsProps['setVideoToBegining'];
  toggleControls: PlayerControlsProps['toggleControls'];
}
function SeekButtonsStripe(props: SeekButtonsStripeProps) {
  const playToggleButtonStyle = useMemo(
    () => (props.paused ? { paddingLeft: 6 } : undefined),
    [props.paused]
  );
  const visible = useMemo(
    () => props.playerControlsState === 'expanded',
    [props.playerControlsState]
  );
  const { ref, focusKey } = useFocusable({
    // focusable: visible,
    preferredChildFocusKey: 'TOGGLE_PLAY',
    focusKey: 'SEEK_BUTTONS',
    saveLastFocusedChild: true,
    autoRestoreFocus: true
  });
  const currentTime = useDeferredValue(props.currentTime);

  const backward = useCallback(() => {
    AnimationDurations.set(
      'PLAYER_AUTOHIDE_TIMER',
      variables.PLAYER_MINIVIEW_EXTENDED_AUTOHIDE_TIMER
    );
    props.toggleControls();
    props.backward(DEFAULT_SEEK_STEP);
  }, [props.backward]);

  const forward = useCallback(() => {
    AnimationDurations.set(
      'PLAYER_AUTOHIDE_TIMER',
      variables.PLAYER_MINIVIEW_EXTENDED_AUTOHIDE_TIMER
    );
    props.toggleControls();
    props.forward(DEFAULT_SEEK_STEP);
  }, [props.forward]);

  const togglePlay = useCallback(() => {
    AnimationDurations.set('PLAYER_AUTOHIDE_TIMER', variables.PLAYER_MINIVIEW_AUTOHIDE_TIMER);
    props.toggleControls();
    props.togglePlay();
  }, [props.togglePlay]);

  const setVideoToLive = useCallback(() => {
    AnimationDurations.set('PLAYER_AUTOHIDE_TIMER', variables.PLAYER_MINIVIEW_AUTOHIDE_TIMER);
    props.toggleControls();
    props.setVideoToLive();
    setFocus('TOGGLE_PLAY');
  }, [props.setVideoToLive]);

  const setVideoToBegining = useCallback(() => {
    AnimationDurations.set('PLAYER_AUTOHIDE_TIMER', variables.PLAYER_MINIVIEW_AUTOHIDE_TIMER);
    props.toggleControls();
    props.setVideoToBegining();
    setFocus('TOGGLE_PLAY');
  }, [props.setVideoToBegining]);

  const livePointDisabled = useMemo(() => {
    const delta = getLivePoint() - (props.currentEpgStart + currentTime);
    return delta < ENABLE_LIVE_SECONDS;
  }, [currentTime, props.currentEpgStart]);

  const beginingDisabled = useMemo(() => currentTime < ENABLE_BEGINING_SECONDS, [currentTime]);

  const forwardDisabled = useMemo(() => {
    const delta = getLivePoint() - (props.currentEpgStart + currentTime);
    return delta < DEFAULT_SEEK_STEP;
  }, [currentTime, props.currentEpgStart]);

  const liveIcon = useMemo(
    () => <span className={getClassName('live-icon', { live: livePointDisabled })} />,
    [livePointDisabled]
  );

  if (isEmpty(props.i18n)) {
    return <></>;
  }
  return (
    <FocusContext.Provider value={focusKey}>
      <div className={getClassName('seek-button-stripe', { visible })} ref={ref}>
        <EpgShow type="prev" togglePlay={props.togglePlay} toggleControls={props.toggleControls} />
        <Button
          title={props.i18n.common.home.toUpperCase()}
          icon={RestartIcon}
          disabled={beginingDisabled}
          onEnterPress={setVideoToBegining}
        />
        <SimpleButton focusKey="BACKWARD" onEnterPress={backward}>
          <Icon src={BackwardIcon} size="medium" />
        </SimpleButton>
        <SimpleButton focusKey="TOGGLE_PLAY" onEnterPress={togglePlay}>
          <Icon
            src={props.paused ? PlayIcon : PauseIcon}
            size="medium"
            style={playToggleButtonStyle}
          />
        </SimpleButton>
        <SimpleButton disabled={forwardDisabled} focusKey="FORWARD" onEnterPress={forward}>
          <Icon src={ForwardIcon} size="medium" />
        </SimpleButton>
        <Button
          title={props.i18n.common.live.toUpperCase()}
          icon={liveIcon}
          disabled={livePointDisabled}
          onEnterPress={setVideoToLive}
        />
        <EpgShow type="next" togglePlay={props.togglePlay} toggleControls={props.toggleControls} />
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ videoPlayer, i18n }: any) => {
  return {
    i18n,
    paused: videoPlayer.video.paused,
    playerControlsState: videoPlayer.playerControlsState,
    currentEpgStart: isEmpty(videoPlayer.epgs) ? 0 : videoPlayer.epgs.current.start,
    currentTime: videoPlayer.video.currentTime
  };
};
export default React.memo(connect(mapStateToProps, {})(SeekButtonsStripe));
