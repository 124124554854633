import variables from 'common/config/variables';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

export default function useOnScreen(ref, offsetX = 0, offsetY = 0) {
  const [isIntersecting, setIntersecting] = useState(false);
  const debouncedFunction = useRef(null);

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   */
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          debouncedFunction.current && debouncedFunction.current.cancel();
          debouncedFunction.current = debounce(() => {
            setIntersecting(entry.intersectionRatio > 0);
          }, variables.SHORT_THROTTLE);
          debouncedFunction.current();
        },
        {
          threshold: [0, 0.1, 1],
          root: ref.current?.parentElement,
          rootMargin: `${offsetY}px ${offsetX}px ${offsetY}px ${offsetX}px`
        }
      ),
    [ref.current]
  );

  useEffect(() => {
    ref.current && observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return isIntersecting;
}
