import { Epg } from 'common/constants/data-types';

const MAX_ROW_TITLE_LENGTH = 38;
const getTitletRowSpan = (text: string) => Math.ceil(text.length / MAX_ROW_TITLE_LENGTH);

/**
 * LINECLAMP LOGICAL CONDITIONS
 *
 * Title:
 * Default - 1 row
 * Max 2 rows - at the expense of Director and Actors rows (both should be hided)
 *
 * Description:
 * Default - 2 rows
 * Min 1 row (if there is not enough info for 2 rows in Description)
 * Max - 4 rows (if there is no Director and Actors info AND Title is on 1 row)
 *
 * Director:
 * Default - 1 row
 * Max - 2 rows (if no Actors AND Title is on 1 row)
 *
 * Actors:
 * Default - 1 row
 * Max - 2 rows  (if no Directors info AND Title is on 1 row
 *
 */

type getLineClamps = (input: {
  title: string;
  mainInfo: string | null;
  persons: Epg['persons'];
  actors: Epg['actors'];
}) => {
  linesTitle: number;
  linesMainInfo: number;
  linesDirector: number;
  linesActors: number;
};

export const getLineClamps: getLineClamps = ({ title, mainInfo, persons = [], actors = [] }) => {
  const hasMainInfo = Boolean(mainInfo && mainInfo.length);
  const hasDirector = Boolean(persons.find((item) => item.function === 'Director'));
  const hasActors = Boolean(actors.length);

  let linesTitle = 1;
  if ((!hasDirector && !hasActors) || !hasMainInfo) {
    linesTitle = Math.min(getTitletRowSpan(title), 2);
  }

  let linesMainInfo = getMainInfoLineClamp(hasDirector, hasActors);
  if (linesTitle > 1) {
    linesMainInfo -= 1;
  }

  let linesDirector = 1;
  if (!hasActors && linesTitle === 1) {
    linesDirector = 2;
  }

  let linesActors = 1;
  if (!hasDirector && linesTitle === 1) {
    linesActors = 2;
  }

  return {
    linesTitle,
    linesMainInfo,
    linesDirector,
    linesActors
  };
};

const getMainInfoLineClamp = (hasDirector: boolean, hasActors: boolean) => {
  if (hasDirector && hasActors) {
    return 2;
  }

  if (!hasDirector && !hasActors) {
    return 4;
  }

  return 3;
};
