/*
 * Profile (channel) settings types
 */
// Todo: refine types this is a bit ambiguos as it is now
export enum MENU_ITEMS {
  innerMenuItem = 'INNER_MENU_ITEM',
  innerMenuItemWithDisplayValue = 'INNER_MENU_ITEM_WITH_DISPLAY_VALUE',
  toggleOptionItem = 'TOGGLE_OPTION_ITEM',
  radioMenuItem = 'RADIO_ITEM',
  simpleMenuItem = 'SIMPLE_MENU_ITEM',
  categoryLabel = 'CATEGORY_LABEL'
}

export type ChannelListMenuItem = {
  id: string;
  visible?: boolean;
  locked?: boolean;
  position: number;
  favoritePosition: number;
  channelLogo: string;
  icon?: any;
  name: string;
  type: MENU_ITEMS.toggleOptionItem;
};
