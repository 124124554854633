import DefaultMediaImage from '@assets/media/placeholder-message.png';
import { Image } from 'common/components/image';
import { Message } from 'common/reducers/messages';
import { formatDate, getTimeFromTimeStamp } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { isEmpty, isNil } from 'lodash';
import React, { useMemo } from 'react';

interface MessageViewProps {
  active?: boolean;
  message?: Message;
  maxLineSize?: number;
}
function MessageView(props: MessageViewProps) {
  const dateTime = useMemo(() => {
    try {
      if (isNil(props.message?.timestamp)) return '';
      const timestamp = props.message!.timestamp;
      return `${formatDate(timestamp)} / ${getTimeFromTimeStamp(timestamp * 1000)}`;
    } catch (error) {
      console.warn(error);
      return '';
    }
  }, [props.message?.timestamp]);

  const renderBody = () => {
    if (isEmpty(props.message)) return null;
    return (
      <>
        <div className="text-container">
          <span className="title">{props.message?.title}</span>
          <span className="time">{dateTime}</span>
          <span className="content" style={{ WebkitLineClamp: props.maxLineSize }}>
            {props.message?.content}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className={getClassName('message-view', { active: props.active })}>
      <Image
        className="cover"
        src={props.message?.media || DefaultMediaImage}
        fallbackSrc={DefaultMediaImage}
      />
      {renderBody()}
    </div>
  );
}

MessageView.defaultProps = {
  active: true
};

export default React.memo(MessageView);
