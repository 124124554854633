import { Description } from 'common/components/description';
import { TrialCounter } from 'common/components/trial';
import { Epg } from 'common/constants/data-types';
import { DetailInfoStripeItem } from 'common/interfaces';
import { getClassName } from 'common/utils';
import React, { useDeferredValue } from 'react';
import MiniPlayer from './MiniPlayer';

export interface DetailedInfoProps extends DetailInfoStripeItem {
  active?: boolean;
  hasHeader?: boolean;
}

function DetailedInfo(props: DetailedInfoProps) {
  const defferedProps = useDeferredValue(props.epg);
  return (
    <div
      className={getClassName('detailed-info-container', {
        visible: props.active,
        'has-header': props.hasHeader
      })}
    >
      <Description
        numeration={defferedProps.numeration}
        persons={defferedProps.persons}
        originaltitle={defferedProps.originaltitle}
        title={defferedProps.title}
        start={defferedProps.start}
        stop={defferedProps.stop}
        shorttext={defferedProps.shorttext}
        fulltext={defferedProps.fulltext}
        actors={defferedProps.actors}
        year={defferedProps.year}
        country={defferedProps.country}
        major_category={defferedProps.major_category}
        minor_categories={defferedProps.minor_categories}
        duration={defferedProps.duration}
        rating={defferedProps.rating}
        resolution={props.resolution}
      />
      <MiniPlayer {...(defferedProps as Epg)} active={props.active} isLiveCard={props.isLiveCard} />
      <TrialCounter />
    </div>
  );
}
export default React.memo(DetailedInfo);
