import React, { FC } from 'react';
import { connect } from 'react-redux';
import { DefaultButton } from '.';

interface Props {
  i18n: any;
  disabled?: boolean;
  onSignIn: () => Promise<void>;
}

const LoginButton: FC<Props> = ({ i18n, disabled, onSignIn }) => {
  return (
    <DefaultButton
      title={i18n.authentication.signIn}
      textTransform="uppercase"
      disabled={disabled}
      fitContainer
      disableMargin
      onEnterPress={onSignIn}
      focusKey="SIGN_IN"
    />
  );
};

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default connect(mapStateToProps)(LoginButton);
