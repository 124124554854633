import { AppTheme } from 'common/interfaces';
import { getScalablePixel } from 'common/utils';
import { Language } from 'langs';

export const APP_LANGUAGE: Language['1'] = 'bg';

export const VERTICAL_SAFE_AREA_VIEW_PADDING = 0;
export const SAFE_AREA_VIEW_PADDING = getScalablePixel(98);
export const PLAYER_SAFE_AREA_VIEW_PADDING = getScalablePixel(74);

export const MENU_SIZE = getScalablePixel(126);
export const DETAILED_INFO_CONTAINER_HEIGHT = getScalablePixel(744);
export const DETAILED_INFO_SAFE_AREA_PADDING = MENU_SIZE;
export const CAROUSEL_VIEW_WIDTH = getScalablePixel(2304);
export const CAROUSEL_CONTAINER_HEIGHT = getScalablePixel(478);
export const CAROUSEL_CONTAINER_HEIGHT_EXPANDED = getScalablePixel(744);
export const CAROUSEL_VIEW_WIDTH_EXPANDED = getScalablePixel(1920);
export const CAROUSEL_VIEW_HEIGHT = getScalablePixel(892);
export const CAROUSEL_VIEW_HEIGHT_EXPANDED = CAROUSEL_CONTAINER_HEIGHT_EXPANDED;
export const CAROUSEL_INDICATOR_SELECTED_WIDTH = getScalablePixel(29);
export const CAROUSEL_INDICATOR_FOCUSED_WIDTH = getScalablePixel(76);
export const CAROUSEL_INDICATOR_FOCUSED_HEIGHT = getScalablePixel(20);

export const CAROUSEL_INDICATOR_WIDTH = getScalablePixel(10);
export const CAROUSEL_INDICATOR_HEIGHT = getScalablePixel(10);
export const CAROUSEL_INDICATOR_MARGIN = getScalablePixel(5);
export const CAROUSEL_INDICATOR_BORDER_RADIUS = getScalablePixel(86);
export const CAROUSEL_VIEW_WIDTH_SCALE = CAROUSEL_VIEW_WIDTH / CAROUSEL_VIEW_WIDTH_EXPANDED;
export const CAROUSEL_VIEW_HEIGHT_SCALE = CAROUSEL_VIEW_HEIGHT / CAROUSEL_VIEW_HEIGHT_EXPANDED;

export const STRIPE_ITEM_WIDTH = getScalablePixel(424);
export const STRIPE_ITEM_HEIGHT = getScalablePixel(238);
export const STRIPE_ITEM_FOCUSED_SCALE = 1.06;
export const STRIPE_ITEM_PADDING = getScalablePixel(8);
export const STRIPE_PADDING = getScalablePixel(24);
export const TEXT_CARD_WIDTH = STRIPE_ITEM_WIDTH;
export const TEXT_CARD_HEIGHT = getScalablePixel(142);
export const LIST_ITEM_WIDTH = getScalablePixel(1708);
export const LIST_ITEM_HEIGHT = getScalablePixel(88);

export const MINI_PLAYER_WIDTH = getScalablePixel(1196);
export const MINI_PLAYER_HEIGHT = getScalablePixel(672);

export const DRAWER_CONTAINER_WIDTH = getScalablePixel(745);

export const SETTINGS_LIST_ITEM_HEIGHT = getScalablePixel(80);
export const LAZY_LOAD_OFFSET = STRIPE_ITEM_HEIGHT * 2;

export const MAGIC_MOUSE_HOTAREA_OFFSET = getScalablePixel(100);
const isReduced =
  //@ts-ignore
  window.matchMedia('(prefers-reduced-motion: reduce)') === true ||
  window.matchMedia('(prefers-reduced-motion: reduce)').matches === true;

export const AUTOPLAY_TIMEOUT = 3000; //ms
export const HANDLE_MEDIA_ERROR_TIMEOUT = 5000;
export const AUTOLOAD_TIMEOUT = 2000;
export const SHORT_THROTTLE = 50;
export const THROTTLE_TIMEOUT = 250;
export const LONG_THROTTLE_TIMEOUT = 500;
export const DETAILED_INFO_THROTTLE_TIMEOUT = 500;
export const SCROLL_ANIMATION_DURATION = isReduced ? 0 : 1000;
export const ROW_SCROLL_ANIMATION_DURATION = isReduced ? 0 : 400;
export const CARD_FOCUSED_TIMEOUT = 250;
export const SEARCH_INPUT_TIMEOUT = 500;
export const SEARCH_LOGEVENT_TIMEOUT = 2000;
export const MOUNT_FADEIN_TIMEOUT = 500;
export const FAST_ZAPPING_TIMEOUT = 1000;
export const FAST_ZAP_NUMBER_INPUT_TIMEOUT = 3000;
export const KEYPRESS_TIMEOUT = 90;
export const FASTSCROLL_TIMEOUT = 300;
export const DEFAULT_ANIMATION_DURATION = isReduced ? 0 : 100; // ms
export const PAGE_FADE_DURATION = isReduced ? 0 : 350;
export const STRIPE_ITEM_ANIMATION_DURATION = 300;
export const RIPPLE_EFFECT_ANIMATION_DURATION = isReduced ? 0 : 250;
export const HOVER_SCROLL_TIMEOUT = 250;
export const BANNER_AUTOSCROLL_TIMEOUT = 10000;
export const BANNER_TRANSITION_DURATION = 500;
export const BANNER_TIMEOUT_DURATION = 600;
export const BANNER_AUTO_PLAY_FADE_IN_DURATION = 400;
export const PLAYER_EXPAND_DURATION = 200;
export const CONTROLS_EXPAND_DURATION = 300;
export const SEARCH_TRANSITION_DURATION = 500;
export const PRELOAD_TIMEOUT = 9000;
export const PLAYER_AUTOHIDE_TIMER = 20000;
export const PLAYER_MINIVIEW_AUTOHIDE_TIMER = 3000;
export const PLAYER_MINIVIEW_EXTENDED_AUTOHIDE_TIMER = 6000;
export const AUTO_CLOSE_SIDEMENU_INITIAL_TIMEOUT = 5000;
export const AUTO_CLOSE_SIDEMENU_TIMEOUT = 20000;
export const AUTO_SHOW_PLAYER_TIMEOUT = 30000;
export const SCREENSAVER_BACKGROUND_EFFECT_TIMING = 14000;
export const SCREENSAVER_FADE_DURATION = 700;
export const SCREENSAVER_SUBTITLE_FADE_DELAY = 1400;
export const SCREENSAVER_TEXT_FADEOUT_TIMING = 10000;
export const AUTO_READ_MESSAGE_TIMEOUT = 3000;
export const AUTO_DISMISS_MESSAGE_TIMEOUT = 5000;
export const TEMPORAL_DISABLE_MESSAGE_POPUP_TIMEOUT = 1000;

export const TIMEOUT_AFTER_NETWORK_RESTORE = 5000;

export const DEFAULT_SEEK_STEP = 60; // seconds

export const CLOCK_UPDATE_INTERVAL = 30000; //ms

export const HOLD_TIMEOUT = 350;

export const STRIPE_OVERSCAN = 10;

export const FAST_SEEK_OFFSET = 10; // seconds

export const DEFAULT_VIEWMAP = 'd45707f9dbde3649d34a7d0d043e54be785d71ee';

export const DEFAULT_PAGE_SIZE = 20;

export const LOAD_OVERSCAN_COUNT = 2;

export const INITIAL_STRIPE_ITEM_LOAD_SIZE = 5;

export const PLAY_PREVIOUS_THRESHOLD = 10; // seconds;

export const MAX_FASTZAPP_LENGTH = 4;

export const SEARCH_MIN_INPUT = 3;

export const DISABLE_RIVE = true;

export const DEFAULT_LOGO_PATH = 'https://play.elemental.tv/channel-logos/test-350_elemental.png';

export const WHITELABEL_TYPE: AppTheme = AppTheme.ON_DEMAND;
export const GET_JOIN_URL_PATH: (param?: string) => string | undefined = () => undefined;

export const MIN_VISIBLE_CHANNELS = 1;

export const UPDATE_INTERVAL_FOR_MESSAGES = 60 * 60 * 1000;

export const DEFAULT_MAX_RATING = 5;

/**
 * PROFILE SETTINGS FLAGS
 */
export const CHANGE_PASSWORD_ENABLED = true;
export const REORDER_CHANNELS_ENABLED = true;

export const FAVORITES_MAX_LIMIT: number | undefined | null = null;
export const FAVORITES_MIN_LIMIT = 2;

// Basic colors
export const color_white = '#ffffff';
export const color_black = '#000000';

export const color_grey_90 = '#181818';
export const color_grey_80 = '#282828';
export const color_grey_70 = '#323232';
export const color_grey_60 = '#424242';
export const color_grey_50 = '#666666';
export const color_grey_45 = '#D9D9D9';
export const color_grey_40 = '#999999';
export const color_grey_30 = '#CCCCCC';
export const color_grey_20 = '#f1f1f1';
export const color_grey_10 = '#fbfbfb';

export const color_helper = '#EF4156';

export const color_error = '#DD001B';
export const color_warning = '#FFA113';
//
// Brand colors for items
export const color_primary = '#ED1C24';
export const color_secondary = color_primary;
export const color_focused = color_grey_10;
//

// // Colors
export const background_color_darker = color_grey_90;
export const background_color_dark = '#111417';
export const background_color_light = color_grey_70;
export const background_color_lighter = '#a1a0a0';
export const background_color_a = color_grey_50;
export const background_color_d = color_grey_90;
export const background_color_c = color_grey_20;
export const background_color_e = color_grey_40;
export const background_color_white = color_white;

export const border_color_b = '#c4c4c4';
export const border_color_detail = '#ed1c24';

export const font_color_darker = color_grey_90;
export const font_color_dark = color_grey_60;
export const font_color_light = color_grey_50;
export const font_color_c = color_grey_30;
export const font_color_b = color_grey_40;
export const font_color_d = color_grey_50;
export const font_color_e = color_grey_20;
export const font_color_f = color_grey_10;
export const font_color_white = color_white;
export const font_color_error = '#ff4157';
export const font_color_detail = '#ed1c24';
export const live_color = '#ff0000';

export const group_list_title_color = '#ref color_primary';

export const IDLE_TIME_DEFAULT = 10800; // seconds
export const IDLE_MESSAGE_TIME = 120; // seconds
export const IDLE_MESSAGE_INITIAL_TIME = -1;
export const IDLE_TIME_FORMAT = 'mm:ss'; // 01:58
