import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import React from 'react';
import Button, { ButtonProps } from './Button';
const {
  background_color_c,
  background_color_dark,
  background_color_light,
  background_color_white
} = variables;

interface DefaultButtonProps {
  size?: ButtonProps['size'];
  title: ButtonProps['title'];
  icon?: ButtonProps['icon'];
  onEnterPress?: ButtonProps['onEnterPress'];
  disabled?: ButtonProps['disabled'];
  onFocus?: ButtonProps['onFocus'];
  focusKey?: ButtonProps['focusKey'];
  fitContainer?: ButtonProps['fitContainer'];
  textTransform?: ButtonProps['textTransform'];
  disableMargin?: boolean;
}
function DefaultButton(props: DefaultButtonProps) {
  return (
    <Button
      size="medium"
      {...props}
      focusBackgroundColor={background_color_c}
      backgroundColor={background_color_light}
      focusColor={background_color_dark}
      color={background_color_white}
      fontWeight={700}
      margin={props.disableMargin ? undefined : getScalablePixel(8)}
    />
  );
}

export default React.memo(DefaultButton);
