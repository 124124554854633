import ChannelListIcon from '@assets/media/channel-list-icon.svg';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { setPlayerControlsState, translationPacket } from 'common/actions';
import Icon from 'common/components/Icon';
import { H4 } from 'common/components/Typography';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import { useHoverable } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { ChannelListButtonContainer } from './Controls.styles';

interface ChannelListButtonProps {
  // Redux injected
  i18n: typeof translationPacket;
  playerControlsState: PlayerControlsState;
  setPlayerControlsState: (state: PlayerControlsState) => void;
  //
}
function ChannelListButton(props: ChannelListButtonProps) {
  const onEnterPress = useCallback(() => {
    props.setPlayerControlsState('channels');
  }, []);
  const { ref, focused } = useFocusable({
    focusable: props.playerControlsState === 'expanded',
    focusKey: 'CHANNEL_LIST_BUTTON',
    onEnterPress
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  if (isEmpty(props.i18n) || ['channels', 'bottom-view'].includes(props.playerControlsState)) {
    return <span />;
  }
  return (
    <ChannelListButtonContainer
      ref={ref}
      focused={active}
      mini={['mini', 'hidden'].includes(props.playerControlsState)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onEnterPress as any}
    >
      <Icon size="medium" src={ChannelListIcon} />
      <H4>{props.i18n.common.tvChannels}</H4>
    </ChannelListButtonContainer>
  );
}
const mapStateToProps = ({ i18n, videoPlayer }: any) => ({
  i18n,
  playerControlsState: videoPlayer.playerControlsState
});

export default React.memo(connect(mapStateToProps, { setPlayerControlsState })(ChannelListButton));
