import {
  APP_GET_APP_RESOLUTIONS,
  APP_GET_AUDIOLANGS,
  APP_GET_CONFIG,
  APP_GET_SUBLANGS,
  APP_SET_NAVIGATE_MODE
} from 'common/constants/action-types';
import request from 'common/services/request';
const langs = require('langs');
const availableLanguages = langs.all();

export const getAppConfig = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/config/web',
      });
      const response = await ajax;
      dispatch({
        type: APP_GET_CONFIG,
        payload: response.data,
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

const filterAvailableLanguages = (languages) => {
  const availableLanguages3 = availableLanguages.map((el) => el['3']);
  return languages.filter((language) => availableLanguages3.includes(language));
};

export const getAppSublangs = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/config/sublangs',
      });
      const response = await ajax;
      dispatch({
        type: APP_GET_SUBLANGS,
        payload: filterAvailableLanguages(response.data),
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const getAppAudiolangs = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: 'config/audiolangs',
      });
      const response = await ajax;
      dispatch({
        type: APP_GET_AUDIOLANGS,
        payload: filterAvailableLanguages(response.data),
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const getAppResolutions = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: 'config/resolutions',
      });
      const response = await ajax;
      dispatch({
        type: APP_GET_APP_RESOLUTIONS,
        payload: response.data,
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

/**
 * 
 * @param {string} mode 
 * @returns 
 */
export const setAppNavigateMode = (mode) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: APP_SET_NAVIGATE_MODE,
        payload: mode,
      });
    } catch (error) {
      console.warn(error);
    }
  };
}