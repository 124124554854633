import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { Translation } from 'common/actions';

import lockKeyboardImage from '@assets/media/lock_screen_keyboard.png';
import { Image } from 'common/components/image';
import { PinInput } from 'common/components/input';
import { H2 } from 'common/components/Typography';
import { isEmpty } from 'lodash';
import { LockIconWithText, PinContainer, PinTitle, PopupContainer } from './LockedChannel.styles';

interface PinPromptProps {
  // Redux injected
  i18n: Translation;
  channelSettings: {
    defaultPIN: string;
    lockedPIN: string;
  };
  lockedChannelTitle?: boolean;
  //
  onSuccess: () => void;
  onBack?: () => void;
  active?: boolean;
}

function PinPrompt({
  i18n,
  channelSettings,
  onSuccess,
  onBack,
  lockedChannelTitle,
  active
}: PinPromptProps) {
  const [error, setError] = useState('');

  const handleComplete = useCallback(
    (pinValue: string) => {
      try {
        if (pinValue.toString() === (channelSettings.lockedPIN || channelSettings.defaultPIN)) {
          setError('');
          // closePopup(popupId, onSuccess);
          onSuccess();
          return;
        }
        // if not valid set the error state
        setError(i18n.popups.locked.error);
      } catch (error) {
        console.warn(error);
      }
    },
    [channelSettings, onSuccess]
  );

  const handleBack = useCallback((pinValue: string) => {
    setError('');
    //  if pin empty -> call onBack
    if (isEmpty(pinValue)) {
      if (onBack) {
        onBack();
      }
    }
    // closePopup(popupId);
  }, []);

  const text = error ? error : i18n.popups.locked.title;

  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <PopupContainer>
      {lockedChannelTitle && <H2>{i18n.popups.lockedChannel}</H2>}
      <LockIconWithText>
        <Image src={lockKeyboardImage} style={{ maxWidth: 270 }} />
      </LockIconWithText>
      <PinContainer>
        <PinTitle>{text}</PinTitle>
        <PinInput
          onBack={handleBack}
          onComplete={handleComplete}
          error={error}
          active={active}
          clearError={() => setError('')}
        />
      </PinContainer>
    </PopupContainer>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = ({ i18n, channelSettings }: any) => ({
  i18n,
  channelSettings
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PinPrompt));
