import NoInternetIcon from '@assets/media/no-internet-icon.svg';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { translationPacket } from 'common/actions';
import { DefaultButton } from 'common/components/button';
import { Column } from 'common/components/common/style';
import { H2, P1 } from 'common/components/Typography';
import { usePopup } from 'common/contexts/popup';
import { useOnlineStatus } from 'common/utils/hooks';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import {
  NoNetworkIconStyled as NoInternetIconStyled,
  NoNetworkPopupButtonContainer
} from './NoNetwork.styles';

interface NoNetworkPopupProps {
  i18n: typeof translationPacket;
}
function NoNetworkPopup({ i18n }: NoNetworkPopupProps) {
  const { ref, focusKey } = useFocusable({
    focusKey: 'NO_NETWORK_POPUP',
    preferredChildFocusKey: 'network-popup-ok-button',
    isFocusBoundary: true,
    trackChildren: true
  });

  const location = useLocation();
  const [isOnline] = useOnlineStatus();
  const { closePopup } = usePopup();

  const close = useCallback(() => {
    closePopup('no-network');
  }, []);

  useEffect(() => {
    !isOnline && setFocus('network-popup-ok-button');
  }, [location.pathname, isOnline]);

  return (
    <FocusContext.Provider value={focusKey}>
      <Column alignItems="center">
        <NoInternetIconStyled src={NoInternetIcon} />
        <H2>{i18n?.common?.noInternetTitle}</H2>
        <P1>{i18n?.common?.noInternetDescription}</P1>
        <NoNetworkPopupButtonContainer ref={ref}>
          <DefaultButton
            title={i18n?.common?.ok}
            onEnterPress={close}
            focusKey="network-popup-ok-button"
          />
        </NoNetworkPopupButtonContainer>
      </Column>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(NoNetworkPopup));
