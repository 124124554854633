import { PlayerMaskMixin } from 'App.styles';
import { DEFAULT_SCREEN_HEIGHT, DEFAULT_SCREEN_WIDTH } from 'common/config/constants';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
const { MINI_PLAYER_HEIGHT, MINI_PLAYER_WIDTH, PLAYER_EXPAND_DURATION, background_color_dark } =
  variables;
GlobalStyle.inject`
.page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    z-index: 1;
    background: transparent !important;

    &.menu-shown {
      transform: translateY(-${variables.MENU_SIZE}px);
      /* hack to render the whole page */
      margin-bottom: -${variables.MENU_SIZE}px;
    }

    &.player-openned {
      visibility: hidden;
    }
  }



.ondemand-player {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform-origin: top right !important;
  &.playing {
    transition: transform ${PLAYER_EXPAND_DURATION}ms;
  }
  transform: 
    scale(${MINI_PLAYER_WIDTH / getScalablePixel(DEFAULT_SCREEN_WIDTH)}, 
    ${MINI_PLAYER_HEIGHT / getScalablePixel(DEFAULT_SCREEN_HEIGHT)});
  z-index: 0;
  background: black;
  will-change: transform, opacity, z-index;
  ${PlayerMaskMixin}

  &.fullscreen {
    right: 0;
    transform: scale3d(1, 1, 1);
    z-index: 2;
    &:before {
      display: none;
    }
  }
}

`;
