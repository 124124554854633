import {
  AUTH_DELETE_TOKEN,
  AUTH_SET_LAST_REQUESTED_ROUTE,
  AUTH_SIGN_IN,
} from 'common/constants/action-types';
import { getTokenObject } from 'common/services/helpers';

let initialToken = '';
const tokenObject = getTokenObject();
if (tokenObject && tokenObject.access_token) {
  initialToken = tokenObject.access_token;
}

export default function (
  state = {
    grant_type: 'client_credentials',
    lastRequestedRoute: '',
    redirect: '',
    rememberme: true,
    repeatPassword: '',
    token: initialToken,
  },
  action
) {
  switch (action.type) {
    case AUTH_DELETE_TOKEN:
      return {
        ...state,
        redirect: action.payload.redirect,
        token: '',
      };
    case AUTH_SET_LAST_REQUESTED_ROUTE:
      return {
        ...state,
        lastRequestedRoute: action.payload,
      };
    case AUTH_SIGN_IN:
      const { access_token } = action.payload;

      return {
        ...state,
        token: access_token,
      };
    default:
      return state;
  }
}
