import { getClassName } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import { useHoverable } from 'common/utils/hooks';
import smoothScroll from 'common/utils/smoothScroll';
import React, { useEffect, useRef } from 'react';

interface LiveCategoryProps {
  scrollContainer: any;
  selected?: boolean;
  onClick: VoidFunction;
}

function LiveCategory(props: React.PropsWithChildren<LiveCategoryProps>) {
  const ref = useRef<HTMLSpanElement | null>(null);
  const { active, onMouseEnter, onMouseLeave } = useHoverable(!!props.selected);

  useEffect(() => {
    if (!ref.current || !props.scrollContainer || !props.selected) {
      return;
    }
    //@ts-ignore
    smoothScroll({
      toElement: ref.current,
      scrollingElement: props.scrollContainer,
      inline: 'end',
      duration: AnimationDurations.get('DEFAULT_ANIMATION_DURATION')
    });
  }, [props.selected, props.scrollContainer, ref.current]);

  return (
    <span
      className={getClassName('live-category', { selected: active })}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={props.onClick}
    >
      {props.children}
    </span>
  );
}

export default LiveCategory;
