import { Translation } from 'common/actions';
import { Image } from 'common/components/image';
import PaidDescription from 'common/components/PaidDescription';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

interface StoreState {
  i18n: Translation;
  logos: any;
}

interface ChannelAccessOverlayProps {
  channelId?: string;
  i18n: Translation;
  logos: any;
}

const ChannelAccessOverlay = ({ channelId, logos, i18n }: ChannelAccessOverlayProps) => {
  const channelLogo = useMemo(
    () => logos && channelId && logos[channelId]?.pngWhite,
    [logos, channelId]
  );

  if (isEmpty(i18n)) return <></>;

  return (
    <div className="player-overlay-container">
      <Image className="channel-logo" src={channelLogo} />
      <div className="separator" />
      <div className="player-overlay-title">{i18n.playerOverlay.paidSubscription}</div>
      <div className="player-overlay-info">{i18n.playerOverlay.paidSubscriptionInfo}</div>
      <PaidDescription />
    </div>
  );
};

const mapStateToProps = ({ i18n, logos }: StoreState) => ({
  i18n,
  logos
});

export default React.memo(connect(mapStateToProps, {})(ChannelAccessOverlay));
