import {
  CAROUSEL_BANNER_PLAYING_TOGGLE,
  CAROUSEL_SET_BANNERS,
  CAROUSEL_TOGGLE
} from 'common/constants/action-types';

export interface Banner {
  external_link: { link_type: string; url?: string };
  full_screen_image: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
    alt: string | null;
    copyright: string | null;
  };
  short_description: { type: string; text: string }[];
  title: { type: string; text: string }[];
  tv_channel_position: null;
  tv_show_id?: string;
  video_file: {
    link_type: 'Media';
    name?: string;
    kind?: 'document';
    url?: string;
    size?: string;
  };
  vod_asset_id?: string;
}

export interface Banners {
  [path: string]: Banner[];
}

export interface ICarousel {
  isExpanded?: boolean;
  isBannerPlaying?: boolean;
  banners?: Banners | null;
}

const INITIAL_STATE: ICarousel = {
  isExpanded: false,
  isBannerPlaying: false,
  banners: null
};
export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CAROUSEL_TOGGLE:
      return {
        ...state,
        isExpanded: action.payload
      };
    case CAROUSEL_SET_BANNERS:
      return {
        ...state,
        banners: action.payload
      };
    case CAROUSEL_BANNER_PLAYING_TOGGLE:
      return {
        ...state,
        isBannerPlaying: action.payload
      };
    default:
      return state;
  }
}
