import SearchIcon from '@assets/media/search-icon.svg';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import { FocusableComponentLayout, FocusDetails } from '@noriginmedia/norigin-spatial-navigation';
import { clearSearch, search, SearchMode, SearchType, translationPacket } from 'common/actions';
import Icon from 'common/components/Icon';
import VerticalSeparator from 'common/components/VerticalSeparator';
import { ROUTE_SEARCH } from 'common/config/constants';
import variables from 'common/config/variables';
import { getCaretPosition, getClassName, setCaretPosition } from 'common/utils';
import firebase from 'common/utils/firebase';
import { usePageNavigation } from 'common/utils/hooks';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import MenuItem, { MenuItemProps } from './MenuItem';
const { SEARCH_INPUT_TIMEOUT, SEARCH_LOGEVENT_TIMEOUT, SEARCH_MIN_INPUT } = variables;

interface SearchItemProps extends Omit<MenuItemProps, 'onEnterPress'> {
  i18n: typeof translationPacket;
  searchActive?: boolean;
  search: (searchTerm: string, mode?: SearchMode, type?: SearchType) => Promise<any>;
  clearSearch: VoidFunction;
}
function SearchItem(props: SearchItemProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = usePageNavigation();
  const searchDebounce = useRef<DebouncedFunc<VoidFunction> | null>(null);
  const logSearchEventDebounce = useRef<DebouncedFunc<VoidFunction> | null>(null);

  const handleSearchToggle = useCallback(() => {
    navigate(ROUTE_SEARCH, true);
    inputRef.current?.focus();
  }, [navigate]);

  const handleSearchChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      searchDebounce.current && searchDebounce.current.cancel();
      searchDebounce.current = debounce(() => {
        const searchTerm = currentTarget.value;
        if (searchTerm.length === 0) {
          props.clearSearch();
        }
        if (searchTerm.length >= SEARCH_MIN_INPUT) {
          props.search(searchTerm);
          firebase.logUserSearch(searchTerm);
        }
      }, SEARCH_INPUT_TIMEOUT);
      searchDebounce.current();
    },
    [searchDebounce.current, logSearchEventDebounce.current]
  );

  // Clear states
  useEffect(() => {
    if (!props.searchActive && inputRef.current) {
      inputRef.current.blur();
      inputRef.current.value = '';
    }
  }, [props.searchActive, inputRef.current]);

  // Sync focus and blur events with input
  const onFocus = useCallback(
    (layout: FocusableComponentLayout, options: object, details: FocusDetails) => {
      props.onFocus && props.onFocus(layout, options, details);
      props.searchActive && inputRef.current?.focus();
    },
    [props.onFocus, inputRef.current, props.searchActive]
  );

  const onBlur = useCallback(
    (layout: FocusableComponentLayout, options: object, details: FocusDetails) => {
      props.onBlur && props.onBlur(layout, options, details);
      inputRef.current?.blur();
    },
    [props.onBlur, inputRef.current]
  );
  //

  const onArrowPress = useCallback(
    (direction: string) => {
      if (props.searchActive) {
        const inputFieldRef = inputRef.current as HTMLInputElement;
        const caretPosition = getCaretPosition(inputFieldRef);
        if (direction === 'left' || (direction === 'right' && inputFieldRef.value)) {
          const caretPositionOffset = direction === 'left' ? -1 : 1;
          const position = caretPosition + caretPositionOffset;
          position >= 0 &&
            position <= inputFieldRef.value.length &&
            setCaretPosition(inputFieldRef, position);
          return false;
        }
      }
      return true;
    },
    [props.searchActive]
  );

  const menuItemProps = useMemo(() => {
    const { i18n, ...rest } = props;
    return rest;
  }, [props]);

  if (isEmpty(props.i18n)) {
    return null;
  }

  return (
    <MenuItem
      {...menuItemProps}
      onEnterPress={handleSearchToggle}
      onClick={handleSearchToggle}
      onFocus={onFocus}
      onBlur={onBlur}
      onArrowPress={onArrowPress}
      className={getClassName('search-item', { active: props.searchActive })}
    >
      <Icon
        className={getClassName('search-icon', { active: props.searchActive })}
        src={SearchIcon}
        size="medium"
      />
      <div className={getClassName('search-item-container', { active: props.searchActive })}>
        <input
          ref={inputRef}
          type="text"
          placeholder={props.i18n.search.placeholderShort}
          onChange={handleSearchChange}
        />
        <VerticalSeparator height="100%" />
      </div>
    </MenuItem>
  );
}

const mapStateToProps = ({ i18n }: any) => {
  return {
    i18n
  };
};
export default React.memo(
  connect(mapStateToProps, {
    search,
    clearSearch
  })(SearchItem)
);
