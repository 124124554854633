import { translationPacket } from 'common/actions';
import { Column } from 'common/components/common/style';
import { H4, P1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { CLOCK_POSITIONS } from 'common/reducers/profileSettings';
import { formatTime, getDateAndTimeFast, getDayOfWeek, getToday } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useDeferredValue, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DateTimeContainer, DateTimeSize, TimeClock } from './DateTime.styles';

interface DateTime {
  time: string;
  month: string;
  day: number;
  week: string;
}
interface DateTimeProps {
  // Redux injected
  i18n: typeof translationPacket;
  //
  visible?: boolean;
  expanded?: boolean;
  //
  showDate?: boolean;
  size?: DateTimeSize;
  clockPosition?: string;
  playerControlsState: string;
}
function DateTime({
  i18n,
  showDate = true,
  size = 'large',
  clockPosition = CLOCK_POSITIONS.IN_MENU,
  playerControlsState
}: DateTimeProps) {
  const [dateTime, setDateTime] = useState<DateTime | undefined>();
  const [initialLoad, setInitialLoad] = useState(false);

  const handleUpdate = useCallback(() => {
    const now = getToday().getTime() / 1000;
    const date = getDateAndTimeFast(now, now, i18n.months);
    setDateTime({
      time: formatTime(now),
      month: date.month,
      day: date.day,
      week: getDayOfWeek(now, i18n.days).toUpperCase()
    });
  }, [i18n, setDateTime]);
  // Schedule the timer to handle the clock
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!isEmpty(i18n)) {
      handleUpdate();
      interval = setInterval(handleUpdate, variables.CLOCK_UPDATE_INTERVAL);
    }
    return () => clearInterval(interval);
  }, [i18n]);

  useEffect(() => {
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (initialLoad) {
      setTimeout(() => {
        setInitialLoad(false);
      }, 300);
    }
  }, [initialLoad]);

  const deferredPlayerControlsState = useDeferredValue(playerControlsState);
  const playerHidden = deferredPlayerControlsState === 'hidden';

  return (
    <DateTimeContainer
      className={getClassName('date-container', {
        'always-show': clockPosition === CLOCK_POSITIONS.ALWAYS,
        collapsed: playerHidden,
        expanded: initialLoad || !playerHidden
      })}
    >
      {dateTime && (
        <>
          <TimeClock size={size} className="time">
            {dateTime.time}
          </TimeClock>
          {showDate && (
            <Column className="date">
              <P1>{dateTime.week}</P1>
              <H4>{`${dateTime.day} ${dateTime.month}`}</H4>
            </Column>
          )}
        </>
      )}
    </DateTimeContainer>
  );
}
const mapStateToProps = ({ i18n, videoPlayer }: any) => ({
  i18n,
  playerControlsState: videoPlayer.playerControlsState
});

export default React.memo(connect(mapStateToProps, {})(DateTime));
