import CriticalErrorIcon from '@assets/media/critical-error-icon.svg';

import { translationPacket } from 'common/actions';
import { FullscreenError } from 'common/components/full-screen-error';
import { H1 } from 'common/components/Typography';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const Title = styled(H1)`
  font-size: ${getScalablePixel(48)}px;
`;
interface ErrorLoadingDataProps {
  i18n: typeof translationPacket;
}
function ErrorLoadingData({ i18n }: ErrorLoadingDataProps) {
  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <FullscreenError
      title={<Title>{i18n.errorPage.errorLoadingData}</Title>}
      description={i18n.errorPage.errorLoadingDataDescription}
      info={i18n.errorPage.errorLoadingDataInfo}
      icon={CriticalErrorIcon}
    />
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(ErrorLoadingData));
