import Chevron from '@assets/media/chevron.svg';
import { translationPacket } from 'common/actions';
import { clearChannelListCategory, setChannelListCategory } from 'common/actions/channel-list';
import Icon from 'common/components/Icon';
import { CATEGORY_FAVORITES_TYPE } from 'common/config/constants';
import { Category, LiveCategories, LiveChannel, LiveChannels } from 'common/constants/data-types';
import { IChannelList } from 'common/reducers/channelList';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import LiveCategory from './LiveCategory';

interface LiveCategoriesStripProps {
  visible?: boolean;
  // Redux injected
  liveCategories: LiveCategories;
  liveChannels: LiveChannels;
  channelList: IChannelList;
  i18n: typeof translationPacket;
  favoriteChannels: FavoriteChannels;
  setChannelListCategory: (category: Partial<Category>) => void;
  clearChannelListCategory: () => void;
  //
}

function LiveCategoriesStrip(props: LiveCategoriesStripProps) {
  const liveCategoriesContainerRef = useRef<HTMLDivElement | null>(null);

  const rotatedIconStyle = useMemo(() => ({ transform: 'rotate(180deg)' }), []);

  const liveCategories = useMemo(() => {
    if (isEmpty(props.liveCategories) || isEmpty(props.liveCategories)) return [];

    const liveCategoriesArr = Object.values(props.liveCategories) as Category[];

    const liveChannelsArr = (Object.values(props.liveChannels) as LiveChannel[])
      // Filter out channels that are not visible
      .filter((e) => e.visible);
    // Remove categories that we don't have channels for
    // Also
    return liveCategoriesArr.filter((category) =>
      liveChannelsArr.find((e) => e.category === category.position)
    );
  }, [props.liveCategories, props.liveChannels]);

  const hasFavorites = useMemo(
    () =>
      !isEmpty(props.favoriteChannels) &&
      Object.values(props.favoriteChannels).some((e) => e.visible),
    [props.favoriteChannels]
  );

  const renderAllCategoryItem = useCallback(() => {
    const { channelList, i18n } = props;

    return (
      <LiveCategory
        key={'live-category-strip-all'}
        scrollContainer={liveCategoriesContainerRef.current}
        selected={isEmpty(channelList.category)}
        onClick={props.clearChannelListCategory}
      >
        {i18n.channelList.all}
      </LiveCategory>
    );
  }, [props.i18n, props.channelList]);

  const renderFavoriteCategoryItem = useCallback(() => {
    const { channelList, i18n } = props;
    if (!hasFavorites) return null;

    return (
      <LiveCategory
        key={'live-category-strip-favorite'}
        scrollContainer={liveCategoriesContainerRef.current}
        selected={channelList.category?.position === CATEGORY_FAVORITES_TYPE.position}
        onClick={() => props.setChannelListCategory(CATEGORY_FAVORITES_TYPE)}
      >
        {i18n.channelList.favorites}
      </LiveCategory>
    );
  }, [props.i18n, props.channelList, hasFavorites]);

  const renderCategories = useCallback(() => {
    const { channelList } = props;
    const categoriesTemplate = Object.values(liveCategories)
      // Exclude injected categories
      // That are negative positions
      .filter((e) => e.position >= 0)
      //
      .map((category) => {
        const selected = channelList.category?.position === category.position;

        return (
          <LiveCategory
            key={`live-category-strip-${category.position}`}
            selected={selected}
            scrollContainer={liveCategoriesContainerRef.current}
            onClick={() => props.setChannelListCategory(category)}
          >
            {category.name}
          </LiveCategory>
        );
      });
    return [renderAllCategoryItem(), renderFavoriteCategoryItem(), ...categoriesTemplate];
  }, [
    liveCategories,
    props.channelList.category,
    liveCategoriesContainerRef.current,
    renderAllCategoryItem,
    renderFavoriteCategoryItem
  ]);

  if (isEmpty(props.i18n) || !props.visible) {
    return null;
  }

  return (
    <div className={getClassName('live-categories-strip-container', { visible: props.visible })}>
      <Icon src={Chevron} size="miniature" />
      <Icon src={Chevron} size="miniature" style={rotatedIconStyle} />
      <div className="live-categories-container" ref={liveCategoriesContainerRef}>
        {renderCategories()}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  liveCategories,
  channelList,
  i18n,
  liveChannels,
  favoriteChannels
}: any) => {
  return {
    liveCategories,
    liveChannels,
    channelList,
    i18n,
    favoriteChannels
  };
};

export default React.memo(
  connect(mapStateToProps, { setChannelListCategory, clearChannelListCategory })(
    LiveCategoriesStrip
  ),
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
