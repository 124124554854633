import {
  getAllEpgsForChannel,
  getLastWatchedEpgs,
  getLiveChannels,
  getMostWatchedEpgs,
  playVideo,
  removeUserDetails,
  resetEpgs,
  resetNextVideo,
  resetVideo,
  setChannelSetting,
  setCurrentVideo,
  setError,
  setNextVideo,
  setPlayerControlsState,
  setPlayerPlaying,
  setVideoOptions,
  setVideoPlayerEpgs,
  translationPacket
} from 'common/actions';
import { Epg, LiveChannels } from 'common/constants/data-types';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './Player.styles';

import TabLimitIcon from '@assets/media/tab-limit-icon.svg';
import Hls from '@mediahub-bg/hls.js';
import {
  FocusContext,
  pause as pauseFocusable,
  resume as resumeFocusable,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { LoadingIndicator } from 'common/components/common/style';
import { FullscreenError } from 'common/components/full-screen-error';
import { Image } from 'common/components/image';
import Video, { parseError, THls, VideoRefObject } from 'common/components/video';
import {
  ERROR_SUBCODE_STREAM_LIMIT,
  HAS_ACCESS,
  SECONDS_TO_REVERT,
  STATUS_CODE_BAD_REQUEST,
  SUBTITLE_OFF_ID,
  TIME_BEFORE_BUFFER,
  TIME_TO_RECOVER
} from 'common/config/constants';
import variables from 'common/config/variables';
import { usePopup } from 'common/contexts/popup';
import { AppTheme } from 'common/interfaces';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { ChannelSettingKeys } from 'common/reducers/profileSettings';
import { IVideoPlayer, PlayerControlsState } from 'common/reducers/videoPlayer';
import {
  findClosestNumber,
  getLivePoint,
  getLiveTimestamp,
  getTimeBeforeBuffer
} from 'common/services/helpers';
import { getClassName, getRemoteKeyName, screenSaverToggle, sortByPosition } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import {
  useOnlineStatus,
  useParentalLock,
  useRadioImage,
  useSetState,
  useUpdateScheduler,
  useVisibilityState
} from 'common/utils/hooks';
import {
  AudioTracksUpdatedData,
  AudioTrackSwitchedData,
  ErrorData,
  Level,
  LevelSwitchedData,
  SubtitleTracksUpdatedData,
  SubtitleTrackSwitchData
} from 'hls.js';
import { PromiseDispatch } from 'index';
import {
  debounce,
  DebouncedFunc,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isString,
  toNumber,
  uniqueId
} from 'lodash';
import { ThunkActionDispatch } from 'redux-thunk';
import { Controls } from './controls';
import ChannelAccessOverlay from './controls/ChannelAccessOverlay';
import LockChannelOverlay from './controls/LockChannelOverlay';
const {
  CONTROLS_EXPAND_DURATION,
  DEFAULT_ANIMATION_DURATION,
  FAST_ZAPPING_TIMEOUT,
  FAST_ZAP_NUMBER_INPUT_TIMEOUT,
  HANDLE_MEDIA_ERROR_TIMEOUT,
  MAX_FASTZAPP_LENGTH,
  PLAYER_EXPAND_DURATION,
  PLAY_PREVIOUS_THRESHOLD,
  THROTTLE_TIMEOUT
} = variables;

interface PlayerProps {
  paused?: boolean;
  className?: string;
  onBack?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  // Redux injected
  currentAuthToken: string;
  playerControlsState: PlayerControlsState;
  allEpgs?: Epg[];
  videoSource?: string;
  videoStart: number;
  videoStop: number;
  videoStartPosition: number;
  videoPosition?: number;
  channelId?: string;
  epgsObject?: IVideoPlayer['epgs'];
  isLiveEpg?: number;
  isOpen?: boolean;
  isFullscreen?: boolean;
  isPlaying?: boolean;
  nextVideo?: IVideoPlayer['nextVideo'];
  videoNow?: number;
  videoAccess?: number;
  videoLocked?: boolean;
  liveChannels: LiveChannels;
  audioTracks: IVideoPlayer['video']['audioTracks'];
  subtitleTracks: IVideoPlayer['video']['subtitleTracks'];
  logos: any;
  videoHash?: string;
  i18n: typeof translationPacket;
  profileSettings: any;
  channelSettings: any;
  setPlayerPlaying: (state: boolean) => void;
  getAllEpgsForChannel: any;
  setVideoPlayerEpgs: any;
  setCurrentVideo: any;
  setVideoOptions: any;
  resetVideo: VoidFunction;
  removeUserDetails: VoidFunction;
  setError: (code: number, redirect?: string) => void;
  setPlayerControlsState: (state: PlayerControlsState) => void;
  getLiveChannels: PromiseDispatch;
  resetEpgs: () => void;
  setNextVideo: (
    channelId: string,
    start: number,
    stop: number,
    position: number,
    startPosition?: number,
    autoPlay?: boolean
  ) => void;
  resetNextVideo: () => void;
  playVideo: (currentepg: Epg, options?: any) => void;
  getMostWatchedEpgs: PromiseDispatch;
  getLastWatchedEpgs: PromiseDispatch;
  setChannelSetting: ThunkActionDispatch<typeof setChannelSetting>;
  favoriteChannels: FavoriteChannels;
}

interface PlayerState {
  videoHasFatalError?: boolean;
  fastZapNumber?: number;
  canSupportNativeHls?: boolean;
  showBuffer?: boolean;
}

const Player = (props: PlayerProps) => {
  const [state, setState] = useSetState<PlayerState>({
    videoHasFatalError: false,
    canSupportNativeHls: false,
    showBuffer: false
  });

  const {
    ref: focusRef,
    focusKey,
    hasFocusedChild
  } = useFocusable({
    focusKey: 'PLAYER',
    trackChildren: true,
    isFocusBoundary: true,
    focusable: props.isFullscreen,
    onBlur: props.onBlur
  });

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hls = useRef<THls | null>(null);
  const recoverStartTime = useRef<number | null>(null);
  const recoverSwapAudioCodecDate = useRef<number | null>(null);
  const recoverDecodingErrorDate = useRef<number | null>(null);
  const controlsTimeout = useRef<NodeJS.Timeout>();
  const autoFocusTimeout = useRef<NodeJS.Timeout>();

  const mediaPreloadTimeout = useRef<NodeJS.Timeout>();
  const mediaErrorHandlerTimeout = useRef<NodeJS.Timeout>();
  const holdingOkTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const pressedOkRef = useRef(false);
  const debouncedCalls = useRef<DebouncedFunc<VoidFunction> | undefined>();
  const nextVideoDebounce = useRef<DebouncedFunc<VoidFunction> | undefined>();
  const channelListDebounce = useRef<DebouncedFunc<VoidFunction> | undefined>();
  const playerControlsStateRef = useRef(props.playerControlsState);
  const playerIsFullscreenRef = useRef(props.isFullscreen);
  const playerIsPlayingRef = useRef(props.isPlaying);
  const stateRef = useRef(state);
  const currentRecreatableStream = useRef<
    { channelId: string; start: number; stop: number } | undefined
  >();

  const { cancel: cancelScheduler, forceStart: forceStartScheduler } = useUpdateScheduler(
    !props.isPlaying || props.playerControlsState === 'channels'
  );
  const [isOnline] = useOnlineStatus();
  const isOnlineRef = useRef(isOnline);

  const isAppActive = useVisibilityState({ onVisible: forceStartScheduler });
  const radioImages = useRadioImage(props.channelId as string);
  const { openPopup, closePopup } = usePopup();
  const {
    locked: channelsLocked,
    unlock: temporarilyUnlock,
    lockChannelsBack,
    getChannelsLockedState
  } = useParentalLock();

  const [showUnlockOverlay, setShowUnlockOverlay] = useState(false);
  const showUnlockOverlayRef = useRef(showUnlockOverlay);

  const showPaidOverlay = useMemo(() => {
    return !props.videoAccess && props.videoSource;
  }, [props.videoSource, props.videoAccess]);

  useEffect(() => {
    const status = !!(
      !isEmpty(props.videoSource) &&
      props.videoLocked &&
      !isEmpty(props.channelId) &&
      getChannelsLockedState() &&
      // Show unlock overlay only when video is not paid
      !showPaidOverlay
    );
    setShowUnlockOverlay(status);
    // Reset next video when video is locked
    if (status) {
      props.resetNextVideo();
    }
  }, [props.videoSource, props.videoLocked, props.channelId, showPaidOverlay, channelsLocked]);

  // Listen for channel unlock flag
  useEffect(() => {
    if (!channelsLocked) {
      setShowUnlockOverlay(false);
    }
  }, [channelsLocked]);

  useEffect(() => {
    showUnlockOverlayRef.current = showUnlockOverlay;
  }, [showUnlockOverlay]);

  const channelAccessRef = useRef(false);
  const channelAccess = useMemo(() => {
    const value =
      !isNil(props.videoAccess) && props.videoAccess === HAS_ACCESS && !showUnlockOverlay;
    channelAccessRef.current = value;
    return value;
  }, [props.videoAccess, props.channelId, showUnlockOverlay]);

  const currentChannelPosition = useMemo(() => {
    if (isNil(props.channelId)) return undefined;

    const currentChannelId = props.channelId;
    // Show favorite position if channel is added to the list
    const favoritePosition = props.favoriteChannels[currentChannelId]?.position;
    const explicitPosition = props.videoPosition;
    const originalPosition = props.liveChannels[currentChannelId]?.position;

    if (!isNil(explicitPosition)) return explicitPosition;
    if (!isNil(favoritePosition)) return favoritePosition;

    return originalPosition;
  }, [props.favoriteChannels, props.liveChannels, props.videoPosition, props.channelId]);

  const videoSourceRef = useRef<string | undefined>();
  const videoSource = useMemo(() => {
    const value =
      props.videoAccess === HAS_ACCESS && !showUnlockOverlay ? props.videoSource : undefined;
    videoSourceRef.current = value;
    return value;
  }, [props.videoAccess, props.videoSource, showUnlockOverlay]);

  // Pipe onFocus to parent from children
  useEffect(() => {
    if (hasFocusedChild) {
      props.onFocus && props.onFocus();
    }
  }, [hasFocusedChild]);

  const currentChannelPositionRef = useRef<number | undefined>();
  useEffect(() => {
    currentChannelPositionRef.current = currentChannelPosition;
  }, [currentChannelPosition]);

  useEffect(() => {
    isOnline && forceStartScheduler();
    isOnlineRef.current = isOnline;
  }, [isOnline]);

  useEffect(() => {
    return () => {
      if (hls.current || state.canSupportNativeHls) {
        props.resetVideo();
      }
    };
  }, [hls]);

  useEffect(() => {
    if (!isEmpty(props.allEpgs) && !isEmpty(props.videoSource)) {
      startTransition(() => setEpgs(props.allEpgs, props.videoStart));
    }
  }, [props.allEpgs, props.videoSource]);

  useEffect(() => {
    if (props.videoSource && hls.current && videoRef.current) {
      // Load initial epg only when switching to different channel id
      // and only on live
      if (
        props.channelId &&
        !isEqual(props.epgsObject?.current?.chan_id, props.channelId) &&
        props.isLiveEpg
      ) {
        setInitialEpg();
      }
      if (props.isOpen) {
        loadVideo();
      }
    }
    return () => {
      // debouncedCalls.current && debouncedCalls.current.cancel();
    };
  }, [props.videoSource, hls.current, videoRef.current]);

  useEffect(() => {
    playerIsPlayingRef.current = props.isPlaying;
  }, [props.isPlaying]);

  // Store as a ref the current stream that can be recreated
  useEffect(() => {
    if (props.channelId && !isEmpty(props.channelId) && props.videoStart && props.videoStop) {
      currentRecreatableStream.current = {
        channelId: props.channelId,
        start: props.videoStart,
        stop: props.videoStop
      };
    }
  }, [props.channelId, props.videoStart, props.videoStop]);

  // Try to refresh current stream with new URL
  useEffect(() => {
    if (!isEmpty(currentRecreatableStream.current) && props.currentAuthToken && props.isPlaying) {
      const { channelId, start, stop } = currentRecreatableStream.current;
      changeVideo(channelId, start, stop, currentChannelPositionRef.current, getCurrentTime());
    }
  }, [props.currentAuthToken, props.isPlaying]);

  // Make transition to fullscreen
  useEffect(() => {
    if (props.isFullscreen) {
      debounce(() => {
        videoRef.current?.focus();
        playerIsPlayingRef.current && onCanPlay();
      }, PLAYER_EXPAND_DURATION)();
    }
    playerIsFullscreenRef.current = props.isFullscreen;
  }, [props.isFullscreen, videoRef.current]);

  // Make sure the player has focus
  useEffect(() => {
    hasFocusedChild && videoRef.current?.focus();
  }, [hasFocusedChild, videoRef.current]);

  useEffect(() => {
    playerControlsStateRef.current = props.playerControlsState;
    refreshControlsTimeout();
  }, [props.playerControlsState]);

  // Update state current time
  useEffect(() => {
    timeUpdate();
  }, [props.playerControlsState === 'seeking']);

  //Handle case when current channel has no ACCESS or video is LOCKED
  useEffect(() => {
    if (showPaidOverlay) {
      props.setPlayerControlsState('hidden');
    }
  }, [showPaidOverlay, showUnlockOverlay, videoRef, props.playerControlsState]);

  const showControls = useCallback((mini?: boolean) => {
    // clearTimeout(controlsTimeout.current);
    const newState = mini ? 'mini' : 'expanded';
    newState !== playerControlsStateRef.current &&
      channelAccessRef.current &&
      startTransition(() => {
        // clearTimeout(controlsTimeout.current);
        props.setPlayerControlsState(newState);
        clearTimeout(autoFocusTimeout.current);
        autoFocusTimeout.current = setTimeout(
          () => playerIsFullscreenRef.current && newState === 'expanded' && setFocus('TOGGLE_PLAY'),
          DEFAULT_ANIMATION_DURATION
        );
      });
  }, []);

  const hideControls = useCallback(() => {
    clearTimeout(controlsTimeout.current);
    // When closing channel list first show mini view
    if (playerControlsStateRef.current === 'channels') {
      props.setPlayerControlsState('mini');
      setTimeout(() => {
        props.setPlayerControlsState('hidden');
      }, CONTROLS_EXPAND_DURATION);
    } else {
      props.setPlayerControlsState('hidden');
    }
  }, []);

  const hideControlsWithTimeout = useCallback((mini?: boolean, timeout?: number) => {
    const timerValue =
      timeout ||
      (mini
        ? AnimationDurations.get('PLAYER_MINIVIEW_AUTOHIDE_TIMER')
        : AnimationDurations.get('PLAYER_AUTOHIDE_TIMER'));
    clearTimeout(controlsTimeout.current);
    controlsTimeout.current = setTimeout(() => {
      const toHideControls = !['seeking'].includes(playerControlsStateRef.current);
      toHideControls && props.setPlayerControlsState('hidden');
    }, timerValue);
    // Reset both timer values
    AnimationDurations.reset('PLAYER_MINIVIEW_AUTOHIDE_TIMER');
    AnimationDurations.reset('PLAYER_AUTOHIDE_TIMER');
    //
  }, []);

  const toggleControls = useCallback(
    (mini?: boolean, timeout?: number) => {
      showControls(mini);
      hideControlsWithTimeout(mini, timeout);
    },
    [showControls, hideControlsWithTimeout]
  );

  const refreshControlsTimeout = useCallback(() => {
    // Refresh timeout
    hideControlsWithTimeout(playerControlsStateRef.current === 'mini');
  }, [hideControlsWithTimeout]);

  const getCurrentTime = () => {
    if (videoRef.current) {
      return videoRef.current.currentTime;
    }
    return 0;
  };

  const play = useCallback(async () => {
    try {
      if (!videoRef.current) {
        return;
      }
      await videoRef.current.play();
      props.setVideoOptions({
        paused: false
      });
      screenSaverToggle(false);
    } catch (error) {
      console.info(error);
    }
  }, []);

  const pause = useCallback(() => {
    try {
      if (!videoRef.current) {
        return;
      }
      videoRef.current.pause();
      props.setVideoOptions({
        paused: true
      });
      screenSaverToggle(true);
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    if (!isNil(props.paused) && props.isPlaying) {
      props.paused ? pause() : play();
    }
  }, [props.paused]);

  const togglePlay = useCallback(
    (toPlay?: boolean) => {
      const paused = isNil(toPlay) ? videoRef?.current?.paused : toPlay;
      if (paused) {
        play();
      } else {
        pause();
      }
    },
    [videoRef.current, play, pause]
  );

  const clearTimers = useCallback(() => {
    if (!hasFocusedChild) {
      clearOkHold();
    }
  }, [hasFocusedChild]);

  useEffect(() => {
    !hasFocusedChild && clearTimers();
  }, [hasFocusedChild]);

  const closePlayer = useCallback(() => {
    hideControls();
    cancelScheduler();
    clearTimers();
    props.onBack && props.onBack();
  }, [clearTimers, hideControls, props.onBack, props.videoLocked]);

  useEffect(() => {
    if (!isAppActive) {
      props.isFullscreen && closePlayer();
      props.resetVideo();
      cancelScheduler();
    }
  }, [isAppActive, props.isFullscreen, props.onBack]);

  const onSubtitlesUpdated = useCallback(
    (data: SubtitleTracksUpdatedData) => {
      props.setVideoOptions({
        subtitleTracks: data.subtitleTracks || [],
        currentSubtitleTrack: hls.current?.subtitleTrack || SUBTITLE_OFF_ID
      });
    },
    [hls.current?.subtitleTrack]
  );

  const onSubtitleTrackSwitch = useCallback((data: SubtitleTrackSwitchData) => {
    props.setVideoOptions({
      currentSubtitleTrack: data.id
    });
  }, []);

  // Handle initial dvbAudioTrack and autoLevel
  useEffect(() => {
    props.setVideoOptions({
      currentAudioTrack: hls.current?.dvbAudioTrack,
      autoLevel: hls.current?.autoLevelEnabled
    });
  }, [hls.current?.dvbAudioTrack, hls.current?.autoLevelEnabled]);

  const onDvbAudioTracksUpdated = useCallback(
    (data: AudioTracksUpdatedData) => {
      props.setVideoOptions({
        audioTracks: data.audioTracks.length > 0 ? data.audioTracks : [],
        currentAudioTrack: hls.current?.dvbAudioTrack
      });
    },
    [hls.current]
  );

  const onDvbAudioTrackSwitch = useCallback((data: AudioTrackSwitchedData) => {
    props.setVideoOptions({
      currentAudioTrack: data.id
    });
  }, []);

  const onLevelsUpdated = useCallback((levels: Level[]) => {
    props.setVideoOptions({
      levels,
      autoLevel: true
    });
  }, []);

  const onLevelSwitched = useCallback(
    (data: LevelSwitchedData) => {
      props.setVideoOptions({
        currentLevel: data.level,
        autoLevel: hls.current?.autoLevelEnabled
      });
    },
    [hls?.current?.autoLevelEnabled]
  );

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const onBufferAppended = useCallback(() => {
    // Remove loading when new buffer is received after fatal error recover
    clearMediaError();
    if (stateRef.current.videoHasFatalError) {
      recoverSwapAudioCodecDate.current = null;
      recoverDecodingErrorDate.current = null;
      setState({ videoHasFatalError: false });
    }
  }, []);

  const loadVideo = useCallback(() => {
    if (!videoRef.current || !hls.current) {
      return;
    }

    const videoOptions = {
      startPosition: isNil(props.videoStartPosition) ? -1 : props.videoStartPosition,
      duration: props.videoStop - props.videoStart,
      ended: false,
      paused: false,
      autoPaused: false,
      waiting: false,
      hasStarted: true,
      isLiveEpg: props.videoNow,
      // isFullscreen: props.isFullscreen,
      muted: false
    };

    // Manually update current time
    const currentTime =
      videoOptions.isLiveEpg && videoOptions.startPosition === -1
        ? getLiveTimestamp() - props.videoStart
        : props.videoStartPosition;
    props.setVideoOptions({
      ...videoOptions,
      currentTime: Math.max(currentTime, 0)
    });
  }, [
    videoRef.current,
    hls.current,
    props.videoStartPosition,
    props.videoStart,
    props.videoStop,
    props.videoNow,
    props.isFullscreen,
    recoverStartTime.current
  ]);

  const handleHlsError = useCallback(
    (data: ErrorData) => {
      switch (data.details) {
        case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
          // Force reload media on buffer appending error
          handleMediaError(true);
          break;
        case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
          handleMediaError();
          break;
        default:
          break;
      }

      if (data.fatal) {
        const now = performance.now();
        switch (data.type) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            setState({ videoHasFatalError: true });
            if (
              !recoverDecodingErrorDate.current ||
              now - recoverDecodingErrorDate.current > TIME_TO_RECOVER
            ) {
              recoverDecodingErrorDate.current = performance.now();
              console.warn('Trying to recover media error.');

              // TODO add message Trying to recover media error.
              hls.current?.recoverMediaError();
            } else {
              if (
                !recoverSwapAudioCodecDate.current ||
                now - recoverSwapAudioCodecDate.current > TIME_TO_RECOVER
              ) {
                recoverSwapAudioCodecDate.current = performance.now();
                console.warn('Trying to swap audio codecs and recover media error.');

                // TODO add message Trying to swap audio codecs and recover media error.
                hls.current?.swapAudioCodec();
                hls.current?.recoverMediaError();
              } else {
                console.warn('Cannot recover. Last media error recovery failed.');
              }
            }
            break;
          case Hls.ErrorTypes.NETWORK_ERROR:
            handleHlsNetworkError({ ...data });
            break;
          default:
            // TODO add message A network error occurred, try to recover
            hls.current?.destroy();
            break;
        }
      } else {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            handleNonFatalNetworkError({ ...data });
            break;
        }
      }
    },
    [
      videoRef.current,
      hls.current,
      recoverDecodingErrorDate.current,
      recoverSwapAudioCodecDate.current,
      mediaErrorHandlerTimeout.current
    ]
  );

  const reloadMedia = useCallback(() => {
    props.setPlayerPlaying(false);
    const timeBeforeReload = getCurrentTime();
    hls.current?.recoverMediaError();
    hls.current?.startLoad(timeBeforeReload);
  }, []);

  const handleMediaError = useCallback((forceReload?: boolean) => {
    clearMediaError();
    if (forceReload) {
      reloadMedia();
      return;
    }
    mediaPreloadTimeout.current = setTimeout(() => {
      setState({ showBuffer: true });
    }, variables.PRELOAD_TIMEOUT);
    mediaErrorHandlerTimeout.current = setTimeout(() => reloadMedia, HANDLE_MEDIA_ERROR_TIMEOUT);
  }, []);

  const clearPreloadTimeout = useCallback(() => {
    clearTimeout(mediaPreloadTimeout.current);
    mediaPreloadTimeout.current = undefined;
  }, []);

  const clearBuffering = useCallback(() => {
    clearPreloadTimeout();
    stateRef.current.showBuffer && setState({ showBuffer: false });
  }, []);

  const clearMediaError = useCallback(() => {
    clearBuffering();
    clearTimeout(mediaErrorHandlerTimeout.current);
    mediaErrorHandlerTimeout.current = undefined;
  }, []);

  const setEpgs = useCallback(
    (allEpgs?: Epg[], start?: number) => {
      if (!allEpgs) {
        return;
      }
      const currentEpgIndex = allEpgs.findIndex((item: Epg) => item.start === start);

      if (currentEpgIndex !== null && currentEpgIndex !== -1) {
        const nextEpgIndex = currentEpgIndex + 1;
        const pastEpgIndex = currentEpgIndex - 1;

        const epgs = {
          all: allEpgs,
          current: allEpgs[currentEpgIndex],
          next: typeof allEpgs[nextEpgIndex] !== 'undefined' ? allEpgs[nextEpgIndex] : null,
          prev: typeof allEpgs[pastEpgIndex] !== 'undefined' ? allEpgs[pastEpgIndex] : null,
          initialOffset: currentEpgIndex + 1,
          initialToIndex: currentEpgIndex
        };

        try {
          props.setVideoPlayerEpgs(epgs);
        } catch (error) {
          console.warn('Could not set epgs', error);
        }
      }
    },
    [setVideoPlayerEpgs]
  );

  const setInitialEpg = useCallback(() => {
    try {
      if (isEmpty(props.liveChannels) || !props.channelId) {
        throw new Error('No live channels or no current id');
      }
      props.setVideoPlayerEpgs({
        current: props.liveChannels[props.channelId].currentepg
      });
    } catch (error) {
      console.warn('Could not set initial epgs', error);
    }
  }, [props.liveChannels, props.channelId]);

  const handleHlsNetworkError = useCallback((data: ErrorData) => {
    //noop
  }, []);

  const handleNonFatalNetworkError = useCallback(
    (data: ErrorData) => {
      const error = parseError(data);

      if (error?.status === STATUS_CODE_BAD_REQUEST && error?.subcode) {
        switch (error.subcode) {
          case ERROR_SUBCODE_STREAM_LIMIT:
            {
              pause();
              const onStop = () => {
                closePopup('TAB_LIMIT');
                closePlayer();
                props.resetVideo();
              };
              const onContinue = () => {
                props.epgsObject?.current &&
                  props.playVideo(props.epgsObject.current, {
                    startPosition: getCurrentTime(),
                    hash: uniqueId()
                  });
                closePopup('TAB_LIMIT');
              };
              openPopup({
                fullscreen: true,
                id: 'TAB_LIMIT',
                background: 'black',
                content: (
                  <FullscreenError
                    icon={TabLimitIcon}
                    title={props.i18n.authentication.tabLimit}
                    description={props.i18n.authentication.tabLimitDescription}
                    info={props.i18n.authentication.tabLimitInfo}
                    primaryButtonTitle={props.i18n.authentication.tabLimitPrimaryButton}
                    secondaryButtonTitle={props.i18n.authentication.tabLimitSecondaryButton}
                    onPrimaryButtonPress={onContinue}
                    onSecondaryButtonPress={onStop}
                  />
                )
              });
            }
            break;
        }
      }
    },
    [
      props.epgsObject?.current,
      props.i18n.authentication,
      openPopup,
      closePopup,
      closePlayer,
      play,
      pause
    ]
  );

  const forward = useCallback(
    (seconds: number) => {
      if (!videoRef.current || !props.isPlaying) {
        return;
      }
      let secondsToForward = videoRef.current.currentTime + seconds;
      const timeBeforeBuffer = getTimeBeforeBuffer(
        videoRef.current.currentTime,
        videoRef.current.duration
      );
      if (props.isLiveEpg && timeBeforeBuffer < SECONDS_TO_REVERT) {
        secondsToForward = videoRef.current.currentTime + timeBeforeBuffer;
      }
      setCurrentTime(secondsToForward);
    },
    [videoRef.current, props.isLiveEpg, props.isPlaying]
  );

  const backward = useCallback(
    (seconds: number) => {
      if (!videoRef.current || !props.isPlaying) {
        return;
      }
      setCurrentTime(videoRef.current.currentTime - seconds);
    },
    [videoRef.current, props.epgsObject, props.isPlaying]
  );

  const setCurrentTime = useCallback(
    (value: number) => {
      if (!videoRef.current) {
        return;
      }
      const duration = getDuration();
      if (value < 0 && props.isPlaying) {
        playPrevious(Math.abs(value));
        return;
      } else if (value > duration && props.isPlaying) {
        playNext(Math.abs(duration - value));
        return;
      } else if (isFinite(value)) {
        videoRef.current.currentTime = value;
        // Manually update current time
        props.setVideoOptions({ currentTime: value });
        //
      }
      if (props.isLiveEpg) {
        progress();
      }
    },
    [videoRef.current, props.isLiveEpg, props.epgsObject, props.isPlaying]
  );

  const setVideoToLive = useCallback(() => {
    const allEpgs = props.allEpgs as Epg[] | undefined;
    const epgs = props.epgsObject;
    if (allEpgs && epgs) {
      // find epg that is live streaming
      const now = getLivePoint();
      const currentEpg = allEpgs.find((epg) => epg.start < now && epg.stop > now);
      if (currentEpg) {
        const current = epgs.current;
        // Check if the epg is the same as the one playing one
        currentEpg.start !== current.start
          ? changeVideo(
              currentEpg.chan_id,
              currentEpg.start,
              currentEpg.stop,
              currentChannelPositionRef.current,
              -1
            )
          : setCurrentTime(getDuration() - TIME_BEFORE_BUFFER);
      }
    }
  }, [props.allEpgs, props.epgsObject]);

  const setVideoToBegining = useCallback(() => {
    setCurrentTime(0);
  }, []);

  /**
   * Get video duration
   * if video is VOD duration is equal to stop - start
   * if video is live duration is equal to loaded live time
   * @returns {*}
   */
  const getDuration = useCallback(() => {
    if (!videoRef.current) {
      return 0;
    }
    return videoRef.current.duration;
  }, [videoRef.current]);

  const ended = useCallback(() => {
    const next = props.epgsObject?.next;
    next && changeVideo(next.chanId, next.start, next.stop, currentChannelPositionRef.current, 0);
  }, [props.epgsObject?.next]);

  const playPrevious = useCallback(
    (fromEnd?: number) => {
      try {
        const prev = props.epgsObject?.prev;
        if (!prev) {
          return;
        }
        const startPosition = fromEnd ? prev.stop - prev.start - fromEnd : 0;
        changeVideo(
          prev.chanId,
          prev.start,
          prev.stop,
          currentChannelPositionRef.current,
          startPosition
        );
      } catch (error) {
        console.warn(error);
      }
    },
    [props.epgsObject?.prev]
  );

  const playNext = useCallback(
    (fromStart?: number) => {
      try {
        const next = props.epgsObject?.next;
        if (!next) {
          return;
        }
        const startPosition = fromStart ? fromStart - TIME_BEFORE_BUFFER : 0;
        changeVideo(
          next.chanId,
          next.start,
          next.stop,
          currentChannelPositionRef.current,
          startPosition
        );
      } catch (error) {
        console.warn(error);
      }
    },
    [props.epgsObject?.prev]
  );

  const timeUpdate = useCallback(() => {
    const currentTime = getCurrentTime();
    const videoOptions: any = {};

    //Update currentTime
    if (playerIsFullscreenRef.current && videoRef.current?.buffered.length) {
      videoOptions.currentTime = Math.floor(currentTime);
    }

    if (Object.keys(videoOptions).length !== 0) {
      props.setVideoOptions(videoOptions);
    }

    if (props.videoNow && currentTime >= props.videoStop - props.videoStart) {
      ended();
    }
  }, [videoRef.current, props.videoNow, props.videoStop, props.videoStart, ended]);

  const progress = useCallback(() => {
    const liveProgress = getDuration();
    const videoOptions: any = {};

    if (props.videoNow && props.videoStart + liveProgress > props.videoStop) {
      videoOptions.isLiveEpg = false;
    }

    if (Object.keys(videoOptions).length !== 0) {
      props.setVideoOptions(videoOptions);
    }
  }, [props.isLiveEpg, props.videoNow, props.videoStart, props.videoStop]);

  /**
   * Update the individual setting of quality for the current channel
   * @param {String} setting - the setting to update from channels[index].channelSettings
   * @param {*} value - the value to set for the current channel settings
   */
  const changeIndividualSetting = useCallback(
    (setting: ChannelSettingKeys, value: any) => {
      try {
        if (!props.channelId) return;
        props.setChannelSetting(props.channelId, setting, value);
      } catch (error) {
        console.warn(error);
      }
    },
    [props.profileSettings, props.channelId]
  );

  const changeNextQuality = useCallback(
    (value: number) => {
      props.setVideoOptions({ autoLevel: value < 0 });
      if (hls.current) hls.current.nextLevel = value;
    },
    [props.setVideoOptions, hls.current]
  );

  const changeQuality = useCallback(
    (value: number) => {
      if (!hls.current) {
        return;
      }
      const autoLevel = value < 0;
      props.setVideoOptions({ autoLevel });
      changeIndividualSetting('resolution', autoLevel ? -1 : hls.current.levels[value].height);
      if (hls.current) hls.current.currentLevel = value;
    },
    [hls.current, props.setVideoOptions]
  );

  const findLanguageName = useCallback((resourceList?: any[], value?: any) => {
    const language = resourceList?.find((e) => e.id === value);
    return value === -1 || isNil(language) ? -1 : language.name;
  }, []);

  const changeAudioTrack = useCallback(
    (value: number) => {
      if (!hls.current) {
        return;
      }
      hls.current.dvbAudioTrack = value;
      changeIndividualSetting('audio', findLanguageName(props.audioTracks, value));
    },
    [hls.current, props.audioTracks]
  );

  const changeSubtitleTrack = useCallback(
    (value: number) => {
      if (!hls.current) {
        return;
      }
      hls.current.subtitleTrack = value;
      changeIndividualSetting('subtitles', findLanguageName(props.subtitleTracks, value));
    },
    [hls.current, props.subtitleTracks]
  );

  const changeVideo = useCallback(
    (channelId: string, start: number, stop: number, position?: number, startPosition?: number) => {
      props.setCurrentVideo({
        isOpen: true,
        isPlaying: false,
        channelId,
        video: {
          start,
          stop,
          startPosition: startPosition ? startPosition : 0,
          channelPosition: position
        }
      });
    },
    [props.setCurrentVideo]
  );

  // Handle next video
  useEffect(() => {
    const numberFastZapping = !isNil(state.fastZapNumber);
    nextVideoDebounce.current = debounce(
      () => {
        if (!isEmpty(props.nextVideo) && props.nextVideo.autoPlay) {
          const nextVideo = props.nextVideo;
          if (nextVideo && nextVideo.position !== currentChannelPosition) {
            changeVideo(
              nextVideo.channelId,
              nextVideo.start,
              nextVideo.stop,
              nextVideo.position,
              -1
            );
          } else {
            props.resetNextVideo();
          }
        }
        // Reset states
        setState({ fastZapNumber: undefined });
        props.setVideoOptions({ fastZapping: false });
        //
        nextVideoDebounce.current = undefined;
      },
      numberFastZapping ? FAST_ZAP_NUMBER_INPUT_TIMEOUT : FAST_ZAPPING_TIMEOUT
    );
    nextVideoDebounce.current();
    return () => {
      nextVideoDebounce.current && nextVideoDebounce.current.cancel();
      nextVideoDebounce.current = undefined;
    };
  }, [props.nextVideo, state.fastZapNumber]);
  //

  // Live channel array sorted as like it would be without the paid channel position mask
  const sortedLiveChannelsByPosition = useMemo(() => {
    if (!props.liveChannels) {
      return [];
    }

    if (variables.WHITELABEL_TYPE === AppTheme.LINEAR) {
      return sortByPosition([...Object.values(props.liveChannels)]);
    }

    // If theme is ONDEMAND add favorite channels to the list
    return sortByPosition([
      ...Object.values(props.favoriteChannels),
      ...Object.values(props.liveChannels)
    ]);
  }, [props.liveChannels, props.favoriteChannels]);

  const handleFastZapping = useCallback(
    (param: ('up' | 'down') | number) => {
      if (
        isEmpty(sortedLiveChannelsByPosition) ||
        isNil(currentChannelPositionRef.current) ||
        playerControlsStateRef.current === 'channels'
      ) {
        return;
      }
      const _currentChannelPosition =
        props.nextVideo?.position || currentChannelPositionRef.current;
      const isDirectional = isString(param) ? ['up', 'down'].includes(param) : false;

      let currentChannelIndex = -1;
      if (isFinite(param as number) && !isDirectional) {
        currentChannelIndex = sortedLiveChannelsByPosition.findIndex((c) => c.position === param);
      } else if (isDirectional) {
        currentChannelIndex = sortedLiveChannelsByPosition.findIndex(
          (c) => c.position === _currentChannelPosition
        );
      }
      if (currentChannelIndex !== -1) {
        let nextChannelIndex = -1;
        if (isNumber(param)) {
          // if unlock overlay is presented we shouldn't use numbers to change channels
          if (showUnlockOverlay) {
            return;
          }
          nextChannelIndex = currentChannelIndex;
        } else if (isString(param)) {
          const offset = param === 'up' ? 1 : param === 'down' ? -1 : 0;
          let nextChannelIndex = currentChannelIndex + offset;

          /*
           * Note:
           * If channel is hidden we want to skip over it from the list when switching channels with DOWN and UP.
           * If direction is a Fast Zap Number it should be played, no matter that it is not visible in the channel lists.
           */
          const skipNextChannel = true;
          while (skipNextChannel) {
            // Wrap around if needed
            if (nextChannelIndex >= sortedLiveChannelsByPosition.length) {
              nextChannelIndex = 0;
            } else if (nextChannelIndex < 0) {
              nextChannelIndex = sortedLiveChannelsByPosition.length - 1;
            }

            const nextChannel = sortedLiveChannelsByPosition[nextChannelIndex];

            if (nextChannel.visible) {
              const { start, stop } = nextChannel.currentepg;
              props.setVideoOptions({ fastZapping: true });
              props.setNextVideo(nextChannel.id, start, stop, nextChannel.position, -1, true);
              // Force show mini view when switching to next channel
              isDirectional && toggleControls(true);
              break; // Exit the loop when a visible channel is found
            }

            // Move to the next channel in the specified direction
            nextChannelIndex += offset;
          }
        }

        // Handle switching with numbers
        const nextChannel = sortedLiveChannelsByPosition[nextChannelIndex];
        if (nextChannel) {
          const { start, stop } = nextChannel.currentepg;
          props.setVideoOptions({ fastZapping: true });
          props.setNextVideo(nextChannel.id, start, stop, nextChannel.position, -1, true);
        }
        // Force show mini view when switching to next channel
        isDirectional && toggleControls(true);
      }
    },
    [sortedLiveChannelsByPosition, props.nextVideo]
  );

  const toggleControlOnCanPlay = useCallback(() => {
    const skipToggleOnStates: PlayerControlsState[] = ['channels', 'bottom-view'];
    !skipToggleOnStates.includes(playerControlsStateRef.current) &&
      toggleControls(playerControlsStateRef.current !== 'expanded');
    props.resetNextVideo();
  }, [toggleControls]);

  // When player is playing and the channelId is changed
  useEffect(() => {
    if (props.isPlaying && !isEmpty(props.channelId)) {
      lockChannelsBack();
    }
  }, [props.isPlaying, props.channelId]);

  // Handle this function only on first load and fullscreen
  const onCanPlay = useCallback(() => {
    props.setPlayerPlaying(true);
    if (!playerIsFullscreenRef.current || !channelAccessRef.current) {
      return;
    }
    debouncedCalls.current && debouncedCalls.current.cancel();
    debouncedCalls.current = debounce(() => {
      requestIdleCallback(() => {
        if (props.channelId) {
          props.getAllEpgsForChannel(props.channelId);
          props.getLastWatchedEpgs();
          props.getMostWatchedEpgs();
        }
      });
    }, THROTTLE_TIMEOUT);
    debouncedCalls.current();
    props.setVideoOptions({ fastZapping: false });
    clearBuffering();
    toggleControlOnCanPlay();
    screenSaverToggle(false);
  }, [debouncedCalls.current, props.channelId, channelAccess, toggleControlOnCanPlay]);

  const onLoadStart = useCallback(() => {
    if (isEmpty(videoSourceRef.current) || !playerIsFullscreenRef.current) {
      return;
    }

    mediaPreloadTimeout.current = setTimeout(() => {
      setState({ showBuffer: true });
    }, variables.PRELOAD_TIMEOUT);
  }, []);

  const handleBackKey = useCallback(() => {
    switch (playerControlsStateRef.current) {
      case 'hidden':
        closePlayer();
        break;
      default:
        hideControls();
        break;
    }
  }, [closePlayer]);

  const handleMediaTrackNext = useCallback(() => {
    if (props.isLiveEpg) {
      setVideoToLive();
    } else {
      playNext();
    }
  }, [videoRef.current, props.isLiveEpg, props.epgsObject]);

  const handleMediaTrackPrevious = useCallback(() => {
    const currentTime = getCurrentTime();
    if (currentTime < PLAY_PREVIOUS_THRESHOLD) {
      playPrevious();
    } else {
      setVideoToBegining();
    }
  }, [videoRef.current, props.epgsObject?.prev]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const key = e.keyCode;
      const visible = playerIsFullscreenRef.current;

      if (!visible) {
        return;
      }

      const keyName = getRemoteKeyName(key);
      switch (keyName) {
        case 'OK':
          handleOk(e);
          break;
        // Back
        case 'BACK':
          {
            // Close player if unlock overlay is presented
            // and some ui is visible
            if (!(playerControlsStateRef.current === 'hidden' && showUnlockOverlayRef.current)) {
              handleBackKey();
            }
          }
          break;
        case 'PLAY':
          play();
          break;
        case 'PAUSE':
          pause();
          break;
        case 'PLAY_PAUSE':
          togglePlay();
          break;
        case 'TRACK_PREVIOUS':
          handleMediaTrackPrevious();
          break;
        case 'TRACK_NEXT':
          handleMediaTrackNext();
          break;
        case 'STOP':
          closePlayer();
          break;
        // Arrow up
        // Channel list up
        case 'ARROW_UP':
        case 'CHANNEL_UP':
          ['mini', 'hidden'].includes(playerControlsStateRef.current) && handleFastZapping('up');
          break;
        // Arrow down
        // Channel list down
        case 'ARROW_DOWN':
        case 'CHANNEL_DOWN':
          ['mini', 'hidden'].includes(playerControlsStateRef.current) && handleFastZapping('down');
          break;
        // Channel list button
        case 'CHANNEL_LIST':
          channelListDebounce.current && channelListDebounce.current.cancel();
          channelListDebounce.current = debounce(() => {
            if (playerControlsStateRef.current !== 'channels') {
              props.setPlayerControlsState('channels');
            } else {
              hideControls();
            }
          }, CONTROLS_EXPAND_DURATION);
          channelListDebounce.current();
          break;
        default:
          break;
      }
      if (!showUnlockOverlayRef.current) {
        handleNumberFastZap(e.key);
      }
      refreshControlsTimeout();
    },
    [
      props.isPlaying,
      props.channelId,
      props.nextVideo,
      props.liveChannels,
      videoRef.current,
      nextVideoDebounce.current,
      channelListDebounce.current,
      handleMediaTrackNext,
      handleMediaTrackPrevious,
      play,
      pause,
      togglePlay,
      closePlayer
    ]
  );

  const channelNumbers = useMemo(() => {
    if (isEmpty(props.liveChannels)) return [];

    const liveChannelNumbers = Object.values(props.liveChannels)
      // .filter((channel) => channel.access)
      .map((channel) => {
        return channel.position;
      });

    const favoriteChannelsNumbers = Object.values(props.favoriteChannels).map((e) => e.position);

    return [...favoriteChannelsNumbers, ...liveChannelNumbers];
  }, [props.liveChannels, props.favoriteChannels]);

  const handleNumberFastZap = useCallback(
    (key: string) => {
      const number = toNumber(key);
      if (isFinite(number) && !isEmpty(channelNumbers)) {
        // if channel is locked number fast zapping should be disabled
        if (showUnlockOverlay) {
          return;
        }

        // check if this causes Maximum update depth exceeded. warning
        setState((current) => {
          const fastZapNumber = toNumber(
            (current.fastZapNumber?.toString() || '')
              .concat(number.toString())
              .slice(0, MAX_FASTZAPP_LENGTH)
          );
          const channelNumber = findClosestNumber(channelNumbers, fastZapNumber);
          handleFastZapping(channelNumber);
          return { ...current, fastZapNumber };
        });
      }
    },
    [channelNumbers]
  );

  const clearOkHold = useCallback(() => {
    resumeFocusable();
    clearTimeout(holdingOkTimeoutRef.current);
    holdingOkTimeoutRef.current = undefined;
  }, []);

  const toggleControlsOnSelect = useCallback(() => {
    if (['hidden', 'mini'].includes(playerControlsStateRef.current)) {
      toggleControls();
    }
  }, [toggleControls]);

  const openChannelListOnHold = useCallback(() => {
    // Open channel list only if unlock overlay is not visible and player controls state is hidden or mini
    !showUnlockOverlayRef.current &&
      ['hidden', 'mini'].includes(playerControlsStateRef.current) &&
      props.setPlayerControlsState('channels');
  }, []);

  const onLongPressOk = useCallback(() => {
    pauseFocusable();
    openChannelListOnHold();
  }, [openChannelListOnHold]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const keyName = getRemoteKeyName(e.keyCode);
      // Handle OK key only if it was pressed before that
      if (keyName === 'OK') {
        clearOkHold();
        // Continue with the action only if OK was pressed before
        if (!pressedOkRef.current) return;
        pressedOkRef.current = false;
        toggleControlsOnSelect();
      }
    },
    [toggleControlsOnSelect]
  );

  const handleOk = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // Toggle press flag for OK key when player controls state is hidden or mini
      if (['mini', 'hidden'].includes(playerControlsStateRef.current)) {
        pressedOkRef.current = true;
      }
      // Handle fast zap
      if (nextVideoDebounce.current) {
        nextVideoDebounce.current.flush();
        return;
      }
      // Implementation of long press on remotes that
      // support repeating events. (E.g. LG)
      if (e.repeat) {
        pauseFocusable();
        openChannelListOnHold();
      }
      if (!holdingOkTimeoutRef.current)
        holdingOkTimeoutRef.current = setTimeout(onLongPressOk, variables.HOLD_TIMEOUT);
    },
    [toggleControlsOnSelect, onLongPressOk]
  );

  const onClick = useCallback(() => {
    const visible = playerIsFullscreenRef.current;
    if (!visible) {
      return;
    }
    toggleControlsOnSelect();
  }, [toggleControlsOnSelect]);

  const getRef = useCallback(
    (ref: VideoRefObject) => {
      if (ref) {
        if (ref.hls) hls.current = ref.hls;
        if (ref.video) {
          videoRef.current = ref.video;
          props.isFullscreen && videoRef.current.focus();
        }
      }
    },
    [props.isFullscreen]
  );

  const renderRadioOverlay = () =>
    radioImages && (
      <>
        <Image className="radio-overlay" src={radioImages.radio_wallpaper_image.url} />
        <Image
          className="radio-logo-overlay"
          src={props.logos[props.channelId as string]?.pngWhite}
        />
      </>
    );

  const handlePlayVideo = useCallback(() => {
    if (!props.channelId) {
      return;
    }

    const currentEpg = props.liveChannels[props.channelId].currentepg;

    props.playVideo(currentEpg, { startPosition: -1 });
    props.setVideoOptions({
      isFullscreen: true
    });
  }, [props.playVideo, props.setVideoOptions]);

  const handleUnlockChannelsSuccess = useCallback(() => {
    temporarilyUnlock();
    resumeFocusable();
    handlePlayVideo();
    setShowUnlockOverlay(false);
  }, [temporarilyUnlock, handlePlayVideo]);

  const renderChannelAccessOverlay = useCallback(() => {
    if (showPaidOverlay) {
      return <ChannelAccessOverlay channelId={props.channelId} />;
    }

    if (showUnlockOverlay) {
      return (
        <LockChannelOverlay
          channelId={props.channelId}
          onSuccess={handleUnlockChannelsSuccess}
          onBack={handleBackKey}
          videoRef={videoRef}
        />
      );
    }
  }, [showUnlockOverlay, showPaidOverlay, props.channelId, props.playerControlsState]);

  const renderLoadingIndicator = () => state.showBuffer && <LoadingIndicator />;

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        className={getClassName(`${isEmpty(props.className) ? '' : props.className}`, {
          fullscreen: props.isFullscreen,
          playing: props.isPlaying
        })}
        ref={focusRef}
        onKeyDown={hasFocusedChild || showPaidOverlay || showUnlockOverlay ? onKeyDown : undefined}
        onKeyUp={hasFocusedChild || showPaidOverlay || showUnlockOverlay ? onKeyUp : undefined}
        onClick={onClick}
        onMouseMove={refreshControlsTimeout}
        tabIndex={props.videoSource ? 0 : -1}
      >
        <div className="video-container">
          <Video
            channelId={props.channelId}
            src={videoSource}
            hash={props.videoHash}
            startPosition={props.videoStartPosition}
            tabIndex={props.videoSource ? 0 : -1}
            onTimeUpdate={timeUpdate}
            onEnded={ended}
            onProgress={progress}
            ref={getRef}
            onCanPlay={!props.isPlaying ? onCanPlay : undefined}
            onLoadStart={onLoadStart}
            onSubtitleTrackSwitch={onSubtitleTrackSwitch}
            onSubtitlesUpdated={onSubtitlesUpdated}
            onDvbAudioTrackSwitch={onDvbAudioTrackSwitch}
            onDvbAudioTracksUpdated={onDvbAudioTracksUpdated}
            onLevelSwitched={onLevelSwitched}
            onLevelsUpdated={onLevelsUpdated}
            onHlsError={handleHlsError}
            onBufferAppended={onBufferAppended}
          />
          {renderRadioOverlay()}
          {renderChannelAccessOverlay()}
          {renderLoadingIndicator()}
        </div>
        <Controls
          toggleControls={toggleControls}
          hideControls={hideControls}
          showControls={showControls}
          play={play}
          pause={pause}
          setCurrentTime={setCurrentTime}
          setVideoToBegining={setVideoToBegining}
          setVideoToLive={setVideoToLive}
          forward={forward}
          backward={backward}
          togglePlay={togglePlay}
          playPrevious={playPrevious}
          changeSubtitleTrack={changeSubtitleTrack}
          changeAudioTrack={changeAudioTrack}
          changeQuality={changeQuality}
          fastZapNumber={state.fastZapNumber}
          disabled={showUnlockOverlay}
        />
      </div>
    </FocusContext.Provider>
  );
};

interface PlayerStore {
  videoPlayer: IVideoPlayer;
  liveChannels: LiveChannels;
  logos: any;
  i18n: typeof translationPacket;
  profileSettings: any;
  channelSettings: any;
  favoriteChannels: FavoriteChannels;
  authentication: { token: string };
}
const mapStateToProps = ({
  videoPlayer,
  liveChannels,
  logos,
  i18n,
  profileSettings,
  channelSettings,
  favoriteChannels,
  authentication
}: PlayerStore) => {
  return {
    currentAuthToken: authentication.token,
    allEpgs: videoPlayer.allEpgs,
    videoSource: videoPlayer.video?.source,
    videoStart: videoPlayer.video?.start,
    videoStop: videoPlayer.video?.stop,
    videoStartPosition: videoPlayer.video?.startPosition,
    videoPosition: videoPlayer.video?.channelPosition,
    channelId: videoPlayer.channelId,
    epgsObject: videoPlayer.epgs,
    isLiveEpg: videoPlayer.video?.isLiveEpg,
    isOpen: videoPlayer.isOpen,
    isFullscreen: videoPlayer.video?.isFullscreen,
    isPlaying: videoPlayer.isPlaying,
    nextVideo: videoPlayer.nextVideo,
    videoNow: videoPlayer.video?.now,
    videoAccess: videoPlayer.video?.access,
    videoLocked: videoPlayer.video?.locked,
    videoHash: videoPlayer.video?.hash,
    audioTracks: videoPlayer.video?.audioTracks,
    subtitleTracks: videoPlayer.video?.subtitleTracks,
    logos,
    i18n,
    playerControlsState: videoPlayer.playerControlsState,
    liveChannels,
    profileSettings,
    channelSettings,
    favoriteChannels
  };
};

export default React.memo(
  connect(mapStateToProps, {
    setError,
    setPlayerPlaying,
    getAllEpgsForChannel,
    setVideoPlayerEpgs,
    setCurrentVideo,
    setVideoOptions,
    resetVideo,
    getLiveChannels,
    resetEpgs,
    playVideo,
    setNextVideo,
    resetNextVideo,
    removeUserDetails,
    setPlayerControlsState,
    getLastWatchedEpgs,
    getMostWatchedEpgs,
    setChannelSetting
  })(Player)
);
