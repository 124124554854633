import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import checkboxSelected from '@assets/media/checkboxSelected.svg';
import checkboxUnselected from '@assets/media/checkboxUnselected.svg';
import { setChannelSetting, translationPacket } from 'common/actions';
import { IconSize } from 'common/components/Icon';
import { LOGO_TYPES } from 'common/config/constants';
import variables from 'common/config/variables';
import { FAVORITES_MAX_LIMIT, FAVORITES_MIN_LIMIT } from 'common/config/variables/default';
import { LiveChannels } from 'common/constants/data-types';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { AppTheme } from 'common/interfaces';
import { ChannelSettings, ProfileSettings } from 'common/reducers/profileSettings';
import { getChannelLogo } from 'common/services/helpers';
import { sortByPosition } from 'common/utils';
import { StoreState } from 'index';
import { useSnackbar } from 'notistack';
import { ThunkActionDispatch } from 'redux-thunk';
import ChannelsReorderDrawer from './ChannelsReorderDrawer';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';

interface Props {
  i18n: typeof translationPacket;
  liveChannels: LiveChannels;
  logos: [];
  profileSettings: ProfileSettings;
  setChannelSetting: ThunkActionDispatch<typeof setChannelSetting>;
  // setChannelsOrder: ThunkActionDispatch<any>;
}

function FavoriteChannelsDrawer({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  setChannelSetting
}: Props) {
  const { openDrawer } = useDrawer();
  const { enqueueSnackbar } = useSnackbar();

  const { channels: channelSettings } = useMemo(
    () => profileSettings?.react_tv_settings,
    [profileSettings?.react_tv_settings]
  );

  const favoriteChannelsSettings = useMemo(
    () =>
      Object.keys(channelSettings).reduce((acc, channelId) => {
        const channel = channelSettings[channelId];
        if (channel.favorite) {
          acc[channelId] = { ...channel };
        }
        return acc;
      }, {}) as ChannelSettings,
    [channelSettings]
  );
  const favoriteChannelsSettingsList = useMemo(
    () => Object.values(favoriteChannelsSettings),
    [favoriteChannelsSettings]
  );

  const onMenuItemPress = useCallback(
    (item: SettingsMenuItem) => {
      const channelItem = channelSettings[item.id];

      // if favorites are more than max limit show error
      if (
        FAVORITES_MAX_LIMIT &&
        favoriteChannelsSettingsList.length >= FAVORITES_MAX_LIMIT &&
        !channelItem?.favorite
      ) {
        //@ts-ignore
        enqueueSnackbar(favoriteChannelsMaxError, { title: i18n.snackbar.alert });
        return;
      }

      // Toggle favorite
      const favorite = !channelItem?.favorite;
      setChannelSetting(item.id, 'favorite', favorite);
      // Auto add to the end of the list
      setChannelSetting(
        item.id,
        'favoritePosition',
        favorite ? favoriteChannelsSettingsList.length : 0
      );
      if (variables.WHITELABEL_TYPE === AppTheme.ON_DEMAND) {
        // setChannelsOrder(order);
      }
    },
    [channelSettings, i18n.mainSettings.favoriteChannelsMaxError, favoriteChannelsSettingsList]
  );

  const menuItems = useMemo(() => {
    try {
      const liveChannelsArr = sortByPosition(Object.values(liveChannels));

      const channelMenuItems = liveChannelsArr.map((liveChannel) => {
        const { id, position, name } = liveChannel;

        const icon = {
          activeState: channelSettings[id].favorite,
          activeIcon: checkboxSelected,
          inactiveIcon: checkboxUnselected,
          size: 'medium' as IconSize
        };

        return {
          id,
          position,
          channelLogo: getChannelLogo(liveChannel.id, logos, LOGO_TYPES.png),
          name,
          icon,
          type: MENU_ITEMS.toggleOptionItem
        };
      });

      // If reorder channels is enabled, return only the channelMenuItems
      // Reordering will happen in a different menu
      if (variables.REORDER_CHANNELS_ENABLED) {
        return channelMenuItems;
      }

      const innerMenuItem = {
        id: 'reorder-channels-menu-item',
        label: i18n.mainSettings.channelsReorder,
        disabled: favoriteChannelsSettingsList.length < FAVORITES_MIN_LIMIT,
        onClick: async () => {
          if (favoriteChannelsSettingsList.length < FAVORITES_MIN_LIMIT) return;
          openDrawer({
            id: 'reorder-channels-drawer',
            content: <ChannelsReorderDrawer />,
            positionContent: 'top'
          });
        },
        type: MENU_ITEMS.innerMenuItem
      };

      return [innerMenuItem, ...channelMenuItems];
    } catch (error) {
      console.warn(error);

      return [];
    }
  }, [liveChannels, logos, favoriteChannelsSettingsList]);

  return (
    <SettingsListDrawer
      drawerTitle={i18n.mainSettings.favorites}
      drawerSubtitle={i18n.mainSettings.favoritesSubmenuText}
      menuItems={menuItems}
      onMenuItemPress={onMenuItemPress}
    />
  );
}

const mapDispatchToProps = {
  setChannelSetting
};

const mapStateToProps = ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  appConfig
}: StoreState) => ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  navigateMode: appConfig.navigateMode
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FavoriteChannelsDrawer));
