import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BottomView from './BottomView';
import './Controls.styles';
import TopView from './TopView';
const { DEFAULT_ANIMATION_DURATION } = variables;

export interface PlayerControlsProps {
  toggleControls: (mini?: boolean, timeout?: number) => void;
  showControls: (mini?: boolean) => void;
  hideControls: () => void;
  togglePlay: (toPlay?: boolean) => void;
  forward: (seconds: number) => void;
  backward: (seconds: number) => void;
  setVideoToLive: () => void;
  setVideoToBegining: () => void;
  setCurrentTime: (time: number) => void;
  play: () => void;
  pause: () => void;
  playPrevious: (fromEnd?: number) => void;
  changeSubtitleTrack: (value: number) => void;
  changeAudioTrack: (value: number) => void;
  changeQuality: (value: number) => void;
  fastZapNumber?: number;
  disabled?: boolean;
}

type IPlayerControlsProps = PlayerControlsProps & {
  isFullscreen?: boolean;
  playerControlsState: PlayerControlsState;
};

function PlayerControls(props: IPlayerControlsProps) {
  const { closeDrawer } = useDrawer();
  const { ref, focusKey } = useFocusable({
    focusable: !props.disabled,
    focusKey: 'PLAYER_CONTROLS',
    preferredChildFocusKey: 'TOGGLE_PLAY',
    saveLastFocusedChild: true,
    isFocusBoundary: true,
    autoRestoreFocus: true
  });
  const { hasPopups } = usePopup();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasPopups || props.disabled) {
      return;
    }
    if (props.isFullscreen) {
      ['hidden', 'mini', 'seeking'].includes(props.playerControlsState) && setFocus('SEEKBAR');
      props.playerControlsState === 'channels' && setFocus('CHANNEL_LIST');
    }
    return () => clearTimeout(timeout);
  }, [props.playerControlsState, props.isFullscreen, hasPopups, props.disabled]);

  // Close drawer when player is not expanded or controls are hidden
  useEffect(() => {
    (props.playerControlsState === 'hidden' || !props.isFullscreen) &&
      closeDrawer('player-settings', false);
  }, [props.playerControlsState, props.isFullscreen]);

  // Skip rendering of controls when player is not fullscreen
  if (!props.isFullscreen) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <div className={`controls-container ${props.isFullscreen ? 'visible' : ''}`} ref={ref}>
        <TopView
          showControls={props.showControls}
          toggleControls={props.toggleControls}
          changeAudioTrack={props.changeAudioTrack}
          changeQuality={props.changeQuality}
          changeSubtitleTrack={props.changeSubtitleTrack}
          fastZapNumber={props.fastZapNumber}
        />
        <BottomView {...props} />
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ videoPlayer }: any) => {
  return {
    playerControlsState: videoPlayer.playerControlsState,
    isFullscreen: videoPlayer.video?.isFullscreen
  };
};
export default React.memo(connect(mapStateToProps, {})(PlayerControls));
