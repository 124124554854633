import { prismicClient } from 'common/components/carousel/prismic';
import {
  CAROUSEL_BANNER_PLAYING_TOGGLE,
  CAROUSEL_SET_BANNERS,
  CAROUSEL_TOGGLE
} from 'common/constants/action-types';
import { AppDispatch } from 'index';

export const toggleCarousel = (isExpanded?: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CAROUSEL_TOGGLE,
      payload: isExpanded
    });
  };
};

export const retrieveBanners = () => {
  try {
    return async (dispatch: AppDispatch) => {
      if (!prismicClient) return;

      const [{ data }] = await prismicClient.getAllByType('banners');
      dispatch({
        type: CAROUSEL_SET_BANNERS,
        payload: data
      });
    };
  } catch (error) {
    console.warn(error);
  }
};

export const toggleCarouselPlaying = (isPlaying?: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CAROUSEL_BANNER_PLAYING_TOGGLE,
      payload: isPlaying
    });
  };
};
