import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { translationPacket } from 'common/actions';

import { PinInput } from 'common/components/input';
import { usePopup } from 'common/contexts/popup';
import { StoreState } from 'index';
import { useSnackbar } from 'notistack';
import {
  LockIconWithText,
  PinContainer,
  PinTitle,
  PopupContainer,
  Stepper,
  StepperLine,
  StepperLines
} from './LockedChannel.styles';

interface PinChangeProps {
  popupId: string;
  channelSettings: {
    defaultPIN: string;
    lockedPIN: string;
  };
  i18n: typeof translationPacket;
  onSuccess: (pin: string) => void;
}

enum PinChangeSteps {
  OLD_PIN = 'old-pin',
  NEW_PIN = 'new-pin',
  REPEAT_PIN = 'repeat-pin'
}

type PinChangeStep = {
  id: string;
  title: string;
};

function PinChange({ i18n, channelSettings, onSuccess }: PinChangeProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [pin, setPinValue] = useState({});
  const [error, setError] = useState('');
  const { closePopup } = usePopup();
  const { enqueueSnackbar } = useSnackbar();

  const next = useCallback(
    (step: PinChangeStep) => (pinValue: string) => {
      setPinValue((prevState) => ({
        ...prevState,
        [step.id]: pinValue
      }));

      switch (step.id) {
        case PinChangeSteps.OLD_PIN: {
          const oldPin = channelSettings.lockedPIN || channelSettings.defaultPIN;

          if (pinValue === oldPin) {
            setActiveStep(activeStep + 1);
          } else {
            setError(i18n.popups.locked.error);
          }
          break;
        }

        case PinChangeSteps.NEW_PIN: {
          if (pinValue === channelSettings.lockedPIN) {
            setError(i18n.popups.locked.errorNewPin);
            return;
          }

          setActiveStep(activeStep + 1);
          break;
        }

        case PinChangeSteps.REPEAT_PIN: {
          if (pinValue !== pin[PinChangeSteps.NEW_PIN]) {
            setError(i18n.popups.locked.errorRepeatPin);
            return;
          }
          if (pinValue === pin[PinChangeSteps.NEW_PIN]) {
            onSuccess(pinValue);
            closePopup('pin-change-popup');
            enqueueSnackbar(i18n.popups.locked.successfulChange);
          }
          break;
        }

        default:
          break;
      }
    },
    [channelSettings, activeStep, pin, i18n]
  );

  const handleBackspace = useCallback(
    (step: PinChangeStep) => (value: string) => {
      setPinValue((prevState) => ({
        ...prevState,
        [step.id]: value
      }));
      setError('');

      if (step.id === PinChangeSteps.OLD_PIN) {
        closePopup('pin-change-popup');
        return;
      }

      if (activeStep === 1 || activeStep === 2) {
        setActiveStep(activeStep - 1);
      }
    },
    [pin, activeStep]
  );

  const steps = useMemo(() => {
    return [
      {
        id: PinChangeSteps.OLD_PIN,
        title: i18n.popups.locked.enterOldPIN
      },
      {
        id: PinChangeSteps.NEW_PIN,
        title: i18n.popups.locked.enterNewPIN
      },
      {
        id: PinChangeSteps.REPEAT_PIN,
        title: i18n.popups.locked.repeatNewPIN
      }
    ];
  }, [i18n]);

  const activeClass = useCallback(
    (index: number) => {
      if (error) {
        return 'error';
      }

      return index <= activeStep ? 'active' : '';
    },
    [activeStep, error]
  );

  return (
    <PopupContainer>
      <LockIconWithText>{i18n.popups.locked.changePIN}</LockIconWithText>
      <Stepper>
        <StepperLines>
          {steps.map((step, index) => (
            <StepperLine key={index} className={activeClass(index)} />
          ))}
        </StepperLines>
      </Stepper>
      <PinContainer>
        {steps.map(
          (step, index) =>
            activeStep === index && (
              <div key={`${step.title}-pin-input`}>
                <PinTitle>{error || step.title}</PinTitle>
                <PinInput
                  onBack={handleBackspace(step)}
                  onComplete={next(step)}
                  error={error}
                  clearError={() => setError('')}
                />
              </div>
            )
        )}
      </PinContainer>
    </PopupContainer>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = ({ i18n, channelSettings }: StoreState) => ({
  i18n,
  channelSettings
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PinChange));
