import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  CHANNEL_POSITION_TYPE,
  IS_LIVE_API,
  PAID_CHANNEL_POSITION_OFFSET,
  PRODUCT_NAME,
  SENTRY_DSN
} from 'common/config/constants';
import variables from 'common/config/variables';
import { AppTheme } from 'common/interfaces';
import { getToday } from 'common/services/helpers';
import { arrayReduce } from 'fast-loops';
import { isEmpty, isEqual, isNil } from 'lodash';

const instance = axios.create({
  baseURL: 'https://pg-app-ieof9w8wfld2dfgsbdukkddjikzyd3.scalabl.cloud/1'
});
instance.defaults.headers['Content-Type'] = 'application/json';
instance.defaults.headers.common['X-Parse-Application-Id'] =
  'TOeeyWdB8FDS2mqbxr8FCIOOAUiQIBWaGKRLVYaK';
instance.defaults.headers.common['X-Parse-JavaScript-Key'] =
  '0z0DTyyMlOngHUh5eo64r4xXaIARLKGReOiiBLYk';

const langs = require('langs');
const availableLanguages = langs.all();

export const handleSettingAppLanguage = () => {
  try {
    if (langs.has('1', variables.APP_LANGUAGE)) {
      document.body.lang = variables.APP_LANGUAGE;
    }
  } catch (error) {
    console.warn("Error setting app language", error);
  }
}

export const enableSentryIntegration = () => {
  const environment = `${PRODUCT_NAME}-${IS_LIVE_API ? 'production' : 'development'}`
  !isEmpty(SENTRY_DSN) &&
    Sentry.init({
      dsn: SENTRY_DSN,
      environment,
    });
};

export const trace = (params) => {
  instance.post('/classes/errors', params);
};

export const enableWindowOnError = ({ product } = {}) => {
  if (PRODUCT_NAME !== product) return null;

  window.onerror = (message, source, lineno, colno, error) => {
    if (error) message = error.stack;

    if (message.includes('defined') || message.includes('ReferenceError')) {
      trace({
        message,
        lineno,
        colno,
        error: error,
        userAgent: navigator.userAgent
      });
    }
  };
};

export const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const changeWebSetting = (profileSettings, newSetting) => {
  return {
    ...profileSettings,
    react_tv_settings: { ...profileSettings.react_tv_settings, ...newSetting }
  };
};

export const findDeviceSettingsItem = (deviceSettingsList, id) =>
  deviceSettingsList[id]

export const findDeviceSettingsItemIndex = (deviceSettingsList, id) =>
  deviceSettingsList?.findIndex((e) => e.channelInfo.id === id);

export const sha256 = async (source) => {
  const sourceBytes = new TextEncoder().encode(source);
  const digest = await crypto.subtle.digest('SHA-256', sourceBytes);
  const resultBytes = [...new Uint8Array(digest)];
  return resultBytes.map((x) => x.toString(16).padStart(2, '0')).join('');
};

export const checkVisibleStatusForChannel = (profileSettings, id) => {
  if (!profileSettings || !profileSettings.react_tv_settings) {
    return true;
  }
  const channelItem = findDeviceSettingsItem(
    profileSettings.react_tv_settings.channels,
    id
  );
  return channelItem ? channelItem.visible : true;
};

/**
 * @param {*} profileSettings
 * @param {*} id
 * @returns {Boolean}
 * true - if channel is currently locked
 * false - if channel is is currently unlocked or not locked at all
 */
export const checkLockStatusForChannel = (profileSettings, id) => {
  if (!profileSettings || !profileSettings.react_tv_settings) {
    return false;
  }
  const channelItem = findDeviceSettingsItem(
    profileSettings.react_tv_settings.channels,
    id
  );

  const channelLocked = channelItem && channelItem.locked;
  return channelLocked;
};

export const resolveObject = (path, obj) => {
  return path.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || global.self);
};

export const diffObjects = (object1, object2, keys = []) => {
  for (let key of keys) {
    const prev = resolveObject(key, object1);
    const current = resolveObject(key, object2);
    if (!isEqual(prev, current)) {
      return true;
    }
  }
  return false;
};

export const deepMergeFlatten = (obj1, obj2) => {
  const keys = Object.keys(obj2);
  let nextObj = { ...obj1 };

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = obj2[key];
    if (typeof value === 'object' && value !== null) {
      nextObj = { ...nextObj, ...deepMergeFlatten(nextObj, value) };
    } else {
      nextObj = { ...nextObj, [key]: value };
    }
  }
  return nextObj;
};

export const convertLanguages = (languages = [], fromFormat = '3', toFormat = ['2B']) => {
  // return languages.map((language) => {
  //   return availableLanguages.find(e => e[fromFormat] === language)[toFormat]
  // })
  const mappedLanguages = [];
  const toFormats = [...toFormat];
  languages.forEach((language) => {
    const foundLanguage = availableLanguages.find((e) => e[fromFormat] === language);
    foundLanguage && mappedLanguages.push({
      [fromFormat]: foundLanguage[fromFormat],
      // [toFormat]: foundLanguage[toFormat]
      ...toFormats.reduce((acc, curr) => ({ ...acc, [curr]: foundLanguage[curr] }), {})
    });
  });
  return mappedLanguages;
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function registerKeys() {
  try {
    if (typeof window.tizen === 'undefined') {
      return;
    }
    const usedKeys = [
      'MediaPause',
      'MediaPlay',
      'MediaStop',
      'MediaFastForward',
      'MediaRewind',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'ColorF0Red',
      'Info',
      'Guide',
      'ChannelList',
      'ChannelUp',
      'ChannelDown',
      'Cancel'
    ];
    const getSupportedKeys = () => {
      let i,
        keyCode = {},
        supportedKeys;
      supportedKeys = tizen.tvinputdevice.getSupportedKeys();
      for (i = 0; i < supportedKeys.length; i++) {
        keyCode[supportedKeys[i].name] = supportedKeys[i].code;
      }
      return keyCode;
    };
    // var usedKeys = Object.keys(getSupportedKeys());
    for (let i = 0; i < usedKeys.length; i++) {
      tizen.tvinputdevice.registerKey(
        usedKeys[i],
        function () { },
        function (err) {
          console.warn('Error: ' + err.message);
        }
      );
    }
  } catch (e) {
    console.warn(e);
    // console.warn('failed to register ' + usedKeys[i] + ': ' + e);
  }
}

export function isInViewport(element, offsetX = 0, offsetY = 0, container) {
  if (isEmpty(element)) {
    return false;
  }
  const rect = element.getBoundingClientRect();
  const containerRect = container?.getBoundingClientRect();
  const containerHeight = container
    ? containerRect.height
    : window.innerHeight || document.documentElement.clientHeight;
  const containerWidth = container
    ? containerRect.width
    : window.innerWidth || document.documentElement.clientWidth;

  const containerOffsetLeft = container ? containerRect.left : 0;
  const containerOffsetTop = container ? containerRect.top : 0;
  return (
    Math.ceil(rect.top + offsetY) >= containerOffsetTop &&
    Math.ceil(rect.left + offsetX) >= containerOffsetLeft &&
    Math.ceil(rect.top) <= containerHeight + containerOffsetTop &&
    Math.ceil(rect.left) <= containerWidth + containerOffsetLeft
  );
}

export function getRootPath(path) {
  const route = `/${(path || '').split('/')[1]}`;
  return route;
}

export function getPageName(path) {
  return getRootPath(path).split('/')[1];
}

export function checkWebAssembly() {
  try {
    if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
      const module = new WebAssembly.Module(
        Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
      );
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {
    return false;
  }
  return false;
}

function b64ToUint6(nChr) {
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
      ? nChr - 71
      : nChr > 47 && nChr < 58
        ? nChr + 4
        : nChr === 43
          ? 62
          : nChr === 47
            ? 63
            : 0;
}

export function base64DecToArr(sBase64, nBlockSize) {
  let sB64Enc = sBase64; // sBase64.replace(/[^A-Za-z0-9\+\/]/g, '');
  let nInLen = sB64Enc.length;
  let nOutLen = nBlockSize
    ? Math.ceil(((nInLen * 3 + 1) >>> 2) / nBlockSize) * nBlockSize
    : (nInLen * 3 + 1) >>> 2;
  let aBytes = new Uint8Array(nOutLen);
  let nMod3;
  let nMod4;
  let nUint24;
  let nOutIdx;
  let nInIdx;

  // logger.log(`base64DecToArr: enter: nInLen = ${nInLen}`);

  for (nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        aBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
      }
      nUint24 = 0;
    }
  }

  // logger.log(`base64DecToArr: exit: aBytes.length = ${aBytes.length}`);

  return aBytes;
}

export function hexToRGB(hex, alpha = 1) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export const timeout = async (time) => {
  return await new Promise((r) => setTimeout(r, time));
};

export const handleStaticViewportHeight = () => {
  const height = window.innerHeight;
  try {
    document.getElementById('viewport-meta-tag').content =
      `width=device-width,height=${height}, initial-scale=1.0`;
  } catch (error) {
    console.warn(error)
  }
}

export function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : 0;
}

/**
 * 
 * @param {number} start 
 * @param {number} stop 
 * @returns {number}
 */
const calculateChannelProgress = (start, stop) => {
  const length = (stop - start) * 1000;
  const timePassed = getToday().getTime() - start * 1000;

  const percentPassed = Math.ceil((timePassed * 100) / length);

  return Math.max(0, Math.min(100, percentPassed));
};

/**
 * 
 * @param {*} channels 
 * @returns {LiveChannel[]}
 */
const propagateChannelsAccess = (channels) => {
  return channels.map((channel) => {
    return {
      ...channel,
      position: channel.access ? channel.position : channel.position % PAID_CHANNEL_POSITION_OFFSET,
      currentepg: {
        ...channel.currentepg,
        access: channel.access
      },
      nextepg: {
        ...channel.nextepg,
        access: channel.access
      }
    };
  });
};

// POSITION_TYPE 0 - set channel position to be the position returned from the back-end
// POSITION_TYPE 1 - set channel position to be numerical from the index in the sorted position
const getChannelPositionMap = {
  0: (position, index) => position,
  1: (position, index) => index + 1
};

/**
 * 
 * @param {LiveChannel[]} sortedChannels 
 * @param {import('common/reducers/profileSettings').ChannelSettings?} channels
 */
export function aggregateChannels(sortedChannels, channels) {
  const liveChannels = arrayReduce(
    propagateChannelsAccess(sortedChannels),
    (a, v, index) => {
      const channelSettings = !isEmpty(channels) && channels[v.id];

      // get channel position based on the POSITION_TYPE
      const position = (channelSettings?.position && variables.REORDER_CHANNELS_ENABLED)
        ? channelSettings.position
        : getChannelPositionMap[CHANNEL_POSITION_TYPE](v.position, index)

      // adding additional properties
      const enchancedLiveChannel = {
        ...v,
        progress: calculateChannelProgress(v.currentepg.start, v.currentepg.stop),
        position,
        originalPosition: getChannelPositionMap[CHANNEL_POSITION_TYPE](v.position, index),
        visible: channelSettings ? channelSettings.visible : true,
        locked: channelSettings ? channelSettings.locked : v.adult || false,
        favorite: channelSettings ? channelSettings.favorite : false
      };
      return {
        ...a,
        [v.id]: enchancedLiveChannel
      };
    },
    {}
  );

  // return accumulated live channels
  return liveChannels
}

/**
 * 
 * @param {FavoriteChannel[]} favoriteChannels 
 * @param {DeviceSettingItem[]} channels 
 * @returns {FavoriteChannel[]}
 */
export function aggregateFavoriteChannels(favoriteChannels, channels) {
  return favoriteChannels.map((channel) => {
    const settingItem = (channels).find(
      x => x.channelInfo.id === channel.id
    );

    // If LinearTV return the channel without changing position
    if (variables.WHITELABEL_TYPE === AppTheme.LINEAR) {
      return channel;
    }

    return {
      ...channel,
      position:
        settingItem && !isNil(settingItem.channelSettings.favoritePosition)
          ? settingItem.channelSettings.favoritePosition + 1
          : channel.position
    };
  });
}

/**
 * @param {liveChannels[]}
 * @param {DeviceSettingItem[]} channels 
 * @returns {FavoriteChannel[]}
 */
export function getLiveChannelsMissingSettings(liveChannels, channels) {
  // if some channel ids are not found in channels add them
  // this is to make sure that all available channels have corresponding setting object for them
  // because if channels is generated earlier (before new channels are added)
  // they will be missing from the channels and will be not interractable in settings drawer menus
  let missing = [];

  Object.values(liveChannels).forEach((liveChannel) => {
    const channelItem = findDeviceSettingsItem(channels, liveChannel.id);

    if (!channelItem) {
      missing.push(liveChannel);
    }
  });

  return missing;
}

export function excludeFields(fieldsToExclude, object) {
  return _.pickBy(object, (value, key) => {
    return !fieldsToExclude.includes(key)
  });
}

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {{ width: number, height: number }}
 */
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}


// To detect the focus is in input field or not use below code
export function isInputFieldFocused() {
  var activeElement = document.activeElement;
  if (
    activeElement &&
    (activeElement.tagName.toLowerCase() === "input" ||
      activeElement.tagName.toLowerCase() === "textarea") &&
    !isEmpty(activeElement.value)
  ) {
    return true;
  } else {
    return false;
  }
}