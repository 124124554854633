// organize-imports-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-var-requires
import 'core-js/actual';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'unfetch/polyfill';
// organize-imports-disable-above
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
// use polyfilled Path2D
import 'element-scroll-polyfill';
import 'intersection-observer';
import './Path2D';
// organize-imports-disable-above

/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.fromEntries) {
  Object.fromEntries = function (entries: any) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error('Object.fromEntries() requires a single iterable argument');
    }
    const obj: object = {};
    for (const [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

/*
 * Node.isConnected polyfill for EdgeHTML
 * 2021-04-12
 *
 * By Eli Grey, https://eligrey.com
 * Public domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

if (!('isConnected' in Node.prototype)) {
  Object.defineProperty(Node.prototype, 'isConnected', {
    get() {
      return (
        !this.ownerDocument ||
        !(this.ownerDocument.compareDocumentPosition(this) & this.DOCUMENT_POSITION_DISCONNECTED)
      );
    }
  });
}

// Define globalThis
//@ts-ignore
window.globalThis = window;

/**
 * window.requestIdleCallback()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback#browser_compatibility
 */
if (!window.requestIdleCallback) {
  window.requestIdleCallback = function (callback, options) {
    // eslint-disable-next-line no-var
    var opt = options || {};
    const relaxation = 1;
    const timeout = opt.timeout || relaxation;
    const start = performance.now();
    return setTimeout(function () {
      callback({
        get didTimeout() {
          return opt.timeout ? false : performance.now() - start - relaxation > timeout;
        },
        timeRemaining: function () {
          return Math.max(0, relaxation + (performance.now() - start));
        }
      });
    }, relaxation) as any;
  };
}

/**
 * window.cancelIdleCallback()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/cancelIdleCallback#browser_compatibility
 */
if (!window.cancelIdleCallback) {
  window.cancelIdleCallback = function (id) {
    clearTimeout(id);
  };
}

/**
 * window.requestAnimationFrame()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame#browser_compatibility
 */
if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = function (callback) {
    return window.setTimeout(function () {
      callback(Date.now());
    }, 1000 / 60);
  };
}

/**
 * window.cancelAnimationFrame()
 * version 0.0.0
 * Browser Compatibility:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/cancelAnimationFrame#browser_compatibility
 */
if (!window.cancelAnimationFrame) {
  window.cancelAnimationFrame = function (id) {
    clearTimeout(id);
  };
}
