import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import StripePadding from 'common/components/stripe/StripePadding';
import variables from 'common/config/variables';
import {
  ContentAlign,
  ContentAxisAlignMap,
  ContentScrollingContentAxis,
  ScrollDirectionPaddingMap
} from 'common/interfaces';
import { getClassName, getMouseMoveScrollHandler, getRemoteKeyName } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import smoothScroll from 'common/utils/smoothScroll';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './SimpleScrollView.styles';
import { SimpleScrollViewItemProps } from './SimpleScrollViewItem';
const { KEYPRESS_TIMEOUT } = variables;

export interface SimpleScrollViewProps {
  direction?: 'horizontal' | 'vertical';
  scrollAlign?: ContentAlign;
  focusedClassName?: string;
  className?: string;
  children?:
    | React.ReactElement<SimpleScrollViewItemProps>
    | Array<React.ReactElement<SimpleScrollViewItemProps>>;
  onBack?: (last?: boolean) => boolean;
}

function SimpleScrollView({
  direction = 'horizontal',
  scrollAlign = 'center',
  focusedClassName = 'focused',
  className,
  children,
  onBack
}: SimpleScrollViewProps) {
  const [forceFocusOnIndex, setForceFocusIndex] = useState(-1);
  const [lastFocusedChildIndex, setLastFocusedChildIndex] = useState(-1);
  const { ref, focusKey, hasFocusedChild } = useFocusable({
    trackChildren: true
  });

  const renderChildren = useCallback(() => {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null;
      }
      const onChildFocus = (
        layout: FocusableComponentLayout,
        data: object,
        details: FocusDetails
      ) => {
        const contentAxis = direction === 'horizontal' ? 'x' : 'y';
        //@ts-ignore
        smoothScroll({
          toElement: layout.node,
          scrollingElement: ref.current,
          duration: AnimationDurations.get('DEFAULT_ANIMATION_DURATION'),
          ...(scrollAlign ? { [ContentAxisAlignMap[contentAxis]]: scrollAlign } : undefined)
        });
        setLastFocusedChildIndex(index);
        // Reset force focus
        forceFocusOnIndex === index && setForceFocusIndex(-1);
        child.props.onFocus && child.props.onFocus(layout, data, details);
      };
      return React.cloneElement<SimpleScrollViewItemProps>(child, {
        ...child.props,
        onFocus: onChildFocus,
        initiallyFocused: index === forceFocusOnIndex
      });
    });
  }, [children, forceFocusOnIndex]);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = throttle((e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        const last = lastFocusedChildIndex === 0;
        const onBackResponse = onBack ? onBack(last) : true;
        if (lastFocusedChildIndex !== 0 && onBackResponse) {
          setForceFocusIndex(0);
          return;
        }
      }
    }, KEYPRESS_TIMEOUT);
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild, onBack, lastFocusedChildIndex]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={getClassName(`simple-scroll-view ${direction}`, {
          [focusedClassName]: hasFocusedChild,
          [className || '']: className
        })}
        onMouseMove={getMouseMoveScrollHandler(
          ref.current,
          direction === 'horizontal' ? 'x' : ('y' as ContentScrollingContentAxis)
        )}
      >
        {renderChildren()}
        <StripePadding
          padding={variables.SAFE_AREA_VIEW_PADDING}
          direction={ScrollDirectionPaddingMap[direction === 'horizontal' ? 'x' : 'y'] as string}
        />
      </div>
    </FocusContext.Provider>
  );
}

export default React.memo(SimpleScrollView);
