import SearchIcon from '@assets/media/side-menu-search-icon.png';
import { setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { clearSearch, search, SearchMode, SearchType, translationPacket } from 'common/actions';
import { Image } from 'common/components/image';
import { SEARCH_INPUT_TIMEOUT, SEARCH_MIN_INPUT } from 'common/config/variables/default';
import { SearchState } from 'common/reducers/searchState';
import { getCaretPosition, getClassName, getRemoteKeyName, setCaretPosition } from 'common/utils';
import firebase from 'common/utils/firebase';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

interface StateProps {
  // Redux injected
  i18n: typeof translationPacket;
  searchState: SearchState;
  search: (searchTerm: string, mode?: SearchMode, type?: SearchType) => Promise<any>;
  clearSearch: VoidFunction;
  //
  onFocusStateChange?: (state: boolean) => void;
  onFinishInput?: () => void;
}

type SearchPageBreadCrumbProps = StateProps;

function SearchPageBreadCrumb(props: SearchPageBreadCrumbProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchDebounce = useRef<DebouncedFunc<VoidFunction> | null>(null);
  const logSearchEventDebounce = useRef<DebouncedFunc<VoidFunction> | null>(null);
  const lgKeyboardVisible = useRef(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFinishInputRef = useRef(props.onFinishInput || (() => {}));

  const { focused, ref, focusSelf } = useFocusable({
    forceFocus: true,
    focusKey: 'SEARCH_BREADCRUMB',
    onFocus: () => {
      props.onFocusStateChange && props.onFocusStateChange(true);
      inputRef.current?.focus();
    },
    onBlur: () => {
      props.onFocusStateChange && props.onFocusStateChange(false);
      inputRef.current?.blur();
    },
    onArrowPress: (direction: string) => {
      const inputFieldRef = inputRef.current as HTMLInputElement;
      const caretPosition = getCaretPosition(inputFieldRef);
      if (direction === 'left' || (direction === 'right' && inputFieldRef.value)) {
        const caretPositionOffset = direction === 'left' ? -1 : 1;
        const position = caretPosition + caretPositionOffset;
        position >= 0 &&
          position <= inputFieldRef.value.length &&
          setCaretPosition(inputFieldRef, position);
        return false;
      }
      return true;
    }
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onFinishInputRef.current = props.onFinishInput || (() => {});
  }, [props.onFinishInput]);

  useEffect(() => {
    function keyboardVisibilityChange(event: any) {
      const visibility = event.detail.visibility;
      lgKeyboardVisible.current = !!visibility;
      if (!visibility) {
        onFinishInputRef.current();
      }
    }
    if (focused) {
      document.addEventListener('keyboardStateChange', keyboardVisibilityChange, false);
    }
    return () => {
      document.removeEventListener('keyboardStateChange', keyboardVisibilityChange, false);
    };
  }, [focused]);

  const noResults = useMemo(
    () => isEmpty(props.searchState.recorded),
    [props.searchState.recorded]
  );

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const keyName = getRemoteKeyName(e.keyCode);
      switch (keyName) {
        case 'BACK':
          noResults ? setFocus('MENU') : onFinishInputRef.current();
          break;
        case 'CANCEL':
          onFinishInputRef.current();
          break;
        default:
          break;
      }
    };
    if (focused) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focused, noResults]);

  useEffect(() => {
    if (!props.searchState.hasSearch && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [props.searchState.hasSearch]);

  const showHelperText = useMemo(
    () => !props.searchState.hasSearch && focused && isEmpty(props.searchState.recorded),
    [props.searchState.hasSearch, props.searchState.recorded, focused]
  );

  const handleSearchChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      searchDebounce.current && searchDebounce.current.cancel();
      searchDebounce.current = debounce(() => {
        const searchTerm = currentTarget.value;
        if (searchTerm.length === 0) {
          props.clearSearch();
        }
        if (searchTerm.length >= SEARCH_MIN_INPUT) {
          props.search(searchTerm);
          firebase.logUserSearch(searchTerm);
        }
      }, SEARCH_INPUT_TIMEOUT);
      searchDebounce.current();
    },
    [searchDebounce.current, logSearchEventDebounce.current]
  );

  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchTerm = (e.currentTarget[0] as HTMLInputElement).value;
    if (searchTerm.length === 0) {
      props.clearSearch();
    } else {
      await props.search(searchTerm);
      firebase.logUserSearch(searchTerm);
    }
    onFinishInputRef.current();
  }, []);

  if (isEmpty(props.i18n)) {
    return <></>;
  }

  return (
    <div className={getClassName('search-breadcrumb', { focused })} ref={ref}>
      <span className="search-title">{props.i18n?.search?.searchTitle}</span>
      <div className="linear-search-item-container">
        <Image src={SearchIcon} className="inner-search-icon" />
        <form onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            type="text"
            placeholder={props.i18n.search.placeholderShort}
            onChange={handleSearchChange}
            onFocus={focusSelf}
          />
        </form>
      </div>
      {showHelperText && (
        <span
          className={'helper-text'}
          dangerouslySetInnerHTML={{ __html: props.i18n.search.helperText }}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ i18n, searchState }: StateProps) => {
  return {
    i18n,
    searchState
  };
};
export default React.memo(
  connect(mapStateToProps, {
    search,
    clearSearch
  })(SearchPageBreadCrumb)
);
