class AbortHandler {
  private abortController: AbortController = new AbortController();

  getAbortController() {
    this.abortController = new AbortController();
    return this.abortController;
  }

  abort() {
    this.abortController?.abort();
  }

  get signal() {
    return this.getAbortController().signal;
  }
}

export default new AbortHandler();
