import { MetadataSeparator } from 'common/components/metadata-strip/MetadataStrip.styles';
import variables from 'common/config/variables';
import { LiveChannels } from 'common/constants/data-types';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import { getScalablePixel } from 'common/utils';
import { isEmpty, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { ChannelLogo, ChannelPositionContainer, ChannelPositionText } from './Controls.styles';

interface ChannelPositionProps {
  // Redux injected
  logos: any;
  liveChannels: LiveChannels;
  channelId: string;
  nextChannelId?: string;
  nextChannelPosition?: number;
  playerControlsState: PlayerControlsState;
  favoriteChannels: FavoriteChannels;
  explicitPosition?: number;
  //
  fastZapNumber?: number;
}

function ChannelPosition(props: ChannelPositionProps) {
  const fastZapping = useMemo(() => {
    return !isNil(props.fastZapNumber);
  }, [props.nextChannelId, props.fastZapNumber]);

  const currentChannelId = useMemo(
    () => props.nextChannelId || props.channelId,
    [props.channelId, props.nextChannelId]
  );
  const channelLogo = useMemo(() => {
    if (!currentChannelId) {
      return '';
    }

    return props.logos && props.logos[currentChannelId]?.png;
  }, [props.logos, currentChannelId]);

  const channelPosition = useMemo(() => {
    if (isEmpty(props.liveChannels)) {
      return null;
    }
    // Show fast zap number first
    if (!isNil(props.fastZapNumber)) return props.fastZapNumber;

    const favoritePosition = props.favoriteChannels[currentChannelId]?.position;
    const originalPosition = props.liveChannels[currentChannelId]?.position;

    // Show favorite position if channel is added to the list
    const explicitPosition = !isNil(props.nextChannelPosition)
      ? props.nextChannelPosition
      : // Show original position when reordering is enabled
      variables.REORDER_CHANNELS_ENABLED
      ? originalPosition
      : props.explicitPosition;

    if (!isNil(explicitPosition)) return explicitPosition;
    if (!isNil(favoritePosition)) return favoritePosition;

    return originalPosition;
  }, [
    props.liveChannels,
    currentChannelId,
    props.nextChannelPosition,
    props.fastZapNumber,
    props.explicitPosition,
    props.favoriteChannels
  ]);

  if (
    isEmpty(props.logos) ||
    isEmpty(props.liveChannels) ||
    isEmpty(props.channelId) ||
    (!fastZapping && props.playerControlsState === 'channels')
  ) {
    return <span />;
  }

  return (
    <ChannelPositionContainer>
      <ChannelPositionText>{channelPosition}</ChannelPositionText>
      {!fastZapping && <MetadataSeparator padding={8} size={getScalablePixel(32)} />}
      <ChannelLogo visible={!fastZapping} src={channelLogo} />
    </ChannelPositionContainer>
  );
}

const mapStateToProps = ({ liveChannels, logos, videoPlayer, favoriteChannels }: any) => ({
  channelId: videoPlayer.channelId,
  explicitPosition: videoPlayer?.video?.channelPosition,
  logos,
  liveChannels,
  nextChannelId: videoPlayer.nextVideo?.channelId,
  nextChannelPosition: videoPlayer.nextVideo?.position,
  playerControlsState: videoPlayer.playerControlsState,
  favoriteChannels
});

export default React.memo(connect(mapStateToProps, {})(ChannelPosition));
