import Event from '../events';
import { ErrorTypes, ErrorDetails } from '../errors';

import { logger } from '../utils/logger';

function b64ToUint6 (nChr) {
  return nChr > 64 && nChr < 91
    ? nChr - 65 : nChr > 96 && nChr < 123
      ? nChr - 71 : nChr > 47 && nChr < 58
        ? nChr + 4 : nChr === 43
          ? 62 : nChr === 47
            ? 63 : 0;
}

function base64DecToArr (sBase64, nBlockSize) {
  let sB64Enc = sBase64; // sBase64.replace(/[^A-Za-z0-9\+\/]/g, '');
  let nInLen = sB64Enc.length;
  let nOutLen = nBlockSize ? Math.ceil((nInLen * 3 + 1 >>> 2) / nBlockSize) * nBlockSize : nInLen * 3 + 1 >>> 2;
  let aBytes = new Uint8Array(nOutLen);
  let nMod3;
  let nMod4;
  let nUint24;
  let nOutIdx;
  let nInIdx;

  // logger.log(`base64DecToArr: enter: nInLen = ${nInLen}`);

  for (nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        aBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
      }
      nUint24 = 0;
    }
  }

  // logger.log(`base64DecToArr: exit: aBytes.length = ${aBytes.length}`);

  return aBytes;
}

function uint32ToRGBA (value) {
  let r = (value >> 8) & 0xff;
  let g = (value >> 16) & 0xff;
  let b = (value >> 24) & 0xff;
  let a = value & 0xff;
  return `rgba(${r}, ${g}, ${b}, ${(a / 255).toFixed(1)})`;
}

class DisplayDefinition {
  constructor (width, height, windowHPosMin, windowHPosMax, windowVPosMin, windowVPosMax) {
    this.width = width;
    this.height = height;
    this.windowHPosMin = windowHPosMin;
    this.windowHPosMax = windowHPosMax;
    this.windowVPosMin = windowVPosMin;
    this.windowVPosMax = windowVPosMax;
  }
}

class SubtitleService {
  constructor (subtitlePageId, ancilaryPageId, languageCode) {
    this.subtitlePageId = subtitlePageId;
    this.ancillaryPageId = ancilaryPageId;
    this.regions = new Map();
    this.cluts = new Map();
    this.objects = new Map();
    this.ancillaryCluts = new Map();
    this.ancillaryObjects = new Map();
    this.displayDefinition = null;
    this.pageComposition = null;
    this.languageCode = languageCode;
  }

  reset () {
    this.regions.clear();
    this.cluts.clear();
    this.objects.clear();
    this.ancillaryCluts.clear();
    this.ancillaryObjects.clear();
    this.displayDefinition = null;
    this.pageComposition = null;
  }
}

/**
 *  The page is the definition and arrangement of regions in the screen.
 *  <p>
 *  See ETSI EN 300 743 7.2.2
 */
class PageComposition {
  constructor (timeout, version, state, regions) {
    this.timeout = timeout;
    this.version = version;
    this.state = state;
    this.regions = regions;
  }
}

/**
 * A region within a {@link PageComposition}.
 * <p>
 * See ETSI EN 300 743 7.2.2
 */
class PageRegion {
  constructor (hAddress, vAddress) {
    this.hAddress = hAddress;
    this.vAddress = vAddress;
  }
}

/**
 * An area of the page composed of a list of objects and a CLUT.
 * <p>
 * See ETSI EN 300 743 7.2.3
 */
class RegionComposition {
  constructor (id, fillFlag, width, height, levelOfCompatibility, depth,
    clutId, pixelCode8Bit, pixelCode4Bit, pixelCode2Bit, regionObjects) {
    this.id = id;
    this.fillFlag = fillFlag;
    this.width = width;
    this.height = height;
    this.levelOfCompatibility = levelOfCompatibility;
    this.depth = depth;
    this.clutId = clutId;
    this.pixelCode8Bit = pixelCode8Bit;
    this.pixelCode4Bit = pixelCode4Bit;
    this.pixelCode2Bit = pixelCode2Bit;
    this.regionObjects = regionObjects;
  }

  mergeFrom (otherRegionComposition) {
    if (otherRegionComposition == null) {
      return;
    }

    let iter = otherRegionComposition.regionObjects.entries();
    for (let i of iter) {
      this.regionObjects.set(i[0], i[1]);
    }
  }
}

/**
 * An object within a {@link RegionComposition}.
 * <p>
 * See ETSI EN 300 743 7.2.3
 */
class RegionObject {
  constructor (type, provider, hPosition, vPosition, fgPixelCode, bgPixelCode) {
    this.type = type;
    this.provider = provider;
    this.hPosition = hPosition;
    this.vPosition = vPosition;
    this.fgPixelCode = fgPixelCode;
    this.bgPixelCode = bgPixelCode;
  }
}

/**
 * CLUT family definition containing the color tables for the three bit depths defined
 * <p>
 * See ETSI EN 300 743 7.2.4
 */
class ClutDefinition {
  constructor (id, clutEntries2Bit, clutEntries4Bit, clutEntries8bit) {
    this.id = id;
    this.clutEntries2Bit = clutEntries2Bit;
    this.clutEntries4Bit = clutEntries4Bit;
    this.clutEntries8Bit = clutEntries8bit;
  }
}

/**
 * The textual or graphical representation of an object.
 * <p>
 * See ETSI EN 300 743 7.2.5
 */
class ObjectData {
  constructor (id, nonModifyingColorFlag, topFieldData, bottomFieldData) {
    this.id = id;
    this.nonModifyingColorFlag = nonModifyingColorFlag;
    this.topFieldData = topFieldData;
    this.bottomFieldData = bottomFieldData;
  }
}

class PixelData {
  constructor (data) {
    this.data = data;
    this.offset = 0;
  }
}

class DvbSubParser {
  constructor (compositionPageId, ancilaryPageId, languageCode) {
    this.service = new SubtitleService(compositionPageId, ancilaryPageId, languageCode);
    this.defaultDisplayDefinition = new DisplayDefinition(719, 575, 0, 719, 0, 575);
    this.defaultClutDefinition = new ClutDefinition(0,
      this._generateDefault2BitClutEntries(),
      this._generateDefault4BitClutEntries(),
      this._generateDefault8BitClutEntries());
    this.srcCanvas = document.createElement('canvas');
    this.srcCanvasContext = this.srcCanvas.getContext('2d');
    this.dstCanvas = document.createElement('canvas');
    this.dstCanvasContext = this.dstCanvas.getContext('2d');
  }

  reset () {
    const { srcCanvas } = this;
    this.service.reset();
    this.srcCanvasContext.clearRect(0, 0, srcCanvas.width, srcCanvas.height);
  }

  parse (sample) {
    let data = sample.bytes;
    let length = data.length;
    let offset = 0;
    let pts = sample.pts;
    let reason;
    let service = this.service;
    let displayDefinition;
    const srcCanvas = this.srcCanvas;
    const srcCanvasContext = this.srcCanvasContext;
    const dstCanvas = this.dstCanvas;
    const dstCanvasContext = this.dstCanvasContext;

    // logger.log(`_parseDVBSUBPES: enter`);

    if (data[offset] !== 0x20) {
      reason = 'PES_data_field: data_identifier != 0x20';
      this.observer.trigger(Event.ERROR, {
        type: ErrorTypes.MEDIA_ERROR,
        details: ErrorDetails.FRAG_PARSING_ERROR,
        fatal: true,
        reason: reason
      });
      return null;
    }

    offset++;

    if (data[offset] !== 0x00) {
      reason = 'PES_data_field: subtitle_stream_id != 0x00';
      this.observer.trigger(Event.ERROR, {
        type: ErrorTypes.MEDIA_ERROR,
        details: ErrorDetails.FRAG_PARSING_ERROR,
        fatal: true,
        reason: reason
      });
      return null;
    }

    offset++;

    while ((length - offset) > 6) {
      if (data[offset] === 0x0F) {
        offset += this._parseSubtitlingSegment(data, offset, length, service);
      } else {
        offset++;
      }
    }

    if (service.pageComposition == null) {
      return null;
    }

    let regions = service.pageComposition.regions;
    if (regions.size <= 0) {
      return { pts: pts };
    }

    if (service.displayDefinition != null) {
      displayDefinition = service.displayDefinition;
    } else {
      displayDefinition = this.defaultDisplayDefinition;
    }

    if (((displayDefinition.width + 1) !== srcCanvas.width) ||
      ((displayDefinition.height + 1) !== srcCanvas.height)) {
      srcCanvas.width = displayDefinition.width + 1;
      srcCanvas.height = displayDefinition.height + 1;
    }

    // Screen coordinates of the upper-left and lower-right corners of the
    // page instance. The bottom-right coordinates are exclusive. In other
    // words, the pixel at (pageRight, pageBottom) lies immediately outside
    // the rectangle.
    let pageX = displayDefinition.windowHPosMax;
    let pageY = displayDefinition.windowVPosMax;
    let pageRight = 0;
    let pageBottom = 0;

    // Save the canvas state
    srcCanvasContext.save();

    // Clear the canvas sub-paths list
    srcCanvasContext.beginPath();

    // Add recatangular regions to the canvas clip path
    regions.forEach((pageRegion, regionId) => {
      let regionComposition = service.regions.get(regionId);
      let baseHAddress = pageRegion.hAddress + displayDefinition.windowHPosMin;
      let baseVAddress = pageRegion.vAddress + displayDefinition.windowVPosMin;

      /*
      logger.log(`pageRegion = ${regionId}:` +
                 ` baseHAddress = ${baseHAddress}` +
                 `, baseVAddress = ${baseVAddress}`);
      */

      let clipRight = Math.min(baseHAddress + regionComposition.width,
        displayDefinition.windowHPosMax + 1);
      let clipBottom = Math.min(baseVAddress + regionComposition.height,
        displayDefinition.windowVPosMax + 1);
      srcCanvasContext.rect(baseHAddress, baseVAddress,
        clipRight - baseHAddress, clipBottom - baseVAddress);

      pageX = Math.min(pageX, baseHAddress);
      pageY = Math.min(pageY, baseVAddress);
      pageRight = Math.max(pageRight, clipRight);
      pageBottom = Math.max(pageBottom, clipBottom);

      /*
      logger.log(`pageX = ${pageX}:` +
        ` pageY = ${pageY}` +
        `, pageRight = ${pageRight}` +
        `, pageBottom = ${pageBottom}`);
      */
    });

    // Clip drawing to the canvas clip path
    srcCanvasContext.clip();

    regions.forEach((pageRegion, regionId) => {
      let regionComposition = service.regions.get(regionId);
      let baseHAddress = pageRegion.hAddress + displayDefinition.windowHPosMin;
      let baseVAddress = pageRegion.vAddress + displayDefinition.windowVPosMin;

      let clutDefinition = service.cluts.get(regionComposition.clutId);
      if (clutDefinition == null) {
        clutDefinition = service.ancillaryCluts.get(regionComposition.clutId);
        if (clutDefinition == null) {
          clutDefinition = this.defaultClutDefinition;
        }
      }

      regionComposition.regionObjects.forEach((regionObject, objectId) => {
        let objectData = service.objects.get(objectId);
        if (objectData == null) {
          objectData = service.ancillaryObjects.get(objectId);
        }
        if (objectData != null) {
          // logger.log(`_paintPixelDataSubBlocks: objectId = ${objectId}`)
          // Paint paint = objectData.nonModifyingColorFlag ? null : defaultPaint;
          this._paintPixelDataSubBlocks(objectData, clutDefinition, regionComposition.depth,
            baseHAddress + regionObject.hPosition,
            baseVAddress + regionObject.vPosition);
        }
      });

      if (regionComposition.fillFlag) {
        let color;
        if (regionComposition.depth === DvbSubParser.REGION_DEPTH_8_BIT) {
          color = clutDefinition.clutEntries8Bit[regionComposition.pixelCode8Bit];
        } else if (regionComposition.depth === DvbSubParser.REGION_DEPTH_4_BIT) {
          color = clutDefinition.clutEntries4Bit[regionComposition.pixelCode4Bit];
        } else {
          color = clutDefinition.clutEntries2Bit[regionComposition.pixelCode2Bit];
        }

        /*
        logger.log(`regionComposition.fillFlag = ${regionComposition.fillFlag}:` +
                   `, ${baseHAddress}` +
                   `, ${baseVAddress}` +
                   `, ${regionComposition.width}` +
                   `, ${regionComposition.height}` +
                   `, color = #${color.toString(16).padStart(8, '0')}`);
        */

        let copmpositingMode = srcCanvasContext.globalCompositeOperation;
        srcCanvasContext.globalCompositeOperation = 'destination-over';
        srcCanvasContext.fillStyle = `#${color.toString(16).padStart(8, '0')}`;
        srcCanvasContext.fillRect(baseHAddress, baseVAddress,
          regionComposition.width, regionComposition.height);
        srcCanvasContext.globalCompositeOperation = copmpositingMode;
      }
    });

    // Restore the canvas state
    srcCanvasContext.restore();

    const dstWidth = pageRight - pageX;
    const dstHeight = pageBottom - pageY;
    const dstImageData = srcCanvasContext.getImageData(pageX, pageY,
      dstWidth, dstHeight);
    dstCanvas.width = dstWidth;
    dstCanvas.height = dstHeight;
    dstCanvasContext.putImageData(dstImageData, 0, 0);

    const dataURL = dstCanvas.toDataURL('image/png', 1);
    const imageData = base64DecToArr(dataURL.substring(dataURL.indexOf(',') + 1));

    let cue = {
      pts: pts,
      image: (URL || webkitURL).createObjectURL(new Blob([imageData], { type: 'image/png' })),
      left: pageX / displayDefinition.width,
      top: pageY / displayDefinition.height,
      // The bottom-right coordinates are exclusive
      right: pageRight / (displayDefinition.width + 1),
      bottom: pageBottom / (displayDefinition.height + 1),
      width: dstWidth / (displayDefinition.width + 1),
      height: dstHeight / (displayDefinition.height + 1),
      dar: (displayDefinition.width + 1) / (displayDefinition.height + 1),
      timeout: service.pageComposition.timeout
    };

    srcCanvasContext.clearRect(0, 0, srcCanvas.width, srcCanvas.height);
    dstCanvasContext.clearRect(0, 0, dstWidth, dstHeight);

    return cue;
  }

  _parseSubtitlingSegment (data, offset, length, service) {
    let segmentStart = offset;

    // Sync byte
    if (data[offset] !== 0x0f) {
      return 1;
    }

    // logger.log(`Subtitling_segment: found sync byte at offset ${offset}`);
    offset++;

    let segmentType = data[offset + 0];
    offset += 1;
    let pageId = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;
    let segmentLength = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;
    // logger.log(`Subtitling_segment: segmentType = ${segmentType}, pageId = ${pageId}, segmentLength = ${segmentLength}`);

    let limit = length - offset;
    if (segmentLength > limit) {
      logger.log(`Subtitling_segment: segment_length exceeds limit: ${segmentLength}:${limit}`);
      return limit;
    }

    switch (segmentType) {
    case DvbSubParser.SEGMENT_TYPE_DISPLAY_DEFINITION: {
      if (pageId === service.subtitlePageId) {
        service.displayDefinition = this._parseDisplayDefinition(data, offset, segmentLength);
      }
      break;
    }
    case DvbSubParser.SEGMENT_TYPE_PAGE_COMPOSITION: {
      if (pageId === service.subtitlePageId) {
        let currentPageComposition = service.pageComposition;
        let pageComposition = this._parsePageComposition(data, offset, segmentLength);

        if (pageComposition.state !== 0) {
          service.pageComposition = pageComposition;
          service.regions.clear();
          service.cluts.clear();
          service.objects.clear();
        } else if ((currentPageComposition != null) && (currentPageComposition.version !== pageComposition.version)) {
          service.pageComposition = pageComposition;
        }
      }
      break;
    }
    case DvbSubParser.SEGMENT_TYPE_REGION_COMPOSITION: {
      let regionComposition;
      let pageComposition = service.pageComposition;

      if ((pageId === service.subtitlePageId) && (pageComposition != null)) {
        regionComposition = this._parseRegionComposition(data, offset, segmentLength);
        if (pageComposition.state === 0) {
          regionComposition.mergeFrom(service.regions.get(regionComposition.id));
        }
        service.regions.set(regionComposition.id, regionComposition);
      }
      break;
    }
    case DvbSubParser.SEGMENT_TYPE_CLUT_DEFINITION: {
      if (pageId === service.subtitlePageId) {
        let clutDefinition = this._parseClutDefinition(data, offset, segmentLength);
        service.cluts.set(clutDefinition.id, clutDefinition);
      } else if (pageId === service.ancillaryPageId) {
        let clutDefinition = this._parseClutDefinition(data, offset, segmentLength);
        service.ancillaryCluts.set(clutDefinition.id, clutDefinition);
      }
      break;
    }
    case DvbSubParser.SEGMENT_TYPE_OBJECT_DATA: {
      if (pageId === service.subtitlePageId) {
        let objectData = this._parseObjectData(data, offset, segmentLength);
        service.objects.set(objectData.id, objectData);
      } else if (pageId === service.ancillaryPageId) {
        let objectData = this._parseObjectData(data, offset, segmentLength);
        service.objects.set(objectData.id, objectData);
      }
      break;
    }
    default: {
      break;
    }
    }

    offset += segmentLength;

    return (offset - segmentStart);
  }

  /**
   * Parses a display definition segment, as defined by ETSI EN 300 743 7.2.1.
   */
  _parseDisplayDefinition (data, offset, length) {
    let displayWindowHPosMin;
    let displayWindowHPosMax;
    let displayWindowVPosMin;
    let displayWindowVPosMax;

    let flagDisplayWindow = (data[offset] & 0x08) >> 3;
    offset += 1;
    let width = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;
    let height = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;

    if (flagDisplayWindow) {
      displayWindowHPosMin = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
      displayWindowHPosMax = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
      displayWindowVPosMin = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
      displayWindowVPosMax = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
    } else {
      displayWindowHPosMin = 0;
      displayWindowHPosMax = width;
      displayWindowVPosMin = 0;
      displayWindowVPosMax = height;
    }

    /*
    logger.log(`Display_definition_segment: flagDisplayWindow = ${flagDisplayWindow}` +
               `, width = ${width}` +
               `, height = ${height}` +
               `, displayWindowHPosMin = ${displayWindowHPosMin}` +
               `, displayWindowHPosMax = ${displayWindowHPosMax}` +
               `, displayWindowVPosMin = ${displayWindowVPosMin}` +
               `, displayWindowVPosMax = ${displayWindowVPosMax}`);
    */

    return new DisplayDefinition(width, height,
      displayWindowHPosMin, displayWindowHPosMax,
      displayWindowVPosMin, displayWindowVPosMax);
  }

  /**
   * Parses a page composition segment, as defined by ETSI EN 300 743 7.2.2.
   */
  _parsePageComposition (data, offset, length) {
    let regionId;
    let regionHAddress;
    let regionVAddress;

    let dataStart = offset;
    let dataEnd = dataStart + length;

    let timeout = data[offset];
    offset += 1;
    let versionNumber = (data[offset] & 0xF0) >> 4;
    let state = (data[offset] & 0x0C) >> 2;
    offset += 1;

    /*
    logger.log(`Page_composition_segment: timeout = ${timeout}` +
               `, versionNumber = ${versionNumber}` +
               `, state = ${state}`);
    */

    let regions = new Map();
    while (offset < dataEnd) {
      regionId = data[offset];
      offset += 1;
      // Skip reserved byte
      offset += 1;
      regionHAddress = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
      regionVAddress = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;

      /*
      logger.log(`Page_composition_segment: regionId = ${regionId}` +
                 `, regionHAddress = ${regionHAddress}` +
                 `, regionVAddress = ${regionVAddress}`);
      */

      regions.set(regionId, new PageRegion(regionHAddress, regionVAddress));
    }

    return new PageComposition(timeout, versionNumber, state, regions);
  }

  /**
   * Parses a region composition segment, as defined by ETSI EN 300 743 7.2.3.
   */
  _parseRegionComposition (data, offset, length) {
    let objectId;
    let objectType;
    let objectProviderFlag;
    let objectHPosition;
    let objectVPosition;
    let fgPixelCode;
    let bgPixelCode;

    let dataStart = offset;
    let dataEnd = dataStart + length;

    let id = data[offset];
    offset += 1;
    let versionNumber = (data[offset] & 0xF0) >> 4;
    let fillFlag = (data[offset] & 0x08) >> 3;
    offset += 1;
    let width = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;
    let height = (data[offset + 0] << 8) + data[offset + 1];
    offset += 2;
    let levelOfCompatibility = (data[offset] & 0xE0) >> 5;
    let depth = (data[offset] & 0x1C) >> 2;
    offset += 1;
    let clutId = data[offset];
    offset += 1;
    let pixelCode8Bit = data[offset];
    offset += 1;
    let pixelCode4Bit = (data[offset] & 0xF0) >> 4;
    let pixelCode2Bit = (data[offset] & 0x0F) >> 2;
    offset += 1;

    /*
    logger.log(`Region_composition_segment: id = ${id}` +
               `, versionNumber = ${versionNumber}` +
               `, fillFlag = ${fillFlag}` +
               `, width = ${width}` +
               `, height = ${height}` +
               `, levelOfCompatibility = ${levelOfCompatibility}` +
               `, depth = ${depth}` +
               `, clutId = ${clutId}` +
               `, pixelCode8Bit = ${pixelCode8Bit}` +
               `, pixelCode4Bit = ${pixelCode4Bit}` +
               `, pixelCode2Bit = ${pixelCode2Bit}`);

    */

    let regionObject;
    let regionObjects = new Map();
    while (offset < dataEnd) {
      objectId = (data[offset + 0] << 8) + data[offset + 1];
      offset += 2;
      objectType = (data[offset] & 0xC0) >> 6;
      objectProviderFlag = (data[offset] & 0x30) >> 4;
      objectHPosition = ((data[offset] & 0x0F) << 8) + data[offset + 1];
      offset += 2;
      objectVPosition = ((data[offset] & 0x0F) << 8) + data[offset + 1];
      offset += 2;
      fgPixelCode = 0;
      bgPixelCode = 0;
      if ((objectType === 1) || (objectType === 2)) {
        fgPixelCode = data[offset];
        offset += 1;
        bgPixelCode = data[offset];
        offset += 1;
      }
      /*
      logger.log(`Region_composition_segment: objectId = ${objectId}` +
                 `, objectType = ${objectType}` +
                 `, objectProviderFlag = ${objectProviderFlag}` +
                 `, objectHPosition = ${objectHPosition}` +
                 `, objectVPosition = ${objectVPosition}` +
                 `, fgPixelCode = ${fgPixelCode}` +
                 `, bgPixelCode = ${bgPixelCode}`);
      */

      regionObject = new RegionObject(
        objectType, objectProviderFlag, objectHPosition, objectVPosition,
        fgPixelCode, bgPixelCode);
      regionObjects.set(objectId, regionObject);
    }

    return new RegionComposition(
      id, fillFlag, width, height, levelOfCompatibility, depth, clutId,
      pixelCode8Bit, pixelCode4Bit, pixelCode2Bit, regionObjects);
  }

  /**
   * Parses a CLUT definition segment, as defined by ETSI EN 300 743 7.2.4.
   */
  _parseClutDefinition (data, offset, length) {
    let clutEntryId;
    let flag8BitClutEntry;
    let flag4BitClutEntry;
    let flag2BitClutEntry;
    let flagFullRange;
    let y;
    let cr;
    let cb;
    let t;
    let a;
    let r;
    let g;
    let b;
    let clutEntries;

    let dataStart = offset;
    let dataEnd = dataStart + length;

    let clutId = data[offset];
    offset += 1;
    let versionNumber = (data[offset] & 0xF0) >> 4;
    offset += 1;

    // logger.log(`CLUT_definition_segment: clutId = ${clutId}, versionNumber = ${versionNumber}`)

    let clutEntries2Bit = this._generateDefault2BitClutEntries();
    let clutEntries4Bit = this._generateDefault4BitClutEntries();
    let clutEntries8Bit = this._generateDefault8BitClutEntries();

    while (offset < dataEnd) {
      clutEntryId = data[offset];
      offset += 1;
      flag2BitClutEntry = data[offset] >> 7;
      flag4BitClutEntry = (data[offset] & 0x40) >> 6;
      flag8BitClutEntry = (data[offset] & 0x20) >> 5;
      flagFullRange = data[offset] & 0x01;
      offset += 1;

      if (flag2BitClutEntry) {
        clutEntries = clutEntries2Bit;
      } else if (flag4BitClutEntry) {
        clutEntries = clutEntries4Bit;
      } else {
        clutEntries = clutEntries8Bit;
      }

      if (flagFullRange) {
        y = data[offset];
        offset += 1;
        cr = data[offset];
        offset += 1;
        cb = data[offset];
        offset += 1;
        t = data[offset];
        offset += 1;
      } else {
        y = (data[offset] & 0xFC) >> 2;
        cr = ((data[offset + 0] & 0x03) << 2) + ((data[offset + 1] & 0xC0) >> 6);
        cb = (data[offset + 1] & 0x3C) >> 2;
        t = data[offset] & 0x03;
        offset += 2;
      }

      /*
      logger.log(`CLUT_definition_segment: clutEntryId = ${clutEntryId}` +
                 `, flag8BitClutEntry = ${flag8BitClutEntry}` +
                 `, flag4BitClutEntry = ${flag4BitClutEntry}` +
                 `, flag2BitClutEntry = ${flag2BitClutEntry}` +
                 `, flagFullRange = ${flagFullRange}` +
                 `, y = ${y}` +
                 `, y = ${cr}` +
                 `, y = ${cb}` +
                 `, y = ${t}`);
      */

      if (y === 0) {
        cr = 0x00;
        cb = 0x00;
        t = 0xFF;
      }

      a = (0xFF - (t & 0xFF));
      r = (y + (1.40200 * (cr - 128)));
      g = (y - (0.34414 * (cb - 128)) - (0.71414 * (cr - 128)));
      b = (y + (1.77200 * (cb - 128)));

      clutEntries[clutEntryId] = this._getColor(a,
        Math.max(0, Math.min(r, 255)),
        Math.max(0, Math.min(g, 255)),
        Math.max(0, Math.min(b, 255))
      );
    }

    return new ClutDefinition(clutId, clutEntries2Bit, clutEntries4Bit,
      clutEntries8Bit);
  }

  /**
   * Parses an object data segment, as defined by ETSI EN 300 743 7.2.5.
   */
  _parseObjectData (data, offset, length) {
    let topFieldDataBlockLength;
    let bottomFieldDataBlockLength;
    let topFieldData;
    let bottomFieldData;

    let id = (data[offset] << 8) + data[offset + 1];
    offset += 2;
    let versionNumber = (data[offset] & 0xF0) >> 4;
    let codingMethod = (data[offset] & 0x0C) >> 2;
    let flagNonModifyingColor = (data[offset] & 0x02) >> 1;
    offset += 1;

    /*
    logger.log(`Object_data_segment: id = ${id}` +
               `, versionNumber = ${versionNumber}` +
               `, codingMethod = ${codingMethod}` +
               `, flagNonModifyingColor = ${flagNonModifyingColor}`);
    */

    if (codingMethod === 0) {
      topFieldDataBlockLength = (data[offset] << 8) + data[offset + 1];
      offset += 2;
      bottomFieldDataBlockLength = (data[offset] << 8) + data[offset + 1];
      offset += 2;

      topFieldData = data.subarray(offset, offset + topFieldDataBlockLength);
      offset += topFieldDataBlockLength;
      bottomFieldData = data.subarray(offset, offset + bottomFieldDataBlockLength);
      offset += bottomFieldDataBlockLength;

      /*
      logger.log(`Object_data_segment: topFieldDataBlockLength = ${topFieldDataBlockLength}` +
                 `, bottomFieldDataBlockLength = ${bottomFieldDataBlockLength}`);
      */
    } else if (codingMethod === 1) {
      let numberOfCodes = data[offset];
      offset += 1;
      // Skip the charcter codes
      offset += numberOfCodes * 2;

      // logger.log(`Object_data_segment: numberOfCodes = ${numberOfCodes}`);
    }

    return new ObjectData(id, flagNonModifyingColor, topFieldData, bottomFieldData);
  }

  _generateDefault2BitClutEntries () {
    let entries = new Uint32Array([0x00000000, 0xFFFFFFFF, 0xFF000000, 0xFF7F7F7F]);
    return entries;
  }

  _generateDefault4BitClutEntries () {
    let color;

    let entries = new Uint32Array(16);
    entries[0] = 0x00000000;
    for (let i = 1; i < 16; i++) {
      if (i < 8) {
        color = this._getColor(0xFF,
          ((i & 0x01) !== 0 ? 0xFF : 0x00),
          ((i & 0x02) !== 0 ? 0xFF : 0x00),
          ((i & 0x04) !== 0 ? 0xFF : 0x00));
      } else {
        color = this._getColor(0xFF,
          ((i & 0x01) !== 0 ? 0x7F : 0x00),
          ((i & 0x02) !== 0 ? 0x7F : 0x00),
          ((i & 0x04) !== 0 ? 0x7F : 0x00));
      }
      entries[i] = color;
    }
    return entries;
  }

  _generateDefault8BitClutEntries () {
    let color;

    let entries = new Uint32Array(256);
    entries[0] = 0x00000000;
    for (let i = 1; i < 256; i++) {
      if (i < 8) {
        color = this._getColor(0x3F,
          ((i & 0x01) !== 0 ? 0xFF : 0x00),
          ((i & 0x02) !== 0 ? 0xFF : 0x00),
          ((i & 0x04) !== 0 ? 0xFF : 0x00));
        entries[0] = color;
      } else {
        switch (i & 0x88) {
        case 0x00:
          color = this._getColor(0xFF,
            (((i & 0x01) !== 0 ? 0x55 : 0x00) + ((i & 0x10) !== 0 ? 0xAA : 0x00)),
            (((i & 0x02) !== 0 ? 0x55 : 0x00) + ((i & 0x20) !== 0 ? 0xAA : 0x00)),
            (((i & 0x04) !== 0 ? 0x55 : 0x00) + ((i & 0x40) !== 0 ? 0xAA : 0x00)));
          entries[0] = color;
          break;
        case 0x08:
          color = this._getColor(0x7F,
            (((i & 0x01) !== 0 ? 0x55 : 0x00) + ((i & 0x10) !== 0 ? 0xAA : 0x00)),
            (((i & 0x02) !== 0 ? 0x55 : 0x00) + ((i & 0x20) !== 0 ? 0xAA : 0x00)),
            (((i & 0x04) !== 0 ? 0x55 : 0x00) + ((i & 0x40) !== 0 ? 0xAA : 0x00)));
          entries[0] = color;
          break;
        case 0x80:
          color = this._getColor(0xFF,
            (127 + ((i & 0x01) !== 0 ? 0x2B : 0x00) + ((i & 0x10) !== 0 ? 0x55 : 0x00)),
            (127 + ((i & 0x02) !== 0 ? 0x2B : 0x00) + ((i & 0x20) !== 0 ? 0x55 : 0x00)),
            (127 + ((i & 0x04) !== 0 ? 0x2B : 0x00) + ((i & 0x40) !== 0 ? 0x55 : 0x00)));
          entries[0] = color;
          break;
        case 0x88:
          color = this._getColor(0xFF,
            (((i & 0x01) !== 0 ? 0x2B : 0x00) + ((i & 0x10) !== 0 ? 0x55 : 0x00)),
            (((i & 0x02) !== 0 ? 0x2B : 0x00) + ((i & 0x20) !== 0 ? 0x55 : 0x00)),
            (((i & 0x04) !== 0 ? 0x2B : 0x00) + ((i & 0x40) !== 0 ? 0x55 : 0x00)));
          entries[0] = color;
          break;
        }
      }
    }
    return entries;
  }

  _getColor (a, r, g, b) {
    return (r << 24) | (g << 16) | (b << 8) | a;
  }

  /**
   * Draws a pixel data sub-block, as defined by ETSI EN 300 743 7.2.5.1, into a canvas.
   */
  _paintPixelDataSubBlocks (objectData, clutDefinition, regionDepth,
    hAddress, vAddress) {
    let clutEntries;

    if (regionDepth === DvbSubParser.REGION_DEPTH_8_BIT) {
      clutEntries = clutDefinition.clutEntries8Bit;
    } else if (regionDepth === DvbSubParser.REGION_DEPTH_4_BIT) {
      clutEntries = clutDefinition.clutEntries4Bit;
    } else {
      clutEntries = clutDefinition.clutEntries2Bit;
    }
    // logger.log(`_paintPixelDataSubBlocks: topFieldData.length = ${objectData.topFieldData.length}`);
    this._paintPixelDataSubBlock(objectData.topFieldData, clutEntries, regionDepth,
      hAddress, vAddress);
    // logger.log(`_paintPixelDataSubBlocks: bottomFieldData.length = ${objectData.bottomFieldData.length}`);
    this._paintPixelDataSubBlock(objectData.bottomFieldData, clutEntries, regionDepth,
      hAddress, vAddress + 1);
  }

  /**
   * Draws a pixel data sub-block, as defined by ETSI EN 300 743 7.2.5.1, into a canvas.
   */
  _paintPixelDataSubBlock (data, clutEntries, regionDepth,
    hAddress, vAddress) {
    let column = hAddress;
    let line = vAddress;
    let clutMapTable2To4 = null;
    let clutMapTable2To8 = null;
    let clutMapTable4To8 = null;
    let pixelData = new PixelData(data);

    let dataType;
    while (pixelData.offset < pixelData.data.length) {
      dataType = pixelData.data[pixelData.offset];
      pixelData.offset += 1;
      switch (dataType) {
      case DvbSubParser.DATA_TYPE_2BP_CODE_STRING: {
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_2BP_CODE_STRING');
        let clutMapTable2ToX;
        if (regionDepth === DvbSubParser.REGION_DEPTH_8_BIT) {
          clutMapTable2ToX = clutMapTable2To8 == null ? DvbSubParser.defaultMap2To8 : clutMapTable2To8;
        } else if (regionDepth === DvbSubParser.REGION_DEPTH_4_BIT) {
          clutMapTable2ToX = clutMapTable2To4 == null ? DvbSubParser.defaultMap2To4 : clutMapTable2To4;
        } else {
          clutMapTable2ToX = null;
        }
        column = this._paint2BitPixelCodeString(pixelData, clutEntries, clutMapTable2ToX,
          column, line);
        // data.byteAlign();
        break;
      }
      case DvbSubParser.DATA_TYPE_4BP_CODE_STRING: {
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_4BP_CODE_STRING');
        let clutMapTable4ToX;
        if (regionDepth === DvbSubParser.REGION_DEPTH_8_BIT) {
          clutMapTable4ToX = clutMapTable4To8 == null ? DvbSubParser.defaultMap4To8 : clutMapTable4To8;
        } else {
          clutMapTable4ToX = null;
        }
        column = this._paint4BitPixelCodeString(pixelData, clutEntries, clutMapTable4ToX, column, line);
        break;
      }
      case DvbSubParser.DATA_TYPE_8BP_CODE_STRING:
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_8BP_CODE_STRING');
        column = this._paint8BitPixelCodeString(pixelData, clutEntries, null, column, line);
        break;
      case DvbSubParser.DATA_TYPE_24_TABLE_DATA:
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_24_TABLE_DATA');
        clutMapTable2To4 = this._buildClutMapTable(4, 4, pixelData);
        break;
      case DvbSubParser.DATA_TYPE_28_TABLE_DATA:
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_28_TABLE_DATA');
        clutMapTable2To8 = this._buildClutMapTable(4, 8, pixelData);
        break;
      case DvbSubParser.DATA_TYPE_48_TABLE_DATA:
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_48_TABLE_DATA');
        clutMapTable2To8 = this._buildClutMapTable(16, 8, pixelData);
        break;
      case DvbSubParser.DATA_TYPE_END_LINE:
        // logger.log('_paintPixelDataSubBlock: DATA_TYPE_END_LINE');
        column = hAddress;
        line += 2;
        break;
      default:
        // logger.log(`_paintPixelDataSubBlock: dataType = ${dataType}`);
        // Do nothing.
        break;
      }
    }
  }

  /**
   * Paint a 2-bit/pixel code string, as defined by ETSI EN 300 743 7.2.5.2, to a canvas.
   */
  _paint2BitPixelCodeString (data, offset, clutEntries, clutMapTable,
    column, line) {
    let endOfPixelCodeString = false;
    let eof = false;
    let bitsLeft = 0;
    let bitsToFetch = 0;
    let bitsAvail = 0;
    let runLength = 0;
    let clutIndex = 0;
    let peek;
    let val;
    let scratchPad;
    let canvas = this.srcCanvasContext;

    logger.log('_paint2BitPixelCodeString: enter');

    do {
      runLength = 0;
      clutIndex = 0;

      // Fetch up to 16 bits from the source buffer
      bitsToFetch = 16 - bitsLeft;
      if (bitsToFetch > 0) {
        val = (val << bitsToFetch) & 0xFFFF;
      }

      while (bitsToFetch) {
        if ((bitsAvail === 0) && (offset < data.length)) {
          bitsAvail = 8;
          scratchPad = data[offset];
          offset += 1;
        }

        if (bitsAvail === 0) {
          // Source buffer exhausted
          break;
        }

        if (bitsToFetch < bitsAvail) {
          val |= (scratchPad & ((1 << bitsAvail) - 1)) >> (bitsAvail - bitsToFetch);
          bitsAvail -= bitsToFetch;
          scratchPad = scratchPad & (1 << (bitsAvail - 1));
          bitsToFetch = 0;
        } else {
          val |= (scratchPad & ((1 << bitsAvail) - 1)) << (bitsToFetch - bitsAvail);
          bitsToFetch = bitsToFetch - bitsAvail;
          bitsAvail = 0;
        }
      }

      bitsLeft = 16 - bitsToFetch;

      if ((bitsLeft >= 2) && ((peek = (val & 0xC000) >> 14) !== 0x00)) {
        runLength = 1;
        clutIndex = peek;
        bitsLeft -= 2;
      } else if (((bitsLeft > 3) && (val & 0x2000) >> 13) && (bitsLeft >= 8)) {
        runLength = 3 + ((data[offset] & 0x1C) >> 2);
        clutIndex = (data[offset] & 0x03);
        bitsLeft -= 8;
      } else if ((bitsLeft >= 4) && ((val & 0x1000) >> 12)) {
        runLength = 1;
        bitsLeft -= 4;
      } else if (bitsLeft >= 6) {
        switch ((val & 0x0C00) >> 10) {
        case 0x00: {
          endOfPixelCodeString = true;
          bitsLeft -= 6;
          break;
        }
        case 0x01: {
          runLength = 2;
          bitsLeft -= 6;
          break;
        }
        case 0x02: {
          if (bitsLeft < 12) {
            eof = true;
          } else {
            // 4 bits
            runLength = 12 + ((val & 0x03C0) >> 6);
            // 2 bits
            clutIndex = (val & 0x0030) >> 4;
            bitsLeft -= 12;
          }
          break;
        }
        case 0x03: {
          if (bitsLeft < 16) {
            eof = true;
          } else {
            // 8 bits
            runLength = 29 + ((val & 0x03FC) >> 2);
            clutIndex = val & 0x0003;
            bitsLeft -= 16;
          }
          break;
        }
        }
      } else {
        eof = true;
      }

      if (eof) {
        break;
      }

      //    if (runLength != 0 && paint != null) {
      //      paint.setColor(clutEntries[clutMapTable != null ? clutMapTable[clutIndex] : clutIndex]);
      //      canvas.drawRect(column, line, column + runLength, line + 1, paint);
      //    }

      column += runLength;
    } while (!endOfPixelCodeString);

    //  if (eof)
    //    canvas.drawColor(Color.TRANSPARENT, PorterDuff.Mode.CLEAR);

    logger.log('_paint2BitPixelCodeString: exit');

    return column;
  }

  /**
   * Paint a 4-bit/pixel code string, as defined by ETSI EN 300 743 7.2.5.2, to a canvas.
   */
  _paint4BitPixelCodeString (pixelData, clutEntries, clutMapTable, column, line) {
    let bitsLeft = 0;
    let bitsToFetch;
    let bitsAvail = 0;
    let bitsConsumed = 0;
    let totalBitsConsumed = 0;
    let val = 0;
    let scratchPad;
    let offset = pixelData.offset;
    let state;
    let columnDebug = column;
    let bitsLeftDebug;
    let underflow = true;
    let endOfPixelCodeString = false;
    let runLength = 0;
    let clutIndex = 0;
    let peek;
    let canvas = this.srcCanvasContext;

    // logger.log(`_paint4BitPixelCodeString: enter: pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    do {
      runLength = 0;
      clutIndex = 0;

      if (underflow) {
        // Fetch up to 24 bits from the source buffer
        bitsToFetch = Math.min(24 - bitsLeft, (pixelData.data.length - offset) * 8);

        while (bitsToFetch) {
          // logger.log(`_paint4BitPixelCodeString: fetch: bitsToFetch = ${bitsToFetch}, val = ${(val >>> 0).toString(16)}`)

          if ((bitsAvail === 0) && (offset < pixelData.data.length)) {
            bitsAvail = 8;
            scratchPad = pixelData.data[offset];
            offset += 1;
          }

          if (bitsAvail === 0) {
            // Source buffer exhausted
            // logger.log(`_paint4BitPixelCodeString: eof: offset = ${offset}, totalBitsConsumed = ${totalBitsConsumed}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);
            return column;
          }

          // logger.log(`_paint4BitPixelCodeString: fetch: bitsToFetch = ${bitsToFetch}, bitsAvail = ${bitsAvail}, val = 0x${(val >>> 0).toString(16)}, scratchPad = 0x${(scratchPad >>> 0).toString(16)} `)
          if (bitsToFetch < bitsAvail) {
            val |= scratchPad >> (bitsAvail - bitsToFetch);
            scratchPad = (scratchPad << bitsToFetch) & 0xFF;
            bitsAvail -= bitsToFetch;
            bitsLeft += bitsToFetch;
            bitsToFetch = 0;
            // logger.log(`_paint4BitPixelCodeString: fetch1: bitsToFetch = ${bitsToFetch}, bitsAvail = ${bitsAvail}, val = 0x${(val >>> 0).toString(16)}, scratchPad = 0x${(scratchPad >>> 0).toString(16)} `)
          } else {
            val |= (scratchPad >> (8 - bitsAvail)) << (bitsToFetch - bitsAvail);
            bitsLeft += bitsAvail;
            bitsToFetch -= bitsAvail;
            bitsAvail = 0;
            // logger.log(`_paint4BitPixelCodeString: fetch2: bitsToFetch = ${bitsToFetch}, bitsAvail = ${bitsAvail}, val = 0x${(val >>> 0).toString(16)}, scratchPad = 0x${(scratchPad >>> 0).toString(16)} `)
          }
        }

        underflow = false;
      }

      bitsLeftDebug = bitsLeft;

      if ((bitsLeft >= 4) && (peek = ((val >> 20) & 0x0F)) !== 0) {
        state = 'nextbits() != \'0000\'';
        // 4-bit_pixel-code (4 bits)
        // A 4-bit code, specifying the pseudo-colour of a pixel as either
        // an entry number of a CLUT with sixteen entries or an entry number
        // of a map-table.
        clutIndex = peek;
        runLength = 1;

        bitsConsumed = 4;
      } else if ((bitsLeft >= 5) && (((val >> 19) & 0x1F) === 0) && bitsLeft >= 8) {
        state = 'switch_1 == \'0\'';

        // 4-bit_zero ('0000') + switch_1 ('0') -> '00000'

        peek = (val >> 16) & 7;
        if (peek !== 0) {
          // run_length_3-9 (3 bits)
          // Number of pixels minus 2 that shall be set to pseudo-colour (entry) '0000'.
          runLength = 2 + peek;
          clutIndex = 0;
        } else {
          // end_of_string_signal (3bits)
          // A 3-bit field filled with '000'. The presence of this field, i.e.
          // nextbits() == '000', signals the end of the 4-bit/pixel_code_string.
          endOfPixelCodeString = true;
        }

        bitsConsumed = 8;
      } else if (bitsLeft >= 6 && (((val >> 18) & 0x3F) === 2) && bitsLeft >= 12) {
        state = 'switch_2 == \'0\'';

        // 4-bit_zero ('0000') + switch_1 ('1') + switch_2 ('0') -> '000010'

        // switch_2
        // A 1-bit switch. If set to '0', it signals that that the following 6-bits
        // contain run-length coded pixel-data.

        // run_length_4-7 (2 bits)
        // Number of pixels minus 4 that shall be set to the pseudo-colour defined next
        runLength = 4 + ((val >> 16) & 0x03);
        // 4-bit_pixel-code (4 bits)
        // A 4-bit code, specifying the pseudo-colour of a pixel as either an entry
        // number of a CLUT with sixteen entries or an entry number of a map-table.
        clutIndex = (val >> 12) & 0x0F;

        bitsConsumed = 12;
      } else if (bitsLeft >= 8 && (((val >> 18) & 0x3F) === 3)) {
        state = `switch_3 = '${((val >> 16) & 0x03).toString(2)}'`;
        peek = (val >> 16) & 0x03;
        switch (peek) {
        case 0x02:
          if (bitsLeft < 16) {
            underflow = true;
          } else {
            // run_length_9-24 (4 bits)
            // Number of pixels minus 9 that shall be set to the pseudo-colour
            // defined next.
            runLength = 9 + ((val >> 12) & 0x0F);
            // 4-bit_pixel-code (4 bits)
            // A 4-bit code, specifying the pseudo-colour of a pixel as either
            // an entry number of a CLUT with sixteen entries or an entry number
            // of a map-table.
            clutIndex = (val >> 8) & 0x0F;

            bitsConsumed = 16;
          }
          break;
        case 0x03:
          if (bitsLeft < 20) {
            underflow = true;
          } else {
            // run_length_25-280 (8 bits)
            // Number of pixels minus 25 that shall be set to the pseudo-colour
            // defined next.
            runLength = 25 + ((val >> 8) & 0xFF);
            // 4-bit_pixel-code (4 bits)
            // A 4-bit code, specifying the pseudo-colour of a pixel as either
            // an entry number of a CLUT with sixteen entries or an entry number
            // of a map-table.
            clutIndex = (val >> 4) & 0x0F;

            bitsConsumed = 20;
          }
          break;
        default:
          // '00' - 1 pixel shall be set to pseudo-colour (entry) '0000'
          // '01' - 2 pixels shall be set to pseudo-colour (entry) '0000'
          runLength = peek + 1;

          bitsConsumed = 8;
          break;
        }
      } else {
        underflow = true;
      }

      if (underflow) {
        // logger.log(`_paint4BitPixelCodeString: underflow: bitsLeft = ${bitsLeft}, val = 0x${val.toString(16).padStart(6, '0')}`);
        continue;
      }

      if (runLength !== 0 /* && paint != null */) {
        if (clutMapTable != null) {
          clutIndex = clutMapTable[clutIndex];
        }

        // logger.log(`_paint4BitPixelCodeString: column = ${column}, line = ${line}, width = ${runLength}, clutMapTable != null = ${clutMapTable != null}, clutIndex = ${clutIndex}, #${clutEntries[clutIndex].toString(16).padStart(8, '0')}`);

        canvas.fillStyle = uint32ToRGBA(clutEntries[clutIndex]);
        canvas.fillRect(column, line, runLength, 1);
      }

      bitsLeft -= bitsConsumed;
      totalBitsConsumed += bitsConsumed;

      // logger.log(`_paint4BitPixelCodeString: val = 0x${val.toString(16).padStart(6, '0')}, bitsLeft = ${bitsLeftDebug}, bitsConsumed = ${bitsConsumed}, state = ${state}, runLength = ${runLength}`);

      val = (val << bitsConsumed) & 0xFFFFFF;

      column += runLength;
    } while (!endOfPixelCodeString);

    //  if (eof)
    //    canvas.drawColor(Color.TRANSPARENT, PorterDuff.Mode.CLEAR);

    pixelData.offset += Math.trunc(totalBitsConsumed / 8);
    if ((totalBitsConsumed % 8) > 0) {
      pixelData.offset += 1;
    }

    // logger.log(`_paint4BitPixelCodeString: exit: endOfPixelCodeString = ${endOfPixelCodeString}, runLength = ${column - columnDebug}, totalBitsConsumed = ${totalBitsConsumed}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    return column;
  }

  /**
   * Paint an 8-bit/pixel code string, as defined by ETSI EN 300 743 7.2.5.2, to a canvas.
   */
  _paint8BitPixelCodeString (pixelData, clutEntries, clutMapTable, column, line) {
    let bitsLeft = 0;
    let bitsToFetch = 0;
    let bitsAvail = 0;
    let val;
    let scratchPad;
    let endOfPixelCodeString = false;
    let eof = false;
    let runLength = 0;
    let clutIndex = 0;
    let peek;
    const canvas = this.srcCanvasContext;

    logger.log(`_paint8BitPixelCodeString: enter: pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    do {
      // Fetch up to 24 bits from the source buffer
      bitsToFetch = 24 - bitsLeft;
      //    logger.log(`_paint8BitPixelCodeString: fetch: bitsToFetch = ${bitsToFetch}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);
      if (bitsToFetch > 0) {
        val = (val << bitsToFetch) & 0xFFFFFF;
      }

      while (bitsToFetch) {
        if ((bitsAvail === 0) && (pixelData.offset < pixelData.data.length)) {
          bitsAvail = 8;
          scratchPad = pixelData.data[pixelData.offset];
          pixelData.offset += 1;
        }

        if (bitsAvail === 0) {
          // Source buffer exhausted
          logger.log(`_paint8BitPixelCodeString: fetch: source buffer exhausted: pixelData = ${pixelData.offset}/${pixelData.data.length}`);
          return column;
        }

        if (bitsToFetch < bitsAvail) {
          val |= (scratchPad & ((1 << bitsAvail) - 1)) >> (bitsAvail - bitsToFetch);
          bitsAvail -= bitsToFetch;
          scratchPad = scratchPad & (1 << (bitsAvail - 1));
          bitsToFetch = 0;
        } else {
          val |= (scratchPad & ((1 << bitsAvail) - 1)) << (bitsToFetch - bitsAvail);
          bitsToFetch = bitsToFetch - bitsAvail;
          bitsAvail = 0;
        }
      }

      bitsLeft = 24 - bitsToFetch;

      if ((bitsLeft >= 8) && ((peek = (val & 0xFF0000) >> 16) !== 0x00)) {
        logger.log('_paint8BitPixelCodeString: switch 1');
        runLength = 1;
        clutIndex = peek;
        bitsLeft -= 8;
      } else if (bitsLeft >= 16) {
        logger.log('_paint8BitPixelCodeString: switch 2');
        if ((val & 0x8000) === 0) {
          logger.log('_paint8BitPixelCodeString: switch 2/1');
          peek = (val & 0x7F00) >> 8;
          if (peek !== 0x00) {
            runLength = peek;
            clutIndex = 0x00;
          } else {
            logger.log('_paint8BitPixelCodeString: switch 2/1 - end');
            endOfPixelCodeString = true;
          }
          bitsLeft -= 16;
        } else {
          logger.log('_paint8BitPixelCodeString: switch 2/2');
          runLength = (val & 0x7F00) >> 8;
          if (bitsLeft < 8) {
            logger.log('_paint8BitPixelCodeString: switch 2/2 - eof');
            eof = true;
          } else {
            clutIndex = val & 0xFF;
            bitsLeft -= 24;
          }
        }
      } else {
        logger.log('_paint8BitPixelCodeString: underflow');
        eof = true;
      }

      if (eof) {
        logger.log('_paint8BitPixelCodeString: eof');
        eof = false;
        continue;
      }

      if (runLength !== 0 /* && paint != null */) {
        //      paint.setColor(clutEntries[clutMapTable != null ? clutMapTable[clutIndex] : clutIndex]);
        //      if (clutMapTable != null) {
        //        clutIndex = clutMapTable[clutIndex];
        //      } else {
        //        clutIndex = clutIndex;
        //      }
        //      logger.log(`_paint8BitPixelCodeString: column = ${column}, line = ${line}, width = ${runLength}, clutMapTable != null = ${clutMapTable != null}, clutIndex = ${clutIndex}, ${clutEntries[clutIndex]}`);
        //      canvas.fillStyle = `#${clutEntries[clutIndex].toString(16).padStart(6, '0')}`;
        //      canvas.fillRect(column, line, column + runLength, line + 1);
      }
      column += runLength;
    } while (!endOfPixelCodeString);

    logger.log(`_paint8BitPixelCodeString: exit: endOfPixelCodeString = ${endOfPixelCodeString}, eof = ${eof}, column = ${column}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    //  if (eof)
    //    canvas.drawColor(Color.TRANSPARENT, PorterDuff.Mode.CLEAR);

    return column;
  }

  _buildClutMapTable (length, bitsPerEntry, pixelData) {
    let bitsLeft = 0;
    let bitsToFetch = 0;
    let bitsAvail = 0;
    let bitsConsumed = 0;
    let val;
    let scratchPad;
    let offset = pixelData.offset;
    let underflow = true;
    let a = [];
    let mapTableBitLength = length * bitsPerEntry;

    logger.log(`_buildClutMapTable: enter: length = ${length}, bitsPerEntry = ${bitsPerEntry}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    if (bitsPerEntry !== 4 && bitsPerEntry !== 8) {
      return null;
    }

    do {
      // Fetch up to 8 bits from the source buffer
      bitsToFetch = 8 - bitsLeft;
      if (bitsToFetch > 0) {
        val = (val << bitsToFetch) & 0xFF;
      }

      if (underflow) {
        while (bitsToFetch) {
          logger.log(`_buildClutMapTable: fetch: bitsToFetch = ${bitsToFetch}`);

          if ((bitsAvail === 0) && (offset < pixelData.data.length)) {
            bitsAvail = 8;
            scratchPad = pixelData.data[offset];
            offset += 1;
          }

          if (bitsAvail === 0) {
            // Source buffer exhausted
            logger.log(`_buildClutMapTable: eof: bitsConsumed = ${bitsConsumed}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);
            return null;
          }

          if (bitsToFetch < bitsAvail) {
            val |= (scratchPad >> (bitsAvail - bitsToFetch)) << (bitsLeft - bitsToFetch);
            scratchPad = scratchPad << bitsToFetch;
            bitsAvail -= bitsToFetch;
            bitsLeft += bitsToFetch;
            bitsToFetch = 0;
          } else {
            val |= (scratchPad >> (8 - bitsAvail)) << (bitsLeft - bitsAvail);
            bitsLeft += bitsAvail;
            bitsToFetch -= bitsAvail;
            bitsAvail = 0;
          }

          logger.log(`_buildClutMapTable: fetch: bitsLeft = ${bitsLeft}`);
        }

        underflow = false;
      }

      if (bitsPerEntry < bitsLeft) {
        underflow = true;
      } else {
        if (bitsPerEntry === 4) {
          a.push((val >> 4) & 0xFF);
          bitsLeft -= 4;
          bitsConsumed += 4;
        } else if (bitsPerEntry === 8) {
          a.push(val & 0xFF);
          bitsLeft -= 8;
          bitsConsumed += 8;
        }
      }
    } while (bitsConsumed < mapTableBitLength);

    pixelData.offset += Math.trunc(bitsConsumed / 8);
    if ((bitsConsumed % 8) > 0) {
      pixelData.offset += 1;
    }

    logger.log(`_buildClutMapTable: exit: ${a}, bitsConsumed = ${bitsConsumed}, pixelData = ${pixelData.offset}/${pixelData.data.length}`);

    return a;
  }
}

// Segment types, as defined by ETSI EN 300 743 Table 2
DvbSubParser.SEGMENT_TYPE_PAGE_COMPOSITION = 0x10;
DvbSubParser.SEGMENT_TYPE_REGION_COMPOSITION = 0x11;
DvbSubParser.SEGMENT_TYPE_CLUT_DEFINITION = 0x12;
DvbSubParser.SEGMENT_TYPE_OBJECT_DATA = 0x13;
DvbSubParser.SEGMENT_TYPE_DISPLAY_DEFINITION = 0x14;

// Region depths, as defined by ETSI EN 300 743 Table 5
// const REGION_DEPTH_2_BIT = 1;
DvbSubParser.REGION_DEPTH_4_BIT = 2;
DvbSubParser.REGION_DEPTH_8_BIT = 3;

// Object codings, as defined by ETSI EN 300 743 Table 8
DvbSubParser.OBJECT_CODING_PIXELS = 0;
DvbSubParser.OBJECT_CODING_STRING = 1;

// Pixel-data types, as defined by ETSI EN 300 743 Table 9
DvbSubParser.DATA_TYPE_2BP_CODE_STRING = 0x10;
DvbSubParser.DATA_TYPE_4BP_CODE_STRING = 0x11;
DvbSubParser.DATA_TYPE_8BP_CODE_STRING = 0x12;
DvbSubParser.DATA_TYPE_24_TABLE_DATA = 0x20;
DvbSubParser.DATA_TYPE_28_TABLE_DATA = 0x21;
DvbSubParser.DATA_TYPE_48_TABLE_DATA = 0x22;
DvbSubParser.DATA_TYPE_END_LINE = 0xF0;

// Clut mapping tables, as defined by ETSI EN 300 743 10.4, 10.5, 10.6
DvbSubParser.defaultMap2To4 = new Uint8Array([
  0x00, 0x07, 0x08, 0x0F
]);
DvbSubParser.defaultMap2To8 = new Uint8Array([
  0x00, 0x77, 0x88, 0xFF
]);
DvbSubParser.defaultMap4To8 = new Uint8Array([
  0x00, 0x11, 0x22, 0x33,
  0x44, 0x55, 0x66, 0x77,
  0x88, 0x99, 0xAA, 0xBB,
  0xCC, 0xDD, 0xEE, 0xFF
]);

export default DvbSubParser;
