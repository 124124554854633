import { DefaultButton } from 'common/components/button';
import { LoadingIndicator } from 'common/components/common/style';
import variables from 'common/config/variables';
import { usePopup } from 'common/contexts/popup';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import React, { useCallback, useEffect, useRef, useState } from 'react';

GlobalStyle.inject`
.iframe-popup-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    iframe {
        width: ${getScalablePixel(1800)}px;
        height: ${getScalablePixel(900)}px;
        border: none;
        background: ${variables.color_grey_90};
    }

    .button-holder {
        width: ${getScalablePixel(180)}px;
    }
}
`;

interface IFramePopupViewerProps {
  popupId: string;
  src: string;
}
function IFramePopupViewer(props: IFramePopupViewerProps) {
  const [loaded, setLoaded] = useState(false);
  const spinnerRef = useRef<HTMLDivElement>(null);
  const { closePopup } = usePopup();

  useEffect(() => {
    if (spinnerRef.current) spinnerRef.current.style.visibility = 'hidden';
    const timeout = setTimeout(() => {
      if (spinnerRef.current && !loaded) spinnerRef.current.style.visibility = 'visible';
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [loaded]);

  const onClose = useCallback(() => {
    closePopup(props.popupId);
  }, [props.popupId]);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="iframe-popup-viewer">
      <iframe src={props.src} onLoad={onLoad} />
      {!loaded && <LoadingIndicator ref={spinnerRef} />}
      <div className="button-holder">
        <DefaultButton title="OK" onEnterPress={onClose} />
      </div>
    </div>
  );
}

export default React.memo(IFramePopupViewer);
