import { Column, Row } from 'common/components/common/style';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import { hexToRGB } from 'common/utils/helpers';
import styled from 'styled-components';

export const DemoTitle = styled.div`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -${getScalablePixel(25)}px;
  left: 50%;
  transform: translateX(-50%, 100%);
  transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
  right: 0;
  font-size: ${getScalablePixel(18)}px;
  font-weight: 900;
  background: ${hexToRGB(variables.color_primary, 0.6)};
  border-radius: ${getScalablePixel(4)}px ${getScalablePixel(4)}px 0px 0px;
  width: ${getScalablePixel(94)}px;
  z-index: -1;
  height: ${getScalablePixel(25)}px;
  flex-shrink: 0;
`;

export const RemainingTimeValue = styled.span`
  font-size: ${getScalablePixel(52)}px;
  font-weight: 900;
`;

export const RemainingSubtitle = styled.span`
  font-size: ${getScalablePixel(15)}px;
  font-weight: normal;
  text-align: left;
`;

export const RemainingTimePeriod = styled.span`
  font-size: ${getScalablePixel(22)}px;
  font-weight: 900;
  text-align: left;
`;

export const BottomBorder = styled.span`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  transition: transform 200ms;
  right: 0;
  background: ${hexToRGB(variables.color_primary, 0.6)};
  border-radius: 0px 0px ${getScalablePixel(4)}px ${getScalablePixel(4)}px;
  width: ${getScalablePixel(94)}px;
  z-index: -1;
  bottom: -${getScalablePixel(5)}px;
  height: ${getScalablePixel(5)}px;
  flex-shrink: 0;
`;

export const ColumnContainer = styled(Column)`
  padding: 0 ${getScalablePixel(6)}px;
`;

export const MenuTrialCounterStyle = styled.div<{ focused?: boolean }>`
  color: ${variables.color_white};
  display: flex;
  padding: 0px ${getScalablePixel(16)}px;
  border-radius: ${getScalablePixel(8)}px;
  width: ${getScalablePixel(120)}px;
  align-items: center;
  justify-content: center;
  background: ${hexToRGB(variables.color_grey_80, 0.6)};
  box-shadow: 0px 0px ${getScalablePixel(42)}px -${getScalablePixel(2)}px ${variables.color_grey_60};
  ${DemoTitle}, ${BottomBorder} {
    opacity: ${({ focused }) => (focused ? 1 : 0)};
  }
  ${DemoTitle} {
    transform: ${({ focused }) => (focused ? 'translate(-50%, 0)' : 'translate(-50%, 100%)')};
  }
`;

export const TrialCounterStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${getScalablePixel(32)}px;
  right: 0;
  color: ${variables.color_white};

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(${hexToRGB(variables.color_grey_90, 0.6)}, transparent 70%);
  }

  ${Row} {
    max-width: fit-content;
  }
`;

export const DemoPeriodTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${getScalablePixel(16)}px;
  padding-right: ${getScalablePixel(32)}px;
  width: 100%;
  height: ${getScalablePixel(24)}px;
  background: linear-gradient(90deg, ${variables.color_white} 58%, transparent 94%);
  color: ${variables.color_grey_90};
  font-size: ${getScalablePixel(16)}px;
  z-index: 1;
`;

export const TrialModalStyle = styled.div<{ $primary?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${getScalablePixel(36)}px;
  align-items: center;
  width: ${getScalablePixel(1190)}px;
  background: ${variables.background_color_dark};
  .button-holder {
  }
  .email {
    font-size: ${getScalablePixel(32)}px;
    font-family: Default-Font;
    font-weight: 700;
    color: ${variables.color_white};
    margin-top: ${getScalablePixel(30)}px;
    margin-bottom: ${getScalablePixel(36)}px;
  }
  .button-holder {
    margin-top: ${getScalablePixel(36)}px;
    .button {
      display: flex;
      width: ${getScalablePixel(311)}px;
      padding: ${getScalablePixel(16)}px ${getScalablePixel(18)}px;
      justify-content: center;
      align-items: center;
    }
  }
  .text-box {
    color: ${variables.color_grey_30};
    display: flex;
    width: ${getScalablePixel(989)}px;
    justify-content: center;
    align-items: flex-start;
    &.qr-code {
      height: ${getScalablePixel(280)}px;
      margin-top: ${getScalablePixel(43)}px;

      .left {
        font-size: ${getScalablePixel(30)}px;
      }
    }
    .left {
      display: flex;
      justify-content: space-between;
      height: 100%;
      color: ${variables.color_grey_30};
      text-align: right;
      font-size: ${getScalablePixel(38)}px;
      line-height: 110%;
      width: ${getScalablePixel(375)}px;
      flex-direction: column;
      flex-shrink: 0;
      .numberBox {
        margin-top: ${getScalablePixel(25)}px;
        font-size: ${getScalablePixel(74)}px;
        font-weight: bolder;
        color: ${variables.color_grey_10};
      }
    }
    .right {
      color: ${variables.color_grey_40};
      font-size: ${getScalablePixel(22)}px;
      margin-left: ${getScalablePixel(26)}px;
      font-family: Default-Font;
      width: ${getScalablePixel(438)}px;
      .text {
        &.number {
          font-family: Default-Font;
          color: ${variables.color_grey_10};
          font-size: ${getScalablePixel(34)}px;
          font-weight: bold;
        }
        ~ .text {
          margin-top: ${getScalablePixel(14)}px;
          white-space: pre-line;
        }
      }
    }
  }
`;

export const TrialWelcomeStyle = styled.div<{ $primary?: boolean }>`
  width: 100%;
  height: 100%;
  margin-top: ${getScalablePixel(114)}px;
  font-family: 'Default-Font';
  color: ${variables.color_grey_10};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: ${variables.color_grey_10};
    text-align: center;
    font-size: ${getScalablePixel(42)}px;
    font-family: 'Default-Font';
    font-weight: bold;
  }
  .text {
    &.anyware {
      margin-top: ${getScalablePixel(36)}px;
      font-size: ${getScalablePixel(20)}px;
      font-weight: normal;
    }
    ,
    &.trialDays {
      margin-top: ${getScalablePixel(106)}px;
      font-size: ${getScalablePixel(42)}px;
      font-weight: bold;
    }
    &.takeAdvatage {
      text-align: center;
      font-size: ${getScalablePixel(26)}px;
      margin-top: ${getScalablePixel(10)}px;
      font-weight: normal;
    }
    &.gray-10 {
      color: ${variables.color_grey_10};
    }
    &.gray-30 {
      color: ${variables.color_grey_30};
    }
  }
  .emelemts {
    display: flex;
    justify-content: center;
    color: ${variables.color_grey_30};
    margin-top: ${getScalablePixel(120)}px;
    .element {
      ~ .element {
        margin-left: ${getScalablePixel(32)}px;
      }
    }
    .feature-title {
      width: ${getScalablePixel(271)}px;
      font-size: ${getScalablePixel(20)}px;
      color: ${variables.color_grey_30};
      margin-top: ${getScalablePixel(45)}px;
    }
    .image {
      height: ${getScalablePixel(35)}px;
      &.stopSeek {
        img {
          height: ${getScalablePixel(25)}px;
        }
      }
      img {
        height: ${getScalablePixel(35)}px;
      }
    }
  }
`;

export const ButtonContainerTrial = styled.div<{ $primary?: boolean }>`
  margin-top: ${getScalablePixel(32)}px;
`;

export const TrialEndStyle = styled.div<{ $primary?: boolean }>`
  width: 100%;
  color: ${variables.color_grey_10};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${getScalablePixel(94)}px;

  .icon-trial {
    width: ${getScalablePixel(218)}px;
    height: ${getScalablePixel(198)}px;
  }
  .row {
    display: flex;
    align-items: center;
    color: ${variables.color_grey_30};
    font-size: ${getScalablePixel(22)}px;
    font-weight: 400;
    width: ${getScalablePixel(1300)}px;
    .left ~ .right {
      margin-left: ${getScalablePixel(55)}px;
    }

    &.title {
      width: 100%;
    }

    .left,
    .right {
      flex: 1;
    }
    &.qr-code {
      margin-top: ${getScalablePixel(32)}px;
      width: auto;

      .text-right {
        white-space: pre-line;
        margin-bottom: ${getScalablePixel(32)}px;
      }
    }
    &.row-info {
      margin-top: ${getScalablePixel(38)}px;
      padding: ${getScalablePixel(32)}px 0;
      border-width: ${getScalablePixel(1)}px 0;
      border-color: ${variables.color_grey_30};
      border-style: dashed;

      .left {
        align-items: flex-end;
      }

      .right,
      .left {
        display: flex;
        width: ${getScalablePixel(621)}px;
        flex-direction: column;
        align-self: stretch;
        justify-content: space-between;
      }
    }
  }
  .phone {
    color: ${variables.color_white};
    font-weight: bold;
  }
  .website {
    font-weight: 700;
    line-height: normal;
    color: ${variables.color_white};
  }
  .left {
    text-align: right;
    .expire {
      color: ${variables.color_white};
      font-size: ${getScalablePixel(56)}px;
      width: 90%;
      white-space: pre-line;
      font-weight: bold;
    }
  }
  .right {
    text-align: left;
    .text-right {
      line-height: 1.4;
      width: 90%;
      white-space: pre-line;
    }
    .text-right ~ .text-right {
      margin-top: ${getScalablePixel(32)}px;
    }
    img.image {
      width: ${getScalablePixel(318)}px;
      height: ${getScalablePixel(88)}px;
    }
    .email {
      font-size: ${getScalablePixel(32)}px;
      font-weight: bold;
      margin-top: ${getScalablePixel(16)}px;
      line-height: normal;
      color: ${variables.color_white};
    }
  }
  .button-row {
    width: inherit;
    .left,
    .right {
      flex: 1;
    }
    .right {
      margin-left: ${getScalablePixel(55)}px;
    }

    .button-container {
      display: flex;
      .button {
        margin: 0 !important;
        margin-top: ${getScalablePixel(32)}px !important;
      }
    }
  }
`;
