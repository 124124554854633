import {
  CATEGORIES_ALL_ID,
  FILTER_TYPE_LIVE,
  FILTER_TYPE_RECORDED,
  ROUTE_SEARCH
} from 'common/config/constants';
import {
  SEARCH_CHANNEL_RESULT,
  SEARCH_CLEAR,
  SEARCH_FILTER_RESULT,
  SEARCH_SET_RESULT
} from 'common/constants/action-types';
import { secondsToMilliseconds } from 'common/services/helpers';
import request from 'common/services/request';
import { checkLockStatusForChannel, checkVisibleStatusForChannel } from 'common/utils/helpers';
import { AppDispatch } from 'index';

export enum SearchMode {
  SEARCH_MODE_BOTH = 1,
  SEARCH_MODE_TITLE = 2,
  SEARCH_MODE_DESCRIPTION = 3
}

export type SearchType = 'active' | null | undefined;

export const search = (
  searchTerm: string,
  mode: SearchMode = SearchMode.SEARCH_MODE_BOTH,
  type: SearchType = 'active'
) => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      const url = `${ROUTE_SEARCH}?query=${encodeURIComponent(searchTerm)}&mode=${mode}`;
      const ajax = request({
        url,
        method: 'get'
      });
      const { data } = await ajax;
      const representedCategories = new Set();
      const representedFutureCategories = new Set();
      const representedRecordedCategories = new Set();

      if (data.length > 0) {
        // Separate the recorded from live/future shows
        // because live/future are displayed only
        // if the search is of type=active.
        const now = new Date().getTime();
        const recorded: any[] = [];
        const future: any[] = [];
        const { profileSettings } = getState();

        // Filter out hidden channels
        const filteredChannels = data.filter(
          (channel: any) =>
            checkVisibleStatusForChannel(profileSettings, channel.chanId) &&
            !checkLockStatusForChannel(profileSettings, channel.chanId)
        );
        filteredChannels.forEach((epg: any) => {
          const startMilliseconds = secondsToMilliseconds(epg.start);
          const stopMilliseconds = secondsToMilliseconds(epg.stop);

          representedCategories.add(epg.majorId);

          if (startMilliseconds > now || stopMilliseconds > now) {
            future.push(epg);
            representedFutureCategories.add(epg.majorId);
          } else {
            recorded.push(epg);
            representedRecordedCategories.add(epg.majorId);
          }
        });

        // Make sure that live shows come first.
        future.sort(({ start: startA }, { start: startB }) => startA - startB);

        dispatch({
          type: SEARCH_SET_RESULT,
          payload: {
            recorded,
            representedCategories,
            representedFutureCategories,
            representedRecordedCategories,
            future: type ? future : []
          }
        });
      } else {
        dispatch({
          type: SEARCH_CLEAR,
          payload: {
            hasSearch: true
          }
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };
};

export const clearSearch = (shouldResetLiveFilter?: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SEARCH_CLEAR,
      payload: {
        hasSearch: false,
        shouldResetLiveFilter: shouldResetLiveFilter
      }
    });
  };
};

export const filterByChannelName = (searchString: string) => {
  return (dispatch: AppDispatch, getState: any) => {
    let filteredSearchLive = [];
    const filteredLiveChannels = getState().filteredLiveChannels;

    if (searchString && searchString.trim().length >= 0) {
      Object.keys(filteredLiveChannels).forEach((id) => {
        const channelName = filteredLiveChannels[id].name.toLowerCase();
        const trimmedSearchString = searchString.trim().toLowerCase();

        if (channelName.includes(trimmedSearchString)) {
          filteredSearchLive.push(filteredLiveChannels[id]);
        }
      });
    } else {
      filteredSearchLive = filteredLiveChannels;
    }

    dispatch({
      type: SEARCH_CHANNEL_RESULT,
      payload: {
        filteredSearchLive
      }
    });
  };
};

export const filterByCategory = (category: any, filterType: any) => {
  return (dispatch: AppDispatch, getState: any) => {
    let filteredFuture = [];
    let filteredRecorded = [];
    let filteredLive = [];
    const future = getState().searchState.future;
    const recorded = getState().searchState.recorded;
    const filteredLiveChannels = getState().filteredLiveChannels;
    if (category !== CATEGORIES_ALL_ID && category !== null) {
      if (filterType === FILTER_TYPE_RECORDED) {
        filteredFuture = future.filter(({ majorId }: any) => majorId === category);
        filteredRecorded = recorded.filter(({ majorId }: any) => majorId === category);
      }

      if (filterType === FILTER_TYPE_LIVE) {
        const channels = getState().searchState.filteredSearchLive || filteredLiveChannels;
        Object.keys(channels).forEach((id) => {
          if (category === channels[id].category) {
            filteredLive.push(channels[id]);
          }
        });
      }
    } else {
      filteredFuture = [...future];
      filteredRecorded = [...recorded];
      filteredLive = filteredLiveChannels;
      category = CATEGORIES_ALL_ID;
    }
    dispatch({
      type: SEARCH_FILTER_RESULT,
      payload: {
        filteredFuture,
        filteredRecorded,
        filteredLive,
        filterType,
        category
      }
    });
  };
};
