import AnimationDurations from 'common/utils/AnimationDurations';
import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
.stripe-view-container {
  .stripe-header-container {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
  }
  overflow-y: hidden;
  overflow-x: hidden;
  will-change: transform;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibily: hidden;
  display: flex;
  transition: opacity ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  z-index: 1;
  flex-grow: 1;
  &.x {
    flex-direction: row;
  }
  &.y {
    flex-direction: column;
  }
}

`;
