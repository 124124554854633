import {
  USER_CONFIRM_DELETION,
  USER_GET_DETAILS,
  USER_GET_FULL_DETAILS,
  USER_GET_INVOICES,
  USER_GET_INVOICE_URL,
  USER_REMOVE_DETAILS
} from 'common/constants/action-types';

import { COUNTRIES } from 'common/config/countries';

export default function (state = {}, action) {
  switch (action.type) {
    case USER_GET_FULL_DETAILS:
      let selectedCountryBilling = '';
      let selectedCountryShipping = '';
      let predefinedCountry = '';

      const billingAddress = action.payload.data.billingAddress;
      const shippingAddress =
        action.payload.data.subscription[0] && action.payload.data.subscription[0].shippingAddress;

      if (billingAddress.country) {
        selectedCountryBilling = COUNTRIES.filter((countryObj) => {
          return countryObj.code === billingAddress.country;
        });
      } else {
        predefinedCountry = COUNTRIES.filter((countryObj) => {
          return countryObj.code === action.payload.data.countryLastLogin;
        });
      }

      if (shippingAddress.country) {
        selectedCountryShipping = COUNTRIES.filter((countryObj) => {
          return countryObj.code === shippingAddress.country;
        });
      } else {
        predefinedCountry = COUNTRIES.filter((countryObj) => {
          return countryObj.code === action.payload.data.countryLastLogin;
        });
      }

      return {
        ...state.user,
        ...action.payload.data,
        predefinedCountry:
          predefinedCountry && predefinedCountry[0] ? predefinedCountry[0].name : '',
        predefinedCountryCode:
          predefinedCountry && predefinedCountry[0] ? predefinedCountry[0].code : '',
        countryNameBilling:
          selectedCountryBilling && selectedCountryBilling[0] ? selectedCountryBilling[0].name : '',
        countryNameShipping:
          selectedCountryShipping && selectedCountryShipping[0]
            ? selectedCountryShipping[0].name
            : ''
      };
    case USER_GET_DETAILS:
      return {
        ...action.payload.data
      };
    case USER_GET_INVOICES:
      return {
        ...state,
        invoicesList: action.payload.data
      };
    case USER_GET_INVOICE_URL:
      return {
        ...state,
        invoice: action.payload.data
      };
    case USER_CONFIRM_DELETION:
      return {
        ...state,
        isDeletionConfirmed: action.payload
      };
    case USER_REMOVE_DETAILS:
      return {};
    default:
      return state;
  }
}
