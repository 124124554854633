import { TRANSLATIONS_GET_COMMON } from 'common/constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case TRANSLATIONS_GET_COMMON: {
      return action.payload;
    }
    default:
      return state;
  }
}
