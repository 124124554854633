import React from 'react';

import { Epg } from 'common/constants/data-types';

import { MetadataMajorCategory, MetadataMinor, MetadataSeparator } from './MetadataStrip.styles';

interface MetadataCategoryProps {
  major_category?: Epg['major_category'];
  minor_categories?: Epg['minor_categories'];
}

function MetadataCategory({ minor_categories, major_category }: MetadataCategoryProps) {
  const renderCategories = () => {
    if (!minor_categories) {
      return null;
    }
    return minor_categories.map((category, index) => (
      <React.Fragment key={`minor-category-${index}`}>
        <MetadataSeparator separator={index === 0 ? '/' : undefined} padding={4} />
        <MetadataMinor>{category}</MetadataMinor>
      </React.Fragment>
    ));
  };

  return (
    <>
      <MetadataMajorCategory>{major_category}</MetadataMajorCategory>
      {renderCategories()}
      <MetadataSeparator padding={4} />
    </>
  );
}

export default React.memo(MetadataCategory);
