import { useCallback, useState } from 'react';

type TsetStateParam<T> = Partial<T> | ((prevState: T) => Partial<T>);
type TsetState<T> = (patch: TsetStateParam<T>) => void;

const useSetState = <T extends object>(initialState: T = {} as T): [T, TsetState<T>] => {
  const [state, set] = useState<T>(initialState);
  const setState = useCallback((patch: TsetStateParam<T>) => {
    set((prevState) =>
      Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch)
    );
  }, []);

  return [state, setState];
};

export default useSetState;
