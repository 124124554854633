import { toNumber } from 'lodash';

export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;
export const IS_LIVE_API = JSON.parse(process.env.REACT_APP_IS_LIVE_API);
export let CHANNEL_POSITION_TYPE = 0;
try {
  CHANNEL_POSITION_TYPE = JSON.parse(process.env.REACT_APP_CHANNEL_POSITION_TYPE);
} catch (error) { }
export const RADIO_CATEGORY_ID = toNumber(process.env.REACT_APP_RADIO_CATEGORY_ID);

export const PAID_CHANNEL_POSITION_OFFSET = 100000;

export const API_URL =
  (IS_LIVE_API ? process.env.REACT_APP_LIVE_API_URL : process.env.REACT_APP_DEV_API_URL) || '';

export const SENTRY_DSN = 'https://1a174dd393af4b6c84d35ac69bec6869@o128851.ingest.sentry.io/4505279011880960'

export const DEFAULT_SCREEN_WIDTH = 1920;
export const DEFAULT_SCREEN_HEIGHT = 1080;
export const THUMBNAIL_OFFSET = 240; // in seconds (4 minutes)
export const THUMBNAIL_BUFFER_OFFSET = 8; // in seconds. backend gets a new thumnbnail
export const SHOWS_LIMIT = 2;
export const ON_RECORD_INITIAL_PAGE_SIZE = 20;
export const DIRECTION_RIGHT = 'right';
export const DIRECTION_LEFT = 'left';
export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';
export const DIRECTION_NONE = 'none';
export const API_MAX_CATEGORIES_LIMIT = 20; // the maximum number of categories/subcategories for which epg-s can be fetched simultaneously
export const TIMEZONE = 'Europe/Sofia';
export const TIME_FORMAT = 'en-GB';
export const MS_RANGE = 30000;
export const REMIND_TRIAL_EXPIRATION_BEFORE_DAYS = 5;

export const FILTER_TYPE_LIVE = 'Live';
export const FILTER_TYPE_RECORDED = 'Recorded';

export const CATEGORIES_ALL_ID = 1;
export const CATEGORY_FAVORITES_TYPE = {
  id: -1,
  position: -1
};

// RegExs
export const REGEX_EMAIL =
  /^[A-Z0-9—!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9—!#$%&'*+-/=?^_`{|}~]+\.[A-Z]{2,}$/i;
export const REGEX_PASSWORD = /\S+/;
export const REGEX_ALPHABET = /[\d\W\s\'_']/g;
export const REGEX_ALPHANUMERIC = /[^a-z0-9]/gi;
export const REGEX_ADDRESS = /[^a-zA-Z0-9ÄÖäöÀàÂâÆæßÇçÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜü\-+,.#№:()\s]/g;
export const REGEX_PHONE = /[^0-9+-]/g;
export const REGEX_UCN = /\d{10}/;
export const REGEX_PIN = /^[0-9]{0,4}$/;

// Pin default length
export const DEFAULT_PIN_LENGTH = 4;

// Routes:
export const LAST_WATCHED = '/most-watched/last';
export const MOST_WATCHED = '/most-watched';
export const ON_RECORD = '/on-record';
export const ROUTE_ACTIVATE_ACCOUNT = '/activate-account';
export const ROUTE_ARCHIVE = '/archive';
export const ROUTE_TRIAL_WELCOME = '/trial';
export const ROUTE_TRIAL_END = '/trial-end';
export const ROUTE_CATEGORY = '/category';
export const ROUTE_RESTRICTED_GEOGRAPHY = '/restricted-geography';
export const ROUTE_SIGN_IN = '/login';
export const ROUTE_LOGOUT = 'users/logout';
export const ROUTE_SIGN_UP = '/register';
export const ROUTE_TOO_MANY_USERS = '/restricted-access';
export const ROUTE_NO_SUBSCRIPTION = '/no-subscription';
export const ROUTE_ERROR_LOADING_DATA = '/error-loading-data';
export const ROUTE_SEARCH = '/search';
export const ROUTE_INBOX = '/inbox';
export const ROUTE_TV_CHANNELS = '/channels';
export const ROUTE_GOOGLE_SIGN_IN = '//portal.playtv.bg/v1/users/signin/google';
export const ROUTE_ORDER = '/order';
export const ROUTE_ORDER_SUBSCRIPTIONS = '/order/subscriptions';
export const ROUTE_ORDER_PREVIEW = '/order/preview';
export const ROUTE_ORDER_DETAILS = '/order/details';
export const ROUTE_ORDER_ADDRESSES = '/order/addresses';
export const ROUTE_ORDER_PAYMENT = '/order/payment';
export const ROUTE_ORDER_PAYMENT_SUCCESS = '/order/payment-success';
export const ROUTE_ORDER_PAYMENT_FAIL = '/order/payment-fail';
export const ROUTE_ORDER_ACTIVATION_FAIL = '/order/activation-fail';
// export const ROUTE_ORDER_ACTIVATION_SUCCESS = '/order/activation-success';
export const ROUTE_ROOT = '/';
export const ROUTE_HOME = '/home';
export const REDIRECT_DEFAULT_ROUTE = ROUTE_ROOT;
export const SIGN_IN_DEFAULT_REDIRECT = '/channels';
export const ROUTE_SCREENSAVER = '/screensaver';

// Routes in which the header is shown
export const ROUTES_WITH_HEADER = [ROUTE_HOME, ROUTE_ARCHIVE, ROUTE_SEARCH, ROUTE_TV_CHANNELS];
// Routes which are navigatable from the header on focus
export const MENU_ROUTES = [ROUTE_HOME, ROUTE_ARCHIVE, ROUTE_TV_CHANNELS];

// Status codes:
export const STATUS_CODE_SUCCESS = 200;
export const STATUS_CODE_BAD_REQUEST = 400;

// Groups of error codes that require specific actions
// influencing the overall app behavior:

// 200001 = Users: email validation failed.
// 200004 = Users: access denied.
// 200020 = Users: session has expired.
// 200021 = Users: parallel sessions limit exceeded.
export const ERROR_CODES_THAT_REQUIRE_LOGIN = [200001, 200020];
export const ERROR_SUBCODE_GEO_RESTRICTION = 200031;
export const ERROR_SUBCODE_EXISTING_USER = 200030;
export const ERROR_SUBCODE_PASSWORDS_DONT_MATCH = 200003;
export const ERROR_SUBCODE_WRONG_CURRENT_PASSWORD = 200019;
export const ERROR_SUBCODE_TOO_MANY_USERS = 200021;
export const ERROR_SUBCODE_NO_GDPR_CONSENT = 200033;
export const ERROR_SUBCODE_ACCESS_DENIES = 200004;
export const ERROR_SUBCODE_STREAM_LIMIT = 300004;
export const ERROR_SUBCODE_TRIAL_EXPIRED = 200050;

export const ERROR_REDIRECT_MAP = {
  [ERROR_SUBCODE_TOO_MANY_USERS]: ROUTE_TOO_MANY_USERS,
  [ERROR_SUBCODE_ACCESS_DENIES]: ROUTE_NO_SUBSCRIPTION
};

// Player constants
export const VOLUME_MIN = 0;
export const VOLUME_MAX = 1;
export const VOLUME_STEP = 0.01;
export const TIME_TO_SHARE = 60; // in seconds
export const SECONDS_TO_REVERT = 15; // value in seconds
export const TIME_BEFORE_BUFFER = 12; // value in seconds
export const NOW_EPG = 1; // Live video
export const HAS_ACCESS = 1; // Available in subscription
export const CHANGE_PREV_EPG = 30; // value in seconds
export const CHUNK_SECONDS = 4;
export const ENABLE_LIVE_SECONDS = 16; // 4 chunks * 4 sec = 16
export const ENABLE_BEGINING_SECONDS = 5;
export const ONE_HOUR_IN_SECONDS = 3600; // value in seconds
export const INITIAL_TIME_FORMAT = '00:00';
export const TIME_TO_RECOVER = 3000; // in seconds

export const SUBTITLE_OFF_ID = -1;

// Currency
export const CURRENCIES = {
  EUR: '€',
  BGN: 'лв.'
};

// LogoTypes
export const LOGO_TYPES = {
  svgCentered: 'svgCentered',
  svgLeft: 'svgLeft',
  png: 'png',
  pngWhite: 'pngWhite',
};

// todo: add the rest
export const CHANNEL_SETTING_NAMES = {
  VISIBLE: 'visible',
  LOCKED: 'locked',
  FAVORITE: 'favorite',
  FAVORITE_POSITION: 'favoritePosition'
};

export const CHANNEL_ACCESS_TYPES = {
  PAID: 'paid',
  LOCKED: 'locked'
}

export const RIVE_SUPPORT_CHROME_VERSION = 70;
export const NETWORK_MAX_RETRIES = 6;
export const NETWORK_RETRY_TIMEOUT_INIT = 500;
