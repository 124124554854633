import DefaultMediaImage from '@assets/media/placeholder-message.png';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Translation } from 'common/actions';
import { Image } from 'common/components/image';
import { ROUTE_INBOX } from 'common/config/constants';
import variables from 'common/config/variables';
import { getClassName, getRemoteKeyName, interpolate } from 'common/utils';
import { useInterval, usePageNavigation } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useId, useState } from 'react';
import { connect } from 'react-redux';
import { MessageOptions, useMessage } from './Message';

interface MessagePromptItemProps {
  //Redux injected
  i18n: Translation;
  //
  id: string;
  message: MessageOptions;
}

function MessagePromptItem(props: MessagePromptItemProps) {
  const innerId = useId();
  const navigate = usePageNavigation();
  const { closeMessage } = useMessage();
  const [close, setClose] = useState(false);

  const { ref, focusSelf, focused } = useFocusable({
    focusKey: innerId,
    forceFocus: true,
    isFocusBoundary: true,
    onEnterPress: () => {
      closeMessage(props.id);
      if (!isEmpty(props.message.messageId) && !props.message.multiple) {
        navigate(`${ROUTE_INBOX}?id=${props.message.messageId}`);
      } else {
        navigate(ROUTE_INBOX);
      }
    }
  });

  useEffect(() => {
    focusSelf();
  }, []);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        setClose(true);
      }
    };
    if (focused) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focused]);

  const onAnimationEnd = useCallback(
    (e: React.AnimationEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (close && e.currentTarget === ref.current) {
        closeMessage(props.id);
      }
    },
    [close, props.id]
  );

  const onClose = useCallback(() => {
    setClose(true);
  }, []);
  useInterval(onClose, variables.AUTO_DISMISS_MESSAGE_TIMEOUT);

  return (
    <div
      ref={ref}
      className={getClassName('message-prompt-item', { close, shown: !close })}
      onAnimationEnd={onAnimationEnd}
    >
      <Image
        className="cover"
        src={(!props.message.multiple && props.message.cover) || DefaultMediaImage}
        fallbackSrc={DefaultMediaImage}
      />
      <div className="content-container">
        <span className="title">
          {props.message.multiple ? props.i18n.inbox.newMessages : props.i18n.inbox.newMessage}
        </span>
        <span className="subtitle">
          {props.message.multiple
            ? interpolate(props.i18n.inbox.youHaveUnreadMessage, [props.message.multiple])
            : props.message.title}
        </span>
        <div className="read-more">{props.i18n.inbox.readMore}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => {
  return {
    i18n
  };
};

export default React.memo(connect(mapStateToProps, {})(MessagePromptItem));
