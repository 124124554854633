import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';

const {
  SCREENSAVER_FADE_DURATION,
  SCREENSAVER_BACKGROUND_EFFECT_TIMING,
  SCREENSAVER_SUBTITLE_FADE_DELAY
} = variables;

GlobalStyle.inject`
  .screensaver {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: ${variables.color_grey_90};
  }

  .screensaver-slide {
    .screensaver-background {
      width: 100%;
      animation-duration: ${SCREENSAVER_BACKGROUND_EFFECT_TIMING}ms;
      animation-timing-function: ease-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0;
      mask-image: linear-gradient(transparent 5%, rgba(0, 0, 0, 0.3) 25% ,black 45%, black 60%, rgba(0, 0, 0, 0.2) 75%, transparent 85%);

      &.inactive {
        opacity: 0;
      }

      &.moveLeft {
        animation-name: moveLeft;
      }

      &.moveRight {
        animation-name: moveRight;
      }

      &.zoomOut {
        animation-name: zoomOut;
      }
    }

    .screensaver-top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: ${getScalablePixel(286)}px;
    }
    
    .screensaver-meta {
      padding-top: ${getScalablePixel(69)}px;
      padding-left: ${getScalablePixel(94)}px;
      color: white;

      .screensaver-title {
        color: ${variables.color_grey_10};
        font-size: ${getScalablePixel(22)}px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        opacity: 0;

        &.fadeIn {
          animation: ${SCREENSAVER_FADE_DURATION}ms ease ${SCREENSAVER_FADE_DURATION}ms normal forwards 1 fadeInText;
        }
        &.fadeOut {
          animation: ${SCREENSAVER_FADE_DURATION}ms ease 0s normal forwards 1 fadeOutText;
        }
      }
  
      .screensaver-subtitle {
        color: ${variables.color_grey_30};
        font-size: ${getScalablePixel(20)}px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
        opacity: 0;

        &.fadeIn {
          animation: ${SCREENSAVER_FADE_DURATION}ms ease ${SCREENSAVER_SUBTITLE_FADE_DELAY}ms normal forwards 1 fadeInText;
        }
        &.fadeOut {
          animation: ${SCREENSAVER_FADE_DURATION}ms ease 0s normal forwards 1 fadeOutText;
        }
      }
    }

    .screensaver-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: ${getScalablePixel(534)}px;
      padding-bottom: ${getScalablePixel(80)}px;
      padding-left: ${getScalablePixel(94)}px;

      .screensaver-info-text {
        position: absolute;
        bottom: ${getScalablePixel(80)}px;
        left: ${getScalablePixel(94)}px;
        color: ${variables.color_grey_50};
        font-size: ${getScalablePixel(24)}px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        .icon {
          max-width: ${getScalablePixel(32)}px;
          position: relative;
          top: ${getScalablePixel(10)}px;
          margin-right: ${getScalablePixel(12)}px;
        }
      }
    }

    .screensaver-product-logo {
      position: absolute;
      bottom: ${getScalablePixel(80)}px;
      right: ${getScalablePixel(100)}px;
      max-width: ${getScalablePixel(280)}px;
      width: 100%;
    }
  }

  @keyframes zoomOut {
    0% {
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  @keyframes moveLeft {
    0% {
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translate(-55%, -50%) scale(1.1);
      opacity: 0;
    }
  }

  @keyframes moveRight {
    0% {
      transform: translate(-50%, -50%) scale(1.1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translate(-45%, -50%) scale(1.1);
      opacity: 0;
    }
  }

  @keyframes fadeInText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOutText {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
