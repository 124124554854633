import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

const mapStateToProps = ({ authentication: { redirect, token } }) => ({ redirect, token });

const protect = (ComponentToProtect) => {
  // Creates a wrapping class that renders the
  // component to be protected only if the
  // token from the redux store is available.
  // If no token the app will redirect to a
  // provided route.
  function ProtectedComponent(props) {
    const navigate = useNavigate();

    useEffect(() => {
      if (isEmpty(props.token)) {
        navigate(props.redirect, { replace: true })
      }
    }, [props.redirect, props.token])


    return <ComponentToProtect />
  }

  return connect(mapStateToProps)(ProtectedComponent);
};

export default protect;
