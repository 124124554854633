import { combineReducers } from 'redux';

import appConfig from './appConfig';
import authentication from './authentication';
import carousel from './carousel';
import channelList from './channelList';
import channelSettings from './channelSettings';
import currentChannel from './currentChannel';
import detailedInfo from './detailedInfo';
import errors from './errors';
import favoriteChannels from './favoriteChannels';
import i18n from './i18n';
import idleTimer from './idleTimer';
import lastWatched from './lastWatched';
import liveCategories from './liveCategories';
import liveChannels from './liveChannels';
import logos from './logos';
import messages from './messages';
import mostWatched from './mostWatched';
import order from './order';
import prismic from './prismic';
import profileSettings from './profileSettings';
import recorded from './recorded';
import searchState from './searchState';
import serverTime from './serverTime';
import userDetails from './userDetails';
import videoPlayer from './videoPlayer';

/**
 * TOOD:
 * - convert all js to TypeScript
 */
const rootReducer = combineReducers({
  appConfig,
  authentication,
  currentChannel,
  errors,
  carousel,
  i18n,
  lastWatched,
  liveCategories,
  liveChannels,
  favoriteChannels,
  logos,
  mostWatched,
  order,
  profileSettings,
  channelSettings,
  recorded,
  searchState,
  serverTime,
  userDetails,
  videoPlayer,
  channelList,
  detailedInfo,
  prismic,
  messages,
  idleTimer
});

export default rootReducer;
