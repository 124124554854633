import variables from 'common/config/variables';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

GlobalStyle.inject`
.linear-view-container {
    background-color: ${variables.background_color_dark};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    .linear-page-wrapper {
        transition: opacity ${variables.PAGE_FADE_DURATION}ms;
        transition-delay: ${variables.DEFAULT_ANIMATION_DURATION}ms;
        opacity: 1;
        visibility: visible;
        will-change: opacity;
        &.hidden {
            opacity: 0;
            visibility: hidden;
            transition-delay: ${0}ms;
            /* Force remove 'mask-image' to fix opacity transition flicker */
            .carousel-view-container {
                mask-image: unset;
            }
        }

        &.has-background {
            background: ${variables.background_color_dark};
        }
    }

    .view-with-detail-wrapper {
        
        background: 
        linear-gradient(to bottom,${variables.background_color_dark} 65%, 
            ${hexToRGB(variables.background_color_dark, 0.8)} 80%, 
            ${hexToRGB(variables.background_color_dark, 0.5)} 140%),
        linear-gradient(to top, 
            ${hexToRGB(variables.background_color_dark, 0.8)} 5%,
            ${hexToRGB(variables.background_color_dark, 0.1)} 30%),
        linear-gradient(to right, 
            ${hexToRGB(variables.background_color_dark, 0.8)} 0%,transparent 20%);

        &.missing-detailed-info {
            background: ${hexToRGB(variables.background_color_dark, 0.9)};
        }
    }
}

.page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    z-index: 1;
    /* background-color: ${variables.background_color_dark}; */

    &.menu-shown {
        transform: translateX(-${variables.MENU_SIZE}px);
        /* hack to render the whole page */
        margin-right: -${variables.MENU_SIZE}px;
    }

    &.player-openned {
      visibility: hidden;
    }
}

.linear-player {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: z-index ${variables.PLAYER_EXPAND_DURATION}ms;
    z-index: 0;
    background: black;
    will-change: z-index, transform;

    -webkit-transform: translateZ(0);
  
    &.fullscreen {
      z-index: 2;
    }
}

`;
