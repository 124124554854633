import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
const { color_grey_10, color_grey_20, color_grey_30, color_grey_90 } = variables;

GlobalStyle.inject`
.idle-message-wrapper {
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;

  .idle-message-gradient-mask {
    background: radial-gradient(${hexToRGB(color_grey_90)} 25%, transparent 100%);
    width: 200%;
    height: 200%;
    transform: translate(-50%, 0%);
  }

  .idle-message-inner {
    position: absolute;
    bottom: ${getScalablePixel(54)}px;
    left: ${getScalablePixel(96)}px;
    padding: ${getScalablePixel(20)}px;
    font-size: ${getScalablePixel(22)}px;
  }

  .idle-message-title {
    font-size: ${getScalablePixel(36)}px;
    font-weight: 700;
    color: ${color_grey_10};
    text-transform: uppercase;
  }

  .idle-message-subtitle {
    font-size: ${getScalablePixel(24)}px;
    font-weight: 300;
    color: ${color_grey_30};
  }

  .idle-message-timer {

    @keyframes progress-out {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
    z-index: 1;
    margin-top: ${getScalablePixel(20)}px;
    position: relative;
    background: ${color_grey_30};
    border-radius: ${getScalablePixel(8)}px;
    padding: ${getScalablePixel(16)}px ${getScalablePixel(32)}px;
    font-weight: 500;
    max-width: ${getScalablePixel(360)}px;
    overflow: hidden;

    .idle-message-overlay-bg {
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${color_grey_20};  
      text-align: center;

      &.active {
        animation: progress-out ${variables.IDLE_MESSAGE_TIME}s linear forwards;
      }
    }

    .idle-message-text, .idle-message-time-remaining {
      position: relative;
      display: inline-block;
      color: ${color_grey_90};
      font-size: ${getScalablePixel(28)}px;
    }

    .idle-message-text {
      margin-left: ${getScalablePixel(65)}px;
    }

    .idle-message-time-remaining {
      min-width: ${getScalablePixel(65)}px;
      margin-left: ${getScalablePixel(30)}px;
    }
  }
}
`;
