import { translationPacket } from 'common/actions';
import { ClampContainer, H3, P1 } from 'common/components/Typography';
import { Column, Row } from 'common/components/common/style';
import {
  DescriptionStaff,
  DescriptionSubtitle,
  DescriptionTitle
} from 'common/components/description';
import MetadataStrip from 'common/components/metadata-strip/MetadataStrip';
import { MetadataSeparator } from 'common/components/metadata-strip/MetadataStrip.styles';
import variables from 'common/config/variables';
import { Epg, LiveChannels } from 'common/constants/data-types';
import { INextVideo } from 'common/reducers/videoPlayer';
import { getDateAndTimeFast, getDayOfWeek } from 'common/services/helpers';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { DescriptionContainer, DescriptionTitlesContainer } from './Controls.styles';
const { font_color_c } = variables;

interface DetailedInfoProps {
  // Redux injected
  i18n: typeof translationPacket;
  descriptionContainerVisible?: boolean;
  currentEpg?: Epg;
  liveChannels?: LiveChannels;
  //
  bottomViewVisible?: boolean;
}
function DetailedInfo(props: DetailedInfoProps) {
  const dateTime = useMemo(() => {
    try {
      if (!props.currentEpg || !props.i18n) {
        return undefined;
      }
      const { start, stop } = props.currentEpg;
      return getDateAndTimeFast(start, stop, props.i18n.months);
    } catch (error) {
      return undefined;
    }
  }, [props.currentEpg, props.i18n]);

  const dayOfWeek = useMemo(() => {
    if (!props.currentEpg || !props.i18n) {
      return '-';
    }
    return getDayOfWeek(props.currentEpg.start, props.i18n.days).toUpperCase();
  }, [props.currentEpg]);

  if (isEmpty(props.currentEpg) || isEmpty(props.liveChannels)) {
    return <></>;
  }

  return (
    <Column style={{ willChange: 'transform' }}>
      <Row justifyContent="flex-start" style={{ padding: `${getScalablePixel(16)}px 0` }}>
        <Column style={{ paddingRight: 30 }}>
          <Row justifyContent="flex-start">
            {<P1 style={{ color: font_color_c }}>{dayOfWeek}</P1>}
            <MetadataSeparator padding={8} size={getScalablePixel(32)} />
            {dateTime && (
              <H3 style={{ color: font_color_c }}>{`${
                dateTime.day
              } ${dateTime.month.toUpperCase()}`}</H3>
            )}
          </Row>
          {dateTime && (
            <DescriptionTitle fontWeight="normal">{`${dateTime.start} - ${dateTime.stop}`}</DescriptionTitle>
          )}
        </Column>
        <DescriptionTitlesContainer>
          <DescriptionSubtitle
            numeration={props.currentEpg.numeration}
            originaltitle={props.currentEpg.originaltitle}
          />
          <DescriptionTitle>{props.currentEpg.title}</DescriptionTitle>
        </DescriptionTitlesContainer>
      </Row>
      <MetadataStrip
        year={props.currentEpg.year}
        country={props.currentEpg.country}
        major_category={props.currentEpg.major_category}
        minor_categories={props.currentEpg.minor_categories}
        duration={props.currentEpg.duration}
        rating={props.currentEpg.rating}
        resolution={props.liveChannels[props.currentEpg.chan_id]?.resolution}
      />
      <DescriptionContainer visible={props.descriptionContainerVisible && !props.bottomViewVisible}>
        <ClampContainer lines={2}>
          <P1>
            {props.currentEpg.shorttext || props.currentEpg.fulltext || props.currentEpg.subtitle}
          </P1>
        </ClampContainer>
        <DescriptionStaff actors={props.currentEpg.actors} persons={props.currentEpg.persons} />
      </DescriptionContainer>
    </Column>
  );
}

const mapStateToProps = ({ i18n, videoPlayer, liveChannels }: any) => {
  const nextVideo: INextVideo | undefined = isEmpty(videoPlayer.nextVideo)
    ? undefined
    : videoPlayer.nextVideo;
  const currentEpg = isEmpty(videoPlayer.epgs?.current) ? undefined : videoPlayer.epgs.current;

  return {
    i18n,
    currentEpg:
      nextVideo && !isEmpty(liveChannels)
        ? liveChannels[nextVideo.channelId].currentepg
        : currentEpg,
    liveChannels,
    descriptionContainerVisible: videoPlayer.playerControlsState === 'expanded'
  };
};

export default React.memo(connect(mapStateToProps, {})(DetailedInfo));
