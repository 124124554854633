import variables from 'common/config/variables';
import { useCallback, useEffect, useRef, useState } from 'react';

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useNavigatorOnLine = () => {
  const [status, setStatus] = useState(getOnLineStatus());
  const [previousStatus, setPrevStatus] = useState(status);
  const timeoutConnectionRestoreRef = useRef<NodeJS.Timeout>();
  const debounceConnection = useRef<NodeJS.Timeout>();

  const setStatusDebounced = useCallback((clb: (a: boolean) => boolean) => {
    clearTimeout(debounceConnection.current);
    debounceConnection.current = setTimeout(() => {
      setStatus(clb);
    }, variables.LONG_THROTTLE_TIMEOUT);
  }, []);

  const setOnline = useCallback(() => {
    clearTimeout(debounceConnection.current);
    clearTimeout(timeoutConnectionRestoreRef.current);
    timeoutConnectionRestoreRef.current = setTimeout(
      () =>
        setStatusDebounced((prev) => {
          setPrevStatus(prev);
          return true;
        }),
      variables.TIMEOUT_AFTER_NETWORK_RESTORE
    );
  }, []);

  const setOffline = useCallback(
    () =>
      setStatusDebounced((prev) => {
        clearTimeout(timeoutConnectionRestoreRef.current);
        setPrevStatus(prev);
        return false;
      }),
    []
  );

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
      clearTimeout(debounceConnection.current);
      clearTimeout(timeoutConnectionRestoreRef.current);
    };
  }, []);

  return [status, previousStatus];
};

export default useNavigatorOnLine;
