export default {
  apiKey: 'AIzaSyDfLfvRyRQ2Ah31zzjtthbb-erqNM6ucK8',
  authDomain: 'bulsatcom-apps.firebaseapp.com',
  databaseURL: 'https://bulsatcom-apps.firebaseio.com',
  projectId: 'bulsatcom-apps',
  storageBucket: 'bulsatcom-apps.appspot.com',
  messagingSenderId: '293320168439',
  appId: '1:293320168439:web:081cd6496efd69a18714f2',
  measurementId: 'G-240P03N2H8'
};
