import { Column } from 'common/components/common/style';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import styled, { css } from 'styled-components';
const { background_color_darker } = variables;

export type PositionContent = 'top' | 'bottom' | 'center';

const PositionContentMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

interface DrawerContainerProps {
  visible?: boolean;
}
export const DrawerContainer = styled(Column)<DrawerContainerProps>`
  position: fixed;
  outline: none;
  top: 0;
  right: 0;
  z-index: 10;
  width: ${variables.DRAWER_CONTAINER_WIDTH}px;
  height: 100%;
  background: ${background_color_darker};
  ${({ visible }) =>
    visible &&
    css`
      box-shadow: ${getScalablePixel(-12)}px ${getScalablePixel(4)}px ${getScalablePixel(32)}px 0
        rgba(0, 0, 0, 0.3);
    `}
  transform: translate3d(${({ visible }) => (visible ? 0 : '100%')}, 0, 0);
  transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  flex: 1;
`;

export const DrawerElement = styled(Column)<{ positionContent?: PositionContent }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${background_color_darker};
  ${({ positionContent }) =>
    positionContent ? `justify-content: ${PositionContentMap[positionContent]};` : ''}
`;

interface DrawerOverlayProps {
  visible?: boolean;
}

export const DrawerOverlay = styled('div')<DrawerOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ visible }) => (visible ? 'rgba(0, 0, 0, 0.4)' : 'none')};
  z-index: ${({ visible }) => (visible ? '3' : '-1')};
  transition: background 0.3s ease;
`;
