import React, { useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import lockIcon from '@assets/media/lock-icon.svg';
import unlockIcon from '@assets/media/unlock-icon.svg';
import { setChannelSetting, setLockedPin, translationPacket } from 'common/actions';
import PinChange from 'common/components/keyboard-popups/PinChange';
import { LOGO_TYPES } from 'common/config/constants';
import { LiveChannels } from 'common/constants/data-types';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { ChannelSettings, ProfileSettings } from 'common/reducers/profileSettings';
import { getChannelLogo } from 'common/services/helpers';
import { sortByPosition } from 'common/utils';
import { usePinPopup } from 'common/utils/hooks/usePinPopup';
import { StoreState } from 'index';
import { ThunkActionDispatch } from 'redux-thunk';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';
import UnlockChannelsDrawer from './UnlockChannelsDrawer';

interface Props {
  i18n: typeof translationPacket;
  liveChannels: LiveChannels;
  logos: [];
  profileSettings: ProfileSettings;
  setChannelSetting: ThunkActionDispatch<typeof setChannelSetting>;
  setLockedPin: ThunkActionDispatch<typeof setLockedPin>;
}

function LockChannelsSettingsDrawer({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  setChannelSetting,
  setLockedPin
}: Props) {
  const { openDrawer } = useDrawer();
  const { openPopup } = usePopup();
  const { openPinPopup } = usePinPopup();

  const channelSettingsRef = useRef<ChannelSettings>({});

  const channelSettings = useMemo(() => {
    const channels = profileSettings?.react_tv_settings?.channels;
    channelSettingsRef.current = channels;
    return channels;
  }, [profileSettings?.react_tv_settings?.channels]);

  const liveChannelsArray = useMemo(
    () => sortByPosition(Object.values(liveChannels)),
    [liveChannels]
  );

  const openUnlockChannelsDrawer = useCallback(() => {
    openDrawer({
      id: 'unlock-channels-settings-drawer',
      content: <UnlockChannelsDrawer />,
      positionContent: 'top'
    });
  }, []);

  const onMenuItemPress = useCallback((settingItem: SettingsMenuItem) => {
    if (settingItem.type === MENU_ITEMS.innerMenuItem) return settingItem.onClick?.(settingItem);

    const item = channelSettingsRef.current[settingItem.id];
    const toggleLock = () => setChannelSetting(settingItem.id, 'locked', !item.locked);
    // If the channel is locked, open the pin popup
    item.locked ? openPinPopup({ onSuccess: toggleLock }) : toggleLock();
  }, []);

  const lockChannelsInnerMenuItems = useMemo(() => {
    return [
      {
        id: 'unlock-period',
        label: i18n?.mainSettings?.unlockPeriodTitle,
        onClick: openUnlockChannelsDrawer,
        type: MENU_ITEMS.innerMenuItem
      },
      {
        id: 'change-pin',
        label: i18n?.mainSettings?.changePIN,
        onClick: () => {
          openPopup({
            id: 'pin-change-popup',
            type: 'primary',
            preventBackspace: true,
            content: <PinChange popupId="pin-change-popup" onSuccess={setLockedPin} />
          });
          return null;
        },
        type: MENU_ITEMS.innerMenuItem
      }
    ];
  }, [i18n?.mainSettings?.unlockPeriodTitle, i18n?.mainSettings?.changePIN]);

  const lockChannelsItems = useMemo(() => {
    try {
      return liveChannelsArray.map((liveChannel) => {
        const { id, position, name } = liveChannel;

        const icon = {
          activeState: channelSettings[liveChannel.id]?.locked,
          activeIcon: lockIcon,
          inactiveIcon: unlockIcon
        };

        return {
          id,
          position,
          channelLogo: getChannelLogo(liveChannel.id, logos, LOGO_TYPES.png),
          name,
          icon,
          type: MENU_ITEMS.toggleOptionItem
        };
      });
    } catch (error) {
      console.warn(error);

      return [];
    }
  }, [channelSettings, logos]);

  return (
    <SettingsListDrawer
      drawerTitle={i18n.mainSettings.lockChannels}
      drawerSubtitle={i18n.mainSettings.lockChannelsSubmenuText}
      menuItems={[...lockChannelsInnerMenuItems, ...lockChannelsItems]}
      onMenuItemPress={onMenuItemPress}
    />
  );
}

const mapDispatchToProps = {
  setLockedPin,
  setChannelSetting
};

const mapStateToProps = ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  appConfig
}: StoreState) => ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  navigateMode: appConfig.navigateMode
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(LockChannelsSettingsDrawer));
