import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { Message } from 'common/reducers/messages';
import { getRemoteKeyName } from 'common/utils';
import { usePageNavigation, useQueryParams } from 'common/utils/hooks';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import MessageList from './MessageList';
import MessageView from './MessageView';

function InboxPage() {
  const { ref, focusKey, focusSelf, hasFocusedChild, focused } = useFocusable({
    forceFocus: true,
    trackChildren: true,
    autoRestoreFocus: true
  });
  const navigate = usePageNavigation();

  const [currentMessage, setCurrentMessage] = useState<Message | undefined>();
  const [activeMessage, setActiveMessage] = useState(true);
  const onMessageFocusDebounceRef = useRef<DebouncedFunc<VoidFunction>>();

  const hasMessage = useMemo(() => isEmpty(currentMessage), [currentMessage]);

  const query = useQueryParams();
  const initialMessageId = useMemo(() => query.get('id'), []);

  useEffect(() => {
    focusSelf();
  }, []);

  const goBack = useCallback(() => {
    navigate(-1, true);
  }, []);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        goBack();
      }
    };
    if (hasFocusedChild || focused) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild, focused]);

  const onMessageFocus = useCallback(
    (item: Message) => {
      onMessageFocusDebounceRef.current?.cancel();
      onMessageFocusDebounceRef.current = debounce(
        () => {
          setActiveMessage(true);
          setCurrentMessage(item);
        },
        hasMessage ? 0 : variables.DETAILED_INFO_THROTTLE_TIMEOUT
      );
      onMessageFocusDebounceRef.current();
    },
    [hasMessage]
  );

  const onMessageBlur = useCallback(() => {
    setActiveMessage(false);
  }, []);

  const onMessageEmpty = useCallback(() => {
    setCurrentMessage(undefined);
  }, []);

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className="inbox-page-container view-container">
        <MessageView message={currentMessage} maxLineSize={12} active={activeMessage} />
        <MessageList
          initialMessageId={initialMessageId}
          onMessageFocus={onMessageFocus}
          onMessageBlur={onMessageBlur}
          onMessageEmpty={onMessageEmpty}
          onBack={goBack}
        />
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n }: any) => {
  return {
    i18n
  };
};

export default React.memo(connect(mapStateToProps, {})(InboxPage));
