import CloseIcon from '@assets/media/close-button-icon.png';
import { Translation } from 'common/actions';
import { Button } from 'common/components/button';
import Icon from 'common/components/Icon';
import variables from 'common/config/variables';
import { Message } from 'common/reducers/messages';
import { getClassName } from 'common/utils';
import React, { useCallback } from 'react';

interface MessageItemProps {
  i18n: Translation;
  message: Message;
  focused?: boolean;
  activeForDelete?: boolean;
  activeForMoving?: boolean;
  onMoveFinish?: () => void;
}
function MessageItem(props: MessageItemProps) {
  const onTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLDivElement>) => {
      if (props.activeForMoving && e.propertyName !== 'opacity') {
        props.onMoveFinish && props.onMoveFinish();
      }
    },
    [props.activeForMoving, props.onMoveFinish]
  );

  return (
    <div
      className={getClassName('message-item', {
        read: props.message.read,
        'has-delete-overlay': props.activeForDelete,
        remove: props.activeForMoving
      })}
      onTransitionEnd={onTransitionEnd}
    >
      <div className="message-text-container">
        <span className="message-title">{props.message.title}</span>
        <span className="message-content">{props.message.content}</span>
      </div>
      <Icon
        className={getClassName('message-item-close-icon', {
          hidden: !props.focused || props.activeForDelete
        })}
        src={CloseIcon}
        size="large"
      />
      <div className={getClassName('delete-overlay', { active: props.activeForDelete })}>
        <Button
          title={props.i18n.inbox.delete}
          backgroundColor={variables.background_color_c}
          color={variables.background_color_dark}
          disabled
          fitContainer={false}
          size="medium"
          fontWeight={700}
        />
      </div>
    </div>
  );
}

export default MessageItem;
