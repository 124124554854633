import { ClampContainerMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { color_grey_20 } from 'common/config/variables/default';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
const {
  background_color_darker,
  background_color_e,
  font_color_c,
  font_color_e,
  font_color_f,
  font_color_white,
  MOUNT_FADEIN_TIMEOUT,
  STRIPE_ITEM_ANIMATION_DURATION,
  STRIPE_ITEM_FOCUSED_SCALE,
  STRIPE_ITEM_HEIGHT,
  STRIPE_ITEM_WIDTH,
  TEXT_CARD_HEIGHT,
  TEXT_CARD_WIDTH
} = variables;

GlobalStyle.inject`
.card-background,.live-card-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.live-card-background {
  position: absolute;
  top: 0;
  left: 0;
  // opacity: 0.35;
  transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
  will-change: opacity;
}
.live-card-logo {
  width: ${getScalablePixel(233)}px;
  height: ${getScalablePixel(82)}px;
}

.live-card-box {
  box-sizing: border-box;
  padding: ${getScalablePixel(11)}px ${getScalablePixel(18)}px
   ${getScalablePixel(9)}px ${getScalablePixel(18)}px;
  justify-content: space-between;
}

.card-box {
  display: flex;
  flex-direction: column;
  background-color: ${hexToRGB(variables.color_grey_70, 0.6)};
  width: ${STRIPE_ITEM_WIDTH}px;
  height: ${STRIPE_ITEM_HEIGHT}px;
  position: relative;
  overflow: hidden;
  will-change: contents;

  & * {
    box-sizing: border-box;
  }

  .live-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${variables.color_grey_90};
    transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
    will-change: opacity;
    opacity: 0.8;

    &.focused {
      opacity: 0;
    }
  }

  .live-card-info-container {
    
    transition: transform ${STRIPE_ITEM_ANIMATION_DURATION}ms;
    will-change: transform;
    transform-origin: top left;
    box-sizing: border-box;
    transform: scale3d(1, 1, 1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${font_color_white};
    font-weight: bold;
    font-size: ${getScalablePixel(30)}px;

    & * {
      box-sizing: border-box;
      z-index: 2;
    }

    &.focused {
      transform: scale3d(0.73, 0.73, 0.73);
    }

    &.locked {
      z-index: 5;
    }
  }

  .live-card-title {
    ${ClampContainerMixin({ lines: 1, wordBreak: 'break-all' })}
    font-size: ${getScalablePixel(28)}px;
    color: ${font_color_f};
    transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms, transform ${STRIPE_ITEM_ANIMATION_DURATION}ms;
    will-change: transform, opacity;
    transform-origin: top left;
    box-sizing: border-box;
    opacity: 1;
    
    &.focused {
      opacity: 0 !important;
      transform: translate3d(-5%, -150%, 0) scale3d(0.73, 0.73, 0.73);
    }
  }

  .card-blurred-overlay {
    position: absolute;
    bottom: -11%;
    left: -10%;
    right: -10%;
    height: 50%;
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 0.8) 15%,
      black 20%
    );
    background-position-y: 120%;
    background-position-x: center;
    background-size: 85%;
    background-color: ${hexToRGB(variables.color_grey_90, 0.8)};
    transition: background-image ${MOUNT_FADEIN_TIMEOUT}ms;
  }

  .card-bottom-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${getScalablePixel(67)}px;
    padding: ${getScalablePixel(6)}px;
    display: flex;
    align-items: center;
    color: white;
  }

  .card-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: ${getScalablePixel(9)}px;
  }

  .card-separator {
    width: ${getScalablePixel(1)}px;
    height: ${getScalablePixel(46)}px;
    background-color: white;
    margin-right: ${getScalablePixel(6)}px;
    opacity: 0.5;
  }

  .card-epg-container {
    justify-content: flex-start;
    display: inline-flex;
    align-items: center;
    margin-top: ${getScalablePixel(4)}px;
    margin-bottom: ${getScalablePixel(4)}px;
    font-size: ${getScalablePixel(16)}px;
    text-shadow: 0px 0px ${getScalablePixel(20)}px rgba(0, 0, 0, 1);
    color: ${font_color_c};
    white-space: nowrap;
  }

  .card-epg-separator {
    padding-left: ${getScalablePixel(6)}px;
    padding-right: ${getScalablePixel(6)}px;
    font-size: ${getScalablePixel(34)}px;
    line-height: 50%;
  }

  .card-title {
    ${ClampContainerMixin({ lines: 1, wordBreak: 'break-all' })}
    font-size: ${getScalablePixel(23)}px;
    font-weight: bold;
  }

  .card-logo {
    width: ${getScalablePixel(130)}px;
    height: ${getScalablePixel(60)}px;
  }
}


.text-card-container {
  width: ${TEXT_CARD_WIDTH}px;
  height: ${TEXT_CARD_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & * {
    z-index: 1;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  span {
    text-shadow: -10px 10px 8px rgba(0, 0, 0, 0.8);
    font-size: ${getScalablePixel(28)}px;
    font-weight: bold;
    color: ${font_color_white};
  }
}
.live-card-progress-container {
  height: ${getScalablePixel(6)}px;
  border-radius: ${getScalablePixel(9)}px;
  transition: transform ${STRIPE_ITEM_ANIMATION_DURATION}ms, 
    opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
  will-change: transform;
  overflow: hidden;
  opacity: 0.8;
  background-color: ${background_color_e};
  margin-top: ${getScalablePixel(15)}px;

  &.visible {
    transform: translate3d(0, ${getScalablePixel(20)}px, 0);
  }

  & .value {
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${font_color_f};
    height: 100%;
  }
}

.paid-info-overlay, .locked-info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${background_color_darker};
  z-index: 3;
  transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
  opacity: 0.8;
  will-change: opacity;

  &.focused {
    opacity: 0.5;
  }
}

.locked-info-overlay {
  opacity: 0.25 !important;
}

.paid-info, .locked-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${getScalablePixel(29)}px ${getScalablePixel(24)}px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 4;
  box-sizing: border-box;
  text-transform: uppercase;
  transition: transform ${STRIPE_ITEM_ANIMATION_DURATION}ms !important;
  will-change: transform;
  
  &.focused {
    transform: scale(${STRIPE_ITEM_FOCUSED_SCALE}) 
    translate3d(-${getScalablePixel(32)}px,
    -${getScalablePixel(8)}px,  0);

    .paid-mask-icon {
      transform: rotate(10deg);
    }
  }
}

.paid-mask, .locked-mask {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 100%;
  height: 120%;
  opacity: 0.8;
  background-color: ${background_color_darker};
  z-index: 2;
  transition: transform ${STRIPE_ITEM_ANIMATION_DURATION}ms;
  will-change: transform;
  transform: rotate(-65deg) translate3d(0, 0, 0);

  &.focused {
    transform: rotate(-65deg) translate3d(0, -15%, 0)
  }
}

.locked-mask {
  background-color: ${color_grey_20};
  opacity: 0.25;
}

.paid-info-title {
  font-weight: bold;
  font-size: ${getScalablePixel(21)}px;
  color: ${font_color_e};
  margin-bottom: ${getScalablePixel(4)}px;
}

.paid-info-subtitle {
  font-size: ${getScalablePixel(18)}px;
  color: ${font_color_c};
}
`;
