import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { FocusableItem } from 'common/interfaces';
import { getClassName } from 'common/utils';
import { useHoverable, useRipple } from 'common/utils/hooks';
import React, { PropsWithChildren } from 'react';

interface SimpleButtonProps extends FocusableItem {
  expandOnFocus?: boolean;
}
function SimpleButton(props: PropsWithChildren<SimpleButtonProps>) {
  const { ripples, showRipple } = useRipple({ background: 'rgba(255, 255, 255, 0.5)' });
  const { focused, ref } = useFocusable({
    focusable: !props.disabled,
    onFocus: props.onFocus,
    onEnterPress: (...args) => {
      !props.disabled && props.onEnterPress && props.onEnterPress(...args);
      !props.disabled && showRipple();
    },
    focusKey: props.focusKey
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  return (
    <div
      className={getClassName('simple-button', {
        focused: active,
        'expand-on-focus': props.expandOnFocus,
        disabled: props.disabled
      })}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(!props.disabled && (props.onEnterPress as any)) || undefined}
    >
      {ripples}
      {props.children}
    </div>
  );
}

export default React.memo(SimpleButton);
