import { Translation } from 'common/config/translations';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import {
  resetAllChannelsSettings,
  resetAllGlobalSettings,
  resetAllIndividualSettings,
  resetAllSettings,
  resetAudioForChannels,
  resetFavoriteChannels,
  resetGlobalAudioSetting,
  resetGlobalResolutionSetting,
  resetGlobalSubtitleSetting,
  resetLockedChannels,
  resetOrderChannels,
  resetResolutionForChannels,
  resetSubtitleForChannels,
  resetVisibleChannels,
  saveProfileSettings
} from 'common/actions';
import { LockedChannelPopup } from 'common/components/keyboard-popups';
import ConfirmResetPopup from 'common/components/keyboard-popups/ConfirmResetPopup';
import variables from 'common/config/variables';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { PromiseDispatch, StoreState } from 'index';
import { uniqueId } from 'lodash';
import { useSnackbar } from 'notistack';
import { ThunkActionDispatch } from 'redux-thunk';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';
interface SettingsDrawerProps {
  i18n: Translation;
  saveProfileSettings: PromiseDispatch;
  resetAllSettings: ThunkActionDispatch<typeof resetAllSettings>;
  resetAllChannelsSettings: ThunkActionDispatch<typeof resetAllChannelsSettings>;
  resetAllIndividualSettings: ThunkActionDispatch<typeof resetAllIndividualSettings>;
  resetFavoriteChannels: ThunkActionDispatch<typeof resetFavoriteChannels>;
  resetLockedChannels: ThunkActionDispatch<typeof resetLockedChannels>;
  resetVisibleChannels: ThunkActionDispatch<typeof resetVisibleChannels>;
  resetAllGlobalSettings: ThunkActionDispatch<typeof resetAllGlobalSettings>;
  resetGlobalAudioSetting: ThunkActionDispatch<typeof resetGlobalAudioSetting>;
  resetGlobalSubtitleSetting: ThunkActionDispatch<typeof resetGlobalSubtitleSetting>;
  resetGlobalResolutionSetting: ThunkActionDispatch<typeof resetGlobalResolutionSetting>;
  resetAudioForChannels: ThunkActionDispatch<typeof resetAudioForChannels>;
  resetSubtitleForChannels: ThunkActionDispatch<typeof resetSubtitleForChannels>;
  resetResolutionForChannels: ThunkActionDispatch<typeof resetResolutionForChannels>;
  resetOrderChannels: ThunkActionDispatch<typeof resetOrderChannels>;
}

function ResetSettingsDrawer(props: SettingsDrawerProps) {
  const { openDrawer } = useDrawer();
  const { openPopup } = usePopup();
  const { enqueueSnackbar } = useSnackbar();

  const openPinPopup = useCallback((onConfirm: VoidFunction) => {
    openPopup({
      id: 'locked-channels-pin-popup',
      type: 'primary',
      returnToLastFocused: false,
      onClose: () => {
        setFocus('DRAWER');
      },
      content: <LockedChannelPopup popupId="locked-channels-pin-popup" onSuccess={onConfirm} />
    });
  }, []);

  const openConfirmPopup = useCallback(
    (resetSettingName: string, onConfirm: () => void, requirePin = false) => {
      const handleConfirmation = async () => {
        onConfirm();
        // Save reset settings to profile
        await props.saveProfileSettings();
        enqueueSnackbar(props.i18n.mainSettings.resetSettingsSnackbar);
      };
      openPopup({
        id: 'CONFIRM-RESET-SETTINGS-POPUP',
        preventBackspace: true,
        returnToLastFocused: true,
        content: (
          <ConfirmResetPopup
            resetSettingName={resetSettingName}
            onConfirm={() => (requirePin ? openPinPopup(handleConfirmation) : handleConfirmation())}
          />
        )
      });
    },
    [openPinPopup, props.i18n.mainSettings]
  );

  const openSettingsDrawer = useCallback((title: string, menuItems: SettingsMenuItem[]) => {
    openDrawer({
      id: `reset-${uniqueId()}-settings-drawer`,
      content: <SettingsListDrawer drawerTitle={title} menuItems={menuItems} />,
      positionContent: 'top',
      onClose: props.saveProfileSettings
    });
  }, []);

  const buildResetMenuItem = useCallback(
    (title: string, onConfirm: () => void, subtitle?: string, requirePin = false) => {
      return {
        id: uniqueId('reset-menu-item'),
        label: title,
        subtitle,
        onClick: () => openConfirmPopup(title, onConfirm, requirePin),
        type: MENU_ITEMS.simpleMenuItem
      };
    },
    [openConfirmPopup]
  );

  const RESET_INDIVIDUAL_CATEGORY_LABEL = useMemo(
    () => ({
      id: 'reset_settings_one_by_one',
      type: MENU_ITEMS.categoryLabel,
      label: props.i18n.mainSettings.resetByCategory,
      disabled: true
    }),
    [props.i18n?.mainSettings]
  );

  const channelSettingsMenuItems = useMemo(
    () => [
      buildResetMenuItem(
        props.i18n.mainSettings.resetAll,
        props.resetAllChannelsSettings,
        props.i18n.mainSettings.resetCategories.resetAll,
        true
      ),
      RESET_INDIVIDUAL_CATEGORY_LABEL,
      buildResetMenuItem(props.i18n.mainSettings.favorite, props.resetFavoriteChannels),
      ...(variables.REORDER_CHANNELS_ENABLED
        ? [buildResetMenuItem(props.i18n.mainSettings.channelsReorder, props.resetOrderChannels)]
        : []),
      buildResetMenuItem(props.i18n.mainSettings.visible, props.resetVisibleChannels),
      buildResetMenuItem(props.i18n.mainSettings.locked, props.resetLockedChannels, undefined, true)
    ],
    [props.i18n?.mainSettings]
  );

  const openChannelSettingsMenu = useCallback(() => {
    openSettingsDrawer(props.i18n.mainSettings.channelSettings, channelSettingsMenuItems);
  }, [channelSettingsMenuItems, openSettingsDrawer]);

  const globalSettingsMenuItems = useMemo(
    () => [
      buildResetMenuItem(
        props.i18n.mainSettings.resetAll,
        props.resetAllGlobalSettings,
        props.i18n.mainSettings.resetCategories.globalSettings
      ),
      RESET_INDIVIDUAL_CATEGORY_LABEL,
      buildResetMenuItem(props.i18n.mainSettings.audio, props.resetGlobalAudioSetting),
      buildResetMenuItem(props.i18n.mainSettings.subtitles, props.resetGlobalSubtitleSetting),
      buildResetMenuItem(props.i18n.mainSettings.video, props.resetGlobalResolutionSetting)
    ],
    [props.i18n?.mainSettings]
  );

  const openGlobalSettingsMenu = useCallback(() => {
    openSettingsDrawer(props.i18n.mainSettings.globalSettings, globalSettingsMenuItems);
  }, [globalSettingsMenuItems, openSettingsDrawer]);

  const individualSettingsMenuItems = useMemo(
    () => [
      buildResetMenuItem(
        props.i18n.mainSettings.resetAll,
        props.resetAllIndividualSettings,
        props.i18n.mainSettings.resetCategories.individualSettings
      ),
      RESET_INDIVIDUAL_CATEGORY_LABEL,
      buildResetMenuItem(props.i18n.mainSettings.audio, props.resetAudioForChannels),
      buildResetMenuItem(props.i18n.mainSettings.subtitles, props.resetSubtitleForChannels),
      buildResetMenuItem(props.i18n.mainSettings.video, props.resetResolutionForChannels)
    ],
    [props.i18n?.mainSettings]
  );

  const openIndividualSettingsMenu = useCallback(() => {
    openSettingsDrawer(props.i18n.mainSettings.individualSettings, individualSettingsMenuItems);
  }, [individualSettingsMenuItems, openSettingsDrawer]);

  const resetSettingsMainMenu = useMemo(() => {
    return [
      {
        id: 'reset-all-settings',
        label: props.i18n.mainSettings.resetAll,
        subtitle: props.i18n.mainSettings.resetCategories.resetAll,
        type: MENU_ITEMS.simpleMenuItem,
        onClick: () =>
          openConfirmPopup(props.i18n.mainSettings.resetAll, props.resetAllSettings, true)
      },
      RESET_INDIVIDUAL_CATEGORY_LABEL,
      {
        id: 'reset-channels-settings',
        label: props.i18n.mainSettings.channelSettings,
        subtitle: props.i18n.mainSettings.resetCategories.channels,
        onClick: openChannelSettingsMenu,
        type: MENU_ITEMS.simpleMenuItem
      },
      {
        id: 'reset-global-settings',
        label: props.i18n.mainSettings.globalSettings,
        subtitle: props.i18n.mainSettings.resetCategories.globalSettings,
        onClick: openGlobalSettingsMenu,
        type: MENU_ITEMS.simpleMenuItem
      },
      {
        id: 'reset-individual-settings',
        label: props.i18n.mainSettings.individualSettings,
        subtitle: props.i18n.mainSettings.resetCategories.individualSettings,
        onClick: openIndividualSettingsMenu,
        type: MENU_ITEMS.simpleMenuItem
      }
    ];
  }, [
    props.i18n.mainSettings,
    openConfirmPopup,
    openChannelSettingsMenu,
    openGlobalSettingsMenu,
    openIndividualSettingsMenu
  ]);

  return (
    <div className="main-settings-container">
      <SettingsListDrawer
        drawerTitle={props.i18n?.mainSettings?.resetSettings}
        menuItems={resetSettingsMainMenu}
      />
    </div>
  );
}

const mapDispatchToProps = {
  saveProfileSettings,
  resetAllSettings,
  resetAllChannelsSettings,
  resetFavoriteChannels,
  resetLockedChannels,
  resetVisibleChannels,
  resetAllGlobalSettings,
  resetAllIndividualSettings,
  resetGlobalAudioSetting,
  resetGlobalSubtitleSetting,
  resetGlobalResolutionSetting,
  resetAudioForChannels,
  resetSubtitleForChannels,
  resetResolutionForChannels,
  resetOrderChannels
};

const mapStateToProps = ({ i18n }: StoreState) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ResetSettingsDrawer));
