import Icon, { IconSize } from 'common/components/Icon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import chevronDownIcon from '@assets/media/chevron-down-icon.svg';
import chevronUpIcon from '@assets/media/chevron-up-icon.svg';
import reorderIcon from '@assets/media/reordrer-icon.svg';

import { setChannelSetting, translationPacket } from 'common/actions';
import { LOGO_TYPES } from 'common/config/constants';
import variables from 'common/config/variables';
import { LiveChannels } from 'common/constants/data-types';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { ProfileSettings } from 'common/reducers/profileSettings';
import { getChannelLogo } from 'common/services/helpers';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import { ThunkActionDispatch } from 'redux-thunk';
import { ReorderIconWrapper } from './MainSettingsDrawer.styles';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';

interface Props {
  i18n: typeof translationPacket;
  liveChannels: LiveChannels;
  favoriteChannels: FavoriteChannels;
  logos: [];
  profileSettings: ProfileSettings;
  setChannelSetting: ThunkActionDispatch<typeof setChannelSetting>;
}

function ChannelsReorderDrawer({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  setChannelSetting,
  favoriteChannels
}: Props) {
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const activeItemIdRef = useRef<string | null>(activeItemId);
  const waitingForIdInPosition = useRef<{ id: string; position: number } | null>(null);
  const { channelsReorder, channelsReorderSubmenuText } = useMemo(() => {
    return i18n.mainSettings;
  }, [i18n.mainSettings]);

  const positionKey = useMemo(
    () => (variables.REORDER_CHANNELS_ENABLED ? 'position' : 'favoritePosition'),
    []
  );

  const channelIdsRef = useRef<string[]>([]);
  const channelIds = useMemo(() => {
    const channelsSettings = profileSettings?.react_tv_settings?.channels || {};
    const channelSettingsKeys = Object.keys(channelsSettings).reduce((acc, key) => {
      if (channelsSettings[key].favorite) acc.push(key);
      return acc;
    }, [] as string[]);
    const keys = variables.REORDER_CHANNELS_ENABLED
      ? Object.keys(liveChannels)
      : channelSettingsKeys;
    // If reorder channels is enabled use liveChannels, otherwise use favoriteChannels
    const ids = keys.sort((a, b) => {
      const channelSettingsA = channelsSettings?.[a]?.[positionKey];
      const channelSettingsB = channelsSettings?.[b]?.[positionKey];
      if (channelSettingsA && channelSettingsB) {
        return channelSettingsA - channelSettingsB;
      }
      return 0;
    });
    channelIdsRef.current = ids;
    if (
      waitingForIdInPosition.current &&
      ids.findIndex((id) => id === waitingForIdInPosition.current?.id) ===
        waitingForIdInPosition.current?.position - 1
    ) {
      setTimeout(() => {
        waitingForIdInPosition.current = null;
      }, variables.SHORT_THROTTLE);
    }
    return ids;
  }, [profileSettings?.react_tv_settings?.channels, liveChannels, favoriteChannels, positionKey]);

  useEffect(() => {
    activeItemIdRef.current = activeItemId;
  }, [activeItemId]);

  const handleItemPress = useCallback((settingItem: any) => {
    if (activeItemIdRef.current) {
      setActiveItemId(null);
      return;
    }
    setActiveItemId(settingItem.id);
  }, []);

  const handleArrowPress = useCallback(
    (direction: string) => {
      // If we are waiting for the id to be in the correct position, do not allow any other movement
      if (waitingForIdInPosition.current) return false;

      if (activeItemIdRef.current) {
        const activeItemIndex = channelIdsRef.current.findIndex(
          (id) => id === activeItemIdRef.current
        );
        const activeItemPosition = activeItemIndex + 1;
        const isFirst = direction === 'up' && activeItemIndex === 0;
        const isLast = direction === 'down' && activeItemIndex === channelIdsRef.current.length - 1;
        const disabledDirection = direction === 'left' || direction === 'right';

        if (isFirst || isLast || disabledDirection) {
          return true;
        }

        let offset = 0;
        if (direction === 'down') {
          offset = 1;
        }

        if (direction === 'up') {
          offset = -1;
        }
        const newPosition = activeItemPosition + offset;
        setChannelSetting(activeItemIdRef.current, positionKey, newPosition);
        setChannelSetting(
          channelIdsRef.current[activeItemIndex + offset],
          positionKey,
          activeItemPosition
        );
        waitingForIdInPosition.current = { id: activeItemIdRef.current, position: newPosition };
      }

      return true;
    },
    [positionKey]
  );

  const renderReorderIcon = useCallback(() => {
    return (
      <ReorderIconWrapper>
        <Icon size="medium" src={chevronUpIcon} />
        <Icon size="medium" src={chevronDownIcon} />
      </ReorderIconWrapper>
    );
  }, []);

  const channelsItems = useMemo(() => {
    return channelIds.map((id, index) => {
      const { name } = liveChannels[id];

      const icon = {
        activeState: activeItemId === id,
        activeIcon: renderReorderIcon(),
        inactiveIcon: reorderIcon,
        size: 'medium' as IconSize
      };

      return {
        id,
        position: index + 1,
        channelLogo: getChannelLogo(id, logos, LOGO_TYPES.png),
        name,
        type: MENU_ITEMS.toggleOptionItem,
        icon
      };
    });
  }, [liveChannels, logos, channelIds, activeItemId]);

  const channelsItemsAvailable = useMemo(() => !isEmpty(channelsItems), [channelsItems]);

  return (
    <SettingsListDrawer
      drawerTitle={channelsReorder}
      drawerSubtitle={channelsReorderSubmenuText}
      menuItems={channelsItems}
      onArrowPress={handleArrowPress}
      onMenuItemPress={handleItemPress}
      initiallyFocused={channelsItemsAvailable}
      getFocusKey={(_item: SettingsMenuItem, index: number) => `channel-reorder-item-${index}`}
    />
  );
}

const mapDispatchToProps = {
  setChannelSetting
};

const mapStateToProps = ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  favoriteChannels,
  appConfig
}: StoreState) => ({
  i18n,
  liveChannels,
  logos,
  profileSettings,
  navigateMode: appConfig.navigateMode,
  favoriteChannels
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ChannelsReorderDrawer));
