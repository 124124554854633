import FavoriteIcon from '@assets/media/favorite-icon.svg';
import InfoIcon from '@assets/media/info-icon.svg';
import MediaActionButtons from '@assets/media/media-action-buttons.svg';
import SevenDaysArchiveIcon from '@assets/media/seven-days-archive-icon.svg';
import TrialWelcomeArchiveIcon from '@assets/media/trial-welcome-archive-icon.svg';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Translation } from 'common/actions';
import { DefaultButton } from 'common/components/button';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { TrialModal } from 'common/components/trial';
import { ButtonContainerTrial, TrialWelcomeStyle } from 'common/components/trial/Trial.styles';
import { checkNeedsTrialReminderPopup } from 'common/components/trial/utils';
import { ROUTE_HOME } from 'common/config/constants';
import { usePopup } from 'common/contexts/popup';
import protect from 'common/HoC/protect';
import { LocalStorageTrialObject } from 'common/interfaces';
import { setTrialObject } from 'common/services/helpers';
import { getRemoteKeyName, getScalablePixel } from 'common/utils';
import { usePageNavigation } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const FeatureTitle = ({ image = '', translate = '', classHandler = '' }) => {
  return (
    <div className="element">
      <div className={`image ${classHandler}`}>
        <img src={image} alt="" />
      </div>
      <div className="feature-title">{translate}</div>
    </div>
  );
};

interface TrialProps {
  i18n: Translation;
  userDetails: any;
}

const TrialWelcome = ({ i18n, userDetails }: TrialProps) => {
  const { focusKey, hasFocusedChild, ref } = useFocusable({
    isFocusBoundary: true,
    autoRestoreFocus: true,
    trackChildren: true
  });
  const navigate = usePageNavigation();
  const { openPopup } = usePopup();

  //Init useEffect
  useEffect(() => {
    setFocus('WATCH_NOW_BUTTON');
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetails?.trial)) {
      const trialObject: LocalStorageTrialObject = {
        ...userDetails.trial,
        email: userDetails.email
      };
      setTrialObject(trialObject);
    }
  }, [userDetails]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const modifier = navigator.userAgent.match(/TV/i) ? true : e.ctrlKey;
      if (getRemoteKeyName(e.keyCode) === 'BACK' && modifier) {
        onEnterHome();
        return;
      }
    };
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild]);

  //Enter home button handler
  const onEnterHome = async () => {
    navigate(ROUTE_HOME);
    checkNeedsTrialReminderPopup(userDetails) &&
      openPopup({
        id: 'TRIAL-MODAL',
        returnToLastFocused: false,
        type: 'primary',
        content: <TrialModal />
      });
  };

  const getDurationText = () => {
    if (isEmpty(userDetails?.trial)) return '';
    const days = Math.ceil(userDetails.trial.duration / (24 * 60 * 60));
    return `${i18n.common.watch} ${days} ${days > 1 ? i18n.common.days : i18n.common.day} ${
      i18n.common.interactiveTelevision
    }`;
  };

  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <TrialWelcomeStyle ref={ref}>
        <div className="title">{i18n.trial.welcome}</div>
        <ProductLogo type="full" width={getScalablePixel(438)} />
        <div className="text anyware gray-30">{i18n.trial.anyware}</div>
        <div className="text trialDays gray-10">{getDurationText()}</div>
        <div className="text takeAdvatage gray-10">{i18n.trial.takeAdvantage}</div>
        <div className="emelemts">
          <FeatureTitle
            translate={i18n.trial.features.stopSeek}
            image={MediaActionButtons}
            classHandler="stopSeek"
          />
          <FeatureTitle
            translate={i18n.trial.features.sevenDaysBack}
            image={SevenDaysArchiveIcon}
          />
          <FeatureTitle translate={i18n.trial.features.archive} image={TrialWelcomeArchiveIcon} />
          <FeatureTitle translate={i18n.trial.features.favorite} image={FavoriteIcon} />
          <FeatureTitle translate={i18n.trial.features.extendedInfo} image={InfoIcon} />
        </div>
        <ButtonContainerTrial>
          <DefaultButton
            onEnterPress={onEnterHome}
            focusKey="WATCH_NOW_BUTTON"
            title={i18n.trial.watchNow}
          />
        </ButtonContainerTrial>
      </TrialWelcomeStyle>
    </FocusContext.Provider>
  );
};
const mapStateToProps = ({ i18n, userDetails }: any) => ({
  i18n,
  userDetails
});

export default React.memo(protect(connect(mapStateToProps, {})(TrialWelcome)));
