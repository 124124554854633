import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { RadioButton } from 'common/components/common/style';

import FocusableStripe, { FocusableStripeProps } from 'common/components/stripe/FocusableStripe';
import variables from 'common/config/variables';
import { ChannelListMenuItem, MENU_ITEMS } from 'common/constants/profile-settings-types';
import { AppConfigState } from 'common/reducers/appConfig';
import { ProfileSettings, SettingsKey } from 'common/reducers/profileSettings';
import { StoreState } from 'index';
import InnerSettingsMenuItem from './InnerSettingsMenuItem';
import InnerSettingsToggleChannel from './InnerSettingsToggleChannel';
import {
  MainSettingsDrawerInnerSubtitle,
  MainSettingsDrawerTitle,
  SettingsCategoryTitle
} from './MainSettingsDrawer.styles';

export interface SettingsMenuItem {
  id: string;
  label?: string;
  subtitle?: string;
  type: MENU_ITEMS;
  value?: string | number | null;
  onClick?: (item: SettingsMenuItem) => void;
}

interface Props extends Partial<FocusableStripeProps> {
  appConfig: AppConfigState;
  profileSettings: ProfileSettings;
  onMenuItemPress?: (item: SettingsMenuItem) => void;
  onArrowPress?: any;
  drawerTitle: string;
  drawerSubtitle?: string;
  menuItems: SettingsMenuItem[];
  selectedKey?: SettingsKey;
}

function SettingsListDrawer({
  onMenuItemPress,
  profileSettings,
  appConfig,
  drawerTitle,
  drawerSubtitle,
  menuItems,
  selectedKey,
  ...props
}: Props) {
  const scrollPadding = useMemo(() => variables.SETTINGS_LIST_ITEM_HEIGHT / 2, []);
  const onStripeItemPress = useCallback(
    (menuItem: SettingsMenuItem) => {
      // If specific menu item has a custom onClick handler, call it
      // Else call the default onMenuItemPress handler
      if (menuItem.onClick) {
        menuItem.onClick(menuItem);
      } else {
        onMenuItemPress?.(menuItem);
      }
    },
    [onMenuItemPress]
  );

  const checkCond = useCallback(
    (item: SettingsMenuItem) => {
      if (!selectedKey) return false;
      return item.value === profileSettings.react_tv_settings[selectedKey];
    },
    [profileSettings, selectedKey]
  );

  const renderMenuItem = useCallback(
    (item: SettingsMenuItem) => {
      switch (item.type) {
        case MENU_ITEMS.simpleMenuItem: {
          return (
            <InnerSettingsMenuItem
              key={item.id}
              id={item.id}
              title={item.label}
              subtitle={item.subtitle}
            />
          );
        }

        case MENU_ITEMS.radioMenuItem: {
          return (
            <div key={item.id} className="inner-settings-menu-item radio-menu-item" id={item.id}>
              <RadioButton checked={checkCond(item)} />
              {item.label}
            </div>
          );
        }

        case MENU_ITEMS.categoryLabel: {
          return <SettingsCategoryTitle key={item.label}>{item.label}</SettingsCategoryTitle>;
        }

        case MENU_ITEMS.innerMenuItem: {
          return <InnerSettingsMenuItem id={item.id} title={item.label} subtitle={item.subtitle} />;
        }

        case MENU_ITEMS.toggleOptionItem: {
          const { id, icon, position, channelLogo, name } = item as ChannelListMenuItem;

          return (
            <InnerSettingsToggleChannel
              id={id}
              icon={icon}
              position={position}
              channelLogo={channelLogo}
              name={name}
            />
          );
        }

        default: {
          return null;
        }
      }
    },
    [checkCond]
  );

  return (
    <div className="main-settings-container">
      <MainSettingsDrawerTitle>{drawerTitle}</MainSettingsDrawerTitle>
      {drawerSubtitle && (
        <MainSettingsDrawerInnerSubtitle dangerouslySetInnerHTML={{ __html: drawerSubtitle }} />
      )}
      <FocusableStripe
        focusKey={`SETTINGS_${drawerTitle}_DRAWER`}
        items={menuItems}
        align="center"
        onStripeItemPress={onStripeItemPress}
        initiallyFocused
        isFocusBoundary
        scrollPadding={scrollPadding}
        stripeItemClassName="settings-menu-item"
        {...props}
        navigateMode={appConfig.navigateMode}
        axis="y"
        renderItem={renderMenuItem}
      />
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = ({ profileSettings, appConfig }: StoreState) => ({
  profileSettings,
  appConfig
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SettingsListDrawer));
