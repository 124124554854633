import { ClampContainerMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

const { background_color_a, font_color_white, color_primary } = variables;

GlobalStyle.inject`
.tv-channels-category-list {
    opacity: 0.4;
    margin-bottom: ${getScalablePixel(29)}px;

    &.focused, &:hover {
      opacity: 1;
      
      .tv-channels-category-item {
        &.active {
          background: ${hexToRGB(background_color_a, 0.4)};

          .tv-channels-category-item-title {
            opacity: 0.4;
          }
        }
      }
    }

    .tv-channels-category-item {
      margin-right: ${getScalablePixel(16)}px;
      padding: ${getScalablePixel(12)}px ${getScalablePixel(16)}px;
      border-radius: ${getScalablePixel(4)}px;
      background: ${hexToRGB(background_color_a, 0.4)};
      opacity: 1;

      .tv-channels-category-item-title {
        ${ClampContainerMixin({ lines: 1 })}
        font-size: ${getScalablePixel(22)}px;
        color: ${font_color_white};
        opacity: 0.4;
      }

      &.focused, &:hover {
        background: ${hexToRGB(background_color_a)} !important;

        &.active {
          background: ${hexToRGB(background_color_a, 0.4)};
        }

        .tv-channels-category-item-title {
          opacity: 1 !important;
        }
      }
      
      &.active {
        box-shadow: inset 0 -${getScalablePixel(3)}px 0 0 ${color_primary};
        
        .tv-channels-category-item-title {
          opacity: 1;
        }
      }
    }
  }
`;
