import BackgroundImage from '@assets/media/login_background.png';
import { PlayerMaskMixin } from 'App.styles';
import { Image } from 'common/components/image';
import Video from 'common/components/video';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import styled from 'styled-components';
const {
  CAROUSEL_CONTAINER_HEIGHT,
  CAROUSEL_CONTAINER_HEIGHT_EXPANDED,
  DEFAULT_ANIMATION_DURATION,
  DETAILED_INFO_CONTAINER_HEIGHT,
  FASTSCROLL_TIMEOUT,
  MENU_SIZE,
  MINI_PLAYER_HEIGHT,
  MINI_PLAYER_WIDTH,
  MOUNT_FADEIN_TIMEOUT,
  background_color_dark,
  SAFE_AREA_VIEW_PADDING
} = variables;

GlobalStyle.inject`
.view-with-detail-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.view-details-header-container {
  position: relative;
  transform: translateY(${variables.VERTICAL_SAFE_AREA_VIEW_PADDING}px);
  padding-left: ${SAFE_AREA_VIEW_PADDING}px;
  z-index: 2;
}
.view-details-stripe-header-container {
  padding-left: ${SAFE_AREA_VIEW_PADDING}px;
}
.view-container, .stripe-view-container, .list-view, .view-container-with-background {
  background-color: ${background_color_dark};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.stripe-view-container {
  position: relative;
  will-change: transform, scroll-position;
  z-index: 1;
  background: initial;
  transition: transform ${DEFAULT_ANIMATION_DURATION}ms;
  
  &.carousel-expanded {
    transform: 
      translate3d(0,${CAROUSEL_CONTAINER_HEIGHT_EXPANDED - CAROUSEL_CONTAINER_HEIGHT}px, 0);
  }

  &.disabled-banners {
    transform: 
        translate3d(0,${variables.DETAILED_INFO_SAFE_AREA_PADDING}px, 0);

    &.detail-info-visible {
      transform: 
        translate3d(0,${CAROUSEL_CONTAINER_HEIGHT}px, 0);
    }
  }
}


.view-container-with-background {
  background: url(${BackgroundImage});
  background-size: contain;
  justify-content: center;
  align-items: center;
}

.detailed-info-container {
  width: 100%;
  height: ${DETAILED_INFO_CONTAINER_HEIGHT}px;
  background: transparent;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  opacity: 0.2;
  transition: opacity ${MOUNT_FADEIN_TIMEOUT}ms;
  will-change: opacity;
  z-index: 1;
  padding-top: ${variables.DETAILED_INFO_SAFE_AREA_PADDING}px;

  &.visible {
    opacity: 1;
  }
  
  &.has-header {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.miniplayer-thumbnail-mask {
  display: block;
  position: relative;
  width: ${MINI_PLAYER_WIDTH}px;
  height: ${MINI_PLAYER_HEIGHT}px;
  opacity: 0;
  transition: opacity ${MOUNT_FADEIN_TIMEOUT}ms;
  ${PlayerMaskMixin}

  &.visible {
    opacity: 1;
  transition: opacity 0ms;

  }
}
`;

export const MiniPlayerContainer = styled.div<{ fullscreen?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${MINI_PLAYER_WIDTH}px;
  height: ${MINI_PLAYER_HEIGHT}px;
`;
// ${({ fullscreen }) => fullscreen && 'z-index: 10;'}

export const MiniPlayerThumbnailMask = styled.div<{ visible?: boolean }>`
  display: block;
  position: relative;
  width: ${MINI_PLAYER_WIDTH}px;
  height: ${MINI_PLAYER_HEIGHT}px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${({ visible }) => (visible ? 0 : MOUNT_FADEIN_TIMEOUT)}ms;
  ${PlayerMaskMixin}
`;
export const MiniPlayerThumbnail = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const MiniPlayerStyled = styled(Video)<{ visible?: boolean }>`
  border-left: solid ${background_color_dark} ${getScalablePixel(50)}px;
  position: absolute;
  top: 0;
`;
