import PlayIndicator from '@assets/media/play-indicator.svg';
import {
  Column,
  FlexRowMixin,
  FocusableStyledComponentProps,
  Row
} from 'common/components/common/style';
import { Image } from 'common/components/image';
import {
  ClampContainer,
  ClampContainerMixin,
  H1,
  H2,
  P1,
  ParagraphMixin
} from 'common/components/Typography';
import variables from 'common/config/variables';
import { color_focused } from 'common/config/variables/default';
import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';
const {
  background_color_light,
  CONTROLS_EXPAND_DURATION,
  DEFAULT_ANIMATION_DURATION,
  font_color_b,
  font_color_dark,
  live_color,
  font_color_white,
  PLAYER_SAFE_AREA_VIEW_PADDING,
  color_secondary,
  background_color_d,
  background_color_lighter
} = variables;

GlobalStyle.inject`
.channel-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 0.5;
  flex-shrink: 1;
  flex-basis: 0%;
  background: linear-gradient(-90deg, rgba(24, 24, 24, 0) 0%, #181818 100%);
  max-width: 0;
  transition: all ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  transform: translate3d(-100%, 0, 0);
  margin-bottom: -${PLAYER_SAFE_AREA_VIEW_PADDING}px;
  height: 100vh;
  margin-left: 0;
  z-index: 2;

  &.visible {
    margin-right: -${PLAYER_SAFE_AREA_VIEW_PADDING}px;
    padding-bottom: ${PLAYER_SAFE_AREA_VIEW_PADDING}px;
    transform: translate3d(0, 0, 0);
    max-width: 100%;
  }
}

.controls-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: none;
  flex: 1;
  justify-content: space-between;
  visibility: hidden;

  &.visible {
    visibility: visible;
    position: relative;
    z-index: 2;
  }

  .top-view, .bottom-view {
    position: relative;
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: transform ${CONTROLS_EXPAND_DURATION}ms;
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); /* easeInQuad */
    will-change: transform;

    &.top-view {
      max-height: ${getScalablePixel(374)}px;
      padding: ${getScalablePixel(63)}px ${PLAYER_SAFE_AREA_VIEW_PADDING}px ${getScalablePixel(
  183
)}px
        ${PLAYER_SAFE_AREA_VIEW_PADDING}px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%);
      align-items: flex-start;
      transform: translate3d(0, -100%, 0);
      z-index: initial;

      &.visible {
        transform: translate3d(0, 0, 0);
      }
      &.fast-zapping {
        z-index: 1;
      }
      &.bottom-view-openned {
        z-index: 1;
      }
    }

    &.bottom-view {
      height: 100%;
      min-height: 0;
      max-height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 0.5) 25%,
        rgba(0, 0, 0, 0.7) 45%,
        rgba(0, 0, 0, 0.9) 70%
      );
      align-items: flex-end;
      transform: translate3d(0, +100%, 0);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        z-index: -1;
      }
  

      &.visible {
        transform: translate3d(0, 0, 0);
      }

      &.bottom-view-visible {
        transition: 
          transform ${variables.ROW_SCROLL_ANIMATION_DURATION}ms cubic-bezier(0.25, 1, 0.5, 1);
        transform: 
          translate3d(0, -95%, 0);
      }
    }
  }
}

.player-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  height: ${getScalablePixel(50)}px;
  margin: 0 ${getScalablePixel(14)}px;
}

.bottom-view-controls-container {
  position: relative;
  height: 100%;
  flex: 1;
  max-width: 100%;
  min-width: 0;
  max-height: 100%;
  box-sizing: border-box;

  .extended-bottom-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    /* Offset container by the safe area */
    margin-top: -${PLAYER_SAFE_AREA_VIEW_PADDING}px;
    position: relative;

    &.focused {
      .buttons-strip {
        margin-top: ${getScalablePixel(32)}px;
      }
    }

    .programme-toggle-container {
      position: relative;
      width: calc(100% - ${PLAYER_SAFE_AREA_VIEW_PADDING * 2}px);
      height: ${getScalablePixel(143)}px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      opacity: 0.4;
      transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
      background: 
      linear-gradient(270deg,
        transparent 0%,
        ${hexToRGB(variables.color_grey_60, 0.7)} 25%,
        ${hexToRGB(variables.color_grey_60, 0.9)} 50%,
        ${hexToRGB(variables.color_grey_60, 0.7)} 75%,
        transparent 100%);
      margin-bottom: ${getScalablePixel(12)}px;

      &.focused {
        opacity: 1;
        transform: scale(1.055);
      }

      &:not(.focused) {
        .toggle-state:not(.active) {
          .icon {
            transform: initial !important;
          }
        }
      }

      &.active {
        .selector {
          transform: translateX(100%);
          background: linear-gradient(to right, ${variables.color_grey_10} 85%, transparent 100%);
        }

        .toggle-state:not(.active) {
          transform: initial;
          
          .title-container {
            opacity: 0.4;
          }
          .icon {
            opacity: 1;
            transform: translateX(0);
          }

        }
        .toggle-state.active {
          transform: scale(1.3);

          .title-container {
            opacity: 1;
          }
          .icon {
            opacity: 0.2;
            transform: rotate(180deg) translateX(-50%);
          }
          
        }
      }

      .selector {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: ${getScalablePixel(4)}px;
        transition: transform ${DEFAULT_ANIMATION_DURATION}ms;
        background: linear-gradient(to left, ${variables.color_grey_10} 85%, transparent 100%);
      }

      .channel-logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .channel-logo {
          height: ${getScalablePixel(72)}px;
          width: auto;
        }

        .channel-name {
          font-size: ${getScalablePixel(20)}px;
          font-weight: light;
          color: ${variables.color_grey_40};
        }
      }

      .toggle-state {
        display: flex;
        flex-direction: row;
        transform: scale(1.3);
        transform-origin: left;
        flex: 1 0;
        transition: transform ${DEFAULT_ANIMATION_DURATION}ms;

        .icon {
          transition: transform ${DEFAULT_ANIMATION_DURATION}ms, opacity ${DEFAULT_ANIMATION_DURATION}ms;
          opacity: 0.2;
          transform: translateX(-50%);
        }


        &.active {
          justify-content: flex-end;
          transform: initial;
          transform-origin: right;
          text-align: end;
          
          .title-container {
            opacity: 0.4;
          }
          .icon {
            transform: rotate(180deg);
            opacity: 1;
          }
        }

        .title-container {
          display: flex;
          flex-direction: column;
          padding: 0 ${getScalablePixel(12)}px;
        }

        .title, .subtitle {
          color: ${variables.color_grey_10};
        }

        .title {
          font-size: ${getScalablePixel(24)}px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .subtitle {
          font-size: ${getScalablePixel(18)}px;
          font-weight: light;
          opacity: 0.6;
        }
      }
    }
    
    .buttons-strip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: ${getScalablePixel(48)}px;
      margin-bottom: ${getScalablePixel(32)}px;
      border-bottom: ${getScalablePixel(1)}px solid ${variables.color_grey_50};
      padding-bottom: ${getScalablePixel(8)}px;
      z-index: 1;

      .extended-bottom-view-button {
        border: none;
        margin: 0 ${getScalablePixel(8)}px;
        text-transform: uppercase;
        padding: ${getScalablePixel(16)}px ${getScalablePixel(32)}px;
        width: ${getScalablePixel(302)}px;
        box-sizing: border-box;
        color: ${variables.color_grey_40} !important;

        &:after {
          display: none;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: ${getScalablePixel(4)}px;
          background: ${variables.color_primary};
        }

        &.active, &.focused {
          color: ${variables.color_grey_10} !important;
        }

        &.active {
          &:after {
            display: block;
          }
        }
      }
    }

    .focusable-stripe-title {
      margin-left: ${PLAYER_SAFE_AREA_VIEW_PADDING}px;
    }
    .focusable-stripe-content {
      padding: ${variables.STRIPE_PADDING}px ${PLAYER_SAFE_AREA_VIEW_PADDING}px;
    }

    .info-page {
      display: flex;
      flex-direction: row;
      padding: 0 ${PLAYER_SAFE_AREA_VIEW_PADDING}px;
      min-width: -moz-available;
      min-width: -webkit-fill-available;
      min-width: fill-available;

      .cover-image-holder {
        flex: 1 0 auto;
        width: ${getScalablePixel(706)}px;
        height: ${getScalablePixel(388)}px;
        margin-right: ${getScalablePixel(48)}px;

        .cover-image {
          width: 100%;
          height: 100%;
        }

        .rating-holder {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
        }
      }

      .description-container {
        width: 100%;
        .description {
          font-size: ${getScalablePixel(24)}px;
          color: ${variables.color_grey_10};
          ${ClampContainerMixin({ lines: 13 })}
          padding-bottom: ${getScalablePixel(24)}px;
        }
        .staff {
          padding: ${getScalablePixel(24)}px 0;
          border-top: ${getScalablePixel(2)}px dashed ${variables.color_grey_50};
          border-bottom: ${getScalablePixel(2)}px dashed ${variables.color_grey_50};
        }
      }

    }
  }

  .fast-zapping-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
    position: relative;
    padding: 
      ${getScalablePixel(0)}px
      ${PLAYER_SAFE_AREA_VIEW_PADDING}px
      ${PLAYER_SAFE_AREA_VIEW_PADDING + getScalablePixel(1)}px;
    opacity: 1;
    & > * {
      transition: opacity ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
    }

    &.fast-zapping {
      & > * {
        opacity: 0.3;
      }
    }
  }

  .live-categories-strip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: ${getScalablePixel(16)}px;
    border-bottom: solid ${getScalablePixel(1)}px ${font_color_dark};
    visibility: hidden;
    max-height: 0;
    opacity: 1 !important;

    &.visible {
      visibility: visible;
      max-height: unset;
    }

    .live-categories-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding-right: 20%;
      overflow: hidden;
    }
  }

  .live-category {
    ${ParagraphMixin({})}
    font-size: ${getScalablePixel(22)}px;
    margin: 0 ${getScalablePixel(24)}px;
    white-space: nowrap;
    color: ${font_color_b};

    &.selected {
      color: ${font_color_white};
    }
  }
}

.seek-button-stripe {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 0;
  will-change: transform;
  
  &.visible {
    max-height: ${getScalablePixel(140)}px;
    display: flex;
  }
}

.progressbar-container {
  flex: 1;
  height: ${getScalablePixel(4)}px;
  background: ${background_color_d};
  position: relative;
  margin: ${getScalablePixel(8)}px;
  
  &.focused {
    & .value {
      transform: scale3d(1, 2, 1);
    }
  }

  & .value {
    position: absolute;
    height: 100%;
    transition: width ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
    background: ${color_secondary};
    transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
    will-change: transform;
    

    &.primary {
      background: ${color_secondary};
    }
    &.secondary {
      background: ${background_color_lighter};
    }
  }
}

.seekbar-container {
  ${FlexRowMixin}
  align-items: center;
  padding: ${getScalablePixel(28)}px 0;

  .seekbar-inner-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.seekbar-point {
  width: ${getScalablePixel(4)}px;
  height: ${getScalablePixel(4)}px;
  border-radius: 100%;
  background: white;
  position: absolute;
  top: 50%;
  z-index: 1;
  transform-origin: top;
  transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms,
    left ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  box-shadow: 0px 0px ${getScalablePixel(5)}px ${getScalablePixel(1)}px black;
  transform: translate3d(0, -50%, 0);

  &.focused {
    transform: scale3d(6, 6, 6) translate3d(0, -50%, 0);
  }
}


.player-overlay-container {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${variables.color_grey_90};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .spacer {
    flex: 0.5;
  }

  & > * {
    margin: ${getScalablePixel(24)}px 0;
  }

  & .channel-logo {
    width: ${getScalablePixel(650)}px;
    height: ${getScalablePixel(250)}px;
  }

  & .separator {
    height: ${getScalablePixel(2)}px;
    background-color: ${variables.color_grey_10};
    width: ${getScalablePixel(120)}px;
  }

  & .player-overlay-title {
    font-size: ${getScalablePixel(42)}px;
    color: ${variables.color_grey_10};
    text-transform: uppercase;
    font-weight: bold;
  }

  & .player-overlay-info {
    font-size: ${getScalablePixel(28)}px;
    color: ${variables.color_grey_10};
    opacity: 0.8;
  }

  &.locked-overlay-container {
    height: 100vh;

    .channel-logo {
      margin: auto;
      display: block;
      position: relative;
      top: ${getScalablePixel(-20)}px;
      top: 0;
      width: auto;
      height: ${getScalablePixel(160)}px;
    }
    .pin-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.live-icon {
  width: ${getScalablePixel(8)}px;
  height: ${getScalablePixel(8)}px;
  background: ${font_color_b};
  border-radius: ${getScalablePixel(32)}px;

  &.live {
    background: ${live_color};
  }
}


.rating-pie-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${getScalablePixel(16)}px;

  .circle-graph-container {
    position: relative;
    width: ${getScalablePixel(88)}px;
    height: ${getScalablePixel(88)}px;
    opacity: 0.6;

    .rating-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: ${getScalablePixel(34)}px;
      font-weight: bolder;
      color: ${variables.color_grey_10};

      &.disabled {
        color: ${variables.color_grey_30};
        opacity: 0.6;        
      }
    }
    
    .background {
      position: absolute;
      top: 0;
      left: 0;
    }

    .circle-graph {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .item {
      position: absolute;
      top: 34%;
      left: 30%;
    }
  }

  .title {
    margin-top: ${getScalablePixel(16)}px;
    font-size: ${getScalablePixel(22)}px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${variables.color_grey_30};
  }

  .subtitle {
    font-size: ${getScalablePixel(20)}px;
    font-weight: 400;
    text-transform: lowercase;
    color: ${variables.color_grey_50};
  }

}
.fast-seek-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity 0ms 0ms;

  flex: 0;
  justify-content: center;
  position: fixed;
  bottom: ${getScalablePixel(218)}px;
  left: 0;
  right: 0;
  transform: scale(1.2);
  &.visible {
    opacity: 1;
    transition: opacity ${CONTROLS_EXPAND_DURATION}ms ${CONTROLS_EXPAND_DURATION}ms;
  }

  .fast-seek-thumbnail-wrapper {
    margin: ${getScalablePixel(8)}px;
    flex: 1;
    background: transparent;
    width: ${getScalablePixel(300)}px;
    height: ${getScalablePixel(145)}px;
    overflow: hidden;

    &.primary {
      box-shadow: 0 ${getScalablePixel(16)}px ${getScalablePixel(64)}px 0 #000,
            0 0 ${getScalablePixel(32)}px 0 #000,
            0 ${getScalablePixel(4)}px ${getScalablePixel(24)}px 0 #000,
            0 0 ${getScalablePixel(12)}px 0;
      outline: solid 2px white;
      transform: scale(1.3);
      z-index: 2;

      .image {
        background: #000;
      }
    }
  }
}
`;

interface ContainerProps {
  visible?: boolean;
}

const HidableContainer = css<ContainerProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0ms;
  transition-property: max-height, padding;
  transition-duration: ${CONTROLS_EXPAND_DURATION}ms;
  will-change: max-height, padding;
  padding: 0;
`;

// width: ${getScalablePixel(258)}px;
export const StreamTimesContainer = styled.div<ContainerProps>`
  ${HidableContainer};
  padding-right: ${getScalablePixel(20)}px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${({ visible }) => (visible ? `${getScalablePixel(350)}px` : 0)};
  max-height: ${({ visible }) => (visible ? 'unset' : 0)};
`;

export const ChannelLogo = styled(Image)<{ visible?: boolean }>`
  height: ${getScalablePixel(86)}px;
  width: auto;
  opacity: ${({ visible }) => (visible ? 1 : 0)} !important;
`;

export const ChannelPositionContainer = styled(Row)`
  flex: 0;
`;

export const ChannelPositionText = styled(H1)`
  min-width: ${getScalablePixel(90)}px;
  text-align: right;
  font-weight: 600;
`;

export const SeekbarContainer = styled(Row)`
  align-items: center;
  padding: ${getScalablePixel(28)}px 0;
`;

export type EpgShowType = 'prev' | 'next' | 'future';

export const EpgShowContainer = styled(Row)`
  min-width: ${getScalablePixel(506)}px;
`;

export const EpgShowInfoContainer = styled(Column)<{ type: EpgShowType }>`
  width: ${getScalablePixel(248)}px;
  height: ${getScalablePixel(137)}px;
  padding: 0 ${getScalablePixel(16)}px;
  justify-content: flex-start;
  align-items: ${({ type }) => (type === 'prev' ? 'flex-start' : 'flex-end')};
  overflow: hidden;
`;

export const EpgShowThumbnail = styled.div<
  FocusableStyledComponentProps & { src: string; type: EpgShowType }
>`
  width: ${getScalablePixel(242)}px;
  height: ${getScalablePixel(137)}px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  position: relative;
  transition: background ${DEFAULT_ANIMATION_DURATION}ms, box-shadow ${DEFAULT_ANIMATION_DURATION}ms;
  box-shadow: ${({ focused }) =>
    focused ? `inset 0px 0px 0px ${getScalablePixel(4)}px ${color_focused}` : 'none'};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    z-index: 1;
    ${({ focused, type }) =>
      focused && type !== 'future'
        ? `background: url(${PlayIndicator}) no-repeat center;`
        : 'background: #0000005C;'}
  }
`;

export const DescriptionContainer = styled(Row).attrs((props: ContainerProps) => ({
  style: {
    display: props.visible ? 'flex' : 'none'
  }
}))<ContainerProps>`
  align-items: flex-start;
  & > ${ClampContainer}:first-child {
    padding-right: ${getScalablePixel(32)}px;
  }
  & > ${Column}:last-child {
    padding-right: ${getScalablePixel(8)}px;
    min-width: 30%;
    max-width: 30%;
    flex-basis: auto;
  }
`;

export const ChannelListButtonContainer = styled.div<
  FocusableStyledComponentProps & { mini?: boolean }
>`
  width: ${getScalablePixel(235)}px;
  height: ${getScalablePixel(144)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: solid 1px rgba(255, 255, 255, 0.19);
  border-top-width: 0px;
  box-sizing: border-box;
  padding: ${getScalablePixel(84)}px ${getScalablePixel(32)}px ${getScalablePixel(24)}px;
  border-bottom-right-radius: ${getScalablePixel(32)}px;
  border-bottom-left-radius: ${getScalablePixel(32)}px;
  background: linear-gradient(
    to top,
    ${({ focused }) => (focused ? 'rgba(255, 255, 255, 0.5)' : 'rgba(50,	47	,45, 1)')} -15%,
    rgba(255, 255, 255, 0) 90%
  );
  /* position absolutely center */
  position: absolute;
  top: 0;
  left: 50%;
  will-change: transform;
  transform: translate3d(-50%, ${({ mini }) => (mini ? '-80%' : 0)}, 0);
  transition: transform ${CONTROLS_EXPAND_DURATION}ms;
`;

export const PlayerSettingsDrawerTitle = styled(H2)`
  font-size: ${getScalablePixel(36)}px;
  padding: ${getScalablePixel(64)}px ${getScalablePixel(56)}px;
`;

export const PlayerSettingsDrawerSelection = styled(Row)<FocusableStyledComponentProps>`
  background: ${({ focused }) => (focused ? background_color_light : 'transparent')};
  transition: background ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
  justify-content: flex-start;
  padding: 0 ${getScalablePixel(56)}px;
  margin: ${getScalablePixel(4)}px 0;
  height: ${getScalablePixel(80)}px;
`;

export const PlayerSettingsDrawerSelectionTitle = styled(P1)`
  font-size: ${getScalablePixel(24)}px;
  padding: 0 ${getScalablePixel(10)}px;
`;

export const DescriptionTitlesContainer = styled(Column)`
  flex: 1;
  align-self: flex-end;
`;
