import DefaultCover from '@assets/media/no_info_cover.png';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import {
  getAllEpgsForSeries,
  playVideo,
  setPlayerControlsState,
  Translation
} from 'common/actions';
import { Button } from 'common/components/button';
import { Card } from 'common/components/cards';
import { DescriptionStaff } from 'common/components/description';
import { Image } from 'common/components/image';
import { Stripe, StripeView } from 'common/components/stripe';
import VerticalSeparator from 'common/components/VerticalSeparator';

import Chevron from '@assets/media/chevron.svg';
import Icon from 'common/components/Icon';
import { THUMBNAIL_OFFSET } from 'common/config/constants';
import variables from 'common/config/variables';
import { Epg, IViewWithDetailsStripeItem, LiveChannels } from 'common/constants/data-types';
import { GroupItemData } from 'common/interfaces';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import {
  getChannelLogo,
  getDayOfWeekAndDate,
  getStartOfDayDateObject,
  getThumbnailUrl,
  getToday
} from 'common/services/helpers';
import { getClassName, getScalablePixel } from 'common/utils';
import { useDebounce } from 'common/utils/hooks';
import _, { clamp, debounce, DebouncedFunc, isEmpty, uniqueId } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import RatingPie from './RatingPie';
import { reduceEpisodes } from './utils';

type TView = 'programme' | 'episode' | 'info' | string;

interface ProgrammeToggleProps {
  onToggle?: (active: boolean) => void;
  i18n: Translation;
  logo: string;
  active?: boolean;
  channelName: string;
}

// eslint-disable-next-line react/display-name
const ProgrammeToggle = React.memo((props: ProgrammeToggleProps) => {
  const { ref, focused } = useFocusable({
    onEnterPress: () => props.onToggle && props.onToggle(!props.active),
    onArrowPress: (direction: string) => {
      if (direction === 'right') props.onToggle && props.onToggle(true);
      else if (direction === 'left') props.onToggle && props.onToggle(false);
      return true;
    }
  });

  if (isEmpty(props.i18n)) return <></>;

  return (
    <div
      ref={ref}
      className={getClassName('programme-toggle-container', { focused, active: props.active })}
    >
      <div className="toggle-state">
        <Icon src={Chevron} size="verySmall" />
        <div className="title-container">
          <span className="title">{props.i18n.extendedBottomView.archive}</span>
          <span className="subtitle">{props.i18n.extendedBottomView.archiveSubtitle}</span>
        </div>
      </div>
      <div className="channel-logo-container">
        <Image className="channel-logo" src={props.logo} />
        <span className="channel-name">{props.channelName}</span>
      </div>
      <div className="toggle-state active">
        <div className="title-container">
          <span className="title">{props.i18n.extendedBottomView.upcoming}</span>
          <span className="subtitle">{props.i18n.extendedBottomView.upcomingSubtitle}</span>
        </div>
        <Icon src={Chevron} size="verySmall" />
      </div>
      <span className="selector" />
    </div>
  );
});

interface ButtonStripeProps {
  focusKey: string;
  activeType?: string | number;
  active?: boolean;
  list: { type: string | number; title: string }[];
  visible?: boolean;
  onActive?: (type: string | number) => void;
}
// eslint-disable-next-line react/display-name
const ButtonStrip = React.memo((props: ButtonStripeProps) => {
  const [isFocusBoundary, setIsFocusBoundary] = useState(false);
  const { ref, focusKey } = useFocusable({
    focusKey: props.focusKey,
    saveLastFocusedChild: false,
    isFocusBoundary,
    focusBoundaryDirections: ['down'],
    trackChildren: false,
    preferredChildFocusKey: props.activeType ? `${props.focusKey}-${props.activeType}` : undefined
  });
  const debounceRef = useRef<DebouncedFunc<VoidFunction>>();

  const renderList = () =>
    props.list.map((listItem) => {
      const onClick = () => props.onActive && props.onActive(listItem.type);
      const onFocus = () => {
        listItem.type !== props.activeType && setIsFocusBoundary(true);
        debounceRef.current?.cancel();
        debounceRef.current = debounce(() => {
          onClick();
          setIsFocusBoundary(false);
        }, variables.ROW_SCROLL_ANIMATION_DURATION);
        debounceRef.current();
      };

      return (
        <Button
          title={listItem.title}
          className={getClassName('extended-bottom-view-button', {
            active: props.activeType === listItem.type && props.active
          })}
          key={`extended-bottom-view-button-${listItem.type}`}
          focusKey={`${focusKey}-${listItem.type}`}
          size="large"
          onFocus={onFocus}
          onClick={onClick}
        />
      );
    });

  return (
    <FocusContext.Provider value={focusKey}>
      <div className="buttons-strip" ref={ref}>
        {props.visible && renderList()}
      </div>
    </FocusContext.Provider>
  );
});

interface ExtendedBottomViewProps {
  // Redux
  liveChannels: LiveChannels;
  logos: any;
  i18n: Translation;
  epgs: IVideoPlayer['allEpgs'];
  currentEpg: Epg;
  playVideo: (currentepg: Epg, options?: any) => void;
  isPlayerPlaying?: boolean;
  //
  visible?: boolean;
  closed?: boolean;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  onPlay?: VoidFunction;
}

function ExtendedBottomView(props: ExtendedBottomViewProps) {
  // Generate unique hash key every time the view is "closed"
  // So that the focus resets itself
  const [hashKey, setHashKey] = useState(uniqueId('extended-bottom-view'));
  const [view, setView] = useState<TView>('programme');
  const [episodeRows, setEpisodeRows] = useState<GroupItemData[]>([]);
  const [programmeFuture, setProgrammeFuture] = useState(false);
  const debouncedProgrammeFuture = useDebounce(programmeFuture, variables.LONG_THROTTLE_TIMEOUT);
  const { ref, hasFocusedChild, focusKey } = useFocusable({
    preferredChildFocusKey: 'extended-bottom-view-button-strip',
    trackChildren: true,
    focusBoundaryDirections: ['left', 'right'],
    saveLastFocusedChild: false,
    isFocusBoundary: true,
    onFocus: !props.closed ? props.onFocus : undefined,
    onBlur: !props.closed ? props.onBlur : undefined
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (props.closed) {
      timeout = setTimeout(() => {
        setView('programme');
        setHashKey(uniqueId('extended-bottom-view'));
        setProgrammeFuture(false);
      }, variables.LONG_THROTTLE_TIMEOUT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [props.closed]);

  const getProgrammeDay = useCallback(
    (dateString: string) => {
      if (isEmpty(props.i18n)) return 'NaN';

      try {
        const date = new Date(dateString);
        return getDayOfWeekAndDate(date, props.i18n.days, props.i18n.months, {
          today: props.i18n.common.today,
          yesterday: props.i18n.common.yesterday
        }).toUpperCase();
      } catch (error) {
        console.warn(error);
      }
      return dateString;
    },
    [props.i18n]
  );

  const isSeries = useMemo(
    () => !isEmpty(props.currentEpg) && props.currentEpg?.baseContentId != '0',
    [props.currentEpg?.baseContentId]
  );

  const programmeRows = useMemo(() => {
    const { epgs } = props;

    if (isEmpty(epgs) || isEmpty(props.i18n.common)) return [];
    const now = getToday().getTime() / 1000;
    if (epgs) {
      const formated = _(
        epgs
          .filter((e) => (debouncedProgrammeFuture ? e.start > now : e.start < now))
          .sort((a, b) => (debouncedProgrammeFuture ? a.start - b.start : b.start - a.start))
      )
        .groupBy((e) => getStartOfDayDateObject(Math.floor(e.start * 1000)).toDateString())
        .map((value, key) => {
          return {
            title: getProgrammeDay(key),
            items: value
          };
        })
        .value();
      return formated;
    }
    return [];
  }, [props.epgs, props.i18n.common, debouncedProgrammeFuture]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        const { currentEpg, i18n } = props;

        if (isEmpty(currentEpg) || isEmpty(i18n) || !isSeries || !props.isPlayerPlaying) return;

        const epgs = (await getAllEpgsForSeries(currentEpg.baseContentId)) as Epg[];

        if (epgs) {
          const now = getToday().getTime() / 1000;

          const unknownSeries: (Epg & { future?: boolean })[] = [];
          const knownSeries = epgs
            // Remove the unknown series from the main list
            // So it can be later added to another list
            .filter((e) => {
              if (e.numeration.SeasonNumber == 0) {
                unknownSeries.push({ ...e, future: e.start > now });
                return false;
              }
              return true;
            });

          const reducedEpgs = reduceEpisodes(knownSeries, props.liveChannels);

          const formated = _(
            reducedEpgs
              // Sort EPGs by episode number
              .sort((a, b) => a.numeration?.EpisodeNumber - b.numeration?.EpisodeNumber)
          )
            .groupBy((e) => e.numeration.SeasonNumber)
            .map((value, key) => {
              return {
                title: `${i18n.common.season.toUpperCase()} ${key}`,
                // Inject if the current epg is in the future
                items: value.map((e) => ({ ...e, future: e.start > now }))
              };
            })
            .value();

          setEpisodeRows([
            ...formated,
            // Append at the end the unknown series
            ...(!isEmpty(unknownSeries)
              ? [
                  {
                    title: props.i18n.common.other.toUpperCase(),
                    // Sort the unknown series by the start of the epg
                    items: unknownSeries.sort((a, b) => a.start - b.start)
                  }
                ]
              : [])
          ]);
        } else {
          setEpisodeRows([]);
        }
      } catch (error) {
        console.warn(error);
      }
    }, variables.LONG_THROTTLE_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [props.currentEpg, props.i18n, isSeries, props.isPlayerPlaying]);

  const buttonStripItems: { type: TView; title: string }[] = useMemo(
    () => [
      {
        type: 'programme',
        title: props.i18n.common.programme
      },
      ...(isSeries
        ? [
            {
              type: 'episode',
              title: props.i18n.common.episodes
            }
          ]
        : []),
      {
        type: 'info',
        title: props.i18n.common.information
      }
    ],
    [props.i18n, episodeRows, isSeries]
  );

  const coverImage = useMemo(() => {
    try {
      if (isEmpty(props.currentEpg)) return DefaultCover;
      const cover = props.currentEpg?.photos?.find((e) => e.type === 'cover');
      const wallpaper = props.currentEpg?.photos?.find((e) => e.type === 'wallpaper');
      return (
        cover?.url ||
        wallpaper?.url ||
        getThumbnailUrl(
          props.currentEpg.chan_id,
          props.currentEpg.now,
          props.currentEpg.start + THUMBNAIL_OFFSET
        )
      );
    } catch (error) {
      console.warn(error);
      return DefaultCover;
    }
  }, [props.currentEpg]);

  const openPlayer = useCallback(
    (epg: IViewWithDetailsStripeItem) => {
      const now = getToday().getTime() / 1000;
      if (epg.start > now) return;

      props.playVideo(epg, {
        startPosition: -1
      });
      props.onPlay && props.onPlay();
    },
    [props.onPlay]
  );

  const renderArchiveCard = useCallback(
    (item: Epg) => {
      return (
        <Card
          key={`player-bottom-view-card-${item.eventid}`}
          epg={item}
          isLive={!!item.now}
          showDate={false}
          logo={getChannelLogo(item.chan_id, props.logos)}
        />
      );
    },
    [props.logos]
  );

  const renderEpisodeCard = useCallback(
    (item: Epg & { future?: boolean }) => {
      let title = '';
      if (item.numeration.EpisodeNumber) {
        title = `${props.i18n?.common?.episode} ${item.numeration.EpisodeNumber}`.toUpperCase();
      } else if (item.now) {
        title = props.i18n?.common?.episodeAiring;
      } else {
        title = item.future
          ? props.i18n?.common?.episodeWillAirAt
          : props.i18n?.common?.episodeAiredAt;
      }
      return (
        <Card
          key={`player-bottom-view-episode-card-${item.eventid}`}
          epg={item}
          logo={getChannelLogo(item.chan_id, props.logos)}
          title={title}
        />
      );
    },
    [props.logos]
  );

  const renderProgrammeList = useCallback(() => {
    const chanId = props.currentEpg?.chan_id;
    return (
      <>
        <StripeView
          key="player-controls-programme-view"
          focusKey="player-controls-programme-view"
          saveFocusOnUnmount={false}
          stripeHeader={
            <ProgrammeToggle
              active={programmeFuture}
              onToggle={setProgrammeFuture}
              i18n={props.i18n}
              channelName={props.liveChannels[chanId]?.name}
              logo={getChannelLogo(props.currentEpg?.chan_id, props.logos)}
            />
          }
        >
          {programmeRows.map((row, index) => {
            const key = `player-bottom-view-programme-stripe-${index}-${debouncedProgrammeFuture}`;
            return (
              <Stripe
                key={key}
                title={row.title}
                onStripeItemPress={openPlayer}
                items={row.items}
                renderItem={renderArchiveCard}
              />
            );
          })}
        </StripeView>
      </>
    );
  }, [programmeRows, props.currentEpg, props.logos, programmeFuture, debouncedProgrammeFuture]);

  const renderEpisodeList = useCallback(() => {
    return (
      <StripeView
        key="player-controls-episode-list"
        focusKey="player-controls-episode-list"
        saveFocusOnUnmount={false}
      >
        {episodeRows.map((row, index) => {
          return (
            <Stripe
              key={`player-bottom-view-seasons-stripe-${index}`}
              title={row.title}
              onStripeItemPress={openPlayer}
              items={row.items}
              renderItem={renderEpisodeCard}
            />
          );
        })}
      </StripeView>
    );
  }, [episodeRows]);

  const renderInfoPage = useCallback(() => {
    if (isEmpty(props.currentEpg)) return <></>;
    const hasStaff = !isEmpty(props.currentEpg.persons) || !isEmpty(props.currentEpg.actors);

    const renderRatings = () => {
      if (isEmpty(props.currentEpg.rating)) return null;

      const entries = Object.entries(props.currentEpg.rating).filter(([key]) =>
        ['Action', 'Depth', 'Humor', 'Suspense'].includes(key)
      );

      // Skip rendering when every rate is 0
      if (entries.every(([key, value]) => !value)) return null;

      return entries.map(([key, value], index) => (
        <React.Fragment key={`${key}-${value}`}>
          <RatingPie
            rating={clamp(value, 0, 5)}
            title={props.i18n.rating.titles[key.toLowerCase()]}
          />
          {index < entries.length - 1 && <VerticalSeparator height={getScalablePixel(88)} />}
        </React.Fragment>
      ));
    };
    return (
      <div className="info-page">
        <div className="cover-image-holder">
          <Image src={coverImage} fallbackSrc={DefaultCover} className="cover-image" />
          {!isEmpty(props.currentEpg.rating) && (
            <div className="rating-holder">{renderRatings()}</div>
          )}
        </div>
        <div className="description-container">
          <span className="description">
            {props.currentEpg.fulltext || props.currentEpg.shorttext || props.currentEpg.subtitle}
          </span>
          {hasStaff && (
            <div className="staff">
              <DescriptionStaff
                align="left"
                persons={props.currentEpg.persons}
                actors={props.currentEpg.actors}
              />
            </div>
          )}
        </div>
      </div>
    );
  }, [props.currentEpg, coverImage, props.i18n.rating]);

  const renderView = () => {
    switch (view) {
      case 'episode':
        return renderEpisodeList();
      case 'programme':
        return renderProgrammeList();
      default:
      case 'info':
        return renderInfoPage();
    }
  };

  if (isEmpty(props.i18n)) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        className={getClassName('extended-bottom-view-container', { focused: hasFocusedChild })}
        ref={ref}
        key={hashKey}
      >
        <ButtonStrip
          focusKey="extended-bottom-view-button-strip"
          list={buttonStripItems}
          onActive={setView as any}
          activeType={view}
          visible={props.visible}
          active={hasFocusedChild}
        />
        {renderView()}
      </div>
    </FocusContext.Provider>
  );
}
const mapStateToProps = ({ liveChannels, logos, i18n, videoPlayer }: any) => ({
  liveChannels,
  logos,
  i18n,
  epgs: videoPlayer.epgs?.all,
  currentEpg: videoPlayer?.epgs?.current,
  isPlayerPlaying: videoPlayer.isPlaying
});

export default React.memo(
  connect(mapStateToProps, { setPlayerControlsState, playVideo })(ExtendedBottomView)
);
