import { SET_DETAILED_INFO_DATA } from 'common/constants/action-types';
import { DetailInfoStripeItem, Nullable } from 'common/interfaces';

export interface IDetailedInfo {
  asset?: Nullable<DetailInfoStripeItem>;
}

const INITIAL_STATE: IDetailedInfo = {
  asset: null
};
export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_DETAILED_INFO_DATA:
      return {
        ...state,
        asset: action.payload
      };
    default:
      return state;
  }
}
