import NoInternetIcon from '@assets/media/no-internet-icon.svg';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import {
  playVideoByEventId,
  playVideoByPosition,
  setVideoOptions,
  toggleCarousel,
  translationPacket
} from 'common/actions';
import { usePopup } from 'common/contexts/popup';
import { Banner, ICarousel } from 'common/reducers/carousel';
import { getClassName, getRemoteKeyName } from 'common/utils';
import { getPageName } from 'common/utils/helpers';
import { useOnlineStatus } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import './Carousel.styles';
import CarouselView from './CarouselView';
import Error from './Error';
import IFramePopupViewer from './IFramePopupViewer';

interface CarouselProps {
  customView?: React.ReactElement;
  visible?: boolean;
  disabled?: boolean;
  disableExpanding?: boolean;
  onEmptyCarousel?: (state?: boolean) => void;
  // Redux injected
  i18n: typeof translationPacket;
  carousel: ICarousel;
  toggleCarousel: (isExpanded: boolean) => void;
  playVideoByPosition: (position: number, options?: any) => void;
  playVideoByEventId: (eventid: string, options?: any) => Promise<number | void>;
  setVideoOptions: any;
  //
}
function Carousel({
  i18n,
  visible = true,
  disabled: disabledParam = false,
  onEmptyCarousel,
  carousel,
  toggleCarousel,
  playVideoByPosition,
  playVideoByEventId,
  setVideoOptions,
  customView,
  disableExpanding
}: CarouselProps) {
  const [isOnline] = useOnlineStatus();
  const disabled = useMemo(() => !isOnline || disabledParam, [disabledParam, isOnline]);
  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusable: !disabled,
    focusKey: 'CAROUSEL',
    preferredChildFocusKey: 'CAROUSEL_VIEW',
    trackChildren: true
  });
  const { openPopup } = usePopup();

  useEffect(() => {
    !disableExpanding && toggleCarousel(!hasFocusedChild);
  }, [hasFocusedChild, disableExpanding]);

  // Handle back navigation
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (getRemoteKeyName(e.keyCode) === 'BACK') {
        setFocus('MENU');
      }
    };
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild]);

  const pageBanners = useMemo(() => {
    if (isEmpty(carousel.banners)) return null;
    const rootPath = getPageName(location.pathname);
    return carousel.banners[rootPath];
  }, [carousel.banners, location.pathname]);

  useEffect(() => {
    onEmptyCarousel && onEmptyCarousel(isEmpty(pageBanners));
  }, [pageBanners, onEmptyCarousel]);

  const handleBannerSelection = useCallback(async (banner: Banner) => {
    if (!isEmpty(banner.tv_show_id) && banner.tv_show_id) {
      const status = await playVideoByEventId(banner.tv_show_id);
      status !== -1 &&
        setVideoOptions({
          isFullscreen: true
        });
    } else if (!isEmpty(banner.tv_channel_position) && banner.tv_channel_position) {
      playVideoByPosition(banner.tv_channel_position);
      setVideoOptions({
        isFullscreen: true
      });
    } else if (!isEmpty(banner.vod_asset_id) && banner.vod_asset_id) {
      //TODO:
      return;
    } else if (!isEmpty(banner.external_link?.url) && banner.external_link?.url) {
      openPopup({
        id: 'IFRAME_POPUP_VIEWER',
        content: <IFramePopupViewer popupId="IFRAME_POPUP_VIEWER" src={banner.external_link?.url} />
      });
    }
  }, []);

  const renderView = useCallback(() => {
    // Render 'no network error' banner
    if (!isOnline) {
      return (
        <Error
          icon={NoInternetIcon}
          title={i18n.common.noInternetTitle}
          subtitle={i18n.common.noInternetDescription}
        />
      );
    }
    return (
      <CarouselView
        expanded={!carousel.isExpanded}
        visible={visible}
        banners={pageBanners}
        customView={customView}
        disableExpanding={disableExpanding}
        onEnterPress={handleBannerSelection}
      />
    );
  }, [
    carousel.isExpanded,
    visible,
    pageBanners,
    customView,
    disableExpanding,
    isOnline,
    i18n,
    handleBannerSelection
  ]);

  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className={getClassName('carousel-container', { visible })}>
        {renderView()}
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ carousel, i18n }: any) => ({
  carousel,
  i18n
});

export default React.memo(
  connect(mapStateToProps, {
    toggleCarousel,
    playVideoByPosition,
    playVideoByEventId,
    setVideoOptions
  })(Carousel)
);
