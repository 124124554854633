import DefaultCover from '@assets/media/no_info_cover.png';
import { playVideo, resetVideo } from 'common/actions';
import { Image } from 'common/components/image';
import { HAS_ACCESS } from 'common/config/constants';
import variables from 'common/config/variables';
import { Epg, LiveChannels } from 'common/constants/data-types';
import { AppTheme } from 'common/interfaces';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getThumbnailTimestampWithOffset, getThumbnailUrl } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { useParentalLock, useRadioImage, useVisibilityState } from 'common/utils/hooks';
import { store } from 'index';
import { debounce, isEmpty, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { MiniPlayerContainer } from './ViewWithDetails.styles';
const { AUTOLOAD_TIMEOUT } = variables;

interface StateProps {
  videoIsPlaying?: boolean;
  videoIsFullscreen?: boolean;
  liveChannels: LiveChannels;
  logos: any;
}

interface MiniPlayerProps extends StateProps, Epg {
  isLiveCard?: boolean;
  active?: boolean;
  // Redux injected
  resetVideo: VoidFunction;
  playVideo: (currentepg: Epg, options?: any) => void;
  //
}

function MiniPlayer(props: MiniPlayerProps) {
  const isAppActive = useVisibilityState();
  const { locked: channelsLocked } = useParentalLock();

  useEffect(() => {
    if (variables.WHITELABEL_TYPE !== AppTheme.ON_DEMAND) return;
    let debouncedLoad: any = null;
    if (props.active && isAppActive) {
      handleResetVideo();
      debouncedLoad = debounce(loadVideo, AUTOLOAD_TIMEOUT);
      debouncedLoad();
    }
    return () => {
      debouncedLoad?.cancel();
    };
  }, [props.chan_id, props.start, props.stop, props.active, isAppActive]);

  const handleResetVideo = useCallback(() => {
    const { videoPlayer } = store.getState();
    const playingDifferentStream = !isEqual(
      { chan_id: props.chan_id, start: props.start, stop: props.stop },
      {
        chan_id: videoPlayer?.channelId,
        start: videoPlayer?.video?.start,
        stop: videoPlayer?.video?.stop
      }
    );
    if (playingDifferentStream && !videoPlayer.video?.isFullscreen) {
      props.resetVideo();
    }
  }, [props.chan_id, props.stop, props.start]);

  const locked = useMemo(() => {
    if (isEmpty(props.liveChannels)) return true;

    const locked = props.chan_id && props.liveChannels[props.chan_id]?.locked;

    return locked && channelsLocked;
  }, [props.chan_id, props.liveChannels, channelsLocked]);

  const loadVideo = useCallback(() => {
    if (props.access === HAS_ACCESS && !locked) {
      props.playVideo(props);
    }
  }, [props.chan_id, props.start, props.stop]);

  const radioImages = useRadioImage(props.chan_id);

  const image = useMemo(() => {
    return radioImages
      ? radioImages.radio_cover_image.url
      : getThumbnailUrl(
          props.chan_id,
          props.isLiveCard,
          getThumbnailTimestampWithOffset(props.start)
        );
  }, [props.chan_id, props.start, props.isLiveCard, radioImages]);

  const thumbnalVisible = useMemo(() => {
    if (variables.WHITELABEL_TYPE === AppTheme.LINEAR) return true;
    return !props.videoIsPlaying;
  }, [props.videoIsPlaying]);

  return (
    <MiniPlayerContainer>
      <div className={getClassName('miniplayer-thumbnail-mask', { visible: thumbnalVisible })}>
        <Image transition="fade" src={image} lazy fallbackSrc={DefaultCover} />
        {radioImages && (
          <Image className="radio-logo-overlay" src={props.logos[props.chan_id]?.pngWhite} />
        )}
      </div>
    </MiniPlayerContainer>
  );
}

const checkProps = (prevProps: MiniPlayerProps, nextProps: MiniPlayerProps) => {
  // Don't rerender when videoplayer changes if it's fullscreen
  if (nextProps.videoIsFullscreen) return true;
  return isEqual(prevProps, nextProps);
};

interface StoreProps {
  videoPlayer: IVideoPlayer;
  liveChannels: LiveChannels;
  logos: any;
}

const mapStateToProps = ({ videoPlayer, liveChannels, logos }: StoreProps) => ({
  videoIsPlaying: videoPlayer.isPlaying,
  videoIsFullscreen: videoPlayer?.video?.isFullscreen,
  liveChannels,
  logos
});
export default React.memo(
  connect(mapStateToProps, {
    playVideo,
    resetVideo
  })(MiniPlayer),
  checkProps as any
);
