import { Image } from 'common/components/image';
import React from 'react';
import './Card.styles';

interface TextCardProps {
  title: string;
  bgUrl?: string;
}

function TextCard({ title, bgUrl }: TextCardProps) {
  return (
    <div className="text-card-container">
      {bgUrl && <Image className="no-transition" src={bgUrl} />}
      <span>{title}</span>
    </div>
  );
}

export default React.memo(TextCard);
