import {
  Direction,
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails
} from '@noriginmedia/norigin-spatial-navigation';
import { Epg } from './constants/data-types';

export type ContentScrollingContentAxis = 'x' | 'y';

export type ContentAlign = 'start' | 'center' | 'end' | string;

export const ScrollDirectionPaddingMap = {
  x: 'right',
  y: 'bottom'
};

export const StartOffsetPaddingMap = {
  x: 'left',
  y: 'top'
};

export const ContentAxisAlignMap = {
  x: 'inline',
  y: 'block'
};

export const ScrollPaddingAxisMap = {
  x: 'paddingLeft',
  y: 'paddingTop'
};

export const ScrollPositionAxisMap = {
  x: 'scrollLeft',
  y: 'scrollTop'
};

export const WindowMap = { x: 'innerWidth', y: 'innerHeight' };

export const ContainerOffsetMap = { x: 'width', y: 'height' };

export const FlexDirectionAxisMap = {
  x: 'row',
  y: 'column'
};
export interface FocusableItem {
  focusKey?: string;
  stripeItemClassName?: string;
  initiallyFocused?: boolean;
  initialIndex?: { index: number; animateScroll?: boolean } | number;
  disabled?: boolean;
  isFocusBoundary?: boolean;
  focusBoundaryDirections?: Direction[];
  onArrowPress?: (direction: string, props: any, details: KeyPressDetails) => boolean;
  onEnterPress?: (props: object, details: KeyPressDetails) => void;
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
  onBlur?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
  onClick?: (props: object) => void;
  onHover?: (layout: FocusableComponentLayout, props: object) => void;
  onMouseLeave?: () => void;
}

type Item = any;
export type StripeItemData<T = { [key: string]: any }> = { id: string } & T;
export type GroupItemData<T = { [key: string]: any }> = { title: any; items: StripeItemData<T>[] };

export type Nullable<T> = T | null | undefined;

export interface DetailInfoStripeItem {
  epg: Epg;
  isLiveCard?: boolean;
  resolution: string;
  adult?: boolean;
}

export type RenderItemType<ItemT> = (
  item: StripeItemData & ItemT,
  index: number
) => React.ReactNode;

export type GetFocusKeyFunction<ItemT> = (item: StripeItemData & ItemT, index: number) => string;

export interface StripeItemEvents {
  onStripeItemPress?: (asset: any) => void;
  onStripeItemFocus?: (asset: any) => void;
  onStripeItemHover?: (asset: any) => void;
  onStripeItemBlur?: (asset?: any) => void;
}

export type LoadMoreOptions = {
  rowIndex: number;
  index: number;
};

export interface StripeData {
  title?: string;
  items?: StripeItemData[];
  groupItems?: GroupItemData[];
  renderItem: RenderItemType<Item>;
  renderGroupItem?: RenderItemType<Item>;
  getFocusKey?: GetFocusKeyFunction<Item>;
  visible?: boolean;
  loadMore?: (rowIndex: LoadMoreOptions) => void;
}

export enum AppTheme {
  LINEAR = 'linear',
  ON_DEMAND = 'on-demand'
}
export interface LocalStorageTrialObject {
  email: string;
  detailsFilled?: boolean;
  duration: number;
  // Timestamp
  expire: number;
  // Timestamp
  lastReminderShown?: number;
}

export type RemoteKeyName =
  | 'CHANNEL_LIST'
  | 'OK'
  | 'CHANNEL_UP'
  | 'CHANNEL_DOWN'
  | 'ARROW_UP'
  | 'ARROW_DOWN'
  | 'ARROW_LEFT'
  | 'ARROW_RIGHT'
  | 'BACK'
  | 'PLAY'
  | 'PAUSE'
  | 'STOP'
  | 'FASTFORWARD'
  | 'REWIND'
  | 'RED'
  | 'GREEN'
  | 'YELLOW'
  | 'BLUE'
  | 'TRACK_PREVIOUS'
  | 'TRACK_NEXT'
  | 'PLAY_PAUSE'
  | 'CANCEL'
  | 'BACKSPACE';
export const RemoteKeyCodeMap: { [code: number]: RemoteKeyName | undefined } = {
  10073: 'CHANNEL_LIST',
  458: 'CHANNEL_LIST',
  18: 'CHANNEL_LIST',
  13: 'OK',
  461: 'BACK',
  10009: 'BACK',
  8:
    navigator.userAgent.match(/TV/i) && !navigator.userAgent.match(/Philips/i)
      ? 'BACKSPACE'
      : 'BACK',
  37: 'ARROW_LEFT',
  38: 'ARROW_UP',
  39: 'ARROW_RIGHT',
  40: 'ARROW_DOWN',
  427: 'CHANNEL_UP',
  33: 'CHANNEL_UP',
  34: 'CHANNEL_DOWN',
  428: 'CHANNEL_DOWN',
  403: 'RED',
  404: 'GREEN',
  405: 'YELLOW',
  406: 'BLUE',
  415: 'PLAY',
  19: 'PAUSE',
  413: 'STOP',
  417: 'FASTFORWARD',
  412: 'REWIND',
  88: 'FASTFORWARD',
  90: 'REWIND',
  10232: 'TRACK_PREVIOUS',
  10233: 'TRACK_NEXT',
  10252: 'PLAY_PAUSE',
  65385: 'CANCEL'
};
