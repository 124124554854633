import { MOST_WATCHED_CLEAR_EPGS, MOST_WATCHED_GET_EPGS } from 'common/constants/action-types';
import request from 'common/services/request';
import { propagateChannelAccessToEpg } from 'common/utils/profile-settings';

export const getMostWatchedEpgs = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/metrics/top'
      });

      const { profileSettings } = getState();
      const epgsWithAccessRights = data.map(
        (epg) =>
          propagateChannelAccessToEpg(epg, profileSettings)
      );

      dispatch({
        type: MOST_WATCHED_GET_EPGS,
        payload: epgsWithAccessRights
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const updateMostWatchedEpgs = () => {
  return (dispatch, getState) => {
    try {
      const { profileSettings, mostWatched } = getState();

      const epgsWithAccessRights = (mostWatched || []).map(
        (epg) =>
          propagateChannelAccessToEpg(epg, profileSettings)
      );

      dispatch({
        type: MOST_WATCHED_GET_EPGS,
        payload: epgsWithAccessRights
      });
    } catch (error) {
      console.warn(error);
    }
  };
}

export const clearMostWatchedEpgs = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: MOST_WATCHED_CLEAR_EPGS
      });
    } catch (error) {
      console.warn(error);
    }
  };
};
