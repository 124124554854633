import { LockedChannelPopup } from 'common/components/keyboard-popups';
import { usePopup } from 'common/contexts/popup';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

const PIN_POPUP_ID = 'locked-channel-popup';

interface OpenPinPopupOptions {
  onSuccess: VoidFunction;
}

export const usePinPopup = () => {
  const { openPopup, closePopup, getPopup } = usePopup();

  const openPinPopup = useCallback(({ onSuccess }: OpenPinPopupOptions) => {
    openPopup({
      id: PIN_POPUP_ID,
      type: 'primary',
      preventBackspace: true,
      content: <LockedChannelPopup popupId="locked-channel-popup" onSuccess={onSuccess} />
    });
  }, []);

  const closePinPopup = useCallback(() => {
    closePopup(PIN_POPUP_ID);
  }, []);

  const checkOpened = useCallback(() => {
    return !isEmpty(getPopup(PIN_POPUP_ID));
  }, []);

  return {
    openPinPopup,
    closePinPopup,
    checkOpened
  };
};
