import { Row } from 'common/components/common/style';
import { H1 } from 'common/components/Typography';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

const DOWN_SCALE = 0.8;
const FULL_SCALE = 1;
const BOTH_TRANSLATE_Y_AMOUNT = -30;
const DATE_TRANSLATE_X_AMOUNT = 15;
const CLOCK_TRANSLATE_X_AMOUNT = 180;
const CLOCK_ANIMATION_DURATION = 200;
const DATE_ANIMATION_DURATION = 150;

/*
/ if clock has a class of always-show
/ we position it absolutely and animate
/ by toggling classes in component
*/

export const DateTimeContainer = styled(Row)`
  padding-left: ${getScalablePixel(14)}px;
  z-index: 2;

  &.always-show {
    position: fixed;
    right: ${getScalablePixel(30)}px;
    top: ${getScalablePixel(30)}px;
    right: ${getScalablePixel(60)}px;
    top: ${getScalablePixel(42)}px;
    padding-left: ${getScalablePixel(14)}px;
  }

  .date {
    text-transform: uppercase;
  }

  &.always-show,
  .time,
  .date {
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &.always-show,
  .time {
    animation-duration: ${CLOCK_ANIMATION_DURATION}ms;
  }

  .date {
    animation-duration: ${DATE_ANIMATION_DURATION}ms;
  }

  &.always-show.collapsed {
    animation-name: scaleDown;

    .time {
      animation-name: translateRight;
      animation-delay: ${CLOCK_ANIMATION_DURATION}ms;
    }

    .date {
      animation-name: hide;
      animation-delay: ${CLOCK_ANIMATION_DURATION}ms;
    }
  }

  &.always-show.expanded {
    animation-name: scaleUp;
    animation-delay: ${CLOCK_ANIMATION_DURATION}ms;

    .time {
      animation-name: translateLeft;
    }

    .date {
      animation-name: show;
      animation-delay: ${CLOCK_ANIMATION_DURATION}ms;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: translateY(0) scale(${FULL_SCALE});
    }

    100% {
      transform: translateY(${getScalablePixel(BOTH_TRANSLATE_Y_AMOUNT)}px) scale(${DOWN_SCALE});
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(${getScalablePixel(BOTH_TRANSLATE_Y_AMOUNT)}px) scale(${DOWN_SCALE});
    }

    100% {
      transform: translateY(0) scale(${FULL_SCALE});
    }
  }

  @keyframes hide {
    0% {
      opacity: 1;
      transform: translateX(0);
    }

    100% {
      opacity: 0;
      transform: translateX(${getScalablePixel(DATE_TRANSLATE_X_AMOUNT)}px);
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: translateX(${getScalablePixel(DATE_TRANSLATE_X_AMOUNT)}px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes translateRight {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(${getScalablePixel(CLOCK_TRANSLATE_X_AMOUNT)}px);
    }
  }

  @keyframes translateLeft {
    0% {
      transform: translateX(${getScalablePixel(CLOCK_TRANSLATE_X_AMOUNT)}px);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

export type DateTimeSize = 'small' | 'large';
const FontSizeMap = {
  small: 44,
  large: 60
};
const MinWidthMap = {
  small: 103,
  large: 162
};

export const TimeClock = styled(H1)<{ size: DateTimeSize }>`
  font-size: ${({ size }) => getScalablePixel(FontSizeMap[size])}px;
  padding-right: ${getScalablePixel(6)}px;
  ${({ size }) => (size === 'small' ? 'font-weight: normal;' : 'font-weight: 600;')}
  min-width: ${({ size }) => getScalablePixel(MinWidthMap[size])}px;
  text-align: center;

  &.small {
    font-size: ${getScalablePixel(44)}px;
  }
`;
