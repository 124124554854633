import { AppTheme } from 'common/interfaces';

export const color_primary = '#309933';
export const color_secondary = '#60BC63';

export const DEFAULT_VIEWMAP = '996f54e0ed61199ac26184d1a05faa0e30f46159';

export const DEFAULT_LOGO_PATH = 'https://portal.playtv.bg/channel-logos/test-350_playtv.png';

export const WHITELABEL_TYPE = AppTheme.LINEAR;
