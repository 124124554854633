import {
  USER_GET_PROFILE_SETTINGS,
  USER_REMOVE_PROFILE_SETTINGS,
  USER_RESET_PROFILE_SETTINGS,
  USER_SAVE_PROFILE_SETTINGS,
  USER_SET_CHANNEL_PROFILE_SETTING,
  USER_SET_PROFILE_SETTING,
  USER_SET_PROFILE_SETTINGS
} from 'common/constants/action-types';
import {
  ChannelSetting,
  ChannelSettingKeys,
  ChannelSettingValues,
  INITIAL_STATE,
  ProfileSettings,
  SettingsKey
} from 'common/reducers/profileSettings';
import request from 'common/services/request';
import { changeWebSetting } from 'common/utils/helpers';
import { generateChannelSettings, generateDefaultSettings } from 'common/utils/profile-settings';
import { AppDispatch, StoreState } from 'index';
import { cloneDeep, isEmpty } from 'lodash';
import { getLiveChannels, getRawLiveChannels } from './live-channels';

export const getProfileSettings = () => {
  return async (dispatch: AppDispatch, getState: () => StoreState) => {
    try {
      const { data } = await request({
        method: 'get',
        url: '/users/me/profile_settings'
      });
      let channelSettings = data?.react_tv_settings?.channels;
      if (isEmpty(channelSettings)) {
        const liveChannelsFromState = getState().liveChannels;
        const liveChannels = isEmpty(liveChannelsFromState)
          ? await dispatch(getLiveChannels())
          : liveChannelsFromState;
        // Populate the profile settings with default values if empty
        channelSettings = generateChannelSettings(data, liveChannels);
      }

      const profileSettings = {
        ...INITIAL_STATE,
        ...data,
        react_tv_settings: {
          ...INITIAL_STATE.react_tv_settings,
          ...data.react_tv_settings,
          channels: { ...channelSettings }
        }
      };
      dispatch({
        type: USER_GET_PROFILE_SETTINGS,
        payload: {
          ...changeWebSetting(profileSettings, {
            channels: profileSettings.react_tv_settings.channels
          })
        }
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const saveProfileSettings = () => {
  return async (dispatch: AppDispatch, getState: () => StoreState) => {
    try {
      const profileSettings = getState().profileSettings;

      const { data } = await request({
        method: 'post',
        url: '/users/me/profile_settings',
        data: {
          ...profileSettings
        }
      });

      dispatch({
        type: USER_SAVE_PROFILE_SETTINGS,
        payload: data
      });
      return data as ProfileSettings;
    } catch (error) {
      console.warn(error);
    }
  };
};

export const setProfileSetting = (settingName: SettingsKey, settingValue: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: USER_SET_PROFILE_SETTING,
      payload: { key: settingName, value: settingValue }
    });
  };
};

export const setChannelSetting = (
  id: string,
  key: ChannelSettingKeys,
  value: ChannelSettingValues
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: USER_SET_CHANNEL_PROFILE_SETTING,
      payload: { id, key, value }
    });
  };
};

export const resetAllSettings = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const liveChannels = await getRawLiveChannels();
      // Populate the profile settings with default values if empty
      const settings = generateDefaultSettings(liveChannels);

      dispatch({
        type: USER_RESET_PROFILE_SETTINGS,
        payload: settings
      });
      await dispatch(saveProfileSettings());
    } catch (error) {
      console.warn(error);
    }
  };
};

export const clearProfileSettings = () => (dispatch: AppDispatch) => {
  dispatch({
    type: USER_REMOVE_PROFILE_SETTINGS,
    payload: {}
  });
};

/**
 * Helper function to manipulate each channel setting for other dispatch functions
 * @param callbackFunction - function to execute manipulation for each channel setting
 */
const resetChannelsSetting =
  (callbackFunction: (channel: ChannelSetting) => void) =>
  (dispatch: AppDispatch, getState: () => StoreState) => {
    const profileSettings = cloneDeep(getState().profileSettings) as ProfileSettings;
    if (
      isEmpty(profileSettings) ||
      isEmpty(profileSettings?.react_tv_settings) ||
      isEmpty(profileSettings?.react_tv_settings?.channels)
    ) {
      return;
    }
    Object.keys(profileSettings.react_tv_settings.channels).forEach((channelId) => {
      callbackFunction(profileSettings.react_tv_settings.channels[channelId]);
    });

    dispatch({
      type: USER_SET_PROFILE_SETTINGS,
      payload: profileSettings
    });
  };

export const resetFavoriteChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.favorite = false;
      channel.favoritePosition = 0;
    })
  );

export const resetLockedChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.locked = false;
    })
  );

export const resetVisibleChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.visible = true;
    })
  );

export const resetOrderChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.position = null;
    })
  );
/**
 * Dispatch function that call all reset functions for all channels settings.
 * This includes resetting `favorite`, `locked` and `visible` flags for all individual channels.
 * To remove all settings for all channels, use `resetAllSettings` instead.
 */
export const resetAllChannelsSettings = () => (dispatch: AppDispatch) => {
  dispatch(resetFavoriteChannels());
  dispatch(resetLockedChannels());
  dispatch(resetVisibleChannels());
  dispatch(resetOrderChannels());
};

export const resetSubtitleForChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.subtitles = null;
    })
  );

export const resetAudioForChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.audio = null;
    })
  );

export const resetResolutionForChannels = () => (dispatch: AppDispatch) =>
  dispatch(
    resetChannelsSetting((channel) => {
      channel.resolution = null;
    })
  );

export const resetAllIndividualSettings = () => (dispatch: AppDispatch) => {
  dispatch(resetSubtitleForChannels());
  dispatch(resetAudioForChannels());
  dispatch(resetResolutionForChannels());
};

export const resetGlobalAudioSetting = () => (dispatch: AppDispatch) =>
  dispatch(setProfileSetting('audio', null));

export const resetGlobalSubtitleSetting = () => (dispatch: AppDispatch) =>
  dispatch(setProfileSetting('subtitles', null));

export const resetGlobalResolutionSetting = () => (dispatch: AppDispatch) =>
  dispatch(setProfileSetting('resolution', -1));

/**
 * Dispatch function that call all reset functions for all global settings.
 * This includes resetting `audio`, `subtitles` and `resolution` settings for all channels.
 */
export const resetAllGlobalSettings = () => (dispatch: AppDispatch) => {
  dispatch(resetGlobalAudioSetting());
  dispatch(resetGlobalSubtitleSetting());
  dispatch(resetGlobalResolutionSetting());
};
