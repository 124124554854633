import { useEffect, useState } from 'react';

interface VisibilityChangeOptions {
  onHidden?: () => void;
  onVisible?: () => void;
}

export default function useVisibilityState(options?: VisibilityChangeOptions) {
  const [visible, setIsVisible] = useState(!document.hidden);

  useEffect(() => {
    const onChange = () => {
      setIsVisible(!document.hidden);
      if (document.hidden) {
        options?.onHidden && options.onHidden();
      } else {
        options?.onVisible && options.onVisible();
      }
    };
    setIsVisible(!document.hidden);
    document.addEventListener('visibilitychange', onChange);
    return () => {
      document.removeEventListener('visibilitychange', onChange);
    };
  }, [options]);

  return visible;
}
