import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { FocusableItem } from 'common/interfaces';
import React, { CSSProperties, useMemo } from 'react';
//@ts-ignore
// organize-imports-disable-above
import Icon from 'common/components/Icon';
import variables from 'common/config/variables';
import { getClassName } from 'common/utils';
import { useHoverable, useRipple } from 'common/utils/hooks';
import { StyledButtonProps } from './Button.styles';
const { font_color_white } = variables;

export interface ButtonProps extends FocusableItem, Partial<StyledButtonProps> {
  title: string;
  icon?: string | React.ReactElement;
  className?: string;
}

function Button(props: ButtonProps) {
  const { ripples, showRipple } = useRipple();
  const { focused, ref, focusKey } = useFocusable({
    focusable: !props.disabled,
    onFocus: props.onFocus,
    onEnterPress: (...args) => {
      !props.disabled && props.onEnterPress && props.onEnterPress(...args);
      !props.disabled && showRipple();
    },
    focusKey: props.focusKey
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  const memoizedSize = useMemo(() => props.size || 'small', [props.size]);
  const styleProps = useMemo(() => {
    const { title, icon, ...rest } = props;
    return rest;
  }, [props]);

  return (
    <div
      style={{
        color: active
          ? styleProps.focusColor || styleProps.color || variables.color_grey_10
          : styleProps.color || variables.color_grey_10,
        fontWeight: styleProps.fontWeight,
        textTransform: styleProps.textTransform as CSSProperties['textTransform'],
        borderRadius: styleProps.borderRadius,
        backgroundColor: active
          ? styleProps.focusBackgroundColor || styleProps.backgroundColor
          : styleProps.backgroundColor,
        margin: styleProps.margin
      }}
      className={`${getClassName('button', {
        focused: active,
        disabled: props.disabled
      })} ${memoizedSize} ${props.className || ''}`}
      ref={ref}
      key={`button-${focusKey}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(!props.disabled && (props.onEnterPress as any)) || undefined}
    >
      {ripples}
      {props.icon && (
        <div className="button-icon-container" key={`button-icon-container-${focusKey}`}>
          {typeof props.icon === 'string' ? (
            <Icon src={props.icon} key={`button-icon-${focusKey}`} size="verySmall" />
          ) : (
            props.icon
          )}
        </div>
      )}
      <span className={`button-title ${memoizedSize}`}>{props.title}</span>
    </div>
  );
}
export default React.memo(Button);
