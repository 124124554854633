import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { setProfileSetting, translationPacket } from 'common/actions';

import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { getLockPeriods } from 'common/utils/profile-settings';
import { StoreState } from 'index';
import { ThunkActionDispatch } from 'redux-thunk';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';

interface Props {
  i18n: typeof translationPacket;
  setProfileSetting: ThunkActionDispatch<typeof setProfileSetting>;
}

function UnlockPeriodSettingsDrawer({ i18n, setProfileSetting }: Props) {
  const onMenuItemPress = useCallback((period: SettingsMenuItem) => {
    setProfileSetting('lockPeriod', period.value);
  }, []);

  const LOCK_PERIODS = useMemo(() => getLockPeriods(i18n), [i18n]);

  const menuItems = useMemo(() => {
    return LOCK_PERIODS.map((period) => ({
      id: `unlock-period-${period.label}`,
      label: period.label,
      value: period.value,
      type: MENU_ITEMS.radioMenuItem
    }));
  }, [LOCK_PERIODS]);

  return (
    <SettingsListDrawer
      drawerTitle={i18n?.mainSettings.unlockPeriod}
      menuItems={menuItems}
      onMenuItemPress={onMenuItemPress}
      selectedKey="lockPeriod"
    />
  );
}

const mapDispatchToProps = {
  setProfileSetting
};

const mapStateToProps = ({ i18n }: StoreState) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(UnlockPeriodSettingsDrawer));
