import { SET_DETAILED_INFO_DATA } from 'common/constants/action-types';
import { DetailInfoStripeItem, Nullable } from 'common/interfaces';
import { AppDispatch } from 'index';

export const setDetailedInfoAsset = (asset?: Nullable<DetailInfoStripeItem>) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_DETAILED_INFO_DATA,
      payload: asset
    });
  };
};
