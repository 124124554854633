import { APP_SET_CHANNEL_SETTINGS } from 'common/constants/action-types';
import { PayloadAction } from 'index';

export const INITIAL_STATE = {
  channelSettings: [],
  defaultPIN: '0000',
  lockedPIN: ''
};

export default function (state = INITIAL_STATE, action: PayloadAction) {
  switch (action.type) {
    case APP_SET_CHANNEL_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
