import { sortMessagesByRead } from 'common/actions';
import {
  CLEAR_MESSAGES,
  DELETE_MESSAGES,
  GET_MESSAGES,
  READ_MESSAGES,
  SORT_MESSAGES
} from 'common/constants/action-types';
import { Action } from 'redux';

export interface Message {
  id: string;
  timestamp: number;
  from: string;
  title: string;
  content: string;
  media?: string;
  priority: 'urgent' | 'warning' | 'normal';
  type: 'message';
  read?: boolean;
  seen?: boolean;
}

export type Messages = Message[];

export const INITIAL_STATE: Messages = [];

export default function (state = INITIAL_STATE, action: Action & { payload: any }) {
  switch (action.type) {
    case GET_MESSAGES:
      return [...action.payload];
    case DELETE_MESSAGES: {
      const ids = action.payload.ids as string[];
      // Remove messages from store
      const messages = [...state];
      ids.forEach((id) => {
        const index = messages.findIndex((e) => e.id === id);
        if (index !== -1) messages.splice(index, 1);
      });
      //
      return [...messages];
    }
    case READ_MESSAGES: {
      const ids = action.payload.ids as string[];
      // Remove change flag
      const messages = [...state];
      ids.forEach((id) => {
        const index = messages.findIndex((e) => e.id === id);
        if (index !== -1) messages[index].read = true;
      });
      //
      return [...messages];
    }
    case SORT_MESSAGES: {
      const messages = sortMessagesByRead([...state]);
      //
      return [...messages];
    }
    case CLEAR_MESSAGES:
      return INITIAL_STATE;
    default:
      return state;
  }
}
