import { CLEAR_ERROR, SET_ERROR } from 'common/constants/action-types';

export interface Errors {
  code?: number | null;
  redirect?: string | null;
}

const initialState: Errors = {
  code: null,
  redirect: null
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_ERROR:
      // Simply change the reference to trigger
      // routing in the App component.
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_ERROR:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
