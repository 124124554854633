import ProductIcon from '@assets/media/qr-code-brand-logo.png';
import { Translation } from 'common/actions';
import { MessagePopup } from 'common/components/message-popups';
import { API_URL } from 'common/config/constants';
import { getScalablePixel } from 'common/utils';
import { calculateAspectRatioFit } from 'common/utils/helpers';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

interface DeleteAccountPopupProps {
  i18n: Translation;
  userDetails: any;
}

function DeleteAccountPopup(props: DeleteAccountPopupProps) {
  const originURL = useMemo(() => new URL(API_URL).origin, []);
  const [qrBrandLogoSize, setQrCodeBrandLogoSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const image = new Image();
    image.src = ProductIcon;
    image.onload = () =>
      setQrCodeBrandLogoSize(
        calculateAspectRatioFit(
          image.width,
          image.height,
          getScalablePixel(60),
          getScalablePixel(30)
        )
      );
  }, []);

  const renderQRCode = useCallback(() => {
    const params = new URLSearchParams();
    params.set('email', props.userDetails?.email);
    const value = `${originURL}/account/delete?${params}`;

    return (
      <QRCodeSVG
        value={value!}
        includeMargin
        width={getScalablePixel(280)}
        height={getScalablePixel(280)}
        level="Q"
        imageSettings={{
          src: ProductIcon!,
          excavate: true,
          width: qrBrandLogoSize.width,
          height: qrBrandLogoSize.height
        }}
      />
    );
  }, [props.userDetails?.email, qrBrandLogoSize]);

  return (
    <MessagePopup popupId="DELETE-ACCOUNT-POPUP">
      <div className="delete-account-popup-wrapper">
        <span className="title">{props.i18n.account.deletingYourAccount}</span>
        <div className="row">
          <div className="column">
            <span
              className="description"
              dangerouslySetInnerHTML={{ __html: props.i18n.account.deleteAccountDescription }}
            />
          </div>
          {renderQRCode()}
        </div>
      </div>
    </MessagePopup>
  );
}

const mapStateToProps = ({ i18n, userDetails }: any) => ({
  i18n,
  userDetails
});

export default React.memo(connect(mapStateToProps, {})(DeleteAccountPopup));
