import { DefaultFadeIn } from 'App.styles';
import { Column } from 'common/components/common/style';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

interface PopupContainerProps {
  visible?: boolean;
}
export const PopupContainer = styled.div<PopupContainerProps>`
  position: fixed;
  outline: none;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const PopupPrompt = styled(Column)<{
  fullscreen?: boolean;
  type: 'primary' | 'secondary';
  removeSpacing?: boolean;
}>`
  flex: 1;
  border-top: ${({ type }) =>
    type === 'primary' ? `solid ${getScalablePixel(5)}px ${variables.color_primary}` : 'none'};
  background: ${({ type }) =>
    type === 'primary' ? variables.background_color_dark : variables.background_color_light};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ removeSpacing }) => (removeSpacing ? 0 : getScalablePixel(24))}px;
  border-radius: ${getScalablePixel(12)}px;
  width: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  height: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  ${DefaultFadeIn}
`;
