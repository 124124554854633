import axios from 'axios';
import { refreshToken } from 'common/actions';

import {
  API_URL,
  ERROR_CODES_THAT_REQUIRE_LOGIN,
  ERROR_REDIRECT_MAP,
  ERROR_SUBCODE_ACCESS_DENIES,
  REDIRECT_DEFAULT_ROUTE,
  ROUTE_TRIAL_END
} from 'common/config/constants';
import {
  AUTH_DELETE_TOKEN,
  SET_ERROR,
  USER_REMOVE_DETAILS,
  VIDEO_RESET
} from 'common/constants/action-types';
import {
  deleteTokenObject,
  getTokenObject,
  getTrialObject,
  hasSpecificServerError,
  hasTokenObject,
  setTokenObject
} from 'common/services/helpers';
import { store } from 'index';
import { isEmpty } from 'lodash';
import AbortHandler from './AbortHandler';

/**
 * Create an axios 'client' with default
 * 'baseUrl' and 'token' properties.
 * The client is imported in index.js where
 * after the store is created the 'dispatch' method
 * is referenced on the 'client' as client.__dispatch.
 * This gives the option to dispatch actions on
 * specific responses like specific errors.
 */
export const client = axios.create({ baseURL: API_URL });

/**
 * Success handler.
 * @param response {Object}
 * @returns {Promise<any>}
 */
const onSuccess = (response) => {
  if (hasTokenObject(response)) {
    setTokenObject(response.data.data);
  }

  return Promise.resolve(response.data);
};

/**
 * Error handler.
 * Can dispatch actions on specific error codes.
 * @param error {Object}
 * @returns {Promise<any>}
 */
const onError = async (error) => {
  let payload = error;

  if (hasSpecificServerError(error)) {
    payload = error.response.data;
    const { subcode } = payload;



    // Get from the map where to redirect user based on the error subcode
    let redirect = ERROR_CODES_THAT_REQUIRE_LOGIN.includes(subcode)
      ? REDIRECT_DEFAULT_ROUTE
      : ERROR_REDIRECT_MAP[subcode];

    // Show trial end screen when there is a trace of trial object in localstorage
    // And the current user is the user from trial object
    if (subcode === ERROR_SUBCODE_ACCESS_DENIES) {
      const success = await client.__dispatch(refreshToken());
      // Exit function if refresh token was successful
      if (success) return;

      const trialObject = getTrialObject();
      const userDetails = store.getState().userDetails;
      if (!isEmpty(trialObject) && userDetails.email === trialObject.email) {
        redirect = ROUTE_TRIAL_END
      }
    }

    // Here an action is triggered for the different
    // groups of errors that require specific behaviour
    // that influences the app on a global level.
    // For the local usages (showing a specific error in a form)
    // the error gets propagated and returned as promise
    // here and later in the action creator, providing in such
    // manner the possibility to 'await' it locally in the
    // component that has triggered the action in first place.
    if (ERROR_CODES_THAT_REQUIRE_LOGIN.includes(subcode)) {
      deleteTokenObject();
      // The deletion of the token will automatically
      // trigger the 'protect' guard (see 'protect.js')
      // currently on a protected route.
      client.__dispatch({
        type: AUTH_DELETE_TOKEN,
        payload: {},
      });
      // Reset user details
      // to its initial state.
      client.__dispatch({
        type: USER_REMOVE_DETAILS,
        payload: {},
      });
    }
    // Reset the current video to
    // to its initial state.
    redirect &&
      client.__dispatch({
        type: VIDEO_RESET,
        payload: null,
      });
    client.__dispatch({
      type: SET_ERROR,
      payload: {
        code: subcode,
        redirect,
      },
    });
  }

  return Promise.reject(payload);
};

/**
 * Request wrapper with default success/error handlers.
 * @param options {Object} - defines the method, the url, the data etc.
 */
export default (options) => {
  // Dynamically set the token from the local storage.
  let token = '';
  const tokenObjectFromLocalStorage = getTokenObject();
  if (tokenObjectFromLocalStorage && tokenObjectFromLocalStorage.access_token) {
    token = tokenObjectFromLocalStorage.access_token;
  }
  // Merge with existing headers if provided or set them
  // if not provided.
  if (options.headers) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  } else {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return client({ ...options, signal: AbortHandler.signal })
    .then(onSuccess)
    .catch(onError);
};
