import * as Sentry from '@sentry/react';
import {
  USER_CONFIRM_DELETION,
  USER_GET_DETAILS,
  USER_GET_FULL_DETAILS,
  USER_GET_INVOICES,
  USER_GET_INVOICE_URL,
  USER_REMOVE_DETAILS
} from 'common/constants/action-types';
import request from 'common/services/request';

export const getFullUserDetails = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/users/me?details=1&billing=0'
      });
      const response = await ajax;

      response.data = {
        ...response.data,
        subscription: [
          {
            ...response.data.subscription[0],
            plan: [
              {
                ...response.data.subscription[0].plan[0],
                currencyCode: response.data.subscription[0].plan[0].currency
              }
            ],
            recurringAddons: response.data.subscription[0].addons || []
          }
        ]
      };

      dispatch({
        type: USER_GET_FULL_DETAILS,
        payload: response
      });

      return response.data;
    } catch (error) {
      console.warn(error)
    }
  };
};

export const getUserDetails = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/users/me?billing=0'
      });
      const response = await ajax;
      dispatch({
        type: USER_GET_DETAILS,
        payload: response
      });
      Sentry.setUser({
        email: response.data.email,
        segment: response.data.profiles.find((e) => e.current)?.handle
      });

      return response.data;
    } catch (error) {
      console.warn(error);
    }
  };
};

export const getInvoiceList = () => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/users/me/invoices'
      });
      const response = await ajax;
      dispatch({
        type: USER_GET_INVOICES,
        payload: response
      });

      return response.data;
    } catch (error) {
      console.warn(error)
    }
  };
};

export const removeUserDetails = () => {
  return (dispatch) => {
    dispatch({
      type: USER_REMOVE_DETAILS,
      payload: {}
    });
  };
};

export const getInvoiceUrl = (id) => {
  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: `/billing/invoices/${id}`
      });
      const data = await ajax;
      dispatch({
        type: USER_GET_INVOICE_URL,
        payload: data
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const deleteAccount = ({ password, token }) => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'delete',
        url: `/users/me?access_token=${token}`,
        data: { password }
      });
      // Allow the invoking component to
      // know when the request has succeeded
      // and render the appropriate info.
      return response;
    } catch (error) {
      console.warn(error)
    }
  };
};

export const confirmDeletion = (shouldConfirm) => {
  return (dispatch) => {
    dispatch({
      type: USER_CONFIRM_DELETION,
      payload: shouldConfirm
    });
  };
};

export const cancelSubscriptionRenewal = (cancellationReason) => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'delete',
        url: `/users/me/subscription?reason=${encodeURIComponent(cancellationReason)}`
      });

      return response;
    } catch (error) {
      console.warn(error)
    }
  };
};

export const editBillingDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'post',
        url: `/users/me?access_token=${data.token}`,
        data: data
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
