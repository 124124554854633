import BackgroundImage from '@assets/media/login_background.png';
import { ViewContainer } from 'App.styles';
import {
  getLastWatchedEpgs,
  getLiveCategories,
  getLiveChannels,
  getLogos,
  getMostWatchedEpgs,
  getRecordedCategories,
  getTranslations,
  getUserDetails,
  retrievePrismicRadioImages,
  signIn
} from 'common/actions';
import LoadingIndicator, { LoadingState } from 'common/components/loading-indicator';
import { TrialModal } from 'common/components/trial';
import { checkNeedsTrialReminderPopup } from 'common/components/trial/utils';
import { ROUTE_ERROR_LOADING_DATA, ROUTE_HOME, ROUTE_TRIAL_WELCOME } from 'common/config/constants';
import { usePopup } from 'common/contexts/popup';
import { LocalStorageTrialObject } from 'common/interfaces';
import {
  getTokenObject,
  getTrialObject,
  hasCredentialsObjectInMemory,
  hasTokenInMemory
} from 'common/services/helpers';
import { usePageNavigation, useRequiredData } from 'common/utils/hooks';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Container = styled(ViewContainer)`
  background: url(${BackgroundImage});
  background-size: cover;
`;

function Launcher(props: any) {
  const navigate = usePageNavigation();
  const [loadingState, setLoadingState] = useState<LoadingState>('loading');
  const { get: fetchData } = useRequiredData();
  const { openPopup } = usePopup();

  useEffect(() => {
    if (hasCredentialsObjectInMemory() && hasTokenInMemory()) {
      fetchData().then((response) => {
        // onError
        if (!response) {
          navigate(ROUTE_ERROR_LOADING_DATA, true, true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const {
      liveChannels,
      liveCategories,
      i18n,
      recorded,
      lastWatched,
      mostWatched,
      userDetails,
      serverTime
    } = props;
    const tokenObject = getTokenObject();
    const loading =
      isEmpty(i18n) ||
      isEmpty(liveCategories) ||
      isEmpty(liveChannels) ||
      isEmpty(recorded.categories) ||
      isEmpty(userDetails) ||
      isEmpty(serverTime) ||
      isNil(mostWatched) ||
      isNil(lastWatched);
    // Make sure we have token
    if (!loading && !isEmpty(tokenObject)) {
      setLoadingState('done');
    }
  }, [
    props.liveChannels,
    props.liveCategories,
    props.i18n,
    props.recorded,
    props.mostWatched,
    props.lastWatched,
    props.userDetails,
    props.serverTime
  ]);

  const showTrialWelcomeScreen = useMemo(() => {
    const trialObject = getTrialObject() as LocalStorageTrialObject;

    // Check if the account is a trial account
    // Check if the previous trial account was the same
    return !isEmpty(props.userDetails?.trial) && trialObject?.email !== props.userDetails?.email;
  }, [props.userDetails]);

  const handlePopupReminderForTrial = useCallback(() => {
    // Check trial expiration
    // Show popup when the next screen is not the welcome screen
    if (!showTrialWelcomeScreen) {
      checkNeedsTrialReminderPopup(props.userDetails) &&
        openPopup({
          id: 'TRIAL-MODAL',
          returnToLastFocused: false,
          type: 'primary',
          content: <TrialModal />
        });
    }
  }, [props.userDetails, showTrialWelcomeScreen]);

  const onLoadingFinish = () => {
    handlePopupReminderForTrial();
    navigate(showTrialWelcomeScreen ? ROUTE_TRIAL_WELCOME : ROUTE_HOME, true, true);
  };

  return (
    <Container>
      <LoadingIndicator state={loadingState} onFinish={onLoadingFinish} />
    </Container>
  );
}

const mapStateToProps = ({
  liveChannels,
  liveCategories,
  i18n,
  recorded,
  lastWatched,
  mostWatched,
  prismic,
  profileSettings,
  userDetails,
  serverTime
}: any) => ({
  liveChannels,
  liveCategories,
  i18n,
  recorded,
  mostWatched,
  lastWatched,
  prismic,
  profileSettings,
  userDetails,
  serverTime
});
export default React.memo(
  connect(mapStateToProps, {
    signIn,
    getLiveChannels,
    getLastWatchedEpgs,
    getLiveCategories,
    getLogos,
    getTranslations,
    getRecordedCategories,
    getMostWatchedEpgs,
    getUserDetails,
    retrievePrismicRadioImages
  })(Launcher)
);
