import variables from 'common/config/variables';
import { isEmpty } from 'lodash';
import React from 'react';
import './Image.styles';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  lazy?: boolean;
  fallbackSrc?: string;
  transition?: 'none' | 'fade';
  visible?: boolean;
}

function Image(props: ImageProps) {
  const onError = async ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
    currentTarget.onerror = null;
    currentTarget.src = props.fallbackSrc || variables.DEFAULT_LOGO_PATH;
  };

  return (
    <img
      onError={onError}
      loading={props.lazy ? 'lazy' : undefined}
      decoding="async"
      {...props}
      className={`${isEmpty(props.className) ? '' : props.className} image`}
      src={props.src}
    />
  );
}

export default React.memo(Image);
