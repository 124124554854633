import { LOGOS_SET } from 'common/constants/action-types';
import request from 'common/services/request';
import { isEmpty } from 'lodash';

export const getLogos = () => {
  return async (dispatch, getState) => {
    try {
      const { liveChannels } = getState();
      const data = isEmpty(liveChannels)
        ? (
          await request({
            method: 'get',
            url: '/channels'
          })
        ).data
        : liveChannels;

      const logos = Object.keys(data).reduce((obj, channelId) => {
        let baseUrl = data[channelId].images.baseURL;

        obj[channelId] = {
          svgCentered: `${baseUrl}${data[channelId].images.svgColor}`,
          svgLeft: `${baseUrl}${data[channelId].images.svgLeftColor}`,
          png: `${baseUrl}${data[channelId].images.png350LeftWhite}`,
          pngWhite: `${baseUrl}${data[channelId].images.png350White}`,
          name: data[channelId].name,
          id: channelId,
          access: data[channelId].access,
          locked: data[channelId].locked
        };
        return obj;
      }, {});

      dispatch({
        type: LOGOS_SET,
        payload: logos
      });
    } catch (error) {
      console.warn(error)
    }
  };
};
