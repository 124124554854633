import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';

const WIDTH = getScalablePixel(962);
const HEIGHT = getScalablePixel(170);

GlobalStyle.inject`
.last-watched-channel-hint {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 5;
    width: ${getScalablePixel(472)}px;
    height: ${HEIGHT}px;
    box-sizing: border-box;
    transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;

    &.visible {
        transform: translate(-50%, 0);
    }

    & > *:not(.background-mask):not(.vertical-separator) {
        margin: 0 24px;
    }

    .background-mask {
        position: absolute;
        top: 0;
        left: -50%;
        width: ${WIDTH}px;
        height: ${HEIGHT}px;
    }

    .title-container {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        color: ${variables.color_grey_10};

        .last {
            font-size: ${getScalablePixel(28)}px;
            font-weight: 300;
            line-height: normal;
            text-transform: uppercase;
        }

        .watched-channel {
            font-size: ${getScalablePixel(26)}px;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            width: max-content;
        }
    }
    .channel-logo {
        width: ${getScalablePixel(380)}px;
    }
}
`;
