import QRCodeLogo from '@assets/media/qr-code-brand-logo.png';
import { Translation } from 'common/actions';
import { MessagePopup } from 'common/components/message-popups';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { TrialModalStyle } from './Trial.styles';
import { timeLogicFunction } from './utils';

interface TrialModalProps {
  i18n: Translation;
  userDetails: any;
}

const TrialModal = (props: TrialModalProps) => {
  const remainingTime = useMemo(
    () => timeLogicFunction(props.userDetails.trial.expire),
    [props.userDetails.trial.expire]
  );

  const renderRemainingTime = () => {
    const time = `${remainingTime.number <= 1 ? remainingTime.word : ''} ${remainingTime.number} ${
      remainingTime.wordTime
    }`;
    const modalTexts = props.i18n?.trial?.modal;
    return (
      <>
        <div className="text">
          {remainingTime.number > 1 ? modalTexts?.daysXtoExpirePlural : modalTexts?.daysXtoExpire}
        </div>
        <div className="numberBox">{time}</div>
      </>
    );
  };

  const renderQRCode = useCallback(() => {
    const value = variables.GET_JOIN_URL_PATH(props.userDetails?.email);
    if (isEmpty(value)) {
      return null;
    }
    return (
      <QRCodeSVG
        value={value!}
        includeMargin
        width={getScalablePixel(280)}
        height={getScalablePixel(280)}
        level="Q"
        imageSettings={{
          src: QRCodeLogo,
          excavate: true,
          width: getScalablePixel(53),
          height: getScalablePixel(16)
        }}
      />
    );
  }, [props.userDetails.email]);

  return (
    <MessagePopup
      popupId={'TRIAL-MODAL'}
      content={
        <TrialModalStyle>
          <ProductLogo type="small" height={getScalablePixel(84)} />
          <div className="email">{props.userDetails.email}</div>
          <div className="text-box">
            <div className="left">{renderRemainingTime()}</div>
            <div className="right">
              <div className="text">{props.i18n?.trial?.modal?.expire1}</div>
              <div className="text">{props.i18n?.trial?.modal?.expire2}</div>
              <div className="text number">{props.i18n?.trial?.modal?.phone}</div>
              <div className="text">{props.i18n?.trial?.modal?.toOffice}</div>
            </div>
          </div>
          <div className="text-box qr-code">
            <div className="left">
              <div className="text">{props.i18n?.trial?.modal?.qrScanCode}</div>
              <H2>{props.i18n?.trial?.modal?.webSite}</H2>
            </div>
            <div className="right">{renderQRCode()}</div>
          </div>
        </TrialModalStyle>
      }
    />
  );
};

const mapStateToProps = ({ i18n, userDetails }: any) => ({
  i18n,
  userDetails
});

export default React.memo(connect(mapStateToProps, {})(TrialModal));
