import { ClampContainer } from 'common/components/Typography';
import variables from 'common/config/variables';
import { Epg } from 'common/constants/data-types';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import { getEpisodeInfoText } from 'Player/controls/utils';
import React from 'react';
import styled from 'styled-components';

const StyledSubtitle = styled(ClampContainer)`
  font-size: ${getScalablePixel(26)}px;
  color: ${variables.font_color_c};
`;

interface DescriptionSubtitleProps {
  numeration?: Epg['numeration'];
  originaltitle?: Epg['originaltitle'];
  children?: string;
}

function DescriptionSubtitle({ children, numeration, originaltitle }: DescriptionSubtitleProps) {
  if (children) {
    return <StyledSubtitle>{children}</StyledSubtitle>;
  }

  const getSubtitle = () => {
    try {
      const episodeInfoText = getEpisodeInfoText(numeration);
      if (isEmpty(episodeInfoText)) return originaltitle || '';
      const episodeInfo = `${episodeInfoText} · ` || '';
      return episodeInfo + originaltitle;
    } catch (error) {
      return '';
    }
  };

  return <StyledSubtitle>{getSubtitle()}</StyledSubtitle>;
}

export default React.memo(DescriptionSubtitle);
