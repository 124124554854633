import { ROUTES_WITH_HEADER, ROUTE_ARCHIVE, ROUTE_HOME } from 'common/config/constants';
import { getKey } from 'common/utils';
import { getRootPath } from 'common/utils/helpers';

const routeMatchRegex = new RegExp(`${ROUTE_ARCHIVE}.+(\\d{3})`);

export const getRouteMatch = (pathname: string) => pathname.match(routeMatchRegex);

export const isMenuShown = (locationPath: string) =>
  ROUTES_WITH_HEADER.includes(getRootPath(locationPath));

export const getInitialFocusKey = (locationPath: string) =>
  getKey('menu-item', undefined, getRootPath(ROUTE_HOME));
