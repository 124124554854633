import variables from 'common/config/variables';
import GlobalStyle from 'common/utils/GlobalStyle';
import { uniqueId } from 'lodash';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { css, keyframes } from 'styled-components';

const { RIPPLE_EFFECT_ANIMATION_DURATION } = variables;

const rippleAnimation = keyframes`
from {
  visibility: visible;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
}
to {
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
`;

const Ripple = () => css`
  animation: ${rippleAnimation} ${RIPPLE_EFFECT_ANIMATION_DURATION}ms;
`;

GlobalStyle.inject`
.ripple-effect {
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(100, 100, 100, 0.5);
  border-radius: 50%;
  visibility: hidden;
  z-index: 2;
  opacity: 0.4;
  pointer-events: none;
  ${Ripple()}
}
`;

interface Riple {
  key: string | number;
  style: CSSProperties;
}

export default function useRipple(style?: CSSProperties) {
  const [ripples, setRipples] = useState<Riple[]>([]);

  const showRipple = useCallback(() => {
    const newRipple: Riple = {
      key: uniqueId('ripple'),
      style: { ...style }
    };

    setRipples((state) => [...state, newRipple]);
  }, [style]);

  const ripplesArray = useMemo(
    () =>
      ripples.map((currentRipple) => {
        const handleAnimationEnd = () => {
          setRipples((state) =>
            state.filter((previousRipple) => previousRipple.key !== currentRipple.key)
          );
        };

        return (
          <span
            key={currentRipple.key}
            className="ripple-effect"
            style={currentRipple.style}
            onAnimationEnd={handleAnimationEnd}
          />
        );
      }),
    [ripples]
  );

  return { ripples: ripplesArray, showRipple };
}
