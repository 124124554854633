import React, { useDeferredValue } from 'react';

import FutureThumbnail from '@assets/media/card_next_cover.png';
import DefaultThumbnail from '@assets/media/no_info_thumbnail.png';
import StarIcon from '@assets/media/rating.svg';
import { translationPacket } from 'common/actions';
import Icon from 'common/components/Icon';
import { Image } from 'common/components/image';
import { Epg } from 'common/constants/data-types';
import {
  getDateAndTimeFast,
  getThumbnailTimestampWithOffset,
  getThumbnailUrl
} from 'common/services/helpers';
import { useRadioImage } from 'common/utils/hooks';
import { StoreState } from 'index';
import { shallowEqual, useSelector } from 'react-redux';
import './Card.styles';

interface CardProps {
  isLive?: boolean;
  hidden?: boolean;
  epg: Epg;
  logo?: string;
  title?: string;
  showDate?: boolean;
}

function Card({
  isLive,
  logo: logoSrc,
  epg,
  hidden,
  title: titleParam,
  showDate = true
}: CardProps) {
  const i18n = useSelector(
    (state: StoreState) => state.i18n,
    shallowEqual
  ) as typeof translationPacket;
  const { start, stop, chan_id, title, rating, eventid } = useDeferredValue(epg);
  const radioImages = useRadioImage(chan_id);
  const realThumbnailUrl = getThumbnailUrl(chan_id, isLive, getThumbnailTimestampWithOffset(start));

  // Show radio image only when the show is not in the future
  const thumbnailUrl =
    radioImages && realThumbnailUrl !== FutureThumbnail
      ? radioImages.radio_thumbnail_image.url
      : realThumbnailUrl;
  const parsedDate = getDateAndTimeFast(start, stop, i18n.months);
  const programDate = `${parsedDate.day} ${parsedDate.month}`;
  const programTimeSlot = `${parsedDate.start} - ${parsedDate.stop}`;
  const programRating = `${rating?.Overall || rating?.OverallMP || ''}`;
  const ratingVisible = !!parseFloat(programRating);

  if (hidden) {
    return <div className="card-box"></div>;
  }

  return (
    <div className="card-box">
      <Image
        crossOrigin="anonymous"
        className="card-background"
        id={`card-background-${eventid}`}
        src={thumbnailUrl || undefined}
        fallbackSrc={DefaultThumbnail}
      />
      <div className="card-blurred-overlay" id={`card-overlay-${eventid}`} />
      <div className="card-bottom-container">
        {logoSrc && (
          <>
            <Image className="card-logo" src={logoSrc} />
            <span className="card-separator" />
          </>
        )}
        <div className="card-info-container">
          <span className="card-title">{titleParam || title}</span>
          <div className="card-epg-container">
            {showDate && (
              <>
                {programDate}
                <span className="card-epg-separator">·</span>
              </>
            )}
            {programTimeSlot}
            {ratingVisible && <Icon className="card-rating-icon" size="small" src={StarIcon} />}
            {ratingVisible && programRating}
            {/* Empty span for spacing */}
            {!ratingVisible && <span />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Card);
