import { NavigateMode } from 'common/reducers/appConfig';
import { StoreState } from 'index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useHoverable = (focusedState: boolean) => {
  const navigateMode = useSelector(
    (state: StoreState) => state.appConfig.navigateMode,
    shallowEqual
  ) as NavigateMode;
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  useEffect(() => {
    navigateMode === 'DIRECTIONAL' && setHovered(false);
  }, [navigateMode]);

  const active = useMemo(() => {
    return { DIRECTIONAL: focusedState, POINTER: hovered }[navigateMode];
  }, [focusedState, hovered, navigateMode]);

  return { active, onMouseEnter, onMouseLeave };
};

export default useHoverable;
