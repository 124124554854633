import { API_MAX_CATEGORIES_LIMIT } from 'common/config/constants';
import variables from 'common/config/variables';
import {
  CATEGORIES_GET,
  RECORDED_CLEAR_CATEGORIES_EPGS,
  RECORDED_CLEAR_CATEGORY_LIST,
  RECORDED_CLEAR_SUBCATEGORIES_EPGS,
  RECORDED_CLEAR_SUBCATEGORY_LIST,
  RECORDED_GET_CATEGORY_EPGS,
  RECORDED_GET_CATEGORY_LIST_NEXT_PAGE,
  RECORDED_GET_SUBCATEGORY_EPGS,
  RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE,
  RECORDED_RESET
} from 'common/constants/action-types';
import { partitionArray } from 'common/services/helpers';
import request from 'common/services/request';
import { propagateChannelAccessToEpg } from 'common/utils/profile-settings';
import { isEmpty } from 'lodash';
import { store } from '../..';

const requestFilteredEpgs = async (url, data) => {
  const response = await request({
    method: 'post',
    url,
    data
  });
  const { profileSettings, liveChannels } = store.getState();
  const entries = Object.entries(response.data).map(([categoryId, epgs]) => [
    categoryId,
    // filter all adult channels
    epgs
      .filter((epg) => !liveChannels[epg.chanId].adult)
      .map((epg) => propagateChannelAccessToEpg(epg, profileSettings))
  ]);
  return {
    ...response,
    data: Object.fromEntries(entries)
  };
};

export const getRecordedCategories = () => {

  return async (dispatch) => {
    try {
      const ajax = request({
        method: 'get',
        url: '/epgs/categories'
      });
      const { data } = await ajax;
      dispatch({
        type: CATEGORIES_GET,
        payload: data
      });
      return data;
    } catch (error) {
      console.warn(error)

    }
  };
};

// FOR THE PAGE WITH ALL CATEGORIES:

const createInitialCategoriesQuery = (idList, count = 20) => {
  return { categories: idList.map((id) => ({ count, id, offset: 0 })) };
};

export const getListsForManyCategories = () => {
  return async (dispatch, getState) => {
    try {
      let { recorded } = getState();
      if (isEmpty(recorded)) {
        recorded = { categories: await getRecordedCategories() };
      }
      const data = createInitialCategoriesQuery(Object.keys(recorded.categories));
      const promises = partitionArray(data.categories, API_MAX_CATEGORIES_LIMIT).map(
        (categories) => {
          return requestFilteredEpgs('/epgs/categories/many', { categories });
        }
      );
      const res = await Promise.all(promises);
      const accumulatedData = res.reduce((accumulated, current) => {
        return {
          ...accumulated,
          ...current.data
        };
      }, {});

      dispatch({
        type: RECORDED_GET_CATEGORY_EPGS,
        payload: accumulatedData
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const getNextPageForCategory = (queryData) => {
  return async (dispatch, getState) => {
    try {
      const ajax = requestFilteredEpgs('/epgs/categories/many', queryData);

      const catId = queryData.categories[0].id;
      const { data } = await ajax;
      const prevState = getState().recorded.categoriesEpgs[catId];
      dispatch({
        type: RECORDED_GET_CATEGORY_EPGS,
        payload: data[catId].length > 0 ? data : prevState
      });

    } catch (error) {
      console.warn(error)
    }
  };
};

export const getPrevPageForCategory = (queryData) => {
  return async (dispatch) => {
    try {
      const ajax = requestFilteredEpgs('/epgs/categories/many', queryData);

      const { data } = await ajax;
      dispatch({
        type: RECORDED_GET_CATEGORY_EPGS,
        payload: data
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const clearCategoriesEpgs = () => {
  return async (dispatch) => {
    dispatch({
      type: RECORDED_CLEAR_CATEGORIES_EPGS,
      payload: null
    });
  };
};

// FOR THE PAGE WITH ALL SUBCATEGORIES FOR A CATEGORY:

const createInitialSubcategoriesQuery = (idList, count = 20) => {
  return { subcategories: idList.map((id) => ({ count, id, offset: 0 })) };
};

export const getListsForManySubcategories = (category) => {
  return async (dispatch) => {
    try {
      const subcategoriesIds = category.subcategories.map(({ id }) => id);
      const data = createInitialSubcategoriesQuery(subcategoriesIds);

      const promises = partitionArray(data.subcategories, API_MAX_CATEGORIES_LIMIT).map(
        (subcategories) => {
          return requestFilteredEpgs('/epgs/subcategories/many', { subcategories });
        }
      );
      const res = await Promise.all(promises);
      const accumulatedData = res.reduce((accumulated, current) => {
        return {
          ...accumulated,
          ...current.data
        };
      }, {});

      dispatch({
        type: RECORDED_GET_SUBCATEGORY_EPGS,
        payload: accumulatedData
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const getNextPageForSubcategory = (queryData) => {
  return async (dispatch, getState) => {
    try {
      const ajax = requestFilteredEpgs('/epgs/subcategories/many', queryData);

      const catId = queryData.subcategories[0].id;
      const { data } = await ajax;
      const prevState = getState().recorded.subcategoriesEpgs[catId];
      dispatch({
        type: RECORDED_GET_SUBCATEGORY_EPGS,
        payload: data[catId].length > 0 ? data : prevState
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const getPrevPageForSubcategory = (queryData) => {
  return async (dispatch) => {
    try {
      const ajax = requestFilteredEpgs('/epgs/subcategories/many', queryData);

      const { data } = await ajax;
      dispatch({
        type: RECORDED_GET_SUBCATEGORY_EPGS,
        payload: data
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const clearSubcategoriesEpgs = () => {
  return async (dispatch) => {
    dispatch({
      type: RECORDED_CLEAR_SUBCATEGORIES_EPGS,
      payload: null
    });
  };
};

// FOR THE PAGE WITH LISTING FOR A CATEGORY:

export const getCategoryListNextPage = (id, offset, count) => {
  return async (dispatch) => {
    try {
      const query = {
        categories: [{ id, offset, count }]
      };
      const ajax = requestFilteredEpgs('/epgs/categories/many', query);

      const { data } = await ajax;

      dispatch({
        type: RECORDED_GET_CATEGORY_LIST_NEXT_PAGE,
        payload: { data, id }
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const clearCategoryList = (id) => {
  return (dispatch) => {
    dispatch({
      type: RECORDED_CLEAR_CATEGORY_LIST,
      payload: { id }
    });
  };
};

// FOR THE PAGE WITH LISTING FOR A SUBCATEGORY:

export const getSubcategoryListNextPage = (id, count = variables.DEFAULT_PAGE_SIZE, offset = 0) => {
  return async (dispatch) => {
    try {
      const queryData = {
        subcategories: [{ id, offset, count }]
      };
      const ajax = requestFilteredEpgs('/epgs/subcategories/many', queryData);
      const subCatId = queryData.subcategories[0].id;
      const { data } = await ajax;

      dispatch({
        type: RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE,
        payload: { data, id: subCatId }
      });
    } catch (error) {
      console.warn(error)
    }
  };
};

export const clearSubcategoryList = (id) => {
  return (dispatch) => {
    dispatch({
      type: RECORDED_CLEAR_SUBCATEGORY_LIST,
      payload: { id }
    });
  };
};

export const updateSubcategoryEpgs = () => {
  return (dispatch, getState) => {
    try {
      const { profileSettings, recorded } = getState();
      const epgsWithAccessRights = Object.entries(recorded.subcategoriesEpgs).map(
        ([subCatId, epgs]) =>
          [subCatId, epgs.map((epg) => propagateChannelAccessToEpg(epg, profileSettings))]
      );
      dispatch({
        type: RECORDED_GET_SUBCATEGORY_EPGS,
        payload: Object.fromEntries(epgsWithAccessRights)
      });
    } catch (error) {
      console.warn(error)
    }
  };

}

export const resetRecorded = () => {
  return (dispatch) => {
    dispatch({
      type: RECORDED_RESET
    });
  };
};
