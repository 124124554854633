import Icon from 'common/components/Icon';
import { getClassName } from 'common/utils';
import React from 'react';

export interface CardAccessOverlayProps {
  accessType: string;
  focused?: boolean;
  title: string;
  subTitle: string;
  icon: string;
  secondaryIcon?: string;
}

function CardAccessOverlay(props: CardAccessOverlayProps) {
  return (
    <>
      <div
        className={getClassName(`${props.accessType}-info-overlay`, { focused: props.focused })}
      />
      <div className={getClassName(`${props.accessType}-mask`, { focused: props.focused })} />
      <div className={getClassName(`${props.accessType}-info`, { focused: props.focused })}>
        <div>
          <Icon className={`${props.accessType}-mask-icon`} size="large" src={props.icon} />
          {props.secondaryIcon && (
            <Icon
              className={`${props.accessType}-mask-icon`}
              size="large"
              src={props.secondaryIcon}
              style={{ width: 62, height: 62, position: 'relative', top: '10px' }}
            />
          )}
        </div>
        <div className="paid-info-title">{props.title}</div>
        <div className="paid-info-subtitle">{props.subTitle}</div>
      </div>
    </>
  );
}

export default React.memo(CardAccessOverlay);
