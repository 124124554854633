import DefaultThumbnail from '@assets/media/no_info_thumbnail.png';
import { Image } from 'common/components/image';
import { TIME_BEFORE_BUFFER } from 'common/config/constants';
import variables from 'common/config/variables';
import { getLivePoint, getThumbnailUrl } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import React, { useCallback, useMemo } from 'react';
const { FAST_SEEK_OFFSET } = variables;

interface FastSeekStripeProps {
  visible?: boolean;
  timestamp: number;
  chanId: string;
  endTime?: number;
}
export default function FastSeekStripe(props: FastSeekStripeProps) {
  // Show 2 thumbnails before now and 2 after now
  const items = useMemo(() => {
    if (!props.visible || props.timestamp <= 0) {
      return [];
    }
    const endTime = Math.floor(!props.endTime ? getLivePoint() : props.endTime);
    const before = [...Array(3)]
      .map((_, index) => ({
        timestamp: props.timestamp - (index + 1) * FAST_SEEK_OFFSET,
        primary: false
      }))
      .reverse();
    const now = [{ timestamp: props.timestamp, primary: true }];
    const after = [...Array(3)].map((_, index) => ({
      timestamp: props.timestamp + (index + 1) * FAST_SEEK_OFFSET,
      primary: false
    }));
    return [...before, ...now, ...after].map(({ timestamp, primary }) =>
      timestamp < endTime + TIME_BEFORE_BUFFER
        ? { src: getThumbnailUrl(props.chanId, false, timestamp), primary }
        : undefined
    );
  }, [props.timestamp, props.chanId, props.endTime, props.visible]);

  const renderThumbnail = useCallback((src?: string, primary?: boolean, key?: string) => {
    return (
      <div className={getClassName('fast-seek-thumbnail-wrapper', { primary })} key={key}>
        {src && <Image src={src} fallbackSrc={DefaultThumbnail} />}
      </div>
    );
  }, []);
  return (
    <div className={getClassName('fast-seek-container', { visible: props.visible })}>
      {items.map((item, index) =>
        renderThumbnail(item?.src, item?.primary, `FAST-SEEK-THUMB-${index}`)
      )}
    </div>
  );
}
