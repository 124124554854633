import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import FocusableStripe from 'common/components/stripe/FocusableStripe';
import variables from 'common/config/variables';
import {
  ContentAlign,
  ContentAxisAlignMap,
  ContentScrollingContentAxis,
  FocusableItem,
  ScrollPaddingAxisMap,
  StripeItemEvents
} from 'common/interfaces';
import { AppConfigState, NavigateMode } from 'common/reducers/appConfig';
import { getDefaultSmoothScrollOptions, getKey } from 'common/utils';
import smoothScroll from 'common/utils/smoothScroll';
import { isNil } from 'lodash';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { StripeProps } from './Stripe';
import StripePadding from './StripePadding';
import './StripeView.styles';

interface StripeHeaderProps {
  onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
}

// eslint-disable-next-line react/display-name
const StripeHeader = React.memo((props: React.PropsWithChildren<StripeHeaderProps>) => {
  const { ref, focusKey } = useFocusable({
    onFocus: props.onFocus
  });

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className="stripe-header-container">
        {props.children}
      </div>
    </FocusContext.Provider>
  );
});

interface StripeViewState {
  // Redux injected
  navigateMode: NavigateMode;
  //
}

export interface StripeViewProps extends StripeItemEvents, FocusableItem {
  loading?: boolean;
  children?: React.ReactElement<StripeProps> | React.ReactElement<StripeProps>[] | React.ReactNode;
  axis?: ContentScrollingContentAxis;
  align?: ContentAlign;
  scrollPadding?: number;
  stripeAlign?: ContentAlign;
  stripeScrollPadding?: number;
  stripeInfiniteScroll?: boolean;
  forceLoadToIndex?: number;
  saveFocusOnUnmount?: boolean;
  stripeHeader?: React.ReactNode;
  onBack?: (last?: boolean) => boolean;
}

function StripeView(props: StripeViewProps & StripeViewState) {
  const location = useLocation();
  const currentFocusedRow = useRef<number | undefined>();
  const [focusIndexes, setFocusIndexes] = useState<[number, number] | undefined>();
  const { ref, focusKey } = useFocusable({
    focusKey: props.focusKey || location.pathname,
    focusable: !props.disabled,
    isFocusBoundary: props.isFocusBoundary,
    focusBoundaryDirections: props.focusBoundaryDirections,
    autoRestoreFocus: true,
    saveLastFocusedChild: true,
    trackChildren: false,
    onFocus: props.onFocus,
    onBlur: props.onBlur
  });
  // Invert axis in order to have the StripeView Scroll
  const contentAxis = useMemo(() => ((props.axis || 'x') === 'x' ? 'y' : 'x'), [props.axis]);
  const stripePaddingValue = useMemo(() => '100%', []);

  // Avoid missing focus
  useEffect(() => {
    const currentFocusKey = getCurrentFocusKey();
    if (isNil(currentFocusKey)) {
      startTransition(() => setFocusIndexes([0, 0]));
    }
  }, []);

  // Sync prop with inner state
  useEffect(() => {
    props.initiallyFocused && setFocusIndexes([0, 0]);
  }, [props.initiallyFocused]);

  const onStripeHeaderFocus = useCallback((layout: FocusableComponentLayout) => {
    smoothScroll({
      ...getDefaultSmoothScrollOptions(layout.width),
      duration: variables.ROW_SCROLL_ANIMATION_DURATION,
      toElement: layout.node,
      scrollingElement: ref.current,
      paddingLeft: 0,
      paddingTop: 0
    });
  }, []);

  const onRowFocus = useCallback(
    (layout: FocusableComponentLayout, data: Partial<{ index: number }>) => {
      currentFocusedRow.current = data?.index;
      //@ts-ignore
      smoothScroll({
        ...getDefaultSmoothScrollOptions(layout.width),
        duration: variables.ROW_SCROLL_ANIMATION_DURATION,
        toElement: layout.node,
        scrollingElement: ref.current,
        ...(props.align ? { [ContentAxisAlignMap[contentAxis]]: props.align } : undefined),
        ...(!isNil(props.scrollPadding)
          ? { [ScrollPaddingAxisMap[contentAxis]]: props.scrollPadding }
          : undefined)
      });
    },
    [props.align, props.scrollPadding, contentAxis, props.axis]
  );

  const onRowBlur = useCallback(() => {
    currentFocusedRow.current = undefined;
  }, []);

  const onBack = useCallback((status: number) => {
    const last = status === -1 && currentFocusedRow.current === 0;
    const onBackResponse = props.onBack ? props.onBack(last) : true;
    if (!onBackResponse) return;
    if (status === 0) {
      !isNil(currentFocusedRow.current) && setFocusIndexes([currentFocusedRow.current, 0]);
    } else if (status === -1 && currentFocusedRow.current === 0) {
      setFocus('MENU');
    } else if (status === -1) {
      setFocusIndexes([0, 0]);
    }
  }, []);

  const onScrollToIndex = useCallback(() => {
    setFocusIndexes(undefined);
  }, []);

  const childrenArray = useMemo(() => {
    return React.Children.toArray(props.children);
  }, [props.children]);

  const renderStripe = useCallback(
    ({ props: childProps, key: childKey }: { props: StripeProps; key?: string }, index: number) => {
      const key = getKey('stripe', index, props.focusKey);
      const focusIndex =
        (!isNil(props.initialIndex) && {
          index: props.initialIndex as number,
          animateScroll: true
        }) ||
        (!isNil(focusIndexes) && index === focusIndexes[0]
          ? { index: focusIndexes[1], animateScroll: true }
          : undefined);
      return (
        <FocusableStripe
          key={childKey || key}
          items={childProps.items}
          title={childProps.title}
          renderItem={childProps.renderItem}
          visible={childProps.visible}
          index={index}
          onStripeItemPress={props.onStripeItemPress || childProps.onStripeItemPress}
          onFocus={onRowFocus}
          onBlur={onRowBlur}
          onStripeItemFocus={props.onStripeItemFocus || childProps.onStripeItemFocus}
          onStripeItemBlur={props.onStripeItemBlur || childProps.onStripeItemBlur}
          onStripeItemHover={props.onStripeItemHover || childProps.onStripeItemHover}
          getFocusKey={childProps.getFocusKey}
          onBack={onBack}
          initialIndex={focusIndex}
          onScrollToIndex={onScrollToIndex}
          align={props.stripeAlign}
          axis={props.axis || 'x'}
          scrollPadding={props.stripeScrollPadding}
          loadMore={childProps.loadMore}
          forceLoadToIndex={props.forceLoadToIndex}
          navigateMode={props.navigateMode}
          stripeItemClassName={props.stripeItemClassName}
          saveFocusOnUnmount={props.saveFocusOnUnmount}
        />
      );
    },
    [
      props.onStripeItemBlur,
      props.onStripeItemFocus,
      props.onStripeItemPress,
      props.onStripeItemHover,
      onScrollToIndex,
      props.stripeItemClassName,
      onBack,
      focusIndexes,
      props.stripeScrollPadding,
      props.forceLoadToIndex,
      props.axis,
      props.initialIndex,
      props.navigateMode,
      props.saveFocusOnUnmount,
      onRowFocus,
      onRowBlur
    ]
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div className={`stripe-view-container ${contentAxis}`} ref={ref} key={focusKey}>
        {props.stripeHeader && (
          <StripeHeader onFocus={onStripeHeaderFocus}>{props.stripeHeader}</StripeHeader>
        )}
        {childrenArray.map((item: any, index) => renderStripe(item, index))}
        <StripePadding direction="bottom" padding={stripePaddingValue} />
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ appConfig }: { appConfig: AppConfigState }) => ({
  navigateMode: appConfig.navigateMode
});

export default React.memo(connect(mapStateToProps, {})(StripeView));
