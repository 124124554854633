import {
  pause as pauseFocusable,
  resume as resumeFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { setIdleTimer } from 'common/actions/idle-timer';
import variables from 'common/config/variables';
import { StoreState } from 'index';
import { debounce, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const { IDLE_MESSAGE_TIME, IDLE_TIME_DEFAULT, IDLE_MESSAGE_INITIAL_TIME } = variables;

interface IdleTimerProps {
  onIdleTimerEnd?: () => void;
  disabled?: boolean;
}

let idleTimeout: NodeJS.Timeout | null = null;

export function useIdleTimer({ onIdleTimerEnd, disabled }: IdleTimerProps) {
  const dispatch = useDispatch();
  const { isPlaying, isOpen, messageShown, countdownTime } = useSelector((state: StoreState) => {
    return {
      isPlaying: state.videoPlayer.isPlaying,
      isOpen: state.videoPlayer.isOpen,
      messageShown: state.idleTimer.visible,
      countdownTime: state.idleTimer.countdownTime
    };
  }, shallowEqual);

  const idleTimeoutValue = useSelector(
    (state: StoreState) => state.userDetails.idleTimeout,
    shallowEqual
  );
  const idleTime: number = useMemo(
    () => (!isNil(idleTimeoutValue) ? idleTimeoutValue : IDLE_TIME_DEFAULT),
    [idleTimeoutValue]
  );

  const onIdleTimerEndRef = useRef(onIdleTimerEnd);
  const messageShownRef = useRef(messageShown);
  const countdownTimeRef = useRef(countdownTime);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    onIdleTimerEndRef.current = onIdleTimerEnd;
  }, [onIdleTimerEnd]);

  useEffect(() => {
    messageShownRef.current = messageShown;
  }, [messageShown]);

  useEffect(() => {
    countdownTimeRef.current = countdownTime;
    // If countdown time is 0, call onIdleTimerEnd
    if (countdownTime === 0) {
      onIdleTimerEnd && onIdleTimerEnd();
    }
  }, [countdownTime]);

  const timerCountdownHandler = useCallback(() => {
    if (countdownTimeRef.current <= 0) {
      return;
    } else {
      // Decrease countdown time
      dispatch(setIdleTimer({ countdownTime: countdownTimeRef.current - 1 }));
    }
  }, []);

  useEffect(() => {
    // If idle message is shown, start countdown
    if (messageShown && countdownTime === IDLE_MESSAGE_INITIAL_TIME) {
      dispatch(setIdleTimer({ countdownTime: IDLE_MESSAGE_TIME }));
      countdownIntervalRef.current = setInterval(timerCountdownHandler, 1000);
    } else if (!messageShown) {
      // Clear countdown interval and reset countdown time
      clear();
      countdownIntervalRef.current !== null && clearInterval(countdownIntervalRef.current);
    }
  }, [messageShown, countdownTime]);

  const start = useCallback(() => {
    // If idleTimeout is already set, do not set it again
    // Do not set idleTimeout if idleTime returned from back end is 0
    if (idleTimeout !== null || messageShownRef.current || disabled || !idleTime) return;
    idleTimeout !== null && clearTimeout(idleTimeout);
    // Set timeout to show idle message
    idleTimeout = setTimeout(() => {
      dispatch(setIdleTimer({ visible: true }));
    }, idleTime * 1000);
  }, [disabled, idleTime]);

  const clear = useCallback(() => {
    // Clear idleTimeout and reset idle message options
    idleTimeout !== null && clearTimeout(idleTimeout);
    idleTimeout = null;
    dispatch(setIdleTimer({ visible: false, countdownTime: IDLE_MESSAGE_INITIAL_TIME }));
  }, []);

  useEffect(() => {
    if (isPlaying && !idleTimeout) {
      start();
    }

    if (!isOpen) {
      clear();
    }
  }, [isPlaying, isOpen, idleTime]);

  useEffect(() => {
    const handler = debounce((e: Event) => {
      // Prevent all other event listeners for capturing when idle message is shown
      if (messageShownRef.current) {
        e.preventDefault();
        e.stopPropagation();
      }
      clear();
      start();
    }, variables.THROTTLE_TIMEOUT);

    // Add event listeners for keyboard, mouse and touch events
    // when player is open
    if (isOpen) {
      document.addEventListener('keyup', handler, { capture: true });
      document.addEventListener('keydown', handler, { capture: true });
      document.addEventListener('click', handler, { capture: true });
      document.addEventListener('mousemove', handler, { capture: true });
    }
    // Clear on unmount
    return () => {
      document.removeEventListener('keyup', handler, { capture: true });
      document.removeEventListener('keydown', handler, { capture: true });
      document.removeEventListener('click', handler, { capture: true });
      document.removeEventListener('mousemove', handler, { capture: true });
    };
  }, [isOpen, idleTime]);

  useEffect(() => {
    // If idle message is shown, pause spatial navigation
    if (messageShown) {
      pauseFocusable();
    } else {
      resumeFocusable();
    }
  }, [messageShown]);

  return {
    idleMessageShown: messageShown as boolean,
    remainingTime: countdownTime as number,
    clear
  };
}
