import {
  USER_GET_PROFILE_SETTINGS,
  USER_REMOVE_PROFILE_SETTINGS,
  USER_RESET_PROFILE_SETTINGS,
  USER_SAVE_PROFILE_SETTINGS,
  USER_SET_CHANNEL_PROFILE_SETTING,
  USER_SET_PROFILE_SETTING,
  USER_SET_PROFILE_SETTINGS
} from 'common/constants/action-types';

export enum CLOCK_POSITIONS {
  IN_MENU = 'in-menu',
  ALWAYS = 'always'
}

export enum LOCK_PERIODS {
  ALWAYS_LOCK = -1,
  SESSION_LOCK = -2
}

export type LanguageSetting = string | null | undefined;

export type ChannelSettings = { [id: string]: ChannelSetting };

export interface ChannelSetting {
  favoritePosition: number;
  favorite?: boolean;
  visible?: boolean;
  audio: LanguageSetting;
  resolution: number | null;
  subtitles: LanguageSetting;
  position: number | null;
  locked?: boolean;
}
export type ChannelSettingKeys = keyof ChannelSetting;
export type ChannelSettingValues = ChannelSetting[ChannelSettingKeys];

export interface Settings {
  resolution: number;
  subtitles: null | string | number;
  audio: null | null;
  channels: ChannelSettings;
  lockPeriod: LOCK_PERIODS | number;
  timeToLockChannels: null;
  clockPosition: CLOCK_POSITIONS;
  saved?: boolean;
}

export type SettingsKey = keyof Settings;

export interface ProfileSettings {
  react_tv_settings: Settings;
}

export const INITIAL_STATE: ProfileSettings = {
  react_tv_settings: {
    resolution: -1,
    subtitles: null,
    audio: null,
    channels: {},
    lockPeriod: 1000,
    timeToLockChannels: null,
    clockPosition: CLOCK_POSITIONS.IN_MENU,
    saved: false
  }
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case USER_GET_PROFILE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        react_tv_settings: {
          ...state.react_tv_settings,
          ...action.payload.react_tv_settings,
          saved: true
        }
      };

    case USER_SET_PROFILE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        react_tv_settings: {
          ...state.react_tv_settings,
          ...action.payload.react_tv_settings,
          saved: false
        }
      };
    case USER_SAVE_PROFILE_SETTINGS:
      return {
        ...state,
        ...action.payload,
        react_tv_settings: {
          ...state.react_tv_settings,
          ...action.payload.react_tv_settings,
          saved: true
        }
      };
    case USER_SET_PROFILE_SETTING: {
      const { key, value } = action.payload;
      return {
        ...state,
        react_tv_settings: {
          ...state.react_tv_settings,
          [key]: value,
          saved: false
        }
      };
    }
    case USER_SET_CHANNEL_PROFILE_SETTING: {
      const channel = state.react_tv_settings.channels[action.payload.id];
      if (!channel) {
        return state;
      }
      channel[action.payload.key] = action.payload.value;

      return {
        ...state,
        react_tv_settings: {
          ...state.react_tv_settings,
          channels: {
            ...state.react_tv_settings.channels,
            [action.payload.id]: { ...channel }
          },
          saved: false
        }
      };
    }
    case USER_REMOVE_PROFILE_SETTINGS:
      return {
        ...action.payload
      };
    case USER_RESET_PROFILE_SETTINGS:
      return {
        ...state,
        react_tv_settings: {
          ...state.react_tv_settings,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
