import { IconStyle } from 'common/components/common/style';
import { Image, ImageProps } from 'common/components/image';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import React from 'react';

GlobalStyle.inject`
.icon {
  height: auto;
  padding: ${getScalablePixel(4)}px;

  &.miniature {
    ${IconStyle({ size: 'miniature' })}
  }
  &.verySmall {
    ${IconStyle({ size: 'verySmall' })}
  }
  &.small {
    ${IconStyle({ size: 'small' })}
  }
  &.medium {
    ${IconStyle({ size: 'medium' })}
  }
  &.large {
    ${IconStyle({ size: 'large' })}
  }

  &.card-rating-icon {
    padding-left: ${getScalablePixel(8)}px;
  }

  &.paid-mask-icon, &.locked-mask-icon {
    margin-right: -${getScalablePixel(8)}px;
    margin-bottom: ${getScalablePixel(28)}px;
    will-change: transform;
  }
}
`;

export type IconSize = 'miniature' | 'verySmall' | 'small' | 'medium' | 'large';
export interface IconProps extends ImageProps {
  size: IconSize;
}

function Icon(props: IconProps) {
  return <Image {...props} className={`icon ${props.size} ${props.className}`} />;
}

export default React.memo(Icon);
