//TODO
export default {
  apiKey: 'AIzaSyCUsxvVILbWoxTmdlXii1NjJ3CGERlt-xo',
  authDomain: 'elementaltv-apps.firebaseapp.com',
  databaseURL: 'https://elementaltv-apps.firebaseio.com',
  projectId: 'elementaltv-apps',
  storageBucket: 'elementaltv-apps.appspot.com',
  messagingSenderId: '3027454208',
  appId: '1:3027454208:web:252adc80e80047fa6b0be5',
  measurementId: 'G-WLJHQDJ0QV'
};
