import { removeUserDetails, translationPacket } from 'common/actions';
import { DeleteAccountPopup } from 'common/components/delete-account-popup';
import { ChangePasswordPopup } from 'common/components/keyboard-popups';
import { LogoutPopup } from 'common/components/logout-popup';
import FocusableStripe from 'common/components/stripe/FocusableStripe';
import { TrialModal } from 'common/components/trial';
import { P1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { useDrawer } from 'common/contexts/drawer';
import { usePopup } from 'common/contexts/popup';
import { NavigateMode } from 'common/reducers/appConfig';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import InnerSettingsMenuItem from './InnerSettingsMenuItem';
import {
  DeleteAccountLabel,
  MainSettingsDrawerInnerSubtitle,
  MainSettingsDrawerTitle
} from './MainSettingsDrawer.styles';
import { SettingsMenuItem } from './SettingsListDrawer';

interface Props {
  i18n: typeof translationPacket;
  navigateMode: NavigateMode;
  userDetails: any;
}

function ProfileSettingsDrawer({ i18n, navigateMode, userDetails }: Props) {
  const { closeAll } = useDrawer();
  const { openPopup } = usePopup();

  const onMenuItemPress = useCallback(
    (item: SettingsMenuItem) => item.onClick && item.onClick(item),
    []
  );

  const openLogoutPopup = useCallback(() => {
    openPopup({
      id: 'LOGOUT-POPUP',
      returnToLastFocused: true,
      content: (
        <LogoutPopup
          onSignOut={() => {
            closeAll();
          }}
        />
      )
    });
  }, [openPopup]);

  const menuItems = useMemo(() => {
    const registerMenuItem = {
      id: 'register',
      label: i18n?.trial.profileSettings.register,
      type: MENU_ITEMS.simpleMenuItem,
      onClick: () =>
        openPopup({
          id: 'TRIAL-MODAL',
          returnToLastFocused: true,
          type: 'primary',
          content: <TrialModal />
        })
    };

    const logoutMenuItem = {
      id: 'logout',
      label: isEmpty(userDetails?.trial) ? i18n?.account.logout : i18n?.account.changeAccountTitle,
      type: MENU_ITEMS.simpleMenuItem,
      onClick: openLogoutPopup
    };

    const changePasswordMenuItem = {
      id: 'change-password',
      label: i18n?.account.changePassword,
      type: MENU_ITEMS.simpleMenuItem,
      onClick: () =>
        openPopup({
          id: 'CHANGE-PASSWORD-MODAL',
          returnToLastFocused: true,
          type: 'primary',
          content: <ChangePasswordPopup />
        })
    };

    const requestDeleteAccountMenuItem = {
      id: 'request-delete-account',
      label: <DeleteAccountLabel>{i18n?.account.deleteAccount}</DeleteAccountLabel>,
      type: MENU_ITEMS.simpleMenuItem,
      onClick: () =>
        openPopup({
          id: 'DELETE-ACCOUNT-POPUP',
          returnToLastFocused: true,
          type: 'primary',
          content: <DeleteAccountPopup />
        })
    };

    return [
      ...(variables.CHANGE_PASSWORD_ENABLED ? [changePasswordMenuItem] : []),
      ...(!isEmpty(userDetails?.trial) ? [registerMenuItem] : []),
      logoutMenuItem,
      requestDeleteAccountMenuItem
    ];
  }, [i18n, openLogoutPopup]);

  const renderMenuItem = useCallback(
    (item: SettingsMenuItem) => (
      <InnerSettingsMenuItem
        key={item.id}
        id={item.id}
        title={item.label}
        subtitle={item.subtitle}
      />
    ),
    []
  );

  const renderTitle = useCallback(
    () =>
      isEmpty(userDetails?.trial)
        ? i18n?.mainSettings.profile
        : i18n?.trial.profileSettings.demoAccount,
    [i18n, userDetails?.trial]
  );

  return (
    <div className="main-settings-container">
      <MainSettingsDrawerTitle>{renderTitle()}</MainSettingsDrawerTitle>
      <MainSettingsDrawerInnerSubtitle>{userDetails?.email}</MainSettingsDrawerInnerSubtitle>

      {!isEmpty(userDetails?.trial) && (
        <P1
          className="trial-information"
          dangerouslySetInnerHTML={{ __html: i18n?.trial.profileSettings.information }}
        />
      )}

      <FocusableStripe
        focusKey="PROFILE_SETTINGS_MENU"
        items={menuItems}
        axis="y"
        align="center"
        onStripeItemPress={onMenuItemPress}
        renderItem={renderMenuItem}
        initiallyFocused
        isFocusBoundary
        navigateMode={navigateMode}
        stripeItemClassName="settings-menu-item"
      />
    </div>
  );
}

const mapDispatchToProps = {
  removeUserDetails
};

const mapStateToProps = ({ i18n, userDetails, appConfig }: StoreState) => ({
  i18n,
  userDetails,
  navigateMode: appConfig.navigateMode
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ProfileSettingsDrawer));
