import { MOST_WATCHED_CLEAR_EPGS, MOST_WATCHED_GET_EPGS } from 'common/constants/action-types';

export default (state = null, { payload, type }) => {
  switch (type) {
    case MOST_WATCHED_GET_EPGS:
      return payload;
    case MOST_WATCHED_CLEAR_EPGS:
      return null;
    default:
      return state;
  }
};
