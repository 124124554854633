import {
  APP_GET_APP_RESOLUTIONS,
  APP_GET_AUDIOLANGS,
  APP_GET_CONFIG,
  APP_GET_SUBLANGS,
  APP_SET_NAVIGATE_MODE
} from 'common/constants/action-types';

export type NavigateMode = 'DIRECTIONAL' | 'POINTER';

export interface AppConfigState {
  navigateMode: NavigateMode;
  subtitles: unknown[];
  audio: unknown[];
  resolutions: unknown[];
}

const INITIAL_STATE: AppConfigState = {
  navigateMode: 'DIRECTIONAL',
  subtitles: [],
  audio: [],
  resolutions: []
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case APP_GET_CONFIG:
      return {
        ...state,
        ...action.payload
      };
    case APP_GET_SUBLANGS:
      return {
        ...state,
        subtitles: [...action.payload]
      };
    case APP_GET_AUDIOLANGS:
      return {
        ...state,
        audio: [...action.payload]
      };
    case APP_GET_APP_RESOLUTIONS:
      return {
        ...state,
        resolutions: [...action.payload]
      };
    case APP_SET_NAVIGATE_MODE:
      return {
        ...state,
        navigateMode: action.payload
      };
    default:
      return state;
  }
}
