import NoSearchResultsIcon from '@assets/media/no-search-results-icon.png';
import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { clearSearch, playVideo, setVideoOptions, translationPacket } from 'common/actions';
import { Card } from 'common/components/cards';
import { Image } from 'common/components/image';
import { SearchPageBreadCrumb } from 'common/components/search-page-breadcrumb';
import { Stripe } from 'common/components/stripe';
import { H2 } from 'common/components/Typography';
import { ViewWithDetails } from 'common/components/view-with-details';
import { Epg, IViewWithDetailsStripeItem, LiveChannels } from 'common/constants/data-types';
import { SearchState } from 'common/reducers/searchState';
import { getChannelLogo } from 'common/services/helpers';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

interface StateProps {
  i18n: typeof translationPacket;
  searchState: SearchState;
  logos: any;
  liveChannels: LiveChannels;
  playVideo: typeof playVideo;
  setVideoOptions: typeof setVideoOptions;
  clearSearch: VoidFunction;
}

type SearchProps = StateProps;

function LinearSearchPage(props: SearchProps) {
  const opennedPlayer = useRef(false);
  const [initialIndexFocus, setInitiallyFocused] = useState<number | undefined>();
  const [searchActive, setSearchActive] = useState(false);
  const hasResults = useMemo(
    () => !isEmpty(props.searchState.recorded),
    [props.searchState.recorded]
  );

  const { ref, focusKey, hasFocusedChild } = useFocusable({
    trackChildren: true,
    onBlur: () => {
      // Clear search
      if (!opennedPlayer.current) {
        props.clearSearch();
      }
      opennedPlayer.current = false;
    }
  });
  // Clear state
  useEffect(() => {
    return () => {
      props.clearSearch();
    };
  }, []);

  useEffect(() => {
    hasFocusedChild && !hasResults && setFocus('SEARCH_BREADCRUMB');
    if (!hasFocusedChild) {
      clearInitialFocusFlag();
    }
  }, [hasResults, hasFocusedChild]);

  const openPlayer = useCallback((asset: IViewWithDetailsStripeItem) => {
    props.playVideo(asset);
    props.setVideoOptions({
      isFullscreen: true
    });
    opennedPlayer.current = true;
  }, []);

  const renderArchiveCard = useCallback(
    (item: Epg) => (
      <Card
        key={`archive-card-${item.eventid}`}
        epg={item}
        logo={getChannelLogo(item.chan_id, props.logos)}
      />
    ),
    [props.logos]
  );

  const renderSearchList = useCallback(() => {
    if (isEmpty(props.searchState.recorded)) {
      return null;
    }
    const { recorded } = props.searchState;
    return (
      <Stripe
        key={'search'}
        items={recorded}
        renderItem={renderArchiveCard}
        title={props.i18n.search.results}
        onStripeItemPress={openPlayer}
      />
    );
  }, [props.searchState.recorded, openPlayer]);

  const getSelectedAssetData = useCallback(
    (asset: IViewWithDetailsStripeItem) => {
      if (asset.chan_id) {
        return {
          epg: asset,
          // Extract resolution from liveChannels
          resolution: props.liveChannels[asset.chan_id]?.resolution
        };
      }
      return undefined;
    },
    [props.liveChannels]
  );

  const renderStripeHeader = useCallback(() => {
    return (
      <>
        {props.searchState.hasSearch && !hasResults && (
          <div className="no-results-container">
            <H2>{props.i18n.search.noResults}</H2>
            <Image src={NoSearchResultsIcon} />
          </div>
        )}
      </>
    );
  }, [props.searchState]);

  const handleFinishInput = useCallback(() => setInitiallyFocused(0), []);
  const clearInitialFocusFlag = useCallback(() => setInitiallyFocused(undefined), []);

  const renderSearchHeader = useCallback(() => {
    return (
      <SearchPageBreadCrumb
        onFocusStateChange={setSearchActive}
        onFinishInput={handleFinishInput}
      />
    );
  }, [handleFinishInput]);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={getClassName('linear-search-page', { 'search-active': searchActive })}
      >
        <ViewWithDetails
          hideBanners
          disabled={!hasResults && searchActive}
          onStripeItemFocus={clearInitialFocusFlag}
          preferredChildFocusKey="SEARCH_BREADCRUMB"
          getSelectedAssetData={getSelectedAssetData}
          header={renderSearchHeader()}
          initialIndex={initialIndexFocus}
          stripeHeader={renderStripeHeader()}
        >
          {renderSearchList()}
        </ViewWithDetails>
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n, searchState, logos, liveChannels }: any) => {
  return {
    i18n,
    searchState,
    logos,
    liveChannels
  };
};

export default React.memo(
  connect(mapStateToProps, {
    clearSearch,
    playVideo,
    setVideoOptions
  })(LinearSearchPage)
);
