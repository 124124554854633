import { PRODUCT_NAME } from 'common/config/constants';
import * as defaultVariablesModule from 'common/config/variables/default';
import { isString } from 'lodash';

type TVariables = typeof defaultVariablesModule;

function buildConfig(a?: Record<string, any>, b?: Record<string, any>) {
  const merged = { ...a, ...b };

  for (const [key, prop] of Object.entries(merged)) {
    if (isString(prop) && prop.startsWith('#ref')) {
      const refTo = prop.split(' ')[1];

      merged[key] = merged[refTo];
    }
  }

  return merged;
}

/* eslint-disable @typescript-eslint/no-var-requires */
const defaultVariables = require('common/config/variables/default');
let productVariables: TVariables | undefined = undefined;
try {
  productVariables = require(`common/config/variables/${PRODUCT_NAME}`);
} catch (error) {
  console.warn(`Could not find variables for '${PRODUCT_NAME}', using default`);
}

const variables = buildConfig(defaultVariables, productVariables) as TVariables;

export { variables as productVariables };

// Read and inject whitelabel variables.
// !!IMPORTANT
// The same variable name must exists in the default variables for the brand
let whiteLabelVariables = undefined;
try {
  whiteLabelVariables = require(`white-label/${variables.WHITELABEL_TYPE}/variables`);
} catch (error) {
  console.warn(`Could not find variables for '${variables.WHITELABEL_TYPE}', using default`);
}

export default { ...variables, ...whiteLabelVariables } as TVariables;
