import { ADDONS_GET, SUBSCRIPTIONS_GET } from 'common/constants/action-types';

export default function (state = { subscriptions: [], addons: [] }, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_GET:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ADDONS_GET:
      return {
        ...state,
        addons: action.payload,
      };
    default:
      return state;
  }
}
