import { useCallback, useState } from 'react';

const useOpennedOverlay = (focused?: boolean) => {
  const [openned, setOpenned] = useState(false);

  const open = useCallback(() => {
    setOpenned(true);
  }, []);

  const close = useCallback(() => {
    setOpenned(false);
  }, []);

  return { focused: focused || openned, setOpenned: open, setClosed: close };
};

export default useOpennedOverlay;
