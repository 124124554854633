import { Row } from 'common/components/common/style';
import { Image } from 'common/components/image';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

export const NoNetworkPopupButtonContainer = styled(Row)`
  padding-top: ${getScalablePixel(42)}px;
  flex: 1;
  width: 100%;
  justify-content: center;
  & > * {
    margin: 0 ${getScalablePixel(8)}px;
  }
`;

export const NoNetworkIconStyled = styled(Image)`
  width: ${getScalablePixel(183)}px;
  height: ${getScalablePixel(183)}px;
  padding-bottom: ${getScalablePixel(28)}px;
`;
