import {
  APP_CLEAR_FAVORITE_CHANNELS,
  APP_SET_FAVORITE_CHANNELS
} from 'common/constants/action-types';
import { LiveChannel, LiveChannels } from 'common/constants/data-types';
import { PayloadAction } from 'index';

export type FavoriteChannel = LiveChannel;

export type FavoriteChannels = LiveChannels;

export const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action: PayloadAction) {
  switch (action.type) {
    case APP_SET_FAVORITE_CHANNELS:
      return {
        ...action.payload
      };
    case APP_CLEAR_FAVORITE_CHANNELS:
      return {};
    default:
      return state;
  }
}
