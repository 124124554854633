import {
  clearFavoriteChannels,
  clearLastWatchedEpgs,
  clearLiveCategories,
  clearLiveChannels,
  clearMessages,
  clearMostWatchedEpgs,
  clearProfileSettings,
  getAppAudiolangs,
  getAppResolutions,
  getAppSublangs,
  getChannelSettings,
  getLastWatchedEpgs,
  getLiveCategories,
  getLiveChannels,
  getLogos,
  getMessages,
  getMostWatchedEpgs,
  getProfileSettings,
  getRecordedCategories,
  getServerTime,
  getUserDetails,
  refreshToken as refreshTokenAction,
  removeUserDetails,
  resetRecorded,
  retrieveBanners,
  retrievePrismicRadioImages,
  retrievePrismicScreensaverImages
} from 'common/actions';
import { AppDispatch } from 'index';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useMessages from './useMessages';

/**
 * Custom hook that handles fetching and clearing required data.
 * @returns {Object} An object containing the following functions:
 *   - get: A function that retrieves the required data by calling the `fetchData` function after refreshing the token.
 *   - clear: A function that clears the required data.
 *   - refreshToken: A function that refreshes the user token.
 */
const useRequiredData = () => {
  const dispatch = useDispatch<AppDispatch>();
  // Start and stop the timer when the required data
  // is fetched and cleared
  const { stopTimer, startTimer } = useMessages();

  const clear = useCallback(() => {
    dispatch(clearLastWatchedEpgs());
    dispatch(clearMostWatchedEpgs());
    dispatch(clearLiveCategories());
    dispatch(clearLiveChannels());
    dispatch(resetRecorded());
    dispatch(removeUserDetails());
    dispatch(clearFavoriteChannels());
    dispatch(clearMessages());
    dispatch(clearProfileSettings());
    stopTimer();
  }, []);

  const fetchData = useCallback(async () => {
    await Promise.all([
      dispatch(getServerTime()),
      dispatch(getProfileSettings()),
      dispatch(getChannelSettings()),
      dispatch(getLiveChannels()),
      dispatch(getLiveCategories()),
      dispatch(getLogos()),
      dispatch(getRecordedCategories()),
      dispatch(getUserDetails()),
      dispatch(retrievePrismicRadioImages()),
      dispatch(retrievePrismicScreensaverImages()),
      dispatch(retrieveBanners()),
      dispatch(getMostWatchedEpgs()),
      dispatch(getLastWatchedEpgs()),
      dispatch(getAppAudiolangs()),
      dispatch(getAppSublangs()),
      dispatch(getAppResolutions()),
      dispatch(getMessages()),
    ]);
    startTimer();
  }, []);

  /**
   * @param {Function} onRefresh - Optional function to call after refreshing the token
   * @returns {Promise<Boolean>} true if everything OK, false on error
   */
  const refreshToken = useCallback(async (onRefresh?: (() => Promise<void>) | undefined) => {
    //
    return (await dispatch(refreshTokenAction(onRefresh))) as unknown as boolean;
  }, []);

  /**
   * Retrieves the required data by calling the `fetchData` function after refreshing the token.
   * @returns {Promise<boolean>} A promise that resolves to the fetched data.
   */
  const get = useCallback(async () => {
    try {
      return await refreshToken(fetchData);
    } catch (error) {
      console.error('Error while fetching required data', error);
      return false;
    }
  }, [refreshToken]);

  return { get, clear, refreshToken };
};
export default useRequiredData;
