import { H1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

// width: ${getScalablePixel(404)}px;
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Footer = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 150px;
  width: 100%;
`;

export const LogoContainer = styled.div`
  text-align: center;
  height: ${getScalablePixel(140)}px;
`;

GlobalStyle.inject`
.content {
  align-items: flex-start;
  justify-content: space-around;
  padding: 0 ${getScalablePixel(64)}px;
  
  ${H1} {
    font-size: ${getScalablePixel(40)}px;
    color: ${variables.font_color_b};
    text-align: center;
  }
  .input-fields > ${H1}, .register-title-container {
    padding-bottom: ${getScalablePixel(32)}px;
    justify-content: flex-start;
    img {
      padding: 0 ${getScalablePixel(8)}px;
      height: ${getScalablePixel(30)}px;
      width: unset;
    }
  }

  .container {
    flex: 1;
    align-items: center;
  }
  .input-fields {
    width: ${getScalablePixel(420)}px;
    align-self: center;
  }
}
`;
