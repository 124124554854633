import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { resetVideo } from 'common/actions';
import { setDetailedInfoAsset } from 'common/actions/detailed-info';
import Carousel from 'common/components/carousel/Carousel';
import StripeView, { StripeViewProps } from 'common/components/stripe/StripeView';
import variables from 'common/config/variables';
import { IViewWithDetailsStripeItem } from 'common/constants/data-types';
import { AppTheme, DetailInfoStripeItem, Nullable, StripeItemData } from 'common/interfaces';
import { AppConfigState, NavigateMode } from 'common/reducers/appConfig';
import { IDetailedInfo } from 'common/reducers/detailedInfo';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getClassName } from 'common/utils';
import { usePinPopup } from 'common/utils/hooks/usePinPopup';
import { store } from 'index';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import DetailedInfo from './DetailedInfo';
import './ViewWithDetails.styles';
const { DETAILED_INFO_THROTTLE_TIMEOUT, THROTTLE_TIMEOUT } = variables;

interface ViewWithDetailsProps extends Omit<StripeViewProps, 'isPlayerFullscreen'> {
  customBannerView?: React.ReactElement;
  hideBanners?: boolean;
  disableBannerExpanding?: boolean;
  disableBanners?: boolean;
  getSelectedAssetData?: (asset: IViewWithDetailsStripeItem) => Nullable<DetailInfoStripeItem>;
  stripeHeader?: React.ReactNode;
  preferredChildFocusKey?: string;
  header?: React.ReactNode;
  // Redux injected
  carouselSelected?: boolean;
  isPlayerOpenned?: boolean;
  navigateMode: NavigateMode;
  resetVideo: VoidFunction;
  currentAsset?: Nullable<DetailInfoStripeItem>;
  setDetailedInfoAsset: (asset?: Nullable<DetailInfoStripeItem>) => void;
  //
}
function ViewWithDetails(props: ViewWithDetailsProps) {
  const stripeViewFocusedRef = useRef(false);
  const [stripeViewFocused, setStripeViewFocused] = useState(false);
  const [lockedFocusToContainer, setLockFocusToContainer] = useState(false);
  const { pathname } = useLocation();
  const focusKeyValue = useMemo(() => props.focusKey || pathname, [pathname]);
  const focusKeyStripeView = useMemo(() => `${focusKeyValue}-stripe-view`, [focusKeyValue]);

  const _onFocus = useCallback(() => {
    // If the returned focus is outside the stripe view handle the reset
    if (!stripeViewFocusedRef.current) {
      handleResetVideo(undefined);
    }
  }, []);

  const { ref, hasFocusedChild, focusKey } = useFocusable({
    isFocusBoundary: lockedFocusToContainer,
    focusKey: focusKeyValue,
    saveLastFocusedChild: true,
    trackChildren: true,
    forceFocus: true,
    onFocus: _onFocus
  });
  const [detailInfoVisible, setDetailInfoVisible] = useState<boolean>(!!props.currentAsset);
  const idleCallbackIdRef = useRef(-1);
  const isPlayerOpennedRef = useRef(props.isPlayerOpenned);
  const updateDetailedInfoThrottleRef = useRef<DebouncedFunc<VoidFunction> | null>(null);
  const { checkOpened } = usePinPopup();

  const detailedInfoActive = useMemo(
    () => detailInfoVisible && (props.navigateMode === 'DIRECTIONAL' ? stripeViewFocused : true),
    [props.navigateMode, stripeViewFocused, detailInfoVisible]
  );

  useEffect(() => {
    let keyDown = false;
    const onKeyUp = () => {
      if (keyDown) {
        setLockFocusToContainer(false);
      }
      keyDown = false;
    };
    const onKeyDown = () => {
      if (keyDown) {
        setLockFocusToContainer(true);
      }
      keyDown = true;
    };
    // Handle listener for keypress to avoid going to the sidemenu when holding keydown
    if (hasFocusedChild && variables.WHITELABEL_TYPE === AppTheme.LINEAR) {
      document.addEventListener('keyup', onKeyUp);
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [hasFocusedChild]);

  useEffect(() => {
    isPlayerOpennedRef.current = props.isPlayerOpenned;
  }, [props.isPlayerOpenned]);

  const cleanUp = () => {
    cancelIdleCallback(idleCallbackIdRef.current);
    updateDetailedInfoThrottleRef.current?.cancel();
    props.setDetailedInfoAsset(null);
    variables.WHITELABEL_TYPE !== AppTheme.LINEAR && props.resetVideo();
  };

  // Clean up
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const parseAsset = useCallback(
    (asset: StripeItemData<IViewWithDetailsStripeItem>) => {
      return props.getSelectedAssetData && asset && props.getSelectedAssetData(asset);
    },
    [props.getSelectedAssetData]
  );

  const handleResetVideo = useCallback((newAsset: Nullable<DetailInfoStripeItem>) => {
    // Skip reset based on the current app theme
    if (variables.WHITELABEL_TYPE !== AppTheme.ON_DEMAND) return;
    //
    // Poll data to prevent rerendering
    const currentAsset = store.getState()?.detailedInfo?.asset;
    // Reset last video when switching a show
    // Compare by value deep
    JSON.stringify(newAsset) !== JSON.stringify(currentAsset) && props.resetVideo();
  }, []);

  const getUpdateAssetDebounce = useCallback(() => {
    // Don't throttle when removing or showing detailed info
    // Poll current asset for performance boost
    if (isEmpty(store.getState().detailedInfo?.asset)) {
      return 0;
    }
    return props.navigateMode === 'DIRECTIONAL' ? DETAILED_INFO_THROTTLE_TIMEOUT : THROTTLE_TIMEOUT;
  }, [props.navigateMode]);

  const handleSelectedAsset = useCallback(
    (asset: Nullable<DetailInfoStripeItem>) => {
      // Don't throttle when removing or showing detailed info
      updateDetailedInfoThrottleRef.current?.cancel();
      cancelIdleCallback(idleCallbackIdRef.current);
      updateDetailedInfoThrottleRef.current = debounce(() => {
        idleCallbackIdRef.current = requestIdleCallback(() => {
          props.setDetailedInfoAsset(asset);
          setDetailInfoVisible(!!asset);
        });
      }, getUpdateAssetDebounce());
      updateDetailedInfoThrottleRef.current();
    },
    [getUpdateAssetDebounce]
  );

  const onStripeItemFocus = useCallback(
    (asset: StripeItemData<IViewWithDetailsStripeItem>) => {
      props.onStripeItemFocus && props.onStripeItemFocus(asset);
      const newAsset = parseAsset(asset);
      handleSelectedAsset(newAsset);
      handleResetVideo(newAsset);
    },
    [props.onStripeItemFocus, handleSelectedAsset, parseAsset]
  );

  const onStripeItemBlur = useCallback(() => {
    props.onStripeItemBlur && props.onStripeItemBlur();
    setDetailInfoVisible(false);
  }, [props.onStripeItemBlur]);

  const onStripeItemHover = useCallback(
    (asset: StripeItemData<IViewWithDetailsStripeItem>) => {
      props.onStripeItemHover && props.onStripeItemHover(asset);
      const newAsset = parseAsset(asset);
      handleSelectedAsset(newAsset);
      handleResetVideo(newAsset);
    },
    [props.onStripeItemHover, handleSelectedAsset, parseAsset]
  );

  const onBlur = useCallback(
    (layout?: FocusableComponentLayout, data?: object, details?: FocusDetails) => {
      // Don't call on blur on pointer navigation when hideBanners is active to avoid jumping
      if (props.navigateMode === 'POINTER' && props.hideBanners) return;
      props.onBlur && layout && details && props.onBlur(layout, data || {}, details);
      //
      cancelIdleCallback(idleCallbackIdRef.current);
      setDetailInfoVisible(false);
      stripeViewFocusedRef.current = false;
      setStripeViewFocused(false);

      // Don't clear asset when opening player with popups
      const openedPopup = checkOpened();

      if (!isPlayerOpennedRef.current && !openedPopup) {
        cleanUp();
      }
    },
    [props.onBlur, props.navigateMode, props.hideBanners]
  );

  const onFocus = useCallback(
    (layout: FocusableComponentLayout, data: object, details: FocusDetails) => {
      props.onFocus && props.onFocus(layout, data, details);
      stripeViewFocusedRef.current = true;
      setStripeViewFocused(true);
    },
    [props.onFocus]
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={getClassName('view-with-detail-wrapper', {
          'missing-detailed-info': props.hideBanners && !props.currentAsset
        })}
      >
        {!isEmpty(props.header) && (
          <div className="view-details-header-container">{props.header}</div>
        )}
        {props.currentAsset && (
          <DetailedInfo
            key={focusKey}
            epg={props.currentAsset.epg}
            isLiveCard={props.currentAsset.isLiveCard}
            resolution={props.currentAsset.resolution}
            active={detailedInfoActive}
            hasHeader={!isEmpty(props.header)}
          />
        )}
        {!props.hideBanners && (
          <Carousel
            disabled={props.disableBanners}
            visible={!props.currentAsset}
            customView={props.customBannerView}
            disableExpanding={props.disableBannerExpanding}
          />
        )}
        <div
          className={getClassName('stripe-view-container', {
            'carousel-expanded': !props.carouselSelected,
            'disabled-banners': props.hideBanners,
            'detail-info-visible': props.currentAsset
          })}
          onMouseLeave={onBlur as any}
        >
          <div className="view-details-stripe-header-container">{props.stripeHeader}</div>
          <StripeView
            focusKey={focusKeyStripeView}
            loading={props.loading}
            onStripeItemFocus={onStripeItemFocus}
            onStripeItemHover={onStripeItemHover}
            onStripeItemBlur={onStripeItemBlur}
            onStripeItemPress={props.onStripeItemPress}
            onFocus={onFocus}
            onBlur={onBlur}
            onBack={props.onBack}
            axis={props.axis}
            align={props.align}
            disabled={props.disabled}
            stripeAlign={props.stripeAlign}
            initiallyFocused={props.initiallyFocused}
            initialIndex={props.initialIndex}
            stripeScrollPadding={props.stripeScrollPadding}
            stripeItemClassName={props.stripeItemClassName}
          >
            {props.children}
          </StripeView>
        </div>
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({
  carousel,
  videoPlayer,
  appConfig,
  detailedInfo
}: {
  carousel: any;
  videoPlayer: IVideoPlayer;
  appConfig: AppConfigState;
  detailedInfo: IDetailedInfo;
}) => ({
  carouselSelected: carousel.isExpanded,
  isPlayerOpenned: videoPlayer.isOpen && videoPlayer.video.isFullscreen,
  navigateMode: appConfig.navigateMode,
  currentAsset: detailedInfo.asset
});

export default React.memo(
  connect(mapStateToProps, { resetVideo, setDetailedInfoAsset })(ViewWithDetails)
);
