import DefaultBanner from '@assets/media/default_banner.png';
import * as prismicH from '@prismicio/helpers';
import { toggleCarouselPlaying } from 'common/actions';
import { Image } from 'common/components/image';
import variables from 'common/config/variables';
import { Banner } from 'common/reducers/carousel';
import { getClassName } from 'common/utils';
import { isInViewport } from 'common/utils/helpers';
import { isEmpty } from 'lodash';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

interface BannerViewProps {
  banner: Banner;
  active?: boolean;
  focused?: boolean;
  // Redux injected
  toggleCarouselPlaying: (state: boolean) => void;
  //
}
function BannerView({ banner, active, focused, toggleCarouselPlaying }: BannerViewProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [mountVideo, setMountVideo] = useState(false);
  const videoEnded = useRef(false);
  const onVideoEndTimeoutRef = useRef<NodeJS.Timeout>();
  const hasVideoPreview = useMemo(
    () => !isEmpty(banner?.video_file?.url) && !!banner?.video_file?.url,
    [banner?.video_file?.url]
  );
  const bannerImageSrc = useMemo(() => prismicH.asImageSrc(banner.full_screen_image), [banner]);

  const isBannerVisible = useCallback(
    () =>
      isInViewport(
        ref.current,
        variables.CAROUSEL_VIEW_WIDTH - variables.CAROUSEL_VIEW_WIDTH_EXPANDED,
        variables.CAROUSEL_CONTAINER_HEIGHT_EXPANDED - variables.CAROUSEL_CONTAINER_HEIGHT
      ),
    []
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasVideoPreview && active) {
      try {
        clearTimeout(onVideoEndTimeoutRef.current);
        timeout = setTimeout(() => {
          if (isBannerVisible()) {
            videoEnded.current = false;
            setMountVideo(true);
          }
        }, variables.AUTOPLAY_TIMEOUT);
      } catch (error) {
        console.warn(`Error while playing video preview ${banner.video_file}`, error);
      }
    }
    return () => {
      clearTimeout(timeout);
      setShowPreview(false);
      setMountVideo(false);
      videoEnded.current = false;
    };
  }, [hasVideoPreview, active]);

  useEffect(() => {
    if (!focused) {
      setMountVideo(false);
    }
  }, [focused]);

  useEffect(() => {
    if (!mountVideo || active) {
      toggleCarouselPlaying(false);
    }
  }, [mountVideo, active]);

  const onVideoCanPlay = useCallback(() => {
    if (!videoEnded.current) {
      setShowPreview(true);
      toggleCarouselPlaying(true);
    }
  }, []);

  const onVideoEnd = useCallback(() => {
    videoEnded.current = true;
    setShowPreview(false);
    clearTimeout(onVideoEndTimeoutRef.current);
    onVideoEndTimeoutRef.current = setTimeout(() => {
      setMountVideo(false);
    }, variables.LONG_THROTTLE_TIMEOUT);
  }, []);

  const renderPreviewContainer = () => {
    if (!hasVideoPreview || !mountVideo) return null;
    return (
      <div className={getClassName('banner-preview-container', { show: showPreview })}>
        <div className="banner-preview-text-container">
          <span className="title">{banner.title[0]?.text}</span>
          <span className="subtitle">{banner.short_description[0]?.text}</span>
        </div>
        <video
          src={banner.video_file.url}
          autoPlay
          controls={false}
          onEnded={onVideoEnd}
          onCanPlay={onVideoCanPlay}
        />
      </div>
    );
  };

  return (
    <div className="banner-view" ref={ref}>
      {renderPreviewContainer()}
      <Image
        className={getClassName('banner-image', {
          hide: showPreview,
          'unmount-video': !mountVideo
        })}
        src={bannerImageSrc || DefaultBanner}
        fallbackSrc={DefaultBanner}
      />
    </div>
  );
}

export default React.memo(connect(null, { toggleCarouselPlaying })(BannerView));
