import { signOut as signOutAction } from 'common/actions';
import { AppDispatch } from 'index';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useRequiredData from './useRequiredData';

// Helper hook to handle sign out with clearing the user data
function useSignOut() {
  const { clear } = useRequiredData();
  const dispatch = useDispatch<AppDispatch>();

  const signOut = useCallback(async () => {
    await dispatch(signOutAction());
    clear();
  }, [clear]);

  return signOut;
}

export default useSignOut;
