import { ClampContainer, H3 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';
const { font_color_c, font_color_d, font_color_e } = variables;

export const MetadataSeparator = styled(H3)<{
  separator?: string;
  padding?: number;
  size?: number;
}>`
  color: ${font_color_c};
  padding: ${({ padding = 2 }) => `0 ${getScalablePixel(padding)}px`};
  &:after {
    content: '${({ separator }) => separator || '·'}';
  }
  ${({ size }) => (size ? `font-size: ${size}px;` : '')}
`;

export const MetadataMajorCategory = styled.span`
  color: ${font_color_e};
  text-transform: uppercase;
  font-size: ${getScalablePixel(20)}px;
`;

export const MetadataMinor = styled(ClampContainer)`
  font-weight: normal;
  text-transform: uppercase;
  color: ${font_color_c};
  opacity: 0.8;
  word-break: break-all;
`;

export const MetadataRating = styled.span`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: ${getScalablePixel(4)}px;
  }
`;

export const MetadataRatingMax = styled.span`
  color: ${font_color_d};
  font-weight: normal;
`;
