import React, { ReactNode } from 'react';

type DetailedInfoProps = {
  text: ReactNode;
  subText?: ReactNode;
};

export default function DetailedInfo({ text, subText }: DetailedInfoProps) {
  return (
    <div className="menu-detailed-info-wrapper">
      <span className="menu-separator" />
      <span className={`menu-detailed-info-text ${!subText ? 'active' : ''}`}>{text}</span>
      {subText && <span className="menu-detailed-sub-text">{subText}</span>}
    </div>
  );
}
