import MiniPlayIcon from '@assets/media/mini-play-icon.svg';

import Icon from 'common/components/Icon';
import { getClassName } from 'common/utils';
import React from 'react';
import { BannerType } from './utils';

const INDICATOR_TYPE_MAP: { [key in BannerType]: string } = {
  playable: MiniPlayIcon,
  link: MiniPlayIcon
};

interface IndicatorProps {
  selected?: boolean;
  focused?: boolean;
  initial?: boolean;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onKeyDown: (e: React.MouseEvent | React.KeyboardEvent) => void;
  ariaLabel: string;
  bannerType?: BannerType;
}
function Indicator(props: IndicatorProps) {
  const renderIndicatorIcon = () => {
    if (!props.bannerType || !(props.focused && props.selected)) return null;
    return <Icon size="verySmall" src={INDICATOR_TYPE_MAP[props.bannerType]} />;
  };

  return (
    <div
      className={getClassName('carousel-indicator', {
        selected: props.selected,
        focused: props.focused,
        initial: props.initial
      })}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      aria-label={props.ariaLabel}
    >
      {renderIndicatorIcon()}
    </div>
  );
}

export default React.memo(Indicator);
