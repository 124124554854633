import StarIcon from '@assets/media/filled-rating.png';
import {
  DescriptionDuration,
  DescriptionRowContainer
} from 'common/components/description/Description.styles';
import Icon from 'common/components/Icon';
import { QualityIcon } from 'common/components/quality-icon';
import { Epg } from 'common/constants/data-types';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import MetadataCategory from './MetadataCategory';
import { MetadataRating, MetadataRatingMax, MetadataSeparator } from './MetadataStrip.styles';

export interface MetadataStripProps {
  year?: Epg['year'];
  country?: Epg['country'];
  major_category?: Epg['major_category'];
  minor_categories?: Epg['minor_categories'];
  duration?: Epg['duration'];
  rating?: Epg['rating'];
  resolution?: string;
}

function MetadataStrip(props: MetadataStripProps) {
  const renderRating = useCallback(() => {
    if (!props.rating) {
      return undefined;
    }
    const rating = props.rating.Overall || props.rating.OverallMP;
    if (!rating) {
      return undefined;
    }
    return (
      <MetadataRating key="metadata-rating">
        <Icon size="verySmall" src={StarIcon} />
        <span>{rating}</span>
        <MetadataRatingMax>/10</MetadataRatingMax>
      </MetadataRating>
    );
  }, [props.rating]);

  const renderCountries = useCallback(() => {
    if (!props.country) {
      return undefined;
    }
    return (
      <React.Fragment key="metadata-countries">
        <span>{props.country[0]}</span>
        <MetadataSeparator padding={4} />
      </React.Fragment>
    );
  }, [props.country]);

  const renderYear = useCallback(() => {
    if (!props.year) {
      return;
    }
    return (
      <React.Fragment key="metadata-year">
        <span>{props.year}</span>
        <MetadataSeparator padding={4} />
      </React.Fragment>
    );
  }, [props.year]);

  const renderDuration = useCallback(() => {
    if (!props.duration) {
      return null;
    }
    const split = props.duration.split(':').map((e) => parseInt(e));
    const duration = `${split[0] ? `${split[0]}ч.` : ''}${split[1] ? ` ${split[1]}м.` : ''}`;
    return <DescriptionDuration key="metadata-duration">{duration}</DescriptionDuration>;
  }, [props.duration]);

  const resolution = useMemo(() => {
    if (isEmpty(props.resolution) || !props.resolution) {
      return undefined;
    }
    return parseInt(props.resolution.split('x')[1]);
  }, [props.resolution]);

  return (
    <DescriptionRowContainer>
      {renderYear()}
      {renderCountries()}
      <MetadataCategory
        major_category={props.major_category}
        minor_categories={props.minor_categories}
      />
      {renderDuration()}
      {resolution && <QualityIcon size="large" resolutionHeight={resolution} noMargin />}
      {renderRating()}
    </DescriptionRowContainer>
  );
}
export default React.memo(MetadataStrip);
