import { DefaultFadeIn } from 'App.styles';
import { ClampContainerMixin, H1, P1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
const {
  CAROUSEL_CONTAINER_HEIGHT,
  DEFAULT_ANIMATION_DURATION,
  MOUNT_FADEIN_TIMEOUT,
  background_color_dark,
  MENU_SIZE,
  background_color_lighter
} = variables;

GlobalStyle.inject`
.carousel-container {
  width: 100%;
  height: ${CAROUSEL_CONTAINER_HEIGHT}px;
  position: relative;
  opacity: 0;
  display: flex;
  &.visible {
    opacity: 1;
  }
}
.carousel-view-container {
  width: 100%;
  display: flex;
  height: ${variables.CAROUSEL_VIEW_HEIGHT_EXPANDED}px;
  transform: 
    scale3d(${variables.CAROUSEL_VIEW_WIDTH_SCALE}, ${variables.CAROUSEL_VIEW_HEIGHT_SCALE}, 1);
  transform-origin: center;
  transition: transform ${DEFAULT_ANIMATION_DURATION}ms, opacity ${MOUNT_FADEIN_TIMEOUT}ms;
  mask-image: linear-gradient( to top, transparent 0%, black 20% );

  &.disable-expanding {
    height: 100%;
  }

  &.expanded {
    transform: scale3d(1, 1, 1);
    mask-image: unset;

    .control-dots {
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-root,
  .carousel {
    overflow: visible;

    .renderIfVisible-placeholder  {
      width: 100%;
      height: ${variables.CAROUSEL_VIEW_HEIGHT_EXPANDED}px;
    }

    .slide:after {
      content: '';
      bottom: -${getScalablePixel(1)}px;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 59%,
        ${background_color_dark} 100%
      );
    }
  }
  .control-dots {
    display: flex;
    height: ${variables.CAROUSEL_INDICATOR_FOCUSED_HEIGHT}px;
    align-items: center;
    justify-content: center;
    transition: transform ${DEFAULT_ANIMATION_DURATION}ms;
    transform:
      translate3d(0,-${variables.CAROUSEL_VIEW_HEIGHT_EXPANDED - CAROUSEL_CONTAINER_HEIGHT}px,0)
      scale(${variables.CAROUSEL_VIEW_WIDTH_EXPANDED / variables.CAROUSEL_VIEW_WIDTH});
  }


  .carousel-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${hexToRGB(variables.color_grey_10, 0.3)};
    width: ${variables.CAROUSEL_INDICATOR_WIDTH}px;
    height: ${variables.CAROUSEL_INDICATOR_HEIGHT}px;
    border-radius: ${variables.CAROUSEL_INDICATOR_BORDER_RADIUS}px;
    margin: 0 ${variables.CAROUSEL_INDICATOR_MARGIN}px;
    position: relative;
    transition-property: width, height;
    transition-duration: ${variables.BANNER_TRANSITION_DURATION}ms;
    will-change: background, width , height;
    overflow: hidden;
    z-index: 1;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -webkit-backface-visibily: hidden;

    &.selected {
      width: ${variables.CAROUSEL_INDICATOR_SELECTED_WIDTH}px;
      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: ${variables.color_grey_10};
        opacity: 0.8;
        animation: roundtime ${variables.BANNER_AUTOSCROLL_TIMEOUT}ms linear forwards;
        transform-origin: left;
      }
    }

    &.initial {
      transition: none !important;
    }

    &.focused {
      &:after {
        display: none !important;
      }
    }

    &.focused.selected {
      width: ${variables.CAROUSEL_INDICATOR_FOCUSED_WIDTH}px;
      height: ${variables.CAROUSEL_INDICATOR_FOCUSED_HEIGHT}px;
      background: ${hexToRGB(variables.color_grey_10)};
    }

    @keyframes roundtime {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  }
}
.banner-image {
  width: ${variables.CAROUSEL_VIEW_WIDTH}px;
  height: ${variables.CAROUSEL_VIEW_HEIGHT_EXPANDED}px;
  object-fit: cover;
  opacity: 1;
  position: relative;
  transition: opacity ${variables.BANNER_AUTO_PLAY_FADE_IN_DURATION}ms;
  
  &.unmount-video {
    transition: opacity 0ms;
  }
  &.hide {
    opacity: 0;
  }

}

.error-banner-container {
  padding-top: ${MENU_SIZE}px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(50% 50%,${hexToRGB(background_color_lighter, 0.2)},  rgba(0,0,0,0) );

  img {
    width: ${getScalablePixel(183)}px;
  }

  ${H1} {
    font-size: ${getScalablePixel(40)}px;
    text-transform: uppercase;
  }
  ${P1} {
    opacity: 0.6;
  }
}

.banner-view {
  width: 100%;
  height: ${variables.CAROUSEL_VIEW_HEIGHT_EXPANDED}px;
  position: relative;

  .banner-preview-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: ${variables.background_color_dark};
    &.show {
      opacity: 1;

      .banner-preview-text-container {
        opacity: 1;
      }
    }

    .banner-preview-text-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: ${variables.CAROUSEL_INDICATOR_FOCUSED_HEIGHT * 2}px;
      left: ${variables.SAFE_AREA_VIEW_PADDING}px;
      text-align: left;
      z-index: 1;
      opacity: 0;
      max-width: 40%;
      transition: 
        opacity ${variables.BANNER_TRANSITION_DURATION}ms ${variables.BANNER_TRANSITION_DURATION}ms;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 200%;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 0;
        background: radial-gradient(${background_color_dark} 90%, transparent);
        filter: blur(${getScalablePixel(90)}px);
      }

      .title, .subtitle {
        z-index: 1;
      }

      .title {
        font-size: ${getScalablePixel(52)}px;
        color: ${variables.color_grey_10};
        font-weight: bold;
        margin-bottom: ${getScalablePixel(16)}px;
        max-width: 80%;
        ${ClampContainerMixin({ lines: 2 })}
      }
      .subtitle {
        width: 100%;
        font-size: ${getScalablePixel(26)}px;
        color: ${variables.color_grey_30};
        font-weight: normal;
        ${ClampContainerMixin({ lines: 5 })}
      }
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.carousel-indicator {
  .icon {
    position: relative;
    ${DefaultFadeIn}
    z-index: 1;
  }
}

`;
