import { H1, P1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';

GlobalStyle.inject`
.tv-channels-banner-background {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -${variables.MENU_SIZE}px;
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    ${H1}, ${P1} {
      opacity: 0.3;
      z-index: 1;
      text-transform: uppercase;
      line-height: 1;
    }

    ${H1} {
      font-size: ${getScalablePixel(82)}px;
      letter-spacing: ${getScalablePixel(9)}px;
    }
    ${P1} {
      font-size: ${getScalablePixel(28)}px;
      letter-spacing: ${getScalablePixel(1)}px;
    }
  }
`;
