import { ClampContainerMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

const {
  LIST_ITEM_WIDTH,
  LIST_ITEM_HEIGHT,
  background_color_light,
  STRIPE_ITEM_ANIMATION_DURATION,
  font_color_dark,
  font_color_c,
  font_color_f,
  background_color_darker
} = variables;

GlobalStyle.inject`
.live-channel-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: ${LIST_ITEM_WIDTH - variables.SAFE_AREA_VIEW_PADDING}px;
    height: ${LIST_ITEM_HEIGHT}px;

    .title-thumbnail-container, .current-epg-container {
      background: ${hexToRGB(background_color_light)};
    }

    &.paid, &.locked {
      span, .logo {
        opacity: 0.4 !important;
      }
    }

    & > *:not(.logo-container) {
      transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
      will-change: opacity;
      opacity: 0.5;
    }

    &.focused {
      & > *:not(.logo-container) {
        transition: opacity ${STRIPE_ITEM_ANIMATION_DURATION}ms;
        opacity: 0.8;
      }
      
      .logo-container {
        background: ${font_color_dark};
      }
    }

    .logo-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: ${getScalablePixel(267)}px;
      height: 100%;
      background: ${background_color_light};

      .logo {
        width: ${getScalablePixel(144)}px;
        height: ${getScalablePixel(51)}px;
      }

      .title {
        ${ClampContainerMixin({ lines: 1 })}
        font-size: ${getScalablePixel(26)}px;
        font-weight: bold;
        color: white;
        flex: 1;
        text-align: center;
      }
    }


    .title-thumbnail-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: ${getScalablePixel(402)}px;
      height: 100%;
      overflow: hidden;
      box-shadow: inset ${getScalablePixel(4)}px 0 ${getScalablePixel(29)}px 0 rgba(0, 0, 0, 0.55);

      .thumbnail {
        width: ${getScalablePixel(244)}px;
        mask-image: linear-gradient(to right, transparent, black);
      }

      .channel-name {
        flex: 1;
        ${ClampContainerMixin({ lines: 1 })}
        font-size: ${getScalablePixel(20)}px;
        font-weight: normal;
        color: white;
        text-align: left;
        padding-left: ${getScalablePixel(20)}px;
        margin-right: -50px;
      }
    }

    .time-slot, .separator {
      font-size: ${getScalablePixel(20)}px;
      font-weight: normal;
      color: ${font_color_c};
      
      &.separator {
        font-weight: bold;
      }
    }

    .epg-title {
      ${ClampContainerMixin({ lines: 1 })}
      font-size: ${getScalablePixel(25.5)}px;
      font-weight: normal;
      color: ${font_color_f};
    }

    .current-epg-container, .next-epg-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      & > *:not(.progressbar) {
        padding: 0 ${getScalablePixel(20)}px;
      }
      
      &.current-epg-container {
        flex: 1;
        .epg-title {
          font-weight: bold;
        }

        .info-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .progressbar {
          width: 100%;
          height: ${getScalablePixel(5)}px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          overflow: hidden;

          & .value {
            width: 100%;
            height: 100%;
            background: ${font_color_f};
            opacity: 0.3;
            will-change: transform;
          }
        }
      }
  
      &.next-epg-container {
        width: ${getScalablePixel(378)}px;
        height: 100%;
        opacity: 0.3;
        background-image: linear-gradient(to right, ${background_color_darker} 71%, rgba(0, 0, 0, 0) 88%);
      }
    }
  }
  .tv-channel-item {
    &:after {
      outline: none;
      border-style: solid;
      border-width: 4px;
      border-image-source: linear-gradient(90deg, transparent 0%, ${hexToRGB(
        variables.color_focused,
        1
      )} 50%, transparent 100%);
      border-image-slice: 1;
    }
  }
`;
