import { getScalablePixel } from 'common/utils';
import React from 'react';

interface MenuSelectorProps {
  width?: number;
  position?: number;
}
function MenuSelector({ width, position, children }: React.PropsWithChildren<MenuSelectorProps>) {
  const transform = `translate3d(${getScalablePixel(position || 0)}px, 0px, 0px) scaleX(${width})`;
  return (
    <div className="menu-selector" style={{ transform }}>
      {children}
    </div>
  );
}

export default React.memo(MenuSelector);
