import { REMIND_TRIAL_EXPIRATION_BEFORE_DAYS } from 'common/config/constants';
import { LocalStorageTrialObject } from 'common/interfaces';
import { getToday, getTrialObject, setTrialObject } from 'common/services/helpers';
import { store } from 'index';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';

export const TIME_LOGIC_UPDATE_INTERVAL = 60 * 1000;

export interface TimeObject {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  word: string;
  wordTime: string;
  number: number;
  expired?: boolean;
}

export const timeLogicFunction = (endDate: number) => {
  const { i18n } = store.getState();
  const now = DateTime.fromMillis(getToday().getTime());
  const end = DateTime.fromSeconds(endDate);
  const timeObject = {
    ...end.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject()
  } as TimeObject;
  if (timeObject.days >= 1) {
    timeObject.word = i18n.trial.remains;
    timeObject.wordTime = i18n.trial.days;
    timeObject.number = timeObject.days + 1;
  } else if (timeObject.days === 1 || (timeObject.days === 0 && timeObject.hours >= 23)) {
    timeObject.word = i18n.trial.remain;
    timeObject.wordTime = i18n.trial.day;
    timeObject.number = 1;
  } else if (
    timeObject.days === 0 &&
    timeObject.hours <= 22 &&
    timeObject.hours > 0 &&
    timeObject.hours !== 0
  ) {
    timeObject.word = i18n.trial.remains;
    timeObject.wordTime = i18n.trial.hours;
    timeObject.number = timeObject.hours + 1;
  } else if (timeObject.minutes <= 59 && timeObject.hours <= 0 && timeObject.seconds >= 0) {
    timeObject.word = i18n.trial.under;
    timeObject.wordTime = i18n.trial.hour;
    timeObject.number = 1;
  } else if (timeObject.seconds < 0) {
    timeObject.expired = true;
  }
  return timeObject;
};

const getDaysDiff = (endDate: number) => {
  const now = DateTime.fromMillis(getToday().getTime());
  const end = DateTime.fromSeconds(endDate);
  const timeObject = {
    ...end.diff(now, ['days', 'hours']).toObject()
  };
  return timeObject;
};

export const checkNeedsTrialReminderPopup = (userDetails: any) => {
  // Check if current user is a trial account
  if (!isEmpty(userDetails?.trial)) {
    const expireAfter = getDaysDiff(userDetails?.trial.expire);
    const trialObject = getTrialObject() as LocalStorageTrialObject;
    const daysBeforeLastReminder = trialObject?.lastReminderShown
      ? getDaysDiff(trialObject?.lastReminderShown).days
      : -1;
    // Check trial expiration
    // Check last timestamp when the reminder was shown
    if (
      !isNil(expireAfter.days) &&
      expireAfter.days <= REMIND_TRIAL_EXPIRATION_BEFORE_DAYS &&
      !isNil(daysBeforeLastReminder) &&
      daysBeforeLastReminder < 0
    ) {
      // Save last timestamp in which the reminder was shown
      setTrialObject({ ...trialObject, lastReminderShown: getToday().getTime() / 1000 });
      return true;
    }
  }
  return false;
};
