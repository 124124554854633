import {
  doesFocusableExist,
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { getRemoteKeyName } from 'common/utils';
import { useStateCallback } from 'common/utils/hooks';
import { isEmpty, isNull, uniqueId } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { PopupContainer as PopupsContainer, PopupPrompt } from './Popup.styles';

interface PopupOptions {
  id?: string;
  type?: 'primary' | 'secondary';
  removeSpacing?: boolean;
  content: React.ReactElement;
  returnToLastFocused?: boolean;
  fullscreen?: boolean;
  background?: string;
  onOpen?: (id: string) => void;
  onClose?: (id: string) => void;
  preventBackspace?: boolean;
}

interface PopupElement extends PopupOptions {
  lastFocusedKey?: string;
}

interface PopupContext {
  hasPopups?: boolean;
  openPopup: (popup: PopupOptions) => string;
  closePopup: (id: string, onClose?: VoidFunction) => void;
  getPopup: (id: string) => PopupElement | null | undefined;
}

const defaultOptions: Partial<PopupOptions> = {
  returnToLastFocused: true,
  type: 'secondary'
};

//@ts-ignore
const PopupContextInner = React.createContext<PopupContext>();

export const usePopup = () => useContext(PopupContextInner);

export function PopupProvider(props: React.PropsWithChildren) {
  const [popups, setPopups] = useStateCallback<PopupElement[]>([]);
  const popupsRef = useRef(popups);

  const { ref, focusKey, hasFocusedChild } = useFocusable({
    focusKey: 'POPUP',
    isFocusBoundary: true,
    trackChildren: true,
    focusable: !isEmpty(popups)
  });

  const hasPopups = useMemo(() => popups.length > 0, [popups]);

  const handleOpenPopup = useCallback((options: PopupOptions) => {
    options = { ...defaultOptions, ...options };
    const id = uniqueId('popup');
    const newPopup: PopupElement = {
      id,
      ...options,
      lastFocusedKey: options.returnToLastFocused ? getCurrentFocusKey() : undefined
    };
    setPopups((current) => {
      const newPopups = [
        ...current,
        ...(current.findIndex(({ id }) => id === newPopup.id) === -1 ? [newPopup] : [])
      ];
      popupsRef.current = newPopups;
      return newPopups;
    });
    newPopup.onOpen && newPopup.onOpen(id);
    return id;
  }, []);
  const handleClosePopup = useCallback((id: string, onClose?: VoidFunction) => {
    let lastFocusedKey: string | undefined | false;
    let popup: PopupElement | undefined;
    setPopups(
      (currentPopups) => {
        popup = currentPopups.find((p) => p.id === id);
        if (popup) {
          const popupIndex = currentPopups.indexOf(popup);
          const newPopups = [...currentPopups];
          newPopups.splice(popupIndex, 1);
          lastFocusedKey = newPopups.length < 1 && currentPopups[popupIndex].lastFocusedKey;
          popupsRef.current = newPopups;
          return newPopups;
        }
        return currentPopups;
      },
      () => {
        // Return focus to lastFocusedKey or auto restore to APP_CONTAINER
        // so the SpatialNavigation engine can handle the container focus
        if (lastFocusedKey && doesFocusableExist(lastFocusedKey)) {
          setFocus(lastFocusedKey);
        } else {
          setFocus('APP_CONTAINER');
        }
        popup?.onClose && popup.onClose(id);
        onClose && onClose();
      }
    );
  }, []);

  const getPopup = useCallback((id: string) => {
    try {
      const popup = popupsRef.current.find((p) => p?.id === id);
      return isNull(popup) ? null : popup;
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      hasPopups: hasPopups,
      openPopup: handleOpenPopup,
      closePopup: handleClosePopup,
      getPopup: getPopup
    }),
    [popups]
  );

  const forceFocus = useCallback(() => {
    ref.current?.focus();
    setFocus('POPUP');
  }, []);

  useEffect(() => {
    hasPopups && forceFocus();
  }, [hasPopups]);

  useEffect(() => {
    // Make sure we always have focus on the popups when we have popups available
    hasPopups && !hasFocusedChild && forceFocus();
  }, [hasPopups, hasFocusedChild]);

  useEffect(() => {
    if (hasFocusedChild) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      // Close last opended popup
      if (getRemoteKeyName(e.keyCode) === 'BACK' && popups.length > 0) {
        const id = popups[popups.length - 1]?.id;
        if (popups[popups.length - 1].preventBackspace) {
          return;
        }
        id && handleClosePopup(id);
      }
    },
    [popups]
  );

  const renderPopups = () => {
    return popups.map((popup) => {
      return (
        <PopupPrompt
          key={popup.id}
          fullscreen={popup.fullscreen}
          style={{ background: popup.background }}
          type={popup.type!}
          removeSpacing={popup.removeSpacing}
        >
          {popup.content}
        </PopupPrompt>
      );
    });
  };

  return (
    <PopupContextInner.Provider value={contextValue}>
      {props.children}
      <FocusContext.Provider value={focusKey}>
        <PopupsContainer ref={ref} visible={popups.length > 0} tabIndex={hasFocusedChild ? 0 : -1}>
          {renderPopups()}
        </PopupsContainer>
      </FocusContext.Provider>
    </PopupContextInner.Provider>
  );
}
