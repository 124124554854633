import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { translationPacket } from 'common/actions';
import { RadioButton } from 'common/components/common/style';
import { useDrawer } from 'common/contexts/drawer';
import { FocusableItem } from 'common/interfaces';
import { getKey } from 'common/utils';
import { useHoverable } from 'common/utils/hooks';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  PlayerSettingsDrawerSelection,
  PlayerSettingsDrawerSelectionTitle,
  PlayerSettingsDrawerTitle
} from './Controls.styles';

interface SelectionProps extends FocusableItem {
  value: string;
  selected?: boolean;
  index: number;
  onIndexSelect?: PlayerSettingsDrawerProps['onIndexSelect'];
}
function Selection(props: SelectionProps) {
  const onIndexSelect = useCallback(() => {
    // If the selection is already selected, do nothing
    if (props.selected) return;

    props.onIndexSelect && props.onIndexSelect(props.index);
    closeDrawer('player-settings');
  }, [props.index, props.selected]);

  const { closeDrawer } = useDrawer();
  const { ref, focusSelf, focused } = useFocusable({
    focusKey: props.focusKey,
    onEnterPress: onIndexSelect
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  useEffect(() => {
    props.selected && focusSelf();
  }, [props.selected]);

  return (
    <PlayerSettingsDrawerSelection
      ref={ref}
      focused={active}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onIndexSelect}
    >
      <RadioButton checked={props.selected} />
      <PlayerSettingsDrawerSelectionTitle>{props.value}</PlayerSettingsDrawerSelectionTitle>
    </PlayerSettingsDrawerSelection>
  );
}

export interface PlayerSettingsDrawerProps {
  // Redux injected
  i18n?: typeof translationPacket;
  //
  type: 'subtitles' | 'audio' | 'quality';
  selections: SelectionProps['value'][];
  currentValue?: string | number;
  onIndexSelect?: (index: number) => void;
}
function PlayerSettingsDrawer(props: PlayerSettingsDrawerProps) {
  useFocusable({
    saveLastFocusedChild: false,
    focusable: false,
    trackChildren: false,
    preferredChildFocusKey: getKey('drawer', props.currentValue, props.type)
  });
  const injectValues = ['quality', 'subtitles'].includes(props.type);
  const indexedBasedType = ['quality'].includes(props.type);
  if (isEmpty(props.i18n)) {
    return <></>;
  }
  const selections = [
    ...(props.type === 'quality' ? [props.i18n.playerSettings.auto] : []),
    ...(props.type === 'subtitles' ? [props.i18n.playerSettings.disabled] : []),
    ...(props.type === 'quality' ? [...props.selections].reverse() : props.selections)
  ];
  let indexes = selections.map((_, i) => (injectValues ? i - 1 : i));
  if (props.type === 'quality') {
    indexes = [indexes[0], ...indexes.slice(1).reverse()];
  }
  return (
    <>
      <PlayerSettingsDrawerTitle>{props.i18n.playerSettings[props.type]}</PlayerSettingsDrawerTitle>
      {selections.map((s, i) => {
        const index = injectValues ? i - 1 : i;
        const comparableValue = indexedBasedType ? indexes[i] : s;
        // If no current value select the first one by default
        const selected = isNil(props.currentValue)
          ? index === -1
          : props.currentValue === comparableValue;
        //
        return (
          <Selection
            key={index}
            value={s}
            index={indexes[i]}
            selected={selected}
            focusKey={getKey('drawer', comparableValue, props.type)}
            onIndexSelect={props.onIndexSelect}
          />
        );
      })}
    </>
  );
}
const mapStateToProps = ({ i18n }: any) => ({ i18n });

export default React.memo(connect(mapStateToProps, {})(PlayerSettingsDrawer));
