import TrialEndIcon from '@assets/media/end-trial-icon.svg';
import QRCodeLogo from '@assets/media/qr-code-brand-logo.png';
import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { DefaultButton } from 'common/components/button';
import { Row } from 'common/components/common/style';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import { TrialEndStyle } from 'common/components/trial/Trial.styles';
import { ROUTE_SIGN_IN } from 'common/config/constants';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import { usePageNavigation, useSignOut } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

const TrialEnd = ({ i18n, userDetails }: any) => {
  const signOut = useSignOut();
  //Focus funciton
  const { ref, focusKey } = useFocusable({
    isFocusBoundary: true,
    autoRestoreFocus: true
  });
  const navigate = usePageNavigation();

  //Init useEffect
  useEffect(() => {
    setFocus('SIGN_IN');
  }, []);

  //Enter home button handler
  const onSignIn = useCallback(async () => {
    navigate(ROUTE_SIGN_IN, true);
    await signOut();
  }, []);

  const renderQRCode = useCallback(() => {
    const value = variables.GET_JOIN_URL_PATH(userDetails?.email);
    if (isEmpty(value)) {
      return null;
    }
    return (
      <QRCodeSVG
        value={value!}
        includeMargin
        width={getScalablePixel(280)}
        height={getScalablePixel(280)}
        level="Q"
        imageSettings={{
          src: QRCodeLogo,
          excavate: true,
          width: getScalablePixel(53),
          height: getScalablePixel(16)
        }}
      />
    );
  }, [userDetails.email]);

  // Check if focusKey is outside of the container to make sure focus is not lost outside of the container
  useEffect(() => {
    const handleKeyPress = () => {
      const key = getCurrentFocusKey();
      if (key !== 'SIGN_IN') {
        setFocus('SIGN_IN');
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <FocusContext.Provider value={focusKey}>
      <TrialEndStyle ref={ref}>
        <div className="row">
          <div className="left">
            <img className="icon-trial" src={TrialEndIcon} />
          </div>
          <div className="right">
            <ProductLogo type="small" />
            <div className="email">{userDetails?.email}</div>
          </div>
        </div>
        <div className="row row-info">
          <div className="left">
            <span className="expire">{i18n?.trial?.endDemoPeriod}</span>
          </div>
          <div className="right">
            <div className="text-right">
              {i18n?.trial?.endDemoText1} <span className="phone">{i18n?.trial?.modal?.phone}</span>
            </div>
            <div className="text-right">{i18n?.trial?.endDemoText2}</div>
          </div>
        </div>
        <div className="row qr-code">
          <div className="left">
            <div className="text-right">{i18n?.trial?.endDemoText3}</div>
            <div className="website">{i18n?.trial?.modal?.webSite}</div>
          </div>
          <div className="right">{renderQRCode()}</div>
        </div>
        <Row className="button-row" justifyContent="flex-end" style={{ marginLeft: '16px 0' }}>
          <div className="left"></div>
          <div className="right">
            <div className="button-container">
              <DefaultButton
                title={i18n?.authentication?.signInAnotherAccount}
                onEnterPress={onSignIn}
                focusKey="SIGN_IN"
                disableMargin
              />
            </div>
          </div>
        </Row>
      </TrialEndStyle>
    </FocusContext.Provider>
  );
};
const mapStateToProps = ({ i18n, profileSettings, userDetails }: any) => ({
  i18n,
  profileSettings,
  userDetails
});

export default React.memo(connect(mapStateToProps, {})(TrialEnd));
