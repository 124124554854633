import {
  RETRIEVE_PRISMIC_RADIO_IMAGES,
  RETRIEVE_PRISMIC_SCREENSAVER_IMAGES
} from 'common/constants/action-types';

export interface PrismicImage {
  dimensions: { width: string; height: string };
  alt?: string;
  copyright?: string;
  url: string;
}

export interface PrismicRadioImages {
  radio_wallpaper_image: PrismicImage;
  radio_cover_image: PrismicImage;
  radio_thumbnail_image: PrismicImage;
}

export interface ItemMeta {
  type: string;
  text: string;
  spans: any[];
}

export interface SimpleItem {
  title: ItemMeta[];
  location: ItemMeta[];

  background: PrismicImage;
}

export interface PrismicScreensaver {
  simple_items: SimpleItem[];
}

export interface IPrismic {
  radioImages?: PrismicRadioImages | null;
  screensaver?: PrismicScreensaver | null;
}

const INITIAL_STATE: IPrismic = {
  radioImages: null,
  screensaver: null
};

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case RETRIEVE_PRISMIC_RADIO_IMAGES: {
      return {
        ...state,
        radioImages: action.payload
      };
    }
    case RETRIEVE_PRISMIC_SCREENSAVER_IMAGES: {
      return {
        ...state,
        screensaver: action.payload
      };
    }
    default:
      return state;
  }
}
