import { CHANNEL_LIST_SET_CATEGORY } from 'common/constants/action-types';
import { Category } from 'common/constants/data-types';
import { store } from 'index';

export const setChannelListCategory = (category: Partial<Category>) => {
  return (dispatch: typeof store.dispatch) => {
    dispatch({
      type: CHANNEL_LIST_SET_CATEGORY,
      payload: category
    });
  };
};

export const clearChannelListCategory = () => {
  return (dispatch: typeof store.dispatch) => {
    dispatch({
      type: CHANNEL_LIST_SET_CATEGORY,
      payload: undefined
    });
  };
};
