import ExitIcon from '@assets/media/exit-icon.svg';
import { translationPacket } from 'common/actions';
import { Prompt } from 'common/components/promp';
import { closeCurrentApp } from 'common/utils';
import React from 'react';
import { connect } from 'react-redux';

interface LogoutPopupProps {
  // Redux injected
  i18n: typeof translationPacket;
  //
}
function ExitPopup({ i18n }: LogoutPopupProps) {
  return (
    <Prompt
      name="EXIT-POPUP"
      title={i18n.app.exitTitle}
      subtitle={i18n.app.exitDescription}
      confirmTitle={i18n.app.exitButton}
      closeTitle={i18n.common.remove}
      icon={ExitIcon}
      onConfirm={closeCurrentApp}
    />
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(ExitPopup));
