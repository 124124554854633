import TVImageBackground from '@assets/media/tv_channels_background.png';
import { translationPacket } from 'common/actions';
import { H1, P1 } from 'common/components/Typography';
import { Image } from 'common/components/image';
import React from 'react';
import { connect } from 'react-redux';
import './TVChannelsBannerBackground.styles';

interface TVChannelsBannerBackgroundProps {
  i18n: typeof translationPacket;
}

function TVChannelsBannerBackground(props: TVChannelsBannerBackgroundProps) {
  return (
    <div className="tv-channels-banner-background">
      <H1>{props.i18n?.tvChannelsPage.list}</H1>
      <P1>{props.i18n?.tvChannelsPage.withTvChannels}</P1>
      <Image loading="eager" src={TVImageBackground} />
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps)(TVChannelsBannerBackground));
