import CriticalErrorIcon from '@assets/media/critical-error-icon.svg';
import { translationPacket } from 'common/actions';
import { Column } from 'common/components/common/style';
import { FullscreenError } from 'common/components/full-screen-error';
import { H1, H3 } from 'common/components/Typography';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

interface ErrorBoundaryFallbackProps {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
  i18n: typeof translationPacket;
}

const ErrorBoundaryFallback = (props: ErrorBoundaryFallbackProps) => {
  const hashId = useMemo(() => {
    return props.eventId.slice(0, 8).toUpperCase();
  }, [props.eventId]);

  return (
    <FullscreenError
      icon={CriticalErrorIcon}
      title={props.i18n?.errorPage?.criticalError}
      description={
        <Column style={{ textAlign: 'start' }}>
          <H3 style={{ fontWeight: '500', opacity: 0.6 }}>{props.i18n?.errorPage?.errorNumber}</H3>
          <H1 style={{ fontWeight: '500' }}>{hashId}</H1>
        </Column>
      }
      info={props.i18n?.errorPage?.description}
      primaryButtonTitle={props.i18n?.errorPage?.restartButton}
    />
  );
};

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(ErrorBoundaryFallback));
