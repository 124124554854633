import AudioIcon from '@assets/media/audio-icon.svg';
import SubtitlesIcon from '@assets/media/subtitles-icon.svg';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { SimpleButton } from 'common/components/button';
import Icon from 'common/components/Icon';
import { QualityIcon } from 'common/components/quality-icon';
import { P1 } from 'common/components/Typography';
import { useDrawer } from 'common/contexts/drawer';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getClassName } from 'common/utils';
import { Level } from 'hls.js';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { PlayerControlsProps } from './Controls';
import PlayerSettingsDrawer, { PlayerSettingsDrawerProps } from './PlayerSettingsDrawer';
import { convertToLanguage, findLanguageName } from './utils';

interface PlayerSettingsProps {
  // Redux injected
  channelId: string;
  currentLevel: any;
  audioTracks?: IVideoPlayer['video']['audioTracks'];
  currentAudioTrack?: IVideoPlayer['video']['currentAudioTrack'];
  subtitleTracks?: IVideoPlayer['video']['subtitleTracks'];
  currentSubtitleTrack?: IVideoPlayer['video']['currentSubtitleTrack'];
  levels: Level[];
  autoLevel?: boolean;
  //
  showControls: PlayerControlsProps['showControls'];
  toggleControls: PlayerControlsProps['showControls'];
  changeSubtitleTrack: PlayerControlsProps['changeSubtitleTrack'];
  changeAudioTrack: PlayerControlsProps['changeAudioTrack'];
  changeQuality: PlayerControlsProps['changeQuality'];
}

function PlayerSettings(props: PlayerSettingsProps) {
  const audioTracks = useMemo(() => convertToLanguage(props.audioTracks), [props.audioTracks]);
  const previousAudioTrack = useRef<string | undefined>();
  const currentAudioTrack = useMemo(() => {
    const value = findLanguageName(audioTracks, props.currentAudioTrack);
    previousAudioTrack.current = value;
    return value;
  }, [audioTracks, props.currentAudioTrack]);

  const subtitleTracks = useMemo(
    () => convertToLanguage(props.subtitleTracks),
    [props.subtitleTracks]
  );
  const previousSubtitleTrack = useRef<string | undefined>();
  const currentSubtitleTrack = useMemo(() => {
    const value = findLanguageName(subtitleTracks, props.currentSubtitleTrack);
    previousSubtitleTrack.current = value;
    return value;
  }, [subtitleTracks, props.currentSubtitleTrack]);

  const { openDrawer } = useDrawer();
  const { focusKey, ref, hasFocusedChild } = useFocusable({
    trackChildren: true,
    focusKey: 'PLAYER_SETTINGS'
  });

  const getSettingValue = useCallback(
    (value?: string, previousValue?: React.MutableRefObject<string | undefined>) => {
      try {
        if (isNil(value)) {
          throw Error('No Value');
        }
        const newValue = value.substring(0, 3);
        if (previousValue) {
          previousValue.current = newValue;
        }
        return newValue;
      } catch (error) {
        // show previous value
        return previousValue?.current || 'off';
      }
    },
    []
  );

  const getSettingValues = useCallback((objectList?: any) => {
    try {
      if (isEmpty(objectList)) {
        throw Error('No Value');
      }
      return objectList;
    } catch (error) {
      return [];
    }
  }, []);

  const getDisabledState = useCallback((objectList?: any) => {
    return isEmpty(objectList) || objectList.length <= 1;
  }, []);

  const subtitlesDisabled = useMemo(
    () => getDisabledState(props.subtitleTracks),
    [props.subtitleTracks]
  );

  const audioDisabled = useMemo(() => getDisabledState(props.audioTracks), [props.audioTracks]);

  const currentLevelValue = useMemo(() => {
    if (!isEmpty(props.levels) && !isNil(props.currentLevel) && props.levels[props.currentLevel]) {
      return props.autoLevel ? 'AUTO' : `${props.levels[props.currentLevel].height}p`;
    }
  }, [props.currentLevel, props.levels, props.autoLevel]);

  const levelsRef = useRef(props.levels);
  const currentLevelRef = useRef(props.currentLevel);
  const currentLevelValueRef = useRef(currentLevelValue);

  // Skipping the use of useEffect in order to ensure
  // that the refs are up to date with the rerender
  if (!isNil(props.currentLevel) && props.levels && currentLevelValue) {
    currentLevelRef.current = props.currentLevel;
    levelsRef.current = props.levels;
    currentLevelValueRef.current = currentLevelValue;
  }

  const openSettingsDrawer = (
    type: PlayerSettingsDrawerProps['type'],
    values?: string[],
    currentValue?: string | number
  ) => {
    if (isEmpty(values) || !values) {
      return;
    }
    const changeMap = {
      audio: props.changeAudioTrack,
      subtitles: props.changeSubtitleTrack,
      quality: props.changeQuality
    };
    openDrawer({
      id: 'player-settings',
      content: (
        <PlayerSettingsDrawer
          type={type}
          onIndexSelect={changeMap[type]}
          selections={values}
          currentValue={currentValue}
        />
      ),
      positionContent: 'center',
      onOpen: () => props.showControls(),
      onClose: () => props.toggleControls()
    });
  };

  if (isEmpty(props.channelId)) {
    return <></>;
  }
  return (
    <FocusContext.Provider value={focusKey}>
      <div className="player-settings-container" ref={ref}>
        <SimpleButton
          expandOnFocus
          disabled={subtitlesDisabled}
          onEnterPress={() =>
            openSettingsDrawer('subtitles', getSettingValues(subtitleTracks), currentSubtitleTrack)
          }
        >
          <P1>{getSettingValue(currentSubtitleTrack, previousSubtitleTrack)}</P1>
          <Icon src={SubtitlesIcon} size="small" />
        </SimpleButton>
        <div
          className={getClassName('player-settings-separator', {
            'has-focused-child': hasFocusedChild
          })}
          key="separator-1"
        />
        <SimpleButton
          expandOnFocus
          disabled={audioDisabled}
          onEnterPress={() =>
            openSettingsDrawer('audio', getSettingValues(audioTracks), currentAudioTrack)
          }
        >
          <P1>{getSettingValue(currentAudioTrack, previousAudioTrack)}</P1>
          <Icon src={AudioIcon} size="small" />
        </SimpleButton>
        <div
          className={getClassName('player-settings-separator', {
            'has-focused-child': hasFocusedChild
          })}
          key="separator-2"
        />
        {!isEmpty(levelsRef.current) &&
          !isNil(currentLevelRef.current) &&
          levelsRef.current[currentLevelRef.current] && (
            <SimpleButton
              expandOnFocus
              onEnterPress={() =>
                openSettingsDrawer(
                  'quality',
                  levelsRef.current.map((e) => `${e.height}p`),
                  props.autoLevel ? -1 : currentLevelRef.current
                )
              }
            >
              <P1>{currentLevelValueRef.current}</P1>
              <QualityIcon
                size="small"
                resolutionHeight={levelsRef.current[currentLevelRef.current].height}
              />
            </SimpleButton>
          )}
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ videoPlayer }: any) => {
  const {
    currentLevel,
    audioTracks,
    currentAudioTrack,
    subtitleTracks,
    currentSubtitleTrack,
    levels,
    autoLevel
  } = videoPlayer.video;
  return {
    channelId: videoPlayer.channelId,
    currentLevel,
    audioTracks,
    currentAudioTrack,
    subtitleTracks,
    currentSubtitleTrack,
    autoLevel,
    levels
  };
};

export default React.memo(connect(mapStateToProps)(PlayerSettings));
