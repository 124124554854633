import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import GlobalStyle from 'common/utils/GlobalStyle';
import React from 'react';

GlobalStyle.inject`
.vertical-separator, .player-settings-separator {
  width: 0;
  height: 80%;
  margin: 0 ${getScalablePixel(2)}px;
  opacity: 0.5;
  border: solid ${getScalablePixel(1)}px #fff;

  &.player-settings-separator {
    height: ${getScalablePixel(14)}px;
    transition: margin ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms;
    opacity: 0.5;

    &.has-focused-child {
      opacity: 0;
      margin: ${getScalablePixel(12)}px;
    }
  }
}
`;

interface VerticalSeparatorProps {
  height?: string | number;
  margin?: number;
  opacity?: number;
}

function VerticalSeparator({ height, margin, opacity }: VerticalSeparatorProps) {
  return (
    <div
      className="vertical-separator"
      style={{
        height,
        margin,
        opacity
      }}
    />
  );
}

export default React.memo(VerticalSeparator);
