import {
  CATEGORIES_GET,
  RECORDED_CLEAR_CATEGORIES_EPGS,
  RECORDED_CLEAR_CATEGORY_LIST,
  RECORDED_CLEAR_SUBCATEGORIES_EPGS,
  RECORDED_CLEAR_SUBCATEGORY_LIST,
  RECORDED_GET_CATEGORY_EPGS,
  RECORDED_GET_CATEGORY_LIST_NEXT_PAGE,
  RECORDED_GET_SUBCATEGORY_EPGS,
  RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE,
  RECORDED_RESET
} from 'common/constants/action-types';

const initialState = {
  categories: {},
  subcategoriesEpgs: {},
  categoriesEpgs: {},
  categoryList: {},
  subcategoryList: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_GET:
      return {
        ...state,
        categories: action.payload
      };
    case RECORDED_RESET:
      return {
        ...initialState
      };
    case RECORDED_GET_CATEGORY_EPGS:
      return {
        ...state,
        categoriesEpgs: {
          ...state.categoriesEpgs,
          ...action.payload
        }
      };
    case RECORDED_CLEAR_CATEGORIES_EPGS:
      return {
        ...state,
        categoriesEpgs: {}
      };
    case RECORDED_GET_SUBCATEGORY_EPGS:
      return {
        ...state,
        subcategoriesEpgs: {
          ...state.subcategoriesEpgs,
          ...action.payload
        }
      };
    case RECORDED_CLEAR_SUBCATEGORIES_EPGS:
      return {
        ...state,
        subcategoriesEpgs: {}
      };
    case RECORDED_GET_CATEGORY_LIST_NEXT_PAGE:
      const { data, id } = action.payload;
      const prevCatData = state.categoryList[id] || [];
      const newCatData = data[id];

      const updatedCategory = {
        [id]: newCatData.length > 0 ? [...prevCatData, ...newCatData] : [...prevCatData]
      };
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          ...updatedCategory
        }
      };
    case RECORDED_CLEAR_CATEGORY_LIST:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          ...{ [action.payload.id]: [] }
        }
      };
    case RECORDED_GET_SUBCATEGORY_LIST_NEXT_PAGE:
      const prevSubCatData = state.subcategoriesEpgs[action.payload.id] || [];
      const newSubCatData = action.payload.data[action.payload.id];

      const updatedSubcategory = {
        [action.payload.id]:
          newSubCatData.length > 0 ? [...prevSubCatData, ...newSubCatData] : [...prevSubCatData]
      };
      return {
        ...state,
        subcategoriesEpgs: {
          ...state.subcategoriesEpgs,
          ...updatedSubcategory
        }
      };
    case RECORDED_CLEAR_SUBCATEGORY_LIST:
      return {
        ...state,
        subcategoryList: {
          ...state.subcategoryList,
          ...{ [action.payload.id]: [] }
        }
      };
    default:
      return state;
  }
}
