/* eslint-disable @typescript-eslint/no-var-requires */
import { LoadingIndicator as Spinner } from 'common/components/common/style';
import { getScalablePixel } from 'common/utils';
import { getChromeVersion } from 'common/utils/helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingIndicatorContainer } from './LoadingIndicator.styles';
//@ts-ignore
import RiveAnimation from '@assets/animations/loader.riv';
import { RIVE_SUPPORT_CHROME_VERSION } from 'common/config/constants';
import variables from 'common/config/variables';

const RIVE_SUPPORTED = getChromeVersion() >= RIVE_SUPPORT_CHROME_VERSION;

let useRive: any | undefined;
if (RIVE_SUPPORTED) {
  useRive = require('@rive-app/react-canvas').useRive;
}

const RiveLoader = (props: LoadingIndicatorProps) => {
  const [finish, setFinish] = useState(false);
  const { RiveComponent } = useRive({
    src: RiveAnimation,
    stateMachines: 'loading',
    animations: props.state || 'loading',
    autoplay: true,
    onStop: (e: any) => {
      e.data && e.data.toString().includes('done') && setFinish(true);
    }
  });

  useEffect(() => {
    if (finish) {
      props.onFinish && props.onFinish();
    }
  }, [finish, props.onFinish]);

  if (!RIVE_SUPPORTED) {
    return null;
  }

  return (
    <RiveComponent
      style={{
        width: getScalablePixel(500),
        height: getScalablePixel(500)
      }}
    />
  );
};

export type LoadingState = 'loading' | 'done';
interface LoadingIndicatorProps {
  state?: LoadingState;
  onFinish?: () => void;
}

export default function LoadingIndicator(props: LoadingIndicatorProps) {
  const supported = useMemo(() => !variables.DISABLE_RIVE && RIVE_SUPPORTED, []);

  useEffect(() => {
    if (!supported && props.state?.includes('done') && props.onFinish) {
      props.onFinish();
    }
  }, [props.state, props.onFinish, supported]);

  return (
    <LoadingIndicatorContainer>
      {supported ? <RiveLoader {...props} /> : <Spinner />}
    </LoadingIndicatorContainer>
  );
}
