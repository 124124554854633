export default {
  apiKey: 'AIzaSyA6jnmRCfc3SftYcBd1W1BP9Z652DrufaA',
  authDomain: 'msat-apps.firebaseapp.com',
  databaseURL: 'https://msat-apps.firebaseio.com',
  projectId: 'msat-apps',
  storageBucket: 'msat-apps.appspot.com',
  messagingSenderId: '730315354316',
  appId: '1:730315354316:web:0f80ab442bb244fc16d269',
  measurementId: 'G-XHDKD8TTH0'
};
