import { ParagraphMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import AnimationDurations from 'common/utils/AnimationDurations';
import GlobalStyle from 'common/utils/GlobalStyle';
const {
  FASTSCROLL_TIMEOUT,
  MOUNT_FADEIN_TIMEOUT,
  SAFE_AREA_VIEW_PADDING,
  STRIPE_ITEM_FOCUSED_SCALE,
  STRIPE_ITEM_PADDING,
  STRIPE_PADDING,
  DEFAULT_ANIMATION_DURATION
} = variables;

const StripeItemTransitionMixin = ({ delay = 0 }) => `
  transition: transform ${AnimationDurations.get('DEFAULT_ANIMATION_DURATION')}ms ${delay}ms,
    opacity ${MOUNT_FADEIN_TIMEOUT}ms;
`;

GlobalStyle.inject`
.stripe-item {
  position: relative;
  background-color: transparent;
  object-fit: contain;
  will-change: transform;
  flex: 1 0 auto;
  max-width: fit-content;
  z-index: 1;
  ${StripeItemTransitionMixin({ delay: 0 })}
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibily: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    outline-width: ${getScalablePixel(4)}px;
    outline-color: ${variables.color_focused};
    outline-style: solid;
    transform: translateY(-50%) scale3d(0,0,0);
    will-change: transform;
  }
  
  &.focused {
    transform: scale3d(${STRIPE_ITEM_FOCUSED_SCALE},${STRIPE_ITEM_FOCUSED_SCALE},${STRIPE_ITEM_FOCUSED_SCALE});
    ${StripeItemTransitionMixin({ delay: FASTSCROLL_TIMEOUT })}
    z-index: 2;

    &:after {
      transform: translateY(-50%) scale3d(1,1,1);
    }
  }

  &.x {
    margin: 0 ${STRIPE_ITEM_PADDING / 2}px;
  }
  &.y {
    margin: ${STRIPE_ITEM_PADDING / 2}px 0;
    &.first {
      margin-top: ${STRIPE_ITEM_FOCUSED_SCALE / 2}%;
    }
  }
 }


 .focusable-stripe-wrapper {
  width: 100%;
  height: 100%;
  /* TODO: uncomment for stripe-wrapper to container the whole height of container */
  /* flex: 1 1 100%; */
  &.x:not(:first-child), &.x:not(:first-child) {
    margin-top: ${getScalablePixel(16)}px;
  }
  &.y:not(:first-of-type) {
    margin-left: ${getScalablePixel(16)}px;
  }

  &.x {
    min-height: fit-content;
  }

  &.y {
    min-width: fit-content;
    /* TODO: uncomment for stripe-wrapper to container the whole height of container */
    /* height: 0; */
  }

  &.focused {
    .focusable-stripe-title {
      opacity: 1;
      transform: scale(1.3);
      transition: opacity ${DEFAULT_ANIMATION_DURATION}ms, transform ${DEFAULT_ANIMATION_DURATION}ms ${FASTSCROLL_TIMEOUT}ms;
    }
  }
}

.focusable-stripe-title {
  ${ParagraphMixin({})}
  font-size: ${getScalablePixel(26)}px;
  opacity: 0.6;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-left: ${SAFE_AREA_VIEW_PADDING}px;
  padding-top: ${getScalablePixel(4)}px;
  display: inline-block;
  transition: opacity ${DEFAULT_ANIMATION_DURATION}ms, transform ${DEFAULT_ANIMATION_DURATION}ms;
  transform-origin: bottom left;
  will-change: opacity, transform;
}

.focusable-stripe-content {
  display: flex;
  align-items: center;
  padding: ${STRIPE_PADDING}px ${SAFE_AREA_VIEW_PADDING}px;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibily: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  will-change: scroll-position;
  box-sizing: content-box;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;

  &.x {
    flex-direction: row;
    padding-right: 0;
  }
  &.y {
    flex-direction: column;
    padding: initial;
    padding-bottom: 0;
  }
}

`;
