import { FILTER_TYPE_LIVE, FILTER_TYPE_RECORDED } from 'common/config/constants';
import {
  SEARCH_CHANNEL_RESULT,
  SEARCH_CLEAR,
  SEARCH_FILTER_RESULT,
  SEARCH_SET_RESULT
} from 'common/constants/action-types';
import { Category, Epg } from 'common/constants/data-types';

export interface SearchState {
  hasSearch?: boolean;
  filteredLive: any;
  filteredSearchLive: any[];
  filteredFuture: Epg[];
  filteredRecorded: Epg[];
  filterLivePreviousCategory: any;
  future: Epg[];
  recorded: Epg[];
  category?: Category;
  representedCategories: Set<unknown>;
  representedFutureCategories: Set<unknown>;
  representedRecordedCategories: Set<unknown>;
}

export default (
  state: SearchState = {
    hasSearch: false,
    filteredLive: [],
    filteredFuture: [],
    filteredSearchLive: [],
    filteredRecorded: [],
    filterLivePreviousCategory: [],
    future: [],
    recorded: [],
    representedCategories: new Set(),
    representedFutureCategories: new Set(),
    representedRecordedCategories: new Set()
  },
  { payload, type }: any
) => {
  switch (type) {
    case SEARCH_SET_RESULT:
      return {
        ...state,
        hasSearch: true,
        filteredFuture: [...payload.future],
        filteredRecorded: [...payload.recorded],
        future: [...payload.future],
        recorded: [...payload.recorded],
        filteredLive: state.filteredLive,
        representedCategories: payload.representedCategories,
        representedFutureCategories: payload.representedFutureCategories,
        representedRecordedCategories: payload.representedRecordedCategories
      };
    case SEARCH_CLEAR:
      return {
        ...state,
        ...payload,
        // hasSearch: payload.shouldResetLiveFilter ? false : true,
        filteredFuture: [],
        filterType: payload.shouldResetLiveFilter ? '' : FILTER_TYPE_LIVE,
        category: payload.shouldResetLiveFilter ? null : state.filterLivePreviousCategory,
        filteredRecorded: [],
        future: [],
        filteredLive: payload.shouldResetLiveFilter ? [] : state.filteredLive,
        recorded: [],
        representedCategories: new Set(),
        representedFutureCategories: new Set(),
        representedRecordedCategories: new Set()
      };
    case SEARCH_FILTER_RESULT:
      return {
        ...state,
        filteredLive:
          payload.filterType === FILTER_TYPE_RECORDED ? state.filteredLive : payload.filteredLive,
        filterLivePreviousCategory:
          payload.filterType === FILTER_TYPE_LIVE ? payload.category : state.category,
        filteredFuture: payload.filteredFuture,
        filteredRecorded: payload.filteredRecorded,
        filterType: payload.filterType,
        category: payload.category
      };
    case SEARCH_CHANNEL_RESULT:
      return {
        ...state,
        filteredSearchLive: payload.filteredSearchLive || state.filteredSearchLive
      };
    default:
      return state;
  }
};
