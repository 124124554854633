import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { resetVideo, setVideoOptions } from 'common/actions';
import { DateTime } from 'common/components/date-time';
import { IdleTimerOverlay } from 'common/components/idle-timer';
import { ROUTE_ROOT } from 'common/config/constants';
import variables from 'common/config/variables';
import { LiveChannels } from 'common/constants/data-types';
import { CLOCK_POSITIONS, ProfileSettings } from 'common/reducers/profileSettings';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { useParentalLock } from 'common/utils/hooks';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import { TopMenu } from 'menu';
import Pages from 'pages';
import { Player } from 'Player';
import React, { useCallback, useEffect, useRef } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

interface OnDemandProps {
  setVideoOptions: (options: any) => void;
  resetVideo: VoidFunction;
  liveChannels: LiveChannels;
  currentChannelId: string | undefined;
  videoLocked?: boolean;
  clockPosition: string;
}

function OnDemand(props: OnDemandProps) {
  const location = useLocation();
  const { getChannelsLockedState } = useParentalLock();

  const adultVideoRef = useRef(false);
  useEffect(() => {
    if (!isEmpty(props.liveChannels) && !isEmpty(props.currentChannelId)) {
      adultVideoRef.current = !!props.liveChannels[props.currentChannelId as string].adult;
    }
  }, [props.liveChannels, props.currentChannelId]);

  const videoLockedRef = useRef(props.videoLocked);
  useEffect(() => {
    videoLockedRef.current = props.videoLocked;
  }, [props.videoLocked]);

  const playerOpenOnFullscreen = useSelector(
    ({ videoPlayer }: StoreState) => videoPlayer.isOpen && videoPlayer.video.isFullscreen,
    shallowEqual
  );

  const onPlayerBack = useCallback(
    (onFocus?: VoidFunction) => {
      props.setVideoOptions({
        isFullscreen: false
      });
      setTimeout(() => {
        setFocus(location.pathname);
        onFocus && onFocus();
      }, variables.DEFAULT_ANIMATION_DURATION);
      // If lock period is set to always lock OR
      // If video played is from adult channel -> reset video
      if ((getChannelsLockedState() && videoLockedRef.current) || adultVideoRef.current) {
        props.resetVideo();
      }
    },
    [location.pathname]
  );

  const onIdleBack = useCallback(() => {
    window.location.href = ROUTE_ROOT;
  }, []);

  return (
    <>
      <TopMenu focusKey="MENU" />
      <Pages />
      <IdleTimerOverlay onBack={onIdleBack} />
      <Player className="ondemand-player" onBack={onPlayerBack} />
      {playerOpenOnFullscreen && props.clockPosition === CLOCK_POSITIONS.ALWAYS && (
        <DateTime clockPosition={props.clockPosition} />
      )}
    </>
  );
}

interface OnDemandStore {
  liveChannels: LiveChannels;
  videoPlayer: IVideoPlayer;
  profileSettings: ProfileSettings;
}

const mapStateToProps = ({ liveChannels, videoPlayer, profileSettings }: OnDemandStore) => {
  return {
    liveChannels,
    currentChannelId: videoPlayer.channelId,
    videoLocked: videoPlayer.video?.locked,
    clockPosition: profileSettings?.react_tv_settings?.clockPosition
  };
};

export default React.memo(
  connect(mapStateToProps, {
    setVideoOptions,
    resetVideo
  })(OnDemand)
);
