import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import hideIcon from '@assets/media/hide-icon.svg';
import showIcon from '@assets/media/show-icon.svg';
import { setChannelSetting, translationPacket } from 'common/actions';
import { LOGO_TYPES } from 'common/config/constants';
import variables from 'common/config/variables';
import { LiveChannels } from 'common/constants/data-types';
import { MENU_ITEMS } from 'common/constants/profile-settings-types';
import { ChannelSettings } from 'common/reducers/profileSettings';
import { getChannelLogo } from 'common/services/helpers';
import { sortByPosition } from 'common/utils';
import { StoreState } from 'index';
import { useSnackbar } from 'notistack';
import { ThunkActionDispatch } from 'redux-thunk';
import SettingsListDrawer, { SettingsMenuItem } from './SettingsListDrawer';
interface Props {
  i18n: typeof translationPacket;
  liveChannels: LiveChannels;
  profileSettingsChannels: ChannelSettings;
  logos: [];
  setChannelSetting: ThunkActionDispatch<typeof setChannelSetting>;
}

function HideChannelsSettingsDrawer({
  i18n,
  liveChannels,
  profileSettingsChannels,
  logos,
  setChannelSetting
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const profileSettingsChannelsRef = useRef(profileSettingsChannels);
  const visibleChannelsLength = useRef(0);

  useEffect(() => {
    profileSettingsChannelsRef.current = profileSettingsChannels;
  }, [profileSettingsChannels]);

  useEffect(() => {
    visibleChannelsLength.current = Object.values(profileSettingsChannels).filter(
      (channel) => channel.visible
    ).length;
  }, [profileSettingsChannels]);

  const toggleHideChannel = useCallback((liveChannel: SettingsMenuItem) => {
    const visible = profileSettingsChannelsRef.current[liveChannel.id]?.visible;

    // Have to have atleast minimum visible channels
    if (visibleChannelsLength.current === variables.MIN_VISIBLE_CHANNELS) {
      //@ts-ignore
      enqueueSnackbar(i18n.mainSettings.hiddenChannelsMaxError, { title: i18n.snackbar.alert });
      return;
    }
    setChannelSetting(liveChannel.id, 'visible', !visible);
  }, []);

  const hideChannelsItems = useMemo(() => {
    try {
      const liveChannelsArr = sortByPosition(Object.values(liveChannels));

      return liveChannelsArr.map((liveChannel) => {
        const { id, position, name } = liveChannel;
        const channelSettings = profileSettingsChannels[id] || {};

        const icon = {
          activeState: channelSettings?.visible,
          activeIcon: showIcon,
          inactiveIcon: hideIcon
        };

        return {
          id,
          position,
          channelLogo: getChannelLogo(liveChannel.id, logos, LOGO_TYPES.png),
          name,
          icon,
          type: MENU_ITEMS.toggleOptionItem
        };
      });
    } catch (error) {
      console.warn(error);

      return [];
    }
  }, [liveChannels, logos, profileSettingsChannels]);

  return (
    <SettingsListDrawer
      drawerTitle={i18n.mainSettings.hideChannels}
      drawerSubtitle={i18n.mainSettings.hideChannelsSubmenuText}
      menuItems={hideChannelsItems}
      onMenuItemPress={toggleHideChannel}
    />
  );
}

const mapDispatchToProps = {
  setChannelSetting
};

const mapStateToProps = ({
  i18n,
  liveChannels,
  logos,
  appConfig,
  profileSettings
}: StoreState) => ({
  i18n,
  liveChannels,
  logos,
  navigateMode: appConfig.navigateMode,
  profileSettingsChannels: profileSettings?.react_tv_settings?.channels
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(HideChannelsSettingsDrawer));
