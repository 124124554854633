import {
  getMessages as getMessagesAction,
  readMessages as readMessagesAction
} from 'common/actions';
import MessageView from 'common/components/inbox/MessageView';
import { MessagePopup } from 'common/components/message-popups';
import variables from 'common/config/variables';
import { useMessage } from 'common/contexts/message';
import { usePopup } from 'common/contexts/popup';
import { Message, Messages } from 'common/reducers/messages';
import { AppDispatch, store, StoreState } from 'index';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useVisibilityState from './useVisibilityState';

// Global interval
let updateInterval: NodeJS.Timer | null = null;

const useMessages = () => {
  const messages = useSelector((state: StoreState) => state.messages as Messages);
  const isAppActive = useVisibilityState();
  const isAppActiveRef = useRef(isAppActive);
  const startedShowingMessages = useRef(false);
  const dispatch = useDispatch<AppDispatch>();
  const { openMessage } = useMessage();
  const { openPopup, hasPopups } = usePopup();

  const getMessages = useCallback(async () => {
    await dispatch(getMessagesAction());
  }, []);

  const readMessages = useCallback(async (ids: string | string[]) => {
    await dispatch(readMessagesAction(ids));
  }, []);

  const handleUpdateTimer = useCallback(async () => {
    if (!isAppActiveRef.current || isEmpty(store.getState().userDetails)) return;
    await getMessages();
  }, []);

  const hasMessages = useMemo(() => !isEmpty(messages), [messages]);
  const hasInboxMessages = useMemo(
    () => !isEmpty(messages.filter((e) => e.priority !== 'warning')),
    [messages]
  );
  const hasUnreadMessages = useMemo(
    () => messages.filter((e) => e.priority !== 'warning').some((e) => !e?.read),
    [messages]
  );

  const messagePromptToShow = useRef<Message[]>([]);
  const unreadStandartMessages = useMemo(() => {
    const value = messages.filter((e) => e.priority === 'normal' && !e.read && !e.seen);
    messagePromptToShow.current = Array.from(new Set([...messagePromptToShow.current, ...value]));
    return value;
  }, [messages]);

  const messagePopupsToShow = useRef<Message[]>([]);
  const unreadUrgentMessages = useMemo(() => {
    const value = messages.filter(
      (e) => ['warning', 'urgent'].includes(e.priority) && !e.read && !e.seen
    );
    messagePopupsToShow.current = Array.from(new Set([...messagePopupsToShow.current, ...value]));
    return value;
  }, [messages]);

  useEffect(() => {
    isAppActiveRef.current = isAppActive;
  }, [isAppActive]);

  const openStandartMessages = useCallback(() => {
    if (!isEmpty(messagePromptToShow.current)) {
      const message = messagePromptToShow.current[messagePromptToShow.current.length - 1];
      message &&
        openMessage({
          id: message.id,
          multiple: messagePromptToShow.current.length > 1 ? messagePromptToShow.current.length : 0,
          messageId: message.id,
          title: message.title,
          cover: message.media
        });
      // Clear stack
      messagePromptToShow.current = [];
      //
    }
  }, []);

  const openMessages = useCallback(() => {
    if (hasPopups || startedShowingMessages.current) return;
    // Messages to read
    const ids: string[] = [];
    //
    // Recursively open every message
    const openMessage = async () => {
      // Pop out of the stack of urgent messages
      const message = messagePopupsToShow.current.shift();
      //
      if (isEmpty(message)) {
        // Batch read all the openned messages
        !isEmpty(ids) && (await readMessages(ids));
        //
        // Handle opening of the standart prompt messages
        openStandartMessages();
        //
        // Clear flag
        startedShowingMessages.current = false;
        //
        return;
      }
      startedShowingMessages.current = true;
      // Temporaly disable dismiss when the first popup is diplayed
      // The check is when the batch ids is empty
      const temporalDisableDismiss = ids.length === 0;
      openPopup({
        id: message.id,
        removeSpacing: true,
        type: 'primary',
        content: (
          <MessagePopup
            popupId={message.id}
            temporalDisableDismiss={temporalDisableDismiss}
            content={<MessageView message={message} />}
          />
        ),
        onClose: (id: string) => {
          ids.push(id);
          openMessage();
        }
      });
    };
    openMessage();
  }, [hasPopups, openStandartMessages]);

  // Check for messages
  const handleMessages = useCallback(() => {
    openMessages();
  }, [unreadStandartMessages, unreadUrgentMessages, openMessages]);

  // Explicitly start and stop the timer
  const startTimer = useCallback(() => {
    if (isAppActive) {
      // Only create the interval when the global reference is not yet set
      if (updateInterval === null) {
        updateInterval = setInterval(handleUpdateTimer, variables.UPDATE_INTERVAL_FOR_MESSAGES);
      }
    }
  }, [isAppActive]);

  const stopTimer = useCallback(() => {
    updateInterval && clearInterval(updateInterval);
    updateInterval = null;
  }, []);

  return {
    messages,
    unreadStandartMessages,
    unreadUrgentMessages,
    hasMessages,
    hasInboxMessages,
    hasUnreadMessages,
    getMessages,
    readMessages,
    handleMessages,
    startTimer,
    stopTimer
  };
};

export default useMessages;
