import Icon from 'common/components/Icon';
import React from 'react';
import {
  MainSettingListItem,
  MainSettingsDrawerItemSubtitle,
  MainSettingsDrawerItemTitle,
  SettingItemContent,
  SettingItemIcon
} from './MainSettingsDrawer.styles';

interface Props {
  title: string;
  icon?: string;
  subtitle?: string;
}

function SettingsDrawerSettingItem({ icon, title, subtitle }: Props) {
  return (
    <MainSettingListItem>
      {icon && (
        <SettingItemIcon>
          <Icon size="large" src={icon} style={{ filter: 'contrast(6.5)' }} />
        </SettingItemIcon>
      )}
      <SettingItemContent>
        <MainSettingsDrawerItemTitle>{title}</MainSettingsDrawerItemTitle>
        <MainSettingsDrawerItemSubtitle>{subtitle}</MainSettingsDrawerItemSubtitle>
      </SettingItemContent>
    </MainSettingListItem>
  );
}

export default React.memo(SettingsDrawerSettingItem);
