import BackgroundImage from '@assets/media/login_background.png';
import BulbIcon from 'common/assets/default/media/bulb-icon.svg';
import Icon from 'common/components/Icon';
import ProductLogo from 'common/components/product-logo/ProductLogo';
import variables from 'common/config/variables';
import { Nullable } from 'common/interfaces';
import { SimpleItem } from 'common/reducers/prismic';
import { getScalablePixel } from 'common/utils';
import { StoreState } from 'index';
import { first, isEmpty, sample, shuffle } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './Screensaver.styles';

const BACKGROUND_EFFECTS = ['zoomOut', 'moveLeft', 'moveRight'];

interface ScreensaverProps {
  onScreensaverResume?: VoidFunction;
}

function Screensaver({ onScreensaverResume }: ScreensaverProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [effect, setEffect] = useState(sample(BACKGROUND_EFFECTS));
  const [textEffect, setTextEffect] = useState<'fadeIn' | 'fadeOut'>('fadeIn');
  const fadeTimeoutRef = useRef<NodeJS.Timeout>();

  const { screensaver, i18n } = useSelector(
    (state: StoreState) => ({
      screensaver: state.prismic.screensaver,
      i18n: state.i18n
    }),
    shallowEqual
  );

  const images = useMemo(() => {
    if (isEmpty(screensaver?.simple_items)) return [];

    return shuffle(screensaver.simple_items);
  }, [screensaver?.simple_items]);

  const slide = useMemo((): Nullable<SimpleItem> => {
    if (isEmpty(screensaver) || isEmpty(images)) return null;

    return images[activeIndex];
  }, [activeIndex, images]);

  const handleAnimEnd = useCallback(() => {
    if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
    fadeTimeoutRef.current = setTimeout(() => {
      setTextEffect('fadeOut');
    }, variables.SCREENSAVER_TEXT_FADEOUT_TIMING);
  }, []);

  const setEffectsOnLoad = useCallback(() => {
    const effects = BACKGROUND_EFFECTS.filter((x) => x !== effect);
    setEffect(sample(effects) as string);
    setTextEffect('fadeIn');
  }, [effect]);

  useEffect(() => {
    const handler = () => {
      onScreensaverResume && onScreensaverResume();
    };

    document.addEventListener('keydown', handler);
    document.addEventListener('click', handler);
    document.addEventListener('mousemove', handler);

    return () => {
      document.removeEventListener('keydown', handler);
      document.removeEventListener('click', handler);
      document.removeEventListener('mousemove', handler);
    };
  }, [onScreensaverResume]);

  const onBackgroundAnimationEnd = useCallback(() => {
    const newIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
  }, [images, activeIndex, setActiveIndex]);

  if (!screensaver || !slide || !i18n)
    return <img className="screensaver-background" src={BackgroundImage} />;

  return (
    <div className="screensaver">
      <div className="screensaver-slide">
        <div className="screensaver-top">
          <div className="screensaver-meta">
            <h3 className={`screensaver-title ${textEffect}`}>{first(slide.title)?.text}</h3>
            <h5 className={`screensaver-subtitle ${textEffect}`} onAnimationEnd={handleAnimEnd}>
              {first(slide.location)?.text}
            </h5>
          </div>
        </div>
        <div className="screensaver-bottom">
          <h4 className="screensaver-info-text">
            <Icon src={BulbIcon} size="medium" />
            {i18n.app.resumeVideoText}
          </h4>
        </div>
        <div className="screensaver-product-logo">
          <ProductLogo width={getScalablePixel(280)} type="full" />
        </div>
        <img
          className={`screensaver-background ${effect}`}
          src={slide.background.url}
          onLoad={setEffectsOnLoad}
          onAnimationEnd={onBackgroundAnimationEnd}
        />
      </div>
    </div>
  );
}

export default memo(Screensaver);
