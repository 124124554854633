// organize-imports-disable-next-line
import 'polyfills';
// organize-imports-disable-above
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import reducers from './common/reducers';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { PrismicProvider } from '@prismicio/react';
import { prismicClient } from 'common/components/carousel/prismic';
import CustomSnackbar from 'common/components/custom-snackbar';
import { DrawerProvier } from 'common/contexts/drawer';
import { MessageProvider } from 'common/contexts/message';
import { PopupProvider } from 'common/contexts/popup';
import ProfileSettingsProvider from 'common/contexts/profile-settings/ProfileSettingsProvider';
import { initializeFirebase } from 'common/utils/firebase';
import { client } from './common/services/request';
import {
  enableSentryIntegration,
  handleSettingAppLanguage,
  handleStaticViewportHeight,
  registerKeys
} from './common/utils/helpers';

handleSettingAppLanguage();
enableSentryIntegration();
handleStaticViewportHeight();

/** Redux Store config - START */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

let composeEnhancers = compose;
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

export const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)));

export type StoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type PayloadAction = {
  type: string;
  payload: any;
};
export type PromiseDispatch = () => Promise<any>;
/** Redux Store config - END */

// Enable the client to be able to dispatch actions
// on specific responses/errors.
(client as any).__dispatch = store.dispatch;

// Register remote keys
registerKeys();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const RootComponent = () => {
  const { ref, focusKey } = useFocusable({
    saveLastFocusedChild: true,
    autoRestoreFocus: true,
    focusKey: 'APP_CONTAINER',
    preferredChildFocusKey: 'MENU'
  });

  return (
    <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          Components={{ default: CustomSnackbar }}
        >
          <Router>
            <FocusContext.Provider value={focusKey}>
              <div className="view-container" ref={ref}>
                {/* Listens for changes in profile settings */}
                <ProfileSettingsProvider>
                  <MessageProvider>
                    <PopupProvider>
                      <DrawerProvier>
                        <PrismicProvider client={prismicClient}>
                          <App />
                        </PrismicProvider>
                      </DrawerProvier>
                    </PopupProvider>
                  </MessageProvider>
                </ProfileSettingsProvider>
                {/*  */}
              </div>
            </FocusContext.Provider>
          </Router>
        </SnackbarProvider>
      </Provider>
    </React.StrictMode>
  );
};
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Initialize firebase
initializeFirebase();
