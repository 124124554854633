import { FocusableComponentLayout } from '@noriginmedia/norigin-spatial-navigation';

type FocusElement = {
  focusKey: string;
  layout?: FocusableComponentLayout & { index: number; parentIndex?: number };
};
type FocusKeysMap = { [page: string]: FocusElement };

class FocusHistory {
  public previousFocusMap: FocusKeysMap = {};
  public currentFocusMap: FocusKeysMap = {};
  private mergeData = (map: FocusKeysMap, data: any, path: string) => {
    map[path] = {
      ...map[path],
      ...data
    };
  };
  setPrevious = (focusKey: string, pathname: string) => {
    return this.mergeData(
      this.previousFocusMap,
      {
        focusKey
      },
      pathname
    );
  };
  setPreviousElement = (element: FocusElement, pathname: string) => {
    return this.mergeData(this.previousFocusMap, element, pathname);
  };
  setPreviousNode = (layout: FocusElement['layout'], pathname: string) => {
    return this.mergeData(this.previousFocusMap, { layout }, pathname);
  };
  getPreviousByPath = (path: string) => {
    return this.previousFocusMap[path];
  };
  setCurrent = (focusKey: string, pathname: string) => {
    // Move current into previous before updating current
    // this.previousFocusMap[pathname] = this.currentFocusMap[pathname];
    return this.mergeData(
      this.currentFocusMap,
      {
        focusKey
      },
      pathname
    );
  };
  setCurrentElement = (element: FocusElement, pathname: string) => {
    return this.mergeData(this.currentFocusMap, element, pathname);
  };
  setCurrentNode = (layout: FocusElement['layout'], pathname: string) => {
    this.previousFocusMap[pathname] = this.currentFocusMap[pathname];
    return this.mergeData(this.currentFocusMap, { layout }, pathname);
  };
  getCurrentByPath = (path: string) => {
    return this.currentFocusMap[path];
  };
  clearHistoryForPath = (path: string) => {
    delete this.previousFocusMap[path];
    delete this.currentFocusMap[path];
  };
}
export default new FocusHistory();
