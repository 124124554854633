import {
  CURRENT_CHANNEL_SET,
  CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY,
} from 'common/constants/action-types';

export default function (
  state = {
    epgs: {},
    linkIsShown: false,
    channel: {},
  },
  action
) {
  switch (action.type) {
    case CURRENT_CHANNEL_SET:
      return {
        ...state,
        ...action.payload,
        epgs: {},
      };
    case CURRENT_CHANNEL_SET_HISTORY_FOR_A_DAY:
      return {
        ...state,
        epgs: {
          ...state.epgs,
          isTodayEpgsRequestPending: action.payload.isTodayEpgsRequestPending,
          isTomorrowEpgsRequestPending: action.payload.isTomorrowEpgsRequestPending,
          [action.payload.id]: action.payload.epgs,
        },
      };
    default:
      return state;
  }
}
