import { FocusableComponentLayout, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { ContentScrollingContentAxis, FocusableItem } from 'common/interfaces';
import { NavigateMode } from 'common/reducers/appConfig';
import { getClassName } from 'common/utils';
import { deepEqual } from 'fast-equals';
import { debounce } from 'lodash';
import React, {
  MouseEvent,
  ReactElement,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import './Stripe.styles';

const { FASTSCROLL_TIMEOUT } = variables;

interface StripeItemProps extends FocusableItem {
  index: number;
  // Used to track the index of the stripes in a list which are focusable
  focusableIndex: number;
  fastScroll?: boolean;
  axis?: ContentScrollingContentAxis;
  first?: boolean;
  last?: boolean;
  children?: ReactElement;
  navigateMode: NavigateMode;
  hidden?: boolean;
  getFocusKey?: (index: number, focusKey?: string) => void;
}

function StripeItem({
  index,
  focusableIndex,
  children,
  onEnterPress,
  onFocus,
  onBlur,
  onArrowPress,
  onClick: onClickParam,
  onHover: onHoverParam,
  onMouseLeave: onMouseLeaveParam,
  focusKey: focusKeyParam,
  axis,
  navigateMode,
  stripeItemClassName,
  disabled,
  hidden,
  getFocusKey
}: StripeItemProps) {
  const [hovered, setHovered] = useState(false);
  const {
    ref,
    focused: directionalFocused,
    focusSelf,
    focusKey
  } = useFocusable({
    focusable: !disabled,
    onEnterPress,
    onFocus,
    onBlur,
    onArrowPress,
    focusKey: focusKeyParam,
    extraProps: {
      index
    }
  });
  const [debouncedFocus, setDebouncedFocus] = useState(false);
  const focused = useMemo(() => {
    return { DIRECTIONAL: directionalFocused, POINTER: hovered }[navigateMode];
  }, [directionalFocused, hovered, navigateMode]);

  const getFocusKeyRef = useRef(getFocusKey);

  useEffect(() => {
    getFocusKeyRef.current = getFocusKey;
  }, [getFocusKey]);

  useEffect(() => {
    getFocusKeyRef.current && getFocusKeyRef.current(focusableIndex, focusKey);
    return () => {
      getFocusKeyRef.current && getFocusKeyRef.current(focusableIndex, undefined);
    };
  }, [focusKey, focusableIndex]);

  useEffect(() => {
    // Sync focus
    if (hovered && !directionalFocused) focusSelf();
  }, [hovered, directionalFocused]);

  useEffect(() => {
    const debounced = debounce(
      () => startTransition(() => setDebouncedFocus(focused)),
      focused ? FASTSCROLL_TIMEOUT : 0
    );
    debounced();
    return () => {
      debounced.cancel();
    };
  }, [focused]);

  useEffect(() => {
    // Clear hover flag after changing navigate mode
    if (navigateMode === 'DIRECTIONAL') {
      setHovered(false);
    }
  }, [navigateMode]);

  const onClick = () => {
    onClickParam && onClickParam({ index });
  };

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    setHovered(true);
    const layout: FocusableComponentLayout = {
      left: e.clientX,
      top: e.clientY,
      width: e.currentTarget.offsetWidth,
      height: e.currentTarget.offsetHeight,
      x: e.clientX,
      y: e.clientY,
      node: e.currentTarget
    };
    const data = { index };
    onHoverParam && onHoverParam(layout, data);
  };

  const onMouseLeave = () => {
    setHovered(false);
    onMouseLeaveParam && onMouseLeaveParam();
  };

  const renderChild = () => {
    return children && React.cloneElement(children, { focused: debouncedFocus, hidden });
  };

  return (
    <div
      ref={ref}
      key={focusKey}
      onClick={onClick}
      onMouseMove={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={-1}
      className={getClassName(`stripe-item ${axis || 'x'} ${stripeItemClassName || ''}`, {
        focused,
        first: index === 0
      })}
    >
      {renderChild()}
    </div>
  );
}

const isEqual = (prevProps: StripeItemProps, nextProps: StripeItemProps) => {
  const { children: prevChildren, ...restOfPrevProps } = prevProps;
  const { children: nextChildren, ...restOfNextProps } = nextProps;

  return (
    deepEqual(restOfPrevProps, restOfNextProps) &&
    deepEqual(prevChildren?.props, nextChildren?.props)
  );
};

// Ignore children change
export default React.memo(StripeItem, isEqual);
