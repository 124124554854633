import { Epg, EpgNumeration, LiveChannels } from 'common/constants/data-types';
import { pad } from 'common/services/helpers';
import * as langs from 'langs';
import { isEmpty, isNil, orderBy } from 'lodash';

export function convertToLanguage(resourceList?: { name: string }[]) {
  try {
    return !isEmpty(resourceList) && resourceList
      ? resourceList.map((e) => {
          const language = langs.where('2B', e.name);
          return language ? language.local : e.name;
        })
      : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
}

export function findLanguageName(resourceList?: langs.Language['local'][], index?: number) {
  try {
    return !isNil(index) && !isEmpty(resourceList) && resourceList
      ? resourceList[index]
      : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
}

// Compare function for validating identical episodes
export const compareEpisodes = (a: Epg, b: Epg) =>
  a.numeration.SeasonNumber === b.numeration.SeasonNumber &&
  a.numeration.EpisodeNumber === b.numeration.EpisodeNumber;

// Reduce the epg list to remove and sort repeating episodes
export const reduceEpisodes = (epgs: Epg[], liveChannels: LiveChannels) => {
  const reducedEpgs = epgs.reduce((acumulator: any[], value) => {
    const episodes = epgs.filter((e) => compareEpisodes(e, value));
    if (episodes.length > 0 && acumulator.findIndex((e) => compareEpisodes(e, value)) === -1) {
      const prioritizedEpisodes = orderBy(
        episodes,
        [
          'stop',
          (e) => liveChannels[e.chan_id].position,
          (e) => liveChannels[e.chan_id].resolution
        ],
        ['asc', 'asc', 'desc']
      );
      // Push the item that fits the most criteria
      acumulator.push(prioritizedEpisodes[0]);
    }

    return acumulator;
  }, []) as Epg[];
  return reducedEpgs;
};

export const getEpisodeInfoText = (numeration?: EpgNumeration) => {
  if (!numeration) return null;

  const { EpisodeNumber, SeasonNumber } = numeration;
  let episodeInfo = '';
  if (SeasonNumber !== 0) episodeInfo += `С${pad(SeasonNumber.toString())} `;
  if (EpisodeNumber !== 0) episodeInfo += `Е${pad(EpisodeNumber.toString())}`;

  return isEmpty(episodeInfo) ? null : episodeInfo;
};
