import { Translation } from 'common/actions';
import VerticalSeparator from 'common/components/VerticalSeparator';
import { Image } from 'common/components/image';
import { getClassName } from 'common/utils';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import './LastWatchedChannelHint.styles';

interface LastWatchedChannelHintProps {
  channelId: string;
  visible?: boolean;
  // Redux injected
  i18n: Translation;
  logos: any;
}
function LastWatchedChannelHint(props: LastWatchedChannelHintProps) {
  const channelLogo = useMemo(
    () => props.logos && props.logos[props.channelId]?.png,
    [props.logos, props.channelId]
  );

  const renderBackgroundMask = useCallback(
    () => (
      <svg fill="none" className="background-mask">
        <path
          opacity="0.4"
          d="M0 0H161.25H266.5H477H687.5H792.75H962L924.206 4.47086C900.134 7.31836 882 27.7244 882 51.9636C882 117.153 829.153 170 763.964 170H198.036C132.847 170 80 117.153 80 51.9636C80 27.7244 61.8656 7.31836 37.7943 4.47086L0 0Z"
          fill="black"
        />
      </svg>
    ),
    []
  );

  return (
    <div className={getClassName('last-watched-channel-hint', { visible: props.visible })}>
      {renderBackgroundMask()}
      <div className="title-container">
        <span className="last">{props.i18n?.menu?.last}</span>
        <span className="watched-channel">{props.i18n?.menu?.watchedChannel}</span>
      </div>
      <VerticalSeparator />
      <Image className="channel-logo" src={channelLogo} />
    </div>
  );
}

const mapStateToProps = ({ i18n, logos }: any) => {
  return {
    i18n,
    logos
  };
};
export default React.memo(connect(mapStateToProps, {})(LastWatchedChannelHint));
