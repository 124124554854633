import { getLastWatchedEpgs, getLiveChannels, getMostWatchedEpgs } from 'common/actions';
import { LiveChannels } from 'common/constants/data-types';
import { scheduleUpdater } from 'common/services/helpers';
import { AppDispatch, StoreState } from 'index';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const useUpdateScheduler = (disabled?: boolean) => {
  const liveChannels = useSelector(
    (state: StoreState) => state.liveChannels as LiveChannels,
    shallowEqual
  );
  const dispatch = useDispatch<AppDispatch>();
  const updateScheduler = useRef<NodeJS.Timeout>();

  // Schedule update on livechannel list when playing
  useEffect(() => {
    start();
    return () => {
      cancel();
    };
  }, [liveChannels, disabled]);
  //

  const cancel = useCallback(() => {
    clearTimeout(updateScheduler.current);
  }, [updateScheduler.current]);

  const start = useCallback(() => {
    let updateSchedulerInner: NodeJS.Timeout | undefined;
    if (!isEmpty(liveChannels) && !disabled) {
      cancel();
      updateSchedulerInner = scheduleUpdater(liveChannels, () => {
        requestIdleCallback(() => {
          dispatch(getLiveChannels());
          dispatch(getMostWatchedEpgs());
          dispatch(getLastWatchedEpgs());
        });
      });
      updateScheduler.current = updateSchedulerInner;
    }
    return updateSchedulerInner;
  }, [liveChannels, disabled, updateScheduler.current]);

  return { cancel, forceStart: start };
};
export default useUpdateScheduler;
