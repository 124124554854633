import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
const {
  color_primary,
  color_grey_20,
  color_grey_30,
  color_grey_40,
  color_grey_45,
  color_grey_50,
  color_grey_70,
  color_grey_80,
  color_grey_10,
  background_color_light
} = variables;

GlobalStyle.inject`
.text-white {
  color: #fff;
}
.main-settings-container {

  .trial-information {
    padding: ${getScalablePixel(20)}px ${getScalablePixel(46)}px;
    max-width: ${getScalablePixel(600)}px;
    font-size: ${getScalablePixel(24)}px;
    color: ${color_grey_40};

    b {
      color: ${color_grey_10}
    }
  }

  .focusable-stripe-content {
    &.y {
      padding: inherit !important;
    }
  }

  .inner-settings-menu-item.disabled {
    position: relative;
  }

  .settings-menu-item.stripe-item.y, .inner-settings-menu-item.disabled {
    margin: 0;
    margin-bottom: 1px; 
    
    &.focused {
      background: ${background_color_light}
    }
    transform: none !important;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 100% !important;
      left: 0;
      width: 100%;
      transform: none !important;
      height: 1px;
      outline: none;
      background: ${color_grey_70};
    }
  }

  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: ${color_grey_80};
  height: 100%;
  .inner-settings-toggle-item, .inner-settings-menu-item {
    &.disabled {
      .inner-settings-menu-title {
        opacity: 0.5;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${getScalablePixel(27)}px;
    font-size: ${getScalablePixel(28)}px;
    width: ${variables.DRAWER_CONTAINER_WIDTH}px;
    min-height: ${variables.SETTINGS_LIST_ITEM_HEIGHT}px; 
    color: ${color_grey_10};
    align-items: center;
    box-sizing: border-box;
    &.radio-menu-item {
      justify-content: flex-start;
      gap: ${getScalablePixel(16)}px;
    }
    .action-icon {
      display: flex;
      justify-content: center;
      .icon {
        box-sizing: border-box;
      }
      .reorder-active-icon {
        background: #F1F1F1;
        position: relative;
        width: ${getScalablePixel(80)}px;
        height: ${getScalablePixel(80)}px;
        margin-left: -${getScalablePixel(24)}px;
        margin-right: -${getScalablePixel(12)}px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          position: absolute;
          left: ${getScalablePixel(19)}px;
          height: ${getScalablePixel(34)}px;
          width: ${getScalablePixel(34)}px;
        }
        img:first-child {
          top: ${getScalablePixel(8)}px;
        }
        img:last-child {
          top: ${getScalablePixel(28)}px;
          transform: rotate(180deg);
        }
      }
    }
    .channel-position {
      font-weight: 700;
      margin-left: ${getScalablePixel(33)}px;
      width: ${getScalablePixel(53)}px;
    }
    .channel-logo {
      display: flex;
      width: ${getScalablePixel(166)}px;
      height: ${getScalablePixel(66)}px;
      margin-left:  ${getScalablePixel(68)}px;
    }
  
    .channel-name {
      margin-left: ${getScalablePixel(10)}px;
      font-weight: 400;
      color: ${color_grey_20};
      flex: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .inner-settings-menu-item {
    padding: 0 ${getScalablePixel(48)}px;
    &.has-subtitle {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      padding-top: ${getScalablePixel(20)}px;
      padding-bottom: ${getScalablePixel(20)}px;
    }
    .inner-settings-menu-subtitle {
      margin-top: ${getScalablePixel(4)}px;
      font-size: ${getScalablePixel(18)}px;
      color: ${color_grey_40};
    }
  }
}
.pin-input-hidden-field {
  opacity: 0;
  position: absolute; 
}
.pin-input-field {
  background-color: ${color_grey_70};
  color: ${color_grey_45};
  margin: 0 ${getScalablePixel(4)}px;
  border-radius: ${getScalablePixel(4)}px;
  border: ${getScalablePixel(2)}px solid ${color_grey_40};
  width: ${getScalablePixel(87)}px;
  height: ${getScalablePixel(92)}px;
  font-size: ${getScalablePixel(64)}px;
  caret-color: transparent;
  text-align: center;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &.error:focus, &.error.focused, &.error {
    border-color: ${color_primary};
  }
  &.focused {
    background-color: ${color_grey_50};
    border-color: ${color_grey_30};
    border-width: ${getScalablePixel(3)}px;
    box-shadow: 0 0 ${getScalablePixel(14)}px rgba(255, 255, 255, 0.5);
    user-select: none;
  }
  .pin-input-field-value {
    display: block;
    width: ${getScalablePixel(14)}px;
    height: ${getScalablePixel(14)}px;
    background-color: ${color_grey_45};
    border-radius: 100%;
  }
}
`;
