import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { VerticalSeparator } from 'common/components/common/style';
import { DateTime } from 'common/components/date-time';
import { HAS_ACCESS } from 'common/config/constants';
import { CLOCK_POSITIONS } from 'common/reducers/profileSettings';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import { getClassName, getScalablePixel } from 'common/utils';
import { StoreState } from 'index';
import { isEmpty, isNil } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import ChannelListButton from './ChannelListButton';
import ChannelPosition from './ChannelPosition';
import { PlayerControlsProps } from './Controls';
import PlayerSettings from './PlayerSettings';

interface TopViewProps {
  // Redux injected
  playerControlsState: PlayerControlsState;
  hasNextVideo?: boolean;
  channelAccess?: number;
  //
  showControls: PlayerControlsProps['showControls'];
  toggleControls: PlayerControlsProps['showControls'];
  changeSubtitleTrack: PlayerControlsProps['changeSubtitleTrack'];
  changeAudioTrack: PlayerControlsProps['changeAudioTrack'];
  changeQuality: PlayerControlsProps['changeQuality'];
  fastZapNumber?: number;
  clockPosition: CLOCK_POSITIONS;
}

function TopView(props: TopViewProps) {
  const { ref, focusKey } = useFocusable({
    preferredChildFocusKey: 'CHANNEL_LIST_BUTTON',
    saveLastFocusedChild: false
  });
  const visible = useMemo(() => {
    if (!isNil(props.fastZapNumber) || props.hasNextVideo) return true;

    switch (props.playerControlsState) {
      case 'mini':
      case 'expanded':
      case 'bottom-view':
        return props.channelAccess === HAS_ACCESS;
      case 'channels':
        return true;
      default:
        return false;
    }
  }, [props.playerControlsState, props.channelAccess, props.fastZapNumber, props.hasNextVideo]);

  const additionalComponentsVisible = useMemo(
    () => !['channels', 'bottom-view'].includes(props.playerControlsState),
    [props.playerControlsState]
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        className={getClassName('top-view', {
          'fast-zapping': props.fastZapNumber,
          visible,
          'bottom-view-openned': props.playerControlsState === 'bottom-view'
        })}
        ref={ref}
      >
        <ChannelPosition fastZapNumber={props.fastZapNumber} />
        <ChannelListButton />
        <div className="flex row">
          {additionalComponentsVisible && (
            <PlayerSettings
              showControls={props.showControls}
              toggleControls={props.toggleControls}
              changeAudioTrack={props.changeAudioTrack}
              changeQuality={props.changeQuality}
              changeSubtitleTrack={props.changeSubtitleTrack}
            />
          )}
          {additionalComponentsVisible && (
            <VerticalSeparator
              height={`${getScalablePixel(36)}px`}
              style={{ marginRight: props.clockPosition === CLOCK_POSITIONS.ALWAYS ? 375 : 0 }}
            />
          )}
          {props.clockPosition === CLOCK_POSITIONS.IN_MENU && <DateTime />}
        </div>
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ videoPlayer, profileSettings }: StoreState) => ({
  playerControlsState: videoPlayer.playerControlsState,
  hasNextVideo: !isEmpty(videoPlayer.nextVideo),
  channelAccess: videoPlayer.video?.access,
  clockPosition: profileSettings.react_tv_settings?.clockPosition
});

export default React.memo(connect(mapStateToProps, {})(TopView));
