import FutureThumbnail from '@assets/media/card_next_cover.png';
import {
  API_URL,
  CURRENCIES,
  DIRECTION_DOWN,
  DIRECTION_LEFT,
  DIRECTION_NONE,
  DIRECTION_RIGHT,
  DIRECTION_UP, HAS_ACCESS, MS_RANGE,
  NOW_EPG,
  ONE_HOUR_IN_SECONDS,
  REGEX_EMAIL,
  REGEX_PASSWORD,
  SECONDS_TO_REVERT,
  THUMBNAIL_BUFFER_OFFSET,
  THUMBNAIL_OFFSET,
  TIMEZONE,
  TIME_BEFORE_BUFFER,
  TIME_FORMAT
} from 'common/config/constants';
import {
  checkLockStatusForChannel,
  checkVisibleStatusForChannel,
  diffObjects,
  findDeviceSettingsItem
} from 'common/utils/helpers';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { store } from '../..';
import config from './config';

// FACEPALM
// TEMP vars
export let customerID;

export const setCustomerID = (id) => {
  if (id) {
    customerID = id;
  }
};
//

/**
 * Replace all variables of type '{number}' with given params
 * @param string {string}
 * @param replacements [number|string|array}
 * @returns {string}
 */

/**
 * Format sconds in HH:MM:SS format
 * @param sec {number}
 * @returns {string}
 */
export const toHHMMSS = (sec) => {
  const secondsToConvert = parseInt(sec);
  const secondsToHours = parseInt(secondsToConvert / ONE_HOUR_IN_SECONDS);
  const secondsToMinutes = parseInt(secondsToConvert / 60) % 60;
  const secondsLeft = parseInt(secondsToConvert) % 60;
  const hours = secondsToHours <= 9 ? `0${secondsToHours}` : secondsToHours;
  const minutes = secondsToMinutes <= 9 ? `0${secondsToMinutes}` : secondsToMinutes;
  const seconds = secondsLeft <= 9 ? `0${secondsLeft}` : secondsLeft;

  return hours !== '00' ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const msToDDHHMMSS = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysMS = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysMS / (60 * 60 * 1000));
  const hoursMS = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursMS / (60 * 1000));
  const minutesMS = ms % (60 * 1000);
  const sec = Math.floor(minutesMS / 1000);

  return {
    days: pad(days),
    hours: pad(hours),
    minutes: pad(minutes),
    sec: pad(sec)
  };
};

export const minutesToMS = (minutes) => {
  const minutesToConvert = parseInt(minutes);
  return minutesToConvert * 60 * 1000;
};

export const pad = (number) => (number < 10 ? '0' + number : number);

/**
 * If data is object - transform in array and sort from min to max, if data is array - sort from min to max
 * @param data {Array | Object}
 * @param isAlphabetical {Boolean}
 * @returns {Array}
 */
export const sort = (
  data,
  isAlphabetical,
  sortBySettings = true,
  channels = undefined
) => {
  let itemList = undefined;
  if (sortBySettings) {
    try {
      itemList =
        channels || store.getState().profileSettings.react_tv_settings?.channels;
    } catch (error) {
      console.warn(error)
    }
  }

  const array = Array.isArray(data) ? data : Object.values(data);
  try {
    return array.sort((a, b) => {
      const itemA = sortBySettings && findDeviceSettingsItem(itemList, a.id);
      const itemB = sortBySettings && findDeviceSettingsItem(itemList, b.id);
      if (isAlphabetical) {
        return a.name > b.name ? 1 : -1;
      } else if (itemList && itemA && itemB && sortBySettings) {
        const positionA = a.access === HAS_ACCESS ? itemA.position : a.position;
        const positionB = b.access === HAS_ACCESS ? itemB.position : b.position;
        return positionA - positionB;
      }
      // Fallback to position from the supplier
      return a.position - b.position;
    });
  } catch (e) {
    console.warn(e);
    return [];
  }
};

/**
 * Calculate show current progress in percentages. All units are in seconds;
 * @param start {number}
 * @param end {number}
 * @param currentTime {number}
 * @returns {number}
 */
export const calculateTimePercentages = (start, end, currentTime) => {
  let percentages = 0;

  const now =
    typeof currentTime !== 'undefined'
      ? currentTime
      : new Date().getTime() / 1000 - config.timestampOffset;

  if (now >= end) {
    percentages = 100;
  } else if (now <= start) {
    percentages = 0;
  } else {
    const duration = end - start;
    const progress = now - start;
    const percentageInSeconds = duration / 100;
    percentages = progress / percentageInSeconds;
  }

  return percentages;
};

/**
 * Calculate how many px is given percentages of width in px
 */
export const calculatePercentageInPixels = (percentages, width) => {
  return (width * percentages) / 100;
};

/**
 * Returns functions to execute when touchstart event and touchend event occur if swipe event is detected
 * @param callback - function to execute on touchend event if condition is met
 */
export const addSwipeEvent = (callback) => {
  let swipeDir,
    startX,
    startY,
    distX,
    distY,
    dist = null,
    elapsedTime,
    startTime;

  const threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    handleSwipe = callback;

  const touchStart = (e) => {
    const touchObject = e.changedTouches[0];
    swipeDir = DIRECTION_NONE;
    dist = 0;
    startX = touchObject.pageX;
    startY = touchObject.pageY;
    startTime = new Date().getTime(); // record time when finger first makes contact with surface
  };

  const touchEnd = (e) => {
    const touchObject = e.changedTouches[0];
    distX = touchObject.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
    distY = touchObject.pageY - startY; // get vertical dist traveled by finger while in contact with surface
    elapsedTime = new Date().getTime() - startTime; // get time elapsed
    if (elapsedTime <= allowedTime) {
      // first condition for swipe met
      if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
        // 2nd condition for horizontal swipe met
        swipeDir = distX < 0 ? DIRECTION_LEFT : DIRECTION_RIGHT; // if dist traveled is negative, it indicates left swipe
      } else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
        // 2nd condition for vertical swipe met
        swipeDir = distY < 0 ? DIRECTION_UP : DIRECTION_DOWN; // if dist traveled is negative, it indicates up swipe
      }
    }

    handleSwipe(e, swipeDir);
  };

  return {
    touchEnd: touchEnd,
    touchStart: touchStart
  };
};

/**
 * Takes an array, copies it and then splits it
 * to a new array of arrays with lengths
 * equal (or smaller) than the limit;
 * @param list {Array} - the array to be partitioned
 * @param limit {number} - the partition size
 * @returns {Array}
 */
export const partitionArray = (originalList, limit) => {
  const list = originalList.map((item) => {
    const { id, ...rest } = item;
    return {
      ...rest,
      id: parseInt(id)
    };
  });

  const copy = list.slice();
  const result = [];
  while (copy.length) {
    result.push(copy.splice(0, limit));
  }

  return result;
};

/**
 * Takes the 'search' key of the 'location' object
 * provided by the React router and parses it to an object.
 * The keys correspond to the param's name and the
 * value depends on the specific case:
 *  - name=ivan turns into {name: ivan}
 *  - name= turns into {name: ""}
 *  - name turns into {name: true}
 * @param queryParamsString {string}
 * @returns {Object}
 */
export const parseQuery = (queryParamsString) => {
  const normalized = queryParamsString.trim().replace('?', '');
  const arrayOfParams = normalized.split('&');

  return arrayOfParams.reduce((current, param) => {
    let newResult = current;
    const hasEqualSign = param.indexOf('=') >= 0;
    const keyValue = param.trim().split('=');
    if (param !== '') {
      if (keyValue[0] && hasEqualSign) {
        newResult = {
          ...current,
          [keyValue[0]]: keyValue[1]
        };
      } else if (keyValue[0] && !hasEqualSign) {
        newResult = {
          ...current,
          [keyValue[0]]: true
        };
      }
    }

    return newResult;
  }, {});
};

/**
 * Formats time from timestamp to HH:MM format in a timezone
 * @param time {number}
 * @returns {string}
 */
export const formatTime = (time) => {
  const timeInMilliseconds = secondsToMilliseconds(time);

  return new Date(timeInMilliseconds).toLocaleTimeString(TIME_FORMAT, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
    hour12: false,
    timeZone: TIMEZONE
  });
};
/**
 * Get a string formatted like: 30 November, 16:00 - 19:00
 * @param start {number} - show start timestamp in seconds
 * @param stop {number} - show end timestamp in seconds
 * @returns {{day: number, month: string, start: string, stop: string}}
 */
export const getDateAndTime = (start, stop = 0) => {
  let timeInMilliseconds = secondsToMilliseconds(start);
  const startTime = formatTime(start);
  const endTime = stop ? formatTime(stop) : 0;
  const startDate = DateTime.fromMillis(timeInMilliseconds)
    .toFormat('d LLLL')
    .split(' ');
  const day = parseInt(startDate[0]);
  const month = startDate[1];
  return {
    day,
    month,
    start: startTime,
    stop: endTime
  };
};

/**
 * Get a string formatted like: 30 November, 16:00 - 19:00
 * @param start {number} - show start timestamp in seconds
 * @param stop {number} - show end timestamp in seconds
 * @param months {string[]} - the translated months
 * @returns {{day: number, month: string, start: string, stop: string}}
 */
export const getDateAndTimeFast = (start, stop, months) => {
  let timeInMilliseconds = secondsToMilliseconds(start);
  const startDate = new Date(timeInMilliseconds)
    .toLocaleDateString(TIME_FORMAT, {
      day: '2-digit',
      month: '2-digit',
      timeZone: TIMEZONE
    })
    .replace(/\u200E/g, '');
  const date = startDate.split('/');
  const day = parseInt(date[0]);
  const monthIndex = parseInt(date[1]) - 1;
  const startTime = formatTime(start);
  const endTime = formatTime(stop);

  return {
    day,
    month: months[monthIndex],
    start: startTime,
    stop: endTime
  };
};

/**
 * Get a formatted string with bullets separator and Live indicator if showLiveLabel is true
 * @param start {number}
 * @param stop {number}
 * @param months {number}
 * @param showLiveLabel {boolean}
 * @returns {object}
 */
// export const getDateAndTimeWithLive = (start, stop, months, showLiveLabel) => {
//   const initialDateAndTime = getDateAndTime(start, stop, months, true);

//   if (showLiveLabel) {
//     initialDateAndTime.__html += ` <span class="date-time-bullet">&bull;</span> <span class="date-time-live">Live</span>`;
//   }
//   return initialDateAndTime;
// }

/**
 * Get the today's Date.
 * @returns {Date}
 */
export const getToday = () => {
  // Get server timestamp.
  const nowInTimestamp = new Date().getTime() - secondsToMilliseconds(config.timestampOffset);

  // Get the time wherever you are.
  const now = new Date(nowInTimestamp);

  return now;
};

export const getDayOfWeek = (timestamp, days) => {
  try {
    const startDate = getStartOfDayDateObject(timestamp * 1000);
    return days[startDate.getDay()];
  } catch (error) {
    console.warn(error);
    return '';
  }
};

/**
 * Get string formatted like:
 * 'Saturday, 24th of March ' or 'yesterday, 25th of March'
 * depending on the day.
 * @param date {Date}
 * @param days {Array<string>}
 * @param months {Array<string>}
 * @param otherDays {Object | null}
 * @returns {string}
 */
export const getDayOfWeekAndDate = (date, days, months, otherDays) => {
  const dayOfWeekIndex = date.getDay();
  let dayOfWeek = days[dayOfWeekIndex];
  const dayInMonth = date.getDate();
  const monthIndex = date.getMonth();
  let todayDate = getToday();

  // 'todayDate' is mutated here!
  const yesterdayDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
  if (date.getDate() === getToday().getDate()) {
    dayOfWeek = otherDays && otherDays.today ? otherDays.today : dayOfWeek;
  } else if (date.getDate() === yesterdayDate.getDate()) {
    dayOfWeek = otherDays && otherDays.yesterday ? otherDays.yesterday : dayOfWeek;
  }
  const month = months[monthIndex];

  return `${dayOfWeek}, ${dayInMonth} ${month}`;
};

/**
 * Get a date object pointing to the beginning of
 * the corresponding day for a timezone.
 * @param timestamp {number}
 * @returns {Date}
 */
export const getStartOfDayDateObject = (timestamp) => {
  const date = new Date(timestamp);
  const localeDate = date.toLocaleDateString('en-US', { timeZone: TIMEZONE });

  return new Date(localeDate);
};

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
/**
 * Get the difference between two dates in days.
 * @param a {Date}
 * @param b {Date}
 * @returns {number}
 */
export const dateDifferenceInDays = (a, b) => {
  const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utcA - utcB) / MILLISECONDS_IN_A_DAY);
};

/**
 * Transforms a timestamp from seconds to milliseconds.
 * This is used in order to transform the data from
 * the backend API in a more declarative manner and
 * avoid multiplying to 1000 in the code.
 * @param seconds {number}
 * @returns {number}
 */
export const secondsToMilliseconds = (seconds) => seconds * 1000;

/**
 * Sets the object describing the token
 * in the local storage.
 * @param tokenObj {Object}
 */
export const setTokenObject = (tokenObj) => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem('tokenObject', JSON.stringify(tokenObj));
  } catch (error) {
    console.warn("Can't set token object in local storage.", error);
  }
};

/**
 * Retrieves the token object from local storage.
 * @returns {Object || null}
 */
export const getTokenObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    console.error("Local storage is undefined");
    return;
  }
  let tokenObject = null;
  try {
    const tokenObjectString = localStorage.getItem('tokenObject');
    tokenObject = JSON.parse(tokenObjectString);
  } catch (error) {
    console.error('Error getting token object from local storage.', error);
  }

  return tokenObject;
};

/**
 * Removes the token object from local storage.
 */
export const deleteTokenObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.removeItem('tokenObject');
  } catch (error) {
    console.warn('Error deleting token object from local storage.', error);
  }
};

/*
 * Sets the object describing the user credentials
 * in the local storage.
 * @param tokenObj {Object}
 */
export const setUserObject = (object) => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem('userObject', JSON.stringify(object));
  } catch (error) {
    console.warn("Can't set userObject in local storage.", error);
  }
};

/**
 * @returns {Object || null}
 */
export const getUserObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    console.error("Local storage is undefined");
    return;
  }
  let object = null;
  try {
    const objectString = localStorage.getItem('userObject');
    object = JSON.parse(objectString);
  } catch (error) {
    console.error('Error getting userObject from local storage.', error);
  }

  return object;
};

/**
 */
export const deleteUserObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    const currentUserObject = getUserObject();
    if (hasCredentialsObject(currentUserObject)) {
      setUserObject({ email: currentUserObject.email })
    } else {
      localStorage.removeItem('userObject');
    }
  } catch (error) {
    console.warn('Error deleting userObject from local storage.', error);
  }
};

/*
 * Sets the object describing the trial information
 * in the local storage.
 * @param tokenObj {Object}
 */
export const setTrialObject = (object) => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem('trialObject', JSON.stringify(object));
  } catch (error) {
    console.warn("Can't set trialObject in local storage.", error);
  }
};

/**
 * @returns {Object || null}
 */
export const getTrialObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  let object = null;
  try {
    const objectString = localStorage.getItem('trialObject');
    object = JSON.parse(objectString);
  } catch (error) {
    console.warn('Error getting getTrialObject from local storage.', error);
  }

  return object;
};

/**
 */
export const deleteTrialObject = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.removeItem('trialObject');
  } catch (error) {
    console.warn('Error deleting trialObject from local storage.', error);
  }
};

/**
 * Removes the order object from session storage.
 */
export const deleteOrderObject = () => {
  // Without this check the tests throw error.
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  try {
    sessionStorage.removeItem('orderInfo');
  } catch (error) {
    console.warn('Error deleting order object from session storage.', error);
  }
};

/**
 * 
 * @param {string} channelId 
 */
export const setLastWatchedChannelId = (channelId) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem('lastPlayedChannelId', channelId);
  } catch (error) {
    console.warn("Can't set last watched channelId in local storage.", error);
  }
}


/**
 * @returns {string || null}
 */
export const getLastWatchedChannelId = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    return localStorage.getItem('lastPlayedChannelId');
  } catch (error) {
    console.warn('Error getting last watched channel id from local storage.', error);
  }

  return null;
};

/**
 */
export const deleteLastWatchedChannelId = () => {
  // Without this check the tests throw error.
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.removeItem('lastPlayedChannelId');
  } catch (error) {
    console.warn('Error deleting last watched channel id from local storage.', error);
  }
};

export const deleteTimeToLockChannels = () => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.removeItem('TIME_TO_LOCK_CHANNELS');
  } catch (error) {
    console.warn('Error deleting timeToLockChannels from local storage.', error);
  }
}

/**
 * Checks if a string is a valid email address.
 * Then executes a callback with the result of the
 * check, provided as an argument.
 * @param {string} email
 * @param {Function} callback
 * @returns {boolean}
 */
export const validateEmail = (email, callback) => {
  const trimmedEmail = email.replace(/\s/g, '');
  const isValid = REGEX_EMAIL.test(trimmedEmail);
  if (typeof callback === 'function') {
    callback(isValid);
  }

  return isValid;
};

/**
 * Checks if a string is a valid password.
 * Then executes a callback with the result of the
 * check, provided as an argument.
 * @param {string} password
 * @param {Function} callback
 * @returns {boolean}
 */
export const validatePassword = (password, callback) => {
  const isValid = REGEX_PASSWORD.test(password);
  if (typeof callback === 'function') {
    callback(isValid);
  }

  return isValid;
};

/**
 * Checks if the error is form the list of the
 * defined server errors that have a specific subcode.
 * @param error {Object}
 * @returns {boolean}
 */
export const hasSpecificServerError = (error) =>
  error && error.response && error.response.data && error.response.data.subcode;

/**
 * Checks if the response has
 * authorization data.
 * @param object {Object}
 * @returns {boolean}
 */
export const hasTokenObject = (object) =>
  object?.data?.data && object?.data?.data?.access_token;

export const hasCredentialsObject = (object) =>
  object?.email && object?.grant_type == 'client_credentials' && object?.password;

export const hasTokenInMemory = () => {
  const tokenObject = getTokenObject();
  return !!tokenObject?.access_token;
}

export const hasCredentialsObjectInMemory = () => {
  const userObject = getUserObject();
  return hasCredentialsObject(userObject);
}

/**
 * Creates an object with keys corresponding to
 * the id of major categories that are present
 * in the epgs. Each such key holds object with
 * id, name of the category, epgs array and
 * subcategories object that has the same structure
 * - keys are the ids of the subcategories and
 * each subcategory is an object with id, name
 * and epgs array.
 * @param {Array<Object>} epgs
 * @returns {Object}
 */
export const structureEpgArray = (epgs, profileSettings) => {
  // Filter out hidden and locked channels
  const filtered = epgs.filter(
    (epg) =>
      checkVisibleStatusForChannel(profileSettings, epg.chanId) &&
      !checkLockStatusForChannel(profileSettings, epg.chanId)
  );
  return filtered.reduce((res, current) => {
    res[current.majorId] = res[current.majorId] || {};
    res[current.majorId].name = current.major_category || '';
    res[current.majorId].id = current.majorId;
    res[current.majorId].epgs = res[current.majorId].epgs || [];
    res[current.majorId].epgs.push(current);
    res[current.majorId].subcategories = res[current.majorId].subcategories || {};
    current.category_array.forEach((cat, index) => {
      res[current.majorId].subcategories[cat] = res[current.majorId].subcategories[cat] || {};
      res[current.majorId].subcategories[cat].name = current.categories[index] || '';
      res[current.majorId].subcategories[cat].id = cat;
      res[current.majorId].subcategories[cat].epgs =
        res[current.majorId].subcategories[cat].epgs || [];
      res[current.majorId].subcategories[cat].epgs.push(current);
    });

    return res;
  }, {});
};

/**
 * Formats the date from milliseconds to dd.mm.yy
 * @param {number} seconds
 */
export const formatDate = (seconds) => {
  const date = new Date(secondsToMilliseconds(seconds));
  return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, '.');
};

export const formatDateSubscriptionToday = () => {
  const date = new Date();
  return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, '.');
};

export const formatDateSubscriptionAfterAMonth = (seconds) => {
  const today = new Date();
  let dayAfterOneMonth = new Date();
  dayAfterOneMonth = new Date(dayAfterOneMonth.setDate(today.getDate() + 30));
  return dayAfterOneMonth.toLocaleDateString(TIME_FORMAT).replace(/\//g, '.');
};

/**
 * Formats the date to dd.mm.yy
 * @param date
 * @returns {string}
 */
export const formatDateFromString = (date) => {
  return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, '.');
};

export const getTimeFromTimeStamp = (ts) => {
  const date = new Date(ts);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${pad(hours)}:${pad(minutes)}`;
};

export const getTimeWithHoursFromTimeStamp = (ts) => {
  const date = new Date(ts);
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `<strong>${day}.${month}</strong> в <strong>${pad(hours)}:${pad(minutes)}</strong>`;
};

/**
 *
 * @returns timestamp in seconds before the before
 */
export const getLiveTimestamp = () => {
  return Math.floor(Date.now() / 1000) - SECONDS_TO_REVERT;
};

/**
 * Returns number of day in specific month
 * @param month
 * @param year
 * @returns {number}
 */
export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

/**
 * Formats the currency value from thousands (cents) to whole unit with 2 signs after decimal point
 * @param value {number} (cents)
 * @param currency {string}
 */
export const formatPrice = (value, currency) => {
  if (typeof value !== 'number') {
    value = 0;
  }

  return `${(value / 100).toFixed(2)} ${CURRENCIES[currency] || ''}`;
};

/**
 * Transforms card number from BE in format ************1111
 * to format •••• •••• •••• 1111
 * @param cardNumber {string}
 * @returns {string}
 */
export const formatCardNumber = (cardNumber) => {
  return cardNumber
    .replace(/(\*{4})/g, '$1 ')
    .split('*')
    .join('•');
};

/**
 * constant returns array of packages which users can watch them in android devices
 * @returns {Array}
 */
export const ONLY_ANDROID_DEVICES = ['EROS PACK'];

/**
 * Helper function for figuring out if you should reset the lock status
 * of the channels.
 * This functions uses the Redux store for accesing the current props.
 * For proper use of this functionality the component that you use this helper
 * should have mounted these specific props from the redux store:
 * 
 * 'videoPlayer',
  'liveChannels',
  'filteredLiveChannels',
  'profileSettings.react_tv_settings.timeToLockChannels',
  'profileSettings.react_tv_settings.lockPeriod',
 *
 * @param {Object} prevProps 
 * @param {Object} currentProps
 * @returns {Boolean} - if it should reset the local lock status of the channels
 */
export const shouldResetLockStatus = (prevProps, currentProps) => {
  const keys = [
    'videoPlayer',
    'liveChannels',
    'filteredLiveChannels',
    'profileSettings.react_tv_settings.timeToLockChannels',
    'profileSettings.react_tv_settings.lockPeriod'
  ];
  if (diffObjects(prevProps, currentProps, keys)) {
    // Reset lock status to unknown
    return true;
  }
  return false;
};

export const getThumbnailTimestampWithOffset = (timestamp) => {
  const now = getToday().getTime() / 1000;
  return now - timestamp < THUMBNAIL_OFFSET && now - timestamp > 0 ? now : timestamp + THUMBNAIL_OFFSET;
}

export const getRealThumbnailTimestamp = (timestamp) => {
  return timestamp - ((timestamp % THUMBNAIL_BUFFER_OFFSET) % THUMBNAIL_BUFFER_OFFSET);
};

export const getThumbnailUrl = (chanId, isLive, timestamp) => {
  try {
    if ((!isLive && !timestamp) || !chanId) {
      throw new Error('No image');
    }
    const now = getToday().getTime() / 1000;
    const url = `${API_URL}/tumblrs/${chanId}`;
    if (isLive) {
      // Help the cache engine retrieve only the new thumbnail
      // when the backend has a real new thumbnail
      return `${url}/latest?hash=${Math.floor(getRealThumbnailTimestamp(now))}`;
    } else {
      // Check if archive time is in the future
      const archiveTime = timestamp > now ? -1 : timestamp;
      if (archiveTime === -1) return FutureThumbnail;
      return `${url}/${Math.floor(getRealThumbnailTimestamp(archiveTime))}`;
    }
  } catch (error) {
    return '';
  }
};

export const getChannelLogo = (chanId, logos, type = 'pngWhite') => {
  if (!isEmpty(logos) && !isEmpty(logos[chanId])) {
    return logos[chanId][type];
  }
  return '';
};

export const getPlaylist = (channelId, start, stop) => {
  let token = '';

  const tokenObject = getTokenObject();
  if (tokenObject && tokenObject.access_token) {
    token = tokenObject.access_token;
  }

  const nowInSeconds =
    Math.floor((new Date().getTime() / 1000).toFixed(0)) - config.timestampOffset;
  const now = nowInSeconds >= stop ? 0 : NOW_EPG;

  const startStr = `begin=${start}`;
  const stopStr = !now ? `&end=${stop}` : '';

  const source = `${API_URL}/playlists/${channelId}/playlist.m3u8?${startStr}${stopStr}&access_token=${token}`;
  return {
    now,
    start,
    stop,
    source
  };
};

export const getTimeBeforeBuffer = (currentTime, duration) => {
  return duration - TIME_BEFORE_BUFFER - currentTime;
};

export const getLivePoint = () => {
  return getToday().getTime() / 1000 - SECONDS_TO_REVERT;
};

export const scheduleUpdater = (liveChannels, onTimeout) => {
  if (!isEmpty(liveChannels)) {
    const lowestLiveShowEnd = Math.min(
      ...Object.values(liveChannels).map((e) => e.currentepg.stop)
    );

    let getNewShowAfter = MS_RANGE;
    if (lowestLiveShowEnd != Infinity) {
      getNewShowAfter = Math.floor(
        lowestLiveShowEnd * 1000 - new Date().getTime() + Math.random() * MS_RANGE
      );
    }
    return setTimeout(onTimeout, getNewShowAfter);
  }
};

/**
 *
 * @param {Array<number>} values
 * @param {number} number
 */
export const findClosestNumber = (values, number) => {
  return values.reduce((prev, curr) =>
    Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev
  );
};
