import * as Sentry from '@sentry/react';
import {
  AUTH_DELETE_TOKEN,
  AUTH_SIGN_IN,
  USER_REMOVE_DETAILS,
  USER_REMOVE_PROFILE_SETTINGS,
  VIDEO_RESET
} from 'common/constants/action-types';

import {
  NETWORK_MAX_RETRIES,
  NETWORK_RETRY_TIMEOUT_INIT, ROUTE_LOGOUT
} from 'common/config/constants';
import {
  deleteLastWatchedChannelId,
  deleteTimeToLockChannels,
  deleteTokenObject,
  deleteUserObject,
  getTokenObject,
  getUserObject,
  hasCredentialsObject,
  setUserObject
} from 'common/services/helpers';
import request, { client } from 'common/services/request';

const signInMaybe = async (credentials, googleTokenObj, dispatch) => {
  if (googleTokenObj === undefined) {
    const { data: tokenObj } = await request({
      method: 'post',
      url: '/users/login',
      data: credentials
    });

    dispatch({
      type: AUTH_SIGN_IN,
      payload: tokenObj
    });
    setUserObject(credentials);
    return tokenObj;
  } else {
    dispatch({
      type: AUTH_SIGN_IN,
      payload: googleTokenObj
    });

    return googleTokenObj;
  }
};

export const signIn = (credentials, googleTokenObj) => {
  return async (dispatch) => {
    for (let i = 0; i < NETWORK_MAX_RETRIES; i++) {
      try {
        return await signInMaybe(credentials, googleTokenObj, dispatch);
      } catch (error) {
        if (error.code === 'ERR_NETWORK' && i !== NETWORK_MAX_RETRIES - 1) {
          await new Promise((res) => setTimeout(res, NETWORK_RETRY_TIMEOUT_INIT * 2 ** i));

          continue;
        }
        // Send the error to the component
        // that has triggered the action.
        // This gives the opportunity to
        // display specific server errors
        // in components without using
        // the redux store.
        console.warn(error);
        if (error.code != 'ERR_CANCELED') {
          deleteUserObject();
        }
        return Promise.reject(error);
      }
    }
  };
};

export const signUp = (signUpData) => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'post',
        url: '/users',
        data: signUpData
      });
      // Allow the invoking component to
      // know when the request has succeeded
      // and render the post registration
      // info.
      return Promise.resolve(response);
    } catch (error) {
      // Send the error to the component
      // that has triggered the action.
      // This gives the opportunity to
      // display specific server errors
      // in components without using
      // the redux store.
      return Promise.reject(error);
    }
  };
};

export const testGeoRestriction = () => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'get',
        url: '/geo'
      });
      // Allow the invoking component to
      // know when the request has succeeded
      // and render the post registration
      // info.
      return Promise.resolve(response);
    } catch (error) {
      // Send the error to the component
      // that has triggered the action.
      // This gives the opportunity to
      // display specific server errors
      // in components without using
      // the redux store.
      return Promise.reject(error);
    }
  };
};

export const requestChangePasswordLink = (email) => {
  return async (dispatch) => {
    try {
      const trimmedEmail = email.replace(/\s/g, '');
      const response = await request({
        method: 'get',
        url: `users/resetpassword?email=${trimmedEmail}`
      });
      // Allow the invoking component to
      // know when the request has succeeded
      // and render the appropriate info.
      return Promise.resolve(response);
    } catch (error) {
      // Send the error to the component
      // that has triggered the action.
      // This gives the opportunity to
      // display specific server errors
      // in components without using
      // the redux store.
      return Promise.reject(error);
    }
  };
};

export const resetPassword = (data) => {
  return async (dispatch) => {
    try {
      const response = await request({
        method: 'post',
        url: 'users/resetpassword',
        data
      });
      // Allow the invoking component to
      // know when the request has succeeded
      // and render the appropriate info.
      return Promise.resolve(response);
    } catch (error) {
      // Send the error to the component
      // that has triggered the action.
      // This gives the opportunity to
      // display specific server errors
      // in components without using
      // the redux store.
      return Promise.reject(error);
    }
  };
};

export const changePassword = (oldPassword, newPassword, newPasswordRepeat) => {
  return async (dispatch) => {
    const response = await request({
      method: 'post',
      url: '/users/me/changepassword',
      data: {
        oldpass: oldPassword,
        password: newPassword,
        repeatPassword: newPasswordRepeat
      }
    });
    // Update the user object in the local storage
    const currentUserObject = getUserObject();
    setUserObject({ ...currentUserObject, password: newPassword });
    //
    // Allow the invoking component to
    // know when the request has succeeded
    // and render the appropriate info.
    return response;
  };
};

export const signOut = (redirect = null) => {
  return async (dispatch) => {
    try {
      deleteUserObject();
      dispatch({
        type: AUTH_DELETE_TOKEN,
        payload: {
          redirect
        }
      });
      await request({
        method: 'post',
        url: ROUTE_LOGOUT
      });

      dispatch({
        type: USER_REMOVE_DETAILS,
        payload: {}
      });

      dispatch({
        type: VIDEO_RESET,
        payload: null
      });

      dispatch({
        type: USER_REMOVE_PROFILE_SETTINGS,
        payload: {}
      });
      Sentry.setUser(null);
      // deleteTrialObject();
      deleteTokenObject();
      deleteLastWatchedChannelId();
      deleteTimeToLockChannels();
    } catch (error) {
      console.warn(error);
    }
  };
};

export const checkUserToken = async (access_token) => {
  for (let i = 0; i < NETWORK_MAX_RETRIES; i++) {
    try {
      // Use local Axios client
      await client({
        method: 'get',
        url: '/users/check',

        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      return true;
    } catch (error) {
      if (error.code === 'ERR_NETWORK' && i !== NETWORK_MAX_RETRIES - 1) {
        await new Promise((res) => setTimeout(res, NETWORK_RETRY_TIMEOUT_INIT * 2 ** i));

        continue;
      }
      console.warn(error);
      return false;
    }
  }
};

const handleRefresh = async (onRefresh) => {
  try {
    onRefresh && (await onRefresh());
    return true;
  } catch (error) {
    // Do not clear user and token data on error for onRefresh callback
    console.warn(error);
    return false;
  }
};

export const refreshToken = (onRefresh) => {
  return async (dispatch) => {
    try {
      const userObject = getUserObject();
      const tokenObject = getTokenObject();
      if (tokenObject?.access_token && hasCredentialsObject(userObject)) {
        // Check for valid token
        const response = await checkUserToken(tokenObject.access_token);
        if (!response) {
          // Auto sign in to refresh token
          await dispatch(signIn(userObject));
        }
        return handleRefresh(onRefresh);
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      console.error('Refreshing token failed', error);
      deleteTokenObject();
      deleteUserObject();
      return false;
    }
  };
};
