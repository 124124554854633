import { capitalize, isNil } from 'lodash';
import React from 'react';

interface StripePaddingProps {
  direction?: 'right' | 'left' | 'bottom' | 'top' | string;
  padding?: number | string;
}

const StripePadding = (props: StripePaddingProps) => {
  return (
    <div
      style={{
        ...(props.direction && !isNil(props.padding)
          ? { [`padding${capitalize(props.direction)}`]: props.padding }
          : { paddingRight: '50%' }),
        border: 'solid 1px transparent'
      }}
    />
  );
};

export default StripePadding;
