import { Banner } from 'common/reducers/carousel';
import { isEmpty } from 'lodash';

export type BannerType = 'playable' | 'link';

export const getBannerType = (banner: Banner): BannerType | undefined => {
  if (!isEmpty(banner.tv_show_id)) return 'playable';
  if (!isEmpty(banner.tv_channel_position)) return 'playable';
  if (!isEmpty(banner.vod_asset_id)) return 'playable';
  if (!isEmpty(banner.external_link?.url)) return 'link';
  //   if (!isEmpty(banner.external_link)) return 'playable';
};
