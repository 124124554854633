import variables from 'common/config/variables';
import { AppTheme } from 'common/interfaces';
import { Linear } from './linear';
import { OnDemand } from './on-demand';
import React from 'react';

const WHITELABEL_TYPE_MAP = {
  [AppTheme.ON_DEMAND]: OnDemand,
  [AppTheme.LINEAR]: Linear
};

// Inject different styles based on the theme
switch (variables.WHITELABEL_TYPE) {
  case AppTheme.LINEAR:
    require('./linear/Linear.styles');
    break;
  case AppTheme.ON_DEMAND:
    require('./on-demand/OnDemand.styles');
    break;
  default:
    break;
}

export default React.memo(WHITELABEL_TYPE_MAP[variables.WHITELABEL_TYPE]);
