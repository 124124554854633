import { setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { clearSearch, playVideo, resetVideo, setVideoOptions } from 'common/actions';
import { DateTime } from 'common/components/date-time';
import { IdleTimerOverlay } from 'common/components/idle-timer';
import { LastWatchedChannelHint } from 'common/components/last-watched-channel-hint';
import { HAS_ACCESS, ROUTE_HOME, ROUTE_SCREENSAVER } from 'common/config/constants';
import variables from 'common/config/variables';
import { Epg, LiveChannel, LiveChannels } from 'common/constants/data-types';
import { usePopup } from 'common/contexts/popup';
import { ICarousel } from 'common/reducers/carousel';
import { Errors } from 'common/reducers/errors';
import { CLOCK_POSITIONS, ProfileSettings } from 'common/reducers/profileSettings';
import { IVideoPlayer } from 'common/reducers/videoPlayer';
import { getLastWatchedChannelId, setLastWatchedChannelId } from 'common/services/helpers';
import { closeCurrentApp, getClassName, getDeviceType } from 'common/utils';
import { useParentalLock, useVisibilityState } from 'common/utils/hooks';
import { StoreState } from 'index';
import { isEmpty } from 'lodash';
import SideMenu from 'menu/SideMenu';
import { isMenuShown } from 'menu/utils';
import Pages from 'pages';
import { Player } from 'Player';
import React, { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

const DEVICE_TYPE = getDeviceType();

interface LinearProps {
  hasErrors?: boolean;
  liveChannels?: LiveChannels;
  isPlayerOpenned?: boolean;
  isPlayerFullscreen?: boolean;
  isPlayerPlaying?: boolean;
  currentPlayingEpg?: Epg;
  setVideoOptions: any;
  userDetails: any;
  isPlayingFromBanner?: boolean;
  playVideo: (currentepg: Epg, options: any) => void;
  resetVideo: VoidFunction;
  clearSearch: VoidFunction;
}

function Linear(props: LinearProps) {
  const { hasPopups } = usePopup();
  const location = useLocation();
  const navigate = useNavigate();

  const onAppHidden = useCallback(() => {
    // Do not close the app on TitanOS
    if (DEVICE_TYPE === 'titanos') return;

    closeCurrentApp();
  }, []);

  const onAppVisible = useCallback(() => {
    // Do not reload the app on TitanOS
    if (DEVICE_TYPE === 'titanos') return;
    window.location.reload();
  }, []);

  const isAppActive = useVisibilityState({ onHidden: onAppHidden, onVisible: onAppVisible });
  const shouldResetToHomePosition = useRef(true);
  const inactivityTimeoutRef = useRef<NodeJS.Timeout>();
  const unMountPageTimeoutRef = useRef<NodeJS.Timeout>();
  const disableFocusOnWrapperRef = useRef<boolean>(false);
  const [firstLaunch, setFirstLaunch] = useState(true);

  const [menuFocused, setMenuFocused] = useState(false);
  const menuIsShown = useMemo(() => isMenuShown(location.pathname), [location.pathname]);
  const [pageWrapperActive, setPageWrapperActive] = useState(true);
  const { getChannelsLockedState } = useParentalLock();

  const [, startTransition] = useTransition();

  const loadedLiveChannels = useMemo(() => !isEmpty(props.liveChannels), [props.liveChannels]);
  const [unMountPage, setUnMountPage] = useState(false);

  const isPlayerOpennedRef = useRef(props.isPlayerOpenned);

  useEffect(() => {
    isPlayerOpennedRef.current = props.isPlayerOpenned;
  }, [props.isPlayerOpenned]);

  const returnToPlayer = useCallback(() => {
    if (isPlayerOpennedRef.current) {
      props.clearSearch();
      props.setVideoOptions({
        isFullscreen: true
      });
    }
  }, []);

  // Save last played channel to memory
  useEffect(() => {
    if (!isEmpty(props.currentPlayingEpg)) {
      setLastWatchedChannelId(props.currentPlayingEpg?.chan_id);
    }
  }, [props.currentPlayingEpg]);

  const checkChannelForAccess = useCallback((channel: LiveChannel) => {
    return (
      channel.access === HAS_ACCESS &&
      !(channel.locked && getChannelsLockedState()) &&
      channel.visible
    );
  }, []);

  const lastWatchedChannelId = useMemo(() => {
    if (isEmpty(props.liveChannels)) {
      return null;
    }
    const liveChannelsArray = Object.values(props.liveChannels);
    const lastSavedChannelId = getLastWatchedChannelId();
    const lastSavedChannel = props.liveChannels[lastSavedChannelId];

    return lastSavedChannel && checkChannelForAccess(lastSavedChannel)
      ? lastSavedChannel.id
      : // If last saved channel is not available, return the first available channel
        // fallback to original channel
        liveChannelsArray.find(checkChannelForAccess)?.id || lastSavedChannel.id;
  }, [loadedLiveChannels, isAppActive]);

  const showPageWrapper = useCallback(() => {
    setPageWrapperActive(true);
    disableFocusOnWrapperRef.current = false;
  }, []);

  // Handle initial player loading
  // Load stream when livechannels are available, when app is in active state
  // and when userdetails are available.
  // If for some reason player resets itself, reload the last watched channel.
  useEffect(() => {
    try {
      if (
        loadedLiveChannels &&
        props.liveChannels &&
        lastWatchedChannelId &&
        isAppActive &&
        !props.hasErrors &&
        !isEmpty(props.userDetails) &&
        !isPlayerOpennedRef.current
      ) {
        setFocus('MENU');
        // Force call onMenuFocus to avoid flickering of page
        onMenuFocus();
        const channel = props.liveChannels[lastWatchedChannelId];
        props.playVideo(channel.currentepg, {
          startPosition: -1,
          channelPosition: channel.position
        });
        disableFocusOnWrapperRef.current = true;
      } else if (isAppActive) {
        showPageWrapper();
      }
    } catch (error) {
      console.info(error);
      // Show screen for fallback
      showPageWrapper();
      setUnMountPage(false);
    }
  }, [loadedLiveChannels, isAppActive, props.userDetails, lastWatchedChannelId, props.hasErrors]);
  //

  useEffect(() => {
    if (!isAppActive) {
      setFirstLaunch(true);
    }
  }, [isAppActive]);

  useEffect(() => {
    if (!props.isPlayerOpenned) {
      showPageWrapper();
      setUnMountPage(false);
    }
  }, [props.isPlayerOpenned]);

  // Focus on page when the page comes into view
  useEffect(() => {
    if (pageWrapperActive && isAppActive && !disableFocusOnWrapperRef.current) {
      setFocus(`${location.pathname}-stripe-view`);
    }
  }, [pageWrapperActive, isAppActive]);
  //

  // Reset hide status of page wrapper when player comes on the front
  // Reset page position to HOME
  useEffect(() => {
    if (props.isPlayerFullscreen) {
      setPageWrapperActive(false);
      shouldResetToHomePosition.current && navigate(ROUTE_HOME);
    }
  }, [props.isPlayerFullscreen]);

  // Handle return to player when no activity has been tracked
  useEffect(() => {
    const handleTimer = () => {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = setTimeout(() => {
        returnToPlayer();
        shouldResetToHomePosition.current = true;
      }, variables.AUTO_SHOW_PLAYER_TIMEOUT);
    };
    // Track activity only when page wrapper is active
    // and no popups are on stop of the screen
    // Do not track activity when a banner is playing a preview
    // Do not track activity when errors are present
    // Do not track activity when the player is not openned
    if (
      pageWrapperActive &&
      !props.hasErrors &&
      !hasPopups &&
      !props.isPlayingFromBanner &&
      props.isPlayerOpenned
    ) {
      document.addEventListener('keydown', handleTimer);
      document.addEventListener('mousemove', handleTimer);
      handleTimer();
    }
    return () => {
      document.removeEventListener('keydown', handleTimer);
      document.removeEventListener('mousemove', handleTimer);
      clearTimeout(inactivityTimeoutRef.current);
    };
  }, [
    pageWrapperActive,
    hasPopups,
    props.isPlayingFromBanner,
    props.hasErrors,
    props.isPlayerOpenned
  ]);

  // Sync to mount page when page wrapper is active
  useEffect(() => {
    if (pageWrapperActive) {
      startTransition(() => setUnMountPage(false));
    }
  }, [pageWrapperActive]);

  const onPlayerBack = useCallback(() => {
    if (shouldResetToHomePosition.current) {
      setFocus('MENU');
    } else {
      props.setVideoOptions({
        isFullscreen: false
      });
      startTransition(() => {
        showPageWrapper();
      });
      disableFocusOnWrapperRef.current = false;
    }
    setFirstLaunch(false);
  }, []);

  const onMenuFocus = useCallback(() => {
    setPageWrapperActive(false);
    setMenuFocused(true);
    props.setVideoOptions({
      isFullscreen: false
    });
    shouldResetToHomePosition.current = true;
    unMountPageTimeoutRef.current = setTimeout(() => {
      startTransition(() => setUnMountPage(false));
    }, variables.LONG_THROTTLE_TIMEOUT);
  }, []);

  const onMenuBlur = useCallback(() => {
    setMenuFocused(false);
  }, []);

  const onMenuSelect = useCallback(() => {
    props.setVideoOptions({
      isFullscreen: false
    });
    startTransition(() => showPageWrapper());
    disableFocusOnWrapperRef.current = false;
    shouldResetToHomePosition.current = false;
    setFirstLaunch(false);
  }, [location.pathname]);

  const onPlayerFocus = useCallback(() => {
    if (!pageWrapperActive) {
      returnToPlayer();
      clearTimeout(unMountPageTimeoutRef.current);
      shouldResetToHomePosition.current && startTransition(() => setUnMountPage(true));
    }
  }, [returnToPlayer, pageWrapperActive]);

  const clockPosition = useSelector(
    (state: StoreState) =>
      (state.profileSettings as ProfileSettings)?.react_tv_settings?.clockPosition,
    shallowEqual
  );

  const onIdleTimerBack = useCallback(() => {
    clearTimeout(inactivityTimeoutRef.current);
    setFirstLaunch(true);
    setFocus('home-menu-item');
    props.resetVideo();
    showPageWrapper();
    navigate(ROUTE_SCREENSAVER);
  }, [props.resetVideo]);

  return (
    <div className="linear-view-container">
      {lastWatchedChannelId && menuIsShown && (
        <LastWatchedChannelHint
          channelId={lastWatchedChannelId}
          visible={menuFocused && firstLaunch}
        />
      )}
      <SideMenu
        focusKey="MENU"
        onFocus={onMenuFocus}
        onBlur={onMenuBlur}
        onMenuSelect={onMenuSelect}
        initialOpen={firstLaunch}
      />
      {!unMountPage && (
        <Pages
          className={getClassName('linear-page-wrapper', {
            hidden: !pageWrapperActive,
            'has-background': props.isPlayingFromBanner
          })}
        />
      )}
      <IdleTimerOverlay onBack={onIdleTimerBack} />
      <Player
        className="linear-player"
        onBack={onPlayerBack}
        onFocus={onPlayerFocus}
        paused={props.isPlayingFromBanner}
      />
      {props.isPlayerOpenned && clockPosition === CLOCK_POSITIONS.ALWAYS && (
        <DateTime clockPosition={clockPosition} />
      )}
    </div>
  );
}

interface LinearStore {
  videoPlayer: IVideoPlayer;
  liveChannels: LiveChannels;
  userDetails: any;
  errors: Errors;
  carousel: ICarousel;
}
const mapStateToProps = ({
  videoPlayer,
  liveChannels,
  userDetails,
  errors,
  carousel
}: LinearStore) => {
  return {
    isPlayerOpenned: videoPlayer?.isOpen,
    isPlayerFullscreen: videoPlayer?.video?.isFullscreen,
    isPlayerPlaying: videoPlayer?.isPlaying,
    currentPlayingEpg: videoPlayer?.epgs?.current,
    liveChannels,
    userDetails,
    hasErrors: Object.values(errors).every((e) => !!e),
    isPlayingFromBanner: carousel.isBannerPlaying
  };
};
export default React.memo(
  connect(mapStateToProps, { setVideoOptions, playVideo, resetVideo, clearSearch })(Linear)
);
