import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import {
  playVideo,
  resetNextVideo,
  setNextVideo,
  setPlayerControlsState,
  setVideoOptions,
  translationPacket
} from 'common/actions';
import { clearChannelListCategory, setChannelListCategory } from 'common/actions/channel-list';
import { LiveCard } from 'common/components/cards';
import { PaidChannelPopup } from 'common/components/message-popups';
import FocusableStripe, {
  FocusableStripeRefObject
} from 'common/components/stripe/FocusableStripe';
import { CATEGORY_FAVORITES_TYPE, DEFAULT_SCREEN_HEIGHT } from 'common/config/constants';
import variables from 'common/config/variables';
import {
  Category,
  Epg,
  LiveCategories,
  LiveChannel,
  LiveChannels
} from 'common/constants/data-types';
import { usePopup } from 'common/contexts/popup';
import { NavigateMode } from 'common/reducers/appConfig';
import { IChannelList } from 'common/reducers/channelList';
import { FavoriteChannels } from 'common/reducers/favoriteChannels';
import { PlayerControlsState } from 'common/reducers/videoPlayer';
import { findClosestNumber, sort } from 'common/services/helpers';
import { useParentalLock } from 'common/utils/hooks';
import { debounce, DebouncedFunc, isEmpty, isEqual, isNil, isUndefined } from 'lodash';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
const {
  DETAILED_INFO_THROTTLE_TIMEOUT,
  FAST_ZAP_NUMBER_INPUT_TIMEOUT,
  LONG_THROTTLE_TIMEOUT,
  PLAYER_SAFE_AREA_VIEW_PADDING,
  STRIPE_ITEM_PADDING
} = variables;

interface ChannelListProps {
  fastZapNumber?: number;
  //Redux injected
  visible?: boolean;
  isPlayerOpenned?: boolean;
  liveChannels: LiveChannels;
  favoriteChannels: FavoriteChannels;
  currentChannelId: string;
  videoStart: number | undefined;
  videoStop: number | undefined;
  fastZapping?: boolean;
  playVideo: (currentepg: Epg, options?: any) => void;
  setNextVideo: (
    channelId: string,
    start: number,
    stop: number,
    position: number,
    startPosition?: number,
    autoPlay?: boolean
  ) => void;
  resetNextVideo: VoidFunction;
  setVideoOptions: (options: any) => void;
  channelList: IChannelList;
  liveCategories: LiveCategories;
  setChannelListCategory: (category: Partial<Category>) => void;
  clearChannelListCategory: () => void;
  setPlayerControlsState: (state: PlayerControlsState) => void;
  logos: any;
  i18n: typeof translationPacket;
  navigateMode: NavigateMode;
  currentChannelPosition: number;
  //
}
function ChannelList(props: ChannelListProps) {
  const { openPopup } = usePopup();
  const { ref, focusKey } = useFocusable({
    focusable: props.visible,
    forceFocus: true
  });
  const onStripeItemFocusDebounce = useRef<DebouncedFunc<VoidFunction> | undefined>();
  const scrollPadding = useMemo(() => PLAYER_SAFE_AREA_VIEW_PADDING + STRIPE_ITEM_PADDING, []);
  const startOffsetPadding = useMemo(
    () => `${DEFAULT_SCREEN_HEIGHT - PLAYER_SAFE_AREA_VIEW_PADDING}px`,
    []
  );
  const stripeRef = useRef<FocusableStripeRefObject>(null);
  const [channelListItems, setChannelListItems] = useState<LiveChannel[]>([]);
  const [, setNextCategoryIndex] = useState<number | undefined>(undefined);
  const { locked: channelsLocked } = useParentalLock();

  const hideFavoriteChannels = useMemo(
    () => variables.REORDER_CHANNELS_ENABLED && !props.channelList.category,
    [props.channelList.category]
  );

  // Combine liveChannels with favorite channels
  const channels = useMemo(() => {
    const liveChannelsArr = isEmpty(props.liveChannels) ? [] : Object.values(props.liveChannels);
    const favoriteChannelsArr = isEmpty(props.favoriteChannels)
      ? []
      : Object.values(props.favoriteChannels);
    // Filter out hidden channels
    const filtered = [...favoriteChannelsArr, ...liveChannelsArr].filter((e) => e.visible);
    return sort(filtered, false, variables.REORDER_CHANNELS_ENABLED);
  }, [props.liveChannels, props.favoriteChannels]);

  useEffect(() => {
    const items: LiveChannel[] = [];
    if (props.visible) {
      const currentCategory = props.channelList.category?.position;
      // Clone variable so we can reverse the new variable
      const _channels = [...channels];
      // Reverse it
      _channels.reverse();
      //
      items.push(
        ..._channels
          // Filter by category
          .filter((channel) =>
            !isNil(currentCategory) ? currentCategory === channel.category : true
          )
          // Filter out favorite channels if hideFavoriteChannels is true
          .filter((channel) =>
            hideFavoriteChannels ? channel.category !== CATEGORY_FAVORITES_TYPE.id : true
          )
      );
    }
    const debounced = debounce(() => {
      setChannelListItems(items);
    }, LONG_THROTTLE_TIMEOUT);
    debounced();
    if (channelListItems.length === 0) {
      debounced.flush();
    }
    return () => {
      debounced.cancel();
    };
  }, [props.visible, props.channelList.category, hideFavoriteChannels]);

  const liveCategories = useMemo(() => {
    const liveCategoriesArr = Object.values(props.liveCategories).sort(
      (a, b) => a.position - b.position
    );
    // Filter out categories that we don't have channels for
    const filteredCategories = liveCategoriesArr.filter((category) =>
      channels.find((e) => e.category === category.position)
    );
    return filteredCategories;
  }, [props.liveCategories, channels]);

  useEffect(() => {
    if (isEmpty(channelListItems)) return;
    // Handle focus to channel number from number input
    if (!isNil(props.fastZapNumber)) {
      const fastZapNumber = props.fastZapNumber;
      const debounced = debounce(() => {
        const positions = channelListItems.map((e) => e.position);
        const channelPosition = findClosestNumber(positions, fastZapNumber);
        const channelIndex = positions.indexOf(channelPosition);
        channelIndex !== -1 && stripeRef.current?.scrollToIndex(channelIndex);
      }, FAST_ZAP_NUMBER_INPUT_TIMEOUT);
      debounced();
      return () => debounced.cancel();
    } else {
      // find index from current channel position
      const index = channelListItems.findIndex((x) => x.position === props.currentChannelPosition);

      // Fallback to last index
      const initialIndex =
        index !== -1 && isEmpty(props.channelList.category) ? index : channelListItems.length - 1;
      stripeRef.current?.scrollToIndex(initialIndex);
    }
  }, [props.currentChannelId, channelListItems, props.fastZapNumber, props.currentChannelPosition]);

  const playVideo = useCallback(
    (channel: LiveChannel) => {
      if (!channel.access) {
        openPopup({
          id: 'paid-channel-popup',
          type: 'primary',
          content: <PaidChannelPopup popupId="paid-channel-popup" />
        });
        return;
      }
      // Check if the current show is different from the selected
      const { start, stop, id: channelId } = channel.currentepg;
      const current = {
        channelId: props.currentChannelId,
        start: props.videoStart,
        stop: props.videoStop
      };
      const next = {
        start,
        stop,
        channelId
      };

      if (!isEqual(current, next)) {
        props.playVideo(channel.currentepg, { channelPosition: channel.position });
      }
      props.setPlayerControlsState('mini');
    },
    [props.currentChannelId, props.videoStart, props.videoStop]
  );

  const onStripeItemFocus = useCallback((channel: LiveChannel) => {
    // if (!props.visible) return null;
    onStripeItemFocusDebounce.current && onStripeItemFocusDebounce.current.cancel();
    onStripeItemFocusDebounce.current = debounce(() => {
      props.setVideoOptions({ fastZapping: false });
      props.setNextVideo(
        channel.id,
        channel.currentepg.start,
        channel.currentepg.stop,
        channel.position,
        -1,
        false
      );
    }, DETAILED_INFO_THROTTLE_TIMEOUT);
    onStripeItemFocusDebounce.current();
  }, []);

  useEffect(() => {
    const currentChannel = props.favoriteChannels[props.currentChannelId];
    // If channel is favorited and position === channelFavorite position (e.g. played as a Favorite channel)
    // only then se channelList category to CATEGORY_FAVORITES_TYPE
    if (
      !isUndefined(currentChannel) &&
      currentChannel.category === CATEGORY_FAVORITES_TYPE.id &&
      props.currentChannelPosition === currentChannel.position &&
      currentChannel.visible
    ) {
      props.setChannelListCategory(CATEGORY_FAVORITES_TYPE);
      setNextCategoryIndex(CATEGORY_FAVORITES_TYPE.id);
    }

    return () => {
      // clear category data on unmount
      setChannelListItems([]);
      props.clearChannelListCategory();
      setNextCategoryIndex(undefined);
    };
  }, [props.currentChannelPosition, props.currentChannelId]);

  // Handle closing of channel list
  useEffect(() => {
    if (!props.visible) {
      onStripeItemFocusDebounce.current && onStripeItemFocusDebounce.current.cancel();
      props.setVideoOptions({ fastZapping: false });
      props.resetNextVideo();
    }
  }, [props.visible]);

  const onStripeItemBlur = useCallback(() => {
    startTransition(() => props.setVideoOptions({ fastZapping: true }));
  }, []);

  const onArrowPress = useCallback(
    (direction: string, properties: { index: number }) => {
      const { index } = properties;
      const { clearChannelListCategory, setChannelListCategory } = props;
      switch (direction) {
        // Handle jump from top to bottom and vice versa
        case 'up':
          index === 0 && stripeRef.current?.scrollToIndex(channelListItems.length - 1);
          break;
        case 'down':
          index >= channelListItems.length - 1 && stripeRef.current?.scrollToIndex(0);
          break;
        //
        // Handle category swtiching
        case 'right':
        case 'left':
          {
            const offset = direction === 'right' ? 1 : -1;
            setNextCategoryIndex((current) => {
              let index: number | undefined = 0;
              if (isNil(current)) {
                index = offset === 1 ? 0 : liveCategories.length - 1;
              } else {
                const next = current + offset;
                index = next > liveCategories.length - 1 || next < 0 ? undefined : next;
              }
              const nextCategory = isNil(index) ? undefined : liveCategories[index];
              nextCategory ? setChannelListCategory(nextCategory) : clearChannelListCategory();
              return index;
            });
            // Disable focus on arrow left and right
            return false;
          }
          break;
        //
        default:
          break;
      }
      return true;
    },
    [liveCategories, channelListItems]
  );

  const renderItem = useCallback(
    (item: LiveChannel) => {
      return (
        <LiveCard
          key={`channel-list-${item.id}`}
          liveChannel={item}
          logo={props.logos[item.id]?.png}
          i18n={props.i18n}
          channelsLocked={channelsLocked}
        />
      );
    },
    [props.logos, props.i18n, channelsLocked]
  );

  if (isEmpty(channels) || !props.isPlayerOpenned) {
    return <></>;
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className={`channel-list-container ${props.visible ? 'visible' : ''}`}>
        <FocusableStripe
          ref={stripeRef}
          focusKey="CHANNEL_LIST"
          items={channelListItems}
          disabled={!props.visible}
          visible={props.visible}
          renderItem={renderItem}
          axis="y"
          index={0}
          onStripeItemPress={playVideo}
          // getFocusKey={getFocusKey}
          onStripeItemFocus={props.visible ? onStripeItemFocus : undefined}
          onStripeItemHover={props.visible ? onStripeItemFocus : undefined}
          onStripeItemBlur={onStripeItemBlur}
          onArrowPress={props.visible ? onArrowPress : undefined}
          align="end"
          scrollPadding={scrollPadding}
          startOffsetPadding={startOffsetPadding}
          isFocusBoundary
          navigateMode={props.navigateMode}
        />
      </div>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({
  videoPlayer,
  liveChannels,
  favoriteChannels,
  logos,
  channelList,
  liveCategories,
  i18n,
  appConfig
}: any) => ({
  visible: videoPlayer.playerControlsState === 'channels',
  isPlayerOpenned: videoPlayer.video.isFullscreen,
  liveChannels,
  favoriteChannels,
  currentChannelId: videoPlayer.channelId,
  videoStart: videoPlayer.video?.start,
  videoStop: videoPlayer.video?.stop,
  channelList,
  liveCategories,
  logos,
  fastZapping: videoPlayer.video?.fastZapping,
  i18n,
  navigateMode: appConfig.navigateMode,
  currentChannelPosition: videoPlayer.video.channelPosition
});

export default React.memo(
  connect(mapStateToProps, {
    playVideo,
    setNextVideo,
    resetNextVideo,
    setVideoOptions,
    setChannelListCategory,
    clearChannelListCategory,
    setPlayerControlsState
  })(ChannelList)
);
