import { translationPacket } from 'common/actions';
import { getDateAndTimeFast } from 'common/services/helpers';
import { StoreState } from 'index';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { DescriptionDateRow, DescriptionDateRowTime } from './Description.styles';

interface DescriptionDayProps {
  start?: number;
  stop?: number;
}

function DescriptionDay(props: DescriptionDayProps) {
  const i18n = useSelector(
    (state: StoreState) => state.i18n,
    shallowEqual
  ) as typeof translationPacket;
  const dateTime = useMemo(
    () =>
      props.start && props.stop
        ? getDateAndTimeFast(props.start, props.stop, i18n.months)
        : { day: '-', month: '-', start: '-:-', stop: '-:-' },
    [props.start, props.stop, i18n.months]
  );

  return (
    <DescriptionDateRow>
      {`${dateTime.day} ${dateTime.month} · `}
      <DescriptionDateRowTime>{`${dateTime.start} - ${dateTime.stop}`}</DescriptionDateRowTime>
    </DescriptionDateRow>
  );
}

export default React.memo(DescriptionDay);
