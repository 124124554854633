import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

export type QualityIconSize = 'small' | 'large';

type QualityIconContainerProps = {
  size: QualityIconSize;
  noMargin?: boolean;
};

const FontSizeMap = {
  small: 11,
  large: 20
};

const PaddingMap = {
  small: [2, 6],
  large: [8, 12]
};

export const QualityIconContainer = styled.span<QualityIconContainerProps>`
  border-radius: ${getScalablePixel(6)}px;
  border: solid ${getScalablePixel(2)}px rgba(241, 241, 241, 0.2);
  font-weight: bold;
  width: fit-content;
  color: ${variables.font_color_b};
  ${({ noMargin }) => !noMargin && `margin: ${getScalablePixel(2)}px ${getScalablePixel(8)}px 0`};
  padding: ${({ size }) =>
    `${getScalablePixel(PaddingMap[size][0])}px ${getScalablePixel(PaddingMap[size][1])}px`};
  font-size: ${({ size }) => getScalablePixel(FontSizeMap[size])}px;
`;

export const QualityIconContainerStyle = ({ size }: QualityIconContainerProps) => `
  padding: ${`${getScalablePixel(PaddingMap[size][0])}px ${getScalablePixel(
    PaddingMap[size][1]
  )}px`};
  font-size: ${getScalablePixel(FontSizeMap[size])}px;
`;
