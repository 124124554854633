import CategoriesBackground00 from '@assets/media/archive_banner_background_00.png';
import CategoriesBackground01 from '@assets/media/archive_banner_background_01.png';
import CategoriesBackground02 from '@assets/media/archive_banner_background_02.png';
import CategoriesBackground03 from '@assets/media/archive_banner_background_03.png';
import CategoriesBackground04 from '@assets/media/archive_banner_background_04.png';
import CategoriesBackground05 from '@assets/media/archive_banner_background_05.png';
import CategoriesBackground06 from '@assets/media/archive_banner_background_06.png';
import CategoriesBackground07 from '@assets/media/archive_banner_background_07.png';
import CategoriesBackground08 from '@assets/media/archive_banner_background_08.png';
import CategoriesBackground09 from '@assets/media/archive_banner_background_09.png';
import CategoriesBackground10 from '@assets/media/archive_banner_background_10.png';
import CategoriesBackground11 from '@assets/media/archive_banner_background_11.png';
import CategoriesBackgroundFavorites from '@assets/media/archive_banner_background_favorites.png';
import { pad } from 'common/services/helpers';

const CATEGORIES_BG = {
  '00': CategoriesBackground00,
  '01': CategoriesBackground01,
  '02': CategoriesBackground02,
  '03': CategoriesBackground03,
  '04': CategoriesBackground04,
  '05': CategoriesBackground05,
  '06': CategoriesBackground06,
  '07': CategoriesBackground07,
  '08': CategoriesBackground08,
  '09': CategoriesBackground09,
  '10': CategoriesBackground10,
  '11': CategoriesBackground11,
  favorites: CategoriesBackgroundFavorites,
};

export const getCategoryBackground = (catIdx: number): string => {
  let BG = CATEGORIES_BG[`${pad(catIdx)}`];

  if (!BG) {
    const dummyKey = Math.floor(Math.random() * 12);
    BG = CATEGORIES_BG[`${pad(dummyKey)}`];
  }

  return BG;
};
