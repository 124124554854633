import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';

GlobalStyle.inject`

.search-breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    &.focused {

        flex-direction: column;
        .search-title {
            transform: scale3d(1.42,1.42,1.42);
            opacity: 1;
        }
        
        .linear-search-item-container {
            transform: translateY(50%);
            margin-bottom: ${getScalablePixel(32)}px;
            width: ${getScalablePixel(716)}px;
            opacity: 1;
        }
    }

    .search-title {
        font-size: ${getScalablePixel(28)}px;
        font-weight: bold;
        color: ${variables.color_white};
        opacity: 0.4;
        padding-right: ${getScalablePixel(32)}px;
        transform-origin: left;
        transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms;
        will-change: opacity, transform;
    }


    .helper-text {
        opacity: 0.4;

        font-size: ${getScalablePixel(20)}px;
        color: ${variables.color_white};
        margin-top: ${getScalablePixel(48)}px;
    }

    .linear-search-item-container {
        position: relative;
        border-radius: ${getScalablePixel(8)}px;
        border: ${getScalablePixel(1)}px solid ${variables.color_grey_10};
        padding: ${getScalablePixel(16)}px 0;
        padding-left: ${getScalablePixel(68)}px;
        opacity: 0.4;
        width: ${getScalablePixel(442)}px;
        height: ${getScalablePixel(64)}px;
        box-sizing: border-box;
        background: ${hexToRGB(variables.color_grey_90, 0.2)};
        will-change: transform, width, opacity, content;
        transition: transform ${variables.DEFAULT_ANIMATION_DURATION}ms,
            width ${variables.DEFAULT_ANIMATION_DURATION}ms,
            opacity ${variables.DEFAULT_ANIMATION_DURATION}ms;

        .inner-search-icon {
            position: absolute;
            top: 50%;
            left: ${getScalablePixel(32)}px;
            transform: translateY(-50%);
            width: ${getScalablePixel(24)}px;
        }

        &:before {
            dispay: none;
            content: unset;
        }
        input {
            all: unset;
            flex: 1;
            font-size: ${getScalablePixel(26)}px;
            color: ${variables.font_color_white};
            text-align: left;
        
            &::placeholder {
              font-size: ${getScalablePixel(26)}px;
              color: ${variables.font_color_white};
              opacity: 0.4;
              text-align: left;
            }
        }
    }
}
`;
