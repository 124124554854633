import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { usePopup } from 'common/contexts/popup';
import { getRemoteKeyName } from 'common/utils';
import { useStateCallback } from 'common/utils/hooks';
import { isEmpty, uniqueId } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { DrawerContainer, DrawerElement, DrawerOverlay, PositionContent } from './Drawer.styles';

interface DrawerOptions {
  id?: string;
  content: React.ReactElement | React.ReactElement[];
  positionContent?: PositionContent;
  onOpen?: (id: string) => void;
  onClose?: (id: string) => void;
}

interface DrawerElement extends DrawerOptions {
  lastFocusedKey?: string;
}

interface DrawerContext {
  id?: string;
  hasDrawer?: boolean;
  openDrawer: (drawer: DrawerOptions) => void;
  closeDrawer: (id: string, returnFocus?: boolean) => void;
  closeAll: (returnFocus?: boolean) => void;
  getDrawers: () => DrawerElement[];
}

//@ts-ignore
const DrawerContextInner = React.createContext<DrawerContext>();

export const useDrawer = () => useContext(DrawerContextInner);

export function DrawerProvier(props: React.PropsWithChildren) {
  const { ref, hasFocusedChild, focusKey } = useFocusable({
    focusKey: 'DRAWER',
    isFocusBoundary: true,
    trackChildren: true
  });
  const [drawers, setDrawers] = useStateCallback<DrawerElement[]>([]);
  const drawersRef = useRef(drawers);
  const { hasPopups } = usePopup();

  const hasOverlay = useMemo(() => hasPopups, [hasPopups]);
  const hasOverlayRef = useRef(hasOverlay);

  useEffect(() => {
    drawersRef.current = drawers;
    hasOverlayRef.current = hasOverlay;
  }, [drawers, hasOverlay]);

  const handleOpenDrawer = useCallback(
    (options: DrawerOptions) => {
      // Save last focused key before opening drawer
      const key = getCurrentFocusKey();
      //
      setDrawers(
        (current) => [...current, { id: uniqueId('drawer'), lastFocusedKey: key, ...options }],
        () => {
          ref.current?.focus();
        }
      );
    },
    [ref.current]
  );

  const handleCloseDrawer = useCallback((id: string, returnFocus = true) => {
    let lastFocusedKey: string | undefined | false;
    let drawer: DrawerElement | undefined;
    setDrawers(
      (currentDrawers) => {
        drawer = currentDrawers.find((p) => p.id === id);
        if (drawer) {
          const index = currentDrawers.indexOf(drawer);
          const newDrawers = [...currentDrawers];
          newDrawers.splice(index, 1);
          lastFocusedKey = currentDrawers[index].lastFocusedKey;
          return newDrawers;
        }
        return currentDrawers;
      },
      () => {
        returnFocus && lastFocusedKey && setFocus(lastFocusedKey);
        drawer?.onClose && drawer.onClose(id);
      }
    );
  }, []);

  const handleCloseAll = useCallback((returnFocus = true) => {
    let lastFocusedKey: string | undefined | false;
    let drawer: DrawerElement | undefined;

    setDrawers(
      (currentDrawers) => {
        drawer = currentDrawers[0];

        return [];
      },
      () => {
        returnFocus && lastFocusedKey && setFocus(lastFocusedKey);
        drawer && drawer?.onClose && drawer.onClose(drawer.id as string);
      }
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      hasDrawer: drawers.length > 0,
      openDrawer: handleOpenDrawer,
      closeDrawer: handleCloseDrawer,
      closeAll: handleCloseAll,
      getDrawers: () => drawers
    }),
    [drawers]
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Handle close on back
      if (getRemoteKeyName(e.keyCode) === 'BACK' && drawers.length > 0) {
        const id = drawers[drawers.length - 1]?.id;
        id && handleCloseDrawer(id);
      }
    };
    hasFocusedChild && document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasFocusedChild, drawers]);

  // Close drawers when focus has moved outside of the drawer
  // But keep the drawers if we have an overlay over the drawers
  useEffect(() => {
    if (!hasFocusedChild && !isEmpty(drawersRef.current) && !hasOverlayRef.current) {
      handleCloseAll();
    }
  }, [hasFocusedChild]);

  const renderDrawers = () => {
    return drawers.map((drawer) => {
      return (
        <DrawerElement key={drawer.id} positionContent={drawer.positionContent}>
          {drawer.content}
        </DrawerElement>
      );
    });
  };

  return (
    <DrawerContextInner.Provider value={contextValue}>
      {props.children}
      <FocusContext.Provider value={focusKey}>
        <DrawerOverlay visible={drawers.length > 0} />
        <DrawerContainer tabIndex={hasFocusedChild ? 0 : -1} ref={ref} visible={drawers.length > 0}>
          {renderDrawers()}
        </DrawerContainer>
      </FocusContext.Provider>
    </DrawerContextInner.Provider>
  );
}
