import { prismicClient } from 'common/components/carousel/prismic';
import {
  RETRIEVE_PRISMIC_RADIO_IMAGES,
  RETRIEVE_PRISMIC_SCREENSAVER_IMAGES
} from 'common/constants/action-types';
import { AppDispatch } from 'index';
import { isEmpty } from 'lodash';

export const retrievePrismicRadioImages = () => {
  return async (dispatch: AppDispatch) => {
    try {
      if (!prismicClient) return;
      const response = await prismicClient.getAllByType('radio_images');
      if (isEmpty(response)) return;

      const [{ data }] = response;
      dispatch({
        type: RETRIEVE_PRISMIC_RADIO_IMAGES,
        payload: data
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const retrievePrismicScreensaverImages = () => {
  return async (dispatch: AppDispatch) => {
    try {
      if (!prismicClient) return;
      const response = await prismicClient.getAllByType('screensaver');
      if (isEmpty(response)) return;

      const [{ data }] = response;
      dispatch({
        type: RETRIEVE_PRISMIC_SCREENSAVER_IMAGES,
        payload: data
      });
    } catch (error) {
      console.warn(error);
    }
  };
};
