import { init, navigateByDirection, setFocus } from '@noriginmedia/norigin-spatial-navigation';
import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from 'common/components/error-boundary/ErrorBoundaryFallback';
import FpsMeter from 'common/components/fps-meter';
import { NoNetworkPopup } from 'common/components/no-network-popup';
import { ROUTES_WITH_HEADER, ROUTE_ROOT, ROUTE_SIGN_IN } from 'common/config/constants';
import variables from 'common/config/variables';
import { usePopup } from 'common/contexts/popup';
import { AppConfigState, NavigateMode } from 'common/reducers/appConfig';
import { Errors } from 'common/reducers/errors';
import AbortHandler from 'common/services/AbortHandler';
import { hasCredentialsObjectInMemory, hasTokenInMemory } from 'common/services/helpers';
import { getRemoteKeyName } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { isInputFieldFocused } from 'common/utils/helpers';
import {
  useMessages,
  useOnlineStatus,
  usePageNavigation,
  useRequiredData
} from 'common/utils/hooks';
import React, { Suspense, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import WhiteLabel from 'white-label';
import './App.styles';
import {
  clearError,
  clearLiveChannels,
  getTranslations,
  setAppNavigateMode
} from './common/actions';

init({
  debug: false,
  visualDebug: false,
  throttle: variables.KEYPRESS_TIMEOUT,
  throttleKeypresses: true
  // useGetBoundingClientRect: true
});

interface AppProps {
  errors: Errors;
  navigateMode: NavigateMode;
  clearError: VoidFunction;
  getTranslations: () => Promise<void>;
  setAppNavigateMode: (mode: NavigateMode) => Promise<void>;
  clearLiveChannels: VoidFunction;
}

function App(props: AppProps) {
  const { get: getData, clear: clearData } = useRequiredData();
  const navigate = usePageNavigation();
  const { pathname } = useLocation();
  const [isOnline, prevOnlineStatus] = useOnlineStatus();
  const { openPopup, closePopup } = usePopup();
  const { handleMessages: handleCheckForMessages } = useMessages();

  // Only show messages when we are in the main pages
  // The main pages are pages with header
  const showMessagePromptInPage = useMemo(() => ROUTES_WITH_HEADER.includes(pathname), [pathname]);

  useEffect(() => {
    if (showMessagePromptInPage) {
      handleCheckForMessages();
    }
  }, [showMessagePromptInPage, handleCheckForMessages]);

  // !!! PREVENT back key
  // Needed for Phillips TV
  // This useEffect is separate from the one using the navigateMode
  // Because we want only on app start to attach this event
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      // Check if back key
      if (getRemoteKeyName(e.keyCode) === 'BACK' && !isInputFieldFocused()) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    const onScroll = (event: any) => {
      if (event.deltaY < 0) {
        navigateByDirection('up', event);
      } else {
        // wheeled down
        navigateByDirection('down', event);
      }
    };

    const cursorStateChange = (event: any) => {
      props.setAppNavigateMode(event.detail && event.detail.visibility ? 'POINTER' : 'DIRECTIONAL');
    };

    const onKeyPress = (e: KeyboardEvent) => {
      // Check if back key
      if (getRemoteKeyName(e.keyCode) !== 'BACK') {
        props.navigateMode !== 'DIRECTIONAL' && props.setAppNavigateMode('DIRECTIONAL');
      }
    };

    const onMouseMove = () => {
      props.navigateMode !== 'POINTER' && props.setAppNavigateMode('POINTER');
    };

    window.addEventListener('wheel', onScroll);
    document.addEventListener('cursorStateChange', cursorStateChange);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('keyup', onKeyPress);

    return () => {
      document.removeEventListener('cursorStateChange', cursorStateChange);
      window.removeEventListener('wheel', onScroll);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [props.navigateMode]);

  useEffect(() => {
    if (!isOnline) {
      // Clear data when network is missing
      clearData();
      //
      openPopup({
        id: 'no-network',
        content: <NoNetworkPopup />
      });
    } else if (!prevOnlineStatus) {
      // Wait after network restore to ensure stable network connection
      // Refresh with new data
      getData().then((response) => {
        //
        closePopup('no-network');
        // Set focus
        setFocus('APP_CONTAINER');
        //
        if (!response) {
          console.error('Failed to get data after network restore');
          navigate(ROUTE_SIGN_IN, true, true);
        }
      });
    }
  }, [isOnline, prevOnlineStatus]);

  useEffect(() => {
    if (props.errors.redirect) {
      navigate(props.errors.redirect, true, true);
    }
  }, [props.errors]);

  useEffect(() => {
    (async () => {
      // Always load translations
      await props.getTranslations();
      //
      if (hasTokenInMemory() || hasCredentialsObjectInMemory()) {
        navigate(ROUTE_ROOT, true, true);
        return;
      } else {
        navigate(ROUTE_SIGN_IN);
        return;
      }
    })();
    return () => {
      AbortHandler.abort();
    };
  }, []);

  return (
    <>
      <Suspense>
        {GlobalStyle.render()}
        <Sentry.ErrorBoundary
          fallback={((data) => <ErrorBoundaryFallback {...data} />) as Sentry.FallbackRender}
        >
          <WhiteLabel />
          <FpsMeter />
        </Sentry.ErrorBoundary>
      </Suspense>
    </>
  );
}

const mapStateToProps = ({ errors, appConfig }: { errors: any; appConfig: AppConfigState }) => ({
  errors,
  navigateMode: appConfig.navigateMode
});

export default React.memo(
  connect(mapStateToProps, {
    clearError,
    getTranslations,
    setAppNavigateMode,
    clearLiveChannels
  })(App)
);
