import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { translationPacket } from 'common/actions';
import { getScalablePixel } from 'common/utils';
const { font_color_b, font_color_f, font_color_white } = variables;

import variables from 'common/config/variables';
import PaidDescription from '../PaidDescription';
import MessagePopup from './MessagePopup';

const commonStyles = css`
  text-align: center;
  width: ${getScalablePixel(702)}px;
`;

const Title = styled.div`
  font-size: ${getScalablePixel(32)}px;
  color: ${font_color_white};
  margin-bottom: ${getScalablePixel(32)}px;
  ${commonStyles};
`;

interface PaidChannelPopupProps {
  popupId: string;
  i18n: typeof translationPacket;
}

function PaidChannelPopup({ i18n, popupId }: PaidChannelPopupProps) {
  return (
    <MessagePopup popupId={popupId}>
      <Title>{i18n.popups.noSubscription.title}</Title>
      <PaidDescription />
    </MessagePopup>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(PaidChannelPopup));
