import { usePopup } from 'common/contexts/popup';
import React, { useCallback } from 'react';
import PinPrompt from './PinPrompt';

interface LockedChannelPopupProps {
  popupId: string;
  onSuccess: () => void;
}

function LockedChannelPopup({ popupId, onSuccess: onSuccessParam }: LockedChannelPopupProps) {
  const { closePopup } = usePopup();
  const onSuccess = useCallback(() => {
    closePopup(popupId, onSuccessParam);
  }, [onSuccessParam, popupId]);

  const onBack = useCallback(() => {
    closePopup(popupId);
  }, []);

  return <PinPrompt onSuccess={onSuccess} onBack={onBack} lockedChannelTitle />;
}

export default React.memo(LockedChannelPopup);
