import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { translationPacket } from 'common/actions';
import { Epg } from 'common/constants/data-types';

import {
  DescriptionStaffContainer,
  DescriptionStaffData,
  DescriptionStaffRow,
  DescriptionStaffTitle
} from './Description.styles';

interface DescriptionStaffProps {
  actors?: Epg['actors'];
  persons?: Epg['persons'];
  i18n: typeof translationPacket;
  linesDirector?: number;
  linesActors?: number;
  align?: 'left' | 'right';
}

function DescriptionStaff({
  actors,
  persons,
  i18n,
  linesDirector,
  linesActors,
  align = 'right'
}: DescriptionStaffProps) {
  if (isEmpty(persons) && isEmpty(actors)) {
    return null;
  }

  const director = persons?.find((e) => e.function === 'Director');

  const renderDirector = () => {
    if (!director) {
      return null;
    }

    return (
      <DescriptionStaffRow>
        <DescriptionStaffTitle align={align}>{i18n.common.director}:</DescriptionStaffTitle>
        <DescriptionStaffData lines={linesDirector}>{director.name}</DescriptionStaffData>
      </DescriptionStaffRow>
    );
  };

  const renderActors = () => {
    if (!actors) {
      return null;
    }

    return (
      <DescriptionStaffRow>
        <DescriptionStaffTitle align={align}>{i18n.common.actors}:</DescriptionStaffTitle>
        <DescriptionStaffData lines={linesActors}>{actors.join(', ')}</DescriptionStaffData>
      </DescriptionStaffRow>
    );
  };

  return (
    <DescriptionStaffContainer>
      {renderDirector()}
      {renderActors()}
    </DescriptionStaffContainer>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(DescriptionStaff));
