import { ClampContainerMixin } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import { hexToRGB } from 'common/utils/helpers';
import styled, { css } from 'styled-components';

export const PopupContainer = styled.div<{ visible?: boolean }>`
  position: fixed;
  outline: none;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const SlideIn = css`
  @keyframes message-slide-in {
    from {
      transform: translate3d(100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  animation: message-slide-in ${variables.MOUNT_FADEIN_TIMEOUT}ms ease-in-out;
`;

const SlideOut = css`
  @keyframes message-slide-out {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(100%, 0, 0);
      opacity: 1;
    }
  }
  animation: message-slide-out ${variables.MOUNT_FADEIN_TIMEOUT}ms ease-in-out;
`;

GlobalStyle.inject`
.message-prompt-item {
  position: absolute;
  top: ${getScalablePixel(55)}px;
  right: 0;
  min-width: ${getScalablePixel(810)}px;
  min-height: ${getScalablePixel(230)}px;
  background: ${variables.color_grey_80};
  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: 1;
  border-top-left-radius: ${getScalablePixel(8)}px;
  border-bottom-left-radius: ${getScalablePixel(8)}px;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  
  &.close {
    ${SlideOut}
  }

  &.shown {
    ${SlideIn}
    opacity: 1;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${getScalablePixel(546)}px;
    z-index: 1;
    background: linear-gradient(to left, ${variables.color_grey_80} 30%, transparent 55%);
  }

  .cover {
    width: ${getScalablePixel(546)}px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: ${getScalablePixel(21)}px;
    padding-right: ${getScalablePixel(68)}px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: ${variables.color_grey_80};
    width: ${getScalablePixel(410)}px;
    height: 100%;
    box-sizing: border-box;

    .title {
      font-size: ${getScalablePixel(22)}px;
      padding-bottom: ${getScalablePixel(12)}px;
      color: ${variables.color_grey_10};
      font-weight: bolder;
      border-bottom: ${getScalablePixel(1)}px dashed ${variables.color_grey_10};

      &:before {
        content: '';
        display: inline-block;
        width: ${getScalablePixel(13)}px;
        height:  ${getScalablePixel(13)}px;
        background-color: ${variables.border_color_detail};
        margin-right: ${getScalablePixel(16)}px;
        border-radius: ${getScalablePixel(32)}px;
      }
    }
    .subtitle {
      font-size: ${getScalablePixel(26)}px;
      ${ClampContainerMixin({ lines: 2 })}
      color: ${variables.color_grey_40};
      font-weight: bold;
    }

    .read-more {
      width: ${getScalablePixel(205)}px;
      height: ${getScalablePixel(50)}px;
      overflow: hidden;
      color: ${variables.color_grey_90};
      font-size: ${getScalablePixel(22)}px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: ${getScalablePixel(4)}px;
      border: ${getScalablePixel(2)}px solid ${hexToRGB(variables.color_grey_30, 0.2)};

      @keyframes fill-button-time {
        from {
          transform: scaleX(1);
        }
        to {
          transform: scaleX(0);
        }
      }
      &:before {
        background: ${variables.color_grey_30};
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: -1;
      }
      &:after {
        background: ${variables.color_grey_20};
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        position: absolute;
        animation: fill-button-time ${variables.AUTO_DISMISS_MESSAGE_TIMEOUT}ms linear forwards;
        transform-origin: left;
      }
    }
  }
}
`;
