import MailIcon from '@assets/media/mail-icon.svg';
import {
  doesFocusableExist,
  navigateByDirection,
  setFocus
} from '@noriginmedia/norigin-spatial-navigation';
import {
  deleteMessages,
  getMessages,
  readMessages,
  sortMessages,
  sortMessagesByRead,
  Translation
} from 'common/actions';
import { DefaultButton } from 'common/components/button';
import { Image } from 'common/components/image';
import FocusableStripe from 'common/components/stripe/FocusableStripe';
import { H1, H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { NavigateMode } from 'common/reducers/appConfig';
import { Message, Messages } from 'common/reducers/messages';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import MessageItem from './MessageItem';

const sortByTimestamp = (messages: Messages) => {
  const items = [...messages];
  const sorted = items.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
  return sorted;
};

interface MessageListProps {
  // Redux injected
  i18n: Translation;
  navigateMode: NavigateMode;
  messages: Messages;
  getMessages: () => Promise<Messages>;
  readMessages: (ids: string | string[]) => Promise<void>;
  deleteMessages: (ids: string | string[]) => Promise<void>;
  sortMessages: () => Promise<void>;
  //
  initialMessageId: string | undefined | null;
  onMessageFocus: (message: Message) => void;
  onMessageBlur?: () => void;
  onMessageEmpty?: () => void;
  onBack?: () => void;
}

function MessageList(props: MessageListProps) {
  const [items, setItems] = useState<Messages>([]);
  const [groupCounts, setGroupCounts] = useState<number[]>([0]);
  const [activeMessageForDelete, setActiveMessageForDelete] = useState<string | undefined>();
  const messagesRef = useRef<Message[]>([]);
  const autoReadMessageTimeoutRef = useRef<DebouncedFunc<VoidFunction>>();
  const focusTimeoutRef = useRef<NodeJS.Timeout>();
  const activeMessageForDeleteRef = useRef(activeMessageForDelete);
  const itemToBeMovedRef = useRef<Message | undefined>();
  const moveDirectionRef = useRef<string | undefined>();
  const [itemToBeMoved, setItemToBeMoved] = useState<Message | undefined>();
  const { enqueueSnackbar } = useSnackbar();

  const noMessages = useMemo(() => isEmpty(items), [items]);

  // Handle resort of message list and update group counts
  const handleSortMessages = useCallback(() => {
    setItems((current) => {
      const sorted = sortMessagesByRead(current);
      const unreadLen = sorted.filter((e) => !e.read).length;
      const readLen = sorted.filter((e) => e.read).length;
      setGroupCounts(unreadLen <= 0 ? [readLen] : [unreadLen, readLen]);
      // Get next item in the list
      const currentItemIndex = messagesRef.current?.findIndex(
        (e) => e.id === itemToBeMovedRef.current?.id
      );
      // Focus on the next item after removing the last item
      if (currentItemIndex !== -1) {
        const nextItem =
          messagesRef.current[currentItemIndex + (moveDirectionRef.current === 'up' ? -1 : 1)];
        if (nextItem) {
          const key = `message-item-${nextItem.id}`;
          doesFocusableExist(key) && setFocus(key);
        }
      }
      //Clean up
      itemToBeMovedRef.current = undefined;
      moveDirectionRef.current = undefined;
      setItemToBeMoved(undefined);
      return sorted;
    });
  }, []);

  // Update local messages
  useEffect(() => {
    // Removing warnings from inbox
    const messages = props.messages.filter((e) => e.priority !== 'warning');
    //
    setItems((current) => {
      const resortMessage = isEmpty(current) || current.length !== messages.length;
      const _items = !resortMessage
        ? // Sort messages like they are sorted inside the state when the lenght is different
          messages.sort((a, b) => current.indexOf(a) - current.indexOf(b))
        : sortMessagesByRead(messages);
      if (resortMessage) {
        const unreadLen = _items.filter((e) => !e.read).length;
        const readLen = _items.filter((e) => e.read).length;
        setGroupCounts([unreadLen, readLen]);
      }
      return _items;
    });
  }, [props.messages]);
  //

  useEffect(() => {
    props.getMessages();
    return () => {
      // Resort messages in store when exiting
      props.sortMessages();
      clearTimeout(focusTimeoutRef.current);
      autoReadMessageTimeoutRef.current?.cancel();
    };
  }, []);

  useEffect(() => {
    activeMessageForDeleteRef.current = activeMessageForDelete;
  }, [activeMessageForDelete]);

  useEffect(() => {
    if (noMessages) {
      setFocus('CLOSE_INBOX_BUTTON');
      props.onMessageEmpty && props.onMessageEmpty();
    }
  }, [noMessages, props.onMessageEmpty]);

  const messageItems = useMemo(() => {
    if (isEmpty(items)) return [];
    const hasOneGroup = groupCounts.length < 2;
    const unread = hasOneGroup ? [] : sortByTimestamp(items.slice(0, groupCounts[0]));
    const read = sortByTimestamp(hasOneGroup ? items : items.slice(groupCounts[0]));
    const messages = [
      { title: props.i18n?.inbox?.unread, items: unread },
      { title: props.i18n?.inbox?.read, items: read }
    ];
    messagesRef.current = [...unread, ...read];
    return messages;
  }, [items, groupCounts]);

  const initialIndex = useMemo(() => {
    if (isEmpty(messagesRef.current) || isEmpty(props.initialMessageId)) return 0;
    const index = messagesRef.current.findIndex((e) => e.id === props.initialMessageId);
    return index === -1 ? 0 : index;
  }, [props.initialMessageId]);

  const onArrowPress = useCallback((direction: string, data: { index: number }) => {
    if (direction === 'right') {
      try {
        const { index } = data;
        const message = messagesRef.current[index];
        if (message) {
          setActiveMessageForDelete(message.id);
        }
      } catch (error) {
        console.warn(error);
      }
      return false;
    } else if (['up', 'down'].includes(direction) && itemToBeMovedRef.current) {
      setItemToBeMoved(itemToBeMovedRef.current);
      moveDirectionRef.current = direction;
      return false;
    }
    setActiveMessageForDelete(undefined);
    return true;
  }, []);

  const onStripeItemFocus = useCallback(
    (item: Message) => {
      props.onMessageFocus && props.onMessageFocus(item);
      autoReadMessageTimeoutRef.current?.cancel();
      clearTimeout(focusTimeoutRef.current);
      if (!item?.read) {
        focusTimeoutRef.current = setTimeout(() => {
          autoReadMessageTimeoutRef.current = debounce(async () => {
            itemToBeMovedRef.current = item;
            await props.readMessages(item.id);
          }, variables.AUTO_READ_MESSAGE_TIMEOUT);
          autoReadMessageTimeoutRef.current();
        }, variables.LONG_THROTTLE_TIMEOUT);
      } else {
        itemToBeMovedRef.current = undefined;
      }
    },
    [props.onMessageFocus]
  );

  const onStripeItemBlur = useCallback(async () => {
    props.onMessageBlur && props.onMessageBlur();
    await autoReadMessageTimeoutRef.current?.cancel();
  }, [props.onMessageBlur]);

  const onStripeItemPress = useCallback(
    async (item: Message) => {
      setActiveMessageForDelete(undefined);
      // Delete message when active flag is true
      if (activeMessageForDeleteRef.current) {
        props
          .deleteMessages(item.id)
          .catch(() => enqueueSnackbar(props.i18n.inbox?.errorWhenDeleting));
        // Move focus up when deleting items
        navigateByDirection('up', {});
        //
        handleSortMessages();
        // Clear move flag when deleting item
        itemToBeMovedRef.current = undefined;
        //
        enqueueSnackbar(props.i18n.inbox?.successfulyDeleted);
      }
    },
    [props.i18n]
  );

  const renderGroupItem = useCallback((item: string) => {
    return (
      <div key={item} className="message-inbox-group-title">
        {item}
      </div>
    );
  }, []);

  const renderMessageItem = useCallback(
    (item: Message) => {
      return (
        <MessageItem
          key={item.id}
          message={item as Message}
          i18n={props.i18n}
          activeForDelete={activeMessageForDelete === item.id}
          activeForMoving={itemToBeMoved?.id === item.id}
          onMoveFinish={handleSortMessages}
        />
      );
    },
    [
      activeMessageForDelete,
      props.i18n,
      handleSortMessages,
      itemToBeMoved,
      onStripeItemFocus,
      onStripeItemBlur
    ]
  );

  const getFocusKey = useCallback((item: Message) => {
    return `message-item-${item.id}`;
  }, []);

  const onScrollToIndex = useCallback((index: number) => {
    if (messagesRef.current && !isEmpty(messagesRef.current)) {
      const item = messagesRef.current[index];
      if (item) {
        // Request animation frame so the viewport list can catch up
        requestAnimationFrame(() => {
          const key = `message-item-${item.id}`;
          doesFocusableExist(key) && setFocus(key);
        });
      }
    }
  }, []);

  const renderNoMessagesInfo = useCallback(() => {
    return (
      <div className="no-messages-container">
        <div className="no-messages-info-content">
          <Image className="no-message-icon" src={MailIcon} />
          <H2>{props.i18n.inbox.noMessages}</H2>
        </div>
        <DefaultButton
          title={props.i18n.inbox.close}
          focusKey="CLOSE_INBOX_BUTTON"
          onEnterPress={props.onBack}
        />
      </div>
    );
  }, [props.i18n, props.onBack]);

  if (isEmpty(props.i18n)) {
    return <></>;
  }
  return (
    <div className="message-list">
      <div className="message-list-title">
        <H1>{props.i18n.pageNames.messages}</H1>
      </div>
      <div className="message-list-container">
        <FocusableStripe
          axis="y"
          focusKey="INBOX_MESSAGE_LIST"
          navigateMode={props.navigateMode}
          stripeItemClassName="inbox-message-item"
          isFocusBoundary
          align="center"
          groupItems={messageItems}
          renderGroupItem={renderGroupItem}
          initialIndex={initialIndex}
          visible={noMessages}
          disabled={noMessages}
          onScrollToIndex={onScrollToIndex}
          onStripeItemFocus={onStripeItemFocus}
          onStripeItemBlur={onStripeItemBlur}
          onStripeItemPress={onStripeItemPress}
          onArrowPress={onArrowPress}
          onBlur={props.onMessageEmpty}
          renderItem={renderMessageItem}
          getFocusKey={getFocusKey}
        />
        {noMessages && renderNoMessagesInfo()}
      </div>
    </div>
  );
}

const mapStateToProps = ({ i18n, messages, appConfig }: any) => {
  return {
    i18n,
    messages,
    navigateMode: appConfig.navigateMode
  };
};

export default React.memo(
  connect(mapStateToProps, { getMessages, readMessages, deleteMessages, sortMessages })(MessageList)
);
