import { resetVideo } from 'common/actions';
import {
  timeLogicFunction,
  TimeObject,
  TIME_LOGIC_UPDATE_INTERVAL
} from 'common/components/trial/utils';
import { ROUTE_TRIAL_END } from 'common/config/constants';
import { AppDispatch, StoreState } from 'index';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import usePageNavigation from './usePageNavigation';
import useRequiredData from './useRequiredData';
import useVisibilityState from './useVisibilityState';

let updateInterval: NodeJS.Timer | null = null;

const useTrialTimeLogic = () => {
  const userDetails = useSelector((state: StoreState) => state.userDetails, shallowEqual);
  const [data, setData] = useState<TimeObject | null>(null);
  const navigate = usePageNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const isVisible = useVisibilityState();
  const { clear: clearData } = useRequiredData();

  const handleUpdateTimer = useCallback(() => {
    setData(timeLogicFunction(userDetails.trial.expire));
  }, [userDetails.trial]);

  useEffect(() => {
    // When the component mounts with the needed params calculate timeObject
    if (!isEmpty(userDetails) && !isEmpty(userDetails.trial) && isVisible) {
      handleUpdateTimer();
      // Only create the interval when the global reference is not yet set
      if (updateInterval === null)
        updateInterval = setInterval(handleUpdateTimer, TIME_LOGIC_UPDATE_INTERVAL);
    }
    // Clear when the component unmounts
    else {
      updateInterval && clearInterval(updateInterval);
      updateInterval = null;
    }
  }, [userDetails, isVisible]);

  // Triger expiration
  useEffect(() => {
    if (data?.expired) {
      // Reset video
      dispatch(resetVideo());
      clearData();
      //
      navigate(ROUTE_TRIAL_END, true);
    }
  }, [data]);

  return data;
};

export default useTrialTimeLogic;
