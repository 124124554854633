import { LIVE_CHANNELS_CLEAR, LIVE_CHANNELS_GET } from 'common/constants/action-types';

const defaultState = {}

export default function (state = defaultState, action) {
  switch (action.type) {
    case LIVE_CHANNELS_GET:
      return action.payload;
    case LIVE_CHANNELS_CLEAR:
      return {};
    default:
      return state;
  }
}
