import { P1 } from 'common/components/Typography';
import { toHHMMSS } from 'common/services/helpers';
import { getScalablePixel } from 'common/utils';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { StreamTimesContainer } from './Controls.styles';

const styles = {
  fontSize: getScalablePixel(32),
  textAlign: 'center',
  minWidth: getScalablePixel(130)
};

interface StreamTimesProps {
  currentTime?: number;
  duration?: number;
  visible?: boolean;
}
function StreamTimes(props: StreamTimesProps) {
  const currentTime = useMemo(
    () => (!isNil(props.currentTime) ? toHHMMSS(props.currentTime) : '-:-'),
    [props.currentTime]
  );
  const duration = useMemo(
    () => (!isNil(props.duration) ? toHHMMSS(props.duration) : '-:-'),
    [props.duration]
  );

  return (
    <StreamTimesContainer visible={props.visible}>
      <P1 style={{ ...styles, textAlign: 'end', paddingRight: getScalablePixel(6) }}>
        {currentTime}
      </P1>
      <P1 style={{ ...styles, textAlign: 'start' }}>/ {duration}</P1>
    </StreamTimesContainer>
  );
}

export default React.memo(StreamTimes);
