import {
  CLEAR_MESSAGES,
  DELETE_MESSAGES,
  GET_MESSAGES,
  READ_MESSAGES,
  SORT_MESSAGES
} from 'common/constants/action-types';
import { Messages } from 'common/reducers/messages';
import request from 'common/services/request';
import { AppDispatch } from 'index';

export const sortMessagesByRead = (messages: Messages) => {
  const sorted = [...messages].sort((a, b) => Number(a.read) - Number(b.read));
  return sorted;
};

export const getMessages = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await request({
        method: 'GET',
        url: '/users/me/messages'
      });
      const sorted = sortMessagesByRead(data);
      dispatch({
        type: GET_MESSAGES,
        payload: sorted
      });

      return sorted;
    } catch (error) {
      console.warn(error);
      return [];
    }
  };
};

export const sortMessages = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SORT_MESSAGES
      });
    } catch (error) {
      console.warn(error);
    }
  };
};

export const clearMessages = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CLEAR_MESSAGES
    });
  };
};

export const deleteMessages = (_ids: string | string[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const ids = [_ids].flat();
      const response = request({
        method: 'DELETE',
        url: '/users/me/messages',
        data: { ids }
      });
      dispatch({
        type: DELETE_MESSAGES,
        payload: { ids }
      });
      return await response;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  };
};

export const readMessages = (_ids: string | string[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const ids = [_ids].flat();
      const response = request({
        method: 'PUT',
        url: '/users/me/messages',
        data: { ids }
      });

      dispatch({
        type: READ_MESSAGES,
        payload: { ids }
      });
      return await response;
    } catch (error) {
      console.warn(error);
    }
  };
};
