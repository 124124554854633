import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import GlobalStyle from 'common/utils/GlobalStyle';
import React, { useMemo } from 'react';
import { QualityIconContainerStyle, QualityIconSize } from './QualityIcon.styles';

GlobalStyle.inject`
.quality-icon-container {
  border-radius: ${getScalablePixel(6)}px;
  border: solid ${getScalablePixel(2)}px rgba(241, 241, 241, 0.2);
  font-weight: bold;
  width: fit-content;
  color: ${variables.font_color_b};
  margin: ${getScalablePixel(2)}px ${getScalablePixel(8)}px 0

  &.no-margin {
    margin: unset;
  }
  
  &.small {
    ${QualityIconContainerStyle({ size: 'small' })}
  }
  &.large {
    ${QualityIconContainerStyle({ size: 'large' })}
  }
}
`;
interface QualityIconProps {
  resolutionHeight: number;
  size: QualityIconSize;
  noMargin?: boolean;
}
export default function QualityIcon({ resolutionHeight, size, noMargin }: QualityIconProps) {
  const name = useMemo(() => {
    const height = resolutionHeight;
    if (height < 720) {
      return 'SD';
    }
    if (height === 720) {
      return 'HD';
    }
    if (height === 1080) {
      return 'FHD';
    }
    if (height === 2160) {
      return '4K';
    }
    // Fallback
    return 'SD';
  }, [resolutionHeight]);
  return (
    <span className={`quality-icon-container ${size} ${noMargin ? 'no-margin' : ''}`}>{name}</span>
  );
}
