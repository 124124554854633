import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import PieBackground from '@assets/media/rating-pie-icon.png';
import { Translation } from 'common/actions';
import { Image } from 'common/components/image';
import variables from 'common/config/variables';
import { getClassName } from 'common/utils';
import { clamp, isEmpty } from 'lodash';
import { connect } from 'react-redux';

function GraphItem() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="28"
      className="item"
      viewBox="0 0 35 28"
      fill="none"
    >
      <path
        d="M0 10C11.1185 11.6652 20.1037 18.4861 25 28L35 25C28.6934 11.534 15.585 1.7943 0 0V10Z"
        fill="#FBFBFB"
        fillOpacity="0.5"
      />
      <path
        d="M25.4995 26.806C20.5137 17.6429 11.7699 11.0278 1.00015 9.14897V1.13631C15.3434 3.15939 27.4081 12.0849 33.5873 24.3796L25.4995 26.806Z"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="2.00029"
      />
    </svg>
  );
}

interface RatingPieProps {
  // Redux
  i18n: Translation;
  //
  maxRating?: number;
  rating: number;
  title?: string;
}

function RatingPie(props: RatingPieProps) {
  const graphItemsContainer = useRef<HTMLDivElement>(null);

  const intensityLevels = useMemo(() => Object.values(props.i18n.rating.values), [props.i18n]);

  const intensityLevel = useMemo(() => {
    try {
      if (!isEmpty(intensityLevels)) {
        const intensity = clamp(props.rating, 0, intensityLevels.length - 1);
        return intensityLevels[intensity];
      }
    } catch (error) {
      console.warn(error);
    }
    return null;
  }, [intensityLevels, props.rating]);

  useEffect(() => {
    if (graphItemsContainer.current) {
      const ciclegraph = graphItemsContainer.current;
      const circleElements = ciclegraph.childNodes;

      let angle = 360 - 90;
      const dangle = 360 / circleElements.length;

      const offsetAngle = dangle;
      const startAngle = 35;

      for (let i = 0; i < circleElements.length; i++) {
        const circle = circleElements[i];
        angle += dangle;
        //@ts-ignore
        circle.style.opacity = props.rating > i ? 1 : 0;
        //@ts-ignore
        circle.style.transform = `rotate(${angle - startAngle}deg) translate(${
          ciclegraph.clientWidth / 2.5
        }px) rotate(-${angle}deg) rotate(${offsetAngle * i + startAngle}deg)`;
      }
    }
  }, [props.maxRating, props.rating]);

  const renderGraphItems = useCallback(() => {
    return new Array(props.maxRating || variables.DEFAULT_MAX_RATING)
      .fill(0)
      .map((_e, i) => <GraphItem key={`graph-item-${i}`} />);
  }, [props.maxRating]);

  return (
    <div className="rating-pie-container">
      <div className="circle-graph-container">
        <Image className="background" src={PieBackground} />
        <span className={getClassName('rating-number', { disabled: props.rating <= 0 })}>
          {props.rating > 0 ? props.rating : '-'}
        </span>
        <div className="circle-graph" ref={graphItemsContainer}>
          {renderGraphItems()}
        </div>
      </div>
      {!isEmpty(props.title) && <span className="title">{props.title}</span>}
      {!isEmpty(props.title) && intensityLevel && (
        <span className="subtitle">{intensityLevel}</span>
      )}
    </div>
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(RatingPie));
