import { setThrottle, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import variables from 'common/config/variables';
import { FocusableItem } from 'common/interfaces';
import { Banner } from 'common/reducers/carousel';
import { getClassName } from 'common/utils';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation } from 'react-router';
import BannerView from './BannerView';
import './Carousel.styles';
import Indicator from './Indicator';
import { getBannerType } from './utils';

const {
  BANNER_AUTOSCROLL_TIMEOUT,
  BANNER_TRANSITION_DURATION,
  KEYPRESS_TIMEOUT,
  BANNER_TIMEOUT_DURATION
} = variables;

interface CarouselViewProps extends Omit<FocusableItem, 'onEnterPress'> {
  expanded?: boolean;
  visible?: boolean;
  banners?: Banner[] | null;
  customView?: React.ReactElement;
  disableExpanding?: boolean;
  onEnterPress?: (banner: Banner) => void;
}

function CarouselView(props: CarouselViewProps) {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(0);
  // Disable transitions on indicators when expanding the banners
  const [initiallyOpen, setInitiallyOpen] = useState(false);
  const selectedItemRef = useRef(selectedItem);

  const banners = useMemo(() => {
    try {
      if (isEmpty(props.banners) || !props.banners) return [];
      return props.banners;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }, [location.pathname, props.banners]);
  const bannersRef = useRef(banners);

  useEffect(() => {
    bannersRef.current = banners;
  }, [banners]);

  useEffect(() => {
    selectedItemRef.current = selectedItem;
  }, [selectedItem]);

  const handleBannerSelection = useCallback(() => {
    props.onEnterPress && props.onEnterPress(bannersRef.current[selectedItemRef.current]);
  }, [props.onEnterPress]);

  const { ref, focused, focusSelf } = useFocusable({
    focusable: true,
    focusKey: props.focusKey || 'CAROUSEL_VIEW',
    onEnterPress: handleBannerSelection,
    onArrowPress: (direction: string) => {
      if (['left', 'right'].includes(direction)) {
        setInitiallyOpen(false);
        banners.length > 1 &&
          setSelectedItem((current) =>
            direction === 'left' ? current - 1 : direction === 'right' ? current + 1 : current
          );
      }
      const disableMovement = !['left', 'right'].includes(direction);
      return disableMovement;
    },
    onFocus: () => {
      setInitiallyOpen(true);
      setThrottle({
        throttle: BANNER_TIMEOUT_DURATION,
        throttleKeypresses: true
      });
    },
    onBlur: () => {
      setInitiallyOpen(true);
      setThrottle({
        throttle: KEYPRESS_TIMEOUT,
        throttleKeypresses: true
      });
    }
  });
  const carouselRef = useRef<Carousel | null>(null);

  const infiniteLoop = useMemo(() => (isEmpty(banners) ? true : banners.length > 1), [banners]);

  const bannerTypes = useMemo(() => banners.map((e) => getBannerType(e)), [banners]);

  const renderBanners = useCallback(() => {
    return banners.map((e, index) => {
      const active = props.visible && focused && selectedItem === index;

      return (
        <BannerView key={`carousel-item-${index}`} banner={e} active={active} focused={focused} />
      );
    });
  }, [banners, selectedItem, focused, props.visible]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!focused && props.visible && banners.length > 1) {
      interval = setInterval(
        () => setSelectedItem((current) => current + 1),
        BANNER_AUTOSCROLL_TIMEOUT
      );
    }
    return () => {
      clearInterval(interval);
    };
  }, [focused, props.visible, banners.length]);

  const renderIndicator = useCallback(
    (
      onClick: (e: React.MouseEvent | React.KeyboardEvent) => void,
      selected: boolean,
      index: number,
      label: string
    ) => {
      return (
        <Indicator
          selected={selected}
          focused={focused}
          initial={initiallyOpen}
          key={`carousel-indicator-${index}`}
          onClick={onClick}
          onKeyDown={onClick}
          bannerType={bannerTypes[index]}
          ariaLabel={`${label} ${index + 1}`}
        />
      );
    },
    [focused, bannerTypes, initiallyOpen]
  );

  const renderView = () => {
    if (!props.visible) return null;
    // Conditional rendering
    if (isEmpty(props.customView)) {
      return (
        <Carousel
          ref={carouselRef}
          infiniteLoop
          centerMode
          centerSlidePercentage={100}
          showArrows={false}
          dynamicHeight
          showThumbs={false}
          showIndicators={infiniteLoop}
          showStatus={false}
          stopOnHover={false}
          transitionTime={BANNER_TRANSITION_DURATION}
          renderIndicator={renderIndicator}
          selectedItem={selectedItem}
          onChange={setSelectedItem}
        >
          {renderBanners()}
        </Carousel>
      );
    }
    return React.cloneElement(props.customView, { focused });
  };

  return (
    <div
      ref={ref}
      className={getClassName('carousel-view-container', {
        expanded: props.expanded || props.disableExpanding,
        disableExpanding: props.disableExpanding
      })}
      onClick={focusSelf}
    >
      {renderView()}
    </div>
  );
}

export default React.memo(CarouselView);
