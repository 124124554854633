import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { connect } from 'react-redux';

import { DefaultButton } from 'common/components/button';
import { Column } from 'common/components/common/style';
import { usePopup } from 'common/contexts/popup';

import ProductLogo from 'common/components/product-logo/ProductLogo';
import { H2 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { ButtonContainer } from './MessagePopup.styles';

const MessagePopupContainer = styled(Column)`
  align-items: center;
  min-width: 50vw;

  & > * {
    margin: ${getScalablePixel(8)}px 0;
  }
`;

interface MessagePopupProps extends PropsWithChildren {
  // Redux injected
  btnText: string;
  userDetails?: any;
  //
  popupId: string;
  content?: React.ReactNode;
  temporalDisableDismiss?: boolean;
}

function MessagePopup({
  popupId,
  btnText,
  children,
  userDetails,
  content,
  temporalDisableDismiss
}: MessagePopupProps) {
  const focusKeyValue = useMemo(() => `MESSAGE_POPUP-${popupId}`, [popupId]);
  const closeButtonFocusKey = useMemo(() => `message-close-button-${popupId}`, [popupId]);
  const { ref, focusKey } = useFocusable({
    focusKey: focusKeyValue,
    preferredChildFocusKey: closeButtonFocusKey,
    isFocusBoundary: true,
    trackChildren: true
  });
  const [disableButton, setDisableButton] = useState(temporalDisableDismiss);
  const { closePopup } = usePopup();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (temporalDisableDismiss) {
      setDisableButton(true);
      timeout = setTimeout(() => {
        setDisableButton(false);
      }, variables.TEMPORAL_DISABLE_MESSAGE_POPUP_TIMEOUT);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [temporalDisableDismiss]);

  useEffect(() => {
    setImmediate(() => setFocus(closeButtonFocusKey));
  }, [closeButtonFocusKey]);

  const close = useCallback(() => {
    closePopup(popupId);
  }, []);

  const renderDefaultBody = () => {
    return (
      <>
        <ProductLogo height={getScalablePixel(80)} type="full" />
        <H2>{userDetails?.email}</H2>
        {children}
      </>
    );
  };

  const renderBody = () => {
    if (isEmpty(content)) {
      return renderDefaultBody();
    } else {
      return content;
    }
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <MessagePopupContainer>
        {renderBody()}
        <ButtonContainer ref={ref}>
          <DefaultButton
            title={btnText}
            onEnterPress={close}
            disabled={disableButton}
            size="large"
            focusKey={closeButtonFocusKey}
          />
        </ButtonContainer>
      </MessagePopupContainer>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n, userDetails }: any) => ({
  btnText: i18n?.common?.ok,
  userDetails
});

export default React.memo(connect(mapStateToProps, {})(MessagePopup));
