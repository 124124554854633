import { CATEGORIES_LIVE_CLEAR, CATEGORIES_LIVE_GET } from 'common/constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case CATEGORIES_LIVE_GET:
      return action.payload;
    case CATEGORIES_LIVE_CLEAR:
      return {};
    default:
      return state;
  }
}
