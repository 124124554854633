import FutureThumbnail from '@assets/media/card_next_cover.png';
import { setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { playVideo } from 'common/actions';
import { NoNetworkPopup } from 'common/components/no-network-popup';
import { THUMBNAIL_OFFSET } from 'common/config/constants';
import variables from 'common/config/variables';
import { LONG_THROTTLE_TIMEOUT } from 'common/config/variables/default';
import { Epg } from 'common/constants/data-types';
import { usePopup } from 'common/contexts/popup';
import { IVideoPlayer, PlayerControlsState } from 'common/reducers/videoPlayer';
import { getThumbnailUrl, getToday } from 'common/services/helpers';
import AnimationDurations from 'common/utils/AnimationDurations';
import { useHoverable, useOnlineStatus } from 'common/utils/hooks';
import { debounce, DebouncedFunc, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { PlayerControlsProps } from './Controls';
import { EpgShowContainer, EpgShowThumbnail, EpgShowType } from './Controls.styles';
import EpgShowInfo from './EpgShowInfo';
const { THROTTLE_TIMEOUT } = variables;

interface EpgShowProps {
  type: EpgShowType;
  togglePlay: PlayerControlsProps['togglePlay'];
  toggleControls: PlayerControlsProps['toggleControls'];
  // Redux injected
  epgs?: IVideoPlayer['epgs'];
  playerControlsState: PlayerControlsState;
  playVideo: (currentepg: Epg, options?: any) => void;
  //
}

function EpgShow(props: EpgShowProps) {
  const [isOnline] = useOnlineStatus();
  const isOnlineRef = useRef(isOnline);
  const { openPopup } = usePopup();
  const debouncedPlayVideo = useRef<DebouncedFunc<VoidFunction> | undefined>();
  const epg = useMemo(
    () => (!isEmpty(props.epgs) ? props.epgs[props.type] : undefined),
    [props.epgs, props.type]
  );

  useEffect(() => {
    isOnlineRef.current = isOnline;
  }, [isOnline]);

  const onSelect = useCallback(() => {
    !isOnlineRef.current &&
      openPopup({
        id: 'no-network',
        returnToLastFocused: false,
        content: <NoNetworkPopup />
      });
    if (isEmpty(epg) || !isOnlineRef.current) {
      return;
    }
    const now = getToday().getTime() / 1000;
    // Future show
    if (epg.start > now) {
      return;
    }
    //
    props.togglePlay(false);
    debouncedPlayVideo.current && debouncedPlayVideo.current.cancel();
    debouncedPlayVideo.current = debounce(() => {
      AnimationDurations.set(
        'PLAYER_AUTOHIDE_TIMER',
        variables.PLAYER_MINIVIEW_EXTENDED_AUTOHIDE_TIMER
      );
      props.playVideo(epg, { startPosition: 0 });
    }, LONG_THROTTLE_TIMEOUT);
    debouncedPlayVideo.current();
  }, [epg, debouncedPlayVideo.current]);

  const { ref, focused } = useFocusable({
    onEnterPress: onSelect,
    focusable: !!epg
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);

  const isFuture = useMemo(
    () =>
      !isEmpty(epg) ? props.type === 'next' && epg.start > getToday().getTime() / 1000 : undefined,
    [epg, props.type]
  );
  const thumbnail = useMemo(() => {
    if (isEmpty(epg)) {
      return '';
    }
    const now = getToday().getTime() / 1000;
    // Future show
    if (isFuture) {
      return FutureThumbnail;
    }
    //
    const thumbnailTimestamp =
      now - epg.start < THUMBNAIL_OFFSET ? now : epg.start + THUMBNAIL_OFFSET;
    return getThumbnailUrl(epg.chan_id, epg.now, thumbnailTimestamp);
  }, [
    props.epgs?.prev?.start,
    props.epgs?.next?.start,
    props.type,
    props.playerControlsState,
    isFuture
  ]);

  useEffect(() => {
    // When no epg is found, set focus to play button
    if (focused && !epg) {
      setFocus('TOGGLE_PLAY');
    }
  }, [focused, epg]);

  return (
    <EpgShowContainer
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
    >
      {epg && (
        <>
          {props.type === 'next' && <EpgShowInfo key="next-epg-info" type={props.type} {...epg} />}
          <EpgShowThumbnail
            src={thumbnail}
            type={isFuture ? 'future' : props.type}
            focused={active}
          />
          {props.type === 'prev' && <EpgShowInfo key="prev-epg-info" type={props.type} {...epg} />}
        </>
      )}
    </EpgShowContainer>
  );
}

const mapStateToProps = ({ videoPlayer }: any) => {
  return {
    epgs: videoPlayer.epgs,
    playerControlsState: videoPlayer.playerControlsState
  };
};
export default React.memo(connect(mapStateToProps, { playVideo })(EpgShow));
