import { Row } from 'common/components/common/style';
import { Image } from 'common/components/image';
import { getScalablePixel } from 'common/utils';
import styled from 'styled-components';

export const MessageIconStyled = styled(Image)`
  width: ${getScalablePixel(152)}px;
  height: ${getScalablePixel(183)}px;
`;

export const ButtonContainer = styled(Row)`
  padding-top: ${getScalablePixel(8)}px;
`;
