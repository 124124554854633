import NoSubscriptionIcon from '@assets/media/no-subscription-icon.svg';
import { translationPacket } from 'common/actions';
import { FullscreenError } from 'common/components/full-screen-error';
import { H1 } from 'common/components/Typography';
import { getScalablePixel } from 'common/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const Title = styled(H1)`
  font-size: ${getScalablePixel(60)}px;
`;
interface NoSubscriptionProps {
  i18n: typeof translationPacket;
}
function NoSubscription({ i18n }: NoSubscriptionProps) {
  if (isEmpty(i18n)) {
    return <></>;
  }

  return (
    <FullscreenError
      title={<Title>{i18n.authentication.noSubscription}</Title>}
      description={i18n.authentication.noSubscriptionDescription}
      info={i18n.authentication.noSubscriptionInfo}
      icon={NoSubscriptionIcon}
    />
  );
}

const mapStateToProps = ({ i18n }: any) => ({
  i18n
});

export default React.memo(connect(mapStateToProps, {})(NoSubscription));
