import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { FocusableItem } from 'common/interfaces';
import { getClassName } from 'common/utils';
import React, { useCallback, useEffect } from 'react';

export interface SimpleScrollViewItemProps extends FocusableItem {
  stripeItemClassName?: string;
  className?: string;
  children: React.ReactElement;
}
function SimpleScrollViewItem({
  stripeItemClassName: focusedClassName = 'focused',
  className,
  children,
  onArrowPress,
  onBlur,
  onClick: onClickParam,
  onEnterPress,
  onFocus,
  isFocusBoundary,
  onHover,
  onMouseLeave,
  initiallyFocused = false
}: SimpleScrollViewItemProps) {
  const { ref, focused, focusKey, focusSelf } = useFocusable({
    onFocus,
    onBlur,
    onEnterPress,
    onArrowPress,
    isFocusBoundary
  });

  useEffect(() => {
    if (initiallyFocused) {
      focusSelf();
    }
  }, [initiallyFocused]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      focusSelf();
      onClickParam && onClickParam(e);
    },
    [onClickParam]
  );

  return (
    <div
      ref={ref}
      key={focusKey}
      onClick={onClick}
      className={getClassName('simple-scroll-view-item', {
        [focusedClassName]: focused,
        [className || '']: className
      })}
    >
      {React.cloneElement(children, { focused })}
    </div>
  );
}

export default React.memo(SimpleScrollViewItem);
