import ProductIcon from '@assets/media/logo_small.svg';
import {
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable
} from '@noriginmedia/norigin-spatial-navigation';
import { clearError, resetVideo, translationPacket } from 'common/actions';
import { DefaultButton } from 'common/components/button';
import { Column, Row as FlexRow } from 'common/components/common/style';
import { H1 } from 'common/components/Typography';
import variables from 'common/config/variables';
import { deleteUserObject } from 'common/services/helpers';
import { usePageNavigation, useSignOut } from 'common/utils/hooks';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ButtonContainer,
  Description,
  ErrorIcon,
  MiddleRow,
  ProductIconContainer,
  Row,
  TooManyUsersInfo,
  UserTitle,
  Wrapper
} from './FullscreenError.styles';

interface FullscreenErrorProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  info: string;
  icon: string;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  onPrimaryButtonPress?: VoidFunction;
  onSecondaryButtonPress?: VoidFunction;
  // Redux injected
  i18n: typeof translationPacket;
  userDetails: any;
  clearError: VoidFunction;
  resetVideo: VoidFunction;
  //
}
function FullscreenError({
  i18n,
  userDetails,
  title,
  description,
  info,
  icon,
  clearError,
  resetVideo,
  primaryButtonTitle,
  secondaryButtonTitle,
  onPrimaryButtonPress,
  onSecondaryButtonPress
}: FullscreenErrorProps) {
  const signOut = useSignOut();
  const { ref, focusKey } = useFocusable({
    isFocusBoundary: true,
    preferredChildFocusKey: 'RETRY_BUTTON',
    forceFocus: true
  });
  const navigate = usePageNavigation();

  useEffect(() => {
    // Reset video
    resetVideo();
    //
    const timeout = setTimeout(() => setFocus('RETRY_BUTTON'), variables.SHORT_THROTTLE);
    return () => clearTimeout(timeout);
  }, []);

  const onRetry = useCallback(() => {
    clearError();
    if (onPrimaryButtonPress) {
      onPrimaryButtonPress();
    } else {
      // Hard refresh app
      window.location.reload();
    }
  }, [onPrimaryButtonPress]);

  const onSignIn = useCallback(async () => {
    clearError();
    if (onSecondaryButtonPress) {
      onSecondaryButtonPress();
    } else {
      deleteUserObject();
      await signOut();
      window.location.reload();
    }
  }, [onSecondaryButtonPress]);

  if (isEmpty(userDetails)) {
    return <></>;
  }

  // Check if focusKey is outside of the container to make sure focus is not lost outside of the container
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const key = getCurrentFocusKey();
      if (!['RETRY_BUTTON', 'SIGN_IN'].includes(key)) {
        setFocus('RETRY_BUTTON');
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <FocusContext.Provider value={focusKey}>
      <Wrapper ref={ref}>
        <Row justifyContent="space-between">
          <Column alignItems="flex-end">
            <ErrorIcon src={icon} />
          </Column>
          <Column flex={1}>
            <ProductIconContainer src={ProductIcon} />
            <UserTitle>{userDetails?.email}</UserTitle>
          </Column>
        </Row>
        <MiddleRow>
          {typeof title === 'string' ? <H1>{title}</H1> : title}
          {typeof description === 'string' ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            description
          )}
        </MiddleRow>
        <Row>
          <TooManyUsersInfo dangerouslySetInnerHTML={{ __html: info }} />
          <FlexRow>
            <ButtonContainer alignItems="center">
              <DefaultButton
                focusKey="RETRY_BUTTON"
                title={primaryButtonTitle || i18n.authentication.tryAgain}
                onEnterPress={onRetry}
              />
              <DefaultButton
                focusKey="SIGN_IN"
                title={secondaryButtonTitle || i18n.authentication.signInAnotherAccount}
                onEnterPress={onSignIn}
              />
            </ButtonContainer>
          </FlexRow>
        </Row>
      </Wrapper>
    </FocusContext.Provider>
  );
}

const mapStateToProps = ({ i18n, userDetails }: any) => ({
  i18n,
  userDetails
});

export default React.memo(connect(mapStateToProps, { clearError, resetVideo })(FullscreenError));
