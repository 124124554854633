import { CHANNEL_LIST_SET_CATEGORY } from 'common/constants/action-types';
import { Category } from 'common/constants/data-types';

export interface IChannelList {
  category?: Category | null;
}

const INITIAL_STATE: IChannelList = {
  category: null,
};
export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CHANNEL_LIST_SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
}
