import { SET_IDLE_TIMER } from 'common/constants/action-types';
import { PayloadAction } from 'index';

export interface IdleTimer {
  visible?: boolean;
  countdownTime: number;
}

const defaultState = {
  visible: false,
  countdownTime: -1
};

export default function (state: IdleTimer = defaultState, action: PayloadAction) {
  switch (action.type) {
    case SET_IDLE_TIMER:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
