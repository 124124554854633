import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { playVideo, setPlayerControlsState } from 'common/actions';
import { Progressbar } from 'common/components/progressbar';
import { HAS_ACCESS, TIME_BEFORE_BUFFER } from 'common/config/constants';
import variables from 'common/config/variables';
import { Epg, LiveChannels } from 'common/constants/data-types';
import { FocusableItem } from 'common/interfaces';
import { IVideoPlayer, PlayerControlsState } from 'common/reducers/videoPlayer';
import { calculateTimePercentages, getLiveTimestamp, getToday } from 'common/services/helpers';
import { getClassName, getRemoteKeyName } from 'common/utils';
import { useHoverable } from 'common/utils/hooks';
import { isArray, isEmpty, isNil } from 'lodash';
import React, {
  MouseEvent,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState
} from 'react';
import { connect } from 'react-redux';
import { PlayerControlsProps } from './Controls';
import FastSeekStripe from './FastSeekStripe';
import StreamTimes from './StreamTimes';
const { FAST_SEEK_OFFSET } = variables;

interface SeekOptions {
  timestamp: number;
  epgIndex: number;
}

interface SeekbarProps {
  // Redux injected
  allEpgs?: Epg[];
  currentTime: number;
  epgs?: IVideoPlayer['epgs'];
  playerControlsState: PlayerControlsState;
  setPlayerControlsState: (playerControlsState: PlayerControlsState) => void;
  playVideo: (currentepg: Epg, options?: any) => void;
  nextVideoEpg?: Epg;
  visible?: boolean;
  currentVideoStart?: number;
  currentVideoStop?: number;
  channelAccess?: number;

  //
  pause: PlayerControlsProps['pause'];
  play: PlayerControlsProps['play'];
  setCurrentTime: PlayerControlsProps['setCurrentTime'];
  focusKey?: FocusableItem['focusKey'];
  disabled?: FocusableItem['disabled'];
}
function Seekbar(props: SeekbarProps) {
  const { ref, focused } = useFocusable({
    focusKey: props.focusKey,
    focusable: !props.disabled,
    isFocusBoundary: true,
    onArrowPress: (direction: string) => {
      if (['left', 'right'].includes(direction) && props.channelAccess === HAS_ACCESS) {
        handleSeeking(direction);
      }
      return props.playerControlsState === 'expanded';
    }
  });
  const { active, onMouseEnter, onMouseLeave } = useHoverable(focused);
  const defCurrentTime = useDeferredValue(props.currentTime);

  const [seekOptions, setSeekOptions] = useState<SeekOptions>({ epgIndex: -1, timestamp: 0 });
  const currentEpg = useMemo((): Epg | undefined => {
    try {
      const current = props.epgs?.current;
      const calculatedEpg =
        props.allEpgs && seekOptions.epgIndex !== -1 && props.allEpgs[seekOptions.epgIndex];
      return props.nextVideoEpg || calculatedEpg || current;
    } catch (error) {
      console.warn(error);
      return undefined;
    }
  }, [seekOptions, props.allEpgs, props.nextVideoEpg]);

  useEffect(() => {
    if (props.epgs) {
      setSeekOptions({ epgIndex: props.epgs.initialToIndex, timestamp: 0 });
    }
  }, [props.epgs]);

  useEffect(() => {
    // Reset state and play stream
    if (props.playerControlsState === 'hidden' && seekOptions.timestamp) {
      props.epgs && setSeekOptions({ epgIndex: props.epgs.initialToIndex, timestamp: 0 });
      props.play();
    }
  }, [props.playerControlsState]);

  const currentTime = useMemo(() => {
    try {
      if (isEmpty(currentEpg)) {
        throw new Error('No current EPG');
      }
      const currentRunningEpg = props.epgs?.current;
      const timestamp = seekOptions.timestamp ? seekOptions.timestamp - currentEpg.start : 0;
      const hasNextVideo = !isEmpty(props.nextVideoEpg);
      const showRealCurrentTime =
        props.playerControlsState === 'channels' &&
        currentRunningEpg?.now &&
        props.nextVideoEpg?.chan_id === currentRunningEpg?.chan_id;
      return hasNextVideo && !showRealCurrentTime
        ? getLiveTimestamp() - currentEpg.start
        : timestamp || defCurrentTime;
    } catch (error) {
      return 0;
    }
  }, [
    props.nextVideoEpg,
    currentEpg,
    defCurrentTime,
    seekOptions,
    props.playerControlsState,
    props.epgs?.current
  ]);

  const duration = useMemo(() => {
    try {
      if (isEmpty(currentEpg)) {
        throw new Error('No current EPG');
      }
      return currentEpg.stop - currentEpg.start;
    } catch (error) {
      const { currentVideoStart, currentVideoStop } = props;
      // Fallback to current video start and stop
      return currentVideoStart && currentVideoStop
        ? currentVideoStop - currentVideoStart
        : undefined;
    }
  }, [defCurrentTime, currentEpg, props.currentVideoStart, props.currentVideoStop]);

  const percentage = useMemo(() => {
    try {
      if (!currentEpg) {
        return 0;
      }
      const { start, stop, now: isLiveEpg } = currentEpg;
      const livePosition = calculateTimePercentages(
        start,
        stop,
        getLiveTimestamp() - TIME_BEFORE_BUFFER
      );
      const currentPosition = calculateTimePercentages(start, stop, currentTime + start);
      return isLiveEpg ? [currentPosition, livePosition] : currentPosition;
    } catch (error) {
      console.warn(error);
      return 0;
    }
  }, [seekOptions, props.allEpgs, currentTime, currentEpg, props.nextVideoEpg]);

  const handleSeeking = useCallback(
    (direction: 'left' | 'right' | string) => {
      props.playerControlsState !== 'seeking' && props.setPlayerControlsState('seeking');
      props.pause();
      if (!currentEpg) {
        return;
      }
      const { currentTime } = props;
      const { start, stop, now: isLiveEpg } = currentEpg;
      const offset = FAST_SEEK_OFFSET * (direction === 'left' ? -1 : 1);
      let newSeekTime = seekOptions.timestamp + offset;
      if (!seekOptions.timestamp) {
        newSeekTime += currentTime + start;
      }
      const now = Math.floor(getToday().getTime() / 1000);
      const end = now - TIME_BEFORE_BUFFER;
      let { epgIndex } = seekOptions;
      if (newSeekTime > end && direction === 'right' && isLiveEpg) {
        newSeekTime = end;
      } else if (newSeekTime < start) {
        epgIndex--;
      } else if (newSeekTime > stop) {
        epgIndex++;
      }
      setSeekOptions({ epgIndex, timestamp: newSeekTime });
    },
    [props.playerControlsState, seekOptions, props.currentTime, props.pause]
  );

  const handleSeekFinish = useCallback(() => {
    if (props.playerControlsState !== 'seeking' || !currentEpg || !props.epgs) {
      return;
    }
    const { initialToIndex } = props.epgs;
    const currentTime = seekOptions.timestamp - currentEpg.start;
    if (seekOptions.epgIndex !== initialToIndex) {
      props.playVideo(currentEpg, { startPosition: currentTime });
    } else {
      !isNil(seekOptions.timestamp) && props.setCurrentTime(currentTime);
      props.play();
    }
    setSeekOptions({ epgIndex: initialToIndex, timestamp: 0 });
    props.setPlayerControlsState('mini');
  }, [props.playerControlsState, seekOptions, props.allEpgs, props.epgs, props.play]);

  const handleMouseSeek = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left; //x position within the element.
      const flags = e.buttons;
      const primaryMouseButtonDown = (flags & 1) === 1;
      if (primaryMouseButtonDown && duration) {
        const percentage = x / rect.width;
        props.setCurrentTime(duration * percentage);
      }
    },
    [duration]
  );

  const handleKeyPress = (e: KeyboardEvent) => {
    const key = getRemoteKeyName(e.keyCode);
    const direction = key === 'REWIND' ? 'left' : key === 'FASTFORWARD' ? 'right' : null;
    direction && handleSeeking(direction);
    if (key && ['PLAY', 'PAUSE', 'PLAY_PAUSE', 'OK'].includes(key)) {
      e.preventDefault();
      e.stopPropagation();
      handleSeekFinish();
    }
  };

  useEffect(() => {
    props.visible && document.addEventListener('keyup', handleKeyPress);
    return () => {
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [props.visible, handleSeeking]);

  return (
    <div className="seekbar-container">
      <StreamTimes
        visible={props.playerControlsState !== 'channels'}
        currentTime={currentTime}
        duration={duration}
      />
      <div className="seekbar-inner-container">
        {currentEpg && (
          <FastSeekStripe
            visible={props.playerControlsState === 'seeking'}
            chanId={currentEpg.chan_id}
            timestamp={seekOptions.timestamp}
          />
        )}
        <div
          className="flex row"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={handleMouseSeek}
          onMouseDown={handleMouseSeek}
        >
          <Progressbar customRef={ref} active={active} value={percentage}>
            <div
              className={getClassName('seekbar-point', { focused: active })}
              style={{ left: `${isArray(percentage) ? percentage[0] : percentage}%` }}
            />
          </Progressbar>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  videoPlayer,
  liveChannels
}: {
  videoPlayer: IVideoPlayer;
  liveChannels: LiveChannels;
}) => {
  return {
    currentTime: videoPlayer.video.currentTime,
    epgs: videoPlayer.epgs,
    currentVideoStart: videoPlayer.video.start,
    currentVideoStop: videoPlayer.video.stop,
    allEpgs: videoPlayer.allEpgs,
    nextVideoEpg:
      isEmpty(videoPlayer.nextVideo) || isEmpty(liveChannels)
        ? undefined
        : liveChannels[videoPlayer.nextVideo.channelId].currentepg,
    playerControlsState: videoPlayer.playerControlsState,
    visible: videoPlayer.isPlaying && videoPlayer.video?.isFullscreen,
    channelAccess: videoPlayer.video?.access
  };
};

export default React.memo(connect(mapStateToProps, { setPlayerControlsState, playVideo })(Seekbar));
